import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: any }> = { [K in keyof T]: T[K] };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  bigint: any;
  date: any;
  inet: any;
  json: any;
  jsonb: any;
  numeric: any;
  oid: any;
  smallint: any;
  timestamp: any;
  timestamptz: any;
  uuid: any;
};

/** expression to compare columns of type Boolean. All fields are combined with logical 'AND'. */
export type Boolean_Comparison_Exp = {
  _eq?: Maybe<Scalars['Boolean']>;
  _gt?: Maybe<Scalars['Boolean']>;
  _gte?: Maybe<Scalars['Boolean']>;
  _in?: Maybe<Array<Scalars['Boolean']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['Boolean']>;
  _lte?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Scalars['Boolean']>;
  _nin?: Maybe<Array<Scalars['Boolean']>>;
};

export type CreateUserOutput = {
  __typename?: 'CreateUserOutput';
  user?: Maybe<Users>;
  user_id: Scalars['String'];
};

export type DisableUserOutput = {
  __typename?: 'DisableUserOutput';
  user?: Maybe<Users>;
  user_id: Scalars['String'];
};

export type EnableUserOutput = {
  __typename?: 'EnableUserOutput';
  user?: Maybe<Users>;
  user_id: Scalars['String'];
};

/** expression to compare columns of type Int. All fields are combined with logical 'AND'. */
export type Int_Comparison_Exp = {
  _eq?: Maybe<Scalars['Int']>;
  _gt?: Maybe<Scalars['Int']>;
  _gte?: Maybe<Scalars['Int']>;
  _in?: Maybe<Array<Scalars['Int']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['Int']>;
  _lte?: Maybe<Scalars['Int']>;
  _neq?: Maybe<Scalars['Int']>;
  _nin?: Maybe<Array<Scalars['Int']>>;
};

export type SendBaaOutput = {
  __typename?: 'SendBAAOutput';
  baa?: Maybe<Business_Associate_Agreements>;
  external_id: Scalars['String'];
  external_source?: Maybe<Scalars['String']>;
};

/** expression to compare columns of type String. All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?: Maybe<Scalars['String']>;
  _gt?: Maybe<Scalars['String']>;
  _gte?: Maybe<Scalars['String']>;
  _ilike?: Maybe<Scalars['String']>;
  _in?: Maybe<Array<Scalars['String']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _like?: Maybe<Scalars['String']>;
  _lt?: Maybe<Scalars['String']>;
  _lte?: Maybe<Scalars['String']>;
  _neq?: Maybe<Scalars['String']>;
  _nilike?: Maybe<Scalars['String']>;
  _nin?: Maybe<Array<Scalars['String']>>;
  _nlike?: Maybe<Scalars['String']>;
  _nsimilar?: Maybe<Scalars['String']>;
  _similar?: Maybe<Scalars['String']>;
};

export type UpdateUserAttributes = {
  network_id?: Maybe<Scalars['String']>;
  organization_id?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  practice_id?: Maybe<Scalars['String']>;
  user_name?: Maybe<Scalars['String']>;
};

export type UpdateUserAttributesOutput = {
  __typename?: 'UpdateUserAttributesOutput';
  user_id: Scalars['String'];
};

export type UpdateUserOutput = {
  __typename?: 'UpdateUserOutput';
  user?: Maybe<Users>;
  user_id: Scalars['String'];
};

/**
 * Inbound account creation requests. 
 * 
 * 
 * columns and relationships of "account_creation_requests"
 */
export type Account_Creation_Requests = {
  __typename?: 'account_creation_requests';
  account_creation_request_id: Scalars['String'];
  comments?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  is_archived: Scalars['Boolean'];
  /** An object relationship */
  network?: Maybe<Networks>;
  network_id?: Maybe<Scalars['String']>;
  network_user_email?: Maybe<Scalars['String']>;
  /** An object relationship */
  practice?: Maybe<Practices>;
  practice_id?: Maybe<Scalars['String']>;
  practice_name: Scalars['String'];
  practice_user_email: Scalars['String'];
  practice_user_name: Scalars['String'];
  practice_user_phone?: Maybe<Scalars['String']>;
  providers_text?: Maybe<Scalars['String']>;
  signatory_email?: Maybe<Scalars['String']>;
  signatory_name?: Maybe<Scalars['String']>;
  /** "submitted", "complete" */
  status: Scalars['String'];
  /** An object relationship */
  submitter?: Maybe<Users>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregated selection of "account_creation_requests" */
export type Account_Creation_Requests_Aggregate = {
  __typename?: 'account_creation_requests_aggregate';
  aggregate?: Maybe<Account_Creation_Requests_Aggregate_Fields>;
  nodes: Array<Account_Creation_Requests>;
};

/** aggregate fields of "account_creation_requests" */
export type Account_Creation_Requests_Aggregate_Fields = {
  __typename?: 'account_creation_requests_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Account_Creation_Requests_Max_Fields>;
  min?: Maybe<Account_Creation_Requests_Min_Fields>;
};


/** aggregate fields of "account_creation_requests" */
export type Account_Creation_Requests_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Account_Creation_Requests_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "account_creation_requests" */
export type Account_Creation_Requests_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Account_Creation_Requests_Max_Order_By>;
  min?: Maybe<Account_Creation_Requests_Min_Order_By>;
};

/** input type for inserting array relation for remote table "account_creation_requests" */
export type Account_Creation_Requests_Arr_Rel_Insert_Input = {
  data: Array<Account_Creation_Requests_Insert_Input>;
  on_conflict?: Maybe<Account_Creation_Requests_On_Conflict>;
};

/** Boolean expression to filter rows from the table "account_creation_requests". All fields are combined with a logical 'AND'. */
export type Account_Creation_Requests_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Account_Creation_Requests_Bool_Exp>>>;
  _not?: Maybe<Account_Creation_Requests_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Account_Creation_Requests_Bool_Exp>>>;
  account_creation_request_id?: Maybe<String_Comparison_Exp>;
  comments?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  is_archived?: Maybe<Boolean_Comparison_Exp>;
  network?: Maybe<Networks_Bool_Exp>;
  network_id?: Maybe<String_Comparison_Exp>;
  network_user_email?: Maybe<String_Comparison_Exp>;
  practice?: Maybe<Practices_Bool_Exp>;
  practice_id?: Maybe<String_Comparison_Exp>;
  practice_name?: Maybe<String_Comparison_Exp>;
  practice_user_email?: Maybe<String_Comparison_Exp>;
  practice_user_name?: Maybe<String_Comparison_Exp>;
  practice_user_phone?: Maybe<String_Comparison_Exp>;
  providers_text?: Maybe<String_Comparison_Exp>;
  signatory_email?: Maybe<String_Comparison_Exp>;
  signatory_name?: Maybe<String_Comparison_Exp>;
  status?: Maybe<String_Comparison_Exp>;
  submitter?: Maybe<Users_Bool_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "account_creation_requests" */
export enum Account_Creation_Requests_Constraint {
  /** unique or primary key constraint */
  AccountCreationRequestsPkey = 'account_creation_requests_pkey'
}

/** input type for inserting data into table "account_creation_requests" */
export type Account_Creation_Requests_Insert_Input = {
  account_creation_request_id?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  is_archived?: Maybe<Scalars['Boolean']>;
  network?: Maybe<Networks_Obj_Rel_Insert_Input>;
  network_id?: Maybe<Scalars['String']>;
  network_user_email?: Maybe<Scalars['String']>;
  practice?: Maybe<Practices_Obj_Rel_Insert_Input>;
  practice_id?: Maybe<Scalars['String']>;
  practice_name?: Maybe<Scalars['String']>;
  practice_user_email?: Maybe<Scalars['String']>;
  practice_user_name?: Maybe<Scalars['String']>;
  practice_user_phone?: Maybe<Scalars['String']>;
  providers_text?: Maybe<Scalars['String']>;
  signatory_email?: Maybe<Scalars['String']>;
  signatory_name?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  submitter?: Maybe<Users_Obj_Rel_Insert_Input>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Account_Creation_Requests_Max_Fields = {
  __typename?: 'account_creation_requests_max_fields';
  account_creation_request_id?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  network_id?: Maybe<Scalars['String']>;
  network_user_email?: Maybe<Scalars['String']>;
  practice_id?: Maybe<Scalars['String']>;
  practice_name?: Maybe<Scalars['String']>;
  practice_user_email?: Maybe<Scalars['String']>;
  practice_user_name?: Maybe<Scalars['String']>;
  practice_user_phone?: Maybe<Scalars['String']>;
  providers_text?: Maybe<Scalars['String']>;
  signatory_email?: Maybe<Scalars['String']>;
  signatory_name?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "account_creation_requests" */
export type Account_Creation_Requests_Max_Order_By = {
  account_creation_request_id?: Maybe<Order_By>;
  comments?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  network_id?: Maybe<Order_By>;
  network_user_email?: Maybe<Order_By>;
  practice_id?: Maybe<Order_By>;
  practice_name?: Maybe<Order_By>;
  practice_user_email?: Maybe<Order_By>;
  practice_user_name?: Maybe<Order_By>;
  practice_user_phone?: Maybe<Order_By>;
  providers_text?: Maybe<Order_By>;
  signatory_email?: Maybe<Order_By>;
  signatory_name?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Account_Creation_Requests_Min_Fields = {
  __typename?: 'account_creation_requests_min_fields';
  account_creation_request_id?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  network_id?: Maybe<Scalars['String']>;
  network_user_email?: Maybe<Scalars['String']>;
  practice_id?: Maybe<Scalars['String']>;
  practice_name?: Maybe<Scalars['String']>;
  practice_user_email?: Maybe<Scalars['String']>;
  practice_user_name?: Maybe<Scalars['String']>;
  practice_user_phone?: Maybe<Scalars['String']>;
  providers_text?: Maybe<Scalars['String']>;
  signatory_email?: Maybe<Scalars['String']>;
  signatory_name?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "account_creation_requests" */
export type Account_Creation_Requests_Min_Order_By = {
  account_creation_request_id?: Maybe<Order_By>;
  comments?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  network_id?: Maybe<Order_By>;
  network_user_email?: Maybe<Order_By>;
  practice_id?: Maybe<Order_By>;
  practice_name?: Maybe<Order_By>;
  practice_user_email?: Maybe<Order_By>;
  practice_user_name?: Maybe<Order_By>;
  practice_user_phone?: Maybe<Order_By>;
  providers_text?: Maybe<Order_By>;
  signatory_email?: Maybe<Order_By>;
  signatory_name?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "account_creation_requests" */
export type Account_Creation_Requests_Mutation_Response = {
  __typename?: 'account_creation_requests_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Account_Creation_Requests>;
};

/** input type for inserting object relation for remote table "account_creation_requests" */
export type Account_Creation_Requests_Obj_Rel_Insert_Input = {
  data: Account_Creation_Requests_Insert_Input;
  on_conflict?: Maybe<Account_Creation_Requests_On_Conflict>;
};

/** on conflict condition type for table "account_creation_requests" */
export type Account_Creation_Requests_On_Conflict = {
  constraint: Account_Creation_Requests_Constraint;
  update_columns: Array<Account_Creation_Requests_Update_Column>;
  where?: Maybe<Account_Creation_Requests_Bool_Exp>;
};

/** ordering options when selecting data from "account_creation_requests" */
export type Account_Creation_Requests_Order_By = {
  account_creation_request_id?: Maybe<Order_By>;
  comments?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  is_archived?: Maybe<Order_By>;
  network?: Maybe<Networks_Order_By>;
  network_id?: Maybe<Order_By>;
  network_user_email?: Maybe<Order_By>;
  practice?: Maybe<Practices_Order_By>;
  practice_id?: Maybe<Order_By>;
  practice_name?: Maybe<Order_By>;
  practice_user_email?: Maybe<Order_By>;
  practice_user_name?: Maybe<Order_By>;
  practice_user_phone?: Maybe<Order_By>;
  providers_text?: Maybe<Order_By>;
  signatory_email?: Maybe<Order_By>;
  signatory_name?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  submitter?: Maybe<Users_Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: "account_creation_requests" */
export type Account_Creation_Requests_Pk_Columns_Input = {
  account_creation_request_id: Scalars['String'];
};

/** select columns of table "account_creation_requests" */
export enum Account_Creation_Requests_Select_Column {
  /** column name */
  AccountCreationRequestId = 'account_creation_request_id',
  /** column name */
  Comments = 'comments',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  IsArchived = 'is_archived',
  /** column name */
  NetworkId = 'network_id',
  /** column name */
  NetworkUserEmail = 'network_user_email',
  /** column name */
  PracticeId = 'practice_id',
  /** column name */
  PracticeName = 'practice_name',
  /** column name */
  PracticeUserEmail = 'practice_user_email',
  /** column name */
  PracticeUserName = 'practice_user_name',
  /** column name */
  PracticeUserPhone = 'practice_user_phone',
  /** column name */
  ProvidersText = 'providers_text',
  /** column name */
  SignatoryEmail = 'signatory_email',
  /** column name */
  SignatoryName = 'signatory_name',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "account_creation_requests" */
export type Account_Creation_Requests_Set_Input = {
  account_creation_request_id?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  is_archived?: Maybe<Scalars['Boolean']>;
  network_id?: Maybe<Scalars['String']>;
  network_user_email?: Maybe<Scalars['String']>;
  practice_id?: Maybe<Scalars['String']>;
  practice_name?: Maybe<Scalars['String']>;
  practice_user_email?: Maybe<Scalars['String']>;
  practice_user_name?: Maybe<Scalars['String']>;
  practice_user_phone?: Maybe<Scalars['String']>;
  providers_text?: Maybe<Scalars['String']>;
  signatory_email?: Maybe<Scalars['String']>;
  signatory_name?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "account_creation_requests" */
export enum Account_Creation_Requests_Update_Column {
  /** column name */
  AccountCreationRequestId = 'account_creation_request_id',
  /** column name */
  Comments = 'comments',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  IsArchived = 'is_archived',
  /** column name */
  NetworkId = 'network_id',
  /** column name */
  NetworkUserEmail = 'network_user_email',
  /** column name */
  PracticeId = 'practice_id',
  /** column name */
  PracticeName = 'practice_name',
  /** column name */
  PracticeUserEmail = 'practice_user_email',
  /** column name */
  PracticeUserName = 'practice_user_name',
  /** column name */
  PracticeUserPhone = 'practice_user_phone',
  /** column name */
  ProvidersText = 'providers_text',
  /** column name */
  SignatoryEmail = 'signatory_email',
  /** column name */
  SignatoryName = 'signatory_name',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** columns and relationships of "alignments" */
export type Alignments = {
  __typename?: 'alignments';
  alignment_id: Scalars['String'];
  created_at: Scalars['timestamptz'];
  is_archived: Scalars['Boolean'];
  mbi: Scalars['String'];
  method?: Maybe<Scalars['String']>;
  /** An array relationship */
  patients: Array<Patients>;
  /** An aggregated array relationship */
  patients_aggregate: Patients_Aggregate;
  /** An object relationship */
  provider?: Maybe<Providers>;
  provider_id: Scalars['String'];
  signature_date: Scalars['date'];
  source?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "alignments" */
export type AlignmentsPatientsArgs = {
  distinct_on?: Maybe<Array<Patients_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Patients_Order_By>>;
  where?: Maybe<Patients_Bool_Exp>;
};


/** columns and relationships of "alignments" */
export type AlignmentsPatients_AggregateArgs = {
  distinct_on?: Maybe<Array<Patients_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Patients_Order_By>>;
  where?: Maybe<Patients_Bool_Exp>;
};

/** aggregated selection of "alignments" */
export type Alignments_Aggregate = {
  __typename?: 'alignments_aggregate';
  aggregate?: Maybe<Alignments_Aggregate_Fields>;
  nodes: Array<Alignments>;
};

/** aggregate fields of "alignments" */
export type Alignments_Aggregate_Fields = {
  __typename?: 'alignments_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Alignments_Max_Fields>;
  min?: Maybe<Alignments_Min_Fields>;
};


/** aggregate fields of "alignments" */
export type Alignments_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Alignments_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "alignments" */
export type Alignments_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Alignments_Max_Order_By>;
  min?: Maybe<Alignments_Min_Order_By>;
};

/** input type for inserting array relation for remote table "alignments" */
export type Alignments_Arr_Rel_Insert_Input = {
  data: Array<Alignments_Insert_Input>;
  on_conflict?: Maybe<Alignments_On_Conflict>;
};

/** Boolean expression to filter rows from the table "alignments". All fields are combined with a logical 'AND'. */
export type Alignments_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Alignments_Bool_Exp>>>;
  _not?: Maybe<Alignments_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Alignments_Bool_Exp>>>;
  alignment_id?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  is_archived?: Maybe<Boolean_Comparison_Exp>;
  mbi?: Maybe<String_Comparison_Exp>;
  method?: Maybe<String_Comparison_Exp>;
  patients?: Maybe<Patients_Bool_Exp>;
  provider?: Maybe<Providers_Bool_Exp>;
  provider_id?: Maybe<String_Comparison_Exp>;
  signature_date?: Maybe<Date_Comparison_Exp>;
  source?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "alignments" */
export enum Alignments_Constraint {
  /** unique or primary key constraint */
  AlignmentsPkey = 'alignments_pkey'
}

/** input type for inserting data into table "alignments" */
export type Alignments_Insert_Input = {
  alignment_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  is_archived?: Maybe<Scalars['Boolean']>;
  mbi?: Maybe<Scalars['String']>;
  method?: Maybe<Scalars['String']>;
  patients?: Maybe<Patients_Arr_Rel_Insert_Input>;
  provider?: Maybe<Providers_Obj_Rel_Insert_Input>;
  provider_id?: Maybe<Scalars['String']>;
  signature_date?: Maybe<Scalars['date']>;
  source?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Alignments_Max_Fields = {
  __typename?: 'alignments_max_fields';
  alignment_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  mbi?: Maybe<Scalars['String']>;
  method?: Maybe<Scalars['String']>;
  provider_id?: Maybe<Scalars['String']>;
  signature_date?: Maybe<Scalars['date']>;
  source?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "alignments" */
export type Alignments_Max_Order_By = {
  alignment_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  mbi?: Maybe<Order_By>;
  method?: Maybe<Order_By>;
  provider_id?: Maybe<Order_By>;
  signature_date?: Maybe<Order_By>;
  source?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Alignments_Min_Fields = {
  __typename?: 'alignments_min_fields';
  alignment_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  mbi?: Maybe<Scalars['String']>;
  method?: Maybe<Scalars['String']>;
  provider_id?: Maybe<Scalars['String']>;
  signature_date?: Maybe<Scalars['date']>;
  source?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "alignments" */
export type Alignments_Min_Order_By = {
  alignment_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  mbi?: Maybe<Order_By>;
  method?: Maybe<Order_By>;
  provider_id?: Maybe<Order_By>;
  signature_date?: Maybe<Order_By>;
  source?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "alignments" */
export type Alignments_Mutation_Response = {
  __typename?: 'alignments_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Alignments>;
};

/** input type for inserting object relation for remote table "alignments" */
export type Alignments_Obj_Rel_Insert_Input = {
  data: Alignments_Insert_Input;
  on_conflict?: Maybe<Alignments_On_Conflict>;
};

/** on conflict condition type for table "alignments" */
export type Alignments_On_Conflict = {
  constraint: Alignments_Constraint;
  update_columns: Array<Alignments_Update_Column>;
  where?: Maybe<Alignments_Bool_Exp>;
};

/** ordering options when selecting data from "alignments" */
export type Alignments_Order_By = {
  alignment_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  is_archived?: Maybe<Order_By>;
  mbi?: Maybe<Order_By>;
  method?: Maybe<Order_By>;
  patients_aggregate?: Maybe<Patients_Aggregate_Order_By>;
  provider?: Maybe<Providers_Order_By>;
  provider_id?: Maybe<Order_By>;
  signature_date?: Maybe<Order_By>;
  source?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: "alignments" */
export type Alignments_Pk_Columns_Input = {
  alignment_id: Scalars['String'];
};

/** select columns of table "alignments" */
export enum Alignments_Select_Column {
  /** column name */
  AlignmentId = 'alignment_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  IsArchived = 'is_archived',
  /** column name */
  Mbi = 'mbi',
  /** column name */
  Method = 'method',
  /** column name */
  ProviderId = 'provider_id',
  /** column name */
  SignatureDate = 'signature_date',
  /** column name */
  Source = 'source',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "alignments" */
export type Alignments_Set_Input = {
  alignment_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  is_archived?: Maybe<Scalars['Boolean']>;
  mbi?: Maybe<Scalars['String']>;
  method?: Maybe<Scalars['String']>;
  provider_id?: Maybe<Scalars['String']>;
  signature_date?: Maybe<Scalars['date']>;
  source?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "alignments" */
export enum Alignments_Update_Column {
  /** column name */
  AlignmentId = 'alignment_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  IsArchived = 'is_archived',
  /** column name */
  Mbi = 'mbi',
  /** column name */
  Method = 'method',
  /** column name */
  ProviderId = 'provider_id',
  /** column name */
  SignatureDate = 'signature_date',
  /** column name */
  Source = 'source',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** columns and relationships of "analytics.analytics_cms_active_dce_participants" */
export type Analytics_Analytics_Cms_Active_Dce_Participants = {
  __typename?: 'analytics_analytics_cms_active_dce_participants';
  address_1?: Maybe<Scalars['String']>;
  apt_suite?: Maybe<Scalars['String']>;
  billing_tin?: Maybe<Scalars['String']>;
  billing_tin_current_status?: Maybe<Scalars['String']>;
  billing_tin_deficiency?: Maybe<Scalars['String']>;
  ccn?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  cms_dce_participant_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  effective_end_date?: Maybe<Scalars['date']>;
  effective_start_date?: Maybe<Scalars['date']>;
  entity_id?: Maybe<Scalars['String']>;
  entity_legal_business_name?: Maybe<Scalars['String']>;
  entity_tin?: Maybe<Scalars['String']>;
  individual_npi?: Maybe<Scalars['String']>;
  last_updated?: Maybe<Scalars['date']>;
  legacy_tin_info?: Maybe<Scalars['String']>;
  legal_business_name?: Maybe<Scalars['String']>;
  medicare_id?: Maybe<Scalars['String']>;
  npi_name?: Maybe<Scalars['String']>;
  organizational_npi?: Maybe<Scalars['String']>;
  participant_type?: Maybe<Scalars['String']>;
  performance_year?: Maybe<Scalars['String']>;
  primary_care_services?: Maybe<Scalars['String']>;
  provider_class?: Maybe<Scalars['String']>;
  sole_proprietor?: Maybe<Scalars['String']>;
  sole_proprietor_tin?: Maybe<Scalars['String']>;
  specialty?: Maybe<Scalars['String']>;
  state_territory?: Maybe<Scalars['String']>;
  tin?: Maybe<Scalars['String']>;
  tin_type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  uses_cehrt?: Maybe<Scalars['String']>;
  zip_code?: Maybe<Scalars['String']>;
  zip_code_plus_4?: Maybe<Scalars['String']>;
};

/** aggregated selection of "analytics.analytics_cms_active_dce_participants" */
export type Analytics_Analytics_Cms_Active_Dce_Participants_Aggregate = {
  __typename?: 'analytics_analytics_cms_active_dce_participants_aggregate';
  aggregate?: Maybe<Analytics_Analytics_Cms_Active_Dce_Participants_Aggregate_Fields>;
  nodes: Array<Analytics_Analytics_Cms_Active_Dce_Participants>;
};

/** aggregate fields of "analytics.analytics_cms_active_dce_participants" */
export type Analytics_Analytics_Cms_Active_Dce_Participants_Aggregate_Fields = {
  __typename?: 'analytics_analytics_cms_active_dce_participants_aggregate_fields';
  avg?: Maybe<Analytics_Analytics_Cms_Active_Dce_Participants_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Analytics_Analytics_Cms_Active_Dce_Participants_Max_Fields>;
  min?: Maybe<Analytics_Analytics_Cms_Active_Dce_Participants_Min_Fields>;
  stddev?: Maybe<Analytics_Analytics_Cms_Active_Dce_Participants_Stddev_Fields>;
  stddev_pop?: Maybe<Analytics_Analytics_Cms_Active_Dce_Participants_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Analytics_Analytics_Cms_Active_Dce_Participants_Stddev_Samp_Fields>;
  sum?: Maybe<Analytics_Analytics_Cms_Active_Dce_Participants_Sum_Fields>;
  var_pop?: Maybe<Analytics_Analytics_Cms_Active_Dce_Participants_Var_Pop_Fields>;
  var_samp?: Maybe<Analytics_Analytics_Cms_Active_Dce_Participants_Var_Samp_Fields>;
  variance?: Maybe<Analytics_Analytics_Cms_Active_Dce_Participants_Variance_Fields>;
};


/** aggregate fields of "analytics.analytics_cms_active_dce_participants" */
export type Analytics_Analytics_Cms_Active_Dce_Participants_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Analytics_Analytics_Cms_Active_Dce_Participants_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "analytics.analytics_cms_active_dce_participants" */
export type Analytics_Analytics_Cms_Active_Dce_Participants_Aggregate_Order_By = {
  avg?: Maybe<Analytics_Analytics_Cms_Active_Dce_Participants_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Analytics_Analytics_Cms_Active_Dce_Participants_Max_Order_By>;
  min?: Maybe<Analytics_Analytics_Cms_Active_Dce_Participants_Min_Order_By>;
  stddev?: Maybe<Analytics_Analytics_Cms_Active_Dce_Participants_Stddev_Order_By>;
  stddev_pop?: Maybe<Analytics_Analytics_Cms_Active_Dce_Participants_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Analytics_Analytics_Cms_Active_Dce_Participants_Stddev_Samp_Order_By>;
  sum?: Maybe<Analytics_Analytics_Cms_Active_Dce_Participants_Sum_Order_By>;
  var_pop?: Maybe<Analytics_Analytics_Cms_Active_Dce_Participants_Var_Pop_Order_By>;
  var_samp?: Maybe<Analytics_Analytics_Cms_Active_Dce_Participants_Var_Samp_Order_By>;
  variance?: Maybe<Analytics_Analytics_Cms_Active_Dce_Participants_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Analytics_Analytics_Cms_Active_Dce_Participants_Avg_Fields = {
  __typename?: 'analytics_analytics_cms_active_dce_participants_avg_fields';
  cms_dce_participant_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "analytics.analytics_cms_active_dce_participants" */
export type Analytics_Analytics_Cms_Active_Dce_Participants_Avg_Order_By = {
  cms_dce_participant_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "analytics.analytics_cms_active_dce_participants". All fields are combined with a logical 'AND'. */
export type Analytics_Analytics_Cms_Active_Dce_Participants_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Analytics_Analytics_Cms_Active_Dce_Participants_Bool_Exp>>>;
  _not?: Maybe<Analytics_Analytics_Cms_Active_Dce_Participants_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Analytics_Analytics_Cms_Active_Dce_Participants_Bool_Exp>>>;
  address_1?: Maybe<String_Comparison_Exp>;
  apt_suite?: Maybe<String_Comparison_Exp>;
  billing_tin?: Maybe<String_Comparison_Exp>;
  billing_tin_current_status?: Maybe<String_Comparison_Exp>;
  billing_tin_deficiency?: Maybe<String_Comparison_Exp>;
  ccn?: Maybe<String_Comparison_Exp>;
  city?: Maybe<String_Comparison_Exp>;
  cms_dce_participant_id?: Maybe<Int_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  effective_end_date?: Maybe<Date_Comparison_Exp>;
  effective_start_date?: Maybe<Date_Comparison_Exp>;
  entity_id?: Maybe<String_Comparison_Exp>;
  entity_legal_business_name?: Maybe<String_Comparison_Exp>;
  entity_tin?: Maybe<String_Comparison_Exp>;
  individual_npi?: Maybe<String_Comparison_Exp>;
  last_updated?: Maybe<Date_Comparison_Exp>;
  legacy_tin_info?: Maybe<String_Comparison_Exp>;
  legal_business_name?: Maybe<String_Comparison_Exp>;
  medicare_id?: Maybe<String_Comparison_Exp>;
  npi_name?: Maybe<String_Comparison_Exp>;
  organizational_npi?: Maybe<String_Comparison_Exp>;
  participant_type?: Maybe<String_Comparison_Exp>;
  performance_year?: Maybe<String_Comparison_Exp>;
  primary_care_services?: Maybe<String_Comparison_Exp>;
  provider_class?: Maybe<String_Comparison_Exp>;
  sole_proprietor?: Maybe<String_Comparison_Exp>;
  sole_proprietor_tin?: Maybe<String_Comparison_Exp>;
  specialty?: Maybe<String_Comparison_Exp>;
  state_territory?: Maybe<String_Comparison_Exp>;
  tin?: Maybe<String_Comparison_Exp>;
  tin_type?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  uses_cehrt?: Maybe<String_Comparison_Exp>;
  zip_code?: Maybe<String_Comparison_Exp>;
  zip_code_plus_4?: Maybe<String_Comparison_Exp>;
};

/** aggregate max on columns */
export type Analytics_Analytics_Cms_Active_Dce_Participants_Max_Fields = {
  __typename?: 'analytics_analytics_cms_active_dce_participants_max_fields';
  address_1?: Maybe<Scalars['String']>;
  apt_suite?: Maybe<Scalars['String']>;
  billing_tin?: Maybe<Scalars['String']>;
  billing_tin_current_status?: Maybe<Scalars['String']>;
  billing_tin_deficiency?: Maybe<Scalars['String']>;
  ccn?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  cms_dce_participant_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  effective_end_date?: Maybe<Scalars['date']>;
  effective_start_date?: Maybe<Scalars['date']>;
  entity_id?: Maybe<Scalars['String']>;
  entity_legal_business_name?: Maybe<Scalars['String']>;
  entity_tin?: Maybe<Scalars['String']>;
  individual_npi?: Maybe<Scalars['String']>;
  last_updated?: Maybe<Scalars['date']>;
  legacy_tin_info?: Maybe<Scalars['String']>;
  legal_business_name?: Maybe<Scalars['String']>;
  medicare_id?: Maybe<Scalars['String']>;
  npi_name?: Maybe<Scalars['String']>;
  organizational_npi?: Maybe<Scalars['String']>;
  participant_type?: Maybe<Scalars['String']>;
  performance_year?: Maybe<Scalars['String']>;
  primary_care_services?: Maybe<Scalars['String']>;
  provider_class?: Maybe<Scalars['String']>;
  sole_proprietor?: Maybe<Scalars['String']>;
  sole_proprietor_tin?: Maybe<Scalars['String']>;
  specialty?: Maybe<Scalars['String']>;
  state_territory?: Maybe<Scalars['String']>;
  tin?: Maybe<Scalars['String']>;
  tin_type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  uses_cehrt?: Maybe<Scalars['String']>;
  zip_code?: Maybe<Scalars['String']>;
  zip_code_plus_4?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "analytics.analytics_cms_active_dce_participants" */
export type Analytics_Analytics_Cms_Active_Dce_Participants_Max_Order_By = {
  address_1?: Maybe<Order_By>;
  apt_suite?: Maybe<Order_By>;
  billing_tin?: Maybe<Order_By>;
  billing_tin_current_status?: Maybe<Order_By>;
  billing_tin_deficiency?: Maybe<Order_By>;
  ccn?: Maybe<Order_By>;
  city?: Maybe<Order_By>;
  cms_dce_participant_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  effective_end_date?: Maybe<Order_By>;
  effective_start_date?: Maybe<Order_By>;
  entity_id?: Maybe<Order_By>;
  entity_legal_business_name?: Maybe<Order_By>;
  entity_tin?: Maybe<Order_By>;
  individual_npi?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  legacy_tin_info?: Maybe<Order_By>;
  legal_business_name?: Maybe<Order_By>;
  medicare_id?: Maybe<Order_By>;
  npi_name?: Maybe<Order_By>;
  organizational_npi?: Maybe<Order_By>;
  participant_type?: Maybe<Order_By>;
  performance_year?: Maybe<Order_By>;
  primary_care_services?: Maybe<Order_By>;
  provider_class?: Maybe<Order_By>;
  sole_proprietor?: Maybe<Order_By>;
  sole_proprietor_tin?: Maybe<Order_By>;
  specialty?: Maybe<Order_By>;
  state_territory?: Maybe<Order_By>;
  tin?: Maybe<Order_By>;
  tin_type?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  uses_cehrt?: Maybe<Order_By>;
  zip_code?: Maybe<Order_By>;
  zip_code_plus_4?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Analytics_Analytics_Cms_Active_Dce_Participants_Min_Fields = {
  __typename?: 'analytics_analytics_cms_active_dce_participants_min_fields';
  address_1?: Maybe<Scalars['String']>;
  apt_suite?: Maybe<Scalars['String']>;
  billing_tin?: Maybe<Scalars['String']>;
  billing_tin_current_status?: Maybe<Scalars['String']>;
  billing_tin_deficiency?: Maybe<Scalars['String']>;
  ccn?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  cms_dce_participant_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  effective_end_date?: Maybe<Scalars['date']>;
  effective_start_date?: Maybe<Scalars['date']>;
  entity_id?: Maybe<Scalars['String']>;
  entity_legal_business_name?: Maybe<Scalars['String']>;
  entity_tin?: Maybe<Scalars['String']>;
  individual_npi?: Maybe<Scalars['String']>;
  last_updated?: Maybe<Scalars['date']>;
  legacy_tin_info?: Maybe<Scalars['String']>;
  legal_business_name?: Maybe<Scalars['String']>;
  medicare_id?: Maybe<Scalars['String']>;
  npi_name?: Maybe<Scalars['String']>;
  organizational_npi?: Maybe<Scalars['String']>;
  participant_type?: Maybe<Scalars['String']>;
  performance_year?: Maybe<Scalars['String']>;
  primary_care_services?: Maybe<Scalars['String']>;
  provider_class?: Maybe<Scalars['String']>;
  sole_proprietor?: Maybe<Scalars['String']>;
  sole_proprietor_tin?: Maybe<Scalars['String']>;
  specialty?: Maybe<Scalars['String']>;
  state_territory?: Maybe<Scalars['String']>;
  tin?: Maybe<Scalars['String']>;
  tin_type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  uses_cehrt?: Maybe<Scalars['String']>;
  zip_code?: Maybe<Scalars['String']>;
  zip_code_plus_4?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "analytics.analytics_cms_active_dce_participants" */
export type Analytics_Analytics_Cms_Active_Dce_Participants_Min_Order_By = {
  address_1?: Maybe<Order_By>;
  apt_suite?: Maybe<Order_By>;
  billing_tin?: Maybe<Order_By>;
  billing_tin_current_status?: Maybe<Order_By>;
  billing_tin_deficiency?: Maybe<Order_By>;
  ccn?: Maybe<Order_By>;
  city?: Maybe<Order_By>;
  cms_dce_participant_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  effective_end_date?: Maybe<Order_By>;
  effective_start_date?: Maybe<Order_By>;
  entity_id?: Maybe<Order_By>;
  entity_legal_business_name?: Maybe<Order_By>;
  entity_tin?: Maybe<Order_By>;
  individual_npi?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  legacy_tin_info?: Maybe<Order_By>;
  legal_business_name?: Maybe<Order_By>;
  medicare_id?: Maybe<Order_By>;
  npi_name?: Maybe<Order_By>;
  organizational_npi?: Maybe<Order_By>;
  participant_type?: Maybe<Order_By>;
  performance_year?: Maybe<Order_By>;
  primary_care_services?: Maybe<Order_By>;
  provider_class?: Maybe<Order_By>;
  sole_proprietor?: Maybe<Order_By>;
  sole_proprietor_tin?: Maybe<Order_By>;
  specialty?: Maybe<Order_By>;
  state_territory?: Maybe<Order_By>;
  tin?: Maybe<Order_By>;
  tin_type?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  uses_cehrt?: Maybe<Order_By>;
  zip_code?: Maybe<Order_By>;
  zip_code_plus_4?: Maybe<Order_By>;
};

/** ordering options when selecting data from "analytics.analytics_cms_active_dce_participants" */
export type Analytics_Analytics_Cms_Active_Dce_Participants_Order_By = {
  address_1?: Maybe<Order_By>;
  apt_suite?: Maybe<Order_By>;
  billing_tin?: Maybe<Order_By>;
  billing_tin_current_status?: Maybe<Order_By>;
  billing_tin_deficiency?: Maybe<Order_By>;
  ccn?: Maybe<Order_By>;
  city?: Maybe<Order_By>;
  cms_dce_participant_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  effective_end_date?: Maybe<Order_By>;
  effective_start_date?: Maybe<Order_By>;
  entity_id?: Maybe<Order_By>;
  entity_legal_business_name?: Maybe<Order_By>;
  entity_tin?: Maybe<Order_By>;
  individual_npi?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  legacy_tin_info?: Maybe<Order_By>;
  legal_business_name?: Maybe<Order_By>;
  medicare_id?: Maybe<Order_By>;
  npi_name?: Maybe<Order_By>;
  organizational_npi?: Maybe<Order_By>;
  participant_type?: Maybe<Order_By>;
  performance_year?: Maybe<Order_By>;
  primary_care_services?: Maybe<Order_By>;
  provider_class?: Maybe<Order_By>;
  sole_proprietor?: Maybe<Order_By>;
  sole_proprietor_tin?: Maybe<Order_By>;
  specialty?: Maybe<Order_By>;
  state_territory?: Maybe<Order_By>;
  tin?: Maybe<Order_By>;
  tin_type?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  uses_cehrt?: Maybe<Order_By>;
  zip_code?: Maybe<Order_By>;
  zip_code_plus_4?: Maybe<Order_By>;
};

/** select columns of table "analytics.analytics_cms_active_dce_participants" */
export enum Analytics_Analytics_Cms_Active_Dce_Participants_Select_Column {
  /** column name */
  Address_1 = 'address_1',
  /** column name */
  AptSuite = 'apt_suite',
  /** column name */
  BillingTin = 'billing_tin',
  /** column name */
  BillingTinCurrentStatus = 'billing_tin_current_status',
  /** column name */
  BillingTinDeficiency = 'billing_tin_deficiency',
  /** column name */
  Ccn = 'ccn',
  /** column name */
  City = 'city',
  /** column name */
  CmsDceParticipantId = 'cms_dce_participant_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EffectiveEndDate = 'effective_end_date',
  /** column name */
  EffectiveStartDate = 'effective_start_date',
  /** column name */
  EntityId = 'entity_id',
  /** column name */
  EntityLegalBusinessName = 'entity_legal_business_name',
  /** column name */
  EntityTin = 'entity_tin',
  /** column name */
  IndividualNpi = 'individual_npi',
  /** column name */
  LastUpdated = 'last_updated',
  /** column name */
  LegacyTinInfo = 'legacy_tin_info',
  /** column name */
  LegalBusinessName = 'legal_business_name',
  /** column name */
  MedicareId = 'medicare_id',
  /** column name */
  NpiName = 'npi_name',
  /** column name */
  OrganizationalNpi = 'organizational_npi',
  /** column name */
  ParticipantType = 'participant_type',
  /** column name */
  PerformanceYear = 'performance_year',
  /** column name */
  PrimaryCareServices = 'primary_care_services',
  /** column name */
  ProviderClass = 'provider_class',
  /** column name */
  SoleProprietor = 'sole_proprietor',
  /** column name */
  SoleProprietorTin = 'sole_proprietor_tin',
  /** column name */
  Specialty = 'specialty',
  /** column name */
  StateTerritory = 'state_territory',
  /** column name */
  Tin = 'tin',
  /** column name */
  TinType = 'tin_type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UsesCehrt = 'uses_cehrt',
  /** column name */
  ZipCode = 'zip_code',
  /** column name */
  ZipCodePlus_4 = 'zip_code_plus_4'
}

/** aggregate stddev on columns */
export type Analytics_Analytics_Cms_Active_Dce_Participants_Stddev_Fields = {
  __typename?: 'analytics_analytics_cms_active_dce_participants_stddev_fields';
  cms_dce_participant_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "analytics.analytics_cms_active_dce_participants" */
export type Analytics_Analytics_Cms_Active_Dce_Participants_Stddev_Order_By = {
  cms_dce_participant_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Analytics_Analytics_Cms_Active_Dce_Participants_Stddev_Pop_Fields = {
  __typename?: 'analytics_analytics_cms_active_dce_participants_stddev_pop_fields';
  cms_dce_participant_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "analytics.analytics_cms_active_dce_participants" */
export type Analytics_Analytics_Cms_Active_Dce_Participants_Stddev_Pop_Order_By = {
  cms_dce_participant_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Analytics_Analytics_Cms_Active_Dce_Participants_Stddev_Samp_Fields = {
  __typename?: 'analytics_analytics_cms_active_dce_participants_stddev_samp_fields';
  cms_dce_participant_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "analytics.analytics_cms_active_dce_participants" */
export type Analytics_Analytics_Cms_Active_Dce_Participants_Stddev_Samp_Order_By = {
  cms_dce_participant_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Analytics_Analytics_Cms_Active_Dce_Participants_Sum_Fields = {
  __typename?: 'analytics_analytics_cms_active_dce_participants_sum_fields';
  cms_dce_participant_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "analytics.analytics_cms_active_dce_participants" */
export type Analytics_Analytics_Cms_Active_Dce_Participants_Sum_Order_By = {
  cms_dce_participant_id?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Analytics_Analytics_Cms_Active_Dce_Participants_Var_Pop_Fields = {
  __typename?: 'analytics_analytics_cms_active_dce_participants_var_pop_fields';
  cms_dce_participant_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "analytics.analytics_cms_active_dce_participants" */
export type Analytics_Analytics_Cms_Active_Dce_Participants_Var_Pop_Order_By = {
  cms_dce_participant_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Analytics_Analytics_Cms_Active_Dce_Participants_Var_Samp_Fields = {
  __typename?: 'analytics_analytics_cms_active_dce_participants_var_samp_fields';
  cms_dce_participant_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "analytics.analytics_cms_active_dce_participants" */
export type Analytics_Analytics_Cms_Active_Dce_Participants_Var_Samp_Order_By = {
  cms_dce_participant_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Analytics_Analytics_Cms_Active_Dce_Participants_Variance_Fields = {
  __typename?: 'analytics_analytics_cms_active_dce_participants_variance_fields';
  cms_dce_participant_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "analytics.analytics_cms_active_dce_participants" */
export type Analytics_Analytics_Cms_Active_Dce_Participants_Variance_Order_By = {
  cms_dce_participant_id?: Maybe<Order_By>;
};


/** expression to compare columns of type bigint. All fields are combined with logical 'AND'. */
export type Bigint_Comparison_Exp = {
  _eq?: Maybe<Scalars['bigint']>;
  _gt?: Maybe<Scalars['bigint']>;
  _gte?: Maybe<Scalars['bigint']>;
  _in?: Maybe<Array<Scalars['bigint']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['bigint']>;
  _lte?: Maybe<Scalars['bigint']>;
  _neq?: Maybe<Scalars['bigint']>;
  _nin?: Maybe<Array<Scalars['bigint']>>;
};

/** columns and relationships of "business_associate_agreements" */
export type Business_Associate_Agreements = {
  __typename?: 'business_associate_agreements';
  created_at: Scalars['timestamptz'];
  external_id: Scalars['String'];
  external_response?: Maybe<Scalars['jsonb']>;
  external_source: Scalars['String'];
  is_archived: Scalars['Boolean'];
  /** An object relationship */
  practice?: Maybe<Practices>;
  practice_id?: Maybe<Scalars['String']>;
  recipient_email?: Maybe<Scalars['String']>;
  recipient_name?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  template_vars?: Maybe<Scalars['jsonb']>;
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "business_associate_agreements" */
export type Business_Associate_AgreementsExternal_ResponseArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "business_associate_agreements" */
export type Business_Associate_AgreementsTemplate_VarsArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "business_associate_agreements" */
export type Business_Associate_Agreements_Aggregate = {
  __typename?: 'business_associate_agreements_aggregate';
  aggregate?: Maybe<Business_Associate_Agreements_Aggregate_Fields>;
  nodes: Array<Business_Associate_Agreements>;
};

/** aggregate fields of "business_associate_agreements" */
export type Business_Associate_Agreements_Aggregate_Fields = {
  __typename?: 'business_associate_agreements_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Business_Associate_Agreements_Max_Fields>;
  min?: Maybe<Business_Associate_Agreements_Min_Fields>;
};


/** aggregate fields of "business_associate_agreements" */
export type Business_Associate_Agreements_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Business_Associate_Agreements_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "business_associate_agreements" */
export type Business_Associate_Agreements_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Business_Associate_Agreements_Max_Order_By>;
  min?: Maybe<Business_Associate_Agreements_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Business_Associate_Agreements_Append_Input = {
  external_response?: Maybe<Scalars['jsonb']>;
  template_vars?: Maybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "business_associate_agreements" */
export type Business_Associate_Agreements_Arr_Rel_Insert_Input = {
  data: Array<Business_Associate_Agreements_Insert_Input>;
  on_conflict?: Maybe<Business_Associate_Agreements_On_Conflict>;
};

/** Boolean expression to filter rows from the table "business_associate_agreements". All fields are combined with a logical 'AND'. */
export type Business_Associate_Agreements_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Business_Associate_Agreements_Bool_Exp>>>;
  _not?: Maybe<Business_Associate_Agreements_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Business_Associate_Agreements_Bool_Exp>>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  external_id?: Maybe<String_Comparison_Exp>;
  external_response?: Maybe<Jsonb_Comparison_Exp>;
  external_source?: Maybe<String_Comparison_Exp>;
  is_archived?: Maybe<Boolean_Comparison_Exp>;
  practice?: Maybe<Practices_Bool_Exp>;
  practice_id?: Maybe<String_Comparison_Exp>;
  recipient_email?: Maybe<String_Comparison_Exp>;
  recipient_name?: Maybe<String_Comparison_Exp>;
  status?: Maybe<String_Comparison_Exp>;
  template_vars?: Maybe<Jsonb_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "business_associate_agreements" */
export enum Business_Associate_Agreements_Constraint {
  /** unique or primary key constraint */
  BusinessAssociateAgreementsPkey = 'business_associate_agreements_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Business_Associate_Agreements_Delete_At_Path_Input = {
  external_response?: Maybe<Array<Maybe<Scalars['String']>>>;
  template_vars?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Business_Associate_Agreements_Delete_Elem_Input = {
  external_response?: Maybe<Scalars['Int']>;
  template_vars?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Business_Associate_Agreements_Delete_Key_Input = {
  external_response?: Maybe<Scalars['String']>;
  template_vars?: Maybe<Scalars['String']>;
};

/** input type for inserting data into table "business_associate_agreements" */
export type Business_Associate_Agreements_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  external_id?: Maybe<Scalars['String']>;
  external_response?: Maybe<Scalars['jsonb']>;
  external_source?: Maybe<Scalars['String']>;
  is_archived?: Maybe<Scalars['Boolean']>;
  practice?: Maybe<Practices_Obj_Rel_Insert_Input>;
  practice_id?: Maybe<Scalars['String']>;
  recipient_email?: Maybe<Scalars['String']>;
  recipient_name?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  template_vars?: Maybe<Scalars['jsonb']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Business_Associate_Agreements_Max_Fields = {
  __typename?: 'business_associate_agreements_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  external_id?: Maybe<Scalars['String']>;
  external_source?: Maybe<Scalars['String']>;
  practice_id?: Maybe<Scalars['String']>;
  recipient_email?: Maybe<Scalars['String']>;
  recipient_name?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "business_associate_agreements" */
export type Business_Associate_Agreements_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  external_id?: Maybe<Order_By>;
  external_source?: Maybe<Order_By>;
  practice_id?: Maybe<Order_By>;
  recipient_email?: Maybe<Order_By>;
  recipient_name?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Business_Associate_Agreements_Min_Fields = {
  __typename?: 'business_associate_agreements_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  external_id?: Maybe<Scalars['String']>;
  external_source?: Maybe<Scalars['String']>;
  practice_id?: Maybe<Scalars['String']>;
  recipient_email?: Maybe<Scalars['String']>;
  recipient_name?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "business_associate_agreements" */
export type Business_Associate_Agreements_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  external_id?: Maybe<Order_By>;
  external_source?: Maybe<Order_By>;
  practice_id?: Maybe<Order_By>;
  recipient_email?: Maybe<Order_By>;
  recipient_name?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "business_associate_agreements" */
export type Business_Associate_Agreements_Mutation_Response = {
  __typename?: 'business_associate_agreements_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Business_Associate_Agreements>;
};

/** input type for inserting object relation for remote table "business_associate_agreements" */
export type Business_Associate_Agreements_Obj_Rel_Insert_Input = {
  data: Business_Associate_Agreements_Insert_Input;
  on_conflict?: Maybe<Business_Associate_Agreements_On_Conflict>;
};

/** on conflict condition type for table "business_associate_agreements" */
export type Business_Associate_Agreements_On_Conflict = {
  constraint: Business_Associate_Agreements_Constraint;
  update_columns: Array<Business_Associate_Agreements_Update_Column>;
  where?: Maybe<Business_Associate_Agreements_Bool_Exp>;
};

/** ordering options when selecting data from "business_associate_agreements" */
export type Business_Associate_Agreements_Order_By = {
  created_at?: Maybe<Order_By>;
  external_id?: Maybe<Order_By>;
  external_response?: Maybe<Order_By>;
  external_source?: Maybe<Order_By>;
  is_archived?: Maybe<Order_By>;
  practice?: Maybe<Practices_Order_By>;
  practice_id?: Maybe<Order_By>;
  recipient_email?: Maybe<Order_By>;
  recipient_name?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  template_vars?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: "business_associate_agreements" */
export type Business_Associate_Agreements_Pk_Columns_Input = {
  external_id: Scalars['String'];
  external_source: Scalars['String'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Business_Associate_Agreements_Prepend_Input = {
  external_response?: Maybe<Scalars['jsonb']>;
  template_vars?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "business_associate_agreements" */
export enum Business_Associate_Agreements_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ExternalId = 'external_id',
  /** column name */
  ExternalResponse = 'external_response',
  /** column name */
  ExternalSource = 'external_source',
  /** column name */
  IsArchived = 'is_archived',
  /** column name */
  PracticeId = 'practice_id',
  /** column name */
  RecipientEmail = 'recipient_email',
  /** column name */
  RecipientName = 'recipient_name',
  /** column name */
  Status = 'status',
  /** column name */
  TemplateVars = 'template_vars',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "business_associate_agreements" */
export type Business_Associate_Agreements_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  external_id?: Maybe<Scalars['String']>;
  external_response?: Maybe<Scalars['jsonb']>;
  external_source?: Maybe<Scalars['String']>;
  is_archived?: Maybe<Scalars['Boolean']>;
  practice_id?: Maybe<Scalars['String']>;
  recipient_email?: Maybe<Scalars['String']>;
  recipient_name?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  template_vars?: Maybe<Scalars['jsonb']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "business_associate_agreements" */
export enum Business_Associate_Agreements_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ExternalId = 'external_id',
  /** column name */
  ExternalResponse = 'external_response',
  /** column name */
  ExternalSource = 'external_source',
  /** column name */
  IsArchived = 'is_archived',
  /** column name */
  PracticeId = 'practice_id',
  /** column name */
  RecipientEmail = 'recipient_email',
  /** column name */
  RecipientName = 'recipient_name',
  /** column name */
  Status = 'status',
  /** column name */
  TemplateVars = 'template_vars',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** columns and relationships of "campaign_approvals" */
export type Campaign_Approvals = {
  __typename?: 'campaign_approvals';
  approved_by: Scalars['String'];
  /** An object relationship */
  approver: Users;
  /** An object relationship */
  campaign_template: Campaign_Templates;
  campaign_template_id: Scalars['String'];
  created_at: Scalars['timestamptz'];
  /** An array relationship */
  email_component_approvals: Array<Email_Component_Approvals>;
  /** An aggregated array relationship */
  email_component_approvals_aggregate: Email_Component_Approvals_Aggregate;
  /** An array relationship */
  letter_component_approvals: Array<Letter_Component_Approvals>;
  /** An aggregated array relationship */
  letter_component_approvals_aggregate: Letter_Component_Approvals_Aggregate;
  /** An object relationship */
  practice: Practices;
  practice_id: Scalars['String'];
  /** An array relationship */
  sms_component_approvals: Array<Sms_Component_Approvals>;
  /** An aggregated array relationship */
  sms_component_approvals_aggregate: Sms_Component_Approvals_Aggregate;
  updated_at?: Maybe<Scalars['timestamptz']>;
};


/** columns and relationships of "campaign_approvals" */
export type Campaign_ApprovalsEmail_Component_ApprovalsArgs = {
  distinct_on?: Maybe<Array<Email_Component_Approvals_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Email_Component_Approvals_Order_By>>;
  where?: Maybe<Email_Component_Approvals_Bool_Exp>;
};


/** columns and relationships of "campaign_approvals" */
export type Campaign_ApprovalsEmail_Component_Approvals_AggregateArgs = {
  distinct_on?: Maybe<Array<Email_Component_Approvals_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Email_Component_Approvals_Order_By>>;
  where?: Maybe<Email_Component_Approvals_Bool_Exp>;
};


/** columns and relationships of "campaign_approvals" */
export type Campaign_ApprovalsLetter_Component_ApprovalsArgs = {
  distinct_on?: Maybe<Array<Letter_Component_Approvals_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Letter_Component_Approvals_Order_By>>;
  where?: Maybe<Letter_Component_Approvals_Bool_Exp>;
};


/** columns and relationships of "campaign_approvals" */
export type Campaign_ApprovalsLetter_Component_Approvals_AggregateArgs = {
  distinct_on?: Maybe<Array<Letter_Component_Approvals_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Letter_Component_Approvals_Order_By>>;
  where?: Maybe<Letter_Component_Approvals_Bool_Exp>;
};


/** columns and relationships of "campaign_approvals" */
export type Campaign_ApprovalsSms_Component_ApprovalsArgs = {
  distinct_on?: Maybe<Array<Sms_Component_Approvals_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Sms_Component_Approvals_Order_By>>;
  where?: Maybe<Sms_Component_Approvals_Bool_Exp>;
};


/** columns and relationships of "campaign_approvals" */
export type Campaign_ApprovalsSms_Component_Approvals_AggregateArgs = {
  distinct_on?: Maybe<Array<Sms_Component_Approvals_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Sms_Component_Approvals_Order_By>>;
  where?: Maybe<Sms_Component_Approvals_Bool_Exp>;
};

/** aggregated selection of "campaign_approvals" */
export type Campaign_Approvals_Aggregate = {
  __typename?: 'campaign_approvals_aggregate';
  aggregate?: Maybe<Campaign_Approvals_Aggregate_Fields>;
  nodes: Array<Campaign_Approvals>;
};

/** aggregate fields of "campaign_approvals" */
export type Campaign_Approvals_Aggregate_Fields = {
  __typename?: 'campaign_approvals_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Campaign_Approvals_Max_Fields>;
  min?: Maybe<Campaign_Approvals_Min_Fields>;
};


/** aggregate fields of "campaign_approvals" */
export type Campaign_Approvals_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Campaign_Approvals_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "campaign_approvals" */
export type Campaign_Approvals_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Campaign_Approvals_Max_Order_By>;
  min?: Maybe<Campaign_Approvals_Min_Order_By>;
};

/** input type for inserting array relation for remote table "campaign_approvals" */
export type Campaign_Approvals_Arr_Rel_Insert_Input = {
  data: Array<Campaign_Approvals_Insert_Input>;
  on_conflict?: Maybe<Campaign_Approvals_On_Conflict>;
};

/** Boolean expression to filter rows from the table "campaign_approvals". All fields are combined with a logical 'AND'. */
export type Campaign_Approvals_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Campaign_Approvals_Bool_Exp>>>;
  _not?: Maybe<Campaign_Approvals_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Campaign_Approvals_Bool_Exp>>>;
  approved_by?: Maybe<String_Comparison_Exp>;
  approver?: Maybe<Users_Bool_Exp>;
  campaign_template?: Maybe<Campaign_Templates_Bool_Exp>;
  campaign_template_id?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  email_component_approvals?: Maybe<Email_Component_Approvals_Bool_Exp>;
  letter_component_approvals?: Maybe<Letter_Component_Approvals_Bool_Exp>;
  practice?: Maybe<Practices_Bool_Exp>;
  practice_id?: Maybe<String_Comparison_Exp>;
  sms_component_approvals?: Maybe<Sms_Component_Approvals_Bool_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "campaign_approvals" */
export enum Campaign_Approvals_Constraint {
  /** unique or primary key constraint */
  CampaignInstancesPkey = 'campaign_instances_pkey'
}

/** input type for inserting data into table "campaign_approvals" */
export type Campaign_Approvals_Insert_Input = {
  approved_by?: Maybe<Scalars['String']>;
  approver?: Maybe<Users_Obj_Rel_Insert_Input>;
  campaign_template?: Maybe<Campaign_Templates_Obj_Rel_Insert_Input>;
  campaign_template_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  email_component_approvals?: Maybe<Email_Component_Approvals_Arr_Rel_Insert_Input>;
  letter_component_approvals?: Maybe<Letter_Component_Approvals_Arr_Rel_Insert_Input>;
  practice?: Maybe<Practices_Obj_Rel_Insert_Input>;
  practice_id?: Maybe<Scalars['String']>;
  sms_component_approvals?: Maybe<Sms_Component_Approvals_Arr_Rel_Insert_Input>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Campaign_Approvals_Max_Fields = {
  __typename?: 'campaign_approvals_max_fields';
  approved_by?: Maybe<Scalars['String']>;
  campaign_template_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  practice_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "campaign_approvals" */
export type Campaign_Approvals_Max_Order_By = {
  approved_by?: Maybe<Order_By>;
  campaign_template_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  practice_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Campaign_Approvals_Min_Fields = {
  __typename?: 'campaign_approvals_min_fields';
  approved_by?: Maybe<Scalars['String']>;
  campaign_template_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  practice_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "campaign_approvals" */
export type Campaign_Approvals_Min_Order_By = {
  approved_by?: Maybe<Order_By>;
  campaign_template_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  practice_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "campaign_approvals" */
export type Campaign_Approvals_Mutation_Response = {
  __typename?: 'campaign_approvals_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Campaign_Approvals>;
};

/** input type for inserting object relation for remote table "campaign_approvals" */
export type Campaign_Approvals_Obj_Rel_Insert_Input = {
  data: Campaign_Approvals_Insert_Input;
  on_conflict?: Maybe<Campaign_Approvals_On_Conflict>;
};

/** on conflict condition type for table "campaign_approvals" */
export type Campaign_Approvals_On_Conflict = {
  constraint: Campaign_Approvals_Constraint;
  update_columns: Array<Campaign_Approvals_Update_Column>;
  where?: Maybe<Campaign_Approvals_Bool_Exp>;
};

/** ordering options when selecting data from "campaign_approvals" */
export type Campaign_Approvals_Order_By = {
  approved_by?: Maybe<Order_By>;
  approver?: Maybe<Users_Order_By>;
  campaign_template?: Maybe<Campaign_Templates_Order_By>;
  campaign_template_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  email_component_approvals_aggregate?: Maybe<Email_Component_Approvals_Aggregate_Order_By>;
  letter_component_approvals_aggregate?: Maybe<Letter_Component_Approvals_Aggregate_Order_By>;
  practice?: Maybe<Practices_Order_By>;
  practice_id?: Maybe<Order_By>;
  sms_component_approvals_aggregate?: Maybe<Sms_Component_Approvals_Aggregate_Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: "campaign_approvals" */
export type Campaign_Approvals_Pk_Columns_Input = {
  campaign_template_id: Scalars['String'];
  practice_id: Scalars['String'];
};

/** select columns of table "campaign_approvals" */
export enum Campaign_Approvals_Select_Column {
  /** column name */
  ApprovedBy = 'approved_by',
  /** column name */
  CampaignTemplateId = 'campaign_template_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  PracticeId = 'practice_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "campaign_approvals" */
export type Campaign_Approvals_Set_Input = {
  approved_by?: Maybe<Scalars['String']>;
  campaign_template_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  practice_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "campaign_approvals" */
export enum Campaign_Approvals_Update_Column {
  /** column name */
  ApprovedBy = 'approved_by',
  /** column name */
  CampaignTemplateId = 'campaign_template_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  PracticeId = 'practice_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/**
 * The email templates associated with a particular campaign template
 * 
 * 
 * columns and relationships of "campaign_email_components"
 */
export type Campaign_Email_Components = {
  __typename?: 'campaign_email_components';
  campaign_email_component_id: Scalars['String'];
  /** An object relationship */
  campaign_template: Campaign_Templates;
  campaign_template_id: Scalars['String'];
  component_merge_vars?: Maybe<Scalars['jsonb']>;
  created_at: Scalars['timestamptz'];
  /** An array relationship */
  email_component_approvals: Array<Email_Component_Approvals>;
  /** An aggregated array relationship */
  email_component_approvals_aggregate: Email_Component_Approvals_Aggregate;
  /** An object relationship */
  email_template: Email_Templates;
  email_template_id: Scalars['String'];
  is_archived: Scalars['Boolean'];
  /** An object relationship */
  patient_segment: Patient_Segments;
  patient_segment_id: Scalars['String'];
  send_datetime: Scalars['timestamptz'];
  /** An array relationship */
  tracking_ids: Array<Component_Tracking_Ids>;
  /** An aggregated array relationship */
  tracking_ids_aggregate: Component_Tracking_Ids_Aggregate;
  updated_at: Scalars['timestamptz'];
};


/**
 * The email templates associated with a particular campaign template
 * 
 * 
 * columns and relationships of "campaign_email_components"
 */
export type Campaign_Email_ComponentsComponent_Merge_VarsArgs = {
  path?: Maybe<Scalars['String']>;
};


/**
 * The email templates associated with a particular campaign template
 * 
 * 
 * columns and relationships of "campaign_email_components"
 */
export type Campaign_Email_ComponentsEmail_Component_ApprovalsArgs = {
  distinct_on?: Maybe<Array<Email_Component_Approvals_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Email_Component_Approvals_Order_By>>;
  where?: Maybe<Email_Component_Approvals_Bool_Exp>;
};


/**
 * The email templates associated with a particular campaign template
 * 
 * 
 * columns and relationships of "campaign_email_components"
 */
export type Campaign_Email_ComponentsEmail_Component_Approvals_AggregateArgs = {
  distinct_on?: Maybe<Array<Email_Component_Approvals_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Email_Component_Approvals_Order_By>>;
  where?: Maybe<Email_Component_Approvals_Bool_Exp>;
};


/**
 * The email templates associated with a particular campaign template
 * 
 * 
 * columns and relationships of "campaign_email_components"
 */
export type Campaign_Email_ComponentsTracking_IdsArgs = {
  distinct_on?: Maybe<Array<Component_Tracking_Ids_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Component_Tracking_Ids_Order_By>>;
  where?: Maybe<Component_Tracking_Ids_Bool_Exp>;
};


/**
 * The email templates associated with a particular campaign template
 * 
 * 
 * columns and relationships of "campaign_email_components"
 */
export type Campaign_Email_ComponentsTracking_Ids_AggregateArgs = {
  distinct_on?: Maybe<Array<Component_Tracking_Ids_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Component_Tracking_Ids_Order_By>>;
  where?: Maybe<Component_Tracking_Ids_Bool_Exp>;
};

/** aggregated selection of "campaign_email_components" */
export type Campaign_Email_Components_Aggregate = {
  __typename?: 'campaign_email_components_aggregate';
  aggregate?: Maybe<Campaign_Email_Components_Aggregate_Fields>;
  nodes: Array<Campaign_Email_Components>;
};

/** aggregate fields of "campaign_email_components" */
export type Campaign_Email_Components_Aggregate_Fields = {
  __typename?: 'campaign_email_components_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Campaign_Email_Components_Max_Fields>;
  min?: Maybe<Campaign_Email_Components_Min_Fields>;
};


/** aggregate fields of "campaign_email_components" */
export type Campaign_Email_Components_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Campaign_Email_Components_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "campaign_email_components" */
export type Campaign_Email_Components_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Campaign_Email_Components_Max_Order_By>;
  min?: Maybe<Campaign_Email_Components_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Campaign_Email_Components_Append_Input = {
  component_merge_vars?: Maybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "campaign_email_components" */
export type Campaign_Email_Components_Arr_Rel_Insert_Input = {
  data: Array<Campaign_Email_Components_Insert_Input>;
  on_conflict?: Maybe<Campaign_Email_Components_On_Conflict>;
};

/** Boolean expression to filter rows from the table "campaign_email_components". All fields are combined with a logical 'AND'. */
export type Campaign_Email_Components_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Campaign_Email_Components_Bool_Exp>>>;
  _not?: Maybe<Campaign_Email_Components_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Campaign_Email_Components_Bool_Exp>>>;
  campaign_email_component_id?: Maybe<String_Comparison_Exp>;
  campaign_template?: Maybe<Campaign_Templates_Bool_Exp>;
  campaign_template_id?: Maybe<String_Comparison_Exp>;
  component_merge_vars?: Maybe<Jsonb_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  email_component_approvals?: Maybe<Email_Component_Approvals_Bool_Exp>;
  email_template?: Maybe<Email_Templates_Bool_Exp>;
  email_template_id?: Maybe<String_Comparison_Exp>;
  is_archived?: Maybe<Boolean_Comparison_Exp>;
  patient_segment?: Maybe<Patient_Segments_Bool_Exp>;
  patient_segment_id?: Maybe<String_Comparison_Exp>;
  send_datetime?: Maybe<Timestamptz_Comparison_Exp>;
  tracking_ids?: Maybe<Component_Tracking_Ids_Bool_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "campaign_email_components" */
export enum Campaign_Email_Components_Constraint {
  /** unique or primary key constraint */
  CampaignEmailComponentsPkey = 'campaign_email_components_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Campaign_Email_Components_Delete_At_Path_Input = {
  component_merge_vars?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Campaign_Email_Components_Delete_Elem_Input = {
  component_merge_vars?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Campaign_Email_Components_Delete_Key_Input = {
  component_merge_vars?: Maybe<Scalars['String']>;
};

/** input type for inserting data into table "campaign_email_components" */
export type Campaign_Email_Components_Insert_Input = {
  campaign_email_component_id?: Maybe<Scalars['String']>;
  campaign_template?: Maybe<Campaign_Templates_Obj_Rel_Insert_Input>;
  campaign_template_id?: Maybe<Scalars['String']>;
  component_merge_vars?: Maybe<Scalars['jsonb']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  email_component_approvals?: Maybe<Email_Component_Approvals_Arr_Rel_Insert_Input>;
  email_template?: Maybe<Email_Templates_Obj_Rel_Insert_Input>;
  email_template_id?: Maybe<Scalars['String']>;
  is_archived?: Maybe<Scalars['Boolean']>;
  patient_segment?: Maybe<Patient_Segments_Obj_Rel_Insert_Input>;
  patient_segment_id?: Maybe<Scalars['String']>;
  send_datetime?: Maybe<Scalars['timestamptz']>;
  tracking_ids?: Maybe<Component_Tracking_Ids_Arr_Rel_Insert_Input>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Campaign_Email_Components_Max_Fields = {
  __typename?: 'campaign_email_components_max_fields';
  campaign_email_component_id?: Maybe<Scalars['String']>;
  campaign_template_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  email_template_id?: Maybe<Scalars['String']>;
  patient_segment_id?: Maybe<Scalars['String']>;
  send_datetime?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "campaign_email_components" */
export type Campaign_Email_Components_Max_Order_By = {
  campaign_email_component_id?: Maybe<Order_By>;
  campaign_template_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  email_template_id?: Maybe<Order_By>;
  patient_segment_id?: Maybe<Order_By>;
  send_datetime?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Campaign_Email_Components_Min_Fields = {
  __typename?: 'campaign_email_components_min_fields';
  campaign_email_component_id?: Maybe<Scalars['String']>;
  campaign_template_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  email_template_id?: Maybe<Scalars['String']>;
  patient_segment_id?: Maybe<Scalars['String']>;
  send_datetime?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "campaign_email_components" */
export type Campaign_Email_Components_Min_Order_By = {
  campaign_email_component_id?: Maybe<Order_By>;
  campaign_template_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  email_template_id?: Maybe<Order_By>;
  patient_segment_id?: Maybe<Order_By>;
  send_datetime?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "campaign_email_components" */
export type Campaign_Email_Components_Mutation_Response = {
  __typename?: 'campaign_email_components_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Campaign_Email_Components>;
};

/** input type for inserting object relation for remote table "campaign_email_components" */
export type Campaign_Email_Components_Obj_Rel_Insert_Input = {
  data: Campaign_Email_Components_Insert_Input;
  on_conflict?: Maybe<Campaign_Email_Components_On_Conflict>;
};

/** on conflict condition type for table "campaign_email_components" */
export type Campaign_Email_Components_On_Conflict = {
  constraint: Campaign_Email_Components_Constraint;
  update_columns: Array<Campaign_Email_Components_Update_Column>;
  where?: Maybe<Campaign_Email_Components_Bool_Exp>;
};

/** ordering options when selecting data from "campaign_email_components" */
export type Campaign_Email_Components_Order_By = {
  campaign_email_component_id?: Maybe<Order_By>;
  campaign_template?: Maybe<Campaign_Templates_Order_By>;
  campaign_template_id?: Maybe<Order_By>;
  component_merge_vars?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  email_component_approvals_aggregate?: Maybe<Email_Component_Approvals_Aggregate_Order_By>;
  email_template?: Maybe<Email_Templates_Order_By>;
  email_template_id?: Maybe<Order_By>;
  is_archived?: Maybe<Order_By>;
  patient_segment?: Maybe<Patient_Segments_Order_By>;
  patient_segment_id?: Maybe<Order_By>;
  send_datetime?: Maybe<Order_By>;
  tracking_ids_aggregate?: Maybe<Component_Tracking_Ids_Aggregate_Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: "campaign_email_components" */
export type Campaign_Email_Components_Pk_Columns_Input = {
  campaign_email_component_id: Scalars['String'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Campaign_Email_Components_Prepend_Input = {
  component_merge_vars?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "campaign_email_components" */
export enum Campaign_Email_Components_Select_Column {
  /** column name */
  CampaignEmailComponentId = 'campaign_email_component_id',
  /** column name */
  CampaignTemplateId = 'campaign_template_id',
  /** column name */
  ComponentMergeVars = 'component_merge_vars',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EmailTemplateId = 'email_template_id',
  /** column name */
  IsArchived = 'is_archived',
  /** column name */
  PatientSegmentId = 'patient_segment_id',
  /** column name */
  SendDatetime = 'send_datetime',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "campaign_email_components" */
export type Campaign_Email_Components_Set_Input = {
  campaign_email_component_id?: Maybe<Scalars['String']>;
  campaign_template_id?: Maybe<Scalars['String']>;
  component_merge_vars?: Maybe<Scalars['jsonb']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  email_template_id?: Maybe<Scalars['String']>;
  is_archived?: Maybe<Scalars['Boolean']>;
  patient_segment_id?: Maybe<Scalars['String']>;
  send_datetime?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "campaign_email_components" */
export enum Campaign_Email_Components_Update_Column {
  /** column name */
  CampaignEmailComponentId = 'campaign_email_component_id',
  /** column name */
  CampaignTemplateId = 'campaign_template_id',
  /** column name */
  ComponentMergeVars = 'component_merge_vars',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EmailTemplateId = 'email_template_id',
  /** column name */
  IsArchived = 'is_archived',
  /** column name */
  PatientSegmentId = 'patient_segment_id',
  /** column name */
  SendDatetime = 'send_datetime',
  /** column name */
  UpdatedAt = 'updated_at'
}

/**
 * The letter templates associated with a particular campaign template
 * 
 * 
 * columns and relationships of "campaign_letter_components"
 */
export type Campaign_Letter_Components = {
  __typename?: 'campaign_letter_components';
  campaign_letter_component_id: Scalars['String'];
  /** An object relationship */
  campaign_template: Campaign_Templates;
  campaign_template_id: Scalars['String'];
  component_merge_vars?: Maybe<Scalars['jsonb']>;
  created_at: Scalars['timestamptz'];
  custom_envelope_id?: Maybe<Scalars['String']>;
  is_archived: Scalars['Boolean'];
  is_first_class: Scalars['Boolean'];
  /** An array relationship */
  letter_component_approvals: Array<Letter_Component_Approvals>;
  /** An aggregated array relationship */
  letter_component_approvals_aggregate: Letter_Component_Approvals_Aggregate;
  /** An object relationship */
  letter_template: Letter_Templates;
  letter_template_id: Scalars['String'];
  /** An object relationship */
  patient_segment: Patient_Segments;
  patient_segment_id: Scalars['String'];
  return_envelope_id?: Maybe<Scalars['String']>;
  send_datetime: Scalars['timestamptz'];
  skip_undeliverable_addresses: Scalars['Boolean'];
  /** An array relationship */
  tracking_ids: Array<Component_Tracking_Ids>;
  /** An aggregated array relationship */
  tracking_ids_aggregate: Component_Tracking_Ids_Aggregate;
  updated_at: Scalars['timestamptz'];
};


/**
 * The letter templates associated with a particular campaign template
 * 
 * 
 * columns and relationships of "campaign_letter_components"
 */
export type Campaign_Letter_ComponentsComponent_Merge_VarsArgs = {
  path?: Maybe<Scalars['String']>;
};


/**
 * The letter templates associated with a particular campaign template
 * 
 * 
 * columns and relationships of "campaign_letter_components"
 */
export type Campaign_Letter_ComponentsLetter_Component_ApprovalsArgs = {
  distinct_on?: Maybe<Array<Letter_Component_Approvals_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Letter_Component_Approvals_Order_By>>;
  where?: Maybe<Letter_Component_Approvals_Bool_Exp>;
};


/**
 * The letter templates associated with a particular campaign template
 * 
 * 
 * columns and relationships of "campaign_letter_components"
 */
export type Campaign_Letter_ComponentsLetter_Component_Approvals_AggregateArgs = {
  distinct_on?: Maybe<Array<Letter_Component_Approvals_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Letter_Component_Approvals_Order_By>>;
  where?: Maybe<Letter_Component_Approvals_Bool_Exp>;
};


/**
 * The letter templates associated with a particular campaign template
 * 
 * 
 * columns and relationships of "campaign_letter_components"
 */
export type Campaign_Letter_ComponentsTracking_IdsArgs = {
  distinct_on?: Maybe<Array<Component_Tracking_Ids_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Component_Tracking_Ids_Order_By>>;
  where?: Maybe<Component_Tracking_Ids_Bool_Exp>;
};


/**
 * The letter templates associated with a particular campaign template
 * 
 * 
 * columns and relationships of "campaign_letter_components"
 */
export type Campaign_Letter_ComponentsTracking_Ids_AggregateArgs = {
  distinct_on?: Maybe<Array<Component_Tracking_Ids_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Component_Tracking_Ids_Order_By>>;
  where?: Maybe<Component_Tracking_Ids_Bool_Exp>;
};

/** aggregated selection of "campaign_letter_components" */
export type Campaign_Letter_Components_Aggregate = {
  __typename?: 'campaign_letter_components_aggregate';
  aggregate?: Maybe<Campaign_Letter_Components_Aggregate_Fields>;
  nodes: Array<Campaign_Letter_Components>;
};

/** aggregate fields of "campaign_letter_components" */
export type Campaign_Letter_Components_Aggregate_Fields = {
  __typename?: 'campaign_letter_components_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Campaign_Letter_Components_Max_Fields>;
  min?: Maybe<Campaign_Letter_Components_Min_Fields>;
};


/** aggregate fields of "campaign_letter_components" */
export type Campaign_Letter_Components_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Campaign_Letter_Components_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "campaign_letter_components" */
export type Campaign_Letter_Components_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Campaign_Letter_Components_Max_Order_By>;
  min?: Maybe<Campaign_Letter_Components_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Campaign_Letter_Components_Append_Input = {
  component_merge_vars?: Maybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "campaign_letter_components" */
export type Campaign_Letter_Components_Arr_Rel_Insert_Input = {
  data: Array<Campaign_Letter_Components_Insert_Input>;
  on_conflict?: Maybe<Campaign_Letter_Components_On_Conflict>;
};

/** Boolean expression to filter rows from the table "campaign_letter_components". All fields are combined with a logical 'AND'. */
export type Campaign_Letter_Components_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Campaign_Letter_Components_Bool_Exp>>>;
  _not?: Maybe<Campaign_Letter_Components_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Campaign_Letter_Components_Bool_Exp>>>;
  campaign_letter_component_id?: Maybe<String_Comparison_Exp>;
  campaign_template?: Maybe<Campaign_Templates_Bool_Exp>;
  campaign_template_id?: Maybe<String_Comparison_Exp>;
  component_merge_vars?: Maybe<Jsonb_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  custom_envelope_id?: Maybe<String_Comparison_Exp>;
  is_archived?: Maybe<Boolean_Comparison_Exp>;
  is_first_class?: Maybe<Boolean_Comparison_Exp>;
  letter_component_approvals?: Maybe<Letter_Component_Approvals_Bool_Exp>;
  letter_template?: Maybe<Letter_Templates_Bool_Exp>;
  letter_template_id?: Maybe<String_Comparison_Exp>;
  patient_segment?: Maybe<Patient_Segments_Bool_Exp>;
  patient_segment_id?: Maybe<String_Comparison_Exp>;
  return_envelope_id?: Maybe<String_Comparison_Exp>;
  send_datetime?: Maybe<Timestamptz_Comparison_Exp>;
  skip_undeliverable_addresses?: Maybe<Boolean_Comparison_Exp>;
  tracking_ids?: Maybe<Component_Tracking_Ids_Bool_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "campaign_letter_components" */
export enum Campaign_Letter_Components_Constraint {
  /** unique or primary key constraint */
  CampaignLetterComponentsPkey = 'campaign_letter_components_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Campaign_Letter_Components_Delete_At_Path_Input = {
  component_merge_vars?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Campaign_Letter_Components_Delete_Elem_Input = {
  component_merge_vars?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Campaign_Letter_Components_Delete_Key_Input = {
  component_merge_vars?: Maybe<Scalars['String']>;
};

/** input type for inserting data into table "campaign_letter_components" */
export type Campaign_Letter_Components_Insert_Input = {
  campaign_letter_component_id?: Maybe<Scalars['String']>;
  campaign_template?: Maybe<Campaign_Templates_Obj_Rel_Insert_Input>;
  campaign_template_id?: Maybe<Scalars['String']>;
  component_merge_vars?: Maybe<Scalars['jsonb']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  custom_envelope_id?: Maybe<Scalars['String']>;
  is_archived?: Maybe<Scalars['Boolean']>;
  is_first_class?: Maybe<Scalars['Boolean']>;
  letter_component_approvals?: Maybe<Letter_Component_Approvals_Arr_Rel_Insert_Input>;
  letter_template?: Maybe<Letter_Templates_Obj_Rel_Insert_Input>;
  letter_template_id?: Maybe<Scalars['String']>;
  patient_segment?: Maybe<Patient_Segments_Obj_Rel_Insert_Input>;
  patient_segment_id?: Maybe<Scalars['String']>;
  return_envelope_id?: Maybe<Scalars['String']>;
  send_datetime?: Maybe<Scalars['timestamptz']>;
  skip_undeliverable_addresses?: Maybe<Scalars['Boolean']>;
  tracking_ids?: Maybe<Component_Tracking_Ids_Arr_Rel_Insert_Input>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Campaign_Letter_Components_Max_Fields = {
  __typename?: 'campaign_letter_components_max_fields';
  campaign_letter_component_id?: Maybe<Scalars['String']>;
  campaign_template_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  custom_envelope_id?: Maybe<Scalars['String']>;
  letter_template_id?: Maybe<Scalars['String']>;
  patient_segment_id?: Maybe<Scalars['String']>;
  return_envelope_id?: Maybe<Scalars['String']>;
  send_datetime?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "campaign_letter_components" */
export type Campaign_Letter_Components_Max_Order_By = {
  campaign_letter_component_id?: Maybe<Order_By>;
  campaign_template_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  custom_envelope_id?: Maybe<Order_By>;
  letter_template_id?: Maybe<Order_By>;
  patient_segment_id?: Maybe<Order_By>;
  return_envelope_id?: Maybe<Order_By>;
  send_datetime?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Campaign_Letter_Components_Min_Fields = {
  __typename?: 'campaign_letter_components_min_fields';
  campaign_letter_component_id?: Maybe<Scalars['String']>;
  campaign_template_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  custom_envelope_id?: Maybe<Scalars['String']>;
  letter_template_id?: Maybe<Scalars['String']>;
  patient_segment_id?: Maybe<Scalars['String']>;
  return_envelope_id?: Maybe<Scalars['String']>;
  send_datetime?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "campaign_letter_components" */
export type Campaign_Letter_Components_Min_Order_By = {
  campaign_letter_component_id?: Maybe<Order_By>;
  campaign_template_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  custom_envelope_id?: Maybe<Order_By>;
  letter_template_id?: Maybe<Order_By>;
  patient_segment_id?: Maybe<Order_By>;
  return_envelope_id?: Maybe<Order_By>;
  send_datetime?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "campaign_letter_components" */
export type Campaign_Letter_Components_Mutation_Response = {
  __typename?: 'campaign_letter_components_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Campaign_Letter_Components>;
};

/** input type for inserting object relation for remote table "campaign_letter_components" */
export type Campaign_Letter_Components_Obj_Rel_Insert_Input = {
  data: Campaign_Letter_Components_Insert_Input;
  on_conflict?: Maybe<Campaign_Letter_Components_On_Conflict>;
};

/** on conflict condition type for table "campaign_letter_components" */
export type Campaign_Letter_Components_On_Conflict = {
  constraint: Campaign_Letter_Components_Constraint;
  update_columns: Array<Campaign_Letter_Components_Update_Column>;
  where?: Maybe<Campaign_Letter_Components_Bool_Exp>;
};

/** ordering options when selecting data from "campaign_letter_components" */
export type Campaign_Letter_Components_Order_By = {
  campaign_letter_component_id?: Maybe<Order_By>;
  campaign_template?: Maybe<Campaign_Templates_Order_By>;
  campaign_template_id?: Maybe<Order_By>;
  component_merge_vars?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  custom_envelope_id?: Maybe<Order_By>;
  is_archived?: Maybe<Order_By>;
  is_first_class?: Maybe<Order_By>;
  letter_component_approvals_aggregate?: Maybe<Letter_Component_Approvals_Aggregate_Order_By>;
  letter_template?: Maybe<Letter_Templates_Order_By>;
  letter_template_id?: Maybe<Order_By>;
  patient_segment?: Maybe<Patient_Segments_Order_By>;
  patient_segment_id?: Maybe<Order_By>;
  return_envelope_id?: Maybe<Order_By>;
  send_datetime?: Maybe<Order_By>;
  skip_undeliverable_addresses?: Maybe<Order_By>;
  tracking_ids_aggregate?: Maybe<Component_Tracking_Ids_Aggregate_Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: "campaign_letter_components" */
export type Campaign_Letter_Components_Pk_Columns_Input = {
  campaign_letter_component_id: Scalars['String'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Campaign_Letter_Components_Prepend_Input = {
  component_merge_vars?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "campaign_letter_components" */
export enum Campaign_Letter_Components_Select_Column {
  /** column name */
  CampaignLetterComponentId = 'campaign_letter_component_id',
  /** column name */
  CampaignTemplateId = 'campaign_template_id',
  /** column name */
  ComponentMergeVars = 'component_merge_vars',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomEnvelopeId = 'custom_envelope_id',
  /** column name */
  IsArchived = 'is_archived',
  /** column name */
  IsFirstClass = 'is_first_class',
  /** column name */
  LetterTemplateId = 'letter_template_id',
  /** column name */
  PatientSegmentId = 'patient_segment_id',
  /** column name */
  ReturnEnvelopeId = 'return_envelope_id',
  /** column name */
  SendDatetime = 'send_datetime',
  /** column name */
  SkipUndeliverableAddresses = 'skip_undeliverable_addresses',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "campaign_letter_components" */
export type Campaign_Letter_Components_Set_Input = {
  campaign_letter_component_id?: Maybe<Scalars['String']>;
  campaign_template_id?: Maybe<Scalars['String']>;
  component_merge_vars?: Maybe<Scalars['jsonb']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  custom_envelope_id?: Maybe<Scalars['String']>;
  is_archived?: Maybe<Scalars['Boolean']>;
  is_first_class?: Maybe<Scalars['Boolean']>;
  letter_template_id?: Maybe<Scalars['String']>;
  patient_segment_id?: Maybe<Scalars['String']>;
  return_envelope_id?: Maybe<Scalars['String']>;
  send_datetime?: Maybe<Scalars['timestamptz']>;
  skip_undeliverable_addresses?: Maybe<Scalars['Boolean']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "campaign_letter_components" */
export enum Campaign_Letter_Components_Update_Column {
  /** column name */
  CampaignLetterComponentId = 'campaign_letter_component_id',
  /** column name */
  CampaignTemplateId = 'campaign_template_id',
  /** column name */
  ComponentMergeVars = 'component_merge_vars',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomEnvelopeId = 'custom_envelope_id',
  /** column name */
  IsArchived = 'is_archived',
  /** column name */
  IsFirstClass = 'is_first_class',
  /** column name */
  LetterTemplateId = 'letter_template_id',
  /** column name */
  PatientSegmentId = 'patient_segment_id',
  /** column name */
  ReturnEnvelopeId = 'return_envelope_id',
  /** column name */
  SendDatetime = 'send_datetime',
  /** column name */
  SkipUndeliverableAddresses = 'skip_undeliverable_addresses',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** columns and relationships of "campaign_sms_components" */
export type Campaign_Sms_Components = {
  __typename?: 'campaign_sms_components';
  campaign_sms_component_id: Scalars['String'];
  /** An object relationship */
  campaign_template: Campaign_Templates;
  campaign_template_id: Scalars['String'];
  component_merge_vars?: Maybe<Scalars['jsonb']>;
  created_at: Scalars['timestamptz'];
  is_archived: Scalars['Boolean'];
  /** An object relationship */
  patient_segment: Patient_Segments;
  patient_segment_id: Scalars['String'];
  send_datetime: Scalars['timestamptz'];
  /** An array relationship */
  sms_component_approvals: Array<Sms_Component_Approvals>;
  /** An aggregated array relationship */
  sms_component_approvals_aggregate: Sms_Component_Approvals_Aggregate;
  /** An object relationship */
  sms_template: Sms_Templates;
  sms_template_id: Scalars['String'];
  /** An array relationship */
  tracking_ids: Array<Component_Tracking_Ids>;
  /** An aggregated array relationship */
  tracking_ids_aggregate: Component_Tracking_Ids_Aggregate;
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "campaign_sms_components" */
export type Campaign_Sms_ComponentsComponent_Merge_VarsArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "campaign_sms_components" */
export type Campaign_Sms_ComponentsSms_Component_ApprovalsArgs = {
  distinct_on?: Maybe<Array<Sms_Component_Approvals_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Sms_Component_Approvals_Order_By>>;
  where?: Maybe<Sms_Component_Approvals_Bool_Exp>;
};


/** columns and relationships of "campaign_sms_components" */
export type Campaign_Sms_ComponentsSms_Component_Approvals_AggregateArgs = {
  distinct_on?: Maybe<Array<Sms_Component_Approvals_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Sms_Component_Approvals_Order_By>>;
  where?: Maybe<Sms_Component_Approvals_Bool_Exp>;
};


/** columns and relationships of "campaign_sms_components" */
export type Campaign_Sms_ComponentsTracking_IdsArgs = {
  distinct_on?: Maybe<Array<Component_Tracking_Ids_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Component_Tracking_Ids_Order_By>>;
  where?: Maybe<Component_Tracking_Ids_Bool_Exp>;
};


/** columns and relationships of "campaign_sms_components" */
export type Campaign_Sms_ComponentsTracking_Ids_AggregateArgs = {
  distinct_on?: Maybe<Array<Component_Tracking_Ids_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Component_Tracking_Ids_Order_By>>;
  where?: Maybe<Component_Tracking_Ids_Bool_Exp>;
};

/** aggregated selection of "campaign_sms_components" */
export type Campaign_Sms_Components_Aggregate = {
  __typename?: 'campaign_sms_components_aggregate';
  aggregate?: Maybe<Campaign_Sms_Components_Aggregate_Fields>;
  nodes: Array<Campaign_Sms_Components>;
};

/** aggregate fields of "campaign_sms_components" */
export type Campaign_Sms_Components_Aggregate_Fields = {
  __typename?: 'campaign_sms_components_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Campaign_Sms_Components_Max_Fields>;
  min?: Maybe<Campaign_Sms_Components_Min_Fields>;
};


/** aggregate fields of "campaign_sms_components" */
export type Campaign_Sms_Components_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Campaign_Sms_Components_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "campaign_sms_components" */
export type Campaign_Sms_Components_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Campaign_Sms_Components_Max_Order_By>;
  min?: Maybe<Campaign_Sms_Components_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Campaign_Sms_Components_Append_Input = {
  component_merge_vars?: Maybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "campaign_sms_components" */
export type Campaign_Sms_Components_Arr_Rel_Insert_Input = {
  data: Array<Campaign_Sms_Components_Insert_Input>;
  on_conflict?: Maybe<Campaign_Sms_Components_On_Conflict>;
};

/** Boolean expression to filter rows from the table "campaign_sms_components". All fields are combined with a logical 'AND'. */
export type Campaign_Sms_Components_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Campaign_Sms_Components_Bool_Exp>>>;
  _not?: Maybe<Campaign_Sms_Components_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Campaign_Sms_Components_Bool_Exp>>>;
  campaign_sms_component_id?: Maybe<String_Comparison_Exp>;
  campaign_template?: Maybe<Campaign_Templates_Bool_Exp>;
  campaign_template_id?: Maybe<String_Comparison_Exp>;
  component_merge_vars?: Maybe<Jsonb_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  is_archived?: Maybe<Boolean_Comparison_Exp>;
  patient_segment?: Maybe<Patient_Segments_Bool_Exp>;
  patient_segment_id?: Maybe<String_Comparison_Exp>;
  send_datetime?: Maybe<Timestamptz_Comparison_Exp>;
  sms_component_approvals?: Maybe<Sms_Component_Approvals_Bool_Exp>;
  sms_template?: Maybe<Sms_Templates_Bool_Exp>;
  sms_template_id?: Maybe<String_Comparison_Exp>;
  tracking_ids?: Maybe<Component_Tracking_Ids_Bool_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "campaign_sms_components" */
export enum Campaign_Sms_Components_Constraint {
  /** unique or primary key constraint */
  CampaignSmsComponentsPkey = 'campaign_sms_components_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Campaign_Sms_Components_Delete_At_Path_Input = {
  component_merge_vars?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Campaign_Sms_Components_Delete_Elem_Input = {
  component_merge_vars?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Campaign_Sms_Components_Delete_Key_Input = {
  component_merge_vars?: Maybe<Scalars['String']>;
};

/** input type for inserting data into table "campaign_sms_components" */
export type Campaign_Sms_Components_Insert_Input = {
  campaign_sms_component_id?: Maybe<Scalars['String']>;
  campaign_template?: Maybe<Campaign_Templates_Obj_Rel_Insert_Input>;
  campaign_template_id?: Maybe<Scalars['String']>;
  component_merge_vars?: Maybe<Scalars['jsonb']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  is_archived?: Maybe<Scalars['Boolean']>;
  patient_segment?: Maybe<Patient_Segments_Obj_Rel_Insert_Input>;
  patient_segment_id?: Maybe<Scalars['String']>;
  send_datetime?: Maybe<Scalars['timestamptz']>;
  sms_component_approvals?: Maybe<Sms_Component_Approvals_Arr_Rel_Insert_Input>;
  sms_template?: Maybe<Sms_Templates_Obj_Rel_Insert_Input>;
  sms_template_id?: Maybe<Scalars['String']>;
  tracking_ids?: Maybe<Component_Tracking_Ids_Arr_Rel_Insert_Input>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Campaign_Sms_Components_Max_Fields = {
  __typename?: 'campaign_sms_components_max_fields';
  campaign_sms_component_id?: Maybe<Scalars['String']>;
  campaign_template_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  patient_segment_id?: Maybe<Scalars['String']>;
  send_datetime?: Maybe<Scalars['timestamptz']>;
  sms_template_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "campaign_sms_components" */
export type Campaign_Sms_Components_Max_Order_By = {
  campaign_sms_component_id?: Maybe<Order_By>;
  campaign_template_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  patient_segment_id?: Maybe<Order_By>;
  send_datetime?: Maybe<Order_By>;
  sms_template_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Campaign_Sms_Components_Min_Fields = {
  __typename?: 'campaign_sms_components_min_fields';
  campaign_sms_component_id?: Maybe<Scalars['String']>;
  campaign_template_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  patient_segment_id?: Maybe<Scalars['String']>;
  send_datetime?: Maybe<Scalars['timestamptz']>;
  sms_template_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "campaign_sms_components" */
export type Campaign_Sms_Components_Min_Order_By = {
  campaign_sms_component_id?: Maybe<Order_By>;
  campaign_template_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  patient_segment_id?: Maybe<Order_By>;
  send_datetime?: Maybe<Order_By>;
  sms_template_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "campaign_sms_components" */
export type Campaign_Sms_Components_Mutation_Response = {
  __typename?: 'campaign_sms_components_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Campaign_Sms_Components>;
};

/** input type for inserting object relation for remote table "campaign_sms_components" */
export type Campaign_Sms_Components_Obj_Rel_Insert_Input = {
  data: Campaign_Sms_Components_Insert_Input;
  on_conflict?: Maybe<Campaign_Sms_Components_On_Conflict>;
};

/** on conflict condition type for table "campaign_sms_components" */
export type Campaign_Sms_Components_On_Conflict = {
  constraint: Campaign_Sms_Components_Constraint;
  update_columns: Array<Campaign_Sms_Components_Update_Column>;
  where?: Maybe<Campaign_Sms_Components_Bool_Exp>;
};

/** ordering options when selecting data from "campaign_sms_components" */
export type Campaign_Sms_Components_Order_By = {
  campaign_sms_component_id?: Maybe<Order_By>;
  campaign_template?: Maybe<Campaign_Templates_Order_By>;
  campaign_template_id?: Maybe<Order_By>;
  component_merge_vars?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  is_archived?: Maybe<Order_By>;
  patient_segment?: Maybe<Patient_Segments_Order_By>;
  patient_segment_id?: Maybe<Order_By>;
  send_datetime?: Maybe<Order_By>;
  sms_component_approvals_aggregate?: Maybe<Sms_Component_Approvals_Aggregate_Order_By>;
  sms_template?: Maybe<Sms_Templates_Order_By>;
  sms_template_id?: Maybe<Order_By>;
  tracking_ids_aggregate?: Maybe<Component_Tracking_Ids_Aggregate_Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: "campaign_sms_components" */
export type Campaign_Sms_Components_Pk_Columns_Input = {
  campaign_sms_component_id: Scalars['String'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Campaign_Sms_Components_Prepend_Input = {
  component_merge_vars?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "campaign_sms_components" */
export enum Campaign_Sms_Components_Select_Column {
  /** column name */
  CampaignSmsComponentId = 'campaign_sms_component_id',
  /** column name */
  CampaignTemplateId = 'campaign_template_id',
  /** column name */
  ComponentMergeVars = 'component_merge_vars',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  IsArchived = 'is_archived',
  /** column name */
  PatientSegmentId = 'patient_segment_id',
  /** column name */
  SendDatetime = 'send_datetime',
  /** column name */
  SmsTemplateId = 'sms_template_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "campaign_sms_components" */
export type Campaign_Sms_Components_Set_Input = {
  campaign_sms_component_id?: Maybe<Scalars['String']>;
  campaign_template_id?: Maybe<Scalars['String']>;
  component_merge_vars?: Maybe<Scalars['jsonb']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  is_archived?: Maybe<Scalars['Boolean']>;
  patient_segment_id?: Maybe<Scalars['String']>;
  send_datetime?: Maybe<Scalars['timestamptz']>;
  sms_template_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "campaign_sms_components" */
export enum Campaign_Sms_Components_Update_Column {
  /** column name */
  CampaignSmsComponentId = 'campaign_sms_component_id',
  /** column name */
  CampaignTemplateId = 'campaign_template_id',
  /** column name */
  ComponentMergeVars = 'component_merge_vars',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  IsArchived = 'is_archived',
  /** column name */
  PatientSegmentId = 'patient_segment_id',
  /** column name */
  SendDatetime = 'send_datetime',
  /** column name */
  SmsTemplateId = 'sms_template_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/**
 * A campaign template consists of a collection of letter, email, and message templates, along with the patient segment each is intended for. Campaign templates can belong to a particular practice or network if practice_id or network_id is present. Otherwise, it's available to all users.
 * 
 * 
 * columns and relationships of "campaign_templates"
 */
export type Campaign_Templates = {
  __typename?: 'campaign_templates';
  /** If included, the campaign must be approved by this date, after which it will be considered expired */
  approval_due_date?: Maybe<Scalars['date']>;
  /** An array relationship */
  campaign_approvals: Array<Campaign_Approvals>;
  /** An aggregated array relationship */
  campaign_approvals_aggregate: Campaign_Approvals_Aggregate;
  campaign_description?: Maybe<Scalars['String']>;
  /** An array relationship */
  campaign_email_components: Array<Campaign_Email_Components>;
  /** An aggregated array relationship */
  campaign_email_components_aggregate: Campaign_Email_Components_Aggregate;
  /** An array relationship */
  campaign_letter_components: Array<Campaign_Letter_Components>;
  /** An aggregated array relationship */
  campaign_letter_components_aggregate: Campaign_Letter_Components_Aggregate;
  campaign_merge_vars?: Maybe<Scalars['jsonb']>;
  campaign_name: Scalars['String'];
  /** An array relationship */
  campaign_sms_components: Array<Campaign_Sms_Components>;
  /** An aggregated array relationship */
  campaign_sms_components_aggregate: Campaign_Sms_Components_Aggregate;
  campaign_template_id: Scalars['String'];
  created_at: Scalars['timestamptz'];
  is_archived: Scalars['Boolean'];
  /** An object relationship */
  network?: Maybe<Networks>;
  network_id?: Maybe<Scalars['String']>;
  /** An object relationship */
  practice?: Maybe<Practices>;
  practice_id?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
};


/**
 * A campaign template consists of a collection of letter, email, and message templates, along with the patient segment each is intended for. Campaign templates can belong to a particular practice or network if practice_id or network_id is present. Otherwise, it's available to all users.
 * 
 * 
 * columns and relationships of "campaign_templates"
 */
export type Campaign_TemplatesCampaign_ApprovalsArgs = {
  distinct_on?: Maybe<Array<Campaign_Approvals_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Campaign_Approvals_Order_By>>;
  where?: Maybe<Campaign_Approvals_Bool_Exp>;
};


/**
 * A campaign template consists of a collection of letter, email, and message templates, along with the patient segment each is intended for. Campaign templates can belong to a particular practice or network if practice_id or network_id is present. Otherwise, it's available to all users.
 * 
 * 
 * columns and relationships of "campaign_templates"
 */
export type Campaign_TemplatesCampaign_Approvals_AggregateArgs = {
  distinct_on?: Maybe<Array<Campaign_Approvals_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Campaign_Approvals_Order_By>>;
  where?: Maybe<Campaign_Approvals_Bool_Exp>;
};


/**
 * A campaign template consists of a collection of letter, email, and message templates, along with the patient segment each is intended for. Campaign templates can belong to a particular practice or network if practice_id or network_id is present. Otherwise, it's available to all users.
 * 
 * 
 * columns and relationships of "campaign_templates"
 */
export type Campaign_TemplatesCampaign_Email_ComponentsArgs = {
  distinct_on?: Maybe<Array<Campaign_Email_Components_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Campaign_Email_Components_Order_By>>;
  where?: Maybe<Campaign_Email_Components_Bool_Exp>;
};


/**
 * A campaign template consists of a collection of letter, email, and message templates, along with the patient segment each is intended for. Campaign templates can belong to a particular practice or network if practice_id or network_id is present. Otherwise, it's available to all users.
 * 
 * 
 * columns and relationships of "campaign_templates"
 */
export type Campaign_TemplatesCampaign_Email_Components_AggregateArgs = {
  distinct_on?: Maybe<Array<Campaign_Email_Components_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Campaign_Email_Components_Order_By>>;
  where?: Maybe<Campaign_Email_Components_Bool_Exp>;
};


/**
 * A campaign template consists of a collection of letter, email, and message templates, along with the patient segment each is intended for. Campaign templates can belong to a particular practice or network if practice_id or network_id is present. Otherwise, it's available to all users.
 * 
 * 
 * columns and relationships of "campaign_templates"
 */
export type Campaign_TemplatesCampaign_Letter_ComponentsArgs = {
  distinct_on?: Maybe<Array<Campaign_Letter_Components_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Campaign_Letter_Components_Order_By>>;
  where?: Maybe<Campaign_Letter_Components_Bool_Exp>;
};


/**
 * A campaign template consists of a collection of letter, email, and message templates, along with the patient segment each is intended for. Campaign templates can belong to a particular practice or network if practice_id or network_id is present. Otherwise, it's available to all users.
 * 
 * 
 * columns and relationships of "campaign_templates"
 */
export type Campaign_TemplatesCampaign_Letter_Components_AggregateArgs = {
  distinct_on?: Maybe<Array<Campaign_Letter_Components_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Campaign_Letter_Components_Order_By>>;
  where?: Maybe<Campaign_Letter_Components_Bool_Exp>;
};


/**
 * A campaign template consists of a collection of letter, email, and message templates, along with the patient segment each is intended for. Campaign templates can belong to a particular practice or network if practice_id or network_id is present. Otherwise, it's available to all users.
 * 
 * 
 * columns and relationships of "campaign_templates"
 */
export type Campaign_TemplatesCampaign_Merge_VarsArgs = {
  path?: Maybe<Scalars['String']>;
};


/**
 * A campaign template consists of a collection of letter, email, and message templates, along with the patient segment each is intended for. Campaign templates can belong to a particular practice or network if practice_id or network_id is present. Otherwise, it's available to all users.
 * 
 * 
 * columns and relationships of "campaign_templates"
 */
export type Campaign_TemplatesCampaign_Sms_ComponentsArgs = {
  distinct_on?: Maybe<Array<Campaign_Sms_Components_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Campaign_Sms_Components_Order_By>>;
  where?: Maybe<Campaign_Sms_Components_Bool_Exp>;
};


/**
 * A campaign template consists of a collection of letter, email, and message templates, along with the patient segment each is intended for. Campaign templates can belong to a particular practice or network if practice_id or network_id is present. Otherwise, it's available to all users.
 * 
 * 
 * columns and relationships of "campaign_templates"
 */
export type Campaign_TemplatesCampaign_Sms_Components_AggregateArgs = {
  distinct_on?: Maybe<Array<Campaign_Sms_Components_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Campaign_Sms_Components_Order_By>>;
  where?: Maybe<Campaign_Sms_Components_Bool_Exp>;
};

/** aggregated selection of "campaign_templates" */
export type Campaign_Templates_Aggregate = {
  __typename?: 'campaign_templates_aggregate';
  aggregate?: Maybe<Campaign_Templates_Aggregate_Fields>;
  nodes: Array<Campaign_Templates>;
};

/** aggregate fields of "campaign_templates" */
export type Campaign_Templates_Aggregate_Fields = {
  __typename?: 'campaign_templates_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Campaign_Templates_Max_Fields>;
  min?: Maybe<Campaign_Templates_Min_Fields>;
};


/** aggregate fields of "campaign_templates" */
export type Campaign_Templates_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Campaign_Templates_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "campaign_templates" */
export type Campaign_Templates_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Campaign_Templates_Max_Order_By>;
  min?: Maybe<Campaign_Templates_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Campaign_Templates_Append_Input = {
  campaign_merge_vars?: Maybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "campaign_templates" */
export type Campaign_Templates_Arr_Rel_Insert_Input = {
  data: Array<Campaign_Templates_Insert_Input>;
  on_conflict?: Maybe<Campaign_Templates_On_Conflict>;
};

/** Boolean expression to filter rows from the table "campaign_templates". All fields are combined with a logical 'AND'. */
export type Campaign_Templates_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Campaign_Templates_Bool_Exp>>>;
  _not?: Maybe<Campaign_Templates_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Campaign_Templates_Bool_Exp>>>;
  approval_due_date?: Maybe<Date_Comparison_Exp>;
  campaign_approvals?: Maybe<Campaign_Approvals_Bool_Exp>;
  campaign_description?: Maybe<String_Comparison_Exp>;
  campaign_email_components?: Maybe<Campaign_Email_Components_Bool_Exp>;
  campaign_letter_components?: Maybe<Campaign_Letter_Components_Bool_Exp>;
  campaign_merge_vars?: Maybe<Jsonb_Comparison_Exp>;
  campaign_name?: Maybe<String_Comparison_Exp>;
  campaign_sms_components?: Maybe<Campaign_Sms_Components_Bool_Exp>;
  campaign_template_id?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  is_archived?: Maybe<Boolean_Comparison_Exp>;
  network?: Maybe<Networks_Bool_Exp>;
  network_id?: Maybe<String_Comparison_Exp>;
  practice?: Maybe<Practices_Bool_Exp>;
  practice_id?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "campaign_templates" */
export enum Campaign_Templates_Constraint {
  /** unique or primary key constraint */
  CampaignTemplatesPkey = 'campaign_templates_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Campaign_Templates_Delete_At_Path_Input = {
  campaign_merge_vars?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Campaign_Templates_Delete_Elem_Input = {
  campaign_merge_vars?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Campaign_Templates_Delete_Key_Input = {
  campaign_merge_vars?: Maybe<Scalars['String']>;
};

/** input type for inserting data into table "campaign_templates" */
export type Campaign_Templates_Insert_Input = {
  approval_due_date?: Maybe<Scalars['date']>;
  campaign_approvals?: Maybe<Campaign_Approvals_Arr_Rel_Insert_Input>;
  campaign_description?: Maybe<Scalars['String']>;
  campaign_email_components?: Maybe<Campaign_Email_Components_Arr_Rel_Insert_Input>;
  campaign_letter_components?: Maybe<Campaign_Letter_Components_Arr_Rel_Insert_Input>;
  campaign_merge_vars?: Maybe<Scalars['jsonb']>;
  campaign_name?: Maybe<Scalars['String']>;
  campaign_sms_components?: Maybe<Campaign_Sms_Components_Arr_Rel_Insert_Input>;
  campaign_template_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  is_archived?: Maybe<Scalars['Boolean']>;
  network?: Maybe<Networks_Obj_Rel_Insert_Input>;
  network_id?: Maybe<Scalars['String']>;
  practice?: Maybe<Practices_Obj_Rel_Insert_Input>;
  practice_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Campaign_Templates_Max_Fields = {
  __typename?: 'campaign_templates_max_fields';
  approval_due_date?: Maybe<Scalars['date']>;
  campaign_description?: Maybe<Scalars['String']>;
  campaign_name?: Maybe<Scalars['String']>;
  campaign_template_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  network_id?: Maybe<Scalars['String']>;
  practice_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "campaign_templates" */
export type Campaign_Templates_Max_Order_By = {
  approval_due_date?: Maybe<Order_By>;
  campaign_description?: Maybe<Order_By>;
  campaign_name?: Maybe<Order_By>;
  campaign_template_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  network_id?: Maybe<Order_By>;
  practice_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Campaign_Templates_Min_Fields = {
  __typename?: 'campaign_templates_min_fields';
  approval_due_date?: Maybe<Scalars['date']>;
  campaign_description?: Maybe<Scalars['String']>;
  campaign_name?: Maybe<Scalars['String']>;
  campaign_template_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  network_id?: Maybe<Scalars['String']>;
  practice_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "campaign_templates" */
export type Campaign_Templates_Min_Order_By = {
  approval_due_date?: Maybe<Order_By>;
  campaign_description?: Maybe<Order_By>;
  campaign_name?: Maybe<Order_By>;
  campaign_template_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  network_id?: Maybe<Order_By>;
  practice_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "campaign_templates" */
export type Campaign_Templates_Mutation_Response = {
  __typename?: 'campaign_templates_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Campaign_Templates>;
};

/** input type for inserting object relation for remote table "campaign_templates" */
export type Campaign_Templates_Obj_Rel_Insert_Input = {
  data: Campaign_Templates_Insert_Input;
  on_conflict?: Maybe<Campaign_Templates_On_Conflict>;
};

/** on conflict condition type for table "campaign_templates" */
export type Campaign_Templates_On_Conflict = {
  constraint: Campaign_Templates_Constraint;
  update_columns: Array<Campaign_Templates_Update_Column>;
  where?: Maybe<Campaign_Templates_Bool_Exp>;
};

/** ordering options when selecting data from "campaign_templates" */
export type Campaign_Templates_Order_By = {
  approval_due_date?: Maybe<Order_By>;
  campaign_approvals_aggregate?: Maybe<Campaign_Approvals_Aggregate_Order_By>;
  campaign_description?: Maybe<Order_By>;
  campaign_email_components_aggregate?: Maybe<Campaign_Email_Components_Aggregate_Order_By>;
  campaign_letter_components_aggregate?: Maybe<Campaign_Letter_Components_Aggregate_Order_By>;
  campaign_merge_vars?: Maybe<Order_By>;
  campaign_name?: Maybe<Order_By>;
  campaign_sms_components_aggregate?: Maybe<Campaign_Sms_Components_Aggregate_Order_By>;
  campaign_template_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  is_archived?: Maybe<Order_By>;
  network?: Maybe<Networks_Order_By>;
  network_id?: Maybe<Order_By>;
  practice?: Maybe<Practices_Order_By>;
  practice_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: "campaign_templates" */
export type Campaign_Templates_Pk_Columns_Input = {
  campaign_template_id: Scalars['String'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Campaign_Templates_Prepend_Input = {
  campaign_merge_vars?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "campaign_templates" */
export enum Campaign_Templates_Select_Column {
  /** column name */
  ApprovalDueDate = 'approval_due_date',
  /** column name */
  CampaignDescription = 'campaign_description',
  /** column name */
  CampaignMergeVars = 'campaign_merge_vars',
  /** column name */
  CampaignName = 'campaign_name',
  /** column name */
  CampaignTemplateId = 'campaign_template_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  IsArchived = 'is_archived',
  /** column name */
  NetworkId = 'network_id',
  /** column name */
  PracticeId = 'practice_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "campaign_templates" */
export type Campaign_Templates_Set_Input = {
  approval_due_date?: Maybe<Scalars['date']>;
  campaign_description?: Maybe<Scalars['String']>;
  campaign_merge_vars?: Maybe<Scalars['jsonb']>;
  campaign_name?: Maybe<Scalars['String']>;
  campaign_template_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  is_archived?: Maybe<Scalars['Boolean']>;
  network_id?: Maybe<Scalars['String']>;
  practice_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "campaign_templates" */
export enum Campaign_Templates_Update_Column {
  /** column name */
  ApprovalDueDate = 'approval_due_date',
  /** column name */
  CampaignDescription = 'campaign_description',
  /** column name */
  CampaignMergeVars = 'campaign_merge_vars',
  /** column name */
  CampaignName = 'campaign_name',
  /** column name */
  CampaignTemplateId = 'campaign_template_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  IsArchived = 'is_archived',
  /** column name */
  NetworkId = 'network_id',
  /** column name */
  PracticeId = 'practice_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** columns and relationships of "client_call_records" */
export type Client_Call_Records = {
  __typename?: 'client_call_records';
  call_start_time: Scalars['timestamp'];
  caller_name?: Maybe<Scalars['String']>;
  caller_number: Scalars['String'];
  created_at?: Maybe<Scalars['timestamptz']>;
  dialed_number: Scalars['String'];
  duration_seconds: Scalars['Int'];
  tracking_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregated selection of "client_call_records" */
export type Client_Call_Records_Aggregate = {
  __typename?: 'client_call_records_aggregate';
  aggregate?: Maybe<Client_Call_Records_Aggregate_Fields>;
  nodes: Array<Client_Call_Records>;
};

/** aggregate fields of "client_call_records" */
export type Client_Call_Records_Aggregate_Fields = {
  __typename?: 'client_call_records_aggregate_fields';
  avg?: Maybe<Client_Call_Records_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Client_Call_Records_Max_Fields>;
  min?: Maybe<Client_Call_Records_Min_Fields>;
  stddev?: Maybe<Client_Call_Records_Stddev_Fields>;
  stddev_pop?: Maybe<Client_Call_Records_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Client_Call_Records_Stddev_Samp_Fields>;
  sum?: Maybe<Client_Call_Records_Sum_Fields>;
  var_pop?: Maybe<Client_Call_Records_Var_Pop_Fields>;
  var_samp?: Maybe<Client_Call_Records_Var_Samp_Fields>;
  variance?: Maybe<Client_Call_Records_Variance_Fields>;
};


/** aggregate fields of "client_call_records" */
export type Client_Call_Records_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Client_Call_Records_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "client_call_records" */
export type Client_Call_Records_Aggregate_Order_By = {
  avg?: Maybe<Client_Call_Records_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Client_Call_Records_Max_Order_By>;
  min?: Maybe<Client_Call_Records_Min_Order_By>;
  stddev?: Maybe<Client_Call_Records_Stddev_Order_By>;
  stddev_pop?: Maybe<Client_Call_Records_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Client_Call_Records_Stddev_Samp_Order_By>;
  sum?: Maybe<Client_Call_Records_Sum_Order_By>;
  var_pop?: Maybe<Client_Call_Records_Var_Pop_Order_By>;
  var_samp?: Maybe<Client_Call_Records_Var_Samp_Order_By>;
  variance?: Maybe<Client_Call_Records_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "client_call_records" */
export type Client_Call_Records_Arr_Rel_Insert_Input = {
  data: Array<Client_Call_Records_Insert_Input>;
  on_conflict?: Maybe<Client_Call_Records_On_Conflict>;
};

/** aggregate avg on columns */
export type Client_Call_Records_Avg_Fields = {
  __typename?: 'client_call_records_avg_fields';
  duration_seconds?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "client_call_records" */
export type Client_Call_Records_Avg_Order_By = {
  duration_seconds?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "client_call_records". All fields are combined with a logical 'AND'. */
export type Client_Call_Records_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Client_Call_Records_Bool_Exp>>>;
  _not?: Maybe<Client_Call_Records_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Client_Call_Records_Bool_Exp>>>;
  call_start_time?: Maybe<Timestamp_Comparison_Exp>;
  caller_name?: Maybe<String_Comparison_Exp>;
  caller_number?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  dialed_number?: Maybe<String_Comparison_Exp>;
  duration_seconds?: Maybe<Int_Comparison_Exp>;
  tracking_id?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "client_call_records" */
export enum Client_Call_Records_Constraint {
  /** unique or primary key constraint */
  ClientCallRecordsPkey = 'client_call_records_pkey'
}

/** input type for incrementing integer column in table "client_call_records" */
export type Client_Call_Records_Inc_Input = {
  duration_seconds?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "client_call_records" */
export type Client_Call_Records_Insert_Input = {
  call_start_time?: Maybe<Scalars['timestamp']>;
  caller_name?: Maybe<Scalars['String']>;
  caller_number?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  dialed_number?: Maybe<Scalars['String']>;
  duration_seconds?: Maybe<Scalars['Int']>;
  tracking_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Client_Call_Records_Max_Fields = {
  __typename?: 'client_call_records_max_fields';
  call_start_time?: Maybe<Scalars['timestamp']>;
  caller_name?: Maybe<Scalars['String']>;
  caller_number?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  dialed_number?: Maybe<Scalars['String']>;
  duration_seconds?: Maybe<Scalars['Int']>;
  tracking_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "client_call_records" */
export type Client_Call_Records_Max_Order_By = {
  call_start_time?: Maybe<Order_By>;
  caller_name?: Maybe<Order_By>;
  caller_number?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  dialed_number?: Maybe<Order_By>;
  duration_seconds?: Maybe<Order_By>;
  tracking_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Client_Call_Records_Min_Fields = {
  __typename?: 'client_call_records_min_fields';
  call_start_time?: Maybe<Scalars['timestamp']>;
  caller_name?: Maybe<Scalars['String']>;
  caller_number?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  dialed_number?: Maybe<Scalars['String']>;
  duration_seconds?: Maybe<Scalars['Int']>;
  tracking_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "client_call_records" */
export type Client_Call_Records_Min_Order_By = {
  call_start_time?: Maybe<Order_By>;
  caller_name?: Maybe<Order_By>;
  caller_number?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  dialed_number?: Maybe<Order_By>;
  duration_seconds?: Maybe<Order_By>;
  tracking_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "client_call_records" */
export type Client_Call_Records_Mutation_Response = {
  __typename?: 'client_call_records_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Client_Call_Records>;
};

/** input type for inserting object relation for remote table "client_call_records" */
export type Client_Call_Records_Obj_Rel_Insert_Input = {
  data: Client_Call_Records_Insert_Input;
  on_conflict?: Maybe<Client_Call_Records_On_Conflict>;
};

/** on conflict condition type for table "client_call_records" */
export type Client_Call_Records_On_Conflict = {
  constraint: Client_Call_Records_Constraint;
  update_columns: Array<Client_Call_Records_Update_Column>;
  where?: Maybe<Client_Call_Records_Bool_Exp>;
};

/** ordering options when selecting data from "client_call_records" */
export type Client_Call_Records_Order_By = {
  call_start_time?: Maybe<Order_By>;
  caller_name?: Maybe<Order_By>;
  caller_number?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  dialed_number?: Maybe<Order_By>;
  duration_seconds?: Maybe<Order_By>;
  tracking_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: "client_call_records" */
export type Client_Call_Records_Pk_Columns_Input = {
  call_start_time: Scalars['timestamp'];
  caller_number: Scalars['String'];
  dialed_number: Scalars['String'];
};

/** select columns of table "client_call_records" */
export enum Client_Call_Records_Select_Column {
  /** column name */
  CallStartTime = 'call_start_time',
  /** column name */
  CallerName = 'caller_name',
  /** column name */
  CallerNumber = 'caller_number',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DialedNumber = 'dialed_number',
  /** column name */
  DurationSeconds = 'duration_seconds',
  /** column name */
  TrackingId = 'tracking_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "client_call_records" */
export type Client_Call_Records_Set_Input = {
  call_start_time?: Maybe<Scalars['timestamp']>;
  caller_name?: Maybe<Scalars['String']>;
  caller_number?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  dialed_number?: Maybe<Scalars['String']>;
  duration_seconds?: Maybe<Scalars['Int']>;
  tracking_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Client_Call_Records_Stddev_Fields = {
  __typename?: 'client_call_records_stddev_fields';
  duration_seconds?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "client_call_records" */
export type Client_Call_Records_Stddev_Order_By = {
  duration_seconds?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Client_Call_Records_Stddev_Pop_Fields = {
  __typename?: 'client_call_records_stddev_pop_fields';
  duration_seconds?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "client_call_records" */
export type Client_Call_Records_Stddev_Pop_Order_By = {
  duration_seconds?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Client_Call_Records_Stddev_Samp_Fields = {
  __typename?: 'client_call_records_stddev_samp_fields';
  duration_seconds?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "client_call_records" */
export type Client_Call_Records_Stddev_Samp_Order_By = {
  duration_seconds?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Client_Call_Records_Sum_Fields = {
  __typename?: 'client_call_records_sum_fields';
  duration_seconds?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "client_call_records" */
export type Client_Call_Records_Sum_Order_By = {
  duration_seconds?: Maybe<Order_By>;
};

/** update columns of table "client_call_records" */
export enum Client_Call_Records_Update_Column {
  /** column name */
  CallStartTime = 'call_start_time',
  /** column name */
  CallerName = 'caller_name',
  /** column name */
  CallerNumber = 'caller_number',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DialedNumber = 'dialed_number',
  /** column name */
  DurationSeconds = 'duration_seconds',
  /** column name */
  TrackingId = 'tracking_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Client_Call_Records_Var_Pop_Fields = {
  __typename?: 'client_call_records_var_pop_fields';
  duration_seconds?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "client_call_records" */
export type Client_Call_Records_Var_Pop_Order_By = {
  duration_seconds?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Client_Call_Records_Var_Samp_Fields = {
  __typename?: 'client_call_records_var_samp_fields';
  duration_seconds?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "client_call_records" */
export type Client_Call_Records_Var_Samp_Order_By = {
  duration_seconds?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Client_Call_Records_Variance_Fields = {
  __typename?: 'client_call_records_variance_fields';
  duration_seconds?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "client_call_records" */
export type Client_Call_Records_Variance_Order_By = {
  duration_seconds?: Maybe<Order_By>;
};

/** columns and relationships of "cms_alignment_reports" */
export type Cms_Alignment_Reports = {
  __typename?: 'cms_alignment_reports';
  beneficiary_age?: Maybe<Scalars['Int']>;
  beneficiary_alignment_effective_start_date?: Maybe<Scalars['date']>;
  beneficiary_alignment_effective_termination_date?: Maybe<Scalars['date']>;
  beneficiary_city?: Maybe<Scalars['String']>;
  beneficiary_date_of_birth?: Maybe<Scalars['date']>;
  beneficiary_date_of_death?: Maybe<Scalars['date']>;
  beneficiary_eligibility_alignment_year_1?: Maybe<Scalars['Boolean']>;
  beneficiary_eligibility_alignment_year_2?: Maybe<Scalars['Boolean']>;
  beneficiary_first_name?: Maybe<Scalars['String']>;
  beneficiary_gender?: Maybe<Scalars['String']>;
  beneficiary_last_name?: Maybe<Scalars['String']>;
  beneficiary_line_1_address?: Maybe<Scalars['String']>;
  beneficiary_line_2_address?: Maybe<Scalars['String']>;
  beneficiary_line_3_address?: Maybe<Scalars['String']>;
  beneficiary_line_4_address?: Maybe<Scalars['String']>;
  beneficiary_line_5_address?: Maybe<Scalars['String']>;
  beneficiary_line_6_address?: Maybe<Scalars['String']>;
  beneficiary_mbi_id: Scalars['String'];
  beneficiary_part_d_coverage_alignment_year_1?: Maybe<Scalars['Boolean']>;
  beneficiary_part_d_coverage_alignment_year_2?: Maybe<Scalars['Boolean']>;
  beneficiary_state_county_of_residence_fips?: Maybe<Scalars['String']>;
  beneficiary_state_county_of_residence_ssa?: Maybe<Scalars['String']>;
  beneficiary_usps_state_code?: Maybe<Scalars['String']>;
  beneficiary_zip_4?: Maybe<Scalars['String']>;
  beneficiary_zip_5?: Maybe<Scalars['String']>;
  claim_based_alignment_indicator?: Maybe<Scalars['Boolean']>;
  created_at: Scalars['timestamptz'];
  dc_id: Scalars['String'];
  /** An object relationship */
  file_upload?: Maybe<File_Uploads>;
  file_upload_id?: Maybe<Scalars['String']>;
  frailty_indicator?: Maybe<Scalars['Boolean']>;
  high_risk_score_indicator?: Maybe<Scalars['Boolean']>;
  medium_risk_with_unplanned_admissions_indicator?: Maybe<Scalars['Boolean']>;
  mobility_impairment_indicator?: Maybe<Scalars['Boolean']>;
  newly_aligned_beneficiary_flag?: Maybe<Scalars['Boolean']>;
  performance_year: Scalars['Int'];
  prospective_plus_alignment?: Maybe<Scalars['Boolean']>;
  race_ethnicity?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
  voluntary_alignment_type?: Maybe<Scalars['String']>;
};

/** aggregated selection of "cms_alignment_reports" */
export type Cms_Alignment_Reports_Aggregate = {
  __typename?: 'cms_alignment_reports_aggregate';
  aggregate?: Maybe<Cms_Alignment_Reports_Aggregate_Fields>;
  nodes: Array<Cms_Alignment_Reports>;
};

/** aggregate fields of "cms_alignment_reports" */
export type Cms_Alignment_Reports_Aggregate_Fields = {
  __typename?: 'cms_alignment_reports_aggregate_fields';
  avg?: Maybe<Cms_Alignment_Reports_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Cms_Alignment_Reports_Max_Fields>;
  min?: Maybe<Cms_Alignment_Reports_Min_Fields>;
  stddev?: Maybe<Cms_Alignment_Reports_Stddev_Fields>;
  stddev_pop?: Maybe<Cms_Alignment_Reports_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Cms_Alignment_Reports_Stddev_Samp_Fields>;
  sum?: Maybe<Cms_Alignment_Reports_Sum_Fields>;
  var_pop?: Maybe<Cms_Alignment_Reports_Var_Pop_Fields>;
  var_samp?: Maybe<Cms_Alignment_Reports_Var_Samp_Fields>;
  variance?: Maybe<Cms_Alignment_Reports_Variance_Fields>;
};


/** aggregate fields of "cms_alignment_reports" */
export type Cms_Alignment_Reports_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Cms_Alignment_Reports_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "cms_alignment_reports" */
export type Cms_Alignment_Reports_Aggregate_Order_By = {
  avg?: Maybe<Cms_Alignment_Reports_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Cms_Alignment_Reports_Max_Order_By>;
  min?: Maybe<Cms_Alignment_Reports_Min_Order_By>;
  stddev?: Maybe<Cms_Alignment_Reports_Stddev_Order_By>;
  stddev_pop?: Maybe<Cms_Alignment_Reports_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Cms_Alignment_Reports_Stddev_Samp_Order_By>;
  sum?: Maybe<Cms_Alignment_Reports_Sum_Order_By>;
  var_pop?: Maybe<Cms_Alignment_Reports_Var_Pop_Order_By>;
  var_samp?: Maybe<Cms_Alignment_Reports_Var_Samp_Order_By>;
  variance?: Maybe<Cms_Alignment_Reports_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "cms_alignment_reports" */
export type Cms_Alignment_Reports_Arr_Rel_Insert_Input = {
  data: Array<Cms_Alignment_Reports_Insert_Input>;
  on_conflict?: Maybe<Cms_Alignment_Reports_On_Conflict>;
};

/** aggregate avg on columns */
export type Cms_Alignment_Reports_Avg_Fields = {
  __typename?: 'cms_alignment_reports_avg_fields';
  beneficiary_age?: Maybe<Scalars['Float']>;
  performance_year?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "cms_alignment_reports" */
export type Cms_Alignment_Reports_Avg_Order_By = {
  beneficiary_age?: Maybe<Order_By>;
  performance_year?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "cms_alignment_reports". All fields are combined with a logical 'AND'. */
export type Cms_Alignment_Reports_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Cms_Alignment_Reports_Bool_Exp>>>;
  _not?: Maybe<Cms_Alignment_Reports_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Cms_Alignment_Reports_Bool_Exp>>>;
  beneficiary_age?: Maybe<Int_Comparison_Exp>;
  beneficiary_alignment_effective_start_date?: Maybe<Date_Comparison_Exp>;
  beneficiary_alignment_effective_termination_date?: Maybe<Date_Comparison_Exp>;
  beneficiary_city?: Maybe<String_Comparison_Exp>;
  beneficiary_date_of_birth?: Maybe<Date_Comparison_Exp>;
  beneficiary_date_of_death?: Maybe<Date_Comparison_Exp>;
  beneficiary_eligibility_alignment_year_1?: Maybe<Boolean_Comparison_Exp>;
  beneficiary_eligibility_alignment_year_2?: Maybe<Boolean_Comparison_Exp>;
  beneficiary_first_name?: Maybe<String_Comparison_Exp>;
  beneficiary_gender?: Maybe<String_Comparison_Exp>;
  beneficiary_last_name?: Maybe<String_Comparison_Exp>;
  beneficiary_line_1_address?: Maybe<String_Comparison_Exp>;
  beneficiary_line_2_address?: Maybe<String_Comparison_Exp>;
  beneficiary_line_3_address?: Maybe<String_Comparison_Exp>;
  beneficiary_line_4_address?: Maybe<String_Comparison_Exp>;
  beneficiary_line_5_address?: Maybe<String_Comparison_Exp>;
  beneficiary_line_6_address?: Maybe<String_Comparison_Exp>;
  beneficiary_mbi_id?: Maybe<String_Comparison_Exp>;
  beneficiary_part_d_coverage_alignment_year_1?: Maybe<Boolean_Comparison_Exp>;
  beneficiary_part_d_coverage_alignment_year_2?: Maybe<Boolean_Comparison_Exp>;
  beneficiary_state_county_of_residence_fips?: Maybe<String_Comparison_Exp>;
  beneficiary_state_county_of_residence_ssa?: Maybe<String_Comparison_Exp>;
  beneficiary_usps_state_code?: Maybe<String_Comparison_Exp>;
  beneficiary_zip_4?: Maybe<String_Comparison_Exp>;
  beneficiary_zip_5?: Maybe<String_Comparison_Exp>;
  claim_based_alignment_indicator?: Maybe<Boolean_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  dc_id?: Maybe<String_Comparison_Exp>;
  file_upload?: Maybe<File_Uploads_Bool_Exp>;
  file_upload_id?: Maybe<String_Comparison_Exp>;
  frailty_indicator?: Maybe<Boolean_Comparison_Exp>;
  high_risk_score_indicator?: Maybe<Boolean_Comparison_Exp>;
  medium_risk_with_unplanned_admissions_indicator?: Maybe<Boolean_Comparison_Exp>;
  mobility_impairment_indicator?: Maybe<Boolean_Comparison_Exp>;
  newly_aligned_beneficiary_flag?: Maybe<Boolean_Comparison_Exp>;
  performance_year?: Maybe<Int_Comparison_Exp>;
  prospective_plus_alignment?: Maybe<Boolean_Comparison_Exp>;
  race_ethnicity?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  voluntary_alignment_type?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "cms_alignment_reports" */
export enum Cms_Alignment_Reports_Constraint {
  /** unique or primary key constraint */
  CmsAlignmentReportsPkey = 'cms_alignment_reports_pkey'
}

/** input type for incrementing integer column in table "cms_alignment_reports" */
export type Cms_Alignment_Reports_Inc_Input = {
  beneficiary_age?: Maybe<Scalars['Int']>;
  performance_year?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "cms_alignment_reports" */
export type Cms_Alignment_Reports_Insert_Input = {
  beneficiary_age?: Maybe<Scalars['Int']>;
  beneficiary_alignment_effective_start_date?: Maybe<Scalars['date']>;
  beneficiary_alignment_effective_termination_date?: Maybe<Scalars['date']>;
  beneficiary_city?: Maybe<Scalars['String']>;
  beneficiary_date_of_birth?: Maybe<Scalars['date']>;
  beneficiary_date_of_death?: Maybe<Scalars['date']>;
  beneficiary_eligibility_alignment_year_1?: Maybe<Scalars['Boolean']>;
  beneficiary_eligibility_alignment_year_2?: Maybe<Scalars['Boolean']>;
  beneficiary_first_name?: Maybe<Scalars['String']>;
  beneficiary_gender?: Maybe<Scalars['String']>;
  beneficiary_last_name?: Maybe<Scalars['String']>;
  beneficiary_line_1_address?: Maybe<Scalars['String']>;
  beneficiary_line_2_address?: Maybe<Scalars['String']>;
  beneficiary_line_3_address?: Maybe<Scalars['String']>;
  beneficiary_line_4_address?: Maybe<Scalars['String']>;
  beneficiary_line_5_address?: Maybe<Scalars['String']>;
  beneficiary_line_6_address?: Maybe<Scalars['String']>;
  beneficiary_mbi_id?: Maybe<Scalars['String']>;
  beneficiary_part_d_coverage_alignment_year_1?: Maybe<Scalars['Boolean']>;
  beneficiary_part_d_coverage_alignment_year_2?: Maybe<Scalars['Boolean']>;
  beneficiary_state_county_of_residence_fips?: Maybe<Scalars['String']>;
  beneficiary_state_county_of_residence_ssa?: Maybe<Scalars['String']>;
  beneficiary_usps_state_code?: Maybe<Scalars['String']>;
  beneficiary_zip_4?: Maybe<Scalars['String']>;
  beneficiary_zip_5?: Maybe<Scalars['String']>;
  claim_based_alignment_indicator?: Maybe<Scalars['Boolean']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  dc_id?: Maybe<Scalars['String']>;
  file_upload?: Maybe<File_Uploads_Obj_Rel_Insert_Input>;
  file_upload_id?: Maybe<Scalars['String']>;
  frailty_indicator?: Maybe<Scalars['Boolean']>;
  high_risk_score_indicator?: Maybe<Scalars['Boolean']>;
  medium_risk_with_unplanned_admissions_indicator?: Maybe<Scalars['Boolean']>;
  mobility_impairment_indicator?: Maybe<Scalars['Boolean']>;
  newly_aligned_beneficiary_flag?: Maybe<Scalars['Boolean']>;
  performance_year?: Maybe<Scalars['Int']>;
  prospective_plus_alignment?: Maybe<Scalars['Boolean']>;
  race_ethnicity?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  voluntary_alignment_type?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Cms_Alignment_Reports_Max_Fields = {
  __typename?: 'cms_alignment_reports_max_fields';
  beneficiary_age?: Maybe<Scalars['Int']>;
  beneficiary_alignment_effective_start_date?: Maybe<Scalars['date']>;
  beneficiary_alignment_effective_termination_date?: Maybe<Scalars['date']>;
  beneficiary_city?: Maybe<Scalars['String']>;
  beneficiary_date_of_birth?: Maybe<Scalars['date']>;
  beneficiary_date_of_death?: Maybe<Scalars['date']>;
  beneficiary_first_name?: Maybe<Scalars['String']>;
  beneficiary_gender?: Maybe<Scalars['String']>;
  beneficiary_last_name?: Maybe<Scalars['String']>;
  beneficiary_line_1_address?: Maybe<Scalars['String']>;
  beneficiary_line_2_address?: Maybe<Scalars['String']>;
  beneficiary_line_3_address?: Maybe<Scalars['String']>;
  beneficiary_line_4_address?: Maybe<Scalars['String']>;
  beneficiary_line_5_address?: Maybe<Scalars['String']>;
  beneficiary_line_6_address?: Maybe<Scalars['String']>;
  beneficiary_mbi_id?: Maybe<Scalars['String']>;
  beneficiary_state_county_of_residence_fips?: Maybe<Scalars['String']>;
  beneficiary_state_county_of_residence_ssa?: Maybe<Scalars['String']>;
  beneficiary_usps_state_code?: Maybe<Scalars['String']>;
  beneficiary_zip_4?: Maybe<Scalars['String']>;
  beneficiary_zip_5?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  dc_id?: Maybe<Scalars['String']>;
  file_upload_id?: Maybe<Scalars['String']>;
  performance_year?: Maybe<Scalars['Int']>;
  race_ethnicity?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  voluntary_alignment_type?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "cms_alignment_reports" */
export type Cms_Alignment_Reports_Max_Order_By = {
  beneficiary_age?: Maybe<Order_By>;
  beneficiary_alignment_effective_start_date?: Maybe<Order_By>;
  beneficiary_alignment_effective_termination_date?: Maybe<Order_By>;
  beneficiary_city?: Maybe<Order_By>;
  beneficiary_date_of_birth?: Maybe<Order_By>;
  beneficiary_date_of_death?: Maybe<Order_By>;
  beneficiary_first_name?: Maybe<Order_By>;
  beneficiary_gender?: Maybe<Order_By>;
  beneficiary_last_name?: Maybe<Order_By>;
  beneficiary_line_1_address?: Maybe<Order_By>;
  beneficiary_line_2_address?: Maybe<Order_By>;
  beneficiary_line_3_address?: Maybe<Order_By>;
  beneficiary_line_4_address?: Maybe<Order_By>;
  beneficiary_line_5_address?: Maybe<Order_By>;
  beneficiary_line_6_address?: Maybe<Order_By>;
  beneficiary_mbi_id?: Maybe<Order_By>;
  beneficiary_state_county_of_residence_fips?: Maybe<Order_By>;
  beneficiary_state_county_of_residence_ssa?: Maybe<Order_By>;
  beneficiary_usps_state_code?: Maybe<Order_By>;
  beneficiary_zip_4?: Maybe<Order_By>;
  beneficiary_zip_5?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  dc_id?: Maybe<Order_By>;
  file_upload_id?: Maybe<Order_By>;
  performance_year?: Maybe<Order_By>;
  race_ethnicity?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  voluntary_alignment_type?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Cms_Alignment_Reports_Min_Fields = {
  __typename?: 'cms_alignment_reports_min_fields';
  beneficiary_age?: Maybe<Scalars['Int']>;
  beneficiary_alignment_effective_start_date?: Maybe<Scalars['date']>;
  beneficiary_alignment_effective_termination_date?: Maybe<Scalars['date']>;
  beneficiary_city?: Maybe<Scalars['String']>;
  beneficiary_date_of_birth?: Maybe<Scalars['date']>;
  beneficiary_date_of_death?: Maybe<Scalars['date']>;
  beneficiary_first_name?: Maybe<Scalars['String']>;
  beneficiary_gender?: Maybe<Scalars['String']>;
  beneficiary_last_name?: Maybe<Scalars['String']>;
  beneficiary_line_1_address?: Maybe<Scalars['String']>;
  beneficiary_line_2_address?: Maybe<Scalars['String']>;
  beneficiary_line_3_address?: Maybe<Scalars['String']>;
  beneficiary_line_4_address?: Maybe<Scalars['String']>;
  beneficiary_line_5_address?: Maybe<Scalars['String']>;
  beneficiary_line_6_address?: Maybe<Scalars['String']>;
  beneficiary_mbi_id?: Maybe<Scalars['String']>;
  beneficiary_state_county_of_residence_fips?: Maybe<Scalars['String']>;
  beneficiary_state_county_of_residence_ssa?: Maybe<Scalars['String']>;
  beneficiary_usps_state_code?: Maybe<Scalars['String']>;
  beneficiary_zip_4?: Maybe<Scalars['String']>;
  beneficiary_zip_5?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  dc_id?: Maybe<Scalars['String']>;
  file_upload_id?: Maybe<Scalars['String']>;
  performance_year?: Maybe<Scalars['Int']>;
  race_ethnicity?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  voluntary_alignment_type?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "cms_alignment_reports" */
export type Cms_Alignment_Reports_Min_Order_By = {
  beneficiary_age?: Maybe<Order_By>;
  beneficiary_alignment_effective_start_date?: Maybe<Order_By>;
  beneficiary_alignment_effective_termination_date?: Maybe<Order_By>;
  beneficiary_city?: Maybe<Order_By>;
  beneficiary_date_of_birth?: Maybe<Order_By>;
  beneficiary_date_of_death?: Maybe<Order_By>;
  beneficiary_first_name?: Maybe<Order_By>;
  beneficiary_gender?: Maybe<Order_By>;
  beneficiary_last_name?: Maybe<Order_By>;
  beneficiary_line_1_address?: Maybe<Order_By>;
  beneficiary_line_2_address?: Maybe<Order_By>;
  beneficiary_line_3_address?: Maybe<Order_By>;
  beneficiary_line_4_address?: Maybe<Order_By>;
  beneficiary_line_5_address?: Maybe<Order_By>;
  beneficiary_line_6_address?: Maybe<Order_By>;
  beneficiary_mbi_id?: Maybe<Order_By>;
  beneficiary_state_county_of_residence_fips?: Maybe<Order_By>;
  beneficiary_state_county_of_residence_ssa?: Maybe<Order_By>;
  beneficiary_usps_state_code?: Maybe<Order_By>;
  beneficiary_zip_4?: Maybe<Order_By>;
  beneficiary_zip_5?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  dc_id?: Maybe<Order_By>;
  file_upload_id?: Maybe<Order_By>;
  performance_year?: Maybe<Order_By>;
  race_ethnicity?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  voluntary_alignment_type?: Maybe<Order_By>;
};

/** response of any mutation on the table "cms_alignment_reports" */
export type Cms_Alignment_Reports_Mutation_Response = {
  __typename?: 'cms_alignment_reports_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Cms_Alignment_Reports>;
};

/** input type for inserting object relation for remote table "cms_alignment_reports" */
export type Cms_Alignment_Reports_Obj_Rel_Insert_Input = {
  data: Cms_Alignment_Reports_Insert_Input;
  on_conflict?: Maybe<Cms_Alignment_Reports_On_Conflict>;
};

/** on conflict condition type for table "cms_alignment_reports" */
export type Cms_Alignment_Reports_On_Conflict = {
  constraint: Cms_Alignment_Reports_Constraint;
  update_columns: Array<Cms_Alignment_Reports_Update_Column>;
  where?: Maybe<Cms_Alignment_Reports_Bool_Exp>;
};

/** ordering options when selecting data from "cms_alignment_reports" */
export type Cms_Alignment_Reports_Order_By = {
  beneficiary_age?: Maybe<Order_By>;
  beneficiary_alignment_effective_start_date?: Maybe<Order_By>;
  beneficiary_alignment_effective_termination_date?: Maybe<Order_By>;
  beneficiary_city?: Maybe<Order_By>;
  beneficiary_date_of_birth?: Maybe<Order_By>;
  beneficiary_date_of_death?: Maybe<Order_By>;
  beneficiary_eligibility_alignment_year_1?: Maybe<Order_By>;
  beneficiary_eligibility_alignment_year_2?: Maybe<Order_By>;
  beneficiary_first_name?: Maybe<Order_By>;
  beneficiary_gender?: Maybe<Order_By>;
  beneficiary_last_name?: Maybe<Order_By>;
  beneficiary_line_1_address?: Maybe<Order_By>;
  beneficiary_line_2_address?: Maybe<Order_By>;
  beneficiary_line_3_address?: Maybe<Order_By>;
  beneficiary_line_4_address?: Maybe<Order_By>;
  beneficiary_line_5_address?: Maybe<Order_By>;
  beneficiary_line_6_address?: Maybe<Order_By>;
  beneficiary_mbi_id?: Maybe<Order_By>;
  beneficiary_part_d_coverage_alignment_year_1?: Maybe<Order_By>;
  beneficiary_part_d_coverage_alignment_year_2?: Maybe<Order_By>;
  beneficiary_state_county_of_residence_fips?: Maybe<Order_By>;
  beneficiary_state_county_of_residence_ssa?: Maybe<Order_By>;
  beneficiary_usps_state_code?: Maybe<Order_By>;
  beneficiary_zip_4?: Maybe<Order_By>;
  beneficiary_zip_5?: Maybe<Order_By>;
  claim_based_alignment_indicator?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  dc_id?: Maybe<Order_By>;
  file_upload?: Maybe<File_Uploads_Order_By>;
  file_upload_id?: Maybe<Order_By>;
  frailty_indicator?: Maybe<Order_By>;
  high_risk_score_indicator?: Maybe<Order_By>;
  medium_risk_with_unplanned_admissions_indicator?: Maybe<Order_By>;
  mobility_impairment_indicator?: Maybe<Order_By>;
  newly_aligned_beneficiary_flag?: Maybe<Order_By>;
  performance_year?: Maybe<Order_By>;
  prospective_plus_alignment?: Maybe<Order_By>;
  race_ethnicity?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  voluntary_alignment_type?: Maybe<Order_By>;
};

/** primary key columns input for table: "cms_alignment_reports" */
export type Cms_Alignment_Reports_Pk_Columns_Input = {
  beneficiary_mbi_id: Scalars['String'];
  dc_id: Scalars['String'];
  performance_year: Scalars['Int'];
};

/** select columns of table "cms_alignment_reports" */
export enum Cms_Alignment_Reports_Select_Column {
  /** column name */
  BeneficiaryAge = 'beneficiary_age',
  /** column name */
  BeneficiaryAlignmentEffectiveStartDate = 'beneficiary_alignment_effective_start_date',
  /** column name */
  BeneficiaryAlignmentEffectiveTerminationDate = 'beneficiary_alignment_effective_termination_date',
  /** column name */
  BeneficiaryCity = 'beneficiary_city',
  /** column name */
  BeneficiaryDateOfBirth = 'beneficiary_date_of_birth',
  /** column name */
  BeneficiaryDateOfDeath = 'beneficiary_date_of_death',
  /** column name */
  BeneficiaryEligibilityAlignmentYear_1 = 'beneficiary_eligibility_alignment_year_1',
  /** column name */
  BeneficiaryEligibilityAlignmentYear_2 = 'beneficiary_eligibility_alignment_year_2',
  /** column name */
  BeneficiaryFirstName = 'beneficiary_first_name',
  /** column name */
  BeneficiaryGender = 'beneficiary_gender',
  /** column name */
  BeneficiaryLastName = 'beneficiary_last_name',
  /** column name */
  BeneficiaryLine_1Address = 'beneficiary_line_1_address',
  /** column name */
  BeneficiaryLine_2Address = 'beneficiary_line_2_address',
  /** column name */
  BeneficiaryLine_3Address = 'beneficiary_line_3_address',
  /** column name */
  BeneficiaryLine_4Address = 'beneficiary_line_4_address',
  /** column name */
  BeneficiaryLine_5Address = 'beneficiary_line_5_address',
  /** column name */
  BeneficiaryLine_6Address = 'beneficiary_line_6_address',
  /** column name */
  BeneficiaryMbiId = 'beneficiary_mbi_id',
  /** column name */
  BeneficiaryPartDCoverageAlignmentYear_1 = 'beneficiary_part_d_coverage_alignment_year_1',
  /** column name */
  BeneficiaryPartDCoverageAlignmentYear_2 = 'beneficiary_part_d_coverage_alignment_year_2',
  /** column name */
  BeneficiaryStateCountyOfResidenceFips = 'beneficiary_state_county_of_residence_fips',
  /** column name */
  BeneficiaryStateCountyOfResidenceSsa = 'beneficiary_state_county_of_residence_ssa',
  /** column name */
  BeneficiaryUspsStateCode = 'beneficiary_usps_state_code',
  /** column name */
  BeneficiaryZip_4 = 'beneficiary_zip_4',
  /** column name */
  BeneficiaryZip_5 = 'beneficiary_zip_5',
  /** column name */
  ClaimBasedAlignmentIndicator = 'claim_based_alignment_indicator',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DcId = 'dc_id',
  /** column name */
  FileUploadId = 'file_upload_id',
  /** column name */
  FrailtyIndicator = 'frailty_indicator',
  /** column name */
  HighRiskScoreIndicator = 'high_risk_score_indicator',
  /** column name */
  MediumRiskWithUnplannedAdmissionsIndicator = 'medium_risk_with_unplanned_admissions_indicator',
  /** column name */
  MobilityImpairmentIndicator = 'mobility_impairment_indicator',
  /** column name */
  NewlyAlignedBeneficiaryFlag = 'newly_aligned_beneficiary_flag',
  /** column name */
  PerformanceYear = 'performance_year',
  /** column name */
  ProspectivePlusAlignment = 'prospective_plus_alignment',
  /** column name */
  RaceEthnicity = 'race_ethnicity',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VoluntaryAlignmentType = 'voluntary_alignment_type'
}

/** input type for updating data in table "cms_alignment_reports" */
export type Cms_Alignment_Reports_Set_Input = {
  beneficiary_age?: Maybe<Scalars['Int']>;
  beneficiary_alignment_effective_start_date?: Maybe<Scalars['date']>;
  beneficiary_alignment_effective_termination_date?: Maybe<Scalars['date']>;
  beneficiary_city?: Maybe<Scalars['String']>;
  beneficiary_date_of_birth?: Maybe<Scalars['date']>;
  beneficiary_date_of_death?: Maybe<Scalars['date']>;
  beneficiary_eligibility_alignment_year_1?: Maybe<Scalars['Boolean']>;
  beneficiary_eligibility_alignment_year_2?: Maybe<Scalars['Boolean']>;
  beneficiary_first_name?: Maybe<Scalars['String']>;
  beneficiary_gender?: Maybe<Scalars['String']>;
  beneficiary_last_name?: Maybe<Scalars['String']>;
  beneficiary_line_1_address?: Maybe<Scalars['String']>;
  beneficiary_line_2_address?: Maybe<Scalars['String']>;
  beneficiary_line_3_address?: Maybe<Scalars['String']>;
  beneficiary_line_4_address?: Maybe<Scalars['String']>;
  beneficiary_line_5_address?: Maybe<Scalars['String']>;
  beneficiary_line_6_address?: Maybe<Scalars['String']>;
  beneficiary_mbi_id?: Maybe<Scalars['String']>;
  beneficiary_part_d_coverage_alignment_year_1?: Maybe<Scalars['Boolean']>;
  beneficiary_part_d_coverage_alignment_year_2?: Maybe<Scalars['Boolean']>;
  beneficiary_state_county_of_residence_fips?: Maybe<Scalars['String']>;
  beneficiary_state_county_of_residence_ssa?: Maybe<Scalars['String']>;
  beneficiary_usps_state_code?: Maybe<Scalars['String']>;
  beneficiary_zip_4?: Maybe<Scalars['String']>;
  beneficiary_zip_5?: Maybe<Scalars['String']>;
  claim_based_alignment_indicator?: Maybe<Scalars['Boolean']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  dc_id?: Maybe<Scalars['String']>;
  file_upload_id?: Maybe<Scalars['String']>;
  frailty_indicator?: Maybe<Scalars['Boolean']>;
  high_risk_score_indicator?: Maybe<Scalars['Boolean']>;
  medium_risk_with_unplanned_admissions_indicator?: Maybe<Scalars['Boolean']>;
  mobility_impairment_indicator?: Maybe<Scalars['Boolean']>;
  newly_aligned_beneficiary_flag?: Maybe<Scalars['Boolean']>;
  performance_year?: Maybe<Scalars['Int']>;
  prospective_plus_alignment?: Maybe<Scalars['Boolean']>;
  race_ethnicity?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  voluntary_alignment_type?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Cms_Alignment_Reports_Stddev_Fields = {
  __typename?: 'cms_alignment_reports_stddev_fields';
  beneficiary_age?: Maybe<Scalars['Float']>;
  performance_year?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "cms_alignment_reports" */
export type Cms_Alignment_Reports_Stddev_Order_By = {
  beneficiary_age?: Maybe<Order_By>;
  performance_year?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Cms_Alignment_Reports_Stddev_Pop_Fields = {
  __typename?: 'cms_alignment_reports_stddev_pop_fields';
  beneficiary_age?: Maybe<Scalars['Float']>;
  performance_year?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "cms_alignment_reports" */
export type Cms_Alignment_Reports_Stddev_Pop_Order_By = {
  beneficiary_age?: Maybe<Order_By>;
  performance_year?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Cms_Alignment_Reports_Stddev_Samp_Fields = {
  __typename?: 'cms_alignment_reports_stddev_samp_fields';
  beneficiary_age?: Maybe<Scalars['Float']>;
  performance_year?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "cms_alignment_reports" */
export type Cms_Alignment_Reports_Stddev_Samp_Order_By = {
  beneficiary_age?: Maybe<Order_By>;
  performance_year?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Cms_Alignment_Reports_Sum_Fields = {
  __typename?: 'cms_alignment_reports_sum_fields';
  beneficiary_age?: Maybe<Scalars['Int']>;
  performance_year?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "cms_alignment_reports" */
export type Cms_Alignment_Reports_Sum_Order_By = {
  beneficiary_age?: Maybe<Order_By>;
  performance_year?: Maybe<Order_By>;
};

/** update columns of table "cms_alignment_reports" */
export enum Cms_Alignment_Reports_Update_Column {
  /** column name */
  BeneficiaryAge = 'beneficiary_age',
  /** column name */
  BeneficiaryAlignmentEffectiveStartDate = 'beneficiary_alignment_effective_start_date',
  /** column name */
  BeneficiaryAlignmentEffectiveTerminationDate = 'beneficiary_alignment_effective_termination_date',
  /** column name */
  BeneficiaryCity = 'beneficiary_city',
  /** column name */
  BeneficiaryDateOfBirth = 'beneficiary_date_of_birth',
  /** column name */
  BeneficiaryDateOfDeath = 'beneficiary_date_of_death',
  /** column name */
  BeneficiaryEligibilityAlignmentYear_1 = 'beneficiary_eligibility_alignment_year_1',
  /** column name */
  BeneficiaryEligibilityAlignmentYear_2 = 'beneficiary_eligibility_alignment_year_2',
  /** column name */
  BeneficiaryFirstName = 'beneficiary_first_name',
  /** column name */
  BeneficiaryGender = 'beneficiary_gender',
  /** column name */
  BeneficiaryLastName = 'beneficiary_last_name',
  /** column name */
  BeneficiaryLine_1Address = 'beneficiary_line_1_address',
  /** column name */
  BeneficiaryLine_2Address = 'beneficiary_line_2_address',
  /** column name */
  BeneficiaryLine_3Address = 'beneficiary_line_3_address',
  /** column name */
  BeneficiaryLine_4Address = 'beneficiary_line_4_address',
  /** column name */
  BeneficiaryLine_5Address = 'beneficiary_line_5_address',
  /** column name */
  BeneficiaryLine_6Address = 'beneficiary_line_6_address',
  /** column name */
  BeneficiaryMbiId = 'beneficiary_mbi_id',
  /** column name */
  BeneficiaryPartDCoverageAlignmentYear_1 = 'beneficiary_part_d_coverage_alignment_year_1',
  /** column name */
  BeneficiaryPartDCoverageAlignmentYear_2 = 'beneficiary_part_d_coverage_alignment_year_2',
  /** column name */
  BeneficiaryStateCountyOfResidenceFips = 'beneficiary_state_county_of_residence_fips',
  /** column name */
  BeneficiaryStateCountyOfResidenceSsa = 'beneficiary_state_county_of_residence_ssa',
  /** column name */
  BeneficiaryUspsStateCode = 'beneficiary_usps_state_code',
  /** column name */
  BeneficiaryZip_4 = 'beneficiary_zip_4',
  /** column name */
  BeneficiaryZip_5 = 'beneficiary_zip_5',
  /** column name */
  ClaimBasedAlignmentIndicator = 'claim_based_alignment_indicator',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DcId = 'dc_id',
  /** column name */
  FileUploadId = 'file_upload_id',
  /** column name */
  FrailtyIndicator = 'frailty_indicator',
  /** column name */
  HighRiskScoreIndicator = 'high_risk_score_indicator',
  /** column name */
  MediumRiskWithUnplannedAdmissionsIndicator = 'medium_risk_with_unplanned_admissions_indicator',
  /** column name */
  MobilityImpairmentIndicator = 'mobility_impairment_indicator',
  /** column name */
  NewlyAlignedBeneficiaryFlag = 'newly_aligned_beneficiary_flag',
  /** column name */
  PerformanceYear = 'performance_year',
  /** column name */
  ProspectivePlusAlignment = 'prospective_plus_alignment',
  /** column name */
  RaceEthnicity = 'race_ethnicity',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VoluntaryAlignmentType = 'voluntary_alignment_type'
}

/** aggregate var_pop on columns */
export type Cms_Alignment_Reports_Var_Pop_Fields = {
  __typename?: 'cms_alignment_reports_var_pop_fields';
  beneficiary_age?: Maybe<Scalars['Float']>;
  performance_year?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "cms_alignment_reports" */
export type Cms_Alignment_Reports_Var_Pop_Order_By = {
  beneficiary_age?: Maybe<Order_By>;
  performance_year?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Cms_Alignment_Reports_Var_Samp_Fields = {
  __typename?: 'cms_alignment_reports_var_samp_fields';
  beneficiary_age?: Maybe<Scalars['Float']>;
  performance_year?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "cms_alignment_reports" */
export type Cms_Alignment_Reports_Var_Samp_Order_By = {
  beneficiary_age?: Maybe<Order_By>;
  performance_year?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Cms_Alignment_Reports_Variance_Fields = {
  __typename?: 'cms_alignment_reports_variance_fields';
  beneficiary_age?: Maybe<Scalars['Float']>;
  performance_year?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "cms_alignment_reports" */
export type Cms_Alignment_Reports_Variance_Order_By = {
  beneficiary_age?: Maybe<Order_By>;
  performance_year?: Maybe<Order_By>;
};

/** columns and relationships of "cms_dce_participants" */
export type Cms_Dce_Participants = {
  __typename?: 'cms_dce_participants';
  address_1?: Maybe<Scalars['String']>;
  advanced_payment_percent_reduction?: Maybe<Scalars['numeric']>;
  apt_suite?: Maybe<Scalars['String']>;
  attestation_y_n?: Maybe<Scalars['Boolean']>;
  billing_tin?: Maybe<Scalars['String']>;
  billing_tin_current_status?: Maybe<Scalars['String']>;
  billing_tin_deficiency?: Maybe<Scalars['String']>;
  cardiac_and_pulmonary_rehabilitation?: Maybe<Scalars['Boolean']>;
  care_management_home_visit?: Maybe<Scalars['Boolean']>;
  ccn?: Maybe<Scalars['String']>;
  chronic_disease_management_reward_bei?: Maybe<Scalars['Boolean']>;
  city?: Maybe<Scalars['String']>;
  cms_dce_participant_id: Scalars['Int'];
  concurrent_care_for_hospice_beneficiaries?: Maybe<Scalars['Boolean']>;
  cost_sharing_for_part_b_services_bei?: Maybe<Scalars['Boolean']>;
  created_at: Scalars['timestamptz'];
  diabetic_shoes?: Maybe<Scalars['Boolean']>;
  effective_end_date?: Maybe<Scalars['date']>;
  effective_start_date?: Maybe<Scalars['date']>;
  email?: Maybe<Scalars['String']>;
  entity_id?: Maybe<Scalars['String']>;
  entity_legal_business_name?: Maybe<Scalars['String']>;
  entity_tin?: Maybe<Scalars['String']>;
  /** An object relationship */
  file_upload?: Maybe<File_Uploads>;
  file_upload_id?: Maybe<Scalars['String']>;
  home_health_homebound_waiver?: Maybe<Scalars['Boolean']>;
  home_infusion_therapy?: Maybe<Scalars['Boolean']>;
  hospice_care_certification?: Maybe<Scalars['Boolean']>;
  individual_npi?: Maybe<Scalars['String']>;
  last_updated?: Maybe<Scalars['date']>;
  legacy_tin_info?: Maybe<Scalars['String']>;
  legal_business_name?: Maybe<Scalars['String']>;
  medical_nutrition_therapy?: Maybe<Scalars['Boolean']>;
  medicare_id?: Maybe<Scalars['String']>;
  npi_name?: Maybe<Scalars['String']>;
  nurse_practitioner_services?: Maybe<Scalars['Boolean']>;
  organizational_npi?: Maybe<Scalars['String']>;
  overlaps_deficiencies?: Maybe<Scalars['String']>;
  participant_type?: Maybe<Scalars['String']>;
  performance_year?: Maybe<Scalars['String']>;
  post_discharge_home_visit?: Maybe<Scalars['Boolean']>;
  primary_care_capitation_percent_reduction?: Maybe<Scalars['numeric']>;
  primary_care_services?: Maybe<Scalars['String']>;
  provider_class?: Maybe<Scalars['String']>;
  skilled_nursing_facility_snf_3day_stay_waiver?: Maybe<Scalars['Boolean']>;
  sole_proprietor?: Maybe<Scalars['String']>;
  sole_proprietor_tin?: Maybe<Scalars['String']>;
  specialty?: Maybe<Scalars['String']>;
  state_territory?: Maybe<Scalars['String']>;
  telehealth?: Maybe<Scalars['Boolean']>;
  tin?: Maybe<Scalars['String']>;
  tin_type?: Maybe<Scalars['String']>;
  total_care_capitation_percent_reduction?: Maybe<Scalars['numeric']>;
  updated_at: Scalars['timestamptz'];
  uses_cehrt?: Maybe<Scalars['String']>;
  zip_code?: Maybe<Scalars['String']>;
  zip_code_plus_4?: Maybe<Scalars['String']>;
};

/** aggregated selection of "cms_dce_participants" */
export type Cms_Dce_Participants_Aggregate = {
  __typename?: 'cms_dce_participants_aggregate';
  aggregate?: Maybe<Cms_Dce_Participants_Aggregate_Fields>;
  nodes: Array<Cms_Dce_Participants>;
};

/** aggregate fields of "cms_dce_participants" */
export type Cms_Dce_Participants_Aggregate_Fields = {
  __typename?: 'cms_dce_participants_aggregate_fields';
  avg?: Maybe<Cms_Dce_Participants_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Cms_Dce_Participants_Max_Fields>;
  min?: Maybe<Cms_Dce_Participants_Min_Fields>;
  stddev?: Maybe<Cms_Dce_Participants_Stddev_Fields>;
  stddev_pop?: Maybe<Cms_Dce_Participants_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Cms_Dce_Participants_Stddev_Samp_Fields>;
  sum?: Maybe<Cms_Dce_Participants_Sum_Fields>;
  var_pop?: Maybe<Cms_Dce_Participants_Var_Pop_Fields>;
  var_samp?: Maybe<Cms_Dce_Participants_Var_Samp_Fields>;
  variance?: Maybe<Cms_Dce_Participants_Variance_Fields>;
};


/** aggregate fields of "cms_dce_participants" */
export type Cms_Dce_Participants_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Cms_Dce_Participants_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "cms_dce_participants" */
export type Cms_Dce_Participants_Aggregate_Order_By = {
  avg?: Maybe<Cms_Dce_Participants_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Cms_Dce_Participants_Max_Order_By>;
  min?: Maybe<Cms_Dce_Participants_Min_Order_By>;
  stddev?: Maybe<Cms_Dce_Participants_Stddev_Order_By>;
  stddev_pop?: Maybe<Cms_Dce_Participants_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Cms_Dce_Participants_Stddev_Samp_Order_By>;
  sum?: Maybe<Cms_Dce_Participants_Sum_Order_By>;
  var_pop?: Maybe<Cms_Dce_Participants_Var_Pop_Order_By>;
  var_samp?: Maybe<Cms_Dce_Participants_Var_Samp_Order_By>;
  variance?: Maybe<Cms_Dce_Participants_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "cms_dce_participants" */
export type Cms_Dce_Participants_Arr_Rel_Insert_Input = {
  data: Array<Cms_Dce_Participants_Insert_Input>;
  on_conflict?: Maybe<Cms_Dce_Participants_On_Conflict>;
};

/** aggregate avg on columns */
export type Cms_Dce_Participants_Avg_Fields = {
  __typename?: 'cms_dce_participants_avg_fields';
  advanced_payment_percent_reduction?: Maybe<Scalars['Float']>;
  cms_dce_participant_id?: Maybe<Scalars['Float']>;
  primary_care_capitation_percent_reduction?: Maybe<Scalars['Float']>;
  total_care_capitation_percent_reduction?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "cms_dce_participants" */
export type Cms_Dce_Participants_Avg_Order_By = {
  advanced_payment_percent_reduction?: Maybe<Order_By>;
  cms_dce_participant_id?: Maybe<Order_By>;
  primary_care_capitation_percent_reduction?: Maybe<Order_By>;
  total_care_capitation_percent_reduction?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "cms_dce_participants". All fields are combined with a logical 'AND'. */
export type Cms_Dce_Participants_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Cms_Dce_Participants_Bool_Exp>>>;
  _not?: Maybe<Cms_Dce_Participants_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Cms_Dce_Participants_Bool_Exp>>>;
  address_1?: Maybe<String_Comparison_Exp>;
  advanced_payment_percent_reduction?: Maybe<Numeric_Comparison_Exp>;
  apt_suite?: Maybe<String_Comparison_Exp>;
  attestation_y_n?: Maybe<Boolean_Comparison_Exp>;
  billing_tin?: Maybe<String_Comparison_Exp>;
  billing_tin_current_status?: Maybe<String_Comparison_Exp>;
  billing_tin_deficiency?: Maybe<String_Comparison_Exp>;
  cardiac_and_pulmonary_rehabilitation?: Maybe<Boolean_Comparison_Exp>;
  care_management_home_visit?: Maybe<Boolean_Comparison_Exp>;
  ccn?: Maybe<String_Comparison_Exp>;
  chronic_disease_management_reward_bei?: Maybe<Boolean_Comparison_Exp>;
  city?: Maybe<String_Comparison_Exp>;
  cms_dce_participant_id?: Maybe<Int_Comparison_Exp>;
  concurrent_care_for_hospice_beneficiaries?: Maybe<Boolean_Comparison_Exp>;
  cost_sharing_for_part_b_services_bei?: Maybe<Boolean_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  diabetic_shoes?: Maybe<Boolean_Comparison_Exp>;
  effective_end_date?: Maybe<Date_Comparison_Exp>;
  effective_start_date?: Maybe<Date_Comparison_Exp>;
  email?: Maybe<String_Comparison_Exp>;
  entity_id?: Maybe<String_Comparison_Exp>;
  entity_legal_business_name?: Maybe<String_Comparison_Exp>;
  entity_tin?: Maybe<String_Comparison_Exp>;
  file_upload?: Maybe<File_Uploads_Bool_Exp>;
  file_upload_id?: Maybe<String_Comparison_Exp>;
  home_health_homebound_waiver?: Maybe<Boolean_Comparison_Exp>;
  home_infusion_therapy?: Maybe<Boolean_Comparison_Exp>;
  hospice_care_certification?: Maybe<Boolean_Comparison_Exp>;
  individual_npi?: Maybe<String_Comparison_Exp>;
  last_updated?: Maybe<Date_Comparison_Exp>;
  legacy_tin_info?: Maybe<String_Comparison_Exp>;
  legal_business_name?: Maybe<String_Comparison_Exp>;
  medical_nutrition_therapy?: Maybe<Boolean_Comparison_Exp>;
  medicare_id?: Maybe<String_Comparison_Exp>;
  npi_name?: Maybe<String_Comparison_Exp>;
  nurse_practitioner_services?: Maybe<Boolean_Comparison_Exp>;
  organizational_npi?: Maybe<String_Comparison_Exp>;
  overlaps_deficiencies?: Maybe<String_Comparison_Exp>;
  participant_type?: Maybe<String_Comparison_Exp>;
  performance_year?: Maybe<String_Comparison_Exp>;
  post_discharge_home_visit?: Maybe<Boolean_Comparison_Exp>;
  primary_care_capitation_percent_reduction?: Maybe<Numeric_Comparison_Exp>;
  primary_care_services?: Maybe<String_Comparison_Exp>;
  provider_class?: Maybe<String_Comparison_Exp>;
  skilled_nursing_facility_snf_3day_stay_waiver?: Maybe<Boolean_Comparison_Exp>;
  sole_proprietor?: Maybe<String_Comparison_Exp>;
  sole_proprietor_tin?: Maybe<String_Comparison_Exp>;
  specialty?: Maybe<String_Comparison_Exp>;
  state_territory?: Maybe<String_Comparison_Exp>;
  telehealth?: Maybe<Boolean_Comparison_Exp>;
  tin?: Maybe<String_Comparison_Exp>;
  tin_type?: Maybe<String_Comparison_Exp>;
  total_care_capitation_percent_reduction?: Maybe<Numeric_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  uses_cehrt?: Maybe<String_Comparison_Exp>;
  zip_code?: Maybe<String_Comparison_Exp>;
  zip_code_plus_4?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "cms_dce_participants" */
export enum Cms_Dce_Participants_Constraint {
  /** unique or primary key constraint */
  CmsDceParticipantsCmsDceParticipantIdKey = 'cms_dce_participants_cms_dce_participant_id_key'
}

/** input type for incrementing integer column in table "cms_dce_participants" */
export type Cms_Dce_Participants_Inc_Input = {
  advanced_payment_percent_reduction?: Maybe<Scalars['numeric']>;
  cms_dce_participant_id?: Maybe<Scalars['Int']>;
  primary_care_capitation_percent_reduction?: Maybe<Scalars['numeric']>;
  total_care_capitation_percent_reduction?: Maybe<Scalars['numeric']>;
};

/** input type for inserting data into table "cms_dce_participants" */
export type Cms_Dce_Participants_Insert_Input = {
  address_1?: Maybe<Scalars['String']>;
  advanced_payment_percent_reduction?: Maybe<Scalars['numeric']>;
  apt_suite?: Maybe<Scalars['String']>;
  attestation_y_n?: Maybe<Scalars['Boolean']>;
  billing_tin?: Maybe<Scalars['String']>;
  billing_tin_current_status?: Maybe<Scalars['String']>;
  billing_tin_deficiency?: Maybe<Scalars['String']>;
  cardiac_and_pulmonary_rehabilitation?: Maybe<Scalars['Boolean']>;
  care_management_home_visit?: Maybe<Scalars['Boolean']>;
  ccn?: Maybe<Scalars['String']>;
  chronic_disease_management_reward_bei?: Maybe<Scalars['Boolean']>;
  city?: Maybe<Scalars['String']>;
  cms_dce_participant_id?: Maybe<Scalars['Int']>;
  concurrent_care_for_hospice_beneficiaries?: Maybe<Scalars['Boolean']>;
  cost_sharing_for_part_b_services_bei?: Maybe<Scalars['Boolean']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  diabetic_shoes?: Maybe<Scalars['Boolean']>;
  effective_end_date?: Maybe<Scalars['date']>;
  effective_start_date?: Maybe<Scalars['date']>;
  email?: Maybe<Scalars['String']>;
  entity_id?: Maybe<Scalars['String']>;
  entity_legal_business_name?: Maybe<Scalars['String']>;
  entity_tin?: Maybe<Scalars['String']>;
  file_upload?: Maybe<File_Uploads_Obj_Rel_Insert_Input>;
  file_upload_id?: Maybe<Scalars['String']>;
  home_health_homebound_waiver?: Maybe<Scalars['Boolean']>;
  home_infusion_therapy?: Maybe<Scalars['Boolean']>;
  hospice_care_certification?: Maybe<Scalars['Boolean']>;
  individual_npi?: Maybe<Scalars['String']>;
  last_updated?: Maybe<Scalars['date']>;
  legacy_tin_info?: Maybe<Scalars['String']>;
  legal_business_name?: Maybe<Scalars['String']>;
  medical_nutrition_therapy?: Maybe<Scalars['Boolean']>;
  medicare_id?: Maybe<Scalars['String']>;
  npi_name?: Maybe<Scalars['String']>;
  nurse_practitioner_services?: Maybe<Scalars['Boolean']>;
  organizational_npi?: Maybe<Scalars['String']>;
  overlaps_deficiencies?: Maybe<Scalars['String']>;
  participant_type?: Maybe<Scalars['String']>;
  performance_year?: Maybe<Scalars['String']>;
  post_discharge_home_visit?: Maybe<Scalars['Boolean']>;
  primary_care_capitation_percent_reduction?: Maybe<Scalars['numeric']>;
  primary_care_services?: Maybe<Scalars['String']>;
  provider_class?: Maybe<Scalars['String']>;
  skilled_nursing_facility_snf_3day_stay_waiver?: Maybe<Scalars['Boolean']>;
  sole_proprietor?: Maybe<Scalars['String']>;
  sole_proprietor_tin?: Maybe<Scalars['String']>;
  specialty?: Maybe<Scalars['String']>;
  state_territory?: Maybe<Scalars['String']>;
  telehealth?: Maybe<Scalars['Boolean']>;
  tin?: Maybe<Scalars['String']>;
  tin_type?: Maybe<Scalars['String']>;
  total_care_capitation_percent_reduction?: Maybe<Scalars['numeric']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  uses_cehrt?: Maybe<Scalars['String']>;
  zip_code?: Maybe<Scalars['String']>;
  zip_code_plus_4?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Cms_Dce_Participants_Max_Fields = {
  __typename?: 'cms_dce_participants_max_fields';
  address_1?: Maybe<Scalars['String']>;
  advanced_payment_percent_reduction?: Maybe<Scalars['numeric']>;
  apt_suite?: Maybe<Scalars['String']>;
  billing_tin?: Maybe<Scalars['String']>;
  billing_tin_current_status?: Maybe<Scalars['String']>;
  billing_tin_deficiency?: Maybe<Scalars['String']>;
  ccn?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  cms_dce_participant_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  effective_end_date?: Maybe<Scalars['date']>;
  effective_start_date?: Maybe<Scalars['date']>;
  email?: Maybe<Scalars['String']>;
  entity_id?: Maybe<Scalars['String']>;
  entity_legal_business_name?: Maybe<Scalars['String']>;
  entity_tin?: Maybe<Scalars['String']>;
  file_upload_id?: Maybe<Scalars['String']>;
  individual_npi?: Maybe<Scalars['String']>;
  last_updated?: Maybe<Scalars['date']>;
  legacy_tin_info?: Maybe<Scalars['String']>;
  legal_business_name?: Maybe<Scalars['String']>;
  medicare_id?: Maybe<Scalars['String']>;
  npi_name?: Maybe<Scalars['String']>;
  organizational_npi?: Maybe<Scalars['String']>;
  overlaps_deficiencies?: Maybe<Scalars['String']>;
  participant_type?: Maybe<Scalars['String']>;
  performance_year?: Maybe<Scalars['String']>;
  primary_care_capitation_percent_reduction?: Maybe<Scalars['numeric']>;
  primary_care_services?: Maybe<Scalars['String']>;
  provider_class?: Maybe<Scalars['String']>;
  sole_proprietor?: Maybe<Scalars['String']>;
  sole_proprietor_tin?: Maybe<Scalars['String']>;
  specialty?: Maybe<Scalars['String']>;
  state_territory?: Maybe<Scalars['String']>;
  tin?: Maybe<Scalars['String']>;
  tin_type?: Maybe<Scalars['String']>;
  total_care_capitation_percent_reduction?: Maybe<Scalars['numeric']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  uses_cehrt?: Maybe<Scalars['String']>;
  zip_code?: Maybe<Scalars['String']>;
  zip_code_plus_4?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "cms_dce_participants" */
export type Cms_Dce_Participants_Max_Order_By = {
  address_1?: Maybe<Order_By>;
  advanced_payment_percent_reduction?: Maybe<Order_By>;
  apt_suite?: Maybe<Order_By>;
  billing_tin?: Maybe<Order_By>;
  billing_tin_current_status?: Maybe<Order_By>;
  billing_tin_deficiency?: Maybe<Order_By>;
  ccn?: Maybe<Order_By>;
  city?: Maybe<Order_By>;
  cms_dce_participant_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  effective_end_date?: Maybe<Order_By>;
  effective_start_date?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  entity_id?: Maybe<Order_By>;
  entity_legal_business_name?: Maybe<Order_By>;
  entity_tin?: Maybe<Order_By>;
  file_upload_id?: Maybe<Order_By>;
  individual_npi?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  legacy_tin_info?: Maybe<Order_By>;
  legal_business_name?: Maybe<Order_By>;
  medicare_id?: Maybe<Order_By>;
  npi_name?: Maybe<Order_By>;
  organizational_npi?: Maybe<Order_By>;
  overlaps_deficiencies?: Maybe<Order_By>;
  participant_type?: Maybe<Order_By>;
  performance_year?: Maybe<Order_By>;
  primary_care_capitation_percent_reduction?: Maybe<Order_By>;
  primary_care_services?: Maybe<Order_By>;
  provider_class?: Maybe<Order_By>;
  sole_proprietor?: Maybe<Order_By>;
  sole_proprietor_tin?: Maybe<Order_By>;
  specialty?: Maybe<Order_By>;
  state_territory?: Maybe<Order_By>;
  tin?: Maybe<Order_By>;
  tin_type?: Maybe<Order_By>;
  total_care_capitation_percent_reduction?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  uses_cehrt?: Maybe<Order_By>;
  zip_code?: Maybe<Order_By>;
  zip_code_plus_4?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Cms_Dce_Participants_Min_Fields = {
  __typename?: 'cms_dce_participants_min_fields';
  address_1?: Maybe<Scalars['String']>;
  advanced_payment_percent_reduction?: Maybe<Scalars['numeric']>;
  apt_suite?: Maybe<Scalars['String']>;
  billing_tin?: Maybe<Scalars['String']>;
  billing_tin_current_status?: Maybe<Scalars['String']>;
  billing_tin_deficiency?: Maybe<Scalars['String']>;
  ccn?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  cms_dce_participant_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  effective_end_date?: Maybe<Scalars['date']>;
  effective_start_date?: Maybe<Scalars['date']>;
  email?: Maybe<Scalars['String']>;
  entity_id?: Maybe<Scalars['String']>;
  entity_legal_business_name?: Maybe<Scalars['String']>;
  entity_tin?: Maybe<Scalars['String']>;
  file_upload_id?: Maybe<Scalars['String']>;
  individual_npi?: Maybe<Scalars['String']>;
  last_updated?: Maybe<Scalars['date']>;
  legacy_tin_info?: Maybe<Scalars['String']>;
  legal_business_name?: Maybe<Scalars['String']>;
  medicare_id?: Maybe<Scalars['String']>;
  npi_name?: Maybe<Scalars['String']>;
  organizational_npi?: Maybe<Scalars['String']>;
  overlaps_deficiencies?: Maybe<Scalars['String']>;
  participant_type?: Maybe<Scalars['String']>;
  performance_year?: Maybe<Scalars['String']>;
  primary_care_capitation_percent_reduction?: Maybe<Scalars['numeric']>;
  primary_care_services?: Maybe<Scalars['String']>;
  provider_class?: Maybe<Scalars['String']>;
  sole_proprietor?: Maybe<Scalars['String']>;
  sole_proprietor_tin?: Maybe<Scalars['String']>;
  specialty?: Maybe<Scalars['String']>;
  state_territory?: Maybe<Scalars['String']>;
  tin?: Maybe<Scalars['String']>;
  tin_type?: Maybe<Scalars['String']>;
  total_care_capitation_percent_reduction?: Maybe<Scalars['numeric']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  uses_cehrt?: Maybe<Scalars['String']>;
  zip_code?: Maybe<Scalars['String']>;
  zip_code_plus_4?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "cms_dce_participants" */
export type Cms_Dce_Participants_Min_Order_By = {
  address_1?: Maybe<Order_By>;
  advanced_payment_percent_reduction?: Maybe<Order_By>;
  apt_suite?: Maybe<Order_By>;
  billing_tin?: Maybe<Order_By>;
  billing_tin_current_status?: Maybe<Order_By>;
  billing_tin_deficiency?: Maybe<Order_By>;
  ccn?: Maybe<Order_By>;
  city?: Maybe<Order_By>;
  cms_dce_participant_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  effective_end_date?: Maybe<Order_By>;
  effective_start_date?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  entity_id?: Maybe<Order_By>;
  entity_legal_business_name?: Maybe<Order_By>;
  entity_tin?: Maybe<Order_By>;
  file_upload_id?: Maybe<Order_By>;
  individual_npi?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  legacy_tin_info?: Maybe<Order_By>;
  legal_business_name?: Maybe<Order_By>;
  medicare_id?: Maybe<Order_By>;
  npi_name?: Maybe<Order_By>;
  organizational_npi?: Maybe<Order_By>;
  overlaps_deficiencies?: Maybe<Order_By>;
  participant_type?: Maybe<Order_By>;
  performance_year?: Maybe<Order_By>;
  primary_care_capitation_percent_reduction?: Maybe<Order_By>;
  primary_care_services?: Maybe<Order_By>;
  provider_class?: Maybe<Order_By>;
  sole_proprietor?: Maybe<Order_By>;
  sole_proprietor_tin?: Maybe<Order_By>;
  specialty?: Maybe<Order_By>;
  state_territory?: Maybe<Order_By>;
  tin?: Maybe<Order_By>;
  tin_type?: Maybe<Order_By>;
  total_care_capitation_percent_reduction?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  uses_cehrt?: Maybe<Order_By>;
  zip_code?: Maybe<Order_By>;
  zip_code_plus_4?: Maybe<Order_By>;
};

/** response of any mutation on the table "cms_dce_participants" */
export type Cms_Dce_Participants_Mutation_Response = {
  __typename?: 'cms_dce_participants_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Cms_Dce_Participants>;
};

/** input type for inserting object relation for remote table "cms_dce_participants" */
export type Cms_Dce_Participants_Obj_Rel_Insert_Input = {
  data: Cms_Dce_Participants_Insert_Input;
  on_conflict?: Maybe<Cms_Dce_Participants_On_Conflict>;
};

/** on conflict condition type for table "cms_dce_participants" */
export type Cms_Dce_Participants_On_Conflict = {
  constraint: Cms_Dce_Participants_Constraint;
  update_columns: Array<Cms_Dce_Participants_Update_Column>;
  where?: Maybe<Cms_Dce_Participants_Bool_Exp>;
};

/** ordering options when selecting data from "cms_dce_participants" */
export type Cms_Dce_Participants_Order_By = {
  address_1?: Maybe<Order_By>;
  advanced_payment_percent_reduction?: Maybe<Order_By>;
  apt_suite?: Maybe<Order_By>;
  attestation_y_n?: Maybe<Order_By>;
  billing_tin?: Maybe<Order_By>;
  billing_tin_current_status?: Maybe<Order_By>;
  billing_tin_deficiency?: Maybe<Order_By>;
  cardiac_and_pulmonary_rehabilitation?: Maybe<Order_By>;
  care_management_home_visit?: Maybe<Order_By>;
  ccn?: Maybe<Order_By>;
  chronic_disease_management_reward_bei?: Maybe<Order_By>;
  city?: Maybe<Order_By>;
  cms_dce_participant_id?: Maybe<Order_By>;
  concurrent_care_for_hospice_beneficiaries?: Maybe<Order_By>;
  cost_sharing_for_part_b_services_bei?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  diabetic_shoes?: Maybe<Order_By>;
  effective_end_date?: Maybe<Order_By>;
  effective_start_date?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  entity_id?: Maybe<Order_By>;
  entity_legal_business_name?: Maybe<Order_By>;
  entity_tin?: Maybe<Order_By>;
  file_upload?: Maybe<File_Uploads_Order_By>;
  file_upload_id?: Maybe<Order_By>;
  home_health_homebound_waiver?: Maybe<Order_By>;
  home_infusion_therapy?: Maybe<Order_By>;
  hospice_care_certification?: Maybe<Order_By>;
  individual_npi?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  legacy_tin_info?: Maybe<Order_By>;
  legal_business_name?: Maybe<Order_By>;
  medical_nutrition_therapy?: Maybe<Order_By>;
  medicare_id?: Maybe<Order_By>;
  npi_name?: Maybe<Order_By>;
  nurse_practitioner_services?: Maybe<Order_By>;
  organizational_npi?: Maybe<Order_By>;
  overlaps_deficiencies?: Maybe<Order_By>;
  participant_type?: Maybe<Order_By>;
  performance_year?: Maybe<Order_By>;
  post_discharge_home_visit?: Maybe<Order_By>;
  primary_care_capitation_percent_reduction?: Maybe<Order_By>;
  primary_care_services?: Maybe<Order_By>;
  provider_class?: Maybe<Order_By>;
  skilled_nursing_facility_snf_3day_stay_waiver?: Maybe<Order_By>;
  sole_proprietor?: Maybe<Order_By>;
  sole_proprietor_tin?: Maybe<Order_By>;
  specialty?: Maybe<Order_By>;
  state_territory?: Maybe<Order_By>;
  telehealth?: Maybe<Order_By>;
  tin?: Maybe<Order_By>;
  tin_type?: Maybe<Order_By>;
  total_care_capitation_percent_reduction?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  uses_cehrt?: Maybe<Order_By>;
  zip_code?: Maybe<Order_By>;
  zip_code_plus_4?: Maybe<Order_By>;
};

/** select columns of table "cms_dce_participants" */
export enum Cms_Dce_Participants_Select_Column {
  /** column name */
  Address_1 = 'address_1',
  /** column name */
  AdvancedPaymentPercentReduction = 'advanced_payment_percent_reduction',
  /** column name */
  AptSuite = 'apt_suite',
  /** column name */
  AttestationYN = 'attestation_y_n',
  /** column name */
  BillingTin = 'billing_tin',
  /** column name */
  BillingTinCurrentStatus = 'billing_tin_current_status',
  /** column name */
  BillingTinDeficiency = 'billing_tin_deficiency',
  /** column name */
  CardiacAndPulmonaryRehabilitation = 'cardiac_and_pulmonary_rehabilitation',
  /** column name */
  CareManagementHomeVisit = 'care_management_home_visit',
  /** column name */
  Ccn = 'ccn',
  /** column name */
  ChronicDiseaseManagementRewardBei = 'chronic_disease_management_reward_bei',
  /** column name */
  City = 'city',
  /** column name */
  CmsDceParticipantId = 'cms_dce_participant_id',
  /** column name */
  ConcurrentCareForHospiceBeneficiaries = 'concurrent_care_for_hospice_beneficiaries',
  /** column name */
  CostSharingForPartBServicesBei = 'cost_sharing_for_part_b_services_bei',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DiabeticShoes = 'diabetic_shoes',
  /** column name */
  EffectiveEndDate = 'effective_end_date',
  /** column name */
  EffectiveStartDate = 'effective_start_date',
  /** column name */
  Email = 'email',
  /** column name */
  EntityId = 'entity_id',
  /** column name */
  EntityLegalBusinessName = 'entity_legal_business_name',
  /** column name */
  EntityTin = 'entity_tin',
  /** column name */
  FileUploadId = 'file_upload_id',
  /** column name */
  HomeHealthHomeboundWaiver = 'home_health_homebound_waiver',
  /** column name */
  HomeInfusionTherapy = 'home_infusion_therapy',
  /** column name */
  HospiceCareCertification = 'hospice_care_certification',
  /** column name */
  IndividualNpi = 'individual_npi',
  /** column name */
  LastUpdated = 'last_updated',
  /** column name */
  LegacyTinInfo = 'legacy_tin_info',
  /** column name */
  LegalBusinessName = 'legal_business_name',
  /** column name */
  MedicalNutritionTherapy = 'medical_nutrition_therapy',
  /** column name */
  MedicareId = 'medicare_id',
  /** column name */
  NpiName = 'npi_name',
  /** column name */
  NursePractitionerServices = 'nurse_practitioner_services',
  /** column name */
  OrganizationalNpi = 'organizational_npi',
  /** column name */
  OverlapsDeficiencies = 'overlaps_deficiencies',
  /** column name */
  ParticipantType = 'participant_type',
  /** column name */
  PerformanceYear = 'performance_year',
  /** column name */
  PostDischargeHomeVisit = 'post_discharge_home_visit',
  /** column name */
  PrimaryCareCapitationPercentReduction = 'primary_care_capitation_percent_reduction',
  /** column name */
  PrimaryCareServices = 'primary_care_services',
  /** column name */
  ProviderClass = 'provider_class',
  /** column name */
  SkilledNursingFacilitySnf_3dayStayWaiver = 'skilled_nursing_facility_snf_3day_stay_waiver',
  /** column name */
  SoleProprietor = 'sole_proprietor',
  /** column name */
  SoleProprietorTin = 'sole_proprietor_tin',
  /** column name */
  Specialty = 'specialty',
  /** column name */
  StateTerritory = 'state_territory',
  /** column name */
  Telehealth = 'telehealth',
  /** column name */
  Tin = 'tin',
  /** column name */
  TinType = 'tin_type',
  /** column name */
  TotalCareCapitationPercentReduction = 'total_care_capitation_percent_reduction',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UsesCehrt = 'uses_cehrt',
  /** column name */
  ZipCode = 'zip_code',
  /** column name */
  ZipCodePlus_4 = 'zip_code_plus_4'
}

/** input type for updating data in table "cms_dce_participants" */
export type Cms_Dce_Participants_Set_Input = {
  address_1?: Maybe<Scalars['String']>;
  advanced_payment_percent_reduction?: Maybe<Scalars['numeric']>;
  apt_suite?: Maybe<Scalars['String']>;
  attestation_y_n?: Maybe<Scalars['Boolean']>;
  billing_tin?: Maybe<Scalars['String']>;
  billing_tin_current_status?: Maybe<Scalars['String']>;
  billing_tin_deficiency?: Maybe<Scalars['String']>;
  cardiac_and_pulmonary_rehabilitation?: Maybe<Scalars['Boolean']>;
  care_management_home_visit?: Maybe<Scalars['Boolean']>;
  ccn?: Maybe<Scalars['String']>;
  chronic_disease_management_reward_bei?: Maybe<Scalars['Boolean']>;
  city?: Maybe<Scalars['String']>;
  cms_dce_participant_id?: Maybe<Scalars['Int']>;
  concurrent_care_for_hospice_beneficiaries?: Maybe<Scalars['Boolean']>;
  cost_sharing_for_part_b_services_bei?: Maybe<Scalars['Boolean']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  diabetic_shoes?: Maybe<Scalars['Boolean']>;
  effective_end_date?: Maybe<Scalars['date']>;
  effective_start_date?: Maybe<Scalars['date']>;
  email?: Maybe<Scalars['String']>;
  entity_id?: Maybe<Scalars['String']>;
  entity_legal_business_name?: Maybe<Scalars['String']>;
  entity_tin?: Maybe<Scalars['String']>;
  file_upload_id?: Maybe<Scalars['String']>;
  home_health_homebound_waiver?: Maybe<Scalars['Boolean']>;
  home_infusion_therapy?: Maybe<Scalars['Boolean']>;
  hospice_care_certification?: Maybe<Scalars['Boolean']>;
  individual_npi?: Maybe<Scalars['String']>;
  last_updated?: Maybe<Scalars['date']>;
  legacy_tin_info?: Maybe<Scalars['String']>;
  legal_business_name?: Maybe<Scalars['String']>;
  medical_nutrition_therapy?: Maybe<Scalars['Boolean']>;
  medicare_id?: Maybe<Scalars['String']>;
  npi_name?: Maybe<Scalars['String']>;
  nurse_practitioner_services?: Maybe<Scalars['Boolean']>;
  organizational_npi?: Maybe<Scalars['String']>;
  overlaps_deficiencies?: Maybe<Scalars['String']>;
  participant_type?: Maybe<Scalars['String']>;
  performance_year?: Maybe<Scalars['String']>;
  post_discharge_home_visit?: Maybe<Scalars['Boolean']>;
  primary_care_capitation_percent_reduction?: Maybe<Scalars['numeric']>;
  primary_care_services?: Maybe<Scalars['String']>;
  provider_class?: Maybe<Scalars['String']>;
  skilled_nursing_facility_snf_3day_stay_waiver?: Maybe<Scalars['Boolean']>;
  sole_proprietor?: Maybe<Scalars['String']>;
  sole_proprietor_tin?: Maybe<Scalars['String']>;
  specialty?: Maybe<Scalars['String']>;
  state_territory?: Maybe<Scalars['String']>;
  telehealth?: Maybe<Scalars['Boolean']>;
  tin?: Maybe<Scalars['String']>;
  tin_type?: Maybe<Scalars['String']>;
  total_care_capitation_percent_reduction?: Maybe<Scalars['numeric']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  uses_cehrt?: Maybe<Scalars['String']>;
  zip_code?: Maybe<Scalars['String']>;
  zip_code_plus_4?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Cms_Dce_Participants_Stddev_Fields = {
  __typename?: 'cms_dce_participants_stddev_fields';
  advanced_payment_percent_reduction?: Maybe<Scalars['Float']>;
  cms_dce_participant_id?: Maybe<Scalars['Float']>;
  primary_care_capitation_percent_reduction?: Maybe<Scalars['Float']>;
  total_care_capitation_percent_reduction?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "cms_dce_participants" */
export type Cms_Dce_Participants_Stddev_Order_By = {
  advanced_payment_percent_reduction?: Maybe<Order_By>;
  cms_dce_participant_id?: Maybe<Order_By>;
  primary_care_capitation_percent_reduction?: Maybe<Order_By>;
  total_care_capitation_percent_reduction?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Cms_Dce_Participants_Stddev_Pop_Fields = {
  __typename?: 'cms_dce_participants_stddev_pop_fields';
  advanced_payment_percent_reduction?: Maybe<Scalars['Float']>;
  cms_dce_participant_id?: Maybe<Scalars['Float']>;
  primary_care_capitation_percent_reduction?: Maybe<Scalars['Float']>;
  total_care_capitation_percent_reduction?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "cms_dce_participants" */
export type Cms_Dce_Participants_Stddev_Pop_Order_By = {
  advanced_payment_percent_reduction?: Maybe<Order_By>;
  cms_dce_participant_id?: Maybe<Order_By>;
  primary_care_capitation_percent_reduction?: Maybe<Order_By>;
  total_care_capitation_percent_reduction?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Cms_Dce_Participants_Stddev_Samp_Fields = {
  __typename?: 'cms_dce_participants_stddev_samp_fields';
  advanced_payment_percent_reduction?: Maybe<Scalars['Float']>;
  cms_dce_participant_id?: Maybe<Scalars['Float']>;
  primary_care_capitation_percent_reduction?: Maybe<Scalars['Float']>;
  total_care_capitation_percent_reduction?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "cms_dce_participants" */
export type Cms_Dce_Participants_Stddev_Samp_Order_By = {
  advanced_payment_percent_reduction?: Maybe<Order_By>;
  cms_dce_participant_id?: Maybe<Order_By>;
  primary_care_capitation_percent_reduction?: Maybe<Order_By>;
  total_care_capitation_percent_reduction?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Cms_Dce_Participants_Sum_Fields = {
  __typename?: 'cms_dce_participants_sum_fields';
  advanced_payment_percent_reduction?: Maybe<Scalars['numeric']>;
  cms_dce_participant_id?: Maybe<Scalars['Int']>;
  primary_care_capitation_percent_reduction?: Maybe<Scalars['numeric']>;
  total_care_capitation_percent_reduction?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "cms_dce_participants" */
export type Cms_Dce_Participants_Sum_Order_By = {
  advanced_payment_percent_reduction?: Maybe<Order_By>;
  cms_dce_participant_id?: Maybe<Order_By>;
  primary_care_capitation_percent_reduction?: Maybe<Order_By>;
  total_care_capitation_percent_reduction?: Maybe<Order_By>;
};

/** update columns of table "cms_dce_participants" */
export enum Cms_Dce_Participants_Update_Column {
  /** column name */
  Address_1 = 'address_1',
  /** column name */
  AdvancedPaymentPercentReduction = 'advanced_payment_percent_reduction',
  /** column name */
  AptSuite = 'apt_suite',
  /** column name */
  AttestationYN = 'attestation_y_n',
  /** column name */
  BillingTin = 'billing_tin',
  /** column name */
  BillingTinCurrentStatus = 'billing_tin_current_status',
  /** column name */
  BillingTinDeficiency = 'billing_tin_deficiency',
  /** column name */
  CardiacAndPulmonaryRehabilitation = 'cardiac_and_pulmonary_rehabilitation',
  /** column name */
  CareManagementHomeVisit = 'care_management_home_visit',
  /** column name */
  Ccn = 'ccn',
  /** column name */
  ChronicDiseaseManagementRewardBei = 'chronic_disease_management_reward_bei',
  /** column name */
  City = 'city',
  /** column name */
  CmsDceParticipantId = 'cms_dce_participant_id',
  /** column name */
  ConcurrentCareForHospiceBeneficiaries = 'concurrent_care_for_hospice_beneficiaries',
  /** column name */
  CostSharingForPartBServicesBei = 'cost_sharing_for_part_b_services_bei',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DiabeticShoes = 'diabetic_shoes',
  /** column name */
  EffectiveEndDate = 'effective_end_date',
  /** column name */
  EffectiveStartDate = 'effective_start_date',
  /** column name */
  Email = 'email',
  /** column name */
  EntityId = 'entity_id',
  /** column name */
  EntityLegalBusinessName = 'entity_legal_business_name',
  /** column name */
  EntityTin = 'entity_tin',
  /** column name */
  FileUploadId = 'file_upload_id',
  /** column name */
  HomeHealthHomeboundWaiver = 'home_health_homebound_waiver',
  /** column name */
  HomeInfusionTherapy = 'home_infusion_therapy',
  /** column name */
  HospiceCareCertification = 'hospice_care_certification',
  /** column name */
  IndividualNpi = 'individual_npi',
  /** column name */
  LastUpdated = 'last_updated',
  /** column name */
  LegacyTinInfo = 'legacy_tin_info',
  /** column name */
  LegalBusinessName = 'legal_business_name',
  /** column name */
  MedicalNutritionTherapy = 'medical_nutrition_therapy',
  /** column name */
  MedicareId = 'medicare_id',
  /** column name */
  NpiName = 'npi_name',
  /** column name */
  NursePractitionerServices = 'nurse_practitioner_services',
  /** column name */
  OrganizationalNpi = 'organizational_npi',
  /** column name */
  OverlapsDeficiencies = 'overlaps_deficiencies',
  /** column name */
  ParticipantType = 'participant_type',
  /** column name */
  PerformanceYear = 'performance_year',
  /** column name */
  PostDischargeHomeVisit = 'post_discharge_home_visit',
  /** column name */
  PrimaryCareCapitationPercentReduction = 'primary_care_capitation_percent_reduction',
  /** column name */
  PrimaryCareServices = 'primary_care_services',
  /** column name */
  ProviderClass = 'provider_class',
  /** column name */
  SkilledNursingFacilitySnf_3dayStayWaiver = 'skilled_nursing_facility_snf_3day_stay_waiver',
  /** column name */
  SoleProprietor = 'sole_proprietor',
  /** column name */
  SoleProprietorTin = 'sole_proprietor_tin',
  /** column name */
  Specialty = 'specialty',
  /** column name */
  StateTerritory = 'state_territory',
  /** column name */
  Telehealth = 'telehealth',
  /** column name */
  Tin = 'tin',
  /** column name */
  TinType = 'tin_type',
  /** column name */
  TotalCareCapitationPercentReduction = 'total_care_capitation_percent_reduction',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UsesCehrt = 'uses_cehrt',
  /** column name */
  ZipCode = 'zip_code',
  /** column name */
  ZipCodePlus_4 = 'zip_code_plus_4'
}

/** aggregate var_pop on columns */
export type Cms_Dce_Participants_Var_Pop_Fields = {
  __typename?: 'cms_dce_participants_var_pop_fields';
  advanced_payment_percent_reduction?: Maybe<Scalars['Float']>;
  cms_dce_participant_id?: Maybe<Scalars['Float']>;
  primary_care_capitation_percent_reduction?: Maybe<Scalars['Float']>;
  total_care_capitation_percent_reduction?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "cms_dce_participants" */
export type Cms_Dce_Participants_Var_Pop_Order_By = {
  advanced_payment_percent_reduction?: Maybe<Order_By>;
  cms_dce_participant_id?: Maybe<Order_By>;
  primary_care_capitation_percent_reduction?: Maybe<Order_By>;
  total_care_capitation_percent_reduction?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Cms_Dce_Participants_Var_Samp_Fields = {
  __typename?: 'cms_dce_participants_var_samp_fields';
  advanced_payment_percent_reduction?: Maybe<Scalars['Float']>;
  cms_dce_participant_id?: Maybe<Scalars['Float']>;
  primary_care_capitation_percent_reduction?: Maybe<Scalars['Float']>;
  total_care_capitation_percent_reduction?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "cms_dce_participants" */
export type Cms_Dce_Participants_Var_Samp_Order_By = {
  advanced_payment_percent_reduction?: Maybe<Order_By>;
  cms_dce_participant_id?: Maybe<Order_By>;
  primary_care_capitation_percent_reduction?: Maybe<Order_By>;
  total_care_capitation_percent_reduction?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Cms_Dce_Participants_Variance_Fields = {
  __typename?: 'cms_dce_participants_variance_fields';
  advanced_payment_percent_reduction?: Maybe<Scalars['Float']>;
  cms_dce_participant_id?: Maybe<Scalars['Float']>;
  primary_care_capitation_percent_reduction?: Maybe<Scalars['Float']>;
  total_care_capitation_percent_reduction?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "cms_dce_participants" */
export type Cms_Dce_Participants_Variance_Order_By = {
  advanced_payment_percent_reduction?: Maybe<Order_By>;
  cms_dce_participant_id?: Maybe<Order_By>;
  primary_care_capitation_percent_reduction?: Maybe<Order_By>;
  total_care_capitation_percent_reduction?: Maybe<Order_By>;
};

/** columns and relationships of "cms_provider_alignment_reports" */
export type Cms_Provider_Alignment_Reports = {
  __typename?: 'cms_provider_alignment_reports';
  ACO_ID: Scalars['String'];
  ALGN_TYPE_CLM: Scalars['String'];
  ALGN_TYPE_VA?: Maybe<Scalars['String']>;
  FAC_PRVDR_OSCAR_NUM: Scalars['String'];
  MBI_ID: Scalars['String'];
  PERFORMANCE_YEAR: Scalars['Int'];
  PRVDR_NPI: Scalars['String'];
  PRVDR_TIN: Scalars['String'];
  QEM_ALLOWED_NONPRIMARY_AY1?: Maybe<Scalars['numeric']>;
  QEM_ALLOWED_NONPRIMARY_AY2?: Maybe<Scalars['numeric']>;
  QEM_ALLOWED_OTHER_AY1?: Maybe<Scalars['numeric']>;
  QEM_ALLOWED_OTHER_AY2?: Maybe<Scalars['numeric']>;
  QEM_ALLOWED_PRIMARY_AY1?: Maybe<Scalars['numeric']>;
  QEM_ALLOWED_PRIMARY_AY2?: Maybe<Scalars['numeric']>;
  created_at: Scalars['timestamptz'];
  /** An object relationship */
  file_upload?: Maybe<File_Uploads>;
  file_upload_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregated selection of "cms_provider_alignment_reports" */
export type Cms_Provider_Alignment_Reports_Aggregate = {
  __typename?: 'cms_provider_alignment_reports_aggregate';
  aggregate?: Maybe<Cms_Provider_Alignment_Reports_Aggregate_Fields>;
  nodes: Array<Cms_Provider_Alignment_Reports>;
};

/** aggregate fields of "cms_provider_alignment_reports" */
export type Cms_Provider_Alignment_Reports_Aggregate_Fields = {
  __typename?: 'cms_provider_alignment_reports_aggregate_fields';
  avg?: Maybe<Cms_Provider_Alignment_Reports_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Cms_Provider_Alignment_Reports_Max_Fields>;
  min?: Maybe<Cms_Provider_Alignment_Reports_Min_Fields>;
  stddev?: Maybe<Cms_Provider_Alignment_Reports_Stddev_Fields>;
  stddev_pop?: Maybe<Cms_Provider_Alignment_Reports_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Cms_Provider_Alignment_Reports_Stddev_Samp_Fields>;
  sum?: Maybe<Cms_Provider_Alignment_Reports_Sum_Fields>;
  var_pop?: Maybe<Cms_Provider_Alignment_Reports_Var_Pop_Fields>;
  var_samp?: Maybe<Cms_Provider_Alignment_Reports_Var_Samp_Fields>;
  variance?: Maybe<Cms_Provider_Alignment_Reports_Variance_Fields>;
};


/** aggregate fields of "cms_provider_alignment_reports" */
export type Cms_Provider_Alignment_Reports_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Cms_Provider_Alignment_Reports_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "cms_provider_alignment_reports" */
export type Cms_Provider_Alignment_Reports_Aggregate_Order_By = {
  avg?: Maybe<Cms_Provider_Alignment_Reports_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Cms_Provider_Alignment_Reports_Max_Order_By>;
  min?: Maybe<Cms_Provider_Alignment_Reports_Min_Order_By>;
  stddev?: Maybe<Cms_Provider_Alignment_Reports_Stddev_Order_By>;
  stddev_pop?: Maybe<Cms_Provider_Alignment_Reports_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Cms_Provider_Alignment_Reports_Stddev_Samp_Order_By>;
  sum?: Maybe<Cms_Provider_Alignment_Reports_Sum_Order_By>;
  var_pop?: Maybe<Cms_Provider_Alignment_Reports_Var_Pop_Order_By>;
  var_samp?: Maybe<Cms_Provider_Alignment_Reports_Var_Samp_Order_By>;
  variance?: Maybe<Cms_Provider_Alignment_Reports_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "cms_provider_alignment_reports" */
export type Cms_Provider_Alignment_Reports_Arr_Rel_Insert_Input = {
  data: Array<Cms_Provider_Alignment_Reports_Insert_Input>;
  on_conflict?: Maybe<Cms_Provider_Alignment_Reports_On_Conflict>;
};

/** aggregate avg on columns */
export type Cms_Provider_Alignment_Reports_Avg_Fields = {
  __typename?: 'cms_provider_alignment_reports_avg_fields';
  PERFORMANCE_YEAR?: Maybe<Scalars['Float']>;
  QEM_ALLOWED_NONPRIMARY_AY1?: Maybe<Scalars['Float']>;
  QEM_ALLOWED_NONPRIMARY_AY2?: Maybe<Scalars['Float']>;
  QEM_ALLOWED_OTHER_AY1?: Maybe<Scalars['Float']>;
  QEM_ALLOWED_OTHER_AY2?: Maybe<Scalars['Float']>;
  QEM_ALLOWED_PRIMARY_AY1?: Maybe<Scalars['Float']>;
  QEM_ALLOWED_PRIMARY_AY2?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "cms_provider_alignment_reports" */
export type Cms_Provider_Alignment_Reports_Avg_Order_By = {
  PERFORMANCE_YEAR?: Maybe<Order_By>;
  QEM_ALLOWED_NONPRIMARY_AY1?: Maybe<Order_By>;
  QEM_ALLOWED_NONPRIMARY_AY2?: Maybe<Order_By>;
  QEM_ALLOWED_OTHER_AY1?: Maybe<Order_By>;
  QEM_ALLOWED_OTHER_AY2?: Maybe<Order_By>;
  QEM_ALLOWED_PRIMARY_AY1?: Maybe<Order_By>;
  QEM_ALLOWED_PRIMARY_AY2?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "cms_provider_alignment_reports". All fields are combined with a logical 'AND'. */
export type Cms_Provider_Alignment_Reports_Bool_Exp = {
  ACO_ID?: Maybe<String_Comparison_Exp>;
  ALGN_TYPE_CLM?: Maybe<String_Comparison_Exp>;
  ALGN_TYPE_VA?: Maybe<String_Comparison_Exp>;
  FAC_PRVDR_OSCAR_NUM?: Maybe<String_Comparison_Exp>;
  MBI_ID?: Maybe<String_Comparison_Exp>;
  PERFORMANCE_YEAR?: Maybe<Int_Comparison_Exp>;
  PRVDR_NPI?: Maybe<String_Comparison_Exp>;
  PRVDR_TIN?: Maybe<String_Comparison_Exp>;
  QEM_ALLOWED_NONPRIMARY_AY1?: Maybe<Numeric_Comparison_Exp>;
  QEM_ALLOWED_NONPRIMARY_AY2?: Maybe<Numeric_Comparison_Exp>;
  QEM_ALLOWED_OTHER_AY1?: Maybe<Numeric_Comparison_Exp>;
  QEM_ALLOWED_OTHER_AY2?: Maybe<Numeric_Comparison_Exp>;
  QEM_ALLOWED_PRIMARY_AY1?: Maybe<Numeric_Comparison_Exp>;
  QEM_ALLOWED_PRIMARY_AY2?: Maybe<Numeric_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Cms_Provider_Alignment_Reports_Bool_Exp>>>;
  _not?: Maybe<Cms_Provider_Alignment_Reports_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Cms_Provider_Alignment_Reports_Bool_Exp>>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  file_upload?: Maybe<File_Uploads_Bool_Exp>;
  file_upload_id?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "cms_provider_alignment_reports" */
export enum Cms_Provider_Alignment_Reports_Constraint {
  /** unique or primary key constraint */
  CmsProviderAlignmentReportsPkey = 'cms_provider_alignment_reports_pkey'
}

/** input type for incrementing integer column in table "cms_provider_alignment_reports" */
export type Cms_Provider_Alignment_Reports_Inc_Input = {
  PERFORMANCE_YEAR?: Maybe<Scalars['Int']>;
  QEM_ALLOWED_NONPRIMARY_AY1?: Maybe<Scalars['numeric']>;
  QEM_ALLOWED_NONPRIMARY_AY2?: Maybe<Scalars['numeric']>;
  QEM_ALLOWED_OTHER_AY1?: Maybe<Scalars['numeric']>;
  QEM_ALLOWED_OTHER_AY2?: Maybe<Scalars['numeric']>;
  QEM_ALLOWED_PRIMARY_AY1?: Maybe<Scalars['numeric']>;
  QEM_ALLOWED_PRIMARY_AY2?: Maybe<Scalars['numeric']>;
};

/** input type for inserting data into table "cms_provider_alignment_reports" */
export type Cms_Provider_Alignment_Reports_Insert_Input = {
  ACO_ID?: Maybe<Scalars['String']>;
  ALGN_TYPE_CLM?: Maybe<Scalars['String']>;
  ALGN_TYPE_VA?: Maybe<Scalars['String']>;
  FAC_PRVDR_OSCAR_NUM?: Maybe<Scalars['String']>;
  MBI_ID?: Maybe<Scalars['String']>;
  PERFORMANCE_YEAR?: Maybe<Scalars['Int']>;
  PRVDR_NPI?: Maybe<Scalars['String']>;
  PRVDR_TIN?: Maybe<Scalars['String']>;
  QEM_ALLOWED_NONPRIMARY_AY1?: Maybe<Scalars['numeric']>;
  QEM_ALLOWED_NONPRIMARY_AY2?: Maybe<Scalars['numeric']>;
  QEM_ALLOWED_OTHER_AY1?: Maybe<Scalars['numeric']>;
  QEM_ALLOWED_OTHER_AY2?: Maybe<Scalars['numeric']>;
  QEM_ALLOWED_PRIMARY_AY1?: Maybe<Scalars['numeric']>;
  QEM_ALLOWED_PRIMARY_AY2?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  file_upload?: Maybe<File_Uploads_Obj_Rel_Insert_Input>;
  file_upload_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Cms_Provider_Alignment_Reports_Max_Fields = {
  __typename?: 'cms_provider_alignment_reports_max_fields';
  ACO_ID?: Maybe<Scalars['String']>;
  ALGN_TYPE_CLM?: Maybe<Scalars['String']>;
  ALGN_TYPE_VA?: Maybe<Scalars['String']>;
  FAC_PRVDR_OSCAR_NUM?: Maybe<Scalars['String']>;
  MBI_ID?: Maybe<Scalars['String']>;
  PERFORMANCE_YEAR?: Maybe<Scalars['Int']>;
  PRVDR_NPI?: Maybe<Scalars['String']>;
  PRVDR_TIN?: Maybe<Scalars['String']>;
  QEM_ALLOWED_NONPRIMARY_AY1?: Maybe<Scalars['numeric']>;
  QEM_ALLOWED_NONPRIMARY_AY2?: Maybe<Scalars['numeric']>;
  QEM_ALLOWED_OTHER_AY1?: Maybe<Scalars['numeric']>;
  QEM_ALLOWED_OTHER_AY2?: Maybe<Scalars['numeric']>;
  QEM_ALLOWED_PRIMARY_AY1?: Maybe<Scalars['numeric']>;
  QEM_ALLOWED_PRIMARY_AY2?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  file_upload_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "cms_provider_alignment_reports" */
export type Cms_Provider_Alignment_Reports_Max_Order_By = {
  ACO_ID?: Maybe<Order_By>;
  ALGN_TYPE_CLM?: Maybe<Order_By>;
  ALGN_TYPE_VA?: Maybe<Order_By>;
  FAC_PRVDR_OSCAR_NUM?: Maybe<Order_By>;
  MBI_ID?: Maybe<Order_By>;
  PERFORMANCE_YEAR?: Maybe<Order_By>;
  PRVDR_NPI?: Maybe<Order_By>;
  PRVDR_TIN?: Maybe<Order_By>;
  QEM_ALLOWED_NONPRIMARY_AY1?: Maybe<Order_By>;
  QEM_ALLOWED_NONPRIMARY_AY2?: Maybe<Order_By>;
  QEM_ALLOWED_OTHER_AY1?: Maybe<Order_By>;
  QEM_ALLOWED_OTHER_AY2?: Maybe<Order_By>;
  QEM_ALLOWED_PRIMARY_AY1?: Maybe<Order_By>;
  QEM_ALLOWED_PRIMARY_AY2?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  file_upload_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Cms_Provider_Alignment_Reports_Min_Fields = {
  __typename?: 'cms_provider_alignment_reports_min_fields';
  ACO_ID?: Maybe<Scalars['String']>;
  ALGN_TYPE_CLM?: Maybe<Scalars['String']>;
  ALGN_TYPE_VA?: Maybe<Scalars['String']>;
  FAC_PRVDR_OSCAR_NUM?: Maybe<Scalars['String']>;
  MBI_ID?: Maybe<Scalars['String']>;
  PERFORMANCE_YEAR?: Maybe<Scalars['Int']>;
  PRVDR_NPI?: Maybe<Scalars['String']>;
  PRVDR_TIN?: Maybe<Scalars['String']>;
  QEM_ALLOWED_NONPRIMARY_AY1?: Maybe<Scalars['numeric']>;
  QEM_ALLOWED_NONPRIMARY_AY2?: Maybe<Scalars['numeric']>;
  QEM_ALLOWED_OTHER_AY1?: Maybe<Scalars['numeric']>;
  QEM_ALLOWED_OTHER_AY2?: Maybe<Scalars['numeric']>;
  QEM_ALLOWED_PRIMARY_AY1?: Maybe<Scalars['numeric']>;
  QEM_ALLOWED_PRIMARY_AY2?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  file_upload_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "cms_provider_alignment_reports" */
export type Cms_Provider_Alignment_Reports_Min_Order_By = {
  ACO_ID?: Maybe<Order_By>;
  ALGN_TYPE_CLM?: Maybe<Order_By>;
  ALGN_TYPE_VA?: Maybe<Order_By>;
  FAC_PRVDR_OSCAR_NUM?: Maybe<Order_By>;
  MBI_ID?: Maybe<Order_By>;
  PERFORMANCE_YEAR?: Maybe<Order_By>;
  PRVDR_NPI?: Maybe<Order_By>;
  PRVDR_TIN?: Maybe<Order_By>;
  QEM_ALLOWED_NONPRIMARY_AY1?: Maybe<Order_By>;
  QEM_ALLOWED_NONPRIMARY_AY2?: Maybe<Order_By>;
  QEM_ALLOWED_OTHER_AY1?: Maybe<Order_By>;
  QEM_ALLOWED_OTHER_AY2?: Maybe<Order_By>;
  QEM_ALLOWED_PRIMARY_AY1?: Maybe<Order_By>;
  QEM_ALLOWED_PRIMARY_AY2?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  file_upload_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "cms_provider_alignment_reports" */
export type Cms_Provider_Alignment_Reports_Mutation_Response = {
  __typename?: 'cms_provider_alignment_reports_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Cms_Provider_Alignment_Reports>;
};

/** input type for inserting object relation for remote table "cms_provider_alignment_reports" */
export type Cms_Provider_Alignment_Reports_Obj_Rel_Insert_Input = {
  data: Cms_Provider_Alignment_Reports_Insert_Input;
  on_conflict?: Maybe<Cms_Provider_Alignment_Reports_On_Conflict>;
};

/** on conflict condition type for table "cms_provider_alignment_reports" */
export type Cms_Provider_Alignment_Reports_On_Conflict = {
  constraint: Cms_Provider_Alignment_Reports_Constraint;
  update_columns: Array<Cms_Provider_Alignment_Reports_Update_Column>;
  where?: Maybe<Cms_Provider_Alignment_Reports_Bool_Exp>;
};

/** ordering options when selecting data from "cms_provider_alignment_reports" */
export type Cms_Provider_Alignment_Reports_Order_By = {
  ACO_ID?: Maybe<Order_By>;
  ALGN_TYPE_CLM?: Maybe<Order_By>;
  ALGN_TYPE_VA?: Maybe<Order_By>;
  FAC_PRVDR_OSCAR_NUM?: Maybe<Order_By>;
  MBI_ID?: Maybe<Order_By>;
  PERFORMANCE_YEAR?: Maybe<Order_By>;
  PRVDR_NPI?: Maybe<Order_By>;
  PRVDR_TIN?: Maybe<Order_By>;
  QEM_ALLOWED_NONPRIMARY_AY1?: Maybe<Order_By>;
  QEM_ALLOWED_NONPRIMARY_AY2?: Maybe<Order_By>;
  QEM_ALLOWED_OTHER_AY1?: Maybe<Order_By>;
  QEM_ALLOWED_OTHER_AY2?: Maybe<Order_By>;
  QEM_ALLOWED_PRIMARY_AY1?: Maybe<Order_By>;
  QEM_ALLOWED_PRIMARY_AY2?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  file_upload?: Maybe<File_Uploads_Order_By>;
  file_upload_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: "cms_provider_alignment_reports" */
export type Cms_Provider_Alignment_Reports_Pk_Columns_Input = {
  ACO_ID: Scalars['String'];
  ALGN_TYPE_CLM: Scalars['String'];
  FAC_PRVDR_OSCAR_NUM: Scalars['String'];
  MBI_ID: Scalars['String'];
  PERFORMANCE_YEAR: Scalars['Int'];
  PRVDR_NPI: Scalars['String'];
  PRVDR_TIN: Scalars['String'];
};

/** select columns of table "cms_provider_alignment_reports" */
export enum Cms_Provider_Alignment_Reports_Select_Column {
  /** column name */
  AcoId = 'ACO_ID',
  /** column name */
  AlgnTypeClm = 'ALGN_TYPE_CLM',
  /** column name */
  AlgnTypeVa = 'ALGN_TYPE_VA',
  /** column name */
  FacPrvdrOscarNum = 'FAC_PRVDR_OSCAR_NUM',
  /** column name */
  MbiId = 'MBI_ID',
  /** column name */
  PerformanceYear = 'PERFORMANCE_YEAR',
  /** column name */
  PrvdrNpi = 'PRVDR_NPI',
  /** column name */
  PrvdrTin = 'PRVDR_TIN',
  /** column name */
  QemAllowedNonprimaryAy1 = 'QEM_ALLOWED_NONPRIMARY_AY1',
  /** column name */
  QemAllowedNonprimaryAy2 = 'QEM_ALLOWED_NONPRIMARY_AY2',
  /** column name */
  QemAllowedOtherAy1 = 'QEM_ALLOWED_OTHER_AY1',
  /** column name */
  QemAllowedOtherAy2 = 'QEM_ALLOWED_OTHER_AY2',
  /** column name */
  QemAllowedPrimaryAy1 = 'QEM_ALLOWED_PRIMARY_AY1',
  /** column name */
  QemAllowedPrimaryAy2 = 'QEM_ALLOWED_PRIMARY_AY2',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FileUploadId = 'file_upload_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "cms_provider_alignment_reports" */
export type Cms_Provider_Alignment_Reports_Set_Input = {
  ACO_ID?: Maybe<Scalars['String']>;
  ALGN_TYPE_CLM?: Maybe<Scalars['String']>;
  ALGN_TYPE_VA?: Maybe<Scalars['String']>;
  FAC_PRVDR_OSCAR_NUM?: Maybe<Scalars['String']>;
  MBI_ID?: Maybe<Scalars['String']>;
  PERFORMANCE_YEAR?: Maybe<Scalars['Int']>;
  PRVDR_NPI?: Maybe<Scalars['String']>;
  PRVDR_TIN?: Maybe<Scalars['String']>;
  QEM_ALLOWED_NONPRIMARY_AY1?: Maybe<Scalars['numeric']>;
  QEM_ALLOWED_NONPRIMARY_AY2?: Maybe<Scalars['numeric']>;
  QEM_ALLOWED_OTHER_AY1?: Maybe<Scalars['numeric']>;
  QEM_ALLOWED_OTHER_AY2?: Maybe<Scalars['numeric']>;
  QEM_ALLOWED_PRIMARY_AY1?: Maybe<Scalars['numeric']>;
  QEM_ALLOWED_PRIMARY_AY2?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  file_upload_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Cms_Provider_Alignment_Reports_Stddev_Fields = {
  __typename?: 'cms_provider_alignment_reports_stddev_fields';
  PERFORMANCE_YEAR?: Maybe<Scalars['Float']>;
  QEM_ALLOWED_NONPRIMARY_AY1?: Maybe<Scalars['Float']>;
  QEM_ALLOWED_NONPRIMARY_AY2?: Maybe<Scalars['Float']>;
  QEM_ALLOWED_OTHER_AY1?: Maybe<Scalars['Float']>;
  QEM_ALLOWED_OTHER_AY2?: Maybe<Scalars['Float']>;
  QEM_ALLOWED_PRIMARY_AY1?: Maybe<Scalars['Float']>;
  QEM_ALLOWED_PRIMARY_AY2?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "cms_provider_alignment_reports" */
export type Cms_Provider_Alignment_Reports_Stddev_Order_By = {
  PERFORMANCE_YEAR?: Maybe<Order_By>;
  QEM_ALLOWED_NONPRIMARY_AY1?: Maybe<Order_By>;
  QEM_ALLOWED_NONPRIMARY_AY2?: Maybe<Order_By>;
  QEM_ALLOWED_OTHER_AY1?: Maybe<Order_By>;
  QEM_ALLOWED_OTHER_AY2?: Maybe<Order_By>;
  QEM_ALLOWED_PRIMARY_AY1?: Maybe<Order_By>;
  QEM_ALLOWED_PRIMARY_AY2?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Cms_Provider_Alignment_Reports_Stddev_Pop_Fields = {
  __typename?: 'cms_provider_alignment_reports_stddev_pop_fields';
  PERFORMANCE_YEAR?: Maybe<Scalars['Float']>;
  QEM_ALLOWED_NONPRIMARY_AY1?: Maybe<Scalars['Float']>;
  QEM_ALLOWED_NONPRIMARY_AY2?: Maybe<Scalars['Float']>;
  QEM_ALLOWED_OTHER_AY1?: Maybe<Scalars['Float']>;
  QEM_ALLOWED_OTHER_AY2?: Maybe<Scalars['Float']>;
  QEM_ALLOWED_PRIMARY_AY1?: Maybe<Scalars['Float']>;
  QEM_ALLOWED_PRIMARY_AY2?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "cms_provider_alignment_reports" */
export type Cms_Provider_Alignment_Reports_Stddev_Pop_Order_By = {
  PERFORMANCE_YEAR?: Maybe<Order_By>;
  QEM_ALLOWED_NONPRIMARY_AY1?: Maybe<Order_By>;
  QEM_ALLOWED_NONPRIMARY_AY2?: Maybe<Order_By>;
  QEM_ALLOWED_OTHER_AY1?: Maybe<Order_By>;
  QEM_ALLOWED_OTHER_AY2?: Maybe<Order_By>;
  QEM_ALLOWED_PRIMARY_AY1?: Maybe<Order_By>;
  QEM_ALLOWED_PRIMARY_AY2?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Cms_Provider_Alignment_Reports_Stddev_Samp_Fields = {
  __typename?: 'cms_provider_alignment_reports_stddev_samp_fields';
  PERFORMANCE_YEAR?: Maybe<Scalars['Float']>;
  QEM_ALLOWED_NONPRIMARY_AY1?: Maybe<Scalars['Float']>;
  QEM_ALLOWED_NONPRIMARY_AY2?: Maybe<Scalars['Float']>;
  QEM_ALLOWED_OTHER_AY1?: Maybe<Scalars['Float']>;
  QEM_ALLOWED_OTHER_AY2?: Maybe<Scalars['Float']>;
  QEM_ALLOWED_PRIMARY_AY1?: Maybe<Scalars['Float']>;
  QEM_ALLOWED_PRIMARY_AY2?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "cms_provider_alignment_reports" */
export type Cms_Provider_Alignment_Reports_Stddev_Samp_Order_By = {
  PERFORMANCE_YEAR?: Maybe<Order_By>;
  QEM_ALLOWED_NONPRIMARY_AY1?: Maybe<Order_By>;
  QEM_ALLOWED_NONPRIMARY_AY2?: Maybe<Order_By>;
  QEM_ALLOWED_OTHER_AY1?: Maybe<Order_By>;
  QEM_ALLOWED_OTHER_AY2?: Maybe<Order_By>;
  QEM_ALLOWED_PRIMARY_AY1?: Maybe<Order_By>;
  QEM_ALLOWED_PRIMARY_AY2?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Cms_Provider_Alignment_Reports_Sum_Fields = {
  __typename?: 'cms_provider_alignment_reports_sum_fields';
  PERFORMANCE_YEAR?: Maybe<Scalars['Int']>;
  QEM_ALLOWED_NONPRIMARY_AY1?: Maybe<Scalars['numeric']>;
  QEM_ALLOWED_NONPRIMARY_AY2?: Maybe<Scalars['numeric']>;
  QEM_ALLOWED_OTHER_AY1?: Maybe<Scalars['numeric']>;
  QEM_ALLOWED_OTHER_AY2?: Maybe<Scalars['numeric']>;
  QEM_ALLOWED_PRIMARY_AY1?: Maybe<Scalars['numeric']>;
  QEM_ALLOWED_PRIMARY_AY2?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "cms_provider_alignment_reports" */
export type Cms_Provider_Alignment_Reports_Sum_Order_By = {
  PERFORMANCE_YEAR?: Maybe<Order_By>;
  QEM_ALLOWED_NONPRIMARY_AY1?: Maybe<Order_By>;
  QEM_ALLOWED_NONPRIMARY_AY2?: Maybe<Order_By>;
  QEM_ALLOWED_OTHER_AY1?: Maybe<Order_By>;
  QEM_ALLOWED_OTHER_AY2?: Maybe<Order_By>;
  QEM_ALLOWED_PRIMARY_AY1?: Maybe<Order_By>;
  QEM_ALLOWED_PRIMARY_AY2?: Maybe<Order_By>;
};

/** update columns of table "cms_provider_alignment_reports" */
export enum Cms_Provider_Alignment_Reports_Update_Column {
  /** column name */
  AcoId = 'ACO_ID',
  /** column name */
  AlgnTypeClm = 'ALGN_TYPE_CLM',
  /** column name */
  AlgnTypeVa = 'ALGN_TYPE_VA',
  /** column name */
  FacPrvdrOscarNum = 'FAC_PRVDR_OSCAR_NUM',
  /** column name */
  MbiId = 'MBI_ID',
  /** column name */
  PerformanceYear = 'PERFORMANCE_YEAR',
  /** column name */
  PrvdrNpi = 'PRVDR_NPI',
  /** column name */
  PrvdrTin = 'PRVDR_TIN',
  /** column name */
  QemAllowedNonprimaryAy1 = 'QEM_ALLOWED_NONPRIMARY_AY1',
  /** column name */
  QemAllowedNonprimaryAy2 = 'QEM_ALLOWED_NONPRIMARY_AY2',
  /** column name */
  QemAllowedOtherAy1 = 'QEM_ALLOWED_OTHER_AY1',
  /** column name */
  QemAllowedOtherAy2 = 'QEM_ALLOWED_OTHER_AY2',
  /** column name */
  QemAllowedPrimaryAy1 = 'QEM_ALLOWED_PRIMARY_AY1',
  /** column name */
  QemAllowedPrimaryAy2 = 'QEM_ALLOWED_PRIMARY_AY2',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FileUploadId = 'file_upload_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Cms_Provider_Alignment_Reports_Var_Pop_Fields = {
  __typename?: 'cms_provider_alignment_reports_var_pop_fields';
  PERFORMANCE_YEAR?: Maybe<Scalars['Float']>;
  QEM_ALLOWED_NONPRIMARY_AY1?: Maybe<Scalars['Float']>;
  QEM_ALLOWED_NONPRIMARY_AY2?: Maybe<Scalars['Float']>;
  QEM_ALLOWED_OTHER_AY1?: Maybe<Scalars['Float']>;
  QEM_ALLOWED_OTHER_AY2?: Maybe<Scalars['Float']>;
  QEM_ALLOWED_PRIMARY_AY1?: Maybe<Scalars['Float']>;
  QEM_ALLOWED_PRIMARY_AY2?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "cms_provider_alignment_reports" */
export type Cms_Provider_Alignment_Reports_Var_Pop_Order_By = {
  PERFORMANCE_YEAR?: Maybe<Order_By>;
  QEM_ALLOWED_NONPRIMARY_AY1?: Maybe<Order_By>;
  QEM_ALLOWED_NONPRIMARY_AY2?: Maybe<Order_By>;
  QEM_ALLOWED_OTHER_AY1?: Maybe<Order_By>;
  QEM_ALLOWED_OTHER_AY2?: Maybe<Order_By>;
  QEM_ALLOWED_PRIMARY_AY1?: Maybe<Order_By>;
  QEM_ALLOWED_PRIMARY_AY2?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Cms_Provider_Alignment_Reports_Var_Samp_Fields = {
  __typename?: 'cms_provider_alignment_reports_var_samp_fields';
  PERFORMANCE_YEAR?: Maybe<Scalars['Float']>;
  QEM_ALLOWED_NONPRIMARY_AY1?: Maybe<Scalars['Float']>;
  QEM_ALLOWED_NONPRIMARY_AY2?: Maybe<Scalars['Float']>;
  QEM_ALLOWED_OTHER_AY1?: Maybe<Scalars['Float']>;
  QEM_ALLOWED_OTHER_AY2?: Maybe<Scalars['Float']>;
  QEM_ALLOWED_PRIMARY_AY1?: Maybe<Scalars['Float']>;
  QEM_ALLOWED_PRIMARY_AY2?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "cms_provider_alignment_reports" */
export type Cms_Provider_Alignment_Reports_Var_Samp_Order_By = {
  PERFORMANCE_YEAR?: Maybe<Order_By>;
  QEM_ALLOWED_NONPRIMARY_AY1?: Maybe<Order_By>;
  QEM_ALLOWED_NONPRIMARY_AY2?: Maybe<Order_By>;
  QEM_ALLOWED_OTHER_AY1?: Maybe<Order_By>;
  QEM_ALLOWED_OTHER_AY2?: Maybe<Order_By>;
  QEM_ALLOWED_PRIMARY_AY1?: Maybe<Order_By>;
  QEM_ALLOWED_PRIMARY_AY2?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Cms_Provider_Alignment_Reports_Variance_Fields = {
  __typename?: 'cms_provider_alignment_reports_variance_fields';
  PERFORMANCE_YEAR?: Maybe<Scalars['Float']>;
  QEM_ALLOWED_NONPRIMARY_AY1?: Maybe<Scalars['Float']>;
  QEM_ALLOWED_NONPRIMARY_AY2?: Maybe<Scalars['Float']>;
  QEM_ALLOWED_OTHER_AY1?: Maybe<Scalars['Float']>;
  QEM_ALLOWED_OTHER_AY2?: Maybe<Scalars['Float']>;
  QEM_ALLOWED_PRIMARY_AY1?: Maybe<Scalars['Float']>;
  QEM_ALLOWED_PRIMARY_AY2?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "cms_provider_alignment_reports" */
export type Cms_Provider_Alignment_Reports_Variance_Order_By = {
  PERFORMANCE_YEAR?: Maybe<Order_By>;
  QEM_ALLOWED_NONPRIMARY_AY1?: Maybe<Order_By>;
  QEM_ALLOWED_NONPRIMARY_AY2?: Maybe<Order_By>;
  QEM_ALLOWED_OTHER_AY1?: Maybe<Order_By>;
  QEM_ALLOWED_OTHER_AY2?: Maybe<Order_By>;
  QEM_ALLOWED_PRIMARY_AY1?: Maybe<Order_By>;
  QEM_ALLOWED_PRIMARY_AY2?: Maybe<Order_By>;
};

/** columns and relationships of "cms_pva_responses" */
export type Cms_Pva_Responses = {
  __typename?: 'cms_pva_responses';
  ALIGN_FLAG?: Maybe<Scalars['String']>;
  BENE_CITY?: Maybe<Scalars['String']>;
  BENE_FIRST_NAME?: Maybe<Scalars['String']>;
  BENE_LAST_NAME?: Maybe<Scalars['String']>;
  BENE_LINE_1_ADDRESS?: Maybe<Scalars['String']>;
  BENE_LINE_2_ADDRESS?: Maybe<Scalars['String']>;
  BENE_MBI?: Maybe<Scalars['String']>;
  BENE_STATE?: Maybe<Scalars['String']>;
  BENE_ZIPCODE?: Maybe<Scalars['String']>;
  DCE_ID: Scalars['String'];
  ID_RECEIVED: Scalars['String'];
  IND_NPI: Scalars['String'];
  IND_TIN: Scalars['String'];
  PRACTITIONER_NAME?: Maybe<Scalars['String']>;
  PROVIDER_NAME?: Maybe<Scalars['String']>;
  RESPONSE_CODE_LIST?: Maybe<Scalars['String']>;
  SIGNATURE_DATE: Scalars['date'];
  SUBMISSION_DATE: Scalars['date'];
  VALID_FLAG?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamptz'];
  /** An object relationship */
  file_upload?: Maybe<File_Uploads>;
  file_upload_id?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "cms_pva_responses" */
export type Cms_Pva_Responses_Aggregate = {
  __typename?: 'cms_pva_responses_aggregate';
  aggregate?: Maybe<Cms_Pva_Responses_Aggregate_Fields>;
  nodes: Array<Cms_Pva_Responses>;
};

/** aggregate fields of "cms_pva_responses" */
export type Cms_Pva_Responses_Aggregate_Fields = {
  __typename?: 'cms_pva_responses_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Cms_Pva_Responses_Max_Fields>;
  min?: Maybe<Cms_Pva_Responses_Min_Fields>;
};


/** aggregate fields of "cms_pva_responses" */
export type Cms_Pva_Responses_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Cms_Pva_Responses_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "cms_pva_responses" */
export type Cms_Pva_Responses_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Cms_Pva_Responses_Max_Order_By>;
  min?: Maybe<Cms_Pva_Responses_Min_Order_By>;
};

/** input type for inserting array relation for remote table "cms_pva_responses" */
export type Cms_Pva_Responses_Arr_Rel_Insert_Input = {
  data: Array<Cms_Pva_Responses_Insert_Input>;
  on_conflict?: Maybe<Cms_Pva_Responses_On_Conflict>;
};

/** Boolean expression to filter rows from the table "cms_pva_responses". All fields are combined with a logical 'AND'. */
export type Cms_Pva_Responses_Bool_Exp = {
  ALIGN_FLAG?: Maybe<String_Comparison_Exp>;
  BENE_CITY?: Maybe<String_Comparison_Exp>;
  BENE_FIRST_NAME?: Maybe<String_Comparison_Exp>;
  BENE_LAST_NAME?: Maybe<String_Comparison_Exp>;
  BENE_LINE_1_ADDRESS?: Maybe<String_Comparison_Exp>;
  BENE_LINE_2_ADDRESS?: Maybe<String_Comparison_Exp>;
  BENE_MBI?: Maybe<String_Comparison_Exp>;
  BENE_STATE?: Maybe<String_Comparison_Exp>;
  BENE_ZIPCODE?: Maybe<String_Comparison_Exp>;
  DCE_ID?: Maybe<String_Comparison_Exp>;
  ID_RECEIVED?: Maybe<String_Comparison_Exp>;
  IND_NPI?: Maybe<String_Comparison_Exp>;
  IND_TIN?: Maybe<String_Comparison_Exp>;
  PRACTITIONER_NAME?: Maybe<String_Comparison_Exp>;
  PROVIDER_NAME?: Maybe<String_Comparison_Exp>;
  RESPONSE_CODE_LIST?: Maybe<String_Comparison_Exp>;
  SIGNATURE_DATE?: Maybe<Date_Comparison_Exp>;
  SUBMISSION_DATE?: Maybe<Date_Comparison_Exp>;
  VALID_FLAG?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Cms_Pva_Responses_Bool_Exp>>>;
  _not?: Maybe<Cms_Pva_Responses_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Cms_Pva_Responses_Bool_Exp>>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  file_upload?: Maybe<File_Uploads_Bool_Exp>;
  file_upload_id?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "cms_pva_responses" */
export enum Cms_Pva_Responses_Constraint {
  /** unique or primary key constraint */
  CmsPvaResponsesPkey = 'cms_pva_responses_pkey'
}

/** input type for inserting data into table "cms_pva_responses" */
export type Cms_Pva_Responses_Insert_Input = {
  ALIGN_FLAG?: Maybe<Scalars['String']>;
  BENE_CITY?: Maybe<Scalars['String']>;
  BENE_FIRST_NAME?: Maybe<Scalars['String']>;
  BENE_LAST_NAME?: Maybe<Scalars['String']>;
  BENE_LINE_1_ADDRESS?: Maybe<Scalars['String']>;
  BENE_LINE_2_ADDRESS?: Maybe<Scalars['String']>;
  BENE_MBI?: Maybe<Scalars['String']>;
  BENE_STATE?: Maybe<Scalars['String']>;
  BENE_ZIPCODE?: Maybe<Scalars['String']>;
  DCE_ID?: Maybe<Scalars['String']>;
  ID_RECEIVED?: Maybe<Scalars['String']>;
  IND_NPI?: Maybe<Scalars['String']>;
  IND_TIN?: Maybe<Scalars['String']>;
  PRACTITIONER_NAME?: Maybe<Scalars['String']>;
  PROVIDER_NAME?: Maybe<Scalars['String']>;
  RESPONSE_CODE_LIST?: Maybe<Scalars['String']>;
  SIGNATURE_DATE?: Maybe<Scalars['date']>;
  SUBMISSION_DATE?: Maybe<Scalars['date']>;
  VALID_FLAG?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  file_upload?: Maybe<File_Uploads_Obj_Rel_Insert_Input>;
  file_upload_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Cms_Pva_Responses_Max_Fields = {
  __typename?: 'cms_pva_responses_max_fields';
  ALIGN_FLAG?: Maybe<Scalars['String']>;
  BENE_CITY?: Maybe<Scalars['String']>;
  BENE_FIRST_NAME?: Maybe<Scalars['String']>;
  BENE_LAST_NAME?: Maybe<Scalars['String']>;
  BENE_LINE_1_ADDRESS?: Maybe<Scalars['String']>;
  BENE_LINE_2_ADDRESS?: Maybe<Scalars['String']>;
  BENE_MBI?: Maybe<Scalars['String']>;
  BENE_STATE?: Maybe<Scalars['String']>;
  BENE_ZIPCODE?: Maybe<Scalars['String']>;
  DCE_ID?: Maybe<Scalars['String']>;
  ID_RECEIVED?: Maybe<Scalars['String']>;
  IND_NPI?: Maybe<Scalars['String']>;
  IND_TIN?: Maybe<Scalars['String']>;
  PRACTITIONER_NAME?: Maybe<Scalars['String']>;
  PROVIDER_NAME?: Maybe<Scalars['String']>;
  RESPONSE_CODE_LIST?: Maybe<Scalars['String']>;
  SIGNATURE_DATE?: Maybe<Scalars['date']>;
  SUBMISSION_DATE?: Maybe<Scalars['date']>;
  VALID_FLAG?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  file_upload_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "cms_pva_responses" */
export type Cms_Pva_Responses_Max_Order_By = {
  ALIGN_FLAG?: Maybe<Order_By>;
  BENE_CITY?: Maybe<Order_By>;
  BENE_FIRST_NAME?: Maybe<Order_By>;
  BENE_LAST_NAME?: Maybe<Order_By>;
  BENE_LINE_1_ADDRESS?: Maybe<Order_By>;
  BENE_LINE_2_ADDRESS?: Maybe<Order_By>;
  BENE_MBI?: Maybe<Order_By>;
  BENE_STATE?: Maybe<Order_By>;
  BENE_ZIPCODE?: Maybe<Order_By>;
  DCE_ID?: Maybe<Order_By>;
  ID_RECEIVED?: Maybe<Order_By>;
  IND_NPI?: Maybe<Order_By>;
  IND_TIN?: Maybe<Order_By>;
  PRACTITIONER_NAME?: Maybe<Order_By>;
  PROVIDER_NAME?: Maybe<Order_By>;
  RESPONSE_CODE_LIST?: Maybe<Order_By>;
  SIGNATURE_DATE?: Maybe<Order_By>;
  SUBMISSION_DATE?: Maybe<Order_By>;
  VALID_FLAG?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  file_upload_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Cms_Pva_Responses_Min_Fields = {
  __typename?: 'cms_pva_responses_min_fields';
  ALIGN_FLAG?: Maybe<Scalars['String']>;
  BENE_CITY?: Maybe<Scalars['String']>;
  BENE_FIRST_NAME?: Maybe<Scalars['String']>;
  BENE_LAST_NAME?: Maybe<Scalars['String']>;
  BENE_LINE_1_ADDRESS?: Maybe<Scalars['String']>;
  BENE_LINE_2_ADDRESS?: Maybe<Scalars['String']>;
  BENE_MBI?: Maybe<Scalars['String']>;
  BENE_STATE?: Maybe<Scalars['String']>;
  BENE_ZIPCODE?: Maybe<Scalars['String']>;
  DCE_ID?: Maybe<Scalars['String']>;
  ID_RECEIVED?: Maybe<Scalars['String']>;
  IND_NPI?: Maybe<Scalars['String']>;
  IND_TIN?: Maybe<Scalars['String']>;
  PRACTITIONER_NAME?: Maybe<Scalars['String']>;
  PROVIDER_NAME?: Maybe<Scalars['String']>;
  RESPONSE_CODE_LIST?: Maybe<Scalars['String']>;
  SIGNATURE_DATE?: Maybe<Scalars['date']>;
  SUBMISSION_DATE?: Maybe<Scalars['date']>;
  VALID_FLAG?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  file_upload_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "cms_pva_responses" */
export type Cms_Pva_Responses_Min_Order_By = {
  ALIGN_FLAG?: Maybe<Order_By>;
  BENE_CITY?: Maybe<Order_By>;
  BENE_FIRST_NAME?: Maybe<Order_By>;
  BENE_LAST_NAME?: Maybe<Order_By>;
  BENE_LINE_1_ADDRESS?: Maybe<Order_By>;
  BENE_LINE_2_ADDRESS?: Maybe<Order_By>;
  BENE_MBI?: Maybe<Order_By>;
  BENE_STATE?: Maybe<Order_By>;
  BENE_ZIPCODE?: Maybe<Order_By>;
  DCE_ID?: Maybe<Order_By>;
  ID_RECEIVED?: Maybe<Order_By>;
  IND_NPI?: Maybe<Order_By>;
  IND_TIN?: Maybe<Order_By>;
  PRACTITIONER_NAME?: Maybe<Order_By>;
  PROVIDER_NAME?: Maybe<Order_By>;
  RESPONSE_CODE_LIST?: Maybe<Order_By>;
  SIGNATURE_DATE?: Maybe<Order_By>;
  SUBMISSION_DATE?: Maybe<Order_By>;
  VALID_FLAG?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  file_upload_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "cms_pva_responses" */
export type Cms_Pva_Responses_Mutation_Response = {
  __typename?: 'cms_pva_responses_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Cms_Pva_Responses>;
};

/** input type for inserting object relation for remote table "cms_pva_responses" */
export type Cms_Pva_Responses_Obj_Rel_Insert_Input = {
  data: Cms_Pva_Responses_Insert_Input;
  on_conflict?: Maybe<Cms_Pva_Responses_On_Conflict>;
};

/** on conflict condition type for table "cms_pva_responses" */
export type Cms_Pva_Responses_On_Conflict = {
  constraint: Cms_Pva_Responses_Constraint;
  update_columns: Array<Cms_Pva_Responses_Update_Column>;
  where?: Maybe<Cms_Pva_Responses_Bool_Exp>;
};

/** ordering options when selecting data from "cms_pva_responses" */
export type Cms_Pva_Responses_Order_By = {
  ALIGN_FLAG?: Maybe<Order_By>;
  BENE_CITY?: Maybe<Order_By>;
  BENE_FIRST_NAME?: Maybe<Order_By>;
  BENE_LAST_NAME?: Maybe<Order_By>;
  BENE_LINE_1_ADDRESS?: Maybe<Order_By>;
  BENE_LINE_2_ADDRESS?: Maybe<Order_By>;
  BENE_MBI?: Maybe<Order_By>;
  BENE_STATE?: Maybe<Order_By>;
  BENE_ZIPCODE?: Maybe<Order_By>;
  DCE_ID?: Maybe<Order_By>;
  ID_RECEIVED?: Maybe<Order_By>;
  IND_NPI?: Maybe<Order_By>;
  IND_TIN?: Maybe<Order_By>;
  PRACTITIONER_NAME?: Maybe<Order_By>;
  PROVIDER_NAME?: Maybe<Order_By>;
  RESPONSE_CODE_LIST?: Maybe<Order_By>;
  SIGNATURE_DATE?: Maybe<Order_By>;
  SUBMISSION_DATE?: Maybe<Order_By>;
  VALID_FLAG?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  file_upload?: Maybe<File_Uploads_Order_By>;
  file_upload_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: "cms_pva_responses" */
export type Cms_Pva_Responses_Pk_Columns_Input = {
  DCE_ID: Scalars['String'];
  ID_RECEIVED: Scalars['String'];
  IND_NPI: Scalars['String'];
  IND_TIN: Scalars['String'];
  SIGNATURE_DATE: Scalars['date'];
  SUBMISSION_DATE: Scalars['date'];
};

/** select columns of table "cms_pva_responses" */
export enum Cms_Pva_Responses_Select_Column {
  /** column name */
  AlignFlag = 'ALIGN_FLAG',
  /** column name */
  BeneCity = 'BENE_CITY',
  /** column name */
  BeneFirstName = 'BENE_FIRST_NAME',
  /** column name */
  BeneLastName = 'BENE_LAST_NAME',
  /** column name */
  BeneLine_1Address = 'BENE_LINE_1_ADDRESS',
  /** column name */
  BeneLine_2Address = 'BENE_LINE_2_ADDRESS',
  /** column name */
  BeneMbi = 'BENE_MBI',
  /** column name */
  BeneState = 'BENE_STATE',
  /** column name */
  BeneZipcode = 'BENE_ZIPCODE',
  /** column name */
  DceId = 'DCE_ID',
  /** column name */
  IdReceived = 'ID_RECEIVED',
  /** column name */
  IndNpi = 'IND_NPI',
  /** column name */
  IndTin = 'IND_TIN',
  /** column name */
  PractitionerName = 'PRACTITIONER_NAME',
  /** column name */
  ProviderName = 'PROVIDER_NAME',
  /** column name */
  ResponseCodeList = 'RESPONSE_CODE_LIST',
  /** column name */
  SignatureDate = 'SIGNATURE_DATE',
  /** column name */
  SubmissionDate = 'SUBMISSION_DATE',
  /** column name */
  ValidFlag = 'VALID_FLAG',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FileUploadId = 'file_upload_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "cms_pva_responses" */
export type Cms_Pva_Responses_Set_Input = {
  ALIGN_FLAG?: Maybe<Scalars['String']>;
  BENE_CITY?: Maybe<Scalars['String']>;
  BENE_FIRST_NAME?: Maybe<Scalars['String']>;
  BENE_LAST_NAME?: Maybe<Scalars['String']>;
  BENE_LINE_1_ADDRESS?: Maybe<Scalars['String']>;
  BENE_LINE_2_ADDRESS?: Maybe<Scalars['String']>;
  BENE_MBI?: Maybe<Scalars['String']>;
  BENE_STATE?: Maybe<Scalars['String']>;
  BENE_ZIPCODE?: Maybe<Scalars['String']>;
  DCE_ID?: Maybe<Scalars['String']>;
  ID_RECEIVED?: Maybe<Scalars['String']>;
  IND_NPI?: Maybe<Scalars['String']>;
  IND_TIN?: Maybe<Scalars['String']>;
  PRACTITIONER_NAME?: Maybe<Scalars['String']>;
  PROVIDER_NAME?: Maybe<Scalars['String']>;
  RESPONSE_CODE_LIST?: Maybe<Scalars['String']>;
  SIGNATURE_DATE?: Maybe<Scalars['date']>;
  SUBMISSION_DATE?: Maybe<Scalars['date']>;
  VALID_FLAG?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  file_upload_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "cms_pva_responses" */
export enum Cms_Pva_Responses_Update_Column {
  /** column name */
  AlignFlag = 'ALIGN_FLAG',
  /** column name */
  BeneCity = 'BENE_CITY',
  /** column name */
  BeneFirstName = 'BENE_FIRST_NAME',
  /** column name */
  BeneLastName = 'BENE_LAST_NAME',
  /** column name */
  BeneLine_1Address = 'BENE_LINE_1_ADDRESS',
  /** column name */
  BeneLine_2Address = 'BENE_LINE_2_ADDRESS',
  /** column name */
  BeneMbi = 'BENE_MBI',
  /** column name */
  BeneState = 'BENE_STATE',
  /** column name */
  BeneZipcode = 'BENE_ZIPCODE',
  /** column name */
  DceId = 'DCE_ID',
  /** column name */
  IdReceived = 'ID_RECEIVED',
  /** column name */
  IndNpi = 'IND_NPI',
  /** column name */
  IndTin = 'IND_TIN',
  /** column name */
  PractitionerName = 'PRACTITIONER_NAME',
  /** column name */
  ProviderName = 'PROVIDER_NAME',
  /** column name */
  ResponseCodeList = 'RESPONSE_CODE_LIST',
  /** column name */
  SignatureDate = 'SIGNATURE_DATE',
  /** column name */
  SubmissionDate = 'SUBMISSION_DATE',
  /** column name */
  ValidFlag = 'VALID_FLAG',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FileUploadId = 'file_upload_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** columns and relationships of "component_tracking_ids" */
export type Component_Tracking_Ids = {
  __typename?: 'component_tracking_ids';
  /** An object relationship */
  campaign_email_component?: Maybe<Campaign_Email_Components>;
  /** An object relationship */
  campaign_letter_component?: Maybe<Campaign_Letter_Components>;
  /** An object relationship */
  campaign_sms_component?: Maybe<Campaign_Sms_Components>;
  component_id: Scalars['String'];
  created_at?: Maybe<Scalars['timestamptz']>;
  tracking_id: Scalars['String'];
};

/** aggregated selection of "component_tracking_ids" */
export type Component_Tracking_Ids_Aggregate = {
  __typename?: 'component_tracking_ids_aggregate';
  aggregate?: Maybe<Component_Tracking_Ids_Aggregate_Fields>;
  nodes: Array<Component_Tracking_Ids>;
};

/** aggregate fields of "component_tracking_ids" */
export type Component_Tracking_Ids_Aggregate_Fields = {
  __typename?: 'component_tracking_ids_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Component_Tracking_Ids_Max_Fields>;
  min?: Maybe<Component_Tracking_Ids_Min_Fields>;
};


/** aggregate fields of "component_tracking_ids" */
export type Component_Tracking_Ids_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Component_Tracking_Ids_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "component_tracking_ids" */
export type Component_Tracking_Ids_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Component_Tracking_Ids_Max_Order_By>;
  min?: Maybe<Component_Tracking_Ids_Min_Order_By>;
};

/** input type for inserting array relation for remote table "component_tracking_ids" */
export type Component_Tracking_Ids_Arr_Rel_Insert_Input = {
  data: Array<Component_Tracking_Ids_Insert_Input>;
  on_conflict?: Maybe<Component_Tracking_Ids_On_Conflict>;
};

/** Boolean expression to filter rows from the table "component_tracking_ids". All fields are combined with a logical 'AND'. */
export type Component_Tracking_Ids_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Component_Tracking_Ids_Bool_Exp>>>;
  _not?: Maybe<Component_Tracking_Ids_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Component_Tracking_Ids_Bool_Exp>>>;
  campaign_email_component?: Maybe<Campaign_Email_Components_Bool_Exp>;
  campaign_letter_component?: Maybe<Campaign_Letter_Components_Bool_Exp>;
  campaign_sms_component?: Maybe<Campaign_Sms_Components_Bool_Exp>;
  component_id?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  tracking_id?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "component_tracking_ids" */
export enum Component_Tracking_Ids_Constraint {
  /** unique or primary key constraint */
  ComponentTrackingIdsPkey = 'component_tracking_ids_pkey'
}

/** input type for inserting data into table "component_tracking_ids" */
export type Component_Tracking_Ids_Insert_Input = {
  campaign_email_component?: Maybe<Campaign_Email_Components_Obj_Rel_Insert_Input>;
  campaign_letter_component?: Maybe<Campaign_Letter_Components_Obj_Rel_Insert_Input>;
  campaign_sms_component?: Maybe<Campaign_Sms_Components_Obj_Rel_Insert_Input>;
  component_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  tracking_id?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Component_Tracking_Ids_Max_Fields = {
  __typename?: 'component_tracking_ids_max_fields';
  component_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  tracking_id?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "component_tracking_ids" */
export type Component_Tracking_Ids_Max_Order_By = {
  component_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  tracking_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Component_Tracking_Ids_Min_Fields = {
  __typename?: 'component_tracking_ids_min_fields';
  component_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  tracking_id?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "component_tracking_ids" */
export type Component_Tracking_Ids_Min_Order_By = {
  component_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  tracking_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "component_tracking_ids" */
export type Component_Tracking_Ids_Mutation_Response = {
  __typename?: 'component_tracking_ids_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Component_Tracking_Ids>;
};

/** input type for inserting object relation for remote table "component_tracking_ids" */
export type Component_Tracking_Ids_Obj_Rel_Insert_Input = {
  data: Component_Tracking_Ids_Insert_Input;
  on_conflict?: Maybe<Component_Tracking_Ids_On_Conflict>;
};

/** on conflict condition type for table "component_tracking_ids" */
export type Component_Tracking_Ids_On_Conflict = {
  constraint: Component_Tracking_Ids_Constraint;
  update_columns: Array<Component_Tracking_Ids_Update_Column>;
  where?: Maybe<Component_Tracking_Ids_Bool_Exp>;
};

/** ordering options when selecting data from "component_tracking_ids" */
export type Component_Tracking_Ids_Order_By = {
  campaign_email_component?: Maybe<Campaign_Email_Components_Order_By>;
  campaign_letter_component?: Maybe<Campaign_Letter_Components_Order_By>;
  campaign_sms_component?: Maybe<Campaign_Sms_Components_Order_By>;
  component_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  tracking_id?: Maybe<Order_By>;
};

/** primary key columns input for table: "component_tracking_ids" */
export type Component_Tracking_Ids_Pk_Columns_Input = {
  component_id: Scalars['String'];
  tracking_id: Scalars['String'];
};

/** select columns of table "component_tracking_ids" */
export enum Component_Tracking_Ids_Select_Column {
  /** column name */
  ComponentId = 'component_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  TrackingId = 'tracking_id'
}

/** input type for updating data in table "component_tracking_ids" */
export type Component_Tracking_Ids_Set_Input = {
  component_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  tracking_id?: Maybe<Scalars['String']>;
};

/** update columns of table "component_tracking_ids" */
export enum Component_Tracking_Ids_Update_Column {
  /** column name */
  ComponentId = 'component_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  TrackingId = 'tracking_id'
}

/** columns and relationships of "daily_performance_stats" */
export type Daily_Performance_Stats = {
  __typename?: 'daily_performance_stats';
  alliance_id?: Maybe<Scalars['String']>;
  alliance_name: Scalars['String'];
  offered_inbound_calls: Scalars['Int'];
  report_date: Scalars['date'];
  total_sent_apps: Scalars['Int'];
};

/** aggregated selection of "daily_performance_stats" */
export type Daily_Performance_Stats_Aggregate = {
  __typename?: 'daily_performance_stats_aggregate';
  aggregate?: Maybe<Daily_Performance_Stats_Aggregate_Fields>;
  nodes: Array<Daily_Performance_Stats>;
};

/** aggregate fields of "daily_performance_stats" */
export type Daily_Performance_Stats_Aggregate_Fields = {
  __typename?: 'daily_performance_stats_aggregate_fields';
  avg?: Maybe<Daily_Performance_Stats_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Daily_Performance_Stats_Max_Fields>;
  min?: Maybe<Daily_Performance_Stats_Min_Fields>;
  stddev?: Maybe<Daily_Performance_Stats_Stddev_Fields>;
  stddev_pop?: Maybe<Daily_Performance_Stats_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Daily_Performance_Stats_Stddev_Samp_Fields>;
  sum?: Maybe<Daily_Performance_Stats_Sum_Fields>;
  var_pop?: Maybe<Daily_Performance_Stats_Var_Pop_Fields>;
  var_samp?: Maybe<Daily_Performance_Stats_Var_Samp_Fields>;
  variance?: Maybe<Daily_Performance_Stats_Variance_Fields>;
};


/** aggregate fields of "daily_performance_stats" */
export type Daily_Performance_Stats_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Daily_Performance_Stats_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "daily_performance_stats" */
export type Daily_Performance_Stats_Aggregate_Order_By = {
  avg?: Maybe<Daily_Performance_Stats_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Daily_Performance_Stats_Max_Order_By>;
  min?: Maybe<Daily_Performance_Stats_Min_Order_By>;
  stddev?: Maybe<Daily_Performance_Stats_Stddev_Order_By>;
  stddev_pop?: Maybe<Daily_Performance_Stats_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Daily_Performance_Stats_Stddev_Samp_Order_By>;
  sum?: Maybe<Daily_Performance_Stats_Sum_Order_By>;
  var_pop?: Maybe<Daily_Performance_Stats_Var_Pop_Order_By>;
  var_samp?: Maybe<Daily_Performance_Stats_Var_Samp_Order_By>;
  variance?: Maybe<Daily_Performance_Stats_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "daily_performance_stats" */
export type Daily_Performance_Stats_Arr_Rel_Insert_Input = {
  data: Array<Daily_Performance_Stats_Insert_Input>;
  on_conflict?: Maybe<Daily_Performance_Stats_On_Conflict>;
};

/** aggregate avg on columns */
export type Daily_Performance_Stats_Avg_Fields = {
  __typename?: 'daily_performance_stats_avg_fields';
  offered_inbound_calls?: Maybe<Scalars['Float']>;
  total_sent_apps?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "daily_performance_stats" */
export type Daily_Performance_Stats_Avg_Order_By = {
  offered_inbound_calls?: Maybe<Order_By>;
  total_sent_apps?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "daily_performance_stats". All fields are combined with a logical 'AND'. */
export type Daily_Performance_Stats_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Daily_Performance_Stats_Bool_Exp>>>;
  _not?: Maybe<Daily_Performance_Stats_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Daily_Performance_Stats_Bool_Exp>>>;
  alliance_id?: Maybe<String_Comparison_Exp>;
  alliance_name?: Maybe<String_Comparison_Exp>;
  offered_inbound_calls?: Maybe<Int_Comparison_Exp>;
  report_date?: Maybe<Date_Comparison_Exp>;
  total_sent_apps?: Maybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "daily_performance_stats" */
export enum Daily_Performance_Stats_Constraint {
  /** unique or primary key constraint */
  DailyPerformanceStatsPkey = 'daily_performance_stats_pkey'
}

/** input type for incrementing integer column in table "daily_performance_stats" */
export type Daily_Performance_Stats_Inc_Input = {
  offered_inbound_calls?: Maybe<Scalars['Int']>;
  total_sent_apps?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "daily_performance_stats" */
export type Daily_Performance_Stats_Insert_Input = {
  alliance_id?: Maybe<Scalars['String']>;
  alliance_name?: Maybe<Scalars['String']>;
  offered_inbound_calls?: Maybe<Scalars['Int']>;
  report_date?: Maybe<Scalars['date']>;
  total_sent_apps?: Maybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Daily_Performance_Stats_Max_Fields = {
  __typename?: 'daily_performance_stats_max_fields';
  alliance_id?: Maybe<Scalars['String']>;
  alliance_name?: Maybe<Scalars['String']>;
  offered_inbound_calls?: Maybe<Scalars['Int']>;
  report_date?: Maybe<Scalars['date']>;
  total_sent_apps?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "daily_performance_stats" */
export type Daily_Performance_Stats_Max_Order_By = {
  alliance_id?: Maybe<Order_By>;
  alliance_name?: Maybe<Order_By>;
  offered_inbound_calls?: Maybe<Order_By>;
  report_date?: Maybe<Order_By>;
  total_sent_apps?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Daily_Performance_Stats_Min_Fields = {
  __typename?: 'daily_performance_stats_min_fields';
  alliance_id?: Maybe<Scalars['String']>;
  alliance_name?: Maybe<Scalars['String']>;
  offered_inbound_calls?: Maybe<Scalars['Int']>;
  report_date?: Maybe<Scalars['date']>;
  total_sent_apps?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "daily_performance_stats" */
export type Daily_Performance_Stats_Min_Order_By = {
  alliance_id?: Maybe<Order_By>;
  alliance_name?: Maybe<Order_By>;
  offered_inbound_calls?: Maybe<Order_By>;
  report_date?: Maybe<Order_By>;
  total_sent_apps?: Maybe<Order_By>;
};

/** response of any mutation on the table "daily_performance_stats" */
export type Daily_Performance_Stats_Mutation_Response = {
  __typename?: 'daily_performance_stats_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Daily_Performance_Stats>;
};

/** input type for inserting object relation for remote table "daily_performance_stats" */
export type Daily_Performance_Stats_Obj_Rel_Insert_Input = {
  data: Daily_Performance_Stats_Insert_Input;
  on_conflict?: Maybe<Daily_Performance_Stats_On_Conflict>;
};

/** on conflict condition type for table "daily_performance_stats" */
export type Daily_Performance_Stats_On_Conflict = {
  constraint: Daily_Performance_Stats_Constraint;
  update_columns: Array<Daily_Performance_Stats_Update_Column>;
  where?: Maybe<Daily_Performance_Stats_Bool_Exp>;
};

/** ordering options when selecting data from "daily_performance_stats" */
export type Daily_Performance_Stats_Order_By = {
  alliance_id?: Maybe<Order_By>;
  alliance_name?: Maybe<Order_By>;
  offered_inbound_calls?: Maybe<Order_By>;
  report_date?: Maybe<Order_By>;
  total_sent_apps?: Maybe<Order_By>;
};

/** primary key columns input for table: "daily_performance_stats" */
export type Daily_Performance_Stats_Pk_Columns_Input = {
  alliance_name: Scalars['String'];
  report_date: Scalars['date'];
};

/** select columns of table "daily_performance_stats" */
export enum Daily_Performance_Stats_Select_Column {
  /** column name */
  AllianceId = 'alliance_id',
  /** column name */
  AllianceName = 'alliance_name',
  /** column name */
  OfferedInboundCalls = 'offered_inbound_calls',
  /** column name */
  ReportDate = 'report_date',
  /** column name */
  TotalSentApps = 'total_sent_apps'
}

/** input type for updating data in table "daily_performance_stats" */
export type Daily_Performance_Stats_Set_Input = {
  alliance_id?: Maybe<Scalars['String']>;
  alliance_name?: Maybe<Scalars['String']>;
  offered_inbound_calls?: Maybe<Scalars['Int']>;
  report_date?: Maybe<Scalars['date']>;
  total_sent_apps?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Daily_Performance_Stats_Stddev_Fields = {
  __typename?: 'daily_performance_stats_stddev_fields';
  offered_inbound_calls?: Maybe<Scalars['Float']>;
  total_sent_apps?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "daily_performance_stats" */
export type Daily_Performance_Stats_Stddev_Order_By = {
  offered_inbound_calls?: Maybe<Order_By>;
  total_sent_apps?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Daily_Performance_Stats_Stddev_Pop_Fields = {
  __typename?: 'daily_performance_stats_stddev_pop_fields';
  offered_inbound_calls?: Maybe<Scalars['Float']>;
  total_sent_apps?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "daily_performance_stats" */
export type Daily_Performance_Stats_Stddev_Pop_Order_By = {
  offered_inbound_calls?: Maybe<Order_By>;
  total_sent_apps?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Daily_Performance_Stats_Stddev_Samp_Fields = {
  __typename?: 'daily_performance_stats_stddev_samp_fields';
  offered_inbound_calls?: Maybe<Scalars['Float']>;
  total_sent_apps?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "daily_performance_stats" */
export type Daily_Performance_Stats_Stddev_Samp_Order_By = {
  offered_inbound_calls?: Maybe<Order_By>;
  total_sent_apps?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Daily_Performance_Stats_Sum_Fields = {
  __typename?: 'daily_performance_stats_sum_fields';
  offered_inbound_calls?: Maybe<Scalars['Int']>;
  total_sent_apps?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "daily_performance_stats" */
export type Daily_Performance_Stats_Sum_Order_By = {
  offered_inbound_calls?: Maybe<Order_By>;
  total_sent_apps?: Maybe<Order_By>;
};

/** update columns of table "daily_performance_stats" */
export enum Daily_Performance_Stats_Update_Column {
  /** column name */
  AllianceId = 'alliance_id',
  /** column name */
  AllianceName = 'alliance_name',
  /** column name */
  OfferedInboundCalls = 'offered_inbound_calls',
  /** column name */
  ReportDate = 'report_date',
  /** column name */
  TotalSentApps = 'total_sent_apps'
}

/** aggregate var_pop on columns */
export type Daily_Performance_Stats_Var_Pop_Fields = {
  __typename?: 'daily_performance_stats_var_pop_fields';
  offered_inbound_calls?: Maybe<Scalars['Float']>;
  total_sent_apps?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "daily_performance_stats" */
export type Daily_Performance_Stats_Var_Pop_Order_By = {
  offered_inbound_calls?: Maybe<Order_By>;
  total_sent_apps?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Daily_Performance_Stats_Var_Samp_Fields = {
  __typename?: 'daily_performance_stats_var_samp_fields';
  offered_inbound_calls?: Maybe<Scalars['Float']>;
  total_sent_apps?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "daily_performance_stats" */
export type Daily_Performance_Stats_Var_Samp_Order_By = {
  offered_inbound_calls?: Maybe<Order_By>;
  total_sent_apps?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Daily_Performance_Stats_Variance_Fields = {
  __typename?: 'daily_performance_stats_variance_fields';
  offered_inbound_calls?: Maybe<Scalars['Float']>;
  total_sent_apps?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "daily_performance_stats" */
export type Daily_Performance_Stats_Variance_Order_By = {
  offered_inbound_calls?: Maybe<Order_By>;
  total_sent_apps?: Maybe<Order_By>;
};


/** expression to compare columns of type date. All fields are combined with logical 'AND'. */
export type Date_Comparison_Exp = {
  _eq?: Maybe<Scalars['date']>;
  _gt?: Maybe<Scalars['date']>;
  _gte?: Maybe<Scalars['date']>;
  _in?: Maybe<Array<Scalars['date']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['date']>;
  _lte?: Maybe<Scalars['date']>;
  _neq?: Maybe<Scalars['date']>;
  _nin?: Maybe<Array<Scalars['date']>>;
};

/** columns and relationships of "email_component_approvals" */
export type Email_Component_Approvals = {
  __typename?: 'email_component_approvals';
  /** An object relationship */
  campaign_approval?: Maybe<Campaign_Approvals>;
  /** An object relationship */
  campaign_email_component: Campaign_Email_Components;
  campaign_email_component_id: Scalars['String'];
  campaign_template_id: Scalars['String'];
  created_at: Scalars['timestamptz'];
  is_cancelled: Scalars['Boolean'];
  practice_id: Scalars['String'];
  /** An object relationship */
  scheduled_event?: Maybe<Scheduled_Events>;
  scheduled_event_id?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "email_component_approvals" */
export type Email_Component_Approvals_Aggregate = {
  __typename?: 'email_component_approvals_aggregate';
  aggregate?: Maybe<Email_Component_Approvals_Aggregate_Fields>;
  nodes: Array<Email_Component_Approvals>;
};

/** aggregate fields of "email_component_approvals" */
export type Email_Component_Approvals_Aggregate_Fields = {
  __typename?: 'email_component_approvals_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Email_Component_Approvals_Max_Fields>;
  min?: Maybe<Email_Component_Approvals_Min_Fields>;
};


/** aggregate fields of "email_component_approvals" */
export type Email_Component_Approvals_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Email_Component_Approvals_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "email_component_approvals" */
export type Email_Component_Approvals_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Email_Component_Approvals_Max_Order_By>;
  min?: Maybe<Email_Component_Approvals_Min_Order_By>;
};

/** input type for inserting array relation for remote table "email_component_approvals" */
export type Email_Component_Approvals_Arr_Rel_Insert_Input = {
  data: Array<Email_Component_Approvals_Insert_Input>;
  on_conflict?: Maybe<Email_Component_Approvals_On_Conflict>;
};

/** Boolean expression to filter rows from the table "email_component_approvals". All fields are combined with a logical 'AND'. */
export type Email_Component_Approvals_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Email_Component_Approvals_Bool_Exp>>>;
  _not?: Maybe<Email_Component_Approvals_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Email_Component_Approvals_Bool_Exp>>>;
  campaign_approval?: Maybe<Campaign_Approvals_Bool_Exp>;
  campaign_email_component?: Maybe<Campaign_Email_Components_Bool_Exp>;
  campaign_email_component_id?: Maybe<String_Comparison_Exp>;
  campaign_template_id?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  is_cancelled?: Maybe<Boolean_Comparison_Exp>;
  practice_id?: Maybe<String_Comparison_Exp>;
  scheduled_event?: Maybe<Scheduled_Events_Bool_Exp>;
  scheduled_event_id?: Maybe<String_Comparison_Exp>;
  status?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "email_component_approvals" */
export enum Email_Component_Approvals_Constraint {
  /** unique or primary key constraint */
  EmailComponentApprovalsPkey = 'email_component_approvals_pkey'
}

/** input type for inserting data into table "email_component_approvals" */
export type Email_Component_Approvals_Insert_Input = {
  campaign_approval?: Maybe<Campaign_Approvals_Obj_Rel_Insert_Input>;
  campaign_email_component?: Maybe<Campaign_Email_Components_Obj_Rel_Insert_Input>;
  campaign_email_component_id?: Maybe<Scalars['String']>;
  campaign_template_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  is_cancelled?: Maybe<Scalars['Boolean']>;
  practice_id?: Maybe<Scalars['String']>;
  scheduled_event?: Maybe<Scheduled_Events_Obj_Rel_Insert_Input>;
  scheduled_event_id?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Email_Component_Approvals_Max_Fields = {
  __typename?: 'email_component_approvals_max_fields';
  campaign_email_component_id?: Maybe<Scalars['String']>;
  campaign_template_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  practice_id?: Maybe<Scalars['String']>;
  scheduled_event_id?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "email_component_approvals" */
export type Email_Component_Approvals_Max_Order_By = {
  campaign_email_component_id?: Maybe<Order_By>;
  campaign_template_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  practice_id?: Maybe<Order_By>;
  scheduled_event_id?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Email_Component_Approvals_Min_Fields = {
  __typename?: 'email_component_approvals_min_fields';
  campaign_email_component_id?: Maybe<Scalars['String']>;
  campaign_template_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  practice_id?: Maybe<Scalars['String']>;
  scheduled_event_id?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "email_component_approvals" */
export type Email_Component_Approvals_Min_Order_By = {
  campaign_email_component_id?: Maybe<Order_By>;
  campaign_template_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  practice_id?: Maybe<Order_By>;
  scheduled_event_id?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "email_component_approvals" */
export type Email_Component_Approvals_Mutation_Response = {
  __typename?: 'email_component_approvals_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Email_Component_Approvals>;
};

/** input type for inserting object relation for remote table "email_component_approvals" */
export type Email_Component_Approvals_Obj_Rel_Insert_Input = {
  data: Email_Component_Approvals_Insert_Input;
  on_conflict?: Maybe<Email_Component_Approvals_On_Conflict>;
};

/** on conflict condition type for table "email_component_approvals" */
export type Email_Component_Approvals_On_Conflict = {
  constraint: Email_Component_Approvals_Constraint;
  update_columns: Array<Email_Component_Approvals_Update_Column>;
  where?: Maybe<Email_Component_Approvals_Bool_Exp>;
};

/** ordering options when selecting data from "email_component_approvals" */
export type Email_Component_Approvals_Order_By = {
  campaign_approval?: Maybe<Campaign_Approvals_Order_By>;
  campaign_email_component?: Maybe<Campaign_Email_Components_Order_By>;
  campaign_email_component_id?: Maybe<Order_By>;
  campaign_template_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  is_cancelled?: Maybe<Order_By>;
  practice_id?: Maybe<Order_By>;
  scheduled_event?: Maybe<Scheduled_Events_Order_By>;
  scheduled_event_id?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: "email_component_approvals" */
export type Email_Component_Approvals_Pk_Columns_Input = {
  campaign_email_component_id: Scalars['String'];
  campaign_template_id: Scalars['String'];
  practice_id: Scalars['String'];
};

/** select columns of table "email_component_approvals" */
export enum Email_Component_Approvals_Select_Column {
  /** column name */
  CampaignEmailComponentId = 'campaign_email_component_id',
  /** column name */
  CampaignTemplateId = 'campaign_template_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  IsCancelled = 'is_cancelled',
  /** column name */
  PracticeId = 'practice_id',
  /** column name */
  ScheduledEventId = 'scheduled_event_id',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "email_component_approvals" */
export type Email_Component_Approvals_Set_Input = {
  campaign_email_component_id?: Maybe<Scalars['String']>;
  campaign_template_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  is_cancelled?: Maybe<Scalars['Boolean']>;
  practice_id?: Maybe<Scalars['String']>;
  scheduled_event_id?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "email_component_approvals" */
export enum Email_Component_Approvals_Update_Column {
  /** column name */
  CampaignEmailComponentId = 'campaign_email_component_id',
  /** column name */
  CampaignTemplateId = 'campaign_template_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  IsCancelled = 'is_cancelled',
  /** column name */
  PracticeId = 'practice_id',
  /** column name */
  ScheduledEventId = 'scheduled_event_id',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at'
}

/**
 * A pointer to the external source (e.g. Mailgun) where an email template is stored and defined.
 * 
 * 
 * columns and relationships of "email_templates"
 */
export type Email_Templates = {
  __typename?: 'email_templates';
  body_html?: Maybe<Scalars['String']>;
  /** An array relationship */
  campaign_email_components: Array<Campaign_Email_Components>;
  /** An aggregated array relationship */
  campaign_email_components_aggregate: Campaign_Email_Components_Aggregate;
  created_at: Scalars['timestamptz'];
  default_merge_vars: Scalars['jsonb'];
  email_from: Scalars['String'];
  email_subject?: Maybe<Scalars['String']>;
  email_template_description?: Maybe<Scalars['String']>;
  email_template_id: Scalars['String'];
  email_template_name?: Maybe<Scalars['String']>;
  external_id?: Maybe<Scalars['String']>;
  external_source?: Maybe<Scalars['String']>;
  external_version?: Maybe<Scalars['String']>;
  is_archived: Scalars['Boolean'];
  updated_at: Scalars['timestamptz'];
  webform_url?: Maybe<Scalars['String']>;
};


/**
 * A pointer to the external source (e.g. Mailgun) where an email template is stored and defined.
 * 
 * 
 * columns and relationships of "email_templates"
 */
export type Email_TemplatesCampaign_Email_ComponentsArgs = {
  distinct_on?: Maybe<Array<Campaign_Email_Components_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Campaign_Email_Components_Order_By>>;
  where?: Maybe<Campaign_Email_Components_Bool_Exp>;
};


/**
 * A pointer to the external source (e.g. Mailgun) where an email template is stored and defined.
 * 
 * 
 * columns and relationships of "email_templates"
 */
export type Email_TemplatesCampaign_Email_Components_AggregateArgs = {
  distinct_on?: Maybe<Array<Campaign_Email_Components_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Campaign_Email_Components_Order_By>>;
  where?: Maybe<Campaign_Email_Components_Bool_Exp>;
};


/**
 * A pointer to the external source (e.g. Mailgun) where an email template is stored and defined.
 * 
 * 
 * columns and relationships of "email_templates"
 */
export type Email_TemplatesDefault_Merge_VarsArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "email_templates" */
export type Email_Templates_Aggregate = {
  __typename?: 'email_templates_aggregate';
  aggregate?: Maybe<Email_Templates_Aggregate_Fields>;
  nodes: Array<Email_Templates>;
};

/** aggregate fields of "email_templates" */
export type Email_Templates_Aggregate_Fields = {
  __typename?: 'email_templates_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Email_Templates_Max_Fields>;
  min?: Maybe<Email_Templates_Min_Fields>;
};


/** aggregate fields of "email_templates" */
export type Email_Templates_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Email_Templates_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "email_templates" */
export type Email_Templates_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Email_Templates_Max_Order_By>;
  min?: Maybe<Email_Templates_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Email_Templates_Append_Input = {
  default_merge_vars?: Maybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "email_templates" */
export type Email_Templates_Arr_Rel_Insert_Input = {
  data: Array<Email_Templates_Insert_Input>;
  on_conflict?: Maybe<Email_Templates_On_Conflict>;
};

/** Boolean expression to filter rows from the table "email_templates". All fields are combined with a logical 'AND'. */
export type Email_Templates_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Email_Templates_Bool_Exp>>>;
  _not?: Maybe<Email_Templates_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Email_Templates_Bool_Exp>>>;
  body_html?: Maybe<String_Comparison_Exp>;
  campaign_email_components?: Maybe<Campaign_Email_Components_Bool_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  default_merge_vars?: Maybe<Jsonb_Comparison_Exp>;
  email_from?: Maybe<String_Comparison_Exp>;
  email_subject?: Maybe<String_Comparison_Exp>;
  email_template_description?: Maybe<String_Comparison_Exp>;
  email_template_id?: Maybe<String_Comparison_Exp>;
  email_template_name?: Maybe<String_Comparison_Exp>;
  external_id?: Maybe<String_Comparison_Exp>;
  external_source?: Maybe<String_Comparison_Exp>;
  external_version?: Maybe<String_Comparison_Exp>;
  is_archived?: Maybe<Boolean_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  webform_url?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "email_templates" */
export enum Email_Templates_Constraint {
  /** unique or primary key constraint */
  EmailTemplatesPkey = 'email_templates_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Email_Templates_Delete_At_Path_Input = {
  default_merge_vars?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Email_Templates_Delete_Elem_Input = {
  default_merge_vars?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Email_Templates_Delete_Key_Input = {
  default_merge_vars?: Maybe<Scalars['String']>;
};

/** input type for inserting data into table "email_templates" */
export type Email_Templates_Insert_Input = {
  body_html?: Maybe<Scalars['String']>;
  campaign_email_components?: Maybe<Campaign_Email_Components_Arr_Rel_Insert_Input>;
  created_at?: Maybe<Scalars['timestamptz']>;
  default_merge_vars?: Maybe<Scalars['jsonb']>;
  email_from?: Maybe<Scalars['String']>;
  email_subject?: Maybe<Scalars['String']>;
  email_template_description?: Maybe<Scalars['String']>;
  email_template_id?: Maybe<Scalars['String']>;
  email_template_name?: Maybe<Scalars['String']>;
  external_id?: Maybe<Scalars['String']>;
  external_source?: Maybe<Scalars['String']>;
  external_version?: Maybe<Scalars['String']>;
  is_archived?: Maybe<Scalars['Boolean']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  webform_url?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Email_Templates_Max_Fields = {
  __typename?: 'email_templates_max_fields';
  body_html?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  email_from?: Maybe<Scalars['String']>;
  email_subject?: Maybe<Scalars['String']>;
  email_template_description?: Maybe<Scalars['String']>;
  email_template_id?: Maybe<Scalars['String']>;
  email_template_name?: Maybe<Scalars['String']>;
  external_id?: Maybe<Scalars['String']>;
  external_source?: Maybe<Scalars['String']>;
  external_version?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  webform_url?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "email_templates" */
export type Email_Templates_Max_Order_By = {
  body_html?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  email_from?: Maybe<Order_By>;
  email_subject?: Maybe<Order_By>;
  email_template_description?: Maybe<Order_By>;
  email_template_id?: Maybe<Order_By>;
  email_template_name?: Maybe<Order_By>;
  external_id?: Maybe<Order_By>;
  external_source?: Maybe<Order_By>;
  external_version?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  webform_url?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Email_Templates_Min_Fields = {
  __typename?: 'email_templates_min_fields';
  body_html?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  email_from?: Maybe<Scalars['String']>;
  email_subject?: Maybe<Scalars['String']>;
  email_template_description?: Maybe<Scalars['String']>;
  email_template_id?: Maybe<Scalars['String']>;
  email_template_name?: Maybe<Scalars['String']>;
  external_id?: Maybe<Scalars['String']>;
  external_source?: Maybe<Scalars['String']>;
  external_version?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  webform_url?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "email_templates" */
export type Email_Templates_Min_Order_By = {
  body_html?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  email_from?: Maybe<Order_By>;
  email_subject?: Maybe<Order_By>;
  email_template_description?: Maybe<Order_By>;
  email_template_id?: Maybe<Order_By>;
  email_template_name?: Maybe<Order_By>;
  external_id?: Maybe<Order_By>;
  external_source?: Maybe<Order_By>;
  external_version?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  webform_url?: Maybe<Order_By>;
};

/** response of any mutation on the table "email_templates" */
export type Email_Templates_Mutation_Response = {
  __typename?: 'email_templates_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Email_Templates>;
};

/** input type for inserting object relation for remote table "email_templates" */
export type Email_Templates_Obj_Rel_Insert_Input = {
  data: Email_Templates_Insert_Input;
  on_conflict?: Maybe<Email_Templates_On_Conflict>;
};

/** on conflict condition type for table "email_templates" */
export type Email_Templates_On_Conflict = {
  constraint: Email_Templates_Constraint;
  update_columns: Array<Email_Templates_Update_Column>;
  where?: Maybe<Email_Templates_Bool_Exp>;
};

/** ordering options when selecting data from "email_templates" */
export type Email_Templates_Order_By = {
  body_html?: Maybe<Order_By>;
  campaign_email_components_aggregate?: Maybe<Campaign_Email_Components_Aggregate_Order_By>;
  created_at?: Maybe<Order_By>;
  default_merge_vars?: Maybe<Order_By>;
  email_from?: Maybe<Order_By>;
  email_subject?: Maybe<Order_By>;
  email_template_description?: Maybe<Order_By>;
  email_template_id?: Maybe<Order_By>;
  email_template_name?: Maybe<Order_By>;
  external_id?: Maybe<Order_By>;
  external_source?: Maybe<Order_By>;
  external_version?: Maybe<Order_By>;
  is_archived?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  webform_url?: Maybe<Order_By>;
};

/** primary key columns input for table: "email_templates" */
export type Email_Templates_Pk_Columns_Input = {
  email_template_id: Scalars['String'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Email_Templates_Prepend_Input = {
  default_merge_vars?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "email_templates" */
export enum Email_Templates_Select_Column {
  /** column name */
  BodyHtml = 'body_html',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DefaultMergeVars = 'default_merge_vars',
  /** column name */
  EmailFrom = 'email_from',
  /** column name */
  EmailSubject = 'email_subject',
  /** column name */
  EmailTemplateDescription = 'email_template_description',
  /** column name */
  EmailTemplateId = 'email_template_id',
  /** column name */
  EmailTemplateName = 'email_template_name',
  /** column name */
  ExternalId = 'external_id',
  /** column name */
  ExternalSource = 'external_source',
  /** column name */
  ExternalVersion = 'external_version',
  /** column name */
  IsArchived = 'is_archived',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  WebformUrl = 'webform_url'
}

/** input type for updating data in table "email_templates" */
export type Email_Templates_Set_Input = {
  body_html?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  default_merge_vars?: Maybe<Scalars['jsonb']>;
  email_from?: Maybe<Scalars['String']>;
  email_subject?: Maybe<Scalars['String']>;
  email_template_description?: Maybe<Scalars['String']>;
  email_template_id?: Maybe<Scalars['String']>;
  email_template_name?: Maybe<Scalars['String']>;
  external_id?: Maybe<Scalars['String']>;
  external_source?: Maybe<Scalars['String']>;
  external_version?: Maybe<Scalars['String']>;
  is_archived?: Maybe<Scalars['Boolean']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  webform_url?: Maybe<Scalars['String']>;
};

/** update columns of table "email_templates" */
export enum Email_Templates_Update_Column {
  /** column name */
  BodyHtml = 'body_html',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DefaultMergeVars = 'default_merge_vars',
  /** column name */
  EmailFrom = 'email_from',
  /** column name */
  EmailSubject = 'email_subject',
  /** column name */
  EmailTemplateDescription = 'email_template_description',
  /** column name */
  EmailTemplateId = 'email_template_id',
  /** column name */
  EmailTemplateName = 'email_template_name',
  /** column name */
  ExternalId = 'external_id',
  /** column name */
  ExternalSource = 'external_source',
  /** column name */
  ExternalVersion = 'external_version',
  /** column name */
  IsArchived = 'is_archived',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  WebformUrl = 'webform_url'
}

/** columns and relationships of "emails" */
export type Emails = {
  __typename?: 'emails';
  /** An object relationship */
  campaign_email_component?: Maybe<Campaign_Email_Components>;
  campaign_email_component_id: Scalars['String'];
  created_at: Scalars['timestamptz'];
  email_id: Scalars['String'];
  external_id?: Maybe<Scalars['String']>;
  is_archived: Scalars['Boolean'];
  /** An object relationship */
  patient: Patients;
  patient_id: Scalars['String'];
  /** An object relationship */
  practice: Practices;
  practice_id: Scalars['String'];
  send_datetime: Scalars['timestamptz'];
  status: Scalars['String'];
  tracking_events: Scalars['jsonb'];
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "emails" */
export type EmailsTracking_EventsArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "emails" */
export type Emails_Aggregate = {
  __typename?: 'emails_aggregate';
  aggregate?: Maybe<Emails_Aggregate_Fields>;
  nodes: Array<Emails>;
};

/** aggregate fields of "emails" */
export type Emails_Aggregate_Fields = {
  __typename?: 'emails_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Emails_Max_Fields>;
  min?: Maybe<Emails_Min_Fields>;
};


/** aggregate fields of "emails" */
export type Emails_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Emails_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "emails" */
export type Emails_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Emails_Max_Order_By>;
  min?: Maybe<Emails_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Emails_Append_Input = {
  tracking_events?: Maybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "emails" */
export type Emails_Arr_Rel_Insert_Input = {
  data: Array<Emails_Insert_Input>;
  on_conflict?: Maybe<Emails_On_Conflict>;
};

/** Boolean expression to filter rows from the table "emails". All fields are combined with a logical 'AND'. */
export type Emails_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Emails_Bool_Exp>>>;
  _not?: Maybe<Emails_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Emails_Bool_Exp>>>;
  campaign_email_component?: Maybe<Campaign_Email_Components_Bool_Exp>;
  campaign_email_component_id?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  email_id?: Maybe<String_Comparison_Exp>;
  external_id?: Maybe<String_Comparison_Exp>;
  is_archived?: Maybe<Boolean_Comparison_Exp>;
  patient?: Maybe<Patients_Bool_Exp>;
  patient_id?: Maybe<String_Comparison_Exp>;
  practice?: Maybe<Practices_Bool_Exp>;
  practice_id?: Maybe<String_Comparison_Exp>;
  send_datetime?: Maybe<Timestamptz_Comparison_Exp>;
  status?: Maybe<String_Comparison_Exp>;
  tracking_events?: Maybe<Jsonb_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "emails" */
export enum Emails_Constraint {
  /** unique or primary key constraint */
  EmailsEmailIdTextPatternOps = 'emails_email_id_text_pattern_ops',
  /** unique or primary key constraint */
  EmailsExternalIdKey = 'emails_external_id_key',
  /** unique or primary key constraint */
  EmailsPatientIdPracticeIdCampaignEmailComponentIdKey = 'emails_patient_id_practice_id_campaign_email_component_id_key',
  /** unique or primary key constraint */
  EmailsPkey = 'emails_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Emails_Delete_At_Path_Input = {
  tracking_events?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Emails_Delete_Elem_Input = {
  tracking_events?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Emails_Delete_Key_Input = {
  tracking_events?: Maybe<Scalars['String']>;
};

/** input type for inserting data into table "emails" */
export type Emails_Insert_Input = {
  campaign_email_component?: Maybe<Campaign_Email_Components_Obj_Rel_Insert_Input>;
  campaign_email_component_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  email_id?: Maybe<Scalars['String']>;
  external_id?: Maybe<Scalars['String']>;
  is_archived?: Maybe<Scalars['Boolean']>;
  patient?: Maybe<Patients_Obj_Rel_Insert_Input>;
  patient_id?: Maybe<Scalars['String']>;
  practice?: Maybe<Practices_Obj_Rel_Insert_Input>;
  practice_id?: Maybe<Scalars['String']>;
  send_datetime?: Maybe<Scalars['timestamptz']>;
  status?: Maybe<Scalars['String']>;
  tracking_events?: Maybe<Scalars['jsonb']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Emails_Max_Fields = {
  __typename?: 'emails_max_fields';
  campaign_email_component_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  email_id?: Maybe<Scalars['String']>;
  external_id?: Maybe<Scalars['String']>;
  patient_id?: Maybe<Scalars['String']>;
  practice_id?: Maybe<Scalars['String']>;
  send_datetime?: Maybe<Scalars['timestamptz']>;
  status?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "emails" */
export type Emails_Max_Order_By = {
  campaign_email_component_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  email_id?: Maybe<Order_By>;
  external_id?: Maybe<Order_By>;
  patient_id?: Maybe<Order_By>;
  practice_id?: Maybe<Order_By>;
  send_datetime?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Emails_Min_Fields = {
  __typename?: 'emails_min_fields';
  campaign_email_component_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  email_id?: Maybe<Scalars['String']>;
  external_id?: Maybe<Scalars['String']>;
  patient_id?: Maybe<Scalars['String']>;
  practice_id?: Maybe<Scalars['String']>;
  send_datetime?: Maybe<Scalars['timestamptz']>;
  status?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "emails" */
export type Emails_Min_Order_By = {
  campaign_email_component_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  email_id?: Maybe<Order_By>;
  external_id?: Maybe<Order_By>;
  patient_id?: Maybe<Order_By>;
  practice_id?: Maybe<Order_By>;
  send_datetime?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "emails" */
export type Emails_Mutation_Response = {
  __typename?: 'emails_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Emails>;
};

/** input type for inserting object relation for remote table "emails" */
export type Emails_Obj_Rel_Insert_Input = {
  data: Emails_Insert_Input;
  on_conflict?: Maybe<Emails_On_Conflict>;
};

/** on conflict condition type for table "emails" */
export type Emails_On_Conflict = {
  constraint: Emails_Constraint;
  update_columns: Array<Emails_Update_Column>;
  where?: Maybe<Emails_Bool_Exp>;
};

/** ordering options when selecting data from "emails" */
export type Emails_Order_By = {
  campaign_email_component?: Maybe<Campaign_Email_Components_Order_By>;
  campaign_email_component_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  email_id?: Maybe<Order_By>;
  external_id?: Maybe<Order_By>;
  is_archived?: Maybe<Order_By>;
  patient?: Maybe<Patients_Order_By>;
  patient_id?: Maybe<Order_By>;
  practice?: Maybe<Practices_Order_By>;
  practice_id?: Maybe<Order_By>;
  send_datetime?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  tracking_events?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: "emails" */
export type Emails_Pk_Columns_Input = {
  email_id: Scalars['String'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Emails_Prepend_Input = {
  tracking_events?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "emails" */
export enum Emails_Select_Column {
  /** column name */
  CampaignEmailComponentId = 'campaign_email_component_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EmailId = 'email_id',
  /** column name */
  ExternalId = 'external_id',
  /** column name */
  IsArchived = 'is_archived',
  /** column name */
  PatientId = 'patient_id',
  /** column name */
  PracticeId = 'practice_id',
  /** column name */
  SendDatetime = 'send_datetime',
  /** column name */
  Status = 'status',
  /** column name */
  TrackingEvents = 'tracking_events',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "emails" */
export type Emails_Set_Input = {
  campaign_email_component_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  email_id?: Maybe<Scalars['String']>;
  external_id?: Maybe<Scalars['String']>;
  is_archived?: Maybe<Scalars['Boolean']>;
  patient_id?: Maybe<Scalars['String']>;
  practice_id?: Maybe<Scalars['String']>;
  send_datetime?: Maybe<Scalars['timestamptz']>;
  status?: Maybe<Scalars['String']>;
  tracking_events?: Maybe<Scalars['jsonb']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "emails" */
export enum Emails_Update_Column {
  /** column name */
  CampaignEmailComponentId = 'campaign_email_component_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EmailId = 'email_id',
  /** column name */
  ExternalId = 'external_id',
  /** column name */
  IsArchived = 'is_archived',
  /** column name */
  PatientId = 'patient_id',
  /** column name */
  PracticeId = 'practice_id',
  /** column name */
  SendDatetime = 'send_datetime',
  /** column name */
  Status = 'status',
  /** column name */
  TrackingEvents = 'tracking_events',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** columns and relationships of "file_uploads" */
export type File_Uploads = {
  __typename?: 'file_uploads';
  created_at: Scalars['timestamptz'];
  file_category: Scalars['String'];
  file_name: Scalars['String'];
  file_upload_id: Scalars['String'];
  identity_id: Scalars['String'];
  is_archived: Scalars['Boolean'];
  key: Scalars['String'];
  level: Scalars['String'];
  network_id?: Maybe<Scalars['String']>;
  organization_id?: Maybe<Scalars['String']>;
  practice_id?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
  upload_status?: Maybe<Scalars['String']>;
  user_id: Scalars['String'];
};

/** aggregated selection of "file_uploads" */
export type File_Uploads_Aggregate = {
  __typename?: 'file_uploads_aggregate';
  aggregate?: Maybe<File_Uploads_Aggregate_Fields>;
  nodes: Array<File_Uploads>;
};

/** aggregate fields of "file_uploads" */
export type File_Uploads_Aggregate_Fields = {
  __typename?: 'file_uploads_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<File_Uploads_Max_Fields>;
  min?: Maybe<File_Uploads_Min_Fields>;
};


/** aggregate fields of "file_uploads" */
export type File_Uploads_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<File_Uploads_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "file_uploads" */
export type File_Uploads_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<File_Uploads_Max_Order_By>;
  min?: Maybe<File_Uploads_Min_Order_By>;
};

/** input type for inserting array relation for remote table "file_uploads" */
export type File_Uploads_Arr_Rel_Insert_Input = {
  data: Array<File_Uploads_Insert_Input>;
  on_conflict?: Maybe<File_Uploads_On_Conflict>;
};

/** Boolean expression to filter rows from the table "file_uploads". All fields are combined with a logical 'AND'. */
export type File_Uploads_Bool_Exp = {
  _and?: Maybe<Array<Maybe<File_Uploads_Bool_Exp>>>;
  _not?: Maybe<File_Uploads_Bool_Exp>;
  _or?: Maybe<Array<Maybe<File_Uploads_Bool_Exp>>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  file_category?: Maybe<String_Comparison_Exp>;
  file_name?: Maybe<String_Comparison_Exp>;
  file_upload_id?: Maybe<String_Comparison_Exp>;
  identity_id?: Maybe<String_Comparison_Exp>;
  is_archived?: Maybe<Boolean_Comparison_Exp>;
  key?: Maybe<String_Comparison_Exp>;
  level?: Maybe<String_Comparison_Exp>;
  network_id?: Maybe<String_Comparison_Exp>;
  organization_id?: Maybe<String_Comparison_Exp>;
  practice_id?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  upload_status?: Maybe<String_Comparison_Exp>;
  user_id?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "file_uploads" */
export enum File_Uploads_Constraint {
  /** unique or primary key constraint */
  FileUploadsPkey = 'file_uploads_pkey'
}

/** input type for inserting data into table "file_uploads" */
export type File_Uploads_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  file_category?: Maybe<Scalars['String']>;
  file_name?: Maybe<Scalars['String']>;
  file_upload_id?: Maybe<Scalars['String']>;
  identity_id?: Maybe<Scalars['String']>;
  is_archived?: Maybe<Scalars['Boolean']>;
  key?: Maybe<Scalars['String']>;
  level?: Maybe<Scalars['String']>;
  network_id?: Maybe<Scalars['String']>;
  organization_id?: Maybe<Scalars['String']>;
  practice_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  upload_status?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type File_Uploads_Max_Fields = {
  __typename?: 'file_uploads_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  file_category?: Maybe<Scalars['String']>;
  file_name?: Maybe<Scalars['String']>;
  file_upload_id?: Maybe<Scalars['String']>;
  identity_id?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
  level?: Maybe<Scalars['String']>;
  network_id?: Maybe<Scalars['String']>;
  organization_id?: Maybe<Scalars['String']>;
  practice_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  upload_status?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "file_uploads" */
export type File_Uploads_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  file_category?: Maybe<Order_By>;
  file_name?: Maybe<Order_By>;
  file_upload_id?: Maybe<Order_By>;
  identity_id?: Maybe<Order_By>;
  key?: Maybe<Order_By>;
  level?: Maybe<Order_By>;
  network_id?: Maybe<Order_By>;
  organization_id?: Maybe<Order_By>;
  practice_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  upload_status?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type File_Uploads_Min_Fields = {
  __typename?: 'file_uploads_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  file_category?: Maybe<Scalars['String']>;
  file_name?: Maybe<Scalars['String']>;
  file_upload_id?: Maybe<Scalars['String']>;
  identity_id?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
  level?: Maybe<Scalars['String']>;
  network_id?: Maybe<Scalars['String']>;
  organization_id?: Maybe<Scalars['String']>;
  practice_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  upload_status?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "file_uploads" */
export type File_Uploads_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  file_category?: Maybe<Order_By>;
  file_name?: Maybe<Order_By>;
  file_upload_id?: Maybe<Order_By>;
  identity_id?: Maybe<Order_By>;
  key?: Maybe<Order_By>;
  level?: Maybe<Order_By>;
  network_id?: Maybe<Order_By>;
  organization_id?: Maybe<Order_By>;
  practice_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  upload_status?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "file_uploads" */
export type File_Uploads_Mutation_Response = {
  __typename?: 'file_uploads_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<File_Uploads>;
};

/** input type for inserting object relation for remote table "file_uploads" */
export type File_Uploads_Obj_Rel_Insert_Input = {
  data: File_Uploads_Insert_Input;
  on_conflict?: Maybe<File_Uploads_On_Conflict>;
};

/** on conflict condition type for table "file_uploads" */
export type File_Uploads_On_Conflict = {
  constraint: File_Uploads_Constraint;
  update_columns: Array<File_Uploads_Update_Column>;
  where?: Maybe<File_Uploads_Bool_Exp>;
};

/** ordering options when selecting data from "file_uploads" */
export type File_Uploads_Order_By = {
  created_at?: Maybe<Order_By>;
  file_category?: Maybe<Order_By>;
  file_name?: Maybe<Order_By>;
  file_upload_id?: Maybe<Order_By>;
  identity_id?: Maybe<Order_By>;
  is_archived?: Maybe<Order_By>;
  key?: Maybe<Order_By>;
  level?: Maybe<Order_By>;
  network_id?: Maybe<Order_By>;
  organization_id?: Maybe<Order_By>;
  practice_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  upload_status?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** primary key columns input for table: "file_uploads" */
export type File_Uploads_Pk_Columns_Input = {
  file_upload_id: Scalars['String'];
};

/** select columns of table "file_uploads" */
export enum File_Uploads_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FileCategory = 'file_category',
  /** column name */
  FileName = 'file_name',
  /** column name */
  FileUploadId = 'file_upload_id',
  /** column name */
  IdentityId = 'identity_id',
  /** column name */
  IsArchived = 'is_archived',
  /** column name */
  Key = 'key',
  /** column name */
  Level = 'level',
  /** column name */
  NetworkId = 'network_id',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  PracticeId = 'practice_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UploadStatus = 'upload_status',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "file_uploads" */
export type File_Uploads_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  file_category?: Maybe<Scalars['String']>;
  file_name?: Maybe<Scalars['String']>;
  file_upload_id?: Maybe<Scalars['String']>;
  identity_id?: Maybe<Scalars['String']>;
  is_archived?: Maybe<Scalars['Boolean']>;
  key?: Maybe<Scalars['String']>;
  level?: Maybe<Scalars['String']>;
  network_id?: Maybe<Scalars['String']>;
  organization_id?: Maybe<Scalars['String']>;
  practice_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  upload_status?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['String']>;
};

/** update columns of table "file_uploads" */
export enum File_Uploads_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FileCategory = 'file_category',
  /** column name */
  FileName = 'file_name',
  /** column name */
  FileUploadId = 'file_upload_id',
  /** column name */
  IdentityId = 'identity_id',
  /** column name */
  IsArchived = 'is_archived',
  /** column name */
  Key = 'key',
  /** column name */
  Level = 'level',
  /** column name */
  NetworkId = 'network_id',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  PracticeId = 'practice_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UploadStatus = 'upload_status',
  /** column name */
  UserId = 'user_id'
}

export type Filtered_Insurance_Counts_Args = {
  _limit?: Maybe<Scalars['Int']>;
  _offset?: Maybe<Scalars['Int']>;
  birth_date_after?: Maybe<Scalars['date']>;
  birth_date_before?: Maybe<Scalars['date']>;
  network_id?: Maybe<Scalars['String']>;
  practice_id?: Maybe<Scalars['String']>;
};

/** columns and relationships of "health_equity_reports" */
export type Health_Equity_Reports = {
  __typename?: 'health_equity_reports';
  /** What is your living situation today? */
  AHC_71802_3?: Maybe<Scalars['String']>;
  /** Within the past 12 months, you worried that your food would run out before you got money to buy more. */
  AHC_88122_7?: Maybe<Scalars['String']>;
  /** Within the past 12 months, the food you bought just didn't last and you didn't have money to get more. */
  AHC_88123_5?: Maybe<Scalars['String']>;
  /** In the past 12 months, has lack of reliable transportation kept you from medical appointments, meetings, work or from getting things needed for daily living? */
  AHC_93030_5?: Maybe<Scalars['String']>;
  /** How often does anyone, including family and friends, scream or curse at you? */
  AHC_95615_1?: Maybe<Scalars['String']>;
  /** How often does anyone, including family and friends, threaten you with harm? */
  AHC_95616_9?: Maybe<Scalars['String']>;
  /** How often does anyone, including family and friends, insult or talk down to you? */
  AHC_95617_7?: Maybe<Scalars['String']>;
  /** How often does anyone, including family and friends, physically hurt you? */
  AHC_95618_5?: Maybe<Scalars['String']>;
  /** In the past 12 months has the electric, gas, oil, or water company threatened to shut off services in your home? */
  AHC_96779_4?: Maybe<Scalars['String']>;
  /** Do you have problems with any of the following? Do you have problems with any of the following? None of the above */
  AHC_LA9_3?: Maybe<Scalars['String']>;
  /** Do you have problems with any of the following? Mold */
  AHC_LA28580_1?: Maybe<Scalars['String']>;
  /** Do you have problems with any of the following? Pests such as bugs, ants, or mice */
  AHC_LA31996_4?: Maybe<Scalars['String']>;
  /** Do you have problems with any of the following? Lead paint or pipes */
  AHC_LA31997_2?: Maybe<Scalars['String']>;
  /** Do you have problems with any of the following? Lack of heat */
  AHC_LA31998_0?: Maybe<Scalars['String']>;
  /** Do you have problems with any of the following? Oven or stove not working */
  AHC_LA31999_8?: Maybe<Scalars['String']>;
  /** Do you have problems with any of the following? Smoke detectors missing or not working */
  AHC_LA32000_4?: Maybe<Scalars['String']>;
  /** Do you have problems with any of the following? Water leaks */
  AHC_LA32001_2?: Maybe<Scalars['String']>;
  /** Within the past 12 months, did you worry that your food would run out before you got money to buy more? */
  NC_Q1?: Maybe<Scalars['String']>;
  /** Within the past 12 months, did the food you bought just not last and you didn’t have money to get more? */
  NC_Q2?: Maybe<Scalars['String']>;
  /** Do you have housing? */
  NC_Q4?: Maybe<Scalars['String']>;
  /** Are you worried about losing your housing? */
  NC_Q5?: Maybe<Scalars['String']>;
  /** Within the past 12 months, have you or your family members you live with been unable to get utilities (heat, electricity) when it was really needed? */
  NC_Q6?: Maybe<Scalars['String']>;
  /** Within the past 12 months, has lack of transportation kept you from medical appointments, getting your medicines, non-medical meetings or appointments, work, or from getting things that you need? */
  NC_Q7?: Maybe<Scalars['String']>;
  /** Do you feel physically and emotionally safe where you currently live? */
  NC_Q8?: Maybe<Scalars['String']>;
  /** Within the past 12 months, have you been hit, slapped, kicked or otherwise physically hurt by someone? */
  NC_Q9?: Maybe<Scalars['String']>;
  /** Within the past 12 months, have you been humiliated or emotionally abused in other ways by your partner or ex-partner? */
  NC_Q10?: Maybe<Scalars['String']>;
  /** How many family members, including yourself, do you currently live with? */
  P_63512_8?: Maybe<Scalars['smallint']>;
  /** During the past year, what was the total combined income for you and the family members you live with? This information will help us determine if you are eligible for any benefits. */
  P_63586_2?: Maybe<Scalars['Int']>;
  /** What is your current work situation? */
  P_67875_5?: Maybe<Scalars['String']>;
  /** What is your current work situation? Other */
  P_67875_5_OS?: Maybe<Scalars['String']>;
  /** What is your housing situation today? */
  P_71802_3?: Maybe<Scalars['String']>;
  /** In the past year, have you been afraid of your partner or ex-partner? */
  P_76501_6?: Maybe<Scalars['String']>;
  /** What is the highest level of school that you have finished? */
  P_82589_3?: Maybe<Scalars['String']>;
  /** Do you feel physically and emotionally safe where you currently live? */
  P_93026_3?: Maybe<Scalars['String']>;
  /** How often do you see or talk to people that you care about and feel close to (For example: talking to friends on the phone, visiting friends or family, going to church or club meetings)? */
  P_93029_7?: Maybe<Scalars['String']>;
  /** Has lack of transportation kept you from medical appointments, meetings, work, or from getting things needed for daily living? */
  P_93030_5?: Maybe<Scalars['String']>;
  /** In the past year, have you or any family members you live with been unable to get any of the following when it was really needed? Other */
  P_93031_3_O?: Maybe<Scalars['String']>;
  /** In the past year, have you or any family members you live with been unable to get any of the following when it was really needed? Specify */
  P_93031_3_OS?: Maybe<Scalars['String']>;
  /** Are you worried about losing your housing? */
  P_93033_9?: Maybe<Scalars['String']>;
  /** Have you been discharged from the armed forces of the United States? */
  P_93034_7?: Maybe<Scalars['String']>;
  /** At any point in the past 2 years, has season or migrant farm work been your or your family's main source of income? */
  P_93035_4?: Maybe<Scalars['String']>;
  /** Stress is when someone feels tense, nervous, anxious or can't sleep at night because their mind is troubled - how stressed are you? */
  P_93038_8?: Maybe<Scalars['String']>;
  /** Choose not to answer the question "How many family members, including yourself, do you currently live with?" */
  P_LA30122_8_FAM?: Maybe<Scalars['String']>;
  /** Choose not to answer the question "During the past year, what was the total combined income for you and the family members you live with? This information will help us determine if you are eligible for any benefits." */
  P_LA30122_8_INC?: Maybe<Scalars['String']>;
  /** Choose not to answer the question "In the past year, have you or any family members you live with been unable to get any of the following when it was really needed?" */
  P_LA30122_8_NEEDS?: Maybe<Scalars['String']>;
  /** In the past year, have you or any family members you live with been unable to get any of the following when it was really needed? Utilities */
  P_LA30124_4?: Maybe<Scalars['String']>;
  /** In the past year, have you or any family members you live with been unable to get any of the following when it was really needed? Food */
  P_LA30125_1?: Maybe<Scalars['String']>;
  /** In the past year, have you or any family members you live with been unable to get any of the following when it was really needed? Clothing */
  P_LA30126_9?: Maybe<Scalars['String']>;
  /** In the past year, have you or any family members you live with been unable to get any of the following when it was really needed? Child care */
  P_LA30127_7?: Maybe<Scalars['String']>;
  /** In the past year, have you or any family members you live with been unable to get any of the following when it was really needed? Medicine or Any Health Care (Medical, Dental, Mental Health, Vision) */
  P_LA30128_5?: Maybe<Scalars['String']>;
  /** In the past year, have you or any family members you live with been unable to get any of the following when it was really needed? Phone */
  P_LA30129_3?: Maybe<Scalars['String']>;
  assessment_declined?: Maybe<Scalars['String']>;
  beneficiary_first_name?: Maybe<Scalars['String']>;
  beneficiary_last_name?: Maybe<Scalars['String']>;
  beneficiary_name?: Maybe<Scalars['String']>;
  birth_date?: Maybe<Scalars['date']>;
  channel?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamptz'];
  date_assessment_complete?: Maybe<Scalars['date']>;
  ethnicity_base?: Maybe<Scalars['String']>;
  ethnicity_expanded_1?: Maybe<Scalars['String']>;
  ethnicity_expanded_2?: Maybe<Scalars['String']>;
  ethnicity_expanded_3?: Maybe<Scalars['String']>;
  ethnicity_expanded_4?: Maybe<Scalars['String']>;
  form_language?: Maybe<Scalars['String']>;
  form_practice_id?: Maybe<Scalars['String']>;
  gender_identity?: Maybe<Scalars['String']>;
  gender_identity_os?: Maybe<Scalars['String']>;
  health_equity_report_id: Scalars['String'];
  letter_id?: Maybe<Scalars['String']>;
  letter_id_prefix?: Maybe<Scalars['String']>;
  matched_patient_id?: Maybe<Scalars['String']>;
  mbi?: Maybe<Scalars['String']>;
  /** An object relationship */
  network?: Maybe<Networks>;
  network_number?: Maybe<Scalars['Int']>;
  ocr_output?: Maybe<Scalars['jsonb']>;
  onedrive_file_id?: Maybe<Scalars['String']>;
  practice_id_prefix?: Maybe<Scalars['String']>;
  preferred_language?: Maybe<Scalars['String']>;
  processing_status: Scalars['String'];
  race?: Maybe<Scalars['String']>;
  sex_assigned_at_birth?: Maybe<Scalars['String']>;
  sex_orientation?: Maybe<Scalars['String']>;
  sex_orientation_os?: Maybe<Scalars['String']>;
  short_url?: Maybe<Scalars['String']>;
  submission_id?: Maybe<Scalars['String']>;
  submission_source?: Maybe<Scalars['String']>;
  template_version?: Maybe<Scalars['String']>;
  transcriber_notes?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "health_equity_reports" */
export type Health_Equity_ReportsOcr_OutputArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "health_equity_reports" */
export type Health_Equity_Reports_Aggregate = {
  __typename?: 'health_equity_reports_aggregate';
  aggregate?: Maybe<Health_Equity_Reports_Aggregate_Fields>;
  nodes: Array<Health_Equity_Reports>;
};

/** aggregate fields of "health_equity_reports" */
export type Health_Equity_Reports_Aggregate_Fields = {
  __typename?: 'health_equity_reports_aggregate_fields';
  avg?: Maybe<Health_Equity_Reports_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Health_Equity_Reports_Max_Fields>;
  min?: Maybe<Health_Equity_Reports_Min_Fields>;
  stddev?: Maybe<Health_Equity_Reports_Stddev_Fields>;
  stddev_pop?: Maybe<Health_Equity_Reports_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Health_Equity_Reports_Stddev_Samp_Fields>;
  sum?: Maybe<Health_Equity_Reports_Sum_Fields>;
  var_pop?: Maybe<Health_Equity_Reports_Var_Pop_Fields>;
  var_samp?: Maybe<Health_Equity_Reports_Var_Samp_Fields>;
  variance?: Maybe<Health_Equity_Reports_Variance_Fields>;
};


/** aggregate fields of "health_equity_reports" */
export type Health_Equity_Reports_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Health_Equity_Reports_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "health_equity_reports" */
export type Health_Equity_Reports_Aggregate_Order_By = {
  avg?: Maybe<Health_Equity_Reports_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Health_Equity_Reports_Max_Order_By>;
  min?: Maybe<Health_Equity_Reports_Min_Order_By>;
  stddev?: Maybe<Health_Equity_Reports_Stddev_Order_By>;
  stddev_pop?: Maybe<Health_Equity_Reports_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Health_Equity_Reports_Stddev_Samp_Order_By>;
  sum?: Maybe<Health_Equity_Reports_Sum_Order_By>;
  var_pop?: Maybe<Health_Equity_Reports_Var_Pop_Order_By>;
  var_samp?: Maybe<Health_Equity_Reports_Var_Samp_Order_By>;
  variance?: Maybe<Health_Equity_Reports_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Health_Equity_Reports_Append_Input = {
  ocr_output?: Maybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "health_equity_reports" */
export type Health_Equity_Reports_Arr_Rel_Insert_Input = {
  data: Array<Health_Equity_Reports_Insert_Input>;
  on_conflict?: Maybe<Health_Equity_Reports_On_Conflict>;
};

/** columns and relationships of "health_equity_reports_audit" */
export type Health_Equity_Reports_Audit = {
  __typename?: 'health_equity_reports_audit';
  action?: Maybe<Scalars['String']>;
  action_tstamp_clk?: Maybe<Scalars['timestamptz']>;
  action_tstamp_stm?: Maybe<Scalars['timestamptz']>;
  action_tstamp_tx?: Maybe<Scalars['timestamptz']>;
  application_name?: Maybe<Scalars['String']>;
  changed_fields?: Maybe<Scalars['jsonb']>;
  client_addr?: Maybe<Scalars['inet']>;
  client_port?: Maybe<Scalars['Int']>;
  client_query?: Maybe<Scalars['String']>;
  event_id?: Maybe<Scalars['bigint']>;
  hasura_user?: Maybe<Scalars['jsonb']>;
  health_equity_report_id?: Maybe<Scalars['String']>;
  relid?: Maybe<Scalars['oid']>;
  row_data?: Maybe<Scalars['jsonb']>;
  schema_name?: Maybe<Scalars['String']>;
  /** An object relationship */
  session_user?: Maybe<Users>;
  session_user_name?: Maybe<Scalars['String']>;
  statement_only?: Maybe<Scalars['Boolean']>;
  table_name?: Maybe<Scalars['String']>;
  transaction_id?: Maybe<Scalars['bigint']>;
};


/** columns and relationships of "health_equity_reports_audit" */
export type Health_Equity_Reports_AuditChanged_FieldsArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "health_equity_reports_audit" */
export type Health_Equity_Reports_AuditHasura_UserArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "health_equity_reports_audit" */
export type Health_Equity_Reports_AuditRow_DataArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "health_equity_reports_audit" */
export type Health_Equity_Reports_Audit_Aggregate = {
  __typename?: 'health_equity_reports_audit_aggregate';
  aggregate?: Maybe<Health_Equity_Reports_Audit_Aggregate_Fields>;
  nodes: Array<Health_Equity_Reports_Audit>;
};

/** aggregate fields of "health_equity_reports_audit" */
export type Health_Equity_Reports_Audit_Aggregate_Fields = {
  __typename?: 'health_equity_reports_audit_aggregate_fields';
  avg?: Maybe<Health_Equity_Reports_Audit_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Health_Equity_Reports_Audit_Max_Fields>;
  min?: Maybe<Health_Equity_Reports_Audit_Min_Fields>;
  stddev?: Maybe<Health_Equity_Reports_Audit_Stddev_Fields>;
  stddev_pop?: Maybe<Health_Equity_Reports_Audit_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Health_Equity_Reports_Audit_Stddev_Samp_Fields>;
  sum?: Maybe<Health_Equity_Reports_Audit_Sum_Fields>;
  var_pop?: Maybe<Health_Equity_Reports_Audit_Var_Pop_Fields>;
  var_samp?: Maybe<Health_Equity_Reports_Audit_Var_Samp_Fields>;
  variance?: Maybe<Health_Equity_Reports_Audit_Variance_Fields>;
};


/** aggregate fields of "health_equity_reports_audit" */
export type Health_Equity_Reports_Audit_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Health_Equity_Reports_Audit_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "health_equity_reports_audit" */
export type Health_Equity_Reports_Audit_Aggregate_Order_By = {
  avg?: Maybe<Health_Equity_Reports_Audit_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Health_Equity_Reports_Audit_Max_Order_By>;
  min?: Maybe<Health_Equity_Reports_Audit_Min_Order_By>;
  stddev?: Maybe<Health_Equity_Reports_Audit_Stddev_Order_By>;
  stddev_pop?: Maybe<Health_Equity_Reports_Audit_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Health_Equity_Reports_Audit_Stddev_Samp_Order_By>;
  sum?: Maybe<Health_Equity_Reports_Audit_Sum_Order_By>;
  var_pop?: Maybe<Health_Equity_Reports_Audit_Var_Pop_Order_By>;
  var_samp?: Maybe<Health_Equity_Reports_Audit_Var_Samp_Order_By>;
  variance?: Maybe<Health_Equity_Reports_Audit_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Health_Equity_Reports_Audit_Append_Input = {
  changed_fields?: Maybe<Scalars['jsonb']>;
  hasura_user?: Maybe<Scalars['jsonb']>;
  row_data?: Maybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "health_equity_reports_audit" */
export type Health_Equity_Reports_Audit_Arr_Rel_Insert_Input = {
  data: Array<Health_Equity_Reports_Audit_Insert_Input>;
};

/** aggregate avg on columns */
export type Health_Equity_Reports_Audit_Avg_Fields = {
  __typename?: 'health_equity_reports_audit_avg_fields';
  client_port?: Maybe<Scalars['Float']>;
  event_id?: Maybe<Scalars['Float']>;
  transaction_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "health_equity_reports_audit" */
export type Health_Equity_Reports_Audit_Avg_Order_By = {
  client_port?: Maybe<Order_By>;
  event_id?: Maybe<Order_By>;
  transaction_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "health_equity_reports_audit". All fields are combined with a logical 'AND'. */
export type Health_Equity_Reports_Audit_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Health_Equity_Reports_Audit_Bool_Exp>>>;
  _not?: Maybe<Health_Equity_Reports_Audit_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Health_Equity_Reports_Audit_Bool_Exp>>>;
  action?: Maybe<String_Comparison_Exp>;
  action_tstamp_clk?: Maybe<Timestamptz_Comparison_Exp>;
  action_tstamp_stm?: Maybe<Timestamptz_Comparison_Exp>;
  action_tstamp_tx?: Maybe<Timestamptz_Comparison_Exp>;
  application_name?: Maybe<String_Comparison_Exp>;
  changed_fields?: Maybe<Jsonb_Comparison_Exp>;
  client_addr?: Maybe<Inet_Comparison_Exp>;
  client_port?: Maybe<Int_Comparison_Exp>;
  client_query?: Maybe<String_Comparison_Exp>;
  event_id?: Maybe<Bigint_Comparison_Exp>;
  hasura_user?: Maybe<Jsonb_Comparison_Exp>;
  health_equity_report_id?: Maybe<String_Comparison_Exp>;
  relid?: Maybe<Oid_Comparison_Exp>;
  row_data?: Maybe<Jsonb_Comparison_Exp>;
  schema_name?: Maybe<String_Comparison_Exp>;
  session_user?: Maybe<Users_Bool_Exp>;
  session_user_name?: Maybe<String_Comparison_Exp>;
  statement_only?: Maybe<Boolean_Comparison_Exp>;
  table_name?: Maybe<String_Comparison_Exp>;
  transaction_id?: Maybe<Bigint_Comparison_Exp>;
};

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Health_Equity_Reports_Audit_Delete_At_Path_Input = {
  changed_fields?: Maybe<Array<Maybe<Scalars['String']>>>;
  hasura_user?: Maybe<Array<Maybe<Scalars['String']>>>;
  row_data?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Health_Equity_Reports_Audit_Delete_Elem_Input = {
  changed_fields?: Maybe<Scalars['Int']>;
  hasura_user?: Maybe<Scalars['Int']>;
  row_data?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Health_Equity_Reports_Audit_Delete_Key_Input = {
  changed_fields?: Maybe<Scalars['String']>;
  hasura_user?: Maybe<Scalars['String']>;
  row_data?: Maybe<Scalars['String']>;
};

/** input type for incrementing integer column in table "health_equity_reports_audit" */
export type Health_Equity_Reports_Audit_Inc_Input = {
  client_port?: Maybe<Scalars['Int']>;
  event_id?: Maybe<Scalars['bigint']>;
  transaction_id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "health_equity_reports_audit" */
export type Health_Equity_Reports_Audit_Insert_Input = {
  action?: Maybe<Scalars['String']>;
  action_tstamp_clk?: Maybe<Scalars['timestamptz']>;
  action_tstamp_stm?: Maybe<Scalars['timestamptz']>;
  action_tstamp_tx?: Maybe<Scalars['timestamptz']>;
  application_name?: Maybe<Scalars['String']>;
  changed_fields?: Maybe<Scalars['jsonb']>;
  client_addr?: Maybe<Scalars['inet']>;
  client_port?: Maybe<Scalars['Int']>;
  client_query?: Maybe<Scalars['String']>;
  event_id?: Maybe<Scalars['bigint']>;
  hasura_user?: Maybe<Scalars['jsonb']>;
  health_equity_report_id?: Maybe<Scalars['String']>;
  relid?: Maybe<Scalars['oid']>;
  row_data?: Maybe<Scalars['jsonb']>;
  schema_name?: Maybe<Scalars['String']>;
  session_user?: Maybe<Users_Obj_Rel_Insert_Input>;
  session_user_name?: Maybe<Scalars['String']>;
  statement_only?: Maybe<Scalars['Boolean']>;
  table_name?: Maybe<Scalars['String']>;
  transaction_id?: Maybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Health_Equity_Reports_Audit_Max_Fields = {
  __typename?: 'health_equity_reports_audit_max_fields';
  action?: Maybe<Scalars['String']>;
  action_tstamp_clk?: Maybe<Scalars['timestamptz']>;
  action_tstamp_stm?: Maybe<Scalars['timestamptz']>;
  action_tstamp_tx?: Maybe<Scalars['timestamptz']>;
  application_name?: Maybe<Scalars['String']>;
  client_port?: Maybe<Scalars['Int']>;
  client_query?: Maybe<Scalars['String']>;
  event_id?: Maybe<Scalars['bigint']>;
  health_equity_report_id?: Maybe<Scalars['String']>;
  schema_name?: Maybe<Scalars['String']>;
  session_user_name?: Maybe<Scalars['String']>;
  table_name?: Maybe<Scalars['String']>;
  transaction_id?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "health_equity_reports_audit" */
export type Health_Equity_Reports_Audit_Max_Order_By = {
  action?: Maybe<Order_By>;
  action_tstamp_clk?: Maybe<Order_By>;
  action_tstamp_stm?: Maybe<Order_By>;
  action_tstamp_tx?: Maybe<Order_By>;
  application_name?: Maybe<Order_By>;
  client_port?: Maybe<Order_By>;
  client_query?: Maybe<Order_By>;
  event_id?: Maybe<Order_By>;
  health_equity_report_id?: Maybe<Order_By>;
  schema_name?: Maybe<Order_By>;
  session_user_name?: Maybe<Order_By>;
  table_name?: Maybe<Order_By>;
  transaction_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Health_Equity_Reports_Audit_Min_Fields = {
  __typename?: 'health_equity_reports_audit_min_fields';
  action?: Maybe<Scalars['String']>;
  action_tstamp_clk?: Maybe<Scalars['timestamptz']>;
  action_tstamp_stm?: Maybe<Scalars['timestamptz']>;
  action_tstamp_tx?: Maybe<Scalars['timestamptz']>;
  application_name?: Maybe<Scalars['String']>;
  client_port?: Maybe<Scalars['Int']>;
  client_query?: Maybe<Scalars['String']>;
  event_id?: Maybe<Scalars['bigint']>;
  health_equity_report_id?: Maybe<Scalars['String']>;
  schema_name?: Maybe<Scalars['String']>;
  session_user_name?: Maybe<Scalars['String']>;
  table_name?: Maybe<Scalars['String']>;
  transaction_id?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "health_equity_reports_audit" */
export type Health_Equity_Reports_Audit_Min_Order_By = {
  action?: Maybe<Order_By>;
  action_tstamp_clk?: Maybe<Order_By>;
  action_tstamp_stm?: Maybe<Order_By>;
  action_tstamp_tx?: Maybe<Order_By>;
  application_name?: Maybe<Order_By>;
  client_port?: Maybe<Order_By>;
  client_query?: Maybe<Order_By>;
  event_id?: Maybe<Order_By>;
  health_equity_report_id?: Maybe<Order_By>;
  schema_name?: Maybe<Order_By>;
  session_user_name?: Maybe<Order_By>;
  table_name?: Maybe<Order_By>;
  transaction_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "health_equity_reports_audit" */
export type Health_Equity_Reports_Audit_Mutation_Response = {
  __typename?: 'health_equity_reports_audit_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Health_Equity_Reports_Audit>;
};

/** input type for inserting object relation for remote table "health_equity_reports_audit" */
export type Health_Equity_Reports_Audit_Obj_Rel_Insert_Input = {
  data: Health_Equity_Reports_Audit_Insert_Input;
};

/** ordering options when selecting data from "health_equity_reports_audit" */
export type Health_Equity_Reports_Audit_Order_By = {
  action?: Maybe<Order_By>;
  action_tstamp_clk?: Maybe<Order_By>;
  action_tstamp_stm?: Maybe<Order_By>;
  action_tstamp_tx?: Maybe<Order_By>;
  application_name?: Maybe<Order_By>;
  changed_fields?: Maybe<Order_By>;
  client_addr?: Maybe<Order_By>;
  client_port?: Maybe<Order_By>;
  client_query?: Maybe<Order_By>;
  event_id?: Maybe<Order_By>;
  hasura_user?: Maybe<Order_By>;
  health_equity_report_id?: Maybe<Order_By>;
  relid?: Maybe<Order_By>;
  row_data?: Maybe<Order_By>;
  schema_name?: Maybe<Order_By>;
  session_user?: Maybe<Users_Order_By>;
  session_user_name?: Maybe<Order_By>;
  statement_only?: Maybe<Order_By>;
  table_name?: Maybe<Order_By>;
  transaction_id?: Maybe<Order_By>;
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Health_Equity_Reports_Audit_Prepend_Input = {
  changed_fields?: Maybe<Scalars['jsonb']>;
  hasura_user?: Maybe<Scalars['jsonb']>;
  row_data?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "health_equity_reports_audit" */
export enum Health_Equity_Reports_Audit_Select_Column {
  /** column name */
  Action = 'action',
  /** column name */
  ActionTstampClk = 'action_tstamp_clk',
  /** column name */
  ActionTstampStm = 'action_tstamp_stm',
  /** column name */
  ActionTstampTx = 'action_tstamp_tx',
  /** column name */
  ApplicationName = 'application_name',
  /** column name */
  ChangedFields = 'changed_fields',
  /** column name */
  ClientAddr = 'client_addr',
  /** column name */
  ClientPort = 'client_port',
  /** column name */
  ClientQuery = 'client_query',
  /** column name */
  EventId = 'event_id',
  /** column name */
  HasuraUser = 'hasura_user',
  /** column name */
  HealthEquityReportId = 'health_equity_report_id',
  /** column name */
  Relid = 'relid',
  /** column name */
  RowData = 'row_data',
  /** column name */
  SchemaName = 'schema_name',
  /** column name */
  SessionUserName = 'session_user_name',
  /** column name */
  StatementOnly = 'statement_only',
  /** column name */
  TableName = 'table_name',
  /** column name */
  TransactionId = 'transaction_id'
}

/** input type for updating data in table "health_equity_reports_audit" */
export type Health_Equity_Reports_Audit_Set_Input = {
  action?: Maybe<Scalars['String']>;
  action_tstamp_clk?: Maybe<Scalars['timestamptz']>;
  action_tstamp_stm?: Maybe<Scalars['timestamptz']>;
  action_tstamp_tx?: Maybe<Scalars['timestamptz']>;
  application_name?: Maybe<Scalars['String']>;
  changed_fields?: Maybe<Scalars['jsonb']>;
  client_addr?: Maybe<Scalars['inet']>;
  client_port?: Maybe<Scalars['Int']>;
  client_query?: Maybe<Scalars['String']>;
  event_id?: Maybe<Scalars['bigint']>;
  hasura_user?: Maybe<Scalars['jsonb']>;
  health_equity_report_id?: Maybe<Scalars['String']>;
  relid?: Maybe<Scalars['oid']>;
  row_data?: Maybe<Scalars['jsonb']>;
  schema_name?: Maybe<Scalars['String']>;
  session_user_name?: Maybe<Scalars['String']>;
  statement_only?: Maybe<Scalars['Boolean']>;
  table_name?: Maybe<Scalars['String']>;
  transaction_id?: Maybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Health_Equity_Reports_Audit_Stddev_Fields = {
  __typename?: 'health_equity_reports_audit_stddev_fields';
  client_port?: Maybe<Scalars['Float']>;
  event_id?: Maybe<Scalars['Float']>;
  transaction_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "health_equity_reports_audit" */
export type Health_Equity_Reports_Audit_Stddev_Order_By = {
  client_port?: Maybe<Order_By>;
  event_id?: Maybe<Order_By>;
  transaction_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Health_Equity_Reports_Audit_Stddev_Pop_Fields = {
  __typename?: 'health_equity_reports_audit_stddev_pop_fields';
  client_port?: Maybe<Scalars['Float']>;
  event_id?: Maybe<Scalars['Float']>;
  transaction_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "health_equity_reports_audit" */
export type Health_Equity_Reports_Audit_Stddev_Pop_Order_By = {
  client_port?: Maybe<Order_By>;
  event_id?: Maybe<Order_By>;
  transaction_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Health_Equity_Reports_Audit_Stddev_Samp_Fields = {
  __typename?: 'health_equity_reports_audit_stddev_samp_fields';
  client_port?: Maybe<Scalars['Float']>;
  event_id?: Maybe<Scalars['Float']>;
  transaction_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "health_equity_reports_audit" */
export type Health_Equity_Reports_Audit_Stddev_Samp_Order_By = {
  client_port?: Maybe<Order_By>;
  event_id?: Maybe<Order_By>;
  transaction_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Health_Equity_Reports_Audit_Sum_Fields = {
  __typename?: 'health_equity_reports_audit_sum_fields';
  client_port?: Maybe<Scalars['Int']>;
  event_id?: Maybe<Scalars['bigint']>;
  transaction_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "health_equity_reports_audit" */
export type Health_Equity_Reports_Audit_Sum_Order_By = {
  client_port?: Maybe<Order_By>;
  event_id?: Maybe<Order_By>;
  transaction_id?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Health_Equity_Reports_Audit_Var_Pop_Fields = {
  __typename?: 'health_equity_reports_audit_var_pop_fields';
  client_port?: Maybe<Scalars['Float']>;
  event_id?: Maybe<Scalars['Float']>;
  transaction_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "health_equity_reports_audit" */
export type Health_Equity_Reports_Audit_Var_Pop_Order_By = {
  client_port?: Maybe<Order_By>;
  event_id?: Maybe<Order_By>;
  transaction_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Health_Equity_Reports_Audit_Var_Samp_Fields = {
  __typename?: 'health_equity_reports_audit_var_samp_fields';
  client_port?: Maybe<Scalars['Float']>;
  event_id?: Maybe<Scalars['Float']>;
  transaction_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "health_equity_reports_audit" */
export type Health_Equity_Reports_Audit_Var_Samp_Order_By = {
  client_port?: Maybe<Order_By>;
  event_id?: Maybe<Order_By>;
  transaction_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Health_Equity_Reports_Audit_Variance_Fields = {
  __typename?: 'health_equity_reports_audit_variance_fields';
  client_port?: Maybe<Scalars['Float']>;
  event_id?: Maybe<Scalars['Float']>;
  transaction_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "health_equity_reports_audit" */
export type Health_Equity_Reports_Audit_Variance_Order_By = {
  client_port?: Maybe<Order_By>;
  event_id?: Maybe<Order_By>;
  transaction_id?: Maybe<Order_By>;
};

/** aggregate avg on columns */
export type Health_Equity_Reports_Avg_Fields = {
  __typename?: 'health_equity_reports_avg_fields';
  P_63512_8?: Maybe<Scalars['Float']>;
  P_63586_2?: Maybe<Scalars['Float']>;
  network_number?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "health_equity_reports" */
export type Health_Equity_Reports_Avg_Order_By = {
  P_63512_8?: Maybe<Order_By>;
  P_63586_2?: Maybe<Order_By>;
  network_number?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "health_equity_reports". All fields are combined with a logical 'AND'. */
export type Health_Equity_Reports_Bool_Exp = {
  AHC_71802_3?: Maybe<String_Comparison_Exp>;
  AHC_88122_7?: Maybe<String_Comparison_Exp>;
  AHC_88123_5?: Maybe<String_Comparison_Exp>;
  AHC_93030_5?: Maybe<String_Comparison_Exp>;
  AHC_95615_1?: Maybe<String_Comparison_Exp>;
  AHC_95616_9?: Maybe<String_Comparison_Exp>;
  AHC_95617_7?: Maybe<String_Comparison_Exp>;
  AHC_95618_5?: Maybe<String_Comparison_Exp>;
  AHC_96779_4?: Maybe<String_Comparison_Exp>;
  AHC_LA9_3?: Maybe<String_Comparison_Exp>;
  AHC_LA28580_1?: Maybe<String_Comparison_Exp>;
  AHC_LA31996_4?: Maybe<String_Comparison_Exp>;
  AHC_LA31997_2?: Maybe<String_Comparison_Exp>;
  AHC_LA31998_0?: Maybe<String_Comparison_Exp>;
  AHC_LA31999_8?: Maybe<String_Comparison_Exp>;
  AHC_LA32000_4?: Maybe<String_Comparison_Exp>;
  AHC_LA32001_2?: Maybe<String_Comparison_Exp>;
  NC_Q1?: Maybe<String_Comparison_Exp>;
  NC_Q2?: Maybe<String_Comparison_Exp>;
  NC_Q4?: Maybe<String_Comparison_Exp>;
  NC_Q5?: Maybe<String_Comparison_Exp>;
  NC_Q6?: Maybe<String_Comparison_Exp>;
  NC_Q7?: Maybe<String_Comparison_Exp>;
  NC_Q8?: Maybe<String_Comparison_Exp>;
  NC_Q9?: Maybe<String_Comparison_Exp>;
  NC_Q10?: Maybe<String_Comparison_Exp>;
  P_63512_8?: Maybe<Smallint_Comparison_Exp>;
  P_63586_2?: Maybe<Int_Comparison_Exp>;
  P_67875_5?: Maybe<String_Comparison_Exp>;
  P_67875_5_OS?: Maybe<String_Comparison_Exp>;
  P_71802_3?: Maybe<String_Comparison_Exp>;
  P_76501_6?: Maybe<String_Comparison_Exp>;
  P_82589_3?: Maybe<String_Comparison_Exp>;
  P_93026_3?: Maybe<String_Comparison_Exp>;
  P_93029_7?: Maybe<String_Comparison_Exp>;
  P_93030_5?: Maybe<String_Comparison_Exp>;
  P_93031_3_O?: Maybe<String_Comparison_Exp>;
  P_93031_3_OS?: Maybe<String_Comparison_Exp>;
  P_93033_9?: Maybe<String_Comparison_Exp>;
  P_93034_7?: Maybe<String_Comparison_Exp>;
  P_93035_4?: Maybe<String_Comparison_Exp>;
  P_93038_8?: Maybe<String_Comparison_Exp>;
  P_LA30122_8_FAM?: Maybe<String_Comparison_Exp>;
  P_LA30122_8_INC?: Maybe<String_Comparison_Exp>;
  P_LA30122_8_NEEDS?: Maybe<String_Comparison_Exp>;
  P_LA30124_4?: Maybe<String_Comparison_Exp>;
  P_LA30125_1?: Maybe<String_Comparison_Exp>;
  P_LA30126_9?: Maybe<String_Comparison_Exp>;
  P_LA30127_7?: Maybe<String_Comparison_Exp>;
  P_LA30128_5?: Maybe<String_Comparison_Exp>;
  P_LA30129_3?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Maybe<Health_Equity_Reports_Bool_Exp>>>;
  _not?: Maybe<Health_Equity_Reports_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Health_Equity_Reports_Bool_Exp>>>;
  assessment_declined?: Maybe<String_Comparison_Exp>;
  beneficiary_first_name?: Maybe<String_Comparison_Exp>;
  beneficiary_last_name?: Maybe<String_Comparison_Exp>;
  beneficiary_name?: Maybe<String_Comparison_Exp>;
  birth_date?: Maybe<Date_Comparison_Exp>;
  channel?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  date_assessment_complete?: Maybe<Date_Comparison_Exp>;
  ethnicity_base?: Maybe<String_Comparison_Exp>;
  ethnicity_expanded_1?: Maybe<String_Comparison_Exp>;
  ethnicity_expanded_2?: Maybe<String_Comparison_Exp>;
  ethnicity_expanded_3?: Maybe<String_Comparison_Exp>;
  ethnicity_expanded_4?: Maybe<String_Comparison_Exp>;
  form_language?: Maybe<String_Comparison_Exp>;
  form_practice_id?: Maybe<String_Comparison_Exp>;
  gender_identity?: Maybe<String_Comparison_Exp>;
  gender_identity_os?: Maybe<String_Comparison_Exp>;
  health_equity_report_id?: Maybe<String_Comparison_Exp>;
  letter_id?: Maybe<String_Comparison_Exp>;
  letter_id_prefix?: Maybe<String_Comparison_Exp>;
  matched_patient_id?: Maybe<String_Comparison_Exp>;
  mbi?: Maybe<String_Comparison_Exp>;
  network?: Maybe<Networks_Bool_Exp>;
  network_number?: Maybe<Int_Comparison_Exp>;
  ocr_output?: Maybe<Jsonb_Comparison_Exp>;
  onedrive_file_id?: Maybe<String_Comparison_Exp>;
  practice_id_prefix?: Maybe<String_Comparison_Exp>;
  preferred_language?: Maybe<String_Comparison_Exp>;
  processing_status?: Maybe<String_Comparison_Exp>;
  race?: Maybe<String_Comparison_Exp>;
  sex_assigned_at_birth?: Maybe<String_Comparison_Exp>;
  sex_orientation?: Maybe<String_Comparison_Exp>;
  sex_orientation_os?: Maybe<String_Comparison_Exp>;
  short_url?: Maybe<String_Comparison_Exp>;
  submission_id?: Maybe<String_Comparison_Exp>;
  submission_source?: Maybe<String_Comparison_Exp>;
  template_version?: Maybe<String_Comparison_Exp>;
  transcriber_notes?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "health_equity_reports" */
export enum Health_Equity_Reports_Constraint {
  /** unique or primary key constraint */
  HealthEquityReportsPkey = 'health_equity_reports_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Health_Equity_Reports_Delete_At_Path_Input = {
  ocr_output?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Health_Equity_Reports_Delete_Elem_Input = {
  ocr_output?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Health_Equity_Reports_Delete_Key_Input = {
  ocr_output?: Maybe<Scalars['String']>;
};

/** input type for incrementing integer column in table "health_equity_reports" */
export type Health_Equity_Reports_Inc_Input = {
  P_63512_8?: Maybe<Scalars['smallint']>;
  P_63586_2?: Maybe<Scalars['Int']>;
  network_number?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "health_equity_reports" */
export type Health_Equity_Reports_Insert_Input = {
  AHC_71802_3?: Maybe<Scalars['String']>;
  AHC_88122_7?: Maybe<Scalars['String']>;
  AHC_88123_5?: Maybe<Scalars['String']>;
  AHC_93030_5?: Maybe<Scalars['String']>;
  AHC_95615_1?: Maybe<Scalars['String']>;
  AHC_95616_9?: Maybe<Scalars['String']>;
  AHC_95617_7?: Maybe<Scalars['String']>;
  AHC_95618_5?: Maybe<Scalars['String']>;
  AHC_96779_4?: Maybe<Scalars['String']>;
  AHC_LA9_3?: Maybe<Scalars['String']>;
  AHC_LA28580_1?: Maybe<Scalars['String']>;
  AHC_LA31996_4?: Maybe<Scalars['String']>;
  AHC_LA31997_2?: Maybe<Scalars['String']>;
  AHC_LA31998_0?: Maybe<Scalars['String']>;
  AHC_LA31999_8?: Maybe<Scalars['String']>;
  AHC_LA32000_4?: Maybe<Scalars['String']>;
  AHC_LA32001_2?: Maybe<Scalars['String']>;
  NC_Q1?: Maybe<Scalars['String']>;
  NC_Q2?: Maybe<Scalars['String']>;
  NC_Q4?: Maybe<Scalars['String']>;
  NC_Q5?: Maybe<Scalars['String']>;
  NC_Q6?: Maybe<Scalars['String']>;
  NC_Q7?: Maybe<Scalars['String']>;
  NC_Q8?: Maybe<Scalars['String']>;
  NC_Q9?: Maybe<Scalars['String']>;
  NC_Q10?: Maybe<Scalars['String']>;
  P_63512_8?: Maybe<Scalars['smallint']>;
  P_63586_2?: Maybe<Scalars['Int']>;
  P_67875_5?: Maybe<Scalars['String']>;
  P_67875_5_OS?: Maybe<Scalars['String']>;
  P_71802_3?: Maybe<Scalars['String']>;
  P_76501_6?: Maybe<Scalars['String']>;
  P_82589_3?: Maybe<Scalars['String']>;
  P_93026_3?: Maybe<Scalars['String']>;
  P_93029_7?: Maybe<Scalars['String']>;
  P_93030_5?: Maybe<Scalars['String']>;
  P_93031_3_O?: Maybe<Scalars['String']>;
  P_93031_3_OS?: Maybe<Scalars['String']>;
  P_93033_9?: Maybe<Scalars['String']>;
  P_93034_7?: Maybe<Scalars['String']>;
  P_93035_4?: Maybe<Scalars['String']>;
  P_93038_8?: Maybe<Scalars['String']>;
  P_LA30122_8_FAM?: Maybe<Scalars['String']>;
  P_LA30122_8_INC?: Maybe<Scalars['String']>;
  P_LA30122_8_NEEDS?: Maybe<Scalars['String']>;
  P_LA30124_4?: Maybe<Scalars['String']>;
  P_LA30125_1?: Maybe<Scalars['String']>;
  P_LA30126_9?: Maybe<Scalars['String']>;
  P_LA30127_7?: Maybe<Scalars['String']>;
  P_LA30128_5?: Maybe<Scalars['String']>;
  P_LA30129_3?: Maybe<Scalars['String']>;
  assessment_declined?: Maybe<Scalars['String']>;
  beneficiary_first_name?: Maybe<Scalars['String']>;
  beneficiary_last_name?: Maybe<Scalars['String']>;
  beneficiary_name?: Maybe<Scalars['String']>;
  birth_date?: Maybe<Scalars['date']>;
  channel?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  date_assessment_complete?: Maybe<Scalars['date']>;
  ethnicity_base?: Maybe<Scalars['String']>;
  ethnicity_expanded_1?: Maybe<Scalars['String']>;
  ethnicity_expanded_2?: Maybe<Scalars['String']>;
  ethnicity_expanded_3?: Maybe<Scalars['String']>;
  ethnicity_expanded_4?: Maybe<Scalars['String']>;
  form_language?: Maybe<Scalars['String']>;
  form_practice_id?: Maybe<Scalars['String']>;
  gender_identity?: Maybe<Scalars['String']>;
  gender_identity_os?: Maybe<Scalars['String']>;
  health_equity_report_id?: Maybe<Scalars['String']>;
  letter_id?: Maybe<Scalars['String']>;
  letter_id_prefix?: Maybe<Scalars['String']>;
  matched_patient_id?: Maybe<Scalars['String']>;
  mbi?: Maybe<Scalars['String']>;
  network?: Maybe<Networks_Obj_Rel_Insert_Input>;
  network_number?: Maybe<Scalars['Int']>;
  ocr_output?: Maybe<Scalars['jsonb']>;
  onedrive_file_id?: Maybe<Scalars['String']>;
  practice_id_prefix?: Maybe<Scalars['String']>;
  preferred_language?: Maybe<Scalars['String']>;
  processing_status?: Maybe<Scalars['String']>;
  race?: Maybe<Scalars['String']>;
  sex_assigned_at_birth?: Maybe<Scalars['String']>;
  sex_orientation?: Maybe<Scalars['String']>;
  sex_orientation_os?: Maybe<Scalars['String']>;
  short_url?: Maybe<Scalars['String']>;
  submission_id?: Maybe<Scalars['String']>;
  submission_source?: Maybe<Scalars['String']>;
  template_version?: Maybe<Scalars['String']>;
  transcriber_notes?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Health_Equity_Reports_Max_Fields = {
  __typename?: 'health_equity_reports_max_fields';
  AHC_71802_3?: Maybe<Scalars['String']>;
  AHC_88122_7?: Maybe<Scalars['String']>;
  AHC_88123_5?: Maybe<Scalars['String']>;
  AHC_93030_5?: Maybe<Scalars['String']>;
  AHC_95615_1?: Maybe<Scalars['String']>;
  AHC_95616_9?: Maybe<Scalars['String']>;
  AHC_95617_7?: Maybe<Scalars['String']>;
  AHC_95618_5?: Maybe<Scalars['String']>;
  AHC_96779_4?: Maybe<Scalars['String']>;
  AHC_LA9_3?: Maybe<Scalars['String']>;
  AHC_LA28580_1?: Maybe<Scalars['String']>;
  AHC_LA31996_4?: Maybe<Scalars['String']>;
  AHC_LA31997_2?: Maybe<Scalars['String']>;
  AHC_LA31998_0?: Maybe<Scalars['String']>;
  AHC_LA31999_8?: Maybe<Scalars['String']>;
  AHC_LA32000_4?: Maybe<Scalars['String']>;
  AHC_LA32001_2?: Maybe<Scalars['String']>;
  NC_Q1?: Maybe<Scalars['String']>;
  NC_Q2?: Maybe<Scalars['String']>;
  NC_Q4?: Maybe<Scalars['String']>;
  NC_Q5?: Maybe<Scalars['String']>;
  NC_Q6?: Maybe<Scalars['String']>;
  NC_Q7?: Maybe<Scalars['String']>;
  NC_Q8?: Maybe<Scalars['String']>;
  NC_Q9?: Maybe<Scalars['String']>;
  NC_Q10?: Maybe<Scalars['String']>;
  P_63512_8?: Maybe<Scalars['smallint']>;
  P_63586_2?: Maybe<Scalars['Int']>;
  P_67875_5?: Maybe<Scalars['String']>;
  P_67875_5_OS?: Maybe<Scalars['String']>;
  P_71802_3?: Maybe<Scalars['String']>;
  P_76501_6?: Maybe<Scalars['String']>;
  P_82589_3?: Maybe<Scalars['String']>;
  P_93026_3?: Maybe<Scalars['String']>;
  P_93029_7?: Maybe<Scalars['String']>;
  P_93030_5?: Maybe<Scalars['String']>;
  P_93031_3_O?: Maybe<Scalars['String']>;
  P_93031_3_OS?: Maybe<Scalars['String']>;
  P_93033_9?: Maybe<Scalars['String']>;
  P_93034_7?: Maybe<Scalars['String']>;
  P_93035_4?: Maybe<Scalars['String']>;
  P_93038_8?: Maybe<Scalars['String']>;
  P_LA30122_8_FAM?: Maybe<Scalars['String']>;
  P_LA30122_8_INC?: Maybe<Scalars['String']>;
  P_LA30122_8_NEEDS?: Maybe<Scalars['String']>;
  P_LA30124_4?: Maybe<Scalars['String']>;
  P_LA30125_1?: Maybe<Scalars['String']>;
  P_LA30126_9?: Maybe<Scalars['String']>;
  P_LA30127_7?: Maybe<Scalars['String']>;
  P_LA30128_5?: Maybe<Scalars['String']>;
  P_LA30129_3?: Maybe<Scalars['String']>;
  assessment_declined?: Maybe<Scalars['String']>;
  beneficiary_first_name?: Maybe<Scalars['String']>;
  beneficiary_last_name?: Maybe<Scalars['String']>;
  beneficiary_name?: Maybe<Scalars['String']>;
  birth_date?: Maybe<Scalars['date']>;
  channel?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  date_assessment_complete?: Maybe<Scalars['date']>;
  ethnicity_base?: Maybe<Scalars['String']>;
  ethnicity_expanded_1?: Maybe<Scalars['String']>;
  ethnicity_expanded_2?: Maybe<Scalars['String']>;
  ethnicity_expanded_3?: Maybe<Scalars['String']>;
  ethnicity_expanded_4?: Maybe<Scalars['String']>;
  form_language?: Maybe<Scalars['String']>;
  form_practice_id?: Maybe<Scalars['String']>;
  gender_identity?: Maybe<Scalars['String']>;
  gender_identity_os?: Maybe<Scalars['String']>;
  health_equity_report_id?: Maybe<Scalars['String']>;
  letter_id?: Maybe<Scalars['String']>;
  letter_id_prefix?: Maybe<Scalars['String']>;
  matched_patient_id?: Maybe<Scalars['String']>;
  mbi?: Maybe<Scalars['String']>;
  network_number?: Maybe<Scalars['Int']>;
  onedrive_file_id?: Maybe<Scalars['String']>;
  practice_id_prefix?: Maybe<Scalars['String']>;
  preferred_language?: Maybe<Scalars['String']>;
  processing_status?: Maybe<Scalars['String']>;
  race?: Maybe<Scalars['String']>;
  sex_assigned_at_birth?: Maybe<Scalars['String']>;
  sex_orientation?: Maybe<Scalars['String']>;
  sex_orientation_os?: Maybe<Scalars['String']>;
  short_url?: Maybe<Scalars['String']>;
  submission_id?: Maybe<Scalars['String']>;
  submission_source?: Maybe<Scalars['String']>;
  template_version?: Maybe<Scalars['String']>;
  transcriber_notes?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "health_equity_reports" */
export type Health_Equity_Reports_Max_Order_By = {
  AHC_71802_3?: Maybe<Order_By>;
  AHC_88122_7?: Maybe<Order_By>;
  AHC_88123_5?: Maybe<Order_By>;
  AHC_93030_5?: Maybe<Order_By>;
  AHC_95615_1?: Maybe<Order_By>;
  AHC_95616_9?: Maybe<Order_By>;
  AHC_95617_7?: Maybe<Order_By>;
  AHC_95618_5?: Maybe<Order_By>;
  AHC_96779_4?: Maybe<Order_By>;
  AHC_LA9_3?: Maybe<Order_By>;
  AHC_LA28580_1?: Maybe<Order_By>;
  AHC_LA31996_4?: Maybe<Order_By>;
  AHC_LA31997_2?: Maybe<Order_By>;
  AHC_LA31998_0?: Maybe<Order_By>;
  AHC_LA31999_8?: Maybe<Order_By>;
  AHC_LA32000_4?: Maybe<Order_By>;
  AHC_LA32001_2?: Maybe<Order_By>;
  NC_Q1?: Maybe<Order_By>;
  NC_Q2?: Maybe<Order_By>;
  NC_Q4?: Maybe<Order_By>;
  NC_Q5?: Maybe<Order_By>;
  NC_Q6?: Maybe<Order_By>;
  NC_Q7?: Maybe<Order_By>;
  NC_Q8?: Maybe<Order_By>;
  NC_Q9?: Maybe<Order_By>;
  NC_Q10?: Maybe<Order_By>;
  P_63512_8?: Maybe<Order_By>;
  P_63586_2?: Maybe<Order_By>;
  P_67875_5?: Maybe<Order_By>;
  P_67875_5_OS?: Maybe<Order_By>;
  P_71802_3?: Maybe<Order_By>;
  P_76501_6?: Maybe<Order_By>;
  P_82589_3?: Maybe<Order_By>;
  P_93026_3?: Maybe<Order_By>;
  P_93029_7?: Maybe<Order_By>;
  P_93030_5?: Maybe<Order_By>;
  P_93031_3_O?: Maybe<Order_By>;
  P_93031_3_OS?: Maybe<Order_By>;
  P_93033_9?: Maybe<Order_By>;
  P_93034_7?: Maybe<Order_By>;
  P_93035_4?: Maybe<Order_By>;
  P_93038_8?: Maybe<Order_By>;
  P_LA30122_8_FAM?: Maybe<Order_By>;
  P_LA30122_8_INC?: Maybe<Order_By>;
  P_LA30122_8_NEEDS?: Maybe<Order_By>;
  P_LA30124_4?: Maybe<Order_By>;
  P_LA30125_1?: Maybe<Order_By>;
  P_LA30126_9?: Maybe<Order_By>;
  P_LA30127_7?: Maybe<Order_By>;
  P_LA30128_5?: Maybe<Order_By>;
  P_LA30129_3?: Maybe<Order_By>;
  assessment_declined?: Maybe<Order_By>;
  beneficiary_first_name?: Maybe<Order_By>;
  beneficiary_last_name?: Maybe<Order_By>;
  beneficiary_name?: Maybe<Order_By>;
  birth_date?: Maybe<Order_By>;
  channel?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  date_assessment_complete?: Maybe<Order_By>;
  ethnicity_base?: Maybe<Order_By>;
  ethnicity_expanded_1?: Maybe<Order_By>;
  ethnicity_expanded_2?: Maybe<Order_By>;
  ethnicity_expanded_3?: Maybe<Order_By>;
  ethnicity_expanded_4?: Maybe<Order_By>;
  form_language?: Maybe<Order_By>;
  form_practice_id?: Maybe<Order_By>;
  gender_identity?: Maybe<Order_By>;
  gender_identity_os?: Maybe<Order_By>;
  health_equity_report_id?: Maybe<Order_By>;
  letter_id?: Maybe<Order_By>;
  letter_id_prefix?: Maybe<Order_By>;
  matched_patient_id?: Maybe<Order_By>;
  mbi?: Maybe<Order_By>;
  network_number?: Maybe<Order_By>;
  onedrive_file_id?: Maybe<Order_By>;
  practice_id_prefix?: Maybe<Order_By>;
  preferred_language?: Maybe<Order_By>;
  processing_status?: Maybe<Order_By>;
  race?: Maybe<Order_By>;
  sex_assigned_at_birth?: Maybe<Order_By>;
  sex_orientation?: Maybe<Order_By>;
  sex_orientation_os?: Maybe<Order_By>;
  short_url?: Maybe<Order_By>;
  submission_id?: Maybe<Order_By>;
  submission_source?: Maybe<Order_By>;
  template_version?: Maybe<Order_By>;
  transcriber_notes?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Health_Equity_Reports_Min_Fields = {
  __typename?: 'health_equity_reports_min_fields';
  AHC_71802_3?: Maybe<Scalars['String']>;
  AHC_88122_7?: Maybe<Scalars['String']>;
  AHC_88123_5?: Maybe<Scalars['String']>;
  AHC_93030_5?: Maybe<Scalars['String']>;
  AHC_95615_1?: Maybe<Scalars['String']>;
  AHC_95616_9?: Maybe<Scalars['String']>;
  AHC_95617_7?: Maybe<Scalars['String']>;
  AHC_95618_5?: Maybe<Scalars['String']>;
  AHC_96779_4?: Maybe<Scalars['String']>;
  AHC_LA9_3?: Maybe<Scalars['String']>;
  AHC_LA28580_1?: Maybe<Scalars['String']>;
  AHC_LA31996_4?: Maybe<Scalars['String']>;
  AHC_LA31997_2?: Maybe<Scalars['String']>;
  AHC_LA31998_0?: Maybe<Scalars['String']>;
  AHC_LA31999_8?: Maybe<Scalars['String']>;
  AHC_LA32000_4?: Maybe<Scalars['String']>;
  AHC_LA32001_2?: Maybe<Scalars['String']>;
  NC_Q1?: Maybe<Scalars['String']>;
  NC_Q2?: Maybe<Scalars['String']>;
  NC_Q4?: Maybe<Scalars['String']>;
  NC_Q5?: Maybe<Scalars['String']>;
  NC_Q6?: Maybe<Scalars['String']>;
  NC_Q7?: Maybe<Scalars['String']>;
  NC_Q8?: Maybe<Scalars['String']>;
  NC_Q9?: Maybe<Scalars['String']>;
  NC_Q10?: Maybe<Scalars['String']>;
  P_63512_8?: Maybe<Scalars['smallint']>;
  P_63586_2?: Maybe<Scalars['Int']>;
  P_67875_5?: Maybe<Scalars['String']>;
  P_67875_5_OS?: Maybe<Scalars['String']>;
  P_71802_3?: Maybe<Scalars['String']>;
  P_76501_6?: Maybe<Scalars['String']>;
  P_82589_3?: Maybe<Scalars['String']>;
  P_93026_3?: Maybe<Scalars['String']>;
  P_93029_7?: Maybe<Scalars['String']>;
  P_93030_5?: Maybe<Scalars['String']>;
  P_93031_3_O?: Maybe<Scalars['String']>;
  P_93031_3_OS?: Maybe<Scalars['String']>;
  P_93033_9?: Maybe<Scalars['String']>;
  P_93034_7?: Maybe<Scalars['String']>;
  P_93035_4?: Maybe<Scalars['String']>;
  P_93038_8?: Maybe<Scalars['String']>;
  P_LA30122_8_FAM?: Maybe<Scalars['String']>;
  P_LA30122_8_INC?: Maybe<Scalars['String']>;
  P_LA30122_8_NEEDS?: Maybe<Scalars['String']>;
  P_LA30124_4?: Maybe<Scalars['String']>;
  P_LA30125_1?: Maybe<Scalars['String']>;
  P_LA30126_9?: Maybe<Scalars['String']>;
  P_LA30127_7?: Maybe<Scalars['String']>;
  P_LA30128_5?: Maybe<Scalars['String']>;
  P_LA30129_3?: Maybe<Scalars['String']>;
  assessment_declined?: Maybe<Scalars['String']>;
  beneficiary_first_name?: Maybe<Scalars['String']>;
  beneficiary_last_name?: Maybe<Scalars['String']>;
  beneficiary_name?: Maybe<Scalars['String']>;
  birth_date?: Maybe<Scalars['date']>;
  channel?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  date_assessment_complete?: Maybe<Scalars['date']>;
  ethnicity_base?: Maybe<Scalars['String']>;
  ethnicity_expanded_1?: Maybe<Scalars['String']>;
  ethnicity_expanded_2?: Maybe<Scalars['String']>;
  ethnicity_expanded_3?: Maybe<Scalars['String']>;
  ethnicity_expanded_4?: Maybe<Scalars['String']>;
  form_language?: Maybe<Scalars['String']>;
  form_practice_id?: Maybe<Scalars['String']>;
  gender_identity?: Maybe<Scalars['String']>;
  gender_identity_os?: Maybe<Scalars['String']>;
  health_equity_report_id?: Maybe<Scalars['String']>;
  letter_id?: Maybe<Scalars['String']>;
  letter_id_prefix?: Maybe<Scalars['String']>;
  matched_patient_id?: Maybe<Scalars['String']>;
  mbi?: Maybe<Scalars['String']>;
  network_number?: Maybe<Scalars['Int']>;
  onedrive_file_id?: Maybe<Scalars['String']>;
  practice_id_prefix?: Maybe<Scalars['String']>;
  preferred_language?: Maybe<Scalars['String']>;
  processing_status?: Maybe<Scalars['String']>;
  race?: Maybe<Scalars['String']>;
  sex_assigned_at_birth?: Maybe<Scalars['String']>;
  sex_orientation?: Maybe<Scalars['String']>;
  sex_orientation_os?: Maybe<Scalars['String']>;
  short_url?: Maybe<Scalars['String']>;
  submission_id?: Maybe<Scalars['String']>;
  submission_source?: Maybe<Scalars['String']>;
  template_version?: Maybe<Scalars['String']>;
  transcriber_notes?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "health_equity_reports" */
export type Health_Equity_Reports_Min_Order_By = {
  AHC_71802_3?: Maybe<Order_By>;
  AHC_88122_7?: Maybe<Order_By>;
  AHC_88123_5?: Maybe<Order_By>;
  AHC_93030_5?: Maybe<Order_By>;
  AHC_95615_1?: Maybe<Order_By>;
  AHC_95616_9?: Maybe<Order_By>;
  AHC_95617_7?: Maybe<Order_By>;
  AHC_95618_5?: Maybe<Order_By>;
  AHC_96779_4?: Maybe<Order_By>;
  AHC_LA9_3?: Maybe<Order_By>;
  AHC_LA28580_1?: Maybe<Order_By>;
  AHC_LA31996_4?: Maybe<Order_By>;
  AHC_LA31997_2?: Maybe<Order_By>;
  AHC_LA31998_0?: Maybe<Order_By>;
  AHC_LA31999_8?: Maybe<Order_By>;
  AHC_LA32000_4?: Maybe<Order_By>;
  AHC_LA32001_2?: Maybe<Order_By>;
  NC_Q1?: Maybe<Order_By>;
  NC_Q2?: Maybe<Order_By>;
  NC_Q4?: Maybe<Order_By>;
  NC_Q5?: Maybe<Order_By>;
  NC_Q6?: Maybe<Order_By>;
  NC_Q7?: Maybe<Order_By>;
  NC_Q8?: Maybe<Order_By>;
  NC_Q9?: Maybe<Order_By>;
  NC_Q10?: Maybe<Order_By>;
  P_63512_8?: Maybe<Order_By>;
  P_63586_2?: Maybe<Order_By>;
  P_67875_5?: Maybe<Order_By>;
  P_67875_5_OS?: Maybe<Order_By>;
  P_71802_3?: Maybe<Order_By>;
  P_76501_6?: Maybe<Order_By>;
  P_82589_3?: Maybe<Order_By>;
  P_93026_3?: Maybe<Order_By>;
  P_93029_7?: Maybe<Order_By>;
  P_93030_5?: Maybe<Order_By>;
  P_93031_3_O?: Maybe<Order_By>;
  P_93031_3_OS?: Maybe<Order_By>;
  P_93033_9?: Maybe<Order_By>;
  P_93034_7?: Maybe<Order_By>;
  P_93035_4?: Maybe<Order_By>;
  P_93038_8?: Maybe<Order_By>;
  P_LA30122_8_FAM?: Maybe<Order_By>;
  P_LA30122_8_INC?: Maybe<Order_By>;
  P_LA30122_8_NEEDS?: Maybe<Order_By>;
  P_LA30124_4?: Maybe<Order_By>;
  P_LA30125_1?: Maybe<Order_By>;
  P_LA30126_9?: Maybe<Order_By>;
  P_LA30127_7?: Maybe<Order_By>;
  P_LA30128_5?: Maybe<Order_By>;
  P_LA30129_3?: Maybe<Order_By>;
  assessment_declined?: Maybe<Order_By>;
  beneficiary_first_name?: Maybe<Order_By>;
  beneficiary_last_name?: Maybe<Order_By>;
  beneficiary_name?: Maybe<Order_By>;
  birth_date?: Maybe<Order_By>;
  channel?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  date_assessment_complete?: Maybe<Order_By>;
  ethnicity_base?: Maybe<Order_By>;
  ethnicity_expanded_1?: Maybe<Order_By>;
  ethnicity_expanded_2?: Maybe<Order_By>;
  ethnicity_expanded_3?: Maybe<Order_By>;
  ethnicity_expanded_4?: Maybe<Order_By>;
  form_language?: Maybe<Order_By>;
  form_practice_id?: Maybe<Order_By>;
  gender_identity?: Maybe<Order_By>;
  gender_identity_os?: Maybe<Order_By>;
  health_equity_report_id?: Maybe<Order_By>;
  letter_id?: Maybe<Order_By>;
  letter_id_prefix?: Maybe<Order_By>;
  matched_patient_id?: Maybe<Order_By>;
  mbi?: Maybe<Order_By>;
  network_number?: Maybe<Order_By>;
  onedrive_file_id?: Maybe<Order_By>;
  practice_id_prefix?: Maybe<Order_By>;
  preferred_language?: Maybe<Order_By>;
  processing_status?: Maybe<Order_By>;
  race?: Maybe<Order_By>;
  sex_assigned_at_birth?: Maybe<Order_By>;
  sex_orientation?: Maybe<Order_By>;
  sex_orientation_os?: Maybe<Order_By>;
  short_url?: Maybe<Order_By>;
  submission_id?: Maybe<Order_By>;
  submission_source?: Maybe<Order_By>;
  template_version?: Maybe<Order_By>;
  transcriber_notes?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "health_equity_reports" */
export type Health_Equity_Reports_Mutation_Response = {
  __typename?: 'health_equity_reports_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Health_Equity_Reports>;
};

/** input type for inserting object relation for remote table "health_equity_reports" */
export type Health_Equity_Reports_Obj_Rel_Insert_Input = {
  data: Health_Equity_Reports_Insert_Input;
  on_conflict?: Maybe<Health_Equity_Reports_On_Conflict>;
};

/** on conflict condition type for table "health_equity_reports" */
export type Health_Equity_Reports_On_Conflict = {
  constraint: Health_Equity_Reports_Constraint;
  update_columns: Array<Health_Equity_Reports_Update_Column>;
  where?: Maybe<Health_Equity_Reports_Bool_Exp>;
};

/** ordering options when selecting data from "health_equity_reports" */
export type Health_Equity_Reports_Order_By = {
  AHC_71802_3?: Maybe<Order_By>;
  AHC_88122_7?: Maybe<Order_By>;
  AHC_88123_5?: Maybe<Order_By>;
  AHC_93030_5?: Maybe<Order_By>;
  AHC_95615_1?: Maybe<Order_By>;
  AHC_95616_9?: Maybe<Order_By>;
  AHC_95617_7?: Maybe<Order_By>;
  AHC_95618_5?: Maybe<Order_By>;
  AHC_96779_4?: Maybe<Order_By>;
  AHC_LA9_3?: Maybe<Order_By>;
  AHC_LA28580_1?: Maybe<Order_By>;
  AHC_LA31996_4?: Maybe<Order_By>;
  AHC_LA31997_2?: Maybe<Order_By>;
  AHC_LA31998_0?: Maybe<Order_By>;
  AHC_LA31999_8?: Maybe<Order_By>;
  AHC_LA32000_4?: Maybe<Order_By>;
  AHC_LA32001_2?: Maybe<Order_By>;
  NC_Q1?: Maybe<Order_By>;
  NC_Q2?: Maybe<Order_By>;
  NC_Q4?: Maybe<Order_By>;
  NC_Q5?: Maybe<Order_By>;
  NC_Q6?: Maybe<Order_By>;
  NC_Q7?: Maybe<Order_By>;
  NC_Q8?: Maybe<Order_By>;
  NC_Q9?: Maybe<Order_By>;
  NC_Q10?: Maybe<Order_By>;
  P_63512_8?: Maybe<Order_By>;
  P_63586_2?: Maybe<Order_By>;
  P_67875_5?: Maybe<Order_By>;
  P_67875_5_OS?: Maybe<Order_By>;
  P_71802_3?: Maybe<Order_By>;
  P_76501_6?: Maybe<Order_By>;
  P_82589_3?: Maybe<Order_By>;
  P_93026_3?: Maybe<Order_By>;
  P_93029_7?: Maybe<Order_By>;
  P_93030_5?: Maybe<Order_By>;
  P_93031_3_O?: Maybe<Order_By>;
  P_93031_3_OS?: Maybe<Order_By>;
  P_93033_9?: Maybe<Order_By>;
  P_93034_7?: Maybe<Order_By>;
  P_93035_4?: Maybe<Order_By>;
  P_93038_8?: Maybe<Order_By>;
  P_LA30122_8_FAM?: Maybe<Order_By>;
  P_LA30122_8_INC?: Maybe<Order_By>;
  P_LA30122_8_NEEDS?: Maybe<Order_By>;
  P_LA30124_4?: Maybe<Order_By>;
  P_LA30125_1?: Maybe<Order_By>;
  P_LA30126_9?: Maybe<Order_By>;
  P_LA30127_7?: Maybe<Order_By>;
  P_LA30128_5?: Maybe<Order_By>;
  P_LA30129_3?: Maybe<Order_By>;
  assessment_declined?: Maybe<Order_By>;
  beneficiary_first_name?: Maybe<Order_By>;
  beneficiary_last_name?: Maybe<Order_By>;
  beneficiary_name?: Maybe<Order_By>;
  birth_date?: Maybe<Order_By>;
  channel?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  date_assessment_complete?: Maybe<Order_By>;
  ethnicity_base?: Maybe<Order_By>;
  ethnicity_expanded_1?: Maybe<Order_By>;
  ethnicity_expanded_2?: Maybe<Order_By>;
  ethnicity_expanded_3?: Maybe<Order_By>;
  ethnicity_expanded_4?: Maybe<Order_By>;
  form_language?: Maybe<Order_By>;
  form_practice_id?: Maybe<Order_By>;
  gender_identity?: Maybe<Order_By>;
  gender_identity_os?: Maybe<Order_By>;
  health_equity_report_id?: Maybe<Order_By>;
  letter_id?: Maybe<Order_By>;
  letter_id_prefix?: Maybe<Order_By>;
  matched_patient_id?: Maybe<Order_By>;
  mbi?: Maybe<Order_By>;
  network?: Maybe<Networks_Order_By>;
  network_number?: Maybe<Order_By>;
  ocr_output?: Maybe<Order_By>;
  onedrive_file_id?: Maybe<Order_By>;
  practice_id_prefix?: Maybe<Order_By>;
  preferred_language?: Maybe<Order_By>;
  processing_status?: Maybe<Order_By>;
  race?: Maybe<Order_By>;
  sex_assigned_at_birth?: Maybe<Order_By>;
  sex_orientation?: Maybe<Order_By>;
  sex_orientation_os?: Maybe<Order_By>;
  short_url?: Maybe<Order_By>;
  submission_id?: Maybe<Order_By>;
  submission_source?: Maybe<Order_By>;
  template_version?: Maybe<Order_By>;
  transcriber_notes?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: "health_equity_reports" */
export type Health_Equity_Reports_Pk_Columns_Input = {
  health_equity_report_id: Scalars['String'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Health_Equity_Reports_Prepend_Input = {
  ocr_output?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "health_equity_reports" */
export enum Health_Equity_Reports_Select_Column {
  /** column name */
  Ahc_71802_3 = 'AHC_71802_3',
  /** column name */
  Ahc_88122_7 = 'AHC_88122_7',
  /** column name */
  Ahc_88123_5 = 'AHC_88123_5',
  /** column name */
  Ahc_93030_5 = 'AHC_93030_5',
  /** column name */
  Ahc_95615_1 = 'AHC_95615_1',
  /** column name */
  Ahc_95616_9 = 'AHC_95616_9',
  /** column name */
  Ahc_95617_7 = 'AHC_95617_7',
  /** column name */
  Ahc_95618_5 = 'AHC_95618_5',
  /** column name */
  Ahc_96779_4 = 'AHC_96779_4',
  /** column name */
  AhcLa9_3 = 'AHC_LA9_3',
  /** column name */
  AhcLa28580_1 = 'AHC_LA28580_1',
  /** column name */
  AhcLa31996_4 = 'AHC_LA31996_4',
  /** column name */
  AhcLa31997_2 = 'AHC_LA31997_2',
  /** column name */
  AhcLa31998_0 = 'AHC_LA31998_0',
  /** column name */
  AhcLa31999_8 = 'AHC_LA31999_8',
  /** column name */
  AhcLa32000_4 = 'AHC_LA32000_4',
  /** column name */
  AhcLa32001_2 = 'AHC_LA32001_2',
  /** column name */
  NcQ1 = 'NC_Q1',
  /** column name */
  NcQ2 = 'NC_Q2',
  /** column name */
  NcQ4 = 'NC_Q4',
  /** column name */
  NcQ5 = 'NC_Q5',
  /** column name */
  NcQ6 = 'NC_Q6',
  /** column name */
  NcQ7 = 'NC_Q7',
  /** column name */
  NcQ8 = 'NC_Q8',
  /** column name */
  NcQ9 = 'NC_Q9',
  /** column name */
  NcQ10 = 'NC_Q10',
  /** column name */
  P_63512_8 = 'P_63512_8',
  /** column name */
  P_63586_2 = 'P_63586_2',
  /** column name */
  P_67875_5 = 'P_67875_5',
  /** column name */
  P_67875_5Os = 'P_67875_5_OS',
  /** column name */
  P_71802_3 = 'P_71802_3',
  /** column name */
  P_76501_6 = 'P_76501_6',
  /** column name */
  P_82589_3 = 'P_82589_3',
  /** column name */
  P_93026_3 = 'P_93026_3',
  /** column name */
  P_93029_7 = 'P_93029_7',
  /** column name */
  P_93030_5 = 'P_93030_5',
  /** column name */
  P_93031_3O = 'P_93031_3_O',
  /** column name */
  P_93031_3Os = 'P_93031_3_OS',
  /** column name */
  P_93033_9 = 'P_93033_9',
  /** column name */
  P_93034_7 = 'P_93034_7',
  /** column name */
  P_93035_4 = 'P_93035_4',
  /** column name */
  P_93038_8 = 'P_93038_8',
  /** column name */
  PLa30122_8Fam = 'P_LA30122_8_FAM',
  /** column name */
  PLa30122_8Inc = 'P_LA30122_8_INC',
  /** column name */
  PLa30122_8Needs = 'P_LA30122_8_NEEDS',
  /** column name */
  PLa30124_4 = 'P_LA30124_4',
  /** column name */
  PLa30125_1 = 'P_LA30125_1',
  /** column name */
  PLa30126_9 = 'P_LA30126_9',
  /** column name */
  PLa30127_7 = 'P_LA30127_7',
  /** column name */
  PLa30128_5 = 'P_LA30128_5',
  /** column name */
  PLa30129_3 = 'P_LA30129_3',
  /** column name */
  AssessmentDeclined = 'assessment_declined',
  /** column name */
  BeneficiaryFirstName = 'beneficiary_first_name',
  /** column name */
  BeneficiaryLastName = 'beneficiary_last_name',
  /** column name */
  BeneficiaryName = 'beneficiary_name',
  /** column name */
  BirthDate = 'birth_date',
  /** column name */
  Channel = 'channel',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DateAssessmentComplete = 'date_assessment_complete',
  /** column name */
  EthnicityBase = 'ethnicity_base',
  /** column name */
  EthnicityExpanded_1 = 'ethnicity_expanded_1',
  /** column name */
  EthnicityExpanded_2 = 'ethnicity_expanded_2',
  /** column name */
  EthnicityExpanded_3 = 'ethnicity_expanded_3',
  /** column name */
  EthnicityExpanded_4 = 'ethnicity_expanded_4',
  /** column name */
  FormLanguage = 'form_language',
  /** column name */
  FormPracticeId = 'form_practice_id',
  /** column name */
  GenderIdentity = 'gender_identity',
  /** column name */
  GenderIdentityOs = 'gender_identity_os',
  /** column name */
  HealthEquityReportId = 'health_equity_report_id',
  /** column name */
  LetterId = 'letter_id',
  /** column name */
  LetterIdPrefix = 'letter_id_prefix',
  /** column name */
  MatchedPatientId = 'matched_patient_id',
  /** column name */
  Mbi = 'mbi',
  /** column name */
  NetworkNumber = 'network_number',
  /** column name */
  OcrOutput = 'ocr_output',
  /** column name */
  OnedriveFileId = 'onedrive_file_id',
  /** column name */
  PracticeIdPrefix = 'practice_id_prefix',
  /** column name */
  PreferredLanguage = 'preferred_language',
  /** column name */
  ProcessingStatus = 'processing_status',
  /** column name */
  Race = 'race',
  /** column name */
  SexAssignedAtBirth = 'sex_assigned_at_birth',
  /** column name */
  SexOrientation = 'sex_orientation',
  /** column name */
  SexOrientationOs = 'sex_orientation_os',
  /** column name */
  ShortUrl = 'short_url',
  /** column name */
  SubmissionId = 'submission_id',
  /** column name */
  SubmissionSource = 'submission_source',
  /** column name */
  TemplateVersion = 'template_version',
  /** column name */
  TranscriberNotes = 'transcriber_notes',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "health_equity_reports" */
export type Health_Equity_Reports_Set_Input = {
  AHC_71802_3?: Maybe<Scalars['String']>;
  AHC_88122_7?: Maybe<Scalars['String']>;
  AHC_88123_5?: Maybe<Scalars['String']>;
  AHC_93030_5?: Maybe<Scalars['String']>;
  AHC_95615_1?: Maybe<Scalars['String']>;
  AHC_95616_9?: Maybe<Scalars['String']>;
  AHC_95617_7?: Maybe<Scalars['String']>;
  AHC_95618_5?: Maybe<Scalars['String']>;
  AHC_96779_4?: Maybe<Scalars['String']>;
  AHC_LA9_3?: Maybe<Scalars['String']>;
  AHC_LA28580_1?: Maybe<Scalars['String']>;
  AHC_LA31996_4?: Maybe<Scalars['String']>;
  AHC_LA31997_2?: Maybe<Scalars['String']>;
  AHC_LA31998_0?: Maybe<Scalars['String']>;
  AHC_LA31999_8?: Maybe<Scalars['String']>;
  AHC_LA32000_4?: Maybe<Scalars['String']>;
  AHC_LA32001_2?: Maybe<Scalars['String']>;
  NC_Q1?: Maybe<Scalars['String']>;
  NC_Q2?: Maybe<Scalars['String']>;
  NC_Q4?: Maybe<Scalars['String']>;
  NC_Q5?: Maybe<Scalars['String']>;
  NC_Q6?: Maybe<Scalars['String']>;
  NC_Q7?: Maybe<Scalars['String']>;
  NC_Q8?: Maybe<Scalars['String']>;
  NC_Q9?: Maybe<Scalars['String']>;
  NC_Q10?: Maybe<Scalars['String']>;
  P_63512_8?: Maybe<Scalars['smallint']>;
  P_63586_2?: Maybe<Scalars['Int']>;
  P_67875_5?: Maybe<Scalars['String']>;
  P_67875_5_OS?: Maybe<Scalars['String']>;
  P_71802_3?: Maybe<Scalars['String']>;
  P_76501_6?: Maybe<Scalars['String']>;
  P_82589_3?: Maybe<Scalars['String']>;
  P_93026_3?: Maybe<Scalars['String']>;
  P_93029_7?: Maybe<Scalars['String']>;
  P_93030_5?: Maybe<Scalars['String']>;
  P_93031_3_O?: Maybe<Scalars['String']>;
  P_93031_3_OS?: Maybe<Scalars['String']>;
  P_93033_9?: Maybe<Scalars['String']>;
  P_93034_7?: Maybe<Scalars['String']>;
  P_93035_4?: Maybe<Scalars['String']>;
  P_93038_8?: Maybe<Scalars['String']>;
  P_LA30122_8_FAM?: Maybe<Scalars['String']>;
  P_LA30122_8_INC?: Maybe<Scalars['String']>;
  P_LA30122_8_NEEDS?: Maybe<Scalars['String']>;
  P_LA30124_4?: Maybe<Scalars['String']>;
  P_LA30125_1?: Maybe<Scalars['String']>;
  P_LA30126_9?: Maybe<Scalars['String']>;
  P_LA30127_7?: Maybe<Scalars['String']>;
  P_LA30128_5?: Maybe<Scalars['String']>;
  P_LA30129_3?: Maybe<Scalars['String']>;
  assessment_declined?: Maybe<Scalars['String']>;
  beneficiary_first_name?: Maybe<Scalars['String']>;
  beneficiary_last_name?: Maybe<Scalars['String']>;
  beneficiary_name?: Maybe<Scalars['String']>;
  birth_date?: Maybe<Scalars['date']>;
  channel?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  date_assessment_complete?: Maybe<Scalars['date']>;
  ethnicity_base?: Maybe<Scalars['String']>;
  ethnicity_expanded_1?: Maybe<Scalars['String']>;
  ethnicity_expanded_2?: Maybe<Scalars['String']>;
  ethnicity_expanded_3?: Maybe<Scalars['String']>;
  ethnicity_expanded_4?: Maybe<Scalars['String']>;
  form_language?: Maybe<Scalars['String']>;
  form_practice_id?: Maybe<Scalars['String']>;
  gender_identity?: Maybe<Scalars['String']>;
  gender_identity_os?: Maybe<Scalars['String']>;
  health_equity_report_id?: Maybe<Scalars['String']>;
  letter_id?: Maybe<Scalars['String']>;
  letter_id_prefix?: Maybe<Scalars['String']>;
  matched_patient_id?: Maybe<Scalars['String']>;
  mbi?: Maybe<Scalars['String']>;
  network_number?: Maybe<Scalars['Int']>;
  ocr_output?: Maybe<Scalars['jsonb']>;
  onedrive_file_id?: Maybe<Scalars['String']>;
  practice_id_prefix?: Maybe<Scalars['String']>;
  preferred_language?: Maybe<Scalars['String']>;
  processing_status?: Maybe<Scalars['String']>;
  race?: Maybe<Scalars['String']>;
  sex_assigned_at_birth?: Maybe<Scalars['String']>;
  sex_orientation?: Maybe<Scalars['String']>;
  sex_orientation_os?: Maybe<Scalars['String']>;
  short_url?: Maybe<Scalars['String']>;
  submission_id?: Maybe<Scalars['String']>;
  submission_source?: Maybe<Scalars['String']>;
  template_version?: Maybe<Scalars['String']>;
  transcriber_notes?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Health_Equity_Reports_Stddev_Fields = {
  __typename?: 'health_equity_reports_stddev_fields';
  P_63512_8?: Maybe<Scalars['Float']>;
  P_63586_2?: Maybe<Scalars['Float']>;
  network_number?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "health_equity_reports" */
export type Health_Equity_Reports_Stddev_Order_By = {
  P_63512_8?: Maybe<Order_By>;
  P_63586_2?: Maybe<Order_By>;
  network_number?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Health_Equity_Reports_Stddev_Pop_Fields = {
  __typename?: 'health_equity_reports_stddev_pop_fields';
  P_63512_8?: Maybe<Scalars['Float']>;
  P_63586_2?: Maybe<Scalars['Float']>;
  network_number?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "health_equity_reports" */
export type Health_Equity_Reports_Stddev_Pop_Order_By = {
  P_63512_8?: Maybe<Order_By>;
  P_63586_2?: Maybe<Order_By>;
  network_number?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Health_Equity_Reports_Stddev_Samp_Fields = {
  __typename?: 'health_equity_reports_stddev_samp_fields';
  P_63512_8?: Maybe<Scalars['Float']>;
  P_63586_2?: Maybe<Scalars['Float']>;
  network_number?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "health_equity_reports" */
export type Health_Equity_Reports_Stddev_Samp_Order_By = {
  P_63512_8?: Maybe<Order_By>;
  P_63586_2?: Maybe<Order_By>;
  network_number?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Health_Equity_Reports_Sum_Fields = {
  __typename?: 'health_equity_reports_sum_fields';
  P_63512_8?: Maybe<Scalars['smallint']>;
  P_63586_2?: Maybe<Scalars['Int']>;
  network_number?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "health_equity_reports" */
export type Health_Equity_Reports_Sum_Order_By = {
  P_63512_8?: Maybe<Order_By>;
  P_63586_2?: Maybe<Order_By>;
  network_number?: Maybe<Order_By>;
};

/** update columns of table "health_equity_reports" */
export enum Health_Equity_Reports_Update_Column {
  /** column name */
  Ahc_71802_3 = 'AHC_71802_3',
  /** column name */
  Ahc_88122_7 = 'AHC_88122_7',
  /** column name */
  Ahc_88123_5 = 'AHC_88123_5',
  /** column name */
  Ahc_93030_5 = 'AHC_93030_5',
  /** column name */
  Ahc_95615_1 = 'AHC_95615_1',
  /** column name */
  Ahc_95616_9 = 'AHC_95616_9',
  /** column name */
  Ahc_95617_7 = 'AHC_95617_7',
  /** column name */
  Ahc_95618_5 = 'AHC_95618_5',
  /** column name */
  Ahc_96779_4 = 'AHC_96779_4',
  /** column name */
  AhcLa9_3 = 'AHC_LA9_3',
  /** column name */
  AhcLa28580_1 = 'AHC_LA28580_1',
  /** column name */
  AhcLa31996_4 = 'AHC_LA31996_4',
  /** column name */
  AhcLa31997_2 = 'AHC_LA31997_2',
  /** column name */
  AhcLa31998_0 = 'AHC_LA31998_0',
  /** column name */
  AhcLa31999_8 = 'AHC_LA31999_8',
  /** column name */
  AhcLa32000_4 = 'AHC_LA32000_4',
  /** column name */
  AhcLa32001_2 = 'AHC_LA32001_2',
  /** column name */
  NcQ1 = 'NC_Q1',
  /** column name */
  NcQ2 = 'NC_Q2',
  /** column name */
  NcQ4 = 'NC_Q4',
  /** column name */
  NcQ5 = 'NC_Q5',
  /** column name */
  NcQ6 = 'NC_Q6',
  /** column name */
  NcQ7 = 'NC_Q7',
  /** column name */
  NcQ8 = 'NC_Q8',
  /** column name */
  NcQ9 = 'NC_Q9',
  /** column name */
  NcQ10 = 'NC_Q10',
  /** column name */
  P_63512_8 = 'P_63512_8',
  /** column name */
  P_63586_2 = 'P_63586_2',
  /** column name */
  P_67875_5 = 'P_67875_5',
  /** column name */
  P_67875_5Os = 'P_67875_5_OS',
  /** column name */
  P_71802_3 = 'P_71802_3',
  /** column name */
  P_76501_6 = 'P_76501_6',
  /** column name */
  P_82589_3 = 'P_82589_3',
  /** column name */
  P_93026_3 = 'P_93026_3',
  /** column name */
  P_93029_7 = 'P_93029_7',
  /** column name */
  P_93030_5 = 'P_93030_5',
  /** column name */
  P_93031_3O = 'P_93031_3_O',
  /** column name */
  P_93031_3Os = 'P_93031_3_OS',
  /** column name */
  P_93033_9 = 'P_93033_9',
  /** column name */
  P_93034_7 = 'P_93034_7',
  /** column name */
  P_93035_4 = 'P_93035_4',
  /** column name */
  P_93038_8 = 'P_93038_8',
  /** column name */
  PLa30122_8Fam = 'P_LA30122_8_FAM',
  /** column name */
  PLa30122_8Inc = 'P_LA30122_8_INC',
  /** column name */
  PLa30122_8Needs = 'P_LA30122_8_NEEDS',
  /** column name */
  PLa30124_4 = 'P_LA30124_4',
  /** column name */
  PLa30125_1 = 'P_LA30125_1',
  /** column name */
  PLa30126_9 = 'P_LA30126_9',
  /** column name */
  PLa30127_7 = 'P_LA30127_7',
  /** column name */
  PLa30128_5 = 'P_LA30128_5',
  /** column name */
  PLa30129_3 = 'P_LA30129_3',
  /** column name */
  AssessmentDeclined = 'assessment_declined',
  /** column name */
  BeneficiaryFirstName = 'beneficiary_first_name',
  /** column name */
  BeneficiaryLastName = 'beneficiary_last_name',
  /** column name */
  BeneficiaryName = 'beneficiary_name',
  /** column name */
  BirthDate = 'birth_date',
  /** column name */
  Channel = 'channel',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DateAssessmentComplete = 'date_assessment_complete',
  /** column name */
  EthnicityBase = 'ethnicity_base',
  /** column name */
  EthnicityExpanded_1 = 'ethnicity_expanded_1',
  /** column name */
  EthnicityExpanded_2 = 'ethnicity_expanded_2',
  /** column name */
  EthnicityExpanded_3 = 'ethnicity_expanded_3',
  /** column name */
  EthnicityExpanded_4 = 'ethnicity_expanded_4',
  /** column name */
  FormLanguage = 'form_language',
  /** column name */
  FormPracticeId = 'form_practice_id',
  /** column name */
  GenderIdentity = 'gender_identity',
  /** column name */
  GenderIdentityOs = 'gender_identity_os',
  /** column name */
  HealthEquityReportId = 'health_equity_report_id',
  /** column name */
  LetterId = 'letter_id',
  /** column name */
  LetterIdPrefix = 'letter_id_prefix',
  /** column name */
  MatchedPatientId = 'matched_patient_id',
  /** column name */
  Mbi = 'mbi',
  /** column name */
  NetworkNumber = 'network_number',
  /** column name */
  OcrOutput = 'ocr_output',
  /** column name */
  OnedriveFileId = 'onedrive_file_id',
  /** column name */
  PracticeIdPrefix = 'practice_id_prefix',
  /** column name */
  PreferredLanguage = 'preferred_language',
  /** column name */
  ProcessingStatus = 'processing_status',
  /** column name */
  Race = 'race',
  /** column name */
  SexAssignedAtBirth = 'sex_assigned_at_birth',
  /** column name */
  SexOrientation = 'sex_orientation',
  /** column name */
  SexOrientationOs = 'sex_orientation_os',
  /** column name */
  ShortUrl = 'short_url',
  /** column name */
  SubmissionId = 'submission_id',
  /** column name */
  SubmissionSource = 'submission_source',
  /** column name */
  TemplateVersion = 'template_version',
  /** column name */
  TranscriberNotes = 'transcriber_notes',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Health_Equity_Reports_Var_Pop_Fields = {
  __typename?: 'health_equity_reports_var_pop_fields';
  P_63512_8?: Maybe<Scalars['Float']>;
  P_63586_2?: Maybe<Scalars['Float']>;
  network_number?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "health_equity_reports" */
export type Health_Equity_Reports_Var_Pop_Order_By = {
  P_63512_8?: Maybe<Order_By>;
  P_63586_2?: Maybe<Order_By>;
  network_number?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Health_Equity_Reports_Var_Samp_Fields = {
  __typename?: 'health_equity_reports_var_samp_fields';
  P_63512_8?: Maybe<Scalars['Float']>;
  P_63586_2?: Maybe<Scalars['Float']>;
  network_number?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "health_equity_reports" */
export type Health_Equity_Reports_Var_Samp_Order_By = {
  P_63512_8?: Maybe<Order_By>;
  P_63586_2?: Maybe<Order_By>;
  network_number?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Health_Equity_Reports_Variance_Fields = {
  __typename?: 'health_equity_reports_variance_fields';
  P_63512_8?: Maybe<Scalars['Float']>;
  P_63586_2?: Maybe<Scalars['Float']>;
  network_number?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "health_equity_reports" */
export type Health_Equity_Reports_Variance_Order_By = {
  P_63512_8?: Maybe<Order_By>;
  P_63586_2?: Maybe<Order_By>;
  network_number?: Maybe<Order_By>;
};

/** columns and relationships of "hedr_submission_templates" */
export type Hedr_Submission_Templates = {
  __typename?: 'hedr_submission_templates';
  created_at: Scalars['timestamptz'];
  date_of_birth: Scalars['date'];
  entity_id: Scalars['String'];
  /** An object relationship */
  file_upload?: Maybe<File_Uploads>;
  file_upload_id?: Maybe<Scalars['String']>;
  first_name: Scalars['String'];
  last_name: Scalars['String'];
  mbi: Scalars['String'];
  model_id: Scalars['String'];
  /** An array relationship */
  networks: Array<Networks>;
  /** An aggregated array relationship */
  networks_aggregate: Networks_Aggregate;
  performance_year: Scalars['Int'];
  row_number: Scalars['Int'];
  sex_assigned_at_birth: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "hedr_submission_templates" */
export type Hedr_Submission_TemplatesNetworksArgs = {
  distinct_on?: Maybe<Array<Networks_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Networks_Order_By>>;
  where?: Maybe<Networks_Bool_Exp>;
};


/** columns and relationships of "hedr_submission_templates" */
export type Hedr_Submission_TemplatesNetworks_AggregateArgs = {
  distinct_on?: Maybe<Array<Networks_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Networks_Order_By>>;
  where?: Maybe<Networks_Bool_Exp>;
};

/** aggregated selection of "hedr_submission_templates" */
export type Hedr_Submission_Templates_Aggregate = {
  __typename?: 'hedr_submission_templates_aggregate';
  aggregate?: Maybe<Hedr_Submission_Templates_Aggregate_Fields>;
  nodes: Array<Hedr_Submission_Templates>;
};

/** aggregate fields of "hedr_submission_templates" */
export type Hedr_Submission_Templates_Aggregate_Fields = {
  __typename?: 'hedr_submission_templates_aggregate_fields';
  avg?: Maybe<Hedr_Submission_Templates_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Hedr_Submission_Templates_Max_Fields>;
  min?: Maybe<Hedr_Submission_Templates_Min_Fields>;
  stddev?: Maybe<Hedr_Submission_Templates_Stddev_Fields>;
  stddev_pop?: Maybe<Hedr_Submission_Templates_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Hedr_Submission_Templates_Stddev_Samp_Fields>;
  sum?: Maybe<Hedr_Submission_Templates_Sum_Fields>;
  var_pop?: Maybe<Hedr_Submission_Templates_Var_Pop_Fields>;
  var_samp?: Maybe<Hedr_Submission_Templates_Var_Samp_Fields>;
  variance?: Maybe<Hedr_Submission_Templates_Variance_Fields>;
};


/** aggregate fields of "hedr_submission_templates" */
export type Hedr_Submission_Templates_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Hedr_Submission_Templates_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "hedr_submission_templates" */
export type Hedr_Submission_Templates_Aggregate_Order_By = {
  avg?: Maybe<Hedr_Submission_Templates_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Hedr_Submission_Templates_Max_Order_By>;
  min?: Maybe<Hedr_Submission_Templates_Min_Order_By>;
  stddev?: Maybe<Hedr_Submission_Templates_Stddev_Order_By>;
  stddev_pop?: Maybe<Hedr_Submission_Templates_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Hedr_Submission_Templates_Stddev_Samp_Order_By>;
  sum?: Maybe<Hedr_Submission_Templates_Sum_Order_By>;
  var_pop?: Maybe<Hedr_Submission_Templates_Var_Pop_Order_By>;
  var_samp?: Maybe<Hedr_Submission_Templates_Var_Samp_Order_By>;
  variance?: Maybe<Hedr_Submission_Templates_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "hedr_submission_templates" */
export type Hedr_Submission_Templates_Arr_Rel_Insert_Input = {
  data: Array<Hedr_Submission_Templates_Insert_Input>;
  on_conflict?: Maybe<Hedr_Submission_Templates_On_Conflict>;
};

/** aggregate avg on columns */
export type Hedr_Submission_Templates_Avg_Fields = {
  __typename?: 'hedr_submission_templates_avg_fields';
  performance_year?: Maybe<Scalars['Float']>;
  row_number?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "hedr_submission_templates" */
export type Hedr_Submission_Templates_Avg_Order_By = {
  performance_year?: Maybe<Order_By>;
  row_number?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "hedr_submission_templates". All fields are combined with a logical 'AND'. */
export type Hedr_Submission_Templates_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Hedr_Submission_Templates_Bool_Exp>>>;
  _not?: Maybe<Hedr_Submission_Templates_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Hedr_Submission_Templates_Bool_Exp>>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  date_of_birth?: Maybe<Date_Comparison_Exp>;
  entity_id?: Maybe<String_Comparison_Exp>;
  file_upload?: Maybe<File_Uploads_Bool_Exp>;
  file_upload_id?: Maybe<String_Comparison_Exp>;
  first_name?: Maybe<String_Comparison_Exp>;
  last_name?: Maybe<String_Comparison_Exp>;
  mbi?: Maybe<String_Comparison_Exp>;
  model_id?: Maybe<String_Comparison_Exp>;
  networks?: Maybe<Networks_Bool_Exp>;
  performance_year?: Maybe<Int_Comparison_Exp>;
  row_number?: Maybe<Int_Comparison_Exp>;
  sex_assigned_at_birth?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "hedr_submission_templates" */
export enum Hedr_Submission_Templates_Constraint {
  /** unique or primary key constraint */
  HedrSubmissionTemplatesPkey1 = 'hedr_submission_templates_pkey1'
}

/** input type for incrementing integer column in table "hedr_submission_templates" */
export type Hedr_Submission_Templates_Inc_Input = {
  performance_year?: Maybe<Scalars['Int']>;
  row_number?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "hedr_submission_templates" */
export type Hedr_Submission_Templates_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  date_of_birth?: Maybe<Scalars['date']>;
  entity_id?: Maybe<Scalars['String']>;
  file_upload?: Maybe<File_Uploads_Obj_Rel_Insert_Input>;
  file_upload_id?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  mbi?: Maybe<Scalars['String']>;
  model_id?: Maybe<Scalars['String']>;
  networks?: Maybe<Networks_Arr_Rel_Insert_Input>;
  performance_year?: Maybe<Scalars['Int']>;
  row_number?: Maybe<Scalars['Int']>;
  sex_assigned_at_birth?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Hedr_Submission_Templates_Max_Fields = {
  __typename?: 'hedr_submission_templates_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  date_of_birth?: Maybe<Scalars['date']>;
  entity_id?: Maybe<Scalars['String']>;
  file_upload_id?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  mbi?: Maybe<Scalars['String']>;
  model_id?: Maybe<Scalars['String']>;
  performance_year?: Maybe<Scalars['Int']>;
  row_number?: Maybe<Scalars['Int']>;
  sex_assigned_at_birth?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "hedr_submission_templates" */
export type Hedr_Submission_Templates_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  date_of_birth?: Maybe<Order_By>;
  entity_id?: Maybe<Order_By>;
  file_upload_id?: Maybe<Order_By>;
  first_name?: Maybe<Order_By>;
  last_name?: Maybe<Order_By>;
  mbi?: Maybe<Order_By>;
  model_id?: Maybe<Order_By>;
  performance_year?: Maybe<Order_By>;
  row_number?: Maybe<Order_By>;
  sex_assigned_at_birth?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Hedr_Submission_Templates_Min_Fields = {
  __typename?: 'hedr_submission_templates_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  date_of_birth?: Maybe<Scalars['date']>;
  entity_id?: Maybe<Scalars['String']>;
  file_upload_id?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  mbi?: Maybe<Scalars['String']>;
  model_id?: Maybe<Scalars['String']>;
  performance_year?: Maybe<Scalars['Int']>;
  row_number?: Maybe<Scalars['Int']>;
  sex_assigned_at_birth?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "hedr_submission_templates" */
export type Hedr_Submission_Templates_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  date_of_birth?: Maybe<Order_By>;
  entity_id?: Maybe<Order_By>;
  file_upload_id?: Maybe<Order_By>;
  first_name?: Maybe<Order_By>;
  last_name?: Maybe<Order_By>;
  mbi?: Maybe<Order_By>;
  model_id?: Maybe<Order_By>;
  performance_year?: Maybe<Order_By>;
  row_number?: Maybe<Order_By>;
  sex_assigned_at_birth?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "hedr_submission_templates" */
export type Hedr_Submission_Templates_Mutation_Response = {
  __typename?: 'hedr_submission_templates_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Hedr_Submission_Templates>;
};

/** input type for inserting object relation for remote table "hedr_submission_templates" */
export type Hedr_Submission_Templates_Obj_Rel_Insert_Input = {
  data: Hedr_Submission_Templates_Insert_Input;
  on_conflict?: Maybe<Hedr_Submission_Templates_On_Conflict>;
};

/** on conflict condition type for table "hedr_submission_templates" */
export type Hedr_Submission_Templates_On_Conflict = {
  constraint: Hedr_Submission_Templates_Constraint;
  update_columns: Array<Hedr_Submission_Templates_Update_Column>;
  where?: Maybe<Hedr_Submission_Templates_Bool_Exp>;
};

/** ordering options when selecting data from "hedr_submission_templates" */
export type Hedr_Submission_Templates_Order_By = {
  created_at?: Maybe<Order_By>;
  date_of_birth?: Maybe<Order_By>;
  entity_id?: Maybe<Order_By>;
  file_upload?: Maybe<File_Uploads_Order_By>;
  file_upload_id?: Maybe<Order_By>;
  first_name?: Maybe<Order_By>;
  last_name?: Maybe<Order_By>;
  mbi?: Maybe<Order_By>;
  model_id?: Maybe<Order_By>;
  networks_aggregate?: Maybe<Networks_Aggregate_Order_By>;
  performance_year?: Maybe<Order_By>;
  row_number?: Maybe<Order_By>;
  sex_assigned_at_birth?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: "hedr_submission_templates" */
export type Hedr_Submission_Templates_Pk_Columns_Input = {
  entity_id: Scalars['String'];
  mbi: Scalars['String'];
  performance_year: Scalars['Int'];
};

/** select columns of table "hedr_submission_templates" */
export enum Hedr_Submission_Templates_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DateOfBirth = 'date_of_birth',
  /** column name */
  EntityId = 'entity_id',
  /** column name */
  FileUploadId = 'file_upload_id',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  LastName = 'last_name',
  /** column name */
  Mbi = 'mbi',
  /** column name */
  ModelId = 'model_id',
  /** column name */
  PerformanceYear = 'performance_year',
  /** column name */
  RowNumber = 'row_number',
  /** column name */
  SexAssignedAtBirth = 'sex_assigned_at_birth',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "hedr_submission_templates" */
export type Hedr_Submission_Templates_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  date_of_birth?: Maybe<Scalars['date']>;
  entity_id?: Maybe<Scalars['String']>;
  file_upload_id?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  mbi?: Maybe<Scalars['String']>;
  model_id?: Maybe<Scalars['String']>;
  performance_year?: Maybe<Scalars['Int']>;
  row_number?: Maybe<Scalars['Int']>;
  sex_assigned_at_birth?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Hedr_Submission_Templates_Stddev_Fields = {
  __typename?: 'hedr_submission_templates_stddev_fields';
  performance_year?: Maybe<Scalars['Float']>;
  row_number?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "hedr_submission_templates" */
export type Hedr_Submission_Templates_Stddev_Order_By = {
  performance_year?: Maybe<Order_By>;
  row_number?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Hedr_Submission_Templates_Stddev_Pop_Fields = {
  __typename?: 'hedr_submission_templates_stddev_pop_fields';
  performance_year?: Maybe<Scalars['Float']>;
  row_number?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "hedr_submission_templates" */
export type Hedr_Submission_Templates_Stddev_Pop_Order_By = {
  performance_year?: Maybe<Order_By>;
  row_number?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Hedr_Submission_Templates_Stddev_Samp_Fields = {
  __typename?: 'hedr_submission_templates_stddev_samp_fields';
  performance_year?: Maybe<Scalars['Float']>;
  row_number?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "hedr_submission_templates" */
export type Hedr_Submission_Templates_Stddev_Samp_Order_By = {
  performance_year?: Maybe<Order_By>;
  row_number?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Hedr_Submission_Templates_Sum_Fields = {
  __typename?: 'hedr_submission_templates_sum_fields';
  performance_year?: Maybe<Scalars['Int']>;
  row_number?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "hedr_submission_templates" */
export type Hedr_Submission_Templates_Sum_Order_By = {
  performance_year?: Maybe<Order_By>;
  row_number?: Maybe<Order_By>;
};

/** update columns of table "hedr_submission_templates" */
export enum Hedr_Submission_Templates_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DateOfBirth = 'date_of_birth',
  /** column name */
  EntityId = 'entity_id',
  /** column name */
  FileUploadId = 'file_upload_id',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  LastName = 'last_name',
  /** column name */
  Mbi = 'mbi',
  /** column name */
  ModelId = 'model_id',
  /** column name */
  PerformanceYear = 'performance_year',
  /** column name */
  RowNumber = 'row_number',
  /** column name */
  SexAssignedAtBirth = 'sex_assigned_at_birth',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Hedr_Submission_Templates_Var_Pop_Fields = {
  __typename?: 'hedr_submission_templates_var_pop_fields';
  performance_year?: Maybe<Scalars['Float']>;
  row_number?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "hedr_submission_templates" */
export type Hedr_Submission_Templates_Var_Pop_Order_By = {
  performance_year?: Maybe<Order_By>;
  row_number?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Hedr_Submission_Templates_Var_Samp_Fields = {
  __typename?: 'hedr_submission_templates_var_samp_fields';
  performance_year?: Maybe<Scalars['Float']>;
  row_number?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "hedr_submission_templates" */
export type Hedr_Submission_Templates_Var_Samp_Order_By = {
  performance_year?: Maybe<Order_By>;
  row_number?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Hedr_Submission_Templates_Variance_Fields = {
  __typename?: 'hedr_submission_templates_variance_fields';
  performance_year?: Maybe<Scalars['Float']>;
  row_number?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "hedr_submission_templates" */
export type Hedr_Submission_Templates_Variance_Order_By = {
  performance_year?: Maybe<Order_By>;
  row_number?: Maybe<Order_By>;
};


/** expression to compare columns of type inet. All fields are combined with logical 'AND'. */
export type Inet_Comparison_Exp = {
  _eq?: Maybe<Scalars['inet']>;
  _gt?: Maybe<Scalars['inet']>;
  _gte?: Maybe<Scalars['inet']>;
  _in?: Maybe<Array<Scalars['inet']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['inet']>;
  _lte?: Maybe<Scalars['inet']>;
  _neq?: Maybe<Scalars['inet']>;
  _nin?: Maybe<Array<Scalars['inet']>>;
};

/** columns and relationships of "insurance_counts" */
export type Insurance_Counts = {
  __typename?: 'insurance_counts';
  count?: Maybe<Scalars['bigint']>;
  /** An object relationship */
  insurance_segment_mapping?: Maybe<Insurance_Segment_Mappings>;
  primary_insurance_is_null?: Maybe<Scalars['Boolean']>;
  primary_insurance_payer_name?: Maybe<Scalars['String']>;
  secondary_insurance_is_null?: Maybe<Scalars['Boolean']>;
  secondary_insurance_payer_name?: Maybe<Scalars['String']>;
};

/** aggregated selection of "insurance_counts" */
export type Insurance_Counts_Aggregate = {
  __typename?: 'insurance_counts_aggregate';
  aggregate?: Maybe<Insurance_Counts_Aggregate_Fields>;
  nodes: Array<Insurance_Counts>;
};

/** aggregate fields of "insurance_counts" */
export type Insurance_Counts_Aggregate_Fields = {
  __typename?: 'insurance_counts_aggregate_fields';
  avg?: Maybe<Insurance_Counts_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Insurance_Counts_Max_Fields>;
  min?: Maybe<Insurance_Counts_Min_Fields>;
  stddev?: Maybe<Insurance_Counts_Stddev_Fields>;
  stddev_pop?: Maybe<Insurance_Counts_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Insurance_Counts_Stddev_Samp_Fields>;
  sum?: Maybe<Insurance_Counts_Sum_Fields>;
  var_pop?: Maybe<Insurance_Counts_Var_Pop_Fields>;
  var_samp?: Maybe<Insurance_Counts_Var_Samp_Fields>;
  variance?: Maybe<Insurance_Counts_Variance_Fields>;
};


/** aggregate fields of "insurance_counts" */
export type Insurance_Counts_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Insurance_Counts_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "insurance_counts" */
export type Insurance_Counts_Aggregate_Order_By = {
  avg?: Maybe<Insurance_Counts_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Insurance_Counts_Max_Order_By>;
  min?: Maybe<Insurance_Counts_Min_Order_By>;
  stddev?: Maybe<Insurance_Counts_Stddev_Order_By>;
  stddev_pop?: Maybe<Insurance_Counts_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Insurance_Counts_Stddev_Samp_Order_By>;
  sum?: Maybe<Insurance_Counts_Sum_Order_By>;
  var_pop?: Maybe<Insurance_Counts_Var_Pop_Order_By>;
  var_samp?: Maybe<Insurance_Counts_Var_Samp_Order_By>;
  variance?: Maybe<Insurance_Counts_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Insurance_Counts_Avg_Fields = {
  __typename?: 'insurance_counts_avg_fields';
  count?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "insurance_counts" */
export type Insurance_Counts_Avg_Order_By = {
  count?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "insurance_counts". All fields are combined with a logical 'AND'. */
export type Insurance_Counts_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Insurance_Counts_Bool_Exp>>>;
  _not?: Maybe<Insurance_Counts_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Insurance_Counts_Bool_Exp>>>;
  count?: Maybe<Bigint_Comparison_Exp>;
  insurance_segment_mapping?: Maybe<Insurance_Segment_Mappings_Bool_Exp>;
  primary_insurance_is_null?: Maybe<Boolean_Comparison_Exp>;
  primary_insurance_payer_name?: Maybe<String_Comparison_Exp>;
  secondary_insurance_is_null?: Maybe<Boolean_Comparison_Exp>;
  secondary_insurance_payer_name?: Maybe<String_Comparison_Exp>;
};

/** aggregate max on columns */
export type Insurance_Counts_Max_Fields = {
  __typename?: 'insurance_counts_max_fields';
  count?: Maybe<Scalars['bigint']>;
  primary_insurance_payer_name?: Maybe<Scalars['String']>;
  secondary_insurance_payer_name?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "insurance_counts" */
export type Insurance_Counts_Max_Order_By = {
  count?: Maybe<Order_By>;
  primary_insurance_payer_name?: Maybe<Order_By>;
  secondary_insurance_payer_name?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Insurance_Counts_Min_Fields = {
  __typename?: 'insurance_counts_min_fields';
  count?: Maybe<Scalars['bigint']>;
  primary_insurance_payer_name?: Maybe<Scalars['String']>;
  secondary_insurance_payer_name?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "insurance_counts" */
export type Insurance_Counts_Min_Order_By = {
  count?: Maybe<Order_By>;
  primary_insurance_payer_name?: Maybe<Order_By>;
  secondary_insurance_payer_name?: Maybe<Order_By>;
};

/** ordering options when selecting data from "insurance_counts" */
export type Insurance_Counts_Order_By = {
  count?: Maybe<Order_By>;
  insurance_segment_mapping?: Maybe<Insurance_Segment_Mappings_Order_By>;
  primary_insurance_is_null?: Maybe<Order_By>;
  primary_insurance_payer_name?: Maybe<Order_By>;
  secondary_insurance_is_null?: Maybe<Order_By>;
  secondary_insurance_payer_name?: Maybe<Order_By>;
};

/** select columns of table "insurance_counts" */
export enum Insurance_Counts_Select_Column {
  /** column name */
  Count = 'count',
  /** column name */
  PrimaryInsuranceIsNull = 'primary_insurance_is_null',
  /** column name */
  PrimaryInsurancePayerName = 'primary_insurance_payer_name',
  /** column name */
  SecondaryInsuranceIsNull = 'secondary_insurance_is_null',
  /** column name */
  SecondaryInsurancePayerName = 'secondary_insurance_payer_name'
}

/** aggregate stddev on columns */
export type Insurance_Counts_Stddev_Fields = {
  __typename?: 'insurance_counts_stddev_fields';
  count?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "insurance_counts" */
export type Insurance_Counts_Stddev_Order_By = {
  count?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Insurance_Counts_Stddev_Pop_Fields = {
  __typename?: 'insurance_counts_stddev_pop_fields';
  count?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "insurance_counts" */
export type Insurance_Counts_Stddev_Pop_Order_By = {
  count?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Insurance_Counts_Stddev_Samp_Fields = {
  __typename?: 'insurance_counts_stddev_samp_fields';
  count?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "insurance_counts" */
export type Insurance_Counts_Stddev_Samp_Order_By = {
  count?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Insurance_Counts_Sum_Fields = {
  __typename?: 'insurance_counts_sum_fields';
  count?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "insurance_counts" */
export type Insurance_Counts_Sum_Order_By = {
  count?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Insurance_Counts_Var_Pop_Fields = {
  __typename?: 'insurance_counts_var_pop_fields';
  count?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "insurance_counts" */
export type Insurance_Counts_Var_Pop_Order_By = {
  count?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Insurance_Counts_Var_Samp_Fields = {
  __typename?: 'insurance_counts_var_samp_fields';
  count?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "insurance_counts" */
export type Insurance_Counts_Var_Samp_Order_By = {
  count?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Insurance_Counts_Variance_Fields = {
  __typename?: 'insurance_counts_variance_fields';
  count?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "insurance_counts" */
export type Insurance_Counts_Variance_Order_By = {
  count?: Maybe<Order_By>;
};

/** columns and relationships of "insurance_segment_mappings" */
export type Insurance_Segment_Mappings = {
  __typename?: 'insurance_segment_mappings';
  /** An object relationship */
  patient_segment?: Maybe<Patient_Segments>;
  patient_segment_id: Scalars['String'];
  primary_insurance_is_null: Scalars['Boolean'];
  primary_insurance_payer_name: Scalars['String'];
  secondary_insurance_is_null: Scalars['Boolean'];
  secondary_insurance_payer_name: Scalars['String'];
};

/** aggregated selection of "insurance_segment_mappings" */
export type Insurance_Segment_Mappings_Aggregate = {
  __typename?: 'insurance_segment_mappings_aggregate';
  aggregate?: Maybe<Insurance_Segment_Mappings_Aggregate_Fields>;
  nodes: Array<Insurance_Segment_Mappings>;
};

/** aggregate fields of "insurance_segment_mappings" */
export type Insurance_Segment_Mappings_Aggregate_Fields = {
  __typename?: 'insurance_segment_mappings_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Insurance_Segment_Mappings_Max_Fields>;
  min?: Maybe<Insurance_Segment_Mappings_Min_Fields>;
};


/** aggregate fields of "insurance_segment_mappings" */
export type Insurance_Segment_Mappings_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Insurance_Segment_Mappings_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "insurance_segment_mappings" */
export type Insurance_Segment_Mappings_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Insurance_Segment_Mappings_Max_Order_By>;
  min?: Maybe<Insurance_Segment_Mappings_Min_Order_By>;
};

/** input type for inserting array relation for remote table "insurance_segment_mappings" */
export type Insurance_Segment_Mappings_Arr_Rel_Insert_Input = {
  data: Array<Insurance_Segment_Mappings_Insert_Input>;
  on_conflict?: Maybe<Insurance_Segment_Mappings_On_Conflict>;
};

/** Boolean expression to filter rows from the table "insurance_segment_mappings". All fields are combined with a logical 'AND'. */
export type Insurance_Segment_Mappings_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Insurance_Segment_Mappings_Bool_Exp>>>;
  _not?: Maybe<Insurance_Segment_Mappings_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Insurance_Segment_Mappings_Bool_Exp>>>;
  patient_segment?: Maybe<Patient_Segments_Bool_Exp>;
  patient_segment_id?: Maybe<String_Comparison_Exp>;
  primary_insurance_is_null?: Maybe<Boolean_Comparison_Exp>;
  primary_insurance_payer_name?: Maybe<String_Comparison_Exp>;
  secondary_insurance_is_null?: Maybe<Boolean_Comparison_Exp>;
  secondary_insurance_payer_name?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "insurance_segment_mappings" */
export enum Insurance_Segment_Mappings_Constraint {
  /** unique or primary key constraint */
  InsuranceSegmentMappingsPkey = 'insurance_segment_mappings_pkey'
}

/** input type for inserting data into table "insurance_segment_mappings" */
export type Insurance_Segment_Mappings_Insert_Input = {
  patient_segment?: Maybe<Patient_Segments_Obj_Rel_Insert_Input>;
  patient_segment_id?: Maybe<Scalars['String']>;
  primary_insurance_is_null?: Maybe<Scalars['Boolean']>;
  primary_insurance_payer_name?: Maybe<Scalars['String']>;
  secondary_insurance_is_null?: Maybe<Scalars['Boolean']>;
  secondary_insurance_payer_name?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Insurance_Segment_Mappings_Max_Fields = {
  __typename?: 'insurance_segment_mappings_max_fields';
  patient_segment_id?: Maybe<Scalars['String']>;
  primary_insurance_payer_name?: Maybe<Scalars['String']>;
  secondary_insurance_payer_name?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "insurance_segment_mappings" */
export type Insurance_Segment_Mappings_Max_Order_By = {
  patient_segment_id?: Maybe<Order_By>;
  primary_insurance_payer_name?: Maybe<Order_By>;
  secondary_insurance_payer_name?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Insurance_Segment_Mappings_Min_Fields = {
  __typename?: 'insurance_segment_mappings_min_fields';
  patient_segment_id?: Maybe<Scalars['String']>;
  primary_insurance_payer_name?: Maybe<Scalars['String']>;
  secondary_insurance_payer_name?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "insurance_segment_mappings" */
export type Insurance_Segment_Mappings_Min_Order_By = {
  patient_segment_id?: Maybe<Order_By>;
  primary_insurance_payer_name?: Maybe<Order_By>;
  secondary_insurance_payer_name?: Maybe<Order_By>;
};

/** response of any mutation on the table "insurance_segment_mappings" */
export type Insurance_Segment_Mappings_Mutation_Response = {
  __typename?: 'insurance_segment_mappings_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Insurance_Segment_Mappings>;
};

/** input type for inserting object relation for remote table "insurance_segment_mappings" */
export type Insurance_Segment_Mappings_Obj_Rel_Insert_Input = {
  data: Insurance_Segment_Mappings_Insert_Input;
  on_conflict?: Maybe<Insurance_Segment_Mappings_On_Conflict>;
};

/** on conflict condition type for table "insurance_segment_mappings" */
export type Insurance_Segment_Mappings_On_Conflict = {
  constraint: Insurance_Segment_Mappings_Constraint;
  update_columns: Array<Insurance_Segment_Mappings_Update_Column>;
  where?: Maybe<Insurance_Segment_Mappings_Bool_Exp>;
};

/** ordering options when selecting data from "insurance_segment_mappings" */
export type Insurance_Segment_Mappings_Order_By = {
  patient_segment?: Maybe<Patient_Segments_Order_By>;
  patient_segment_id?: Maybe<Order_By>;
  primary_insurance_is_null?: Maybe<Order_By>;
  primary_insurance_payer_name?: Maybe<Order_By>;
  secondary_insurance_is_null?: Maybe<Order_By>;
  secondary_insurance_payer_name?: Maybe<Order_By>;
};

/** primary key columns input for table: "insurance_segment_mappings" */
export type Insurance_Segment_Mappings_Pk_Columns_Input = {
  primary_insurance_is_null: Scalars['Boolean'];
  primary_insurance_payer_name: Scalars['String'];
  secondary_insurance_is_null: Scalars['Boolean'];
  secondary_insurance_payer_name: Scalars['String'];
};

/** select columns of table "insurance_segment_mappings" */
export enum Insurance_Segment_Mappings_Select_Column {
  /** column name */
  PatientSegmentId = 'patient_segment_id',
  /** column name */
  PrimaryInsuranceIsNull = 'primary_insurance_is_null',
  /** column name */
  PrimaryInsurancePayerName = 'primary_insurance_payer_name',
  /** column name */
  SecondaryInsuranceIsNull = 'secondary_insurance_is_null',
  /** column name */
  SecondaryInsurancePayerName = 'secondary_insurance_payer_name'
}

/** input type for updating data in table "insurance_segment_mappings" */
export type Insurance_Segment_Mappings_Set_Input = {
  patient_segment_id?: Maybe<Scalars['String']>;
  primary_insurance_is_null?: Maybe<Scalars['Boolean']>;
  primary_insurance_payer_name?: Maybe<Scalars['String']>;
  secondary_insurance_is_null?: Maybe<Scalars['Boolean']>;
  secondary_insurance_payer_name?: Maybe<Scalars['String']>;
};

/** update columns of table "insurance_segment_mappings" */
export enum Insurance_Segment_Mappings_Update_Column {
  /** column name */
  PatientSegmentId = 'patient_segment_id',
  /** column name */
  PrimaryInsuranceIsNull = 'primary_insurance_is_null',
  /** column name */
  PrimaryInsurancePayerName = 'primary_insurance_payer_name',
  /** column name */
  SecondaryInsuranceIsNull = 'secondary_insurance_is_null',
  /** column name */
  SecondaryInsurancePayerName = 'secondary_insurance_payer_name'
}


/** expression to compare columns of type json. All fields are combined with logical 'AND'. */
export type Json_Comparison_Exp = {
  _eq?: Maybe<Scalars['json']>;
  _gt?: Maybe<Scalars['json']>;
  _gte?: Maybe<Scalars['json']>;
  _in?: Maybe<Array<Scalars['json']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['json']>;
  _lte?: Maybe<Scalars['json']>;
  _neq?: Maybe<Scalars['json']>;
  _nin?: Maybe<Array<Scalars['json']>>;
};


/** expression to compare columns of type jsonb. All fields are combined with logical 'AND'. */
export type Jsonb_Comparison_Exp = {
  /** is the column contained in the given json value */
  _contained_in?: Maybe<Scalars['jsonb']>;
  /** does the column contain the given json value at the top level */
  _contains?: Maybe<Scalars['jsonb']>;
  _eq?: Maybe<Scalars['jsonb']>;
  _gt?: Maybe<Scalars['jsonb']>;
  _gte?: Maybe<Scalars['jsonb']>;
  /** does the string exist as a top-level key in the column */
  _has_key?: Maybe<Scalars['String']>;
  /** do all of these strings exist as top-level keys in the column */
  _has_keys_all?: Maybe<Array<Scalars['String']>>;
  /** do any of these strings exist as top-level keys in the column */
  _has_keys_any?: Maybe<Array<Scalars['String']>>;
  _in?: Maybe<Array<Scalars['jsonb']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['jsonb']>;
  _lte?: Maybe<Scalars['jsonb']>;
  _neq?: Maybe<Scalars['jsonb']>;
  _nin?: Maybe<Array<Scalars['jsonb']>>;
};

/** columns and relationships of "letter_component_approvals" */
export type Letter_Component_Approvals = {
  __typename?: 'letter_component_approvals';
  /** An object relationship */
  campaign_approval?: Maybe<Campaign_Approvals>;
  /** An object relationship */
  campaign_letter_component: Campaign_Letter_Components;
  campaign_letter_component_id: Scalars['String'];
  campaign_template_id: Scalars['String'];
  created_at: Scalars['timestamptz'];
  is_cancelled: Scalars['Boolean'];
  practice_id: Scalars['String'];
  /** An object relationship */
  scheduled_event?: Maybe<Scheduled_Events>;
  scheduled_event_id?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "letter_component_approvals" */
export type Letter_Component_Approvals_Aggregate = {
  __typename?: 'letter_component_approvals_aggregate';
  aggregate?: Maybe<Letter_Component_Approvals_Aggregate_Fields>;
  nodes: Array<Letter_Component_Approvals>;
};

/** aggregate fields of "letter_component_approvals" */
export type Letter_Component_Approvals_Aggregate_Fields = {
  __typename?: 'letter_component_approvals_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Letter_Component_Approvals_Max_Fields>;
  min?: Maybe<Letter_Component_Approvals_Min_Fields>;
};


/** aggregate fields of "letter_component_approvals" */
export type Letter_Component_Approvals_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Letter_Component_Approvals_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "letter_component_approvals" */
export type Letter_Component_Approvals_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Letter_Component_Approvals_Max_Order_By>;
  min?: Maybe<Letter_Component_Approvals_Min_Order_By>;
};

/** input type for inserting array relation for remote table "letter_component_approvals" */
export type Letter_Component_Approvals_Arr_Rel_Insert_Input = {
  data: Array<Letter_Component_Approvals_Insert_Input>;
  on_conflict?: Maybe<Letter_Component_Approvals_On_Conflict>;
};

/** Boolean expression to filter rows from the table "letter_component_approvals". All fields are combined with a logical 'AND'. */
export type Letter_Component_Approvals_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Letter_Component_Approvals_Bool_Exp>>>;
  _not?: Maybe<Letter_Component_Approvals_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Letter_Component_Approvals_Bool_Exp>>>;
  campaign_approval?: Maybe<Campaign_Approvals_Bool_Exp>;
  campaign_letter_component?: Maybe<Campaign_Letter_Components_Bool_Exp>;
  campaign_letter_component_id?: Maybe<String_Comparison_Exp>;
  campaign_template_id?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  is_cancelled?: Maybe<Boolean_Comparison_Exp>;
  practice_id?: Maybe<String_Comparison_Exp>;
  scheduled_event?: Maybe<Scheduled_Events_Bool_Exp>;
  scheduled_event_id?: Maybe<String_Comparison_Exp>;
  status?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "letter_component_approvals" */
export enum Letter_Component_Approvals_Constraint {
  /** unique or primary key constraint */
  LetterComponentApprovalsPkey = 'letter_component_approvals_pkey'
}

/** input type for inserting data into table "letter_component_approvals" */
export type Letter_Component_Approvals_Insert_Input = {
  campaign_approval?: Maybe<Campaign_Approvals_Obj_Rel_Insert_Input>;
  campaign_letter_component?: Maybe<Campaign_Letter_Components_Obj_Rel_Insert_Input>;
  campaign_letter_component_id?: Maybe<Scalars['String']>;
  campaign_template_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  is_cancelled?: Maybe<Scalars['Boolean']>;
  practice_id?: Maybe<Scalars['String']>;
  scheduled_event?: Maybe<Scheduled_Events_Obj_Rel_Insert_Input>;
  scheduled_event_id?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Letter_Component_Approvals_Max_Fields = {
  __typename?: 'letter_component_approvals_max_fields';
  campaign_letter_component_id?: Maybe<Scalars['String']>;
  campaign_template_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  practice_id?: Maybe<Scalars['String']>;
  scheduled_event_id?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "letter_component_approvals" */
export type Letter_Component_Approvals_Max_Order_By = {
  campaign_letter_component_id?: Maybe<Order_By>;
  campaign_template_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  practice_id?: Maybe<Order_By>;
  scheduled_event_id?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Letter_Component_Approvals_Min_Fields = {
  __typename?: 'letter_component_approvals_min_fields';
  campaign_letter_component_id?: Maybe<Scalars['String']>;
  campaign_template_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  practice_id?: Maybe<Scalars['String']>;
  scheduled_event_id?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "letter_component_approvals" */
export type Letter_Component_Approvals_Min_Order_By = {
  campaign_letter_component_id?: Maybe<Order_By>;
  campaign_template_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  practice_id?: Maybe<Order_By>;
  scheduled_event_id?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "letter_component_approvals" */
export type Letter_Component_Approvals_Mutation_Response = {
  __typename?: 'letter_component_approvals_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Letter_Component_Approvals>;
};

/** input type for inserting object relation for remote table "letter_component_approvals" */
export type Letter_Component_Approvals_Obj_Rel_Insert_Input = {
  data: Letter_Component_Approvals_Insert_Input;
  on_conflict?: Maybe<Letter_Component_Approvals_On_Conflict>;
};

/** on conflict condition type for table "letter_component_approvals" */
export type Letter_Component_Approvals_On_Conflict = {
  constraint: Letter_Component_Approvals_Constraint;
  update_columns: Array<Letter_Component_Approvals_Update_Column>;
  where?: Maybe<Letter_Component_Approvals_Bool_Exp>;
};

/** ordering options when selecting data from "letter_component_approvals" */
export type Letter_Component_Approvals_Order_By = {
  campaign_approval?: Maybe<Campaign_Approvals_Order_By>;
  campaign_letter_component?: Maybe<Campaign_Letter_Components_Order_By>;
  campaign_letter_component_id?: Maybe<Order_By>;
  campaign_template_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  is_cancelled?: Maybe<Order_By>;
  practice_id?: Maybe<Order_By>;
  scheduled_event?: Maybe<Scheduled_Events_Order_By>;
  scheduled_event_id?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: "letter_component_approvals" */
export type Letter_Component_Approvals_Pk_Columns_Input = {
  campaign_letter_component_id: Scalars['String'];
  campaign_template_id: Scalars['String'];
  practice_id: Scalars['String'];
};

/** select columns of table "letter_component_approvals" */
export enum Letter_Component_Approvals_Select_Column {
  /** column name */
  CampaignLetterComponentId = 'campaign_letter_component_id',
  /** column name */
  CampaignTemplateId = 'campaign_template_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  IsCancelled = 'is_cancelled',
  /** column name */
  PracticeId = 'practice_id',
  /** column name */
  ScheduledEventId = 'scheduled_event_id',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "letter_component_approvals" */
export type Letter_Component_Approvals_Set_Input = {
  campaign_letter_component_id?: Maybe<Scalars['String']>;
  campaign_template_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  is_cancelled?: Maybe<Scalars['Boolean']>;
  practice_id?: Maybe<Scalars['String']>;
  scheduled_event_id?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "letter_component_approvals" */
export enum Letter_Component_Approvals_Update_Column {
  /** column name */
  CampaignLetterComponentId = 'campaign_letter_component_id',
  /** column name */
  CampaignTemplateId = 'campaign_template_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  IsCancelled = 'is_cancelled',
  /** column name */
  PracticeId = 'practice_id',
  /** column name */
  ScheduledEventId = 'scheduled_event_id',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** columns and relationships of "letter_format_types" */
export type Letter_Format_Types = {
  __typename?: 'letter_format_types';
  letter_format_type_id: Scalars['String'];
};

/** aggregated selection of "letter_format_types" */
export type Letter_Format_Types_Aggregate = {
  __typename?: 'letter_format_types_aggregate';
  aggregate?: Maybe<Letter_Format_Types_Aggregate_Fields>;
  nodes: Array<Letter_Format_Types>;
};

/** aggregate fields of "letter_format_types" */
export type Letter_Format_Types_Aggregate_Fields = {
  __typename?: 'letter_format_types_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Letter_Format_Types_Max_Fields>;
  min?: Maybe<Letter_Format_Types_Min_Fields>;
};


/** aggregate fields of "letter_format_types" */
export type Letter_Format_Types_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Letter_Format_Types_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "letter_format_types" */
export type Letter_Format_Types_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Letter_Format_Types_Max_Order_By>;
  min?: Maybe<Letter_Format_Types_Min_Order_By>;
};

/** input type for inserting array relation for remote table "letter_format_types" */
export type Letter_Format_Types_Arr_Rel_Insert_Input = {
  data: Array<Letter_Format_Types_Insert_Input>;
  on_conflict?: Maybe<Letter_Format_Types_On_Conflict>;
};

/** Boolean expression to filter rows from the table "letter_format_types". All fields are combined with a logical 'AND'. */
export type Letter_Format_Types_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Letter_Format_Types_Bool_Exp>>>;
  _not?: Maybe<Letter_Format_Types_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Letter_Format_Types_Bool_Exp>>>;
  letter_format_type_id?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "letter_format_types" */
export enum Letter_Format_Types_Constraint {
  /** unique or primary key constraint */
  LetterFormatTypesPkey = 'letter_format_types_pkey'
}

export enum Letter_Format_Types_Enum {
  Check = 'CHECK',
  Letter = 'LETTER',
  Postcard = 'POSTCARD',
  SelfMailer = 'SELF_MAILER'
}

/** expression to compare columns of type letter_format_types_enum. All fields are combined with logical 'AND'. */
export type Letter_Format_Types_Enum_Comparison_Exp = {
  _eq?: Maybe<Letter_Format_Types_Enum>;
  _in?: Maybe<Array<Letter_Format_Types_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Letter_Format_Types_Enum>;
  _nin?: Maybe<Array<Letter_Format_Types_Enum>>;
};

/** input type for inserting data into table "letter_format_types" */
export type Letter_Format_Types_Insert_Input = {
  letter_format_type_id?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Letter_Format_Types_Max_Fields = {
  __typename?: 'letter_format_types_max_fields';
  letter_format_type_id?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "letter_format_types" */
export type Letter_Format_Types_Max_Order_By = {
  letter_format_type_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Letter_Format_Types_Min_Fields = {
  __typename?: 'letter_format_types_min_fields';
  letter_format_type_id?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "letter_format_types" */
export type Letter_Format_Types_Min_Order_By = {
  letter_format_type_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "letter_format_types" */
export type Letter_Format_Types_Mutation_Response = {
  __typename?: 'letter_format_types_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Letter_Format_Types>;
};

/** input type for inserting object relation for remote table "letter_format_types" */
export type Letter_Format_Types_Obj_Rel_Insert_Input = {
  data: Letter_Format_Types_Insert_Input;
  on_conflict?: Maybe<Letter_Format_Types_On_Conflict>;
};

/** on conflict condition type for table "letter_format_types" */
export type Letter_Format_Types_On_Conflict = {
  constraint: Letter_Format_Types_Constraint;
  update_columns: Array<Letter_Format_Types_Update_Column>;
  where?: Maybe<Letter_Format_Types_Bool_Exp>;
};

/** ordering options when selecting data from "letter_format_types" */
export type Letter_Format_Types_Order_By = {
  letter_format_type_id?: Maybe<Order_By>;
};

/** primary key columns input for table: "letter_format_types" */
export type Letter_Format_Types_Pk_Columns_Input = {
  letter_format_type_id: Scalars['String'];
};

/** select columns of table "letter_format_types" */
export enum Letter_Format_Types_Select_Column {
  /** column name */
  LetterFormatTypeId = 'letter_format_type_id'
}

/** input type for updating data in table "letter_format_types" */
export type Letter_Format_Types_Set_Input = {
  letter_format_type_id?: Maybe<Scalars['String']>;
};

/** update columns of table "letter_format_types" */
export enum Letter_Format_Types_Update_Column {
  /** column name */
  LetterFormatTypeId = 'letter_format_type_id'
}

/**
 * A pointer to the external source (e.g. Lob) where a letter template is stored and defined.
 * 
 * 
 * columns and relationships of "letter_templates"
 */
export type Letter_Templates = {
  __typename?: 'letter_templates';
  /** An array relationship */
  campaign_template_letters: Array<Campaign_Letter_Components>;
  /** An aggregated array relationship */
  campaign_template_letters_aggregate: Campaign_Letter_Components_Aggregate;
  created_at: Scalars['timestamptz'];
  default_merge_vars: Scalars['jsonb'];
  external_id: Scalars['String'];
  /** For postcards, this is the ID of the back template. */
  external_secondary_id?: Maybe<Scalars['String']>;
  external_source?: Maybe<Scalars['String']>;
  external_version?: Maybe<Scalars['String']>;
  /** Possible vals are LETTER, POSTCARD, SELF_MAILER */
  format: Letter_Format_Types_Enum;
  is_archived: Scalars['Boolean'];
  is_color: Scalars['Boolean'];
  is_public: Scalars['Boolean'];
  letter_company: Scalars['String'];
  letter_from: Scalars['String'];
  letter_template_description?: Maybe<Scalars['String']>;
  letter_template_id: Scalars['String'];
  letter_template_name?: Maybe<Scalars['String']>;
  num_pages: Scalars['Int'];
  perforated_page?: Maybe<Scalars['Int']>;
  /** For postcards, possible vals are 4x6, 6x9, or 6x11. For self_mailers, vals are 6x18_bifold, 11x9_bifold, 12x9_bifold, 17.75x9_trifold. */
  size?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
  webform_url?: Maybe<Scalars['String']>;
};


/**
 * A pointer to the external source (e.g. Lob) where a letter template is stored and defined.
 * 
 * 
 * columns and relationships of "letter_templates"
 */
export type Letter_TemplatesCampaign_Template_LettersArgs = {
  distinct_on?: Maybe<Array<Campaign_Letter_Components_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Campaign_Letter_Components_Order_By>>;
  where?: Maybe<Campaign_Letter_Components_Bool_Exp>;
};


/**
 * A pointer to the external source (e.g. Lob) where a letter template is stored and defined.
 * 
 * 
 * columns and relationships of "letter_templates"
 */
export type Letter_TemplatesCampaign_Template_Letters_AggregateArgs = {
  distinct_on?: Maybe<Array<Campaign_Letter_Components_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Campaign_Letter_Components_Order_By>>;
  where?: Maybe<Campaign_Letter_Components_Bool_Exp>;
};


/**
 * A pointer to the external source (e.g. Lob) where a letter template is stored and defined.
 * 
 * 
 * columns and relationships of "letter_templates"
 */
export type Letter_TemplatesDefault_Merge_VarsArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "letter_templates" */
export type Letter_Templates_Aggregate = {
  __typename?: 'letter_templates_aggregate';
  aggregate?: Maybe<Letter_Templates_Aggregate_Fields>;
  nodes: Array<Letter_Templates>;
};

/** aggregate fields of "letter_templates" */
export type Letter_Templates_Aggregate_Fields = {
  __typename?: 'letter_templates_aggregate_fields';
  avg?: Maybe<Letter_Templates_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Letter_Templates_Max_Fields>;
  min?: Maybe<Letter_Templates_Min_Fields>;
  stddev?: Maybe<Letter_Templates_Stddev_Fields>;
  stddev_pop?: Maybe<Letter_Templates_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Letter_Templates_Stddev_Samp_Fields>;
  sum?: Maybe<Letter_Templates_Sum_Fields>;
  var_pop?: Maybe<Letter_Templates_Var_Pop_Fields>;
  var_samp?: Maybe<Letter_Templates_Var_Samp_Fields>;
  variance?: Maybe<Letter_Templates_Variance_Fields>;
};


/** aggregate fields of "letter_templates" */
export type Letter_Templates_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Letter_Templates_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "letter_templates" */
export type Letter_Templates_Aggregate_Order_By = {
  avg?: Maybe<Letter_Templates_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Letter_Templates_Max_Order_By>;
  min?: Maybe<Letter_Templates_Min_Order_By>;
  stddev?: Maybe<Letter_Templates_Stddev_Order_By>;
  stddev_pop?: Maybe<Letter_Templates_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Letter_Templates_Stddev_Samp_Order_By>;
  sum?: Maybe<Letter_Templates_Sum_Order_By>;
  var_pop?: Maybe<Letter_Templates_Var_Pop_Order_By>;
  var_samp?: Maybe<Letter_Templates_Var_Samp_Order_By>;
  variance?: Maybe<Letter_Templates_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Letter_Templates_Append_Input = {
  default_merge_vars?: Maybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "letter_templates" */
export type Letter_Templates_Arr_Rel_Insert_Input = {
  data: Array<Letter_Templates_Insert_Input>;
  on_conflict?: Maybe<Letter_Templates_On_Conflict>;
};

/** aggregate avg on columns */
export type Letter_Templates_Avg_Fields = {
  __typename?: 'letter_templates_avg_fields';
  num_pages?: Maybe<Scalars['Float']>;
  perforated_page?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "letter_templates" */
export type Letter_Templates_Avg_Order_By = {
  num_pages?: Maybe<Order_By>;
  perforated_page?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "letter_templates". All fields are combined with a logical 'AND'. */
export type Letter_Templates_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Letter_Templates_Bool_Exp>>>;
  _not?: Maybe<Letter_Templates_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Letter_Templates_Bool_Exp>>>;
  campaign_template_letters?: Maybe<Campaign_Letter_Components_Bool_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  default_merge_vars?: Maybe<Jsonb_Comparison_Exp>;
  external_id?: Maybe<String_Comparison_Exp>;
  external_secondary_id?: Maybe<String_Comparison_Exp>;
  external_source?: Maybe<String_Comparison_Exp>;
  external_version?: Maybe<String_Comparison_Exp>;
  format?: Maybe<Letter_Format_Types_Enum_Comparison_Exp>;
  is_archived?: Maybe<Boolean_Comparison_Exp>;
  is_color?: Maybe<Boolean_Comparison_Exp>;
  is_public?: Maybe<Boolean_Comparison_Exp>;
  letter_company?: Maybe<String_Comparison_Exp>;
  letter_from?: Maybe<String_Comparison_Exp>;
  letter_template_description?: Maybe<String_Comparison_Exp>;
  letter_template_id?: Maybe<String_Comparison_Exp>;
  letter_template_name?: Maybe<String_Comparison_Exp>;
  num_pages?: Maybe<Int_Comparison_Exp>;
  perforated_page?: Maybe<Int_Comparison_Exp>;
  size?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  webform_url?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "letter_templates" */
export enum Letter_Templates_Constraint {
  /** unique or primary key constraint */
  LetterTemplatesPkey = 'letter_templates_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Letter_Templates_Delete_At_Path_Input = {
  default_merge_vars?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Letter_Templates_Delete_Elem_Input = {
  default_merge_vars?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Letter_Templates_Delete_Key_Input = {
  default_merge_vars?: Maybe<Scalars['String']>;
};

/** input type for incrementing integer column in table "letter_templates" */
export type Letter_Templates_Inc_Input = {
  num_pages?: Maybe<Scalars['Int']>;
  perforated_page?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "letter_templates" */
export type Letter_Templates_Insert_Input = {
  campaign_template_letters?: Maybe<Campaign_Letter_Components_Arr_Rel_Insert_Input>;
  created_at?: Maybe<Scalars['timestamptz']>;
  default_merge_vars?: Maybe<Scalars['jsonb']>;
  external_id?: Maybe<Scalars['String']>;
  external_secondary_id?: Maybe<Scalars['String']>;
  external_source?: Maybe<Scalars['String']>;
  external_version?: Maybe<Scalars['String']>;
  format?: Maybe<Letter_Format_Types_Enum>;
  is_archived?: Maybe<Scalars['Boolean']>;
  is_color?: Maybe<Scalars['Boolean']>;
  is_public?: Maybe<Scalars['Boolean']>;
  letter_company?: Maybe<Scalars['String']>;
  letter_from?: Maybe<Scalars['String']>;
  letter_template_description?: Maybe<Scalars['String']>;
  letter_template_id?: Maybe<Scalars['String']>;
  letter_template_name?: Maybe<Scalars['String']>;
  num_pages?: Maybe<Scalars['Int']>;
  perforated_page?: Maybe<Scalars['Int']>;
  size?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  webform_url?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Letter_Templates_Max_Fields = {
  __typename?: 'letter_templates_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  external_id?: Maybe<Scalars['String']>;
  external_secondary_id?: Maybe<Scalars['String']>;
  external_source?: Maybe<Scalars['String']>;
  external_version?: Maybe<Scalars['String']>;
  letter_company?: Maybe<Scalars['String']>;
  letter_from?: Maybe<Scalars['String']>;
  letter_template_description?: Maybe<Scalars['String']>;
  letter_template_id?: Maybe<Scalars['String']>;
  letter_template_name?: Maybe<Scalars['String']>;
  num_pages?: Maybe<Scalars['Int']>;
  perforated_page?: Maybe<Scalars['Int']>;
  size?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  webform_url?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "letter_templates" */
export type Letter_Templates_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  external_id?: Maybe<Order_By>;
  external_secondary_id?: Maybe<Order_By>;
  external_source?: Maybe<Order_By>;
  external_version?: Maybe<Order_By>;
  letter_company?: Maybe<Order_By>;
  letter_from?: Maybe<Order_By>;
  letter_template_description?: Maybe<Order_By>;
  letter_template_id?: Maybe<Order_By>;
  letter_template_name?: Maybe<Order_By>;
  num_pages?: Maybe<Order_By>;
  perforated_page?: Maybe<Order_By>;
  size?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  webform_url?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Letter_Templates_Min_Fields = {
  __typename?: 'letter_templates_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  external_id?: Maybe<Scalars['String']>;
  external_secondary_id?: Maybe<Scalars['String']>;
  external_source?: Maybe<Scalars['String']>;
  external_version?: Maybe<Scalars['String']>;
  letter_company?: Maybe<Scalars['String']>;
  letter_from?: Maybe<Scalars['String']>;
  letter_template_description?: Maybe<Scalars['String']>;
  letter_template_id?: Maybe<Scalars['String']>;
  letter_template_name?: Maybe<Scalars['String']>;
  num_pages?: Maybe<Scalars['Int']>;
  perforated_page?: Maybe<Scalars['Int']>;
  size?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  webform_url?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "letter_templates" */
export type Letter_Templates_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  external_id?: Maybe<Order_By>;
  external_secondary_id?: Maybe<Order_By>;
  external_source?: Maybe<Order_By>;
  external_version?: Maybe<Order_By>;
  letter_company?: Maybe<Order_By>;
  letter_from?: Maybe<Order_By>;
  letter_template_description?: Maybe<Order_By>;
  letter_template_id?: Maybe<Order_By>;
  letter_template_name?: Maybe<Order_By>;
  num_pages?: Maybe<Order_By>;
  perforated_page?: Maybe<Order_By>;
  size?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  webform_url?: Maybe<Order_By>;
};

/** response of any mutation on the table "letter_templates" */
export type Letter_Templates_Mutation_Response = {
  __typename?: 'letter_templates_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Letter_Templates>;
};

/** input type for inserting object relation for remote table "letter_templates" */
export type Letter_Templates_Obj_Rel_Insert_Input = {
  data: Letter_Templates_Insert_Input;
  on_conflict?: Maybe<Letter_Templates_On_Conflict>;
};

/** on conflict condition type for table "letter_templates" */
export type Letter_Templates_On_Conflict = {
  constraint: Letter_Templates_Constraint;
  update_columns: Array<Letter_Templates_Update_Column>;
  where?: Maybe<Letter_Templates_Bool_Exp>;
};

/** ordering options when selecting data from "letter_templates" */
export type Letter_Templates_Order_By = {
  campaign_template_letters_aggregate?: Maybe<Campaign_Letter_Components_Aggregate_Order_By>;
  created_at?: Maybe<Order_By>;
  default_merge_vars?: Maybe<Order_By>;
  external_id?: Maybe<Order_By>;
  external_secondary_id?: Maybe<Order_By>;
  external_source?: Maybe<Order_By>;
  external_version?: Maybe<Order_By>;
  format?: Maybe<Order_By>;
  is_archived?: Maybe<Order_By>;
  is_color?: Maybe<Order_By>;
  is_public?: Maybe<Order_By>;
  letter_company?: Maybe<Order_By>;
  letter_from?: Maybe<Order_By>;
  letter_template_description?: Maybe<Order_By>;
  letter_template_id?: Maybe<Order_By>;
  letter_template_name?: Maybe<Order_By>;
  num_pages?: Maybe<Order_By>;
  perforated_page?: Maybe<Order_By>;
  size?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  webform_url?: Maybe<Order_By>;
};

/** primary key columns input for table: "letter_templates" */
export type Letter_Templates_Pk_Columns_Input = {
  letter_template_id: Scalars['String'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Letter_Templates_Prepend_Input = {
  default_merge_vars?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "letter_templates" */
export enum Letter_Templates_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DefaultMergeVars = 'default_merge_vars',
  /** column name */
  ExternalId = 'external_id',
  /** column name */
  ExternalSecondaryId = 'external_secondary_id',
  /** column name */
  ExternalSource = 'external_source',
  /** column name */
  ExternalVersion = 'external_version',
  /** column name */
  Format = 'format',
  /** column name */
  IsArchived = 'is_archived',
  /** column name */
  IsColor = 'is_color',
  /** column name */
  IsPublic = 'is_public',
  /** column name */
  LetterCompany = 'letter_company',
  /** column name */
  LetterFrom = 'letter_from',
  /** column name */
  LetterTemplateDescription = 'letter_template_description',
  /** column name */
  LetterTemplateId = 'letter_template_id',
  /** column name */
  LetterTemplateName = 'letter_template_name',
  /** column name */
  NumPages = 'num_pages',
  /** column name */
  PerforatedPage = 'perforated_page',
  /** column name */
  Size = 'size',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  WebformUrl = 'webform_url'
}

/** input type for updating data in table "letter_templates" */
export type Letter_Templates_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  default_merge_vars?: Maybe<Scalars['jsonb']>;
  external_id?: Maybe<Scalars['String']>;
  external_secondary_id?: Maybe<Scalars['String']>;
  external_source?: Maybe<Scalars['String']>;
  external_version?: Maybe<Scalars['String']>;
  format?: Maybe<Letter_Format_Types_Enum>;
  is_archived?: Maybe<Scalars['Boolean']>;
  is_color?: Maybe<Scalars['Boolean']>;
  is_public?: Maybe<Scalars['Boolean']>;
  letter_company?: Maybe<Scalars['String']>;
  letter_from?: Maybe<Scalars['String']>;
  letter_template_description?: Maybe<Scalars['String']>;
  letter_template_id?: Maybe<Scalars['String']>;
  letter_template_name?: Maybe<Scalars['String']>;
  num_pages?: Maybe<Scalars['Int']>;
  perforated_page?: Maybe<Scalars['Int']>;
  size?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  webform_url?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Letter_Templates_Stddev_Fields = {
  __typename?: 'letter_templates_stddev_fields';
  num_pages?: Maybe<Scalars['Float']>;
  perforated_page?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "letter_templates" */
export type Letter_Templates_Stddev_Order_By = {
  num_pages?: Maybe<Order_By>;
  perforated_page?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Letter_Templates_Stddev_Pop_Fields = {
  __typename?: 'letter_templates_stddev_pop_fields';
  num_pages?: Maybe<Scalars['Float']>;
  perforated_page?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "letter_templates" */
export type Letter_Templates_Stddev_Pop_Order_By = {
  num_pages?: Maybe<Order_By>;
  perforated_page?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Letter_Templates_Stddev_Samp_Fields = {
  __typename?: 'letter_templates_stddev_samp_fields';
  num_pages?: Maybe<Scalars['Float']>;
  perforated_page?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "letter_templates" */
export type Letter_Templates_Stddev_Samp_Order_By = {
  num_pages?: Maybe<Order_By>;
  perforated_page?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Letter_Templates_Sum_Fields = {
  __typename?: 'letter_templates_sum_fields';
  num_pages?: Maybe<Scalars['Int']>;
  perforated_page?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "letter_templates" */
export type Letter_Templates_Sum_Order_By = {
  num_pages?: Maybe<Order_By>;
  perforated_page?: Maybe<Order_By>;
};

/** update columns of table "letter_templates" */
export enum Letter_Templates_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DefaultMergeVars = 'default_merge_vars',
  /** column name */
  ExternalId = 'external_id',
  /** column name */
  ExternalSecondaryId = 'external_secondary_id',
  /** column name */
  ExternalSource = 'external_source',
  /** column name */
  ExternalVersion = 'external_version',
  /** column name */
  Format = 'format',
  /** column name */
  IsArchived = 'is_archived',
  /** column name */
  IsColor = 'is_color',
  /** column name */
  IsPublic = 'is_public',
  /** column name */
  LetterCompany = 'letter_company',
  /** column name */
  LetterFrom = 'letter_from',
  /** column name */
  LetterTemplateDescription = 'letter_template_description',
  /** column name */
  LetterTemplateId = 'letter_template_id',
  /** column name */
  LetterTemplateName = 'letter_template_name',
  /** column name */
  NumPages = 'num_pages',
  /** column name */
  PerforatedPage = 'perforated_page',
  /** column name */
  Size = 'size',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  WebformUrl = 'webform_url'
}

/** aggregate var_pop on columns */
export type Letter_Templates_Var_Pop_Fields = {
  __typename?: 'letter_templates_var_pop_fields';
  num_pages?: Maybe<Scalars['Float']>;
  perforated_page?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "letter_templates" */
export type Letter_Templates_Var_Pop_Order_By = {
  num_pages?: Maybe<Order_By>;
  perforated_page?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Letter_Templates_Var_Samp_Fields = {
  __typename?: 'letter_templates_var_samp_fields';
  num_pages?: Maybe<Scalars['Float']>;
  perforated_page?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "letter_templates" */
export type Letter_Templates_Var_Samp_Order_By = {
  num_pages?: Maybe<Order_By>;
  perforated_page?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Letter_Templates_Variance_Fields = {
  __typename?: 'letter_templates_variance_fields';
  num_pages?: Maybe<Scalars['Float']>;
  perforated_page?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "letter_templates" */
export type Letter_Templates_Variance_Order_By = {
  num_pages?: Maybe<Order_By>;
  perforated_page?: Maybe<Order_By>;
};

/**
 * Phone number values present in letters, used to join client_call_records and letters tables
 * 
 * 
 * columns and relationships of "letter_variable_values"
 */
export type Letter_Variable_Values = {
  __typename?: 'letter_variable_values';
  key: Scalars['String'];
  /** An object relationship */
  letter?: Maybe<Letters>;
  letter_id: Scalars['String'];
  value: Scalars['String'];
};

/** aggregated selection of "letter_variable_values" */
export type Letter_Variable_Values_Aggregate = {
  __typename?: 'letter_variable_values_aggregate';
  aggregate?: Maybe<Letter_Variable_Values_Aggregate_Fields>;
  nodes: Array<Letter_Variable_Values>;
};

/** aggregate fields of "letter_variable_values" */
export type Letter_Variable_Values_Aggregate_Fields = {
  __typename?: 'letter_variable_values_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Letter_Variable_Values_Max_Fields>;
  min?: Maybe<Letter_Variable_Values_Min_Fields>;
};


/** aggregate fields of "letter_variable_values" */
export type Letter_Variable_Values_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Letter_Variable_Values_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "letter_variable_values" */
export type Letter_Variable_Values_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Letter_Variable_Values_Max_Order_By>;
  min?: Maybe<Letter_Variable_Values_Min_Order_By>;
};

/** input type for inserting array relation for remote table "letter_variable_values" */
export type Letter_Variable_Values_Arr_Rel_Insert_Input = {
  data: Array<Letter_Variable_Values_Insert_Input>;
  on_conflict?: Maybe<Letter_Variable_Values_On_Conflict>;
};

/** Boolean expression to filter rows from the table "letter_variable_values". All fields are combined with a logical 'AND'. */
export type Letter_Variable_Values_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Letter_Variable_Values_Bool_Exp>>>;
  _not?: Maybe<Letter_Variable_Values_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Letter_Variable_Values_Bool_Exp>>>;
  key?: Maybe<String_Comparison_Exp>;
  letter?: Maybe<Letters_Bool_Exp>;
  letter_id?: Maybe<String_Comparison_Exp>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "letter_variable_values" */
export enum Letter_Variable_Values_Constraint {
  /** unique or primary key constraint */
  LetterVariablesPkey = 'letter_variables_pkey'
}

/** input type for inserting data into table "letter_variable_values" */
export type Letter_Variable_Values_Insert_Input = {
  key?: Maybe<Scalars['String']>;
  letter?: Maybe<Letters_Obj_Rel_Insert_Input>;
  letter_id?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Letter_Variable_Values_Max_Fields = {
  __typename?: 'letter_variable_values_max_fields';
  key?: Maybe<Scalars['String']>;
  letter_id?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "letter_variable_values" */
export type Letter_Variable_Values_Max_Order_By = {
  key?: Maybe<Order_By>;
  letter_id?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Letter_Variable_Values_Min_Fields = {
  __typename?: 'letter_variable_values_min_fields';
  key?: Maybe<Scalars['String']>;
  letter_id?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "letter_variable_values" */
export type Letter_Variable_Values_Min_Order_By = {
  key?: Maybe<Order_By>;
  letter_id?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
};

/** response of any mutation on the table "letter_variable_values" */
export type Letter_Variable_Values_Mutation_Response = {
  __typename?: 'letter_variable_values_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Letter_Variable_Values>;
};

/** input type for inserting object relation for remote table "letter_variable_values" */
export type Letter_Variable_Values_Obj_Rel_Insert_Input = {
  data: Letter_Variable_Values_Insert_Input;
  on_conflict?: Maybe<Letter_Variable_Values_On_Conflict>;
};

/** on conflict condition type for table "letter_variable_values" */
export type Letter_Variable_Values_On_Conflict = {
  constraint: Letter_Variable_Values_Constraint;
  update_columns: Array<Letter_Variable_Values_Update_Column>;
  where?: Maybe<Letter_Variable_Values_Bool_Exp>;
};

/** ordering options when selecting data from "letter_variable_values" */
export type Letter_Variable_Values_Order_By = {
  key?: Maybe<Order_By>;
  letter?: Maybe<Letters_Order_By>;
  letter_id?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: "letter_variable_values" */
export type Letter_Variable_Values_Pk_Columns_Input = {
  key: Scalars['String'];
  letter_id: Scalars['String'];
};

/** select columns of table "letter_variable_values" */
export enum Letter_Variable_Values_Select_Column {
  /** column name */
  Key = 'key',
  /** column name */
  LetterId = 'letter_id',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "letter_variable_values" */
export type Letter_Variable_Values_Set_Input = {
  key?: Maybe<Scalars['String']>;
  letter_id?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "letter_variable_values" */
export enum Letter_Variable_Values_Update_Column {
  /** column name */
  Key = 'key',
  /** column name */
  LetterId = 'letter_id',
  /** column name */
  Value = 'value'
}

/** columns and relationships of "letters" */
export type Letters = {
  __typename?: 'letters';
  /** An object relationship */
  campaign_letter_component?: Maybe<Campaign_Letter_Components>;
  campaign_letter_component_id: Scalars['String'];
  created_at: Scalars['timestamptz'];
  expected_delivery_date?: Maybe<Scalars['date']>;
  external_id?: Maybe<Scalars['String']>;
  is_archived: Scalars['Boolean'];
  letter_id: Scalars['String'];
  lob_data?: Maybe<Scalars['jsonb']>;
  /** An object relationship */
  patient: Patients;
  patient_id: Scalars['String'];
  /** An object relationship */
  practice: Practices;
  practice_id: Scalars['String'];
  send_datetime: Scalars['timestamptz'];
  status: Scalars['String'];
  tracking_events: Scalars['jsonb'];
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "letters" */
export type LettersLob_DataArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "letters" */
export type LettersTracking_EventsArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "letters" */
export type Letters_Aggregate = {
  __typename?: 'letters_aggregate';
  aggregate?: Maybe<Letters_Aggregate_Fields>;
  nodes: Array<Letters>;
};

/** aggregate fields of "letters" */
export type Letters_Aggregate_Fields = {
  __typename?: 'letters_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Letters_Max_Fields>;
  min?: Maybe<Letters_Min_Fields>;
};


/** aggregate fields of "letters" */
export type Letters_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Letters_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "letters" */
export type Letters_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Letters_Max_Order_By>;
  min?: Maybe<Letters_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Letters_Append_Input = {
  lob_data?: Maybe<Scalars['jsonb']>;
  tracking_events?: Maybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "letters" */
export type Letters_Arr_Rel_Insert_Input = {
  data: Array<Letters_Insert_Input>;
  on_conflict?: Maybe<Letters_On_Conflict>;
};

/** Boolean expression to filter rows from the table "letters". All fields are combined with a logical 'AND'. */
export type Letters_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Letters_Bool_Exp>>>;
  _not?: Maybe<Letters_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Letters_Bool_Exp>>>;
  campaign_letter_component?: Maybe<Campaign_Letter_Components_Bool_Exp>;
  campaign_letter_component_id?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  expected_delivery_date?: Maybe<Date_Comparison_Exp>;
  external_id?: Maybe<String_Comparison_Exp>;
  is_archived?: Maybe<Boolean_Comparison_Exp>;
  letter_id?: Maybe<String_Comparison_Exp>;
  lob_data?: Maybe<Jsonb_Comparison_Exp>;
  patient?: Maybe<Patients_Bool_Exp>;
  patient_id?: Maybe<String_Comparison_Exp>;
  practice?: Maybe<Practices_Bool_Exp>;
  practice_id?: Maybe<String_Comparison_Exp>;
  send_datetime?: Maybe<Timestamptz_Comparison_Exp>;
  status?: Maybe<String_Comparison_Exp>;
  tracking_events?: Maybe<Jsonb_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "letters" */
export enum Letters_Constraint {
  /** unique or primary key constraint */
  LettersExternalIdKey = 'letters_external_id_key',
  /** unique or primary key constraint */
  LettersLetterIdTextPatternOps = 'letters_letter_id_text_pattern_ops',
  /** unique or primary key constraint */
  LettersPatientIdPracticeIdCampaignLetterComponentIdKey = 'letters_patient_id_practice_id_campaign_letter_component_id_key',
  /** unique or primary key constraint */
  LettersPkey = 'letters_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Letters_Delete_At_Path_Input = {
  lob_data?: Maybe<Array<Maybe<Scalars['String']>>>;
  tracking_events?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Letters_Delete_Elem_Input = {
  lob_data?: Maybe<Scalars['Int']>;
  tracking_events?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Letters_Delete_Key_Input = {
  lob_data?: Maybe<Scalars['String']>;
  tracking_events?: Maybe<Scalars['String']>;
};

/** input type for inserting data into table "letters" */
export type Letters_Insert_Input = {
  campaign_letter_component?: Maybe<Campaign_Letter_Components_Obj_Rel_Insert_Input>;
  campaign_letter_component_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  expected_delivery_date?: Maybe<Scalars['date']>;
  external_id?: Maybe<Scalars['String']>;
  is_archived?: Maybe<Scalars['Boolean']>;
  letter_id?: Maybe<Scalars['String']>;
  lob_data?: Maybe<Scalars['jsonb']>;
  patient?: Maybe<Patients_Obj_Rel_Insert_Input>;
  patient_id?: Maybe<Scalars['String']>;
  practice?: Maybe<Practices_Obj_Rel_Insert_Input>;
  practice_id?: Maybe<Scalars['String']>;
  send_datetime?: Maybe<Scalars['timestamptz']>;
  status?: Maybe<Scalars['String']>;
  tracking_events?: Maybe<Scalars['jsonb']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Letters_Max_Fields = {
  __typename?: 'letters_max_fields';
  campaign_letter_component_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  expected_delivery_date?: Maybe<Scalars['date']>;
  external_id?: Maybe<Scalars['String']>;
  letter_id?: Maybe<Scalars['String']>;
  patient_id?: Maybe<Scalars['String']>;
  practice_id?: Maybe<Scalars['String']>;
  send_datetime?: Maybe<Scalars['timestamptz']>;
  status?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "letters" */
export type Letters_Max_Order_By = {
  campaign_letter_component_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  expected_delivery_date?: Maybe<Order_By>;
  external_id?: Maybe<Order_By>;
  letter_id?: Maybe<Order_By>;
  patient_id?: Maybe<Order_By>;
  practice_id?: Maybe<Order_By>;
  send_datetime?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Letters_Min_Fields = {
  __typename?: 'letters_min_fields';
  campaign_letter_component_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  expected_delivery_date?: Maybe<Scalars['date']>;
  external_id?: Maybe<Scalars['String']>;
  letter_id?: Maybe<Scalars['String']>;
  patient_id?: Maybe<Scalars['String']>;
  practice_id?: Maybe<Scalars['String']>;
  send_datetime?: Maybe<Scalars['timestamptz']>;
  status?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "letters" */
export type Letters_Min_Order_By = {
  campaign_letter_component_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  expected_delivery_date?: Maybe<Order_By>;
  external_id?: Maybe<Order_By>;
  letter_id?: Maybe<Order_By>;
  patient_id?: Maybe<Order_By>;
  practice_id?: Maybe<Order_By>;
  send_datetime?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "letters" */
export type Letters_Mutation_Response = {
  __typename?: 'letters_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Letters>;
};

/** input type for inserting object relation for remote table "letters" */
export type Letters_Obj_Rel_Insert_Input = {
  data: Letters_Insert_Input;
  on_conflict?: Maybe<Letters_On_Conflict>;
};

/** on conflict condition type for table "letters" */
export type Letters_On_Conflict = {
  constraint: Letters_Constraint;
  update_columns: Array<Letters_Update_Column>;
  where?: Maybe<Letters_Bool_Exp>;
};

/** ordering options when selecting data from "letters" */
export type Letters_Order_By = {
  campaign_letter_component?: Maybe<Campaign_Letter_Components_Order_By>;
  campaign_letter_component_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  expected_delivery_date?: Maybe<Order_By>;
  external_id?: Maybe<Order_By>;
  is_archived?: Maybe<Order_By>;
  letter_id?: Maybe<Order_By>;
  lob_data?: Maybe<Order_By>;
  patient?: Maybe<Patients_Order_By>;
  patient_id?: Maybe<Order_By>;
  practice?: Maybe<Practices_Order_By>;
  practice_id?: Maybe<Order_By>;
  send_datetime?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  tracking_events?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: "letters" */
export type Letters_Pk_Columns_Input = {
  letter_id: Scalars['String'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Letters_Prepend_Input = {
  lob_data?: Maybe<Scalars['jsonb']>;
  tracking_events?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "letters" */
export enum Letters_Select_Column {
  /** column name */
  CampaignLetterComponentId = 'campaign_letter_component_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ExpectedDeliveryDate = 'expected_delivery_date',
  /** column name */
  ExternalId = 'external_id',
  /** column name */
  IsArchived = 'is_archived',
  /** column name */
  LetterId = 'letter_id',
  /** column name */
  LobData = 'lob_data',
  /** column name */
  PatientId = 'patient_id',
  /** column name */
  PracticeId = 'practice_id',
  /** column name */
  SendDatetime = 'send_datetime',
  /** column name */
  Status = 'status',
  /** column name */
  TrackingEvents = 'tracking_events',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "letters" */
export type Letters_Set_Input = {
  campaign_letter_component_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  expected_delivery_date?: Maybe<Scalars['date']>;
  external_id?: Maybe<Scalars['String']>;
  is_archived?: Maybe<Scalars['Boolean']>;
  letter_id?: Maybe<Scalars['String']>;
  lob_data?: Maybe<Scalars['jsonb']>;
  patient_id?: Maybe<Scalars['String']>;
  practice_id?: Maybe<Scalars['String']>;
  send_datetime?: Maybe<Scalars['timestamptz']>;
  status?: Maybe<Scalars['String']>;
  tracking_events?: Maybe<Scalars['jsonb']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "letters" */
export enum Letters_Update_Column {
  /** column name */
  CampaignLetterComponentId = 'campaign_letter_component_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ExpectedDeliveryDate = 'expected_delivery_date',
  /** column name */
  ExternalId = 'external_id',
  /** column name */
  IsArchived = 'is_archived',
  /** column name */
  LetterId = 'letter_id',
  /** column name */
  LobData = 'lob_data',
  /** column name */
  PatientId = 'patient_id',
  /** column name */
  PracticeId = 'practice_id',
  /** column name */
  SendDatetime = 'send_datetime',
  /** column name */
  Status = 'status',
  /** column name */
  TrackingEvents = 'tracking_events',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** mutation root */
export type Mutation_Root = {
  __typename?: 'mutation_root';
  /** perform the action: "create_user" */
  create_user?: Maybe<CreateUserOutput>;
  /** delete data from the table: "account_creation_requests" */
  delete_account_creation_requests?: Maybe<Account_Creation_Requests_Mutation_Response>;
  /** delete single row from the table: "account_creation_requests" */
  delete_account_creation_requests_by_pk?: Maybe<Account_Creation_Requests>;
  /** delete data from the table: "alignments" */
  delete_alignments?: Maybe<Alignments_Mutation_Response>;
  /** delete single row from the table: "alignments" */
  delete_alignments_by_pk?: Maybe<Alignments>;
  /** delete data from the table: "business_associate_agreements" */
  delete_business_associate_agreements?: Maybe<Business_Associate_Agreements_Mutation_Response>;
  /** delete single row from the table: "business_associate_agreements" */
  delete_business_associate_agreements_by_pk?: Maybe<Business_Associate_Agreements>;
  /** delete data from the table: "campaign_approvals" */
  delete_campaign_approvals?: Maybe<Campaign_Approvals_Mutation_Response>;
  /** delete single row from the table: "campaign_approvals" */
  delete_campaign_approvals_by_pk?: Maybe<Campaign_Approvals>;
  /** delete data from the table: "campaign_email_components" */
  delete_campaign_email_components?: Maybe<Campaign_Email_Components_Mutation_Response>;
  /** delete single row from the table: "campaign_email_components" */
  delete_campaign_email_components_by_pk?: Maybe<Campaign_Email_Components>;
  /** delete data from the table: "campaign_letter_components" */
  delete_campaign_letter_components?: Maybe<Campaign_Letter_Components_Mutation_Response>;
  /** delete single row from the table: "campaign_letter_components" */
  delete_campaign_letter_components_by_pk?: Maybe<Campaign_Letter_Components>;
  /** delete data from the table: "campaign_sms_components" */
  delete_campaign_sms_components?: Maybe<Campaign_Sms_Components_Mutation_Response>;
  /** delete single row from the table: "campaign_sms_components" */
  delete_campaign_sms_components_by_pk?: Maybe<Campaign_Sms_Components>;
  /** delete data from the table: "campaign_templates" */
  delete_campaign_templates?: Maybe<Campaign_Templates_Mutation_Response>;
  /** delete single row from the table: "campaign_templates" */
  delete_campaign_templates_by_pk?: Maybe<Campaign_Templates>;
  /** delete data from the table: "client_call_records" */
  delete_client_call_records?: Maybe<Client_Call_Records_Mutation_Response>;
  /** delete single row from the table: "client_call_records" */
  delete_client_call_records_by_pk?: Maybe<Client_Call_Records>;
  /** delete data from the table: "cms_alignment_reports" */
  delete_cms_alignment_reports?: Maybe<Cms_Alignment_Reports_Mutation_Response>;
  /** delete single row from the table: "cms_alignment_reports" */
  delete_cms_alignment_reports_by_pk?: Maybe<Cms_Alignment_Reports>;
  /** delete data from the table: "cms_dce_participants" */
  delete_cms_dce_participants?: Maybe<Cms_Dce_Participants_Mutation_Response>;
  /** delete data from the table: "cms_provider_alignment_reports" */
  delete_cms_provider_alignment_reports?: Maybe<Cms_Provider_Alignment_Reports_Mutation_Response>;
  /** delete single row from the table: "cms_provider_alignment_reports" */
  delete_cms_provider_alignment_reports_by_pk?: Maybe<Cms_Provider_Alignment_Reports>;
  /** delete data from the table: "cms_pva_responses" */
  delete_cms_pva_responses?: Maybe<Cms_Pva_Responses_Mutation_Response>;
  /** delete single row from the table: "cms_pva_responses" */
  delete_cms_pva_responses_by_pk?: Maybe<Cms_Pva_Responses>;
  /** delete data from the table: "component_tracking_ids" */
  delete_component_tracking_ids?: Maybe<Component_Tracking_Ids_Mutation_Response>;
  /** delete single row from the table: "component_tracking_ids" */
  delete_component_tracking_ids_by_pk?: Maybe<Component_Tracking_Ids>;
  /** delete data from the table: "daily_performance_stats" */
  delete_daily_performance_stats?: Maybe<Daily_Performance_Stats_Mutation_Response>;
  /** delete single row from the table: "daily_performance_stats" */
  delete_daily_performance_stats_by_pk?: Maybe<Daily_Performance_Stats>;
  /** delete data from the table: "email_component_approvals" */
  delete_email_component_approvals?: Maybe<Email_Component_Approvals_Mutation_Response>;
  /** delete single row from the table: "email_component_approvals" */
  delete_email_component_approvals_by_pk?: Maybe<Email_Component_Approvals>;
  /** delete data from the table: "email_templates" */
  delete_email_templates?: Maybe<Email_Templates_Mutation_Response>;
  /** delete single row from the table: "email_templates" */
  delete_email_templates_by_pk?: Maybe<Email_Templates>;
  /** delete data from the table: "emails" */
  delete_emails?: Maybe<Emails_Mutation_Response>;
  /** delete single row from the table: "emails" */
  delete_emails_by_pk?: Maybe<Emails>;
  /** delete data from the table: "file_uploads" */
  delete_file_uploads?: Maybe<File_Uploads_Mutation_Response>;
  /** delete single row from the table: "file_uploads" */
  delete_file_uploads_by_pk?: Maybe<File_Uploads>;
  /** delete data from the table: "health_equity_reports" */
  delete_health_equity_reports?: Maybe<Health_Equity_Reports_Mutation_Response>;
  /** delete data from the table: "health_equity_reports_audit" */
  delete_health_equity_reports_audit?: Maybe<Health_Equity_Reports_Audit_Mutation_Response>;
  /** delete single row from the table: "health_equity_reports" */
  delete_health_equity_reports_by_pk?: Maybe<Health_Equity_Reports>;
  /** delete data from the table: "hedr_submission_templates" */
  delete_hedr_submission_templates?: Maybe<Hedr_Submission_Templates_Mutation_Response>;
  /** delete single row from the table: "hedr_submission_templates" */
  delete_hedr_submission_templates_by_pk?: Maybe<Hedr_Submission_Templates>;
  /** delete data from the table: "insurance_segment_mappings" */
  delete_insurance_segment_mappings?: Maybe<Insurance_Segment_Mappings_Mutation_Response>;
  /** delete single row from the table: "insurance_segment_mappings" */
  delete_insurance_segment_mappings_by_pk?: Maybe<Insurance_Segment_Mappings>;
  /** delete data from the table: "letter_component_approvals" */
  delete_letter_component_approvals?: Maybe<Letter_Component_Approvals_Mutation_Response>;
  /** delete single row from the table: "letter_component_approvals" */
  delete_letter_component_approvals_by_pk?: Maybe<Letter_Component_Approvals>;
  /** delete data from the table: "letter_format_types" */
  delete_letter_format_types?: Maybe<Letter_Format_Types_Mutation_Response>;
  /** delete single row from the table: "letter_format_types" */
  delete_letter_format_types_by_pk?: Maybe<Letter_Format_Types>;
  /** delete data from the table: "letter_templates" */
  delete_letter_templates?: Maybe<Letter_Templates_Mutation_Response>;
  /** delete single row from the table: "letter_templates" */
  delete_letter_templates_by_pk?: Maybe<Letter_Templates>;
  /** delete data from the table: "letter_variable_values" */
  delete_letter_variable_values?: Maybe<Letter_Variable_Values_Mutation_Response>;
  /** delete single row from the table: "letter_variable_values" */
  delete_letter_variable_values_by_pk?: Maybe<Letter_Variable_Values>;
  /** delete data from the table: "letters" */
  delete_letters?: Maybe<Letters_Mutation_Response>;
  /** delete single row from the table: "letters" */
  delete_letters_by_pk?: Maybe<Letters>;
  /** delete data from the table: "networks" */
  delete_networks?: Maybe<Networks_Mutation_Response>;
  /** delete single row from the table: "networks" */
  delete_networks_by_pk?: Maybe<Networks>;
  /** delete data from the table: "organization_networks" */
  delete_organization_networks?: Maybe<Organization_Networks_Mutation_Response>;
  /** delete single row from the table: "organization_networks" */
  delete_organization_networks_by_pk?: Maybe<Organization_Networks>;
  /** delete data from the table: "organizations" */
  delete_organizations?: Maybe<Organizations_Mutation_Response>;
  /** delete single row from the table: "organizations" */
  delete_organizations_by_pk?: Maybe<Organizations>;
  /** delete data from the table: "patient_roster_files" */
  delete_patient_roster_files?: Maybe<Patient_Roster_Files_Mutation_Response>;
  /** delete single row from the table: "patient_roster_files" */
  delete_patient_roster_files_by_pk?: Maybe<Patient_Roster_Files>;
  /** delete data from the table: "patient_segments" */
  delete_patient_segments?: Maybe<Patient_Segments_Mutation_Response>;
  /** delete single row from the table: "patient_segments" */
  delete_patient_segments_by_pk?: Maybe<Patient_Segments>;
  /** delete data from the table: "patients" */
  delete_patients?: Maybe<Patients_Mutation_Response>;
  /** delete data from the table: "patients_audit" */
  delete_patients_audit?: Maybe<Patients_Audit_Mutation_Response>;
  /** delete single row from the table: "patients" */
  delete_patients_by_pk?: Maybe<Patients>;
  /** delete data from the table: "practices" */
  delete_practices?: Maybe<Practices_Mutation_Response>;
  /** delete single row from the table: "practices" */
  delete_practices_by_pk?: Maybe<Practices>;
  /** delete data from the table: "provider_employments" */
  delete_provider_employments?: Maybe<Provider_Employments_Mutation_Response>;
  /** delete single row from the table: "provider_employments" */
  delete_provider_employments_by_pk?: Maybe<Provider_Employments>;
  /** delete data from the table: "provider_memberships" */
  delete_provider_memberships?: Maybe<Provider_Memberships_Mutation_Response>;
  /** delete single row from the table: "provider_memberships" */
  delete_provider_memberships_by_pk?: Maybe<Provider_Memberships>;
  /** delete data from the table: "providers" */
  delete_providers?: Maybe<Providers_Mutation_Response>;
  /** delete single row from the table: "providers" */
  delete_providers_by_pk?: Maybe<Providers>;
  /** delete data from the table: "pvas" */
  delete_pvas?: Maybe<Pvas_Mutation_Response>;
  /** delete data from the table: "pvas_audit" */
  delete_pvas_audit?: Maybe<Pvas_Audit_Mutation_Response>;
  /** delete single row from the table: "pvas" */
  delete_pvas_by_pk?: Maybe<Pvas>;
  /** delete data from the table: "satisfaction_surveys" */
  delete_satisfaction_surveys?: Maybe<Satisfaction_Surveys_Mutation_Response>;
  /** delete single row from the table: "satisfaction_surveys" */
  delete_satisfaction_surveys_by_pk?: Maybe<Satisfaction_Surveys>;
  /** delete data from the table: "scheduled_events" */
  delete_scheduled_events?: Maybe<Scheduled_Events_Mutation_Response>;
  /** delete data from the table: "sms_component_approvals" */
  delete_sms_component_approvals?: Maybe<Sms_Component_Approvals_Mutation_Response>;
  /** delete single row from the table: "sms_component_approvals" */
  delete_sms_component_approvals_by_pk?: Maybe<Sms_Component_Approvals>;
  /** delete data from the table: "sms_messages" */
  delete_sms_messages?: Maybe<Sms_Messages_Mutation_Response>;
  /** delete single row from the table: "sms_messages" */
  delete_sms_messages_by_pk?: Maybe<Sms_Messages>;
  /** delete data from the table: "sms_templates" */
  delete_sms_templates?: Maybe<Sms_Templates_Mutation_Response>;
  /** delete single row from the table: "sms_templates" */
  delete_sms_templates_by_pk?: Maybe<Sms_Templates>;
  /** delete data from the table: "users" */
  delete_users?: Maybe<Users_Mutation_Response>;
  /** delete data from the table: "users_audit" */
  delete_users_audit?: Maybe<Users_Audit_Mutation_Response>;
  /** delete single row from the table: "users" */
  delete_users_by_pk?: Maybe<Users>;
  /** perform the action: "disable_user" */
  disable_user?: Maybe<DisableUserOutput>;
  /** perform the action: "enable_user" */
  enable_user?: Maybe<EnableUserOutput>;
  /** insert data into the table: "account_creation_requests" */
  insert_account_creation_requests?: Maybe<Account_Creation_Requests_Mutation_Response>;
  /** insert a single row into the table: "account_creation_requests" */
  insert_account_creation_requests_one?: Maybe<Account_Creation_Requests>;
  /** insert data into the table: "alignments" */
  insert_alignments?: Maybe<Alignments_Mutation_Response>;
  /** insert a single row into the table: "alignments" */
  insert_alignments_one?: Maybe<Alignments>;
  /** insert data into the table: "business_associate_agreements" */
  insert_business_associate_agreements?: Maybe<Business_Associate_Agreements_Mutation_Response>;
  /** insert a single row into the table: "business_associate_agreements" */
  insert_business_associate_agreements_one?: Maybe<Business_Associate_Agreements>;
  /** insert data into the table: "campaign_approvals" */
  insert_campaign_approvals?: Maybe<Campaign_Approvals_Mutation_Response>;
  /** insert a single row into the table: "campaign_approvals" */
  insert_campaign_approvals_one?: Maybe<Campaign_Approvals>;
  /** insert data into the table: "campaign_email_components" */
  insert_campaign_email_components?: Maybe<Campaign_Email_Components_Mutation_Response>;
  /** insert a single row into the table: "campaign_email_components" */
  insert_campaign_email_components_one?: Maybe<Campaign_Email_Components>;
  /** insert data into the table: "campaign_letter_components" */
  insert_campaign_letter_components?: Maybe<Campaign_Letter_Components_Mutation_Response>;
  /** insert a single row into the table: "campaign_letter_components" */
  insert_campaign_letter_components_one?: Maybe<Campaign_Letter_Components>;
  /** insert data into the table: "campaign_sms_components" */
  insert_campaign_sms_components?: Maybe<Campaign_Sms_Components_Mutation_Response>;
  /** insert a single row into the table: "campaign_sms_components" */
  insert_campaign_sms_components_one?: Maybe<Campaign_Sms_Components>;
  /** insert data into the table: "campaign_templates" */
  insert_campaign_templates?: Maybe<Campaign_Templates_Mutation_Response>;
  /** insert a single row into the table: "campaign_templates" */
  insert_campaign_templates_one?: Maybe<Campaign_Templates>;
  /** insert data into the table: "client_call_records" */
  insert_client_call_records?: Maybe<Client_Call_Records_Mutation_Response>;
  /** insert a single row into the table: "client_call_records" */
  insert_client_call_records_one?: Maybe<Client_Call_Records>;
  /** insert data into the table: "cms_alignment_reports" */
  insert_cms_alignment_reports?: Maybe<Cms_Alignment_Reports_Mutation_Response>;
  /** insert a single row into the table: "cms_alignment_reports" */
  insert_cms_alignment_reports_one?: Maybe<Cms_Alignment_Reports>;
  /** insert data into the table: "cms_dce_participants" */
  insert_cms_dce_participants?: Maybe<Cms_Dce_Participants_Mutation_Response>;
  /** insert a single row into the table: "cms_dce_participants" */
  insert_cms_dce_participants_one?: Maybe<Cms_Dce_Participants>;
  /** insert data into the table: "cms_provider_alignment_reports" */
  insert_cms_provider_alignment_reports?: Maybe<Cms_Provider_Alignment_Reports_Mutation_Response>;
  /** insert a single row into the table: "cms_provider_alignment_reports" */
  insert_cms_provider_alignment_reports_one?: Maybe<Cms_Provider_Alignment_Reports>;
  /** insert data into the table: "cms_pva_responses" */
  insert_cms_pva_responses?: Maybe<Cms_Pva_Responses_Mutation_Response>;
  /** insert a single row into the table: "cms_pva_responses" */
  insert_cms_pva_responses_one?: Maybe<Cms_Pva_Responses>;
  /** insert data into the table: "component_tracking_ids" */
  insert_component_tracking_ids?: Maybe<Component_Tracking_Ids_Mutation_Response>;
  /** insert a single row into the table: "component_tracking_ids" */
  insert_component_tracking_ids_one?: Maybe<Component_Tracking_Ids>;
  /** insert data into the table: "daily_performance_stats" */
  insert_daily_performance_stats?: Maybe<Daily_Performance_Stats_Mutation_Response>;
  /** insert a single row into the table: "daily_performance_stats" */
  insert_daily_performance_stats_one?: Maybe<Daily_Performance_Stats>;
  /** insert data into the table: "email_component_approvals" */
  insert_email_component_approvals?: Maybe<Email_Component_Approvals_Mutation_Response>;
  /** insert a single row into the table: "email_component_approvals" */
  insert_email_component_approvals_one?: Maybe<Email_Component_Approvals>;
  /** insert data into the table: "email_templates" */
  insert_email_templates?: Maybe<Email_Templates_Mutation_Response>;
  /** insert a single row into the table: "email_templates" */
  insert_email_templates_one?: Maybe<Email_Templates>;
  /** insert data into the table: "emails" */
  insert_emails?: Maybe<Emails_Mutation_Response>;
  /** insert a single row into the table: "emails" */
  insert_emails_one?: Maybe<Emails>;
  /** insert data into the table: "file_uploads" */
  insert_file_uploads?: Maybe<File_Uploads_Mutation_Response>;
  /** insert a single row into the table: "file_uploads" */
  insert_file_uploads_one?: Maybe<File_Uploads>;
  /** insert data into the table: "health_equity_reports" */
  insert_health_equity_reports?: Maybe<Health_Equity_Reports_Mutation_Response>;
  /** insert data into the table: "health_equity_reports_audit" */
  insert_health_equity_reports_audit?: Maybe<Health_Equity_Reports_Audit_Mutation_Response>;
  /** insert a single row into the table: "health_equity_reports_audit" */
  insert_health_equity_reports_audit_one?: Maybe<Health_Equity_Reports_Audit>;
  /** insert a single row into the table: "health_equity_reports" */
  insert_health_equity_reports_one?: Maybe<Health_Equity_Reports>;
  /** insert data into the table: "hedr_submission_templates" */
  insert_hedr_submission_templates?: Maybe<Hedr_Submission_Templates_Mutation_Response>;
  /** insert a single row into the table: "hedr_submission_templates" */
  insert_hedr_submission_templates_one?: Maybe<Hedr_Submission_Templates>;
  /** insert data into the table: "insurance_segment_mappings" */
  insert_insurance_segment_mappings?: Maybe<Insurance_Segment_Mappings_Mutation_Response>;
  /** insert a single row into the table: "insurance_segment_mappings" */
  insert_insurance_segment_mappings_one?: Maybe<Insurance_Segment_Mappings>;
  /** insert data into the table: "letter_component_approvals" */
  insert_letter_component_approvals?: Maybe<Letter_Component_Approvals_Mutation_Response>;
  /** insert a single row into the table: "letter_component_approvals" */
  insert_letter_component_approvals_one?: Maybe<Letter_Component_Approvals>;
  /** insert data into the table: "letter_format_types" */
  insert_letter_format_types?: Maybe<Letter_Format_Types_Mutation_Response>;
  /** insert a single row into the table: "letter_format_types" */
  insert_letter_format_types_one?: Maybe<Letter_Format_Types>;
  /** insert data into the table: "letter_templates" */
  insert_letter_templates?: Maybe<Letter_Templates_Mutation_Response>;
  /** insert a single row into the table: "letter_templates" */
  insert_letter_templates_one?: Maybe<Letter_Templates>;
  /** insert data into the table: "letter_variable_values" */
  insert_letter_variable_values?: Maybe<Letter_Variable_Values_Mutation_Response>;
  /** insert a single row into the table: "letter_variable_values" */
  insert_letter_variable_values_one?: Maybe<Letter_Variable_Values>;
  /** insert data into the table: "letters" */
  insert_letters?: Maybe<Letters_Mutation_Response>;
  /** insert a single row into the table: "letters" */
  insert_letters_one?: Maybe<Letters>;
  /** insert data into the table: "networks" */
  insert_networks?: Maybe<Networks_Mutation_Response>;
  /** insert a single row into the table: "networks" */
  insert_networks_one?: Maybe<Networks>;
  /** insert data into the table: "organization_networks" */
  insert_organization_networks?: Maybe<Organization_Networks_Mutation_Response>;
  /** insert a single row into the table: "organization_networks" */
  insert_organization_networks_one?: Maybe<Organization_Networks>;
  /** insert data into the table: "organizations" */
  insert_organizations?: Maybe<Organizations_Mutation_Response>;
  /** insert a single row into the table: "organizations" */
  insert_organizations_one?: Maybe<Organizations>;
  /** insert data into the table: "patient_roster_files" */
  insert_patient_roster_files?: Maybe<Patient_Roster_Files_Mutation_Response>;
  /** insert a single row into the table: "patient_roster_files" */
  insert_patient_roster_files_one?: Maybe<Patient_Roster_Files>;
  /** insert data into the table: "patient_segments" */
  insert_patient_segments?: Maybe<Patient_Segments_Mutation_Response>;
  /** insert a single row into the table: "patient_segments" */
  insert_patient_segments_one?: Maybe<Patient_Segments>;
  /** insert data into the table: "patients" */
  insert_patients?: Maybe<Patients_Mutation_Response>;
  /** insert data into the table: "patients_audit" */
  insert_patients_audit?: Maybe<Patients_Audit_Mutation_Response>;
  /** insert a single row into the table: "patients_audit" */
  insert_patients_audit_one?: Maybe<Patients_Audit>;
  /** insert a single row into the table: "patients" */
  insert_patients_one?: Maybe<Patients>;
  /** insert data into the table: "practices" */
  insert_practices?: Maybe<Practices_Mutation_Response>;
  /** insert a single row into the table: "practices" */
  insert_practices_one?: Maybe<Practices>;
  /** insert data into the table: "provider_employments" */
  insert_provider_employments?: Maybe<Provider_Employments_Mutation_Response>;
  /** insert a single row into the table: "provider_employments" */
  insert_provider_employments_one?: Maybe<Provider_Employments>;
  /** insert data into the table: "provider_memberships" */
  insert_provider_memberships?: Maybe<Provider_Memberships_Mutation_Response>;
  /** insert a single row into the table: "provider_memberships" */
  insert_provider_memberships_one?: Maybe<Provider_Memberships>;
  /** insert data into the table: "providers" */
  insert_providers?: Maybe<Providers_Mutation_Response>;
  /** insert a single row into the table: "providers" */
  insert_providers_one?: Maybe<Providers>;
  /** insert data into the table: "pvas" */
  insert_pvas?: Maybe<Pvas_Mutation_Response>;
  /** insert data into the table: "pvas_audit" */
  insert_pvas_audit?: Maybe<Pvas_Audit_Mutation_Response>;
  /** insert a single row into the table: "pvas_audit" */
  insert_pvas_audit_one?: Maybe<Pvas_Audit>;
  /** insert a single row into the table: "pvas" */
  insert_pvas_one?: Maybe<Pvas>;
  /** insert data into the table: "satisfaction_surveys" */
  insert_satisfaction_surveys?: Maybe<Satisfaction_Surveys_Mutation_Response>;
  /** insert a single row into the table: "satisfaction_surveys" */
  insert_satisfaction_surveys_one?: Maybe<Satisfaction_Surveys>;
  /** insert data into the table: "scheduled_events" */
  insert_scheduled_events?: Maybe<Scheduled_Events_Mutation_Response>;
  /** insert a single row into the table: "scheduled_events" */
  insert_scheduled_events_one?: Maybe<Scheduled_Events>;
  /** insert data into the table: "sms_component_approvals" */
  insert_sms_component_approvals?: Maybe<Sms_Component_Approvals_Mutation_Response>;
  /** insert a single row into the table: "sms_component_approvals" */
  insert_sms_component_approvals_one?: Maybe<Sms_Component_Approvals>;
  /** insert data into the table: "sms_messages" */
  insert_sms_messages?: Maybe<Sms_Messages_Mutation_Response>;
  /** insert a single row into the table: "sms_messages" */
  insert_sms_messages_one?: Maybe<Sms_Messages>;
  /** insert data into the table: "sms_templates" */
  insert_sms_templates?: Maybe<Sms_Templates_Mutation_Response>;
  /** insert a single row into the table: "sms_templates" */
  insert_sms_templates_one?: Maybe<Sms_Templates>;
  /** insert data into the table: "users" */
  insert_users?: Maybe<Users_Mutation_Response>;
  /** insert data into the table: "users_audit" */
  insert_users_audit?: Maybe<Users_Audit_Mutation_Response>;
  /** insert a single row into the table: "users_audit" */
  insert_users_audit_one?: Maybe<Users_Audit>;
  /** insert a single row into the table: "users" */
  insert_users_one?: Maybe<Users>;
  /** perform the action: "resend_user_invite" */
  resend_user_invite?: Maybe<CreateUserOutput>;
  /** perform the action: "send_baa" */
  send_baa?: Maybe<SendBaaOutput>;
  /** update data of the table: "account_creation_requests" */
  update_account_creation_requests?: Maybe<Account_Creation_Requests_Mutation_Response>;
  /** update single row of the table: "account_creation_requests" */
  update_account_creation_requests_by_pk?: Maybe<Account_Creation_Requests>;
  /** update data of the table: "alignments" */
  update_alignments?: Maybe<Alignments_Mutation_Response>;
  /** update single row of the table: "alignments" */
  update_alignments_by_pk?: Maybe<Alignments>;
  /** update data of the table: "business_associate_agreements" */
  update_business_associate_agreements?: Maybe<Business_Associate_Agreements_Mutation_Response>;
  /** update single row of the table: "business_associate_agreements" */
  update_business_associate_agreements_by_pk?: Maybe<Business_Associate_Agreements>;
  /** update data of the table: "campaign_approvals" */
  update_campaign_approvals?: Maybe<Campaign_Approvals_Mutation_Response>;
  /** update single row of the table: "campaign_approvals" */
  update_campaign_approvals_by_pk?: Maybe<Campaign_Approvals>;
  /** update data of the table: "campaign_email_components" */
  update_campaign_email_components?: Maybe<Campaign_Email_Components_Mutation_Response>;
  /** update single row of the table: "campaign_email_components" */
  update_campaign_email_components_by_pk?: Maybe<Campaign_Email_Components>;
  /** update data of the table: "campaign_letter_components" */
  update_campaign_letter_components?: Maybe<Campaign_Letter_Components_Mutation_Response>;
  /** update single row of the table: "campaign_letter_components" */
  update_campaign_letter_components_by_pk?: Maybe<Campaign_Letter_Components>;
  /** update data of the table: "campaign_sms_components" */
  update_campaign_sms_components?: Maybe<Campaign_Sms_Components_Mutation_Response>;
  /** update single row of the table: "campaign_sms_components" */
  update_campaign_sms_components_by_pk?: Maybe<Campaign_Sms_Components>;
  /** update data of the table: "campaign_templates" */
  update_campaign_templates?: Maybe<Campaign_Templates_Mutation_Response>;
  /** update single row of the table: "campaign_templates" */
  update_campaign_templates_by_pk?: Maybe<Campaign_Templates>;
  /** update data of the table: "client_call_records" */
  update_client_call_records?: Maybe<Client_Call_Records_Mutation_Response>;
  /** update single row of the table: "client_call_records" */
  update_client_call_records_by_pk?: Maybe<Client_Call_Records>;
  /** update data of the table: "cms_alignment_reports" */
  update_cms_alignment_reports?: Maybe<Cms_Alignment_Reports_Mutation_Response>;
  /** update single row of the table: "cms_alignment_reports" */
  update_cms_alignment_reports_by_pk?: Maybe<Cms_Alignment_Reports>;
  /** update data of the table: "cms_dce_participants" */
  update_cms_dce_participants?: Maybe<Cms_Dce_Participants_Mutation_Response>;
  /** update data of the table: "cms_provider_alignment_reports" */
  update_cms_provider_alignment_reports?: Maybe<Cms_Provider_Alignment_Reports_Mutation_Response>;
  /** update single row of the table: "cms_provider_alignment_reports" */
  update_cms_provider_alignment_reports_by_pk?: Maybe<Cms_Provider_Alignment_Reports>;
  /** update data of the table: "cms_pva_responses" */
  update_cms_pva_responses?: Maybe<Cms_Pva_Responses_Mutation_Response>;
  /** update single row of the table: "cms_pva_responses" */
  update_cms_pva_responses_by_pk?: Maybe<Cms_Pva_Responses>;
  /** update data of the table: "component_tracking_ids" */
  update_component_tracking_ids?: Maybe<Component_Tracking_Ids_Mutation_Response>;
  /** update single row of the table: "component_tracking_ids" */
  update_component_tracking_ids_by_pk?: Maybe<Component_Tracking_Ids>;
  /** update data of the table: "daily_performance_stats" */
  update_daily_performance_stats?: Maybe<Daily_Performance_Stats_Mutation_Response>;
  /** update single row of the table: "daily_performance_stats" */
  update_daily_performance_stats_by_pk?: Maybe<Daily_Performance_Stats>;
  /** update data of the table: "email_component_approvals" */
  update_email_component_approvals?: Maybe<Email_Component_Approvals_Mutation_Response>;
  /** update single row of the table: "email_component_approvals" */
  update_email_component_approvals_by_pk?: Maybe<Email_Component_Approvals>;
  /** update data of the table: "email_templates" */
  update_email_templates?: Maybe<Email_Templates_Mutation_Response>;
  /** update single row of the table: "email_templates" */
  update_email_templates_by_pk?: Maybe<Email_Templates>;
  /** update data of the table: "emails" */
  update_emails?: Maybe<Emails_Mutation_Response>;
  /** update single row of the table: "emails" */
  update_emails_by_pk?: Maybe<Emails>;
  /** update data of the table: "file_uploads" */
  update_file_uploads?: Maybe<File_Uploads_Mutation_Response>;
  /** update single row of the table: "file_uploads" */
  update_file_uploads_by_pk?: Maybe<File_Uploads>;
  /** update data of the table: "health_equity_reports" */
  update_health_equity_reports?: Maybe<Health_Equity_Reports_Mutation_Response>;
  /** update data of the table: "health_equity_reports_audit" */
  update_health_equity_reports_audit?: Maybe<Health_Equity_Reports_Audit_Mutation_Response>;
  /** update single row of the table: "health_equity_reports" */
  update_health_equity_reports_by_pk?: Maybe<Health_Equity_Reports>;
  /** update data of the table: "hedr_submission_templates" */
  update_hedr_submission_templates?: Maybe<Hedr_Submission_Templates_Mutation_Response>;
  /** update single row of the table: "hedr_submission_templates" */
  update_hedr_submission_templates_by_pk?: Maybe<Hedr_Submission_Templates>;
  /** update data of the table: "insurance_segment_mappings" */
  update_insurance_segment_mappings?: Maybe<Insurance_Segment_Mappings_Mutation_Response>;
  /** update single row of the table: "insurance_segment_mappings" */
  update_insurance_segment_mappings_by_pk?: Maybe<Insurance_Segment_Mappings>;
  /** update data of the table: "letter_component_approvals" */
  update_letter_component_approvals?: Maybe<Letter_Component_Approvals_Mutation_Response>;
  /** update single row of the table: "letter_component_approvals" */
  update_letter_component_approvals_by_pk?: Maybe<Letter_Component_Approvals>;
  /** update data of the table: "letter_format_types" */
  update_letter_format_types?: Maybe<Letter_Format_Types_Mutation_Response>;
  /** update single row of the table: "letter_format_types" */
  update_letter_format_types_by_pk?: Maybe<Letter_Format_Types>;
  /** update data of the table: "letter_templates" */
  update_letter_templates?: Maybe<Letter_Templates_Mutation_Response>;
  /** update single row of the table: "letter_templates" */
  update_letter_templates_by_pk?: Maybe<Letter_Templates>;
  /** update data of the table: "letter_variable_values" */
  update_letter_variable_values?: Maybe<Letter_Variable_Values_Mutation_Response>;
  /** update single row of the table: "letter_variable_values" */
  update_letter_variable_values_by_pk?: Maybe<Letter_Variable_Values>;
  /** update data of the table: "letters" */
  update_letters?: Maybe<Letters_Mutation_Response>;
  /** update single row of the table: "letters" */
  update_letters_by_pk?: Maybe<Letters>;
  /** update data of the table: "networks" */
  update_networks?: Maybe<Networks_Mutation_Response>;
  /** update single row of the table: "networks" */
  update_networks_by_pk?: Maybe<Networks>;
  /** update data of the table: "organization_networks" */
  update_organization_networks?: Maybe<Organization_Networks_Mutation_Response>;
  /** update single row of the table: "organization_networks" */
  update_organization_networks_by_pk?: Maybe<Organization_Networks>;
  /** update data of the table: "organizations" */
  update_organizations?: Maybe<Organizations_Mutation_Response>;
  /** update single row of the table: "organizations" */
  update_organizations_by_pk?: Maybe<Organizations>;
  /** update data of the table: "patient_roster_files" */
  update_patient_roster_files?: Maybe<Patient_Roster_Files_Mutation_Response>;
  /** update single row of the table: "patient_roster_files" */
  update_patient_roster_files_by_pk?: Maybe<Patient_Roster_Files>;
  /** update data of the table: "patient_segments" */
  update_patient_segments?: Maybe<Patient_Segments_Mutation_Response>;
  /** update single row of the table: "patient_segments" */
  update_patient_segments_by_pk?: Maybe<Patient_Segments>;
  /** update data of the table: "patients" */
  update_patients?: Maybe<Patients_Mutation_Response>;
  /** update data of the table: "patients_audit" */
  update_patients_audit?: Maybe<Patients_Audit_Mutation_Response>;
  /** update single row of the table: "patients" */
  update_patients_by_pk?: Maybe<Patients>;
  /** update data of the table: "practices" */
  update_practices?: Maybe<Practices_Mutation_Response>;
  /** update single row of the table: "practices" */
  update_practices_by_pk?: Maybe<Practices>;
  /** update data of the table: "provider_employments" */
  update_provider_employments?: Maybe<Provider_Employments_Mutation_Response>;
  /** update single row of the table: "provider_employments" */
  update_provider_employments_by_pk?: Maybe<Provider_Employments>;
  /** update data of the table: "provider_memberships" */
  update_provider_memberships?: Maybe<Provider_Memberships_Mutation_Response>;
  /** update single row of the table: "provider_memberships" */
  update_provider_memberships_by_pk?: Maybe<Provider_Memberships>;
  /** update data of the table: "providers" */
  update_providers?: Maybe<Providers_Mutation_Response>;
  /** update single row of the table: "providers" */
  update_providers_by_pk?: Maybe<Providers>;
  /** update data of the table: "pvas" */
  update_pvas?: Maybe<Pvas_Mutation_Response>;
  /** update data of the table: "pvas_audit" */
  update_pvas_audit?: Maybe<Pvas_Audit_Mutation_Response>;
  /** update single row of the table: "pvas" */
  update_pvas_by_pk?: Maybe<Pvas>;
  /** update data of the table: "satisfaction_surveys" */
  update_satisfaction_surveys?: Maybe<Satisfaction_Surveys_Mutation_Response>;
  /** update single row of the table: "satisfaction_surveys" */
  update_satisfaction_surveys_by_pk?: Maybe<Satisfaction_Surveys>;
  /** update data of the table: "scheduled_events" */
  update_scheduled_events?: Maybe<Scheduled_Events_Mutation_Response>;
  /** update data of the table: "sms_component_approvals" */
  update_sms_component_approvals?: Maybe<Sms_Component_Approvals_Mutation_Response>;
  /** update single row of the table: "sms_component_approvals" */
  update_sms_component_approvals_by_pk?: Maybe<Sms_Component_Approvals>;
  /** update data of the table: "sms_messages" */
  update_sms_messages?: Maybe<Sms_Messages_Mutation_Response>;
  /** update single row of the table: "sms_messages" */
  update_sms_messages_by_pk?: Maybe<Sms_Messages>;
  /** update data of the table: "sms_templates" */
  update_sms_templates?: Maybe<Sms_Templates_Mutation_Response>;
  /** update single row of the table: "sms_templates" */
  update_sms_templates_by_pk?: Maybe<Sms_Templates>;
  /** perform the action: "update_user" */
  update_user?: Maybe<UpdateUserOutput>;
  /** update data of the table: "users" */
  update_users?: Maybe<Users_Mutation_Response>;
  /** update data of the table: "users_audit" */
  update_users_audit?: Maybe<Users_Audit_Mutation_Response>;
  /** update single row of the table: "users" */
  update_users_by_pk?: Maybe<Users>;
};


/** mutation root */
export type Mutation_RootCreate_UserArgs = {
  is_admin?: Maybe<Scalars['Boolean']>;
  network_id?: Maybe<Scalars['String']>;
  organization_id?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  practice_id?: Maybe<Scalars['String']>;
  user_email: Scalars['String'];
  user_name: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Account_Creation_RequestsArgs = {
  where: Account_Creation_Requests_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Account_Creation_Requests_By_PkArgs = {
  account_creation_request_id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_AlignmentsArgs = {
  where: Alignments_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Alignments_By_PkArgs = {
  alignment_id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Business_Associate_AgreementsArgs = {
  where: Business_Associate_Agreements_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Business_Associate_Agreements_By_PkArgs = {
  external_id: Scalars['String'];
  external_source: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Campaign_ApprovalsArgs = {
  where: Campaign_Approvals_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Campaign_Approvals_By_PkArgs = {
  campaign_template_id: Scalars['String'];
  practice_id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Campaign_Email_ComponentsArgs = {
  where: Campaign_Email_Components_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Campaign_Email_Components_By_PkArgs = {
  campaign_email_component_id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Campaign_Letter_ComponentsArgs = {
  where: Campaign_Letter_Components_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Campaign_Letter_Components_By_PkArgs = {
  campaign_letter_component_id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Campaign_Sms_ComponentsArgs = {
  where: Campaign_Sms_Components_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Campaign_Sms_Components_By_PkArgs = {
  campaign_sms_component_id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Campaign_TemplatesArgs = {
  where: Campaign_Templates_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Campaign_Templates_By_PkArgs = {
  campaign_template_id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Client_Call_RecordsArgs = {
  where: Client_Call_Records_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Client_Call_Records_By_PkArgs = {
  call_start_time: Scalars['timestamp'];
  caller_number: Scalars['String'];
  dialed_number: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Cms_Alignment_ReportsArgs = {
  where: Cms_Alignment_Reports_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Cms_Alignment_Reports_By_PkArgs = {
  beneficiary_mbi_id: Scalars['String'];
  dc_id: Scalars['String'];
  performance_year: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Cms_Dce_ParticipantsArgs = {
  where: Cms_Dce_Participants_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Cms_Provider_Alignment_ReportsArgs = {
  where: Cms_Provider_Alignment_Reports_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Cms_Provider_Alignment_Reports_By_PkArgs = {
  ACO_ID: Scalars['String'];
  ALGN_TYPE_CLM: Scalars['String'];
  FAC_PRVDR_OSCAR_NUM: Scalars['String'];
  MBI_ID: Scalars['String'];
  PERFORMANCE_YEAR: Scalars['Int'];
  PRVDR_NPI: Scalars['String'];
  PRVDR_TIN: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Cms_Pva_ResponsesArgs = {
  where: Cms_Pva_Responses_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Cms_Pva_Responses_By_PkArgs = {
  DCE_ID: Scalars['String'];
  ID_RECEIVED: Scalars['String'];
  IND_NPI: Scalars['String'];
  IND_TIN: Scalars['String'];
  SIGNATURE_DATE: Scalars['date'];
  SUBMISSION_DATE: Scalars['date'];
};


/** mutation root */
export type Mutation_RootDelete_Component_Tracking_IdsArgs = {
  where: Component_Tracking_Ids_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Component_Tracking_Ids_By_PkArgs = {
  component_id: Scalars['String'];
  tracking_id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Daily_Performance_StatsArgs = {
  where: Daily_Performance_Stats_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Daily_Performance_Stats_By_PkArgs = {
  alliance_name: Scalars['String'];
  report_date: Scalars['date'];
};


/** mutation root */
export type Mutation_RootDelete_Email_Component_ApprovalsArgs = {
  where: Email_Component_Approvals_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Email_Component_Approvals_By_PkArgs = {
  campaign_email_component_id: Scalars['String'];
  campaign_template_id: Scalars['String'];
  practice_id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Email_TemplatesArgs = {
  where: Email_Templates_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Email_Templates_By_PkArgs = {
  email_template_id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_EmailsArgs = {
  where: Emails_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Emails_By_PkArgs = {
  email_id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_File_UploadsArgs = {
  where: File_Uploads_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_File_Uploads_By_PkArgs = {
  file_upload_id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Health_Equity_ReportsArgs = {
  where: Health_Equity_Reports_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Health_Equity_Reports_AuditArgs = {
  where: Health_Equity_Reports_Audit_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Health_Equity_Reports_By_PkArgs = {
  health_equity_report_id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Hedr_Submission_TemplatesArgs = {
  where: Hedr_Submission_Templates_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Hedr_Submission_Templates_By_PkArgs = {
  entity_id: Scalars['String'];
  mbi: Scalars['String'];
  performance_year: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Insurance_Segment_MappingsArgs = {
  where: Insurance_Segment_Mappings_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Insurance_Segment_Mappings_By_PkArgs = {
  primary_insurance_is_null: Scalars['Boolean'];
  primary_insurance_payer_name: Scalars['String'];
  secondary_insurance_is_null: Scalars['Boolean'];
  secondary_insurance_payer_name: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Letter_Component_ApprovalsArgs = {
  where: Letter_Component_Approvals_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Letter_Component_Approvals_By_PkArgs = {
  campaign_letter_component_id: Scalars['String'];
  campaign_template_id: Scalars['String'];
  practice_id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Letter_Format_TypesArgs = {
  where: Letter_Format_Types_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Letter_Format_Types_By_PkArgs = {
  letter_format_type_id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Letter_TemplatesArgs = {
  where: Letter_Templates_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Letter_Templates_By_PkArgs = {
  letter_template_id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Letter_Variable_ValuesArgs = {
  where: Letter_Variable_Values_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Letter_Variable_Values_By_PkArgs = {
  key: Scalars['String'];
  letter_id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_LettersArgs = {
  where: Letters_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Letters_By_PkArgs = {
  letter_id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_NetworksArgs = {
  where: Networks_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Networks_By_PkArgs = {
  network_id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Organization_NetworksArgs = {
  where: Organization_Networks_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Organization_Networks_By_PkArgs = {
  network_id: Scalars['String'];
  organization_id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_OrganizationsArgs = {
  where: Organizations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Organizations_By_PkArgs = {
  organization_id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Patient_Roster_FilesArgs = {
  where: Patient_Roster_Files_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Patient_Roster_Files_By_PkArgs = {
  identity_id: Scalars['String'];
  key: Scalars['String'];
  level: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Patient_SegmentsArgs = {
  where: Patient_Segments_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Patient_Segments_By_PkArgs = {
  patient_segment_id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_PatientsArgs = {
  where: Patients_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Patients_AuditArgs = {
  where: Patients_Audit_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Patients_By_PkArgs = {
  patient_id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_PracticesArgs = {
  where: Practices_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Practices_By_PkArgs = {
  practice_id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Provider_EmploymentsArgs = {
  where: Provider_Employments_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Provider_Employments_By_PkArgs = {
  practice_id: Scalars['String'];
  provider_id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Provider_MembershipsArgs = {
  where: Provider_Memberships_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Provider_Memberships_By_PkArgs = {
  network_id: Scalars['String'];
  provider_id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_ProvidersArgs = {
  where: Providers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Providers_By_PkArgs = {
  provider_id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_PvasArgs = {
  where: Pvas_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Pvas_AuditArgs = {
  where: Pvas_Audit_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Pvas_By_PkArgs = {
  pva_id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Satisfaction_SurveysArgs = {
  where: Satisfaction_Surveys_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Satisfaction_Surveys_By_PkArgs = {
  satisfaction_survey_id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Scheduled_EventsArgs = {
  where: Scheduled_Events_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Sms_Component_ApprovalsArgs = {
  where: Sms_Component_Approvals_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Sms_Component_Approvals_By_PkArgs = {
  campaign_sms_component_id: Scalars['String'];
  campaign_template_id: Scalars['String'];
  practice_id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Sms_MessagesArgs = {
  where: Sms_Messages_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Sms_Messages_By_PkArgs = {
  sms_message_id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Sms_TemplatesArgs = {
  where: Sms_Templates_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Sms_Templates_By_PkArgs = {
  sms_template_id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_UsersArgs = {
  where: Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Users_AuditArgs = {
  where: Users_Audit_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Users_By_PkArgs = {
  user_id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDisable_UserArgs = {
  user_id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootEnable_UserArgs = {
  user_id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootInsert_Account_Creation_RequestsArgs = {
  objects: Array<Account_Creation_Requests_Insert_Input>;
  on_conflict?: Maybe<Account_Creation_Requests_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Account_Creation_Requests_OneArgs = {
  object: Account_Creation_Requests_Insert_Input;
  on_conflict?: Maybe<Account_Creation_Requests_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_AlignmentsArgs = {
  objects: Array<Alignments_Insert_Input>;
  on_conflict?: Maybe<Alignments_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Alignments_OneArgs = {
  object: Alignments_Insert_Input;
  on_conflict?: Maybe<Alignments_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Business_Associate_AgreementsArgs = {
  objects: Array<Business_Associate_Agreements_Insert_Input>;
  on_conflict?: Maybe<Business_Associate_Agreements_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Business_Associate_Agreements_OneArgs = {
  object: Business_Associate_Agreements_Insert_Input;
  on_conflict?: Maybe<Business_Associate_Agreements_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Campaign_ApprovalsArgs = {
  objects: Array<Campaign_Approvals_Insert_Input>;
  on_conflict?: Maybe<Campaign_Approvals_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Campaign_Approvals_OneArgs = {
  object: Campaign_Approvals_Insert_Input;
  on_conflict?: Maybe<Campaign_Approvals_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Campaign_Email_ComponentsArgs = {
  objects: Array<Campaign_Email_Components_Insert_Input>;
  on_conflict?: Maybe<Campaign_Email_Components_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Campaign_Email_Components_OneArgs = {
  object: Campaign_Email_Components_Insert_Input;
  on_conflict?: Maybe<Campaign_Email_Components_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Campaign_Letter_ComponentsArgs = {
  objects: Array<Campaign_Letter_Components_Insert_Input>;
  on_conflict?: Maybe<Campaign_Letter_Components_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Campaign_Letter_Components_OneArgs = {
  object: Campaign_Letter_Components_Insert_Input;
  on_conflict?: Maybe<Campaign_Letter_Components_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Campaign_Sms_ComponentsArgs = {
  objects: Array<Campaign_Sms_Components_Insert_Input>;
  on_conflict?: Maybe<Campaign_Sms_Components_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Campaign_Sms_Components_OneArgs = {
  object: Campaign_Sms_Components_Insert_Input;
  on_conflict?: Maybe<Campaign_Sms_Components_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Campaign_TemplatesArgs = {
  objects: Array<Campaign_Templates_Insert_Input>;
  on_conflict?: Maybe<Campaign_Templates_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Campaign_Templates_OneArgs = {
  object: Campaign_Templates_Insert_Input;
  on_conflict?: Maybe<Campaign_Templates_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Client_Call_RecordsArgs = {
  objects: Array<Client_Call_Records_Insert_Input>;
  on_conflict?: Maybe<Client_Call_Records_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Client_Call_Records_OneArgs = {
  object: Client_Call_Records_Insert_Input;
  on_conflict?: Maybe<Client_Call_Records_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Cms_Alignment_ReportsArgs = {
  objects: Array<Cms_Alignment_Reports_Insert_Input>;
  on_conflict?: Maybe<Cms_Alignment_Reports_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Cms_Alignment_Reports_OneArgs = {
  object: Cms_Alignment_Reports_Insert_Input;
  on_conflict?: Maybe<Cms_Alignment_Reports_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Cms_Dce_ParticipantsArgs = {
  objects: Array<Cms_Dce_Participants_Insert_Input>;
  on_conflict?: Maybe<Cms_Dce_Participants_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Cms_Dce_Participants_OneArgs = {
  object: Cms_Dce_Participants_Insert_Input;
  on_conflict?: Maybe<Cms_Dce_Participants_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Cms_Provider_Alignment_ReportsArgs = {
  objects: Array<Cms_Provider_Alignment_Reports_Insert_Input>;
  on_conflict?: Maybe<Cms_Provider_Alignment_Reports_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Cms_Provider_Alignment_Reports_OneArgs = {
  object: Cms_Provider_Alignment_Reports_Insert_Input;
  on_conflict?: Maybe<Cms_Provider_Alignment_Reports_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Cms_Pva_ResponsesArgs = {
  objects: Array<Cms_Pva_Responses_Insert_Input>;
  on_conflict?: Maybe<Cms_Pva_Responses_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Cms_Pva_Responses_OneArgs = {
  object: Cms_Pva_Responses_Insert_Input;
  on_conflict?: Maybe<Cms_Pva_Responses_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Component_Tracking_IdsArgs = {
  objects: Array<Component_Tracking_Ids_Insert_Input>;
  on_conflict?: Maybe<Component_Tracking_Ids_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Component_Tracking_Ids_OneArgs = {
  object: Component_Tracking_Ids_Insert_Input;
  on_conflict?: Maybe<Component_Tracking_Ids_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Daily_Performance_StatsArgs = {
  objects: Array<Daily_Performance_Stats_Insert_Input>;
  on_conflict?: Maybe<Daily_Performance_Stats_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Daily_Performance_Stats_OneArgs = {
  object: Daily_Performance_Stats_Insert_Input;
  on_conflict?: Maybe<Daily_Performance_Stats_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Email_Component_ApprovalsArgs = {
  objects: Array<Email_Component_Approvals_Insert_Input>;
  on_conflict?: Maybe<Email_Component_Approvals_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Email_Component_Approvals_OneArgs = {
  object: Email_Component_Approvals_Insert_Input;
  on_conflict?: Maybe<Email_Component_Approvals_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Email_TemplatesArgs = {
  objects: Array<Email_Templates_Insert_Input>;
  on_conflict?: Maybe<Email_Templates_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Email_Templates_OneArgs = {
  object: Email_Templates_Insert_Input;
  on_conflict?: Maybe<Email_Templates_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_EmailsArgs = {
  objects: Array<Emails_Insert_Input>;
  on_conflict?: Maybe<Emails_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Emails_OneArgs = {
  object: Emails_Insert_Input;
  on_conflict?: Maybe<Emails_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_File_UploadsArgs = {
  objects: Array<File_Uploads_Insert_Input>;
  on_conflict?: Maybe<File_Uploads_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_File_Uploads_OneArgs = {
  object: File_Uploads_Insert_Input;
  on_conflict?: Maybe<File_Uploads_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Health_Equity_ReportsArgs = {
  objects: Array<Health_Equity_Reports_Insert_Input>;
  on_conflict?: Maybe<Health_Equity_Reports_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Health_Equity_Reports_AuditArgs = {
  objects: Array<Health_Equity_Reports_Audit_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Health_Equity_Reports_Audit_OneArgs = {
  object: Health_Equity_Reports_Audit_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Health_Equity_Reports_OneArgs = {
  object: Health_Equity_Reports_Insert_Input;
  on_conflict?: Maybe<Health_Equity_Reports_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Hedr_Submission_TemplatesArgs = {
  objects: Array<Hedr_Submission_Templates_Insert_Input>;
  on_conflict?: Maybe<Hedr_Submission_Templates_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Hedr_Submission_Templates_OneArgs = {
  object: Hedr_Submission_Templates_Insert_Input;
  on_conflict?: Maybe<Hedr_Submission_Templates_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Insurance_Segment_MappingsArgs = {
  objects: Array<Insurance_Segment_Mappings_Insert_Input>;
  on_conflict?: Maybe<Insurance_Segment_Mappings_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Insurance_Segment_Mappings_OneArgs = {
  object: Insurance_Segment_Mappings_Insert_Input;
  on_conflict?: Maybe<Insurance_Segment_Mappings_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Letter_Component_ApprovalsArgs = {
  objects: Array<Letter_Component_Approvals_Insert_Input>;
  on_conflict?: Maybe<Letter_Component_Approvals_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Letter_Component_Approvals_OneArgs = {
  object: Letter_Component_Approvals_Insert_Input;
  on_conflict?: Maybe<Letter_Component_Approvals_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Letter_Format_TypesArgs = {
  objects: Array<Letter_Format_Types_Insert_Input>;
  on_conflict?: Maybe<Letter_Format_Types_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Letter_Format_Types_OneArgs = {
  object: Letter_Format_Types_Insert_Input;
  on_conflict?: Maybe<Letter_Format_Types_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Letter_TemplatesArgs = {
  objects: Array<Letter_Templates_Insert_Input>;
  on_conflict?: Maybe<Letter_Templates_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Letter_Templates_OneArgs = {
  object: Letter_Templates_Insert_Input;
  on_conflict?: Maybe<Letter_Templates_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Letter_Variable_ValuesArgs = {
  objects: Array<Letter_Variable_Values_Insert_Input>;
  on_conflict?: Maybe<Letter_Variable_Values_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Letter_Variable_Values_OneArgs = {
  object: Letter_Variable_Values_Insert_Input;
  on_conflict?: Maybe<Letter_Variable_Values_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_LettersArgs = {
  objects: Array<Letters_Insert_Input>;
  on_conflict?: Maybe<Letters_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Letters_OneArgs = {
  object: Letters_Insert_Input;
  on_conflict?: Maybe<Letters_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_NetworksArgs = {
  objects: Array<Networks_Insert_Input>;
  on_conflict?: Maybe<Networks_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Networks_OneArgs = {
  object: Networks_Insert_Input;
  on_conflict?: Maybe<Networks_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Organization_NetworksArgs = {
  objects: Array<Organization_Networks_Insert_Input>;
  on_conflict?: Maybe<Organization_Networks_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Organization_Networks_OneArgs = {
  object: Organization_Networks_Insert_Input;
  on_conflict?: Maybe<Organization_Networks_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_OrganizationsArgs = {
  objects: Array<Organizations_Insert_Input>;
  on_conflict?: Maybe<Organizations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Organizations_OneArgs = {
  object: Organizations_Insert_Input;
  on_conflict?: Maybe<Organizations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Patient_Roster_FilesArgs = {
  objects: Array<Patient_Roster_Files_Insert_Input>;
  on_conflict?: Maybe<Patient_Roster_Files_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Patient_Roster_Files_OneArgs = {
  object: Patient_Roster_Files_Insert_Input;
  on_conflict?: Maybe<Patient_Roster_Files_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Patient_SegmentsArgs = {
  objects: Array<Patient_Segments_Insert_Input>;
  on_conflict?: Maybe<Patient_Segments_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Patient_Segments_OneArgs = {
  object: Patient_Segments_Insert_Input;
  on_conflict?: Maybe<Patient_Segments_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_PatientsArgs = {
  objects: Array<Patients_Insert_Input>;
  on_conflict?: Maybe<Patients_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Patients_AuditArgs = {
  objects: Array<Patients_Audit_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Patients_Audit_OneArgs = {
  object: Patients_Audit_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Patients_OneArgs = {
  object: Patients_Insert_Input;
  on_conflict?: Maybe<Patients_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_PracticesArgs = {
  objects: Array<Practices_Insert_Input>;
  on_conflict?: Maybe<Practices_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Practices_OneArgs = {
  object: Practices_Insert_Input;
  on_conflict?: Maybe<Practices_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Provider_EmploymentsArgs = {
  objects: Array<Provider_Employments_Insert_Input>;
  on_conflict?: Maybe<Provider_Employments_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Provider_Employments_OneArgs = {
  object: Provider_Employments_Insert_Input;
  on_conflict?: Maybe<Provider_Employments_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Provider_MembershipsArgs = {
  objects: Array<Provider_Memberships_Insert_Input>;
  on_conflict?: Maybe<Provider_Memberships_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Provider_Memberships_OneArgs = {
  object: Provider_Memberships_Insert_Input;
  on_conflict?: Maybe<Provider_Memberships_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ProvidersArgs = {
  objects: Array<Providers_Insert_Input>;
  on_conflict?: Maybe<Providers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Providers_OneArgs = {
  object: Providers_Insert_Input;
  on_conflict?: Maybe<Providers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_PvasArgs = {
  objects: Array<Pvas_Insert_Input>;
  on_conflict?: Maybe<Pvas_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Pvas_AuditArgs = {
  objects: Array<Pvas_Audit_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Pvas_Audit_OneArgs = {
  object: Pvas_Audit_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Pvas_OneArgs = {
  object: Pvas_Insert_Input;
  on_conflict?: Maybe<Pvas_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Satisfaction_SurveysArgs = {
  objects: Array<Satisfaction_Surveys_Insert_Input>;
  on_conflict?: Maybe<Satisfaction_Surveys_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Satisfaction_Surveys_OneArgs = {
  object: Satisfaction_Surveys_Insert_Input;
  on_conflict?: Maybe<Satisfaction_Surveys_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Scheduled_EventsArgs = {
  objects: Array<Scheduled_Events_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Scheduled_Events_OneArgs = {
  object: Scheduled_Events_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Sms_Component_ApprovalsArgs = {
  objects: Array<Sms_Component_Approvals_Insert_Input>;
  on_conflict?: Maybe<Sms_Component_Approvals_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Sms_Component_Approvals_OneArgs = {
  object: Sms_Component_Approvals_Insert_Input;
  on_conflict?: Maybe<Sms_Component_Approvals_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Sms_MessagesArgs = {
  objects: Array<Sms_Messages_Insert_Input>;
  on_conflict?: Maybe<Sms_Messages_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Sms_Messages_OneArgs = {
  object: Sms_Messages_Insert_Input;
  on_conflict?: Maybe<Sms_Messages_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Sms_TemplatesArgs = {
  objects: Array<Sms_Templates_Insert_Input>;
  on_conflict?: Maybe<Sms_Templates_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Sms_Templates_OneArgs = {
  object: Sms_Templates_Insert_Input;
  on_conflict?: Maybe<Sms_Templates_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_UsersArgs = {
  objects: Array<Users_Insert_Input>;
  on_conflict?: Maybe<Users_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Users_AuditArgs = {
  objects: Array<Users_Audit_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Users_Audit_OneArgs = {
  object: Users_Audit_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Users_OneArgs = {
  object: Users_Insert_Input;
  on_conflict?: Maybe<Users_On_Conflict>;
};


/** mutation root */
export type Mutation_RootResend_User_InviteArgs = {
  user_email: Scalars['String'];
};


/** mutation root */
export type Mutation_RootSend_BaaArgs = {
  practice_id: Scalars['String'];
  recipient_email: Scalars['String'];
  recipient_name?: Maybe<Scalars['String']>;
  template_vars?: Maybe<Scalars['jsonb']>;
};


/** mutation root */
export type Mutation_RootUpdate_Account_Creation_RequestsArgs = {
  _set?: Maybe<Account_Creation_Requests_Set_Input>;
  where: Account_Creation_Requests_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Account_Creation_Requests_By_PkArgs = {
  _set?: Maybe<Account_Creation_Requests_Set_Input>;
  pk_columns: Account_Creation_Requests_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_AlignmentsArgs = {
  _set?: Maybe<Alignments_Set_Input>;
  where: Alignments_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Alignments_By_PkArgs = {
  _set?: Maybe<Alignments_Set_Input>;
  pk_columns: Alignments_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Business_Associate_AgreementsArgs = {
  _append?: Maybe<Business_Associate_Agreements_Append_Input>;
  _delete_at_path?: Maybe<Business_Associate_Agreements_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Business_Associate_Agreements_Delete_Elem_Input>;
  _delete_key?: Maybe<Business_Associate_Agreements_Delete_Key_Input>;
  _prepend?: Maybe<Business_Associate_Agreements_Prepend_Input>;
  _set?: Maybe<Business_Associate_Agreements_Set_Input>;
  where: Business_Associate_Agreements_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Business_Associate_Agreements_By_PkArgs = {
  _append?: Maybe<Business_Associate_Agreements_Append_Input>;
  _delete_at_path?: Maybe<Business_Associate_Agreements_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Business_Associate_Agreements_Delete_Elem_Input>;
  _delete_key?: Maybe<Business_Associate_Agreements_Delete_Key_Input>;
  _prepend?: Maybe<Business_Associate_Agreements_Prepend_Input>;
  _set?: Maybe<Business_Associate_Agreements_Set_Input>;
  pk_columns: Business_Associate_Agreements_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Campaign_ApprovalsArgs = {
  _set?: Maybe<Campaign_Approvals_Set_Input>;
  where: Campaign_Approvals_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Campaign_Approvals_By_PkArgs = {
  _set?: Maybe<Campaign_Approvals_Set_Input>;
  pk_columns: Campaign_Approvals_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Campaign_Email_ComponentsArgs = {
  _append?: Maybe<Campaign_Email_Components_Append_Input>;
  _delete_at_path?: Maybe<Campaign_Email_Components_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Campaign_Email_Components_Delete_Elem_Input>;
  _delete_key?: Maybe<Campaign_Email_Components_Delete_Key_Input>;
  _prepend?: Maybe<Campaign_Email_Components_Prepend_Input>;
  _set?: Maybe<Campaign_Email_Components_Set_Input>;
  where: Campaign_Email_Components_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Campaign_Email_Components_By_PkArgs = {
  _append?: Maybe<Campaign_Email_Components_Append_Input>;
  _delete_at_path?: Maybe<Campaign_Email_Components_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Campaign_Email_Components_Delete_Elem_Input>;
  _delete_key?: Maybe<Campaign_Email_Components_Delete_Key_Input>;
  _prepend?: Maybe<Campaign_Email_Components_Prepend_Input>;
  _set?: Maybe<Campaign_Email_Components_Set_Input>;
  pk_columns: Campaign_Email_Components_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Campaign_Letter_ComponentsArgs = {
  _append?: Maybe<Campaign_Letter_Components_Append_Input>;
  _delete_at_path?: Maybe<Campaign_Letter_Components_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Campaign_Letter_Components_Delete_Elem_Input>;
  _delete_key?: Maybe<Campaign_Letter_Components_Delete_Key_Input>;
  _prepend?: Maybe<Campaign_Letter_Components_Prepend_Input>;
  _set?: Maybe<Campaign_Letter_Components_Set_Input>;
  where: Campaign_Letter_Components_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Campaign_Letter_Components_By_PkArgs = {
  _append?: Maybe<Campaign_Letter_Components_Append_Input>;
  _delete_at_path?: Maybe<Campaign_Letter_Components_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Campaign_Letter_Components_Delete_Elem_Input>;
  _delete_key?: Maybe<Campaign_Letter_Components_Delete_Key_Input>;
  _prepend?: Maybe<Campaign_Letter_Components_Prepend_Input>;
  _set?: Maybe<Campaign_Letter_Components_Set_Input>;
  pk_columns: Campaign_Letter_Components_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Campaign_Sms_ComponentsArgs = {
  _append?: Maybe<Campaign_Sms_Components_Append_Input>;
  _delete_at_path?: Maybe<Campaign_Sms_Components_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Campaign_Sms_Components_Delete_Elem_Input>;
  _delete_key?: Maybe<Campaign_Sms_Components_Delete_Key_Input>;
  _prepend?: Maybe<Campaign_Sms_Components_Prepend_Input>;
  _set?: Maybe<Campaign_Sms_Components_Set_Input>;
  where: Campaign_Sms_Components_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Campaign_Sms_Components_By_PkArgs = {
  _append?: Maybe<Campaign_Sms_Components_Append_Input>;
  _delete_at_path?: Maybe<Campaign_Sms_Components_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Campaign_Sms_Components_Delete_Elem_Input>;
  _delete_key?: Maybe<Campaign_Sms_Components_Delete_Key_Input>;
  _prepend?: Maybe<Campaign_Sms_Components_Prepend_Input>;
  _set?: Maybe<Campaign_Sms_Components_Set_Input>;
  pk_columns: Campaign_Sms_Components_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Campaign_TemplatesArgs = {
  _append?: Maybe<Campaign_Templates_Append_Input>;
  _delete_at_path?: Maybe<Campaign_Templates_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Campaign_Templates_Delete_Elem_Input>;
  _delete_key?: Maybe<Campaign_Templates_Delete_Key_Input>;
  _prepend?: Maybe<Campaign_Templates_Prepend_Input>;
  _set?: Maybe<Campaign_Templates_Set_Input>;
  where: Campaign_Templates_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Campaign_Templates_By_PkArgs = {
  _append?: Maybe<Campaign_Templates_Append_Input>;
  _delete_at_path?: Maybe<Campaign_Templates_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Campaign_Templates_Delete_Elem_Input>;
  _delete_key?: Maybe<Campaign_Templates_Delete_Key_Input>;
  _prepend?: Maybe<Campaign_Templates_Prepend_Input>;
  _set?: Maybe<Campaign_Templates_Set_Input>;
  pk_columns: Campaign_Templates_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Client_Call_RecordsArgs = {
  _inc?: Maybe<Client_Call_Records_Inc_Input>;
  _set?: Maybe<Client_Call_Records_Set_Input>;
  where: Client_Call_Records_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Client_Call_Records_By_PkArgs = {
  _inc?: Maybe<Client_Call_Records_Inc_Input>;
  _set?: Maybe<Client_Call_Records_Set_Input>;
  pk_columns: Client_Call_Records_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Cms_Alignment_ReportsArgs = {
  _inc?: Maybe<Cms_Alignment_Reports_Inc_Input>;
  _set?: Maybe<Cms_Alignment_Reports_Set_Input>;
  where: Cms_Alignment_Reports_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Cms_Alignment_Reports_By_PkArgs = {
  _inc?: Maybe<Cms_Alignment_Reports_Inc_Input>;
  _set?: Maybe<Cms_Alignment_Reports_Set_Input>;
  pk_columns: Cms_Alignment_Reports_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Cms_Dce_ParticipantsArgs = {
  _inc?: Maybe<Cms_Dce_Participants_Inc_Input>;
  _set?: Maybe<Cms_Dce_Participants_Set_Input>;
  where: Cms_Dce_Participants_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Cms_Provider_Alignment_ReportsArgs = {
  _inc?: Maybe<Cms_Provider_Alignment_Reports_Inc_Input>;
  _set?: Maybe<Cms_Provider_Alignment_Reports_Set_Input>;
  where: Cms_Provider_Alignment_Reports_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Cms_Provider_Alignment_Reports_By_PkArgs = {
  _inc?: Maybe<Cms_Provider_Alignment_Reports_Inc_Input>;
  _set?: Maybe<Cms_Provider_Alignment_Reports_Set_Input>;
  pk_columns: Cms_Provider_Alignment_Reports_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Cms_Pva_ResponsesArgs = {
  _set?: Maybe<Cms_Pva_Responses_Set_Input>;
  where: Cms_Pva_Responses_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Cms_Pva_Responses_By_PkArgs = {
  _set?: Maybe<Cms_Pva_Responses_Set_Input>;
  pk_columns: Cms_Pva_Responses_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Component_Tracking_IdsArgs = {
  _set?: Maybe<Component_Tracking_Ids_Set_Input>;
  where: Component_Tracking_Ids_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Component_Tracking_Ids_By_PkArgs = {
  _set?: Maybe<Component_Tracking_Ids_Set_Input>;
  pk_columns: Component_Tracking_Ids_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Daily_Performance_StatsArgs = {
  _inc?: Maybe<Daily_Performance_Stats_Inc_Input>;
  _set?: Maybe<Daily_Performance_Stats_Set_Input>;
  where: Daily_Performance_Stats_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Daily_Performance_Stats_By_PkArgs = {
  _inc?: Maybe<Daily_Performance_Stats_Inc_Input>;
  _set?: Maybe<Daily_Performance_Stats_Set_Input>;
  pk_columns: Daily_Performance_Stats_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Email_Component_ApprovalsArgs = {
  _set?: Maybe<Email_Component_Approvals_Set_Input>;
  where: Email_Component_Approvals_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Email_Component_Approvals_By_PkArgs = {
  _set?: Maybe<Email_Component_Approvals_Set_Input>;
  pk_columns: Email_Component_Approvals_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Email_TemplatesArgs = {
  _append?: Maybe<Email_Templates_Append_Input>;
  _delete_at_path?: Maybe<Email_Templates_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Email_Templates_Delete_Elem_Input>;
  _delete_key?: Maybe<Email_Templates_Delete_Key_Input>;
  _prepend?: Maybe<Email_Templates_Prepend_Input>;
  _set?: Maybe<Email_Templates_Set_Input>;
  where: Email_Templates_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Email_Templates_By_PkArgs = {
  _append?: Maybe<Email_Templates_Append_Input>;
  _delete_at_path?: Maybe<Email_Templates_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Email_Templates_Delete_Elem_Input>;
  _delete_key?: Maybe<Email_Templates_Delete_Key_Input>;
  _prepend?: Maybe<Email_Templates_Prepend_Input>;
  _set?: Maybe<Email_Templates_Set_Input>;
  pk_columns: Email_Templates_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_EmailsArgs = {
  _append?: Maybe<Emails_Append_Input>;
  _delete_at_path?: Maybe<Emails_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Emails_Delete_Elem_Input>;
  _delete_key?: Maybe<Emails_Delete_Key_Input>;
  _prepend?: Maybe<Emails_Prepend_Input>;
  _set?: Maybe<Emails_Set_Input>;
  where: Emails_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Emails_By_PkArgs = {
  _append?: Maybe<Emails_Append_Input>;
  _delete_at_path?: Maybe<Emails_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Emails_Delete_Elem_Input>;
  _delete_key?: Maybe<Emails_Delete_Key_Input>;
  _prepend?: Maybe<Emails_Prepend_Input>;
  _set?: Maybe<Emails_Set_Input>;
  pk_columns: Emails_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_File_UploadsArgs = {
  _set?: Maybe<File_Uploads_Set_Input>;
  where: File_Uploads_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_File_Uploads_By_PkArgs = {
  _set?: Maybe<File_Uploads_Set_Input>;
  pk_columns: File_Uploads_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Health_Equity_ReportsArgs = {
  _append?: Maybe<Health_Equity_Reports_Append_Input>;
  _delete_at_path?: Maybe<Health_Equity_Reports_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Health_Equity_Reports_Delete_Elem_Input>;
  _delete_key?: Maybe<Health_Equity_Reports_Delete_Key_Input>;
  _inc?: Maybe<Health_Equity_Reports_Inc_Input>;
  _prepend?: Maybe<Health_Equity_Reports_Prepend_Input>;
  _set?: Maybe<Health_Equity_Reports_Set_Input>;
  where: Health_Equity_Reports_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Health_Equity_Reports_AuditArgs = {
  _append?: Maybe<Health_Equity_Reports_Audit_Append_Input>;
  _delete_at_path?: Maybe<Health_Equity_Reports_Audit_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Health_Equity_Reports_Audit_Delete_Elem_Input>;
  _delete_key?: Maybe<Health_Equity_Reports_Audit_Delete_Key_Input>;
  _inc?: Maybe<Health_Equity_Reports_Audit_Inc_Input>;
  _prepend?: Maybe<Health_Equity_Reports_Audit_Prepend_Input>;
  _set?: Maybe<Health_Equity_Reports_Audit_Set_Input>;
  where: Health_Equity_Reports_Audit_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Health_Equity_Reports_By_PkArgs = {
  _append?: Maybe<Health_Equity_Reports_Append_Input>;
  _delete_at_path?: Maybe<Health_Equity_Reports_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Health_Equity_Reports_Delete_Elem_Input>;
  _delete_key?: Maybe<Health_Equity_Reports_Delete_Key_Input>;
  _inc?: Maybe<Health_Equity_Reports_Inc_Input>;
  _prepend?: Maybe<Health_Equity_Reports_Prepend_Input>;
  _set?: Maybe<Health_Equity_Reports_Set_Input>;
  pk_columns: Health_Equity_Reports_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Hedr_Submission_TemplatesArgs = {
  _inc?: Maybe<Hedr_Submission_Templates_Inc_Input>;
  _set?: Maybe<Hedr_Submission_Templates_Set_Input>;
  where: Hedr_Submission_Templates_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Hedr_Submission_Templates_By_PkArgs = {
  _inc?: Maybe<Hedr_Submission_Templates_Inc_Input>;
  _set?: Maybe<Hedr_Submission_Templates_Set_Input>;
  pk_columns: Hedr_Submission_Templates_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Insurance_Segment_MappingsArgs = {
  _set?: Maybe<Insurance_Segment_Mappings_Set_Input>;
  where: Insurance_Segment_Mappings_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Insurance_Segment_Mappings_By_PkArgs = {
  _set?: Maybe<Insurance_Segment_Mappings_Set_Input>;
  pk_columns: Insurance_Segment_Mappings_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Letter_Component_ApprovalsArgs = {
  _set?: Maybe<Letter_Component_Approvals_Set_Input>;
  where: Letter_Component_Approvals_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Letter_Component_Approvals_By_PkArgs = {
  _set?: Maybe<Letter_Component_Approvals_Set_Input>;
  pk_columns: Letter_Component_Approvals_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Letter_Format_TypesArgs = {
  _set?: Maybe<Letter_Format_Types_Set_Input>;
  where: Letter_Format_Types_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Letter_Format_Types_By_PkArgs = {
  _set?: Maybe<Letter_Format_Types_Set_Input>;
  pk_columns: Letter_Format_Types_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Letter_TemplatesArgs = {
  _append?: Maybe<Letter_Templates_Append_Input>;
  _delete_at_path?: Maybe<Letter_Templates_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Letter_Templates_Delete_Elem_Input>;
  _delete_key?: Maybe<Letter_Templates_Delete_Key_Input>;
  _inc?: Maybe<Letter_Templates_Inc_Input>;
  _prepend?: Maybe<Letter_Templates_Prepend_Input>;
  _set?: Maybe<Letter_Templates_Set_Input>;
  where: Letter_Templates_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Letter_Templates_By_PkArgs = {
  _append?: Maybe<Letter_Templates_Append_Input>;
  _delete_at_path?: Maybe<Letter_Templates_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Letter_Templates_Delete_Elem_Input>;
  _delete_key?: Maybe<Letter_Templates_Delete_Key_Input>;
  _inc?: Maybe<Letter_Templates_Inc_Input>;
  _prepend?: Maybe<Letter_Templates_Prepend_Input>;
  _set?: Maybe<Letter_Templates_Set_Input>;
  pk_columns: Letter_Templates_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Letter_Variable_ValuesArgs = {
  _set?: Maybe<Letter_Variable_Values_Set_Input>;
  where: Letter_Variable_Values_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Letter_Variable_Values_By_PkArgs = {
  _set?: Maybe<Letter_Variable_Values_Set_Input>;
  pk_columns: Letter_Variable_Values_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_LettersArgs = {
  _append?: Maybe<Letters_Append_Input>;
  _delete_at_path?: Maybe<Letters_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Letters_Delete_Elem_Input>;
  _delete_key?: Maybe<Letters_Delete_Key_Input>;
  _prepend?: Maybe<Letters_Prepend_Input>;
  _set?: Maybe<Letters_Set_Input>;
  where: Letters_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Letters_By_PkArgs = {
  _append?: Maybe<Letters_Append_Input>;
  _delete_at_path?: Maybe<Letters_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Letters_Delete_Elem_Input>;
  _delete_key?: Maybe<Letters_Delete_Key_Input>;
  _prepend?: Maybe<Letters_Prepend_Input>;
  _set?: Maybe<Letters_Set_Input>;
  pk_columns: Letters_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_NetworksArgs = {
  _append?: Maybe<Networks_Append_Input>;
  _delete_at_path?: Maybe<Networks_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Networks_Delete_Elem_Input>;
  _delete_key?: Maybe<Networks_Delete_Key_Input>;
  _inc?: Maybe<Networks_Inc_Input>;
  _prepend?: Maybe<Networks_Prepend_Input>;
  _set?: Maybe<Networks_Set_Input>;
  where: Networks_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Networks_By_PkArgs = {
  _append?: Maybe<Networks_Append_Input>;
  _delete_at_path?: Maybe<Networks_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Networks_Delete_Elem_Input>;
  _delete_key?: Maybe<Networks_Delete_Key_Input>;
  _inc?: Maybe<Networks_Inc_Input>;
  _prepend?: Maybe<Networks_Prepend_Input>;
  _set?: Maybe<Networks_Set_Input>;
  pk_columns: Networks_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Organization_NetworksArgs = {
  _set?: Maybe<Organization_Networks_Set_Input>;
  where: Organization_Networks_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Organization_Networks_By_PkArgs = {
  _set?: Maybe<Organization_Networks_Set_Input>;
  pk_columns: Organization_Networks_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_OrganizationsArgs = {
  _append?: Maybe<Organizations_Append_Input>;
  _delete_at_path?: Maybe<Organizations_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Organizations_Delete_Elem_Input>;
  _delete_key?: Maybe<Organizations_Delete_Key_Input>;
  _prepend?: Maybe<Organizations_Prepend_Input>;
  _set?: Maybe<Organizations_Set_Input>;
  where: Organizations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Organizations_By_PkArgs = {
  _append?: Maybe<Organizations_Append_Input>;
  _delete_at_path?: Maybe<Organizations_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Organizations_Delete_Elem_Input>;
  _delete_key?: Maybe<Organizations_Delete_Key_Input>;
  _prepend?: Maybe<Organizations_Prepend_Input>;
  _set?: Maybe<Organizations_Set_Input>;
  pk_columns: Organizations_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Patient_Roster_FilesArgs = {
  _append?: Maybe<Patient_Roster_Files_Append_Input>;
  _delete_at_path?: Maybe<Patient_Roster_Files_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Patient_Roster_Files_Delete_Elem_Input>;
  _delete_key?: Maybe<Patient_Roster_Files_Delete_Key_Input>;
  _prepend?: Maybe<Patient_Roster_Files_Prepend_Input>;
  _set?: Maybe<Patient_Roster_Files_Set_Input>;
  where: Patient_Roster_Files_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Patient_Roster_Files_By_PkArgs = {
  _append?: Maybe<Patient_Roster_Files_Append_Input>;
  _delete_at_path?: Maybe<Patient_Roster_Files_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Patient_Roster_Files_Delete_Elem_Input>;
  _delete_key?: Maybe<Patient_Roster_Files_Delete_Key_Input>;
  _prepend?: Maybe<Patient_Roster_Files_Prepend_Input>;
  _set?: Maybe<Patient_Roster_Files_Set_Input>;
  pk_columns: Patient_Roster_Files_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Patient_SegmentsArgs = {
  _set?: Maybe<Patient_Segments_Set_Input>;
  where: Patient_Segments_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Patient_Segments_By_PkArgs = {
  _set?: Maybe<Patient_Segments_Set_Input>;
  pk_columns: Patient_Segments_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_PatientsArgs = {
  _append?: Maybe<Patients_Append_Input>;
  _delete_at_path?: Maybe<Patients_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Patients_Delete_Elem_Input>;
  _delete_key?: Maybe<Patients_Delete_Key_Input>;
  _prepend?: Maybe<Patients_Prepend_Input>;
  _set?: Maybe<Patients_Set_Input>;
  where: Patients_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Patients_AuditArgs = {
  _append?: Maybe<Patients_Audit_Append_Input>;
  _delete_at_path?: Maybe<Patients_Audit_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Patients_Audit_Delete_Elem_Input>;
  _delete_key?: Maybe<Patients_Audit_Delete_Key_Input>;
  _inc?: Maybe<Patients_Audit_Inc_Input>;
  _prepend?: Maybe<Patients_Audit_Prepend_Input>;
  _set?: Maybe<Patients_Audit_Set_Input>;
  where: Patients_Audit_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Patients_By_PkArgs = {
  _append?: Maybe<Patients_Append_Input>;
  _delete_at_path?: Maybe<Patients_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Patients_Delete_Elem_Input>;
  _delete_key?: Maybe<Patients_Delete_Key_Input>;
  _prepend?: Maybe<Patients_Prepend_Input>;
  _set?: Maybe<Patients_Set_Input>;
  pk_columns: Patients_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_PracticesArgs = {
  _append?: Maybe<Practices_Append_Input>;
  _delete_at_path?: Maybe<Practices_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Practices_Delete_Elem_Input>;
  _delete_key?: Maybe<Practices_Delete_Key_Input>;
  _prepend?: Maybe<Practices_Prepend_Input>;
  _set?: Maybe<Practices_Set_Input>;
  where: Practices_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Practices_By_PkArgs = {
  _append?: Maybe<Practices_Append_Input>;
  _delete_at_path?: Maybe<Practices_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Practices_Delete_Elem_Input>;
  _delete_key?: Maybe<Practices_Delete_Key_Input>;
  _prepend?: Maybe<Practices_Prepend_Input>;
  _set?: Maybe<Practices_Set_Input>;
  pk_columns: Practices_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Provider_EmploymentsArgs = {
  _set?: Maybe<Provider_Employments_Set_Input>;
  where: Provider_Employments_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Provider_Employments_By_PkArgs = {
  _set?: Maybe<Provider_Employments_Set_Input>;
  pk_columns: Provider_Employments_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Provider_MembershipsArgs = {
  _set?: Maybe<Provider_Memberships_Set_Input>;
  where: Provider_Memberships_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Provider_Memberships_By_PkArgs = {
  _set?: Maybe<Provider_Memberships_Set_Input>;
  pk_columns: Provider_Memberships_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ProvidersArgs = {
  _set?: Maybe<Providers_Set_Input>;
  where: Providers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Providers_By_PkArgs = {
  _set?: Maybe<Providers_Set_Input>;
  pk_columns: Providers_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_PvasArgs = {
  _append?: Maybe<Pvas_Append_Input>;
  _delete_at_path?: Maybe<Pvas_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Pvas_Delete_Elem_Input>;
  _delete_key?: Maybe<Pvas_Delete_Key_Input>;
  _inc?: Maybe<Pvas_Inc_Input>;
  _prepend?: Maybe<Pvas_Prepend_Input>;
  _set?: Maybe<Pvas_Set_Input>;
  where: Pvas_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Pvas_AuditArgs = {
  _append?: Maybe<Pvas_Audit_Append_Input>;
  _delete_at_path?: Maybe<Pvas_Audit_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Pvas_Audit_Delete_Elem_Input>;
  _delete_key?: Maybe<Pvas_Audit_Delete_Key_Input>;
  _inc?: Maybe<Pvas_Audit_Inc_Input>;
  _prepend?: Maybe<Pvas_Audit_Prepend_Input>;
  _set?: Maybe<Pvas_Audit_Set_Input>;
  where: Pvas_Audit_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Pvas_By_PkArgs = {
  _append?: Maybe<Pvas_Append_Input>;
  _delete_at_path?: Maybe<Pvas_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Pvas_Delete_Elem_Input>;
  _delete_key?: Maybe<Pvas_Delete_Key_Input>;
  _inc?: Maybe<Pvas_Inc_Input>;
  _prepend?: Maybe<Pvas_Prepend_Input>;
  _set?: Maybe<Pvas_Set_Input>;
  pk_columns: Pvas_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Satisfaction_SurveysArgs = {
  _append?: Maybe<Satisfaction_Surveys_Append_Input>;
  _delete_at_path?: Maybe<Satisfaction_Surveys_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Satisfaction_Surveys_Delete_Elem_Input>;
  _delete_key?: Maybe<Satisfaction_Surveys_Delete_Key_Input>;
  _inc?: Maybe<Satisfaction_Surveys_Inc_Input>;
  _prepend?: Maybe<Satisfaction_Surveys_Prepend_Input>;
  _set?: Maybe<Satisfaction_Surveys_Set_Input>;
  where: Satisfaction_Surveys_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Satisfaction_Surveys_By_PkArgs = {
  _append?: Maybe<Satisfaction_Surveys_Append_Input>;
  _delete_at_path?: Maybe<Satisfaction_Surveys_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Satisfaction_Surveys_Delete_Elem_Input>;
  _delete_key?: Maybe<Satisfaction_Surveys_Delete_Key_Input>;
  _inc?: Maybe<Satisfaction_Surveys_Inc_Input>;
  _prepend?: Maybe<Satisfaction_Surveys_Prepend_Input>;
  _set?: Maybe<Satisfaction_Surveys_Set_Input>;
  pk_columns: Satisfaction_Surveys_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Scheduled_EventsArgs = {
  _inc?: Maybe<Scheduled_Events_Inc_Input>;
  _set?: Maybe<Scheduled_Events_Set_Input>;
  where: Scheduled_Events_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Sms_Component_ApprovalsArgs = {
  _set?: Maybe<Sms_Component_Approvals_Set_Input>;
  where: Sms_Component_Approvals_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Sms_Component_Approvals_By_PkArgs = {
  _set?: Maybe<Sms_Component_Approvals_Set_Input>;
  pk_columns: Sms_Component_Approvals_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Sms_MessagesArgs = {
  _append?: Maybe<Sms_Messages_Append_Input>;
  _delete_at_path?: Maybe<Sms_Messages_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Sms_Messages_Delete_Elem_Input>;
  _delete_key?: Maybe<Sms_Messages_Delete_Key_Input>;
  _prepend?: Maybe<Sms_Messages_Prepend_Input>;
  _set?: Maybe<Sms_Messages_Set_Input>;
  where: Sms_Messages_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Sms_Messages_By_PkArgs = {
  _append?: Maybe<Sms_Messages_Append_Input>;
  _delete_at_path?: Maybe<Sms_Messages_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Sms_Messages_Delete_Elem_Input>;
  _delete_key?: Maybe<Sms_Messages_Delete_Key_Input>;
  _prepend?: Maybe<Sms_Messages_Prepend_Input>;
  _set?: Maybe<Sms_Messages_Set_Input>;
  pk_columns: Sms_Messages_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Sms_TemplatesArgs = {
  _append?: Maybe<Sms_Templates_Append_Input>;
  _delete_at_path?: Maybe<Sms_Templates_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Sms_Templates_Delete_Elem_Input>;
  _delete_key?: Maybe<Sms_Templates_Delete_Key_Input>;
  _prepend?: Maybe<Sms_Templates_Prepend_Input>;
  _set?: Maybe<Sms_Templates_Set_Input>;
  where: Sms_Templates_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Sms_Templates_By_PkArgs = {
  _append?: Maybe<Sms_Templates_Append_Input>;
  _delete_at_path?: Maybe<Sms_Templates_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Sms_Templates_Delete_Elem_Input>;
  _delete_key?: Maybe<Sms_Templates_Delete_Key_Input>;
  _prepend?: Maybe<Sms_Templates_Prepend_Input>;
  _set?: Maybe<Sms_Templates_Set_Input>;
  pk_columns: Sms_Templates_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_UserArgs = {
  changes?: Maybe<UpdateUserAttributes>;
  user_id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootUpdate_UsersArgs = {
  _append?: Maybe<Users_Append_Input>;
  _delete_at_path?: Maybe<Users_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Users_Delete_Elem_Input>;
  _delete_key?: Maybe<Users_Delete_Key_Input>;
  _prepend?: Maybe<Users_Prepend_Input>;
  _set?: Maybe<Users_Set_Input>;
  where: Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Users_AuditArgs = {
  _append?: Maybe<Users_Audit_Append_Input>;
  _delete_at_path?: Maybe<Users_Audit_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Users_Audit_Delete_Elem_Input>;
  _delete_key?: Maybe<Users_Audit_Delete_Key_Input>;
  _inc?: Maybe<Users_Audit_Inc_Input>;
  _prepend?: Maybe<Users_Audit_Prepend_Input>;
  _set?: Maybe<Users_Audit_Set_Input>;
  where: Users_Audit_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Users_By_PkArgs = {
  _append?: Maybe<Users_Append_Input>;
  _delete_at_path?: Maybe<Users_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Users_Delete_Elem_Input>;
  _delete_key?: Maybe<Users_Delete_Key_Input>;
  _prepend?: Maybe<Users_Prepend_Input>;
  _set?: Maybe<Users_Set_Input>;
  pk_columns: Users_Pk_Columns_Input;
};

/**
 * A network represents either an IPA or a Medical Group. Networks have providers assigned, and network users interact with practice users but don't view individual patients. 
 * 
 * 
 * columns and relationships of "networks"
 */
export type Networks = {
  __typename?: 'networks';
  broker_disclaimer?: Maybe<Scalars['String']>;
  /** DEPRECATED (field not needed) */
  broker_email?: Maybe<Scalars['String']>;
  broker_name?: Maybe<Scalars['String']>;
  /** DEPRECATED! (use broker_phone_for_letters and broker_phone_for_emails) */
  broker_phone?: Maybe<Scalars['String']>;
  broker_phone_for_emails?: Maybe<Scalars['String']>;
  broker_phone_for_letters?: Maybe<Scalars['String']>;
  broker_url_for_emails?: Maybe<Scalars['String']>;
  broker_url_for_letters?: Maybe<Scalars['String']>;
  /** An array relationship */
  campaign_templates: Array<Campaign_Templates>;
  /** An aggregated array relationship */
  campaign_templates_aggregate: Campaign_Templates_Aggregate;
  created_at: Scalars['timestamptz'];
  dc_id?: Maybe<Scalars['String']>;
  /** An object relationship */
  default_practice?: Maybe<Practices>;
  default_practice_id?: Maybe<Scalars['String']>;
  /** An array relationship */
  health_equity_reports: Array<Health_Equity_Reports>;
  /** An aggregated array relationship */
  health_equity_reports_aggregate: Health_Equity_Reports_Aggregate;
  is_archived: Scalars['Boolean'];
  network_color?: Maybe<Scalars['String']>;
  network_description?: Maybe<Scalars['String']>;
  network_external_id?: Maybe<Scalars['String']>;
  network_id: Scalars['String'];
  network_logo?: Maybe<Scalars['String']>;
  network_merge_vars?: Maybe<Scalars['jsonb']>;
  network_name: Scalars['String'];
  network_number: Scalars['Int'];
  network_slug?: Maybe<Scalars['String']>;
  /** An array relationship */
  organization_networks: Array<Organization_Networks>;
  /** An aggregated array relationship */
  organization_networks_aggregate: Organization_Networks_Aggregate;
  /** An array relationship */
  practices: Array<Practices>;
  /** An aggregated array relationship */
  practices_aggregate: Practices_Aggregate;
  /** An array relationship */
  provider_memberships: Array<Provider_Memberships>;
  /** An aggregated array relationship */
  provider_memberships_aggregate: Provider_Memberships_Aggregate;
  /** An array relationship */
  pvas: Array<Pvas>;
  /** An aggregated array relationship */
  pvas_aggregate: Pvas_Aggregate;
  updated_at: Scalars['timestamptz'];
  /** An array relationship */
  users: Array<Users>;
  /** An aggregated array relationship */
  users_aggregate: Users_Aggregate;
};


/**
 * A network represents either an IPA or a Medical Group. Networks have providers assigned, and network users interact with practice users but don't view individual patients. 
 * 
 * 
 * columns and relationships of "networks"
 */
export type NetworksCampaign_TemplatesArgs = {
  distinct_on?: Maybe<Array<Campaign_Templates_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Campaign_Templates_Order_By>>;
  where?: Maybe<Campaign_Templates_Bool_Exp>;
};


/**
 * A network represents either an IPA or a Medical Group. Networks have providers assigned, and network users interact with practice users but don't view individual patients. 
 * 
 * 
 * columns and relationships of "networks"
 */
export type NetworksCampaign_Templates_AggregateArgs = {
  distinct_on?: Maybe<Array<Campaign_Templates_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Campaign_Templates_Order_By>>;
  where?: Maybe<Campaign_Templates_Bool_Exp>;
};


/**
 * A network represents either an IPA or a Medical Group. Networks have providers assigned, and network users interact with practice users but don't view individual patients. 
 * 
 * 
 * columns and relationships of "networks"
 */
export type NetworksHealth_Equity_ReportsArgs = {
  distinct_on?: Maybe<Array<Health_Equity_Reports_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Health_Equity_Reports_Order_By>>;
  where?: Maybe<Health_Equity_Reports_Bool_Exp>;
};


/**
 * A network represents either an IPA or a Medical Group. Networks have providers assigned, and network users interact with practice users but don't view individual patients. 
 * 
 * 
 * columns and relationships of "networks"
 */
export type NetworksHealth_Equity_Reports_AggregateArgs = {
  distinct_on?: Maybe<Array<Health_Equity_Reports_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Health_Equity_Reports_Order_By>>;
  where?: Maybe<Health_Equity_Reports_Bool_Exp>;
};


/**
 * A network represents either an IPA or a Medical Group. Networks have providers assigned, and network users interact with practice users but don't view individual patients. 
 * 
 * 
 * columns and relationships of "networks"
 */
export type NetworksNetwork_Merge_VarsArgs = {
  path?: Maybe<Scalars['String']>;
};


/**
 * A network represents either an IPA or a Medical Group. Networks have providers assigned, and network users interact with practice users but don't view individual patients. 
 * 
 * 
 * columns and relationships of "networks"
 */
export type NetworksOrganization_NetworksArgs = {
  distinct_on?: Maybe<Array<Organization_Networks_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Organization_Networks_Order_By>>;
  where?: Maybe<Organization_Networks_Bool_Exp>;
};


/**
 * A network represents either an IPA or a Medical Group. Networks have providers assigned, and network users interact with practice users but don't view individual patients. 
 * 
 * 
 * columns and relationships of "networks"
 */
export type NetworksOrganization_Networks_AggregateArgs = {
  distinct_on?: Maybe<Array<Organization_Networks_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Organization_Networks_Order_By>>;
  where?: Maybe<Organization_Networks_Bool_Exp>;
};


/**
 * A network represents either an IPA or a Medical Group. Networks have providers assigned, and network users interact with practice users but don't view individual patients. 
 * 
 * 
 * columns and relationships of "networks"
 */
export type NetworksPracticesArgs = {
  distinct_on?: Maybe<Array<Practices_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Practices_Order_By>>;
  where?: Maybe<Practices_Bool_Exp>;
};


/**
 * A network represents either an IPA or a Medical Group. Networks have providers assigned, and network users interact with practice users but don't view individual patients. 
 * 
 * 
 * columns and relationships of "networks"
 */
export type NetworksPractices_AggregateArgs = {
  distinct_on?: Maybe<Array<Practices_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Practices_Order_By>>;
  where?: Maybe<Practices_Bool_Exp>;
};


/**
 * A network represents either an IPA or a Medical Group. Networks have providers assigned, and network users interact with practice users but don't view individual patients. 
 * 
 * 
 * columns and relationships of "networks"
 */
export type NetworksProvider_MembershipsArgs = {
  distinct_on?: Maybe<Array<Provider_Memberships_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Provider_Memberships_Order_By>>;
  where?: Maybe<Provider_Memberships_Bool_Exp>;
};


/**
 * A network represents either an IPA or a Medical Group. Networks have providers assigned, and network users interact with practice users but don't view individual patients. 
 * 
 * 
 * columns and relationships of "networks"
 */
export type NetworksProvider_Memberships_AggregateArgs = {
  distinct_on?: Maybe<Array<Provider_Memberships_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Provider_Memberships_Order_By>>;
  where?: Maybe<Provider_Memberships_Bool_Exp>;
};


/**
 * A network represents either an IPA or a Medical Group. Networks have providers assigned, and network users interact with practice users but don't view individual patients. 
 * 
 * 
 * columns and relationships of "networks"
 */
export type NetworksPvasArgs = {
  distinct_on?: Maybe<Array<Pvas_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Pvas_Order_By>>;
  where?: Maybe<Pvas_Bool_Exp>;
};


/**
 * A network represents either an IPA or a Medical Group. Networks have providers assigned, and network users interact with practice users but don't view individual patients. 
 * 
 * 
 * columns and relationships of "networks"
 */
export type NetworksPvas_AggregateArgs = {
  distinct_on?: Maybe<Array<Pvas_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Pvas_Order_By>>;
  where?: Maybe<Pvas_Bool_Exp>;
};


/**
 * A network represents either an IPA or a Medical Group. Networks have providers assigned, and network users interact with practice users but don't view individual patients. 
 * 
 * 
 * columns and relationships of "networks"
 */
export type NetworksUsersArgs = {
  distinct_on?: Maybe<Array<Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Users_Order_By>>;
  where?: Maybe<Users_Bool_Exp>;
};


/**
 * A network represents either an IPA or a Medical Group. Networks have providers assigned, and network users interact with practice users but don't view individual patients. 
 * 
 * 
 * columns and relationships of "networks"
 */
export type NetworksUsers_AggregateArgs = {
  distinct_on?: Maybe<Array<Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Users_Order_By>>;
  where?: Maybe<Users_Bool_Exp>;
};

/** aggregated selection of "networks" */
export type Networks_Aggregate = {
  __typename?: 'networks_aggregate';
  aggregate?: Maybe<Networks_Aggregate_Fields>;
  nodes: Array<Networks>;
};

/** aggregate fields of "networks" */
export type Networks_Aggregate_Fields = {
  __typename?: 'networks_aggregate_fields';
  avg?: Maybe<Networks_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Networks_Max_Fields>;
  min?: Maybe<Networks_Min_Fields>;
  stddev?: Maybe<Networks_Stddev_Fields>;
  stddev_pop?: Maybe<Networks_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Networks_Stddev_Samp_Fields>;
  sum?: Maybe<Networks_Sum_Fields>;
  var_pop?: Maybe<Networks_Var_Pop_Fields>;
  var_samp?: Maybe<Networks_Var_Samp_Fields>;
  variance?: Maybe<Networks_Variance_Fields>;
};


/** aggregate fields of "networks" */
export type Networks_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Networks_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "networks" */
export type Networks_Aggregate_Order_By = {
  avg?: Maybe<Networks_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Networks_Max_Order_By>;
  min?: Maybe<Networks_Min_Order_By>;
  stddev?: Maybe<Networks_Stddev_Order_By>;
  stddev_pop?: Maybe<Networks_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Networks_Stddev_Samp_Order_By>;
  sum?: Maybe<Networks_Sum_Order_By>;
  var_pop?: Maybe<Networks_Var_Pop_Order_By>;
  var_samp?: Maybe<Networks_Var_Samp_Order_By>;
  variance?: Maybe<Networks_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Networks_Append_Input = {
  network_merge_vars?: Maybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "networks" */
export type Networks_Arr_Rel_Insert_Input = {
  data: Array<Networks_Insert_Input>;
  on_conflict?: Maybe<Networks_On_Conflict>;
};

/** aggregate avg on columns */
export type Networks_Avg_Fields = {
  __typename?: 'networks_avg_fields';
  network_number?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "networks" */
export type Networks_Avg_Order_By = {
  network_number?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "networks". All fields are combined with a logical 'AND'. */
export type Networks_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Networks_Bool_Exp>>>;
  _not?: Maybe<Networks_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Networks_Bool_Exp>>>;
  broker_disclaimer?: Maybe<String_Comparison_Exp>;
  broker_email?: Maybe<String_Comparison_Exp>;
  broker_name?: Maybe<String_Comparison_Exp>;
  broker_phone?: Maybe<String_Comparison_Exp>;
  broker_phone_for_emails?: Maybe<String_Comparison_Exp>;
  broker_phone_for_letters?: Maybe<String_Comparison_Exp>;
  broker_url_for_emails?: Maybe<String_Comparison_Exp>;
  broker_url_for_letters?: Maybe<String_Comparison_Exp>;
  campaign_templates?: Maybe<Campaign_Templates_Bool_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  dc_id?: Maybe<String_Comparison_Exp>;
  default_practice?: Maybe<Practices_Bool_Exp>;
  default_practice_id?: Maybe<String_Comparison_Exp>;
  health_equity_reports?: Maybe<Health_Equity_Reports_Bool_Exp>;
  is_archived?: Maybe<Boolean_Comparison_Exp>;
  network_color?: Maybe<String_Comparison_Exp>;
  network_description?: Maybe<String_Comparison_Exp>;
  network_external_id?: Maybe<String_Comparison_Exp>;
  network_id?: Maybe<String_Comparison_Exp>;
  network_logo?: Maybe<String_Comparison_Exp>;
  network_merge_vars?: Maybe<Jsonb_Comparison_Exp>;
  network_name?: Maybe<String_Comparison_Exp>;
  network_number?: Maybe<Int_Comparison_Exp>;
  network_slug?: Maybe<String_Comparison_Exp>;
  organization_networks?: Maybe<Organization_Networks_Bool_Exp>;
  practices?: Maybe<Practices_Bool_Exp>;
  provider_memberships?: Maybe<Provider_Memberships_Bool_Exp>;
  pvas?: Maybe<Pvas_Bool_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  users?: Maybe<Users_Bool_Exp>;
};

/** unique or primary key constraints on table "networks" */
export enum Networks_Constraint {
  /** unique or primary key constraint */
  NetworksNetworkNumberKey = 'networks_network_number_key',
  /** unique or primary key constraint */
  NetworksNetworkSlugKey = 'networks_network_slug_key',
  /** unique or primary key constraint */
  NetworksPkey = 'networks_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Networks_Delete_At_Path_Input = {
  network_merge_vars?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Networks_Delete_Elem_Input = {
  network_merge_vars?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Networks_Delete_Key_Input = {
  network_merge_vars?: Maybe<Scalars['String']>;
};

/** input type for incrementing integer column in table "networks" */
export type Networks_Inc_Input = {
  network_number?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "networks" */
export type Networks_Insert_Input = {
  broker_disclaimer?: Maybe<Scalars['String']>;
  broker_email?: Maybe<Scalars['String']>;
  broker_name?: Maybe<Scalars['String']>;
  broker_phone?: Maybe<Scalars['String']>;
  broker_phone_for_emails?: Maybe<Scalars['String']>;
  broker_phone_for_letters?: Maybe<Scalars['String']>;
  broker_url_for_emails?: Maybe<Scalars['String']>;
  broker_url_for_letters?: Maybe<Scalars['String']>;
  campaign_templates?: Maybe<Campaign_Templates_Arr_Rel_Insert_Input>;
  created_at?: Maybe<Scalars['timestamptz']>;
  dc_id?: Maybe<Scalars['String']>;
  default_practice?: Maybe<Practices_Obj_Rel_Insert_Input>;
  default_practice_id?: Maybe<Scalars['String']>;
  health_equity_reports?: Maybe<Health_Equity_Reports_Arr_Rel_Insert_Input>;
  is_archived?: Maybe<Scalars['Boolean']>;
  network_color?: Maybe<Scalars['String']>;
  network_description?: Maybe<Scalars['String']>;
  network_external_id?: Maybe<Scalars['String']>;
  network_id?: Maybe<Scalars['String']>;
  network_logo?: Maybe<Scalars['String']>;
  network_merge_vars?: Maybe<Scalars['jsonb']>;
  network_name?: Maybe<Scalars['String']>;
  network_number?: Maybe<Scalars['Int']>;
  network_slug?: Maybe<Scalars['String']>;
  organization_networks?: Maybe<Organization_Networks_Arr_Rel_Insert_Input>;
  practices?: Maybe<Practices_Arr_Rel_Insert_Input>;
  provider_memberships?: Maybe<Provider_Memberships_Arr_Rel_Insert_Input>;
  pvas?: Maybe<Pvas_Arr_Rel_Insert_Input>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  users?: Maybe<Users_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Networks_Max_Fields = {
  __typename?: 'networks_max_fields';
  broker_disclaimer?: Maybe<Scalars['String']>;
  broker_email?: Maybe<Scalars['String']>;
  broker_name?: Maybe<Scalars['String']>;
  broker_phone?: Maybe<Scalars['String']>;
  broker_phone_for_emails?: Maybe<Scalars['String']>;
  broker_phone_for_letters?: Maybe<Scalars['String']>;
  broker_url_for_emails?: Maybe<Scalars['String']>;
  broker_url_for_letters?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  dc_id?: Maybe<Scalars['String']>;
  default_practice_id?: Maybe<Scalars['String']>;
  network_color?: Maybe<Scalars['String']>;
  network_description?: Maybe<Scalars['String']>;
  network_external_id?: Maybe<Scalars['String']>;
  network_id?: Maybe<Scalars['String']>;
  network_logo?: Maybe<Scalars['String']>;
  network_name?: Maybe<Scalars['String']>;
  network_number?: Maybe<Scalars['Int']>;
  network_slug?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "networks" */
export type Networks_Max_Order_By = {
  broker_disclaimer?: Maybe<Order_By>;
  broker_email?: Maybe<Order_By>;
  broker_name?: Maybe<Order_By>;
  broker_phone?: Maybe<Order_By>;
  broker_phone_for_emails?: Maybe<Order_By>;
  broker_phone_for_letters?: Maybe<Order_By>;
  broker_url_for_emails?: Maybe<Order_By>;
  broker_url_for_letters?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  dc_id?: Maybe<Order_By>;
  default_practice_id?: Maybe<Order_By>;
  network_color?: Maybe<Order_By>;
  network_description?: Maybe<Order_By>;
  network_external_id?: Maybe<Order_By>;
  network_id?: Maybe<Order_By>;
  network_logo?: Maybe<Order_By>;
  network_name?: Maybe<Order_By>;
  network_number?: Maybe<Order_By>;
  network_slug?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Networks_Min_Fields = {
  __typename?: 'networks_min_fields';
  broker_disclaimer?: Maybe<Scalars['String']>;
  broker_email?: Maybe<Scalars['String']>;
  broker_name?: Maybe<Scalars['String']>;
  broker_phone?: Maybe<Scalars['String']>;
  broker_phone_for_emails?: Maybe<Scalars['String']>;
  broker_phone_for_letters?: Maybe<Scalars['String']>;
  broker_url_for_emails?: Maybe<Scalars['String']>;
  broker_url_for_letters?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  dc_id?: Maybe<Scalars['String']>;
  default_practice_id?: Maybe<Scalars['String']>;
  network_color?: Maybe<Scalars['String']>;
  network_description?: Maybe<Scalars['String']>;
  network_external_id?: Maybe<Scalars['String']>;
  network_id?: Maybe<Scalars['String']>;
  network_logo?: Maybe<Scalars['String']>;
  network_name?: Maybe<Scalars['String']>;
  network_number?: Maybe<Scalars['Int']>;
  network_slug?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "networks" */
export type Networks_Min_Order_By = {
  broker_disclaimer?: Maybe<Order_By>;
  broker_email?: Maybe<Order_By>;
  broker_name?: Maybe<Order_By>;
  broker_phone?: Maybe<Order_By>;
  broker_phone_for_emails?: Maybe<Order_By>;
  broker_phone_for_letters?: Maybe<Order_By>;
  broker_url_for_emails?: Maybe<Order_By>;
  broker_url_for_letters?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  dc_id?: Maybe<Order_By>;
  default_practice_id?: Maybe<Order_By>;
  network_color?: Maybe<Order_By>;
  network_description?: Maybe<Order_By>;
  network_external_id?: Maybe<Order_By>;
  network_id?: Maybe<Order_By>;
  network_logo?: Maybe<Order_By>;
  network_name?: Maybe<Order_By>;
  network_number?: Maybe<Order_By>;
  network_slug?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "networks" */
export type Networks_Mutation_Response = {
  __typename?: 'networks_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Networks>;
};

/** input type for inserting object relation for remote table "networks" */
export type Networks_Obj_Rel_Insert_Input = {
  data: Networks_Insert_Input;
  on_conflict?: Maybe<Networks_On_Conflict>;
};

/** on conflict condition type for table "networks" */
export type Networks_On_Conflict = {
  constraint: Networks_Constraint;
  update_columns: Array<Networks_Update_Column>;
  where?: Maybe<Networks_Bool_Exp>;
};

/** ordering options when selecting data from "networks" */
export type Networks_Order_By = {
  broker_disclaimer?: Maybe<Order_By>;
  broker_email?: Maybe<Order_By>;
  broker_name?: Maybe<Order_By>;
  broker_phone?: Maybe<Order_By>;
  broker_phone_for_emails?: Maybe<Order_By>;
  broker_phone_for_letters?: Maybe<Order_By>;
  broker_url_for_emails?: Maybe<Order_By>;
  broker_url_for_letters?: Maybe<Order_By>;
  campaign_templates_aggregate?: Maybe<Campaign_Templates_Aggregate_Order_By>;
  created_at?: Maybe<Order_By>;
  dc_id?: Maybe<Order_By>;
  default_practice?: Maybe<Practices_Order_By>;
  default_practice_id?: Maybe<Order_By>;
  health_equity_reports_aggregate?: Maybe<Health_Equity_Reports_Aggregate_Order_By>;
  is_archived?: Maybe<Order_By>;
  network_color?: Maybe<Order_By>;
  network_description?: Maybe<Order_By>;
  network_external_id?: Maybe<Order_By>;
  network_id?: Maybe<Order_By>;
  network_logo?: Maybe<Order_By>;
  network_merge_vars?: Maybe<Order_By>;
  network_name?: Maybe<Order_By>;
  network_number?: Maybe<Order_By>;
  network_slug?: Maybe<Order_By>;
  organization_networks_aggregate?: Maybe<Organization_Networks_Aggregate_Order_By>;
  practices_aggregate?: Maybe<Practices_Aggregate_Order_By>;
  provider_memberships_aggregate?: Maybe<Provider_Memberships_Aggregate_Order_By>;
  pvas_aggregate?: Maybe<Pvas_Aggregate_Order_By>;
  updated_at?: Maybe<Order_By>;
  users_aggregate?: Maybe<Users_Aggregate_Order_By>;
};

/** primary key columns input for table: "networks" */
export type Networks_Pk_Columns_Input = {
  network_id: Scalars['String'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Networks_Prepend_Input = {
  network_merge_vars?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "networks" */
export enum Networks_Select_Column {
  /** column name */
  BrokerDisclaimer = 'broker_disclaimer',
  /** column name */
  BrokerEmail = 'broker_email',
  /** column name */
  BrokerName = 'broker_name',
  /** column name */
  BrokerPhone = 'broker_phone',
  /** column name */
  BrokerPhoneForEmails = 'broker_phone_for_emails',
  /** column name */
  BrokerPhoneForLetters = 'broker_phone_for_letters',
  /** column name */
  BrokerUrlForEmails = 'broker_url_for_emails',
  /** column name */
  BrokerUrlForLetters = 'broker_url_for_letters',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DcId = 'dc_id',
  /** column name */
  DefaultPracticeId = 'default_practice_id',
  /** column name */
  IsArchived = 'is_archived',
  /** column name */
  NetworkColor = 'network_color',
  /** column name */
  NetworkDescription = 'network_description',
  /** column name */
  NetworkExternalId = 'network_external_id',
  /** column name */
  NetworkId = 'network_id',
  /** column name */
  NetworkLogo = 'network_logo',
  /** column name */
  NetworkMergeVars = 'network_merge_vars',
  /** column name */
  NetworkName = 'network_name',
  /** column name */
  NetworkNumber = 'network_number',
  /** column name */
  NetworkSlug = 'network_slug',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "networks" */
export type Networks_Set_Input = {
  broker_disclaimer?: Maybe<Scalars['String']>;
  broker_email?: Maybe<Scalars['String']>;
  broker_name?: Maybe<Scalars['String']>;
  broker_phone?: Maybe<Scalars['String']>;
  broker_phone_for_emails?: Maybe<Scalars['String']>;
  broker_phone_for_letters?: Maybe<Scalars['String']>;
  broker_url_for_emails?: Maybe<Scalars['String']>;
  broker_url_for_letters?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  dc_id?: Maybe<Scalars['String']>;
  default_practice_id?: Maybe<Scalars['String']>;
  is_archived?: Maybe<Scalars['Boolean']>;
  network_color?: Maybe<Scalars['String']>;
  network_description?: Maybe<Scalars['String']>;
  network_external_id?: Maybe<Scalars['String']>;
  network_id?: Maybe<Scalars['String']>;
  network_logo?: Maybe<Scalars['String']>;
  network_merge_vars?: Maybe<Scalars['jsonb']>;
  network_name?: Maybe<Scalars['String']>;
  network_number?: Maybe<Scalars['Int']>;
  network_slug?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Networks_Stddev_Fields = {
  __typename?: 'networks_stddev_fields';
  network_number?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "networks" */
export type Networks_Stddev_Order_By = {
  network_number?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Networks_Stddev_Pop_Fields = {
  __typename?: 'networks_stddev_pop_fields';
  network_number?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "networks" */
export type Networks_Stddev_Pop_Order_By = {
  network_number?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Networks_Stddev_Samp_Fields = {
  __typename?: 'networks_stddev_samp_fields';
  network_number?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "networks" */
export type Networks_Stddev_Samp_Order_By = {
  network_number?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Networks_Sum_Fields = {
  __typename?: 'networks_sum_fields';
  network_number?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "networks" */
export type Networks_Sum_Order_By = {
  network_number?: Maybe<Order_By>;
};

/** update columns of table "networks" */
export enum Networks_Update_Column {
  /** column name */
  BrokerDisclaimer = 'broker_disclaimer',
  /** column name */
  BrokerEmail = 'broker_email',
  /** column name */
  BrokerName = 'broker_name',
  /** column name */
  BrokerPhone = 'broker_phone',
  /** column name */
  BrokerPhoneForEmails = 'broker_phone_for_emails',
  /** column name */
  BrokerPhoneForLetters = 'broker_phone_for_letters',
  /** column name */
  BrokerUrlForEmails = 'broker_url_for_emails',
  /** column name */
  BrokerUrlForLetters = 'broker_url_for_letters',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DcId = 'dc_id',
  /** column name */
  DefaultPracticeId = 'default_practice_id',
  /** column name */
  IsArchived = 'is_archived',
  /** column name */
  NetworkColor = 'network_color',
  /** column name */
  NetworkDescription = 'network_description',
  /** column name */
  NetworkExternalId = 'network_external_id',
  /** column name */
  NetworkId = 'network_id',
  /** column name */
  NetworkLogo = 'network_logo',
  /** column name */
  NetworkMergeVars = 'network_merge_vars',
  /** column name */
  NetworkName = 'network_name',
  /** column name */
  NetworkNumber = 'network_number',
  /** column name */
  NetworkSlug = 'network_slug',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Networks_Var_Pop_Fields = {
  __typename?: 'networks_var_pop_fields';
  network_number?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "networks" */
export type Networks_Var_Pop_Order_By = {
  network_number?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Networks_Var_Samp_Fields = {
  __typename?: 'networks_var_samp_fields';
  network_number?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "networks" */
export type Networks_Var_Samp_Order_By = {
  network_number?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Networks_Variance_Fields = {
  __typename?: 'networks_variance_fields';
  network_number?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "networks" */
export type Networks_Variance_Order_By = {
  network_number?: Maybe<Order_By>;
};


/** expression to compare columns of type numeric. All fields are combined with logical 'AND'. */
export type Numeric_Comparison_Exp = {
  _eq?: Maybe<Scalars['numeric']>;
  _gt?: Maybe<Scalars['numeric']>;
  _gte?: Maybe<Scalars['numeric']>;
  _in?: Maybe<Array<Scalars['numeric']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['numeric']>;
  _lte?: Maybe<Scalars['numeric']>;
  _neq?: Maybe<Scalars['numeric']>;
  _nin?: Maybe<Array<Scalars['numeric']>>;
};


/** expression to compare columns of type oid. All fields are combined with logical 'AND'. */
export type Oid_Comparison_Exp = {
  _eq?: Maybe<Scalars['oid']>;
  _gt?: Maybe<Scalars['oid']>;
  _gte?: Maybe<Scalars['oid']>;
  _in?: Maybe<Array<Scalars['oid']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['oid']>;
  _lte?: Maybe<Scalars['oid']>;
  _neq?: Maybe<Scalars['oid']>;
  _nin?: Maybe<Array<Scalars['oid']>>;
};

/** column ordering options */
export enum Order_By {
  /** in the ascending order, nulls last */
  Asc = 'asc',
  /** in the ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in the ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in the descending order, nulls first */
  Desc = 'desc',
  /** in the descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in the descending order, nulls last */
  DescNullsLast = 'desc_nulls_last'
}

/** columns and relationships of "organization_networks" */
export type Organization_Networks = {
  __typename?: 'organization_networks';
  created_at: Scalars['timestamptz'];
  is_archived: Scalars['Boolean'];
  /** An object relationship */
  network: Networks;
  network_id: Scalars['String'];
  /** An object relationship */
  organization: Organizations;
  organization_id: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "organization_networks" */
export type Organization_Networks_Aggregate = {
  __typename?: 'organization_networks_aggregate';
  aggregate?: Maybe<Organization_Networks_Aggregate_Fields>;
  nodes: Array<Organization_Networks>;
};

/** aggregate fields of "organization_networks" */
export type Organization_Networks_Aggregate_Fields = {
  __typename?: 'organization_networks_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Organization_Networks_Max_Fields>;
  min?: Maybe<Organization_Networks_Min_Fields>;
};


/** aggregate fields of "organization_networks" */
export type Organization_Networks_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Organization_Networks_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "organization_networks" */
export type Organization_Networks_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Organization_Networks_Max_Order_By>;
  min?: Maybe<Organization_Networks_Min_Order_By>;
};

/** input type for inserting array relation for remote table "organization_networks" */
export type Organization_Networks_Arr_Rel_Insert_Input = {
  data: Array<Organization_Networks_Insert_Input>;
  on_conflict?: Maybe<Organization_Networks_On_Conflict>;
};

/** Boolean expression to filter rows from the table "organization_networks". All fields are combined with a logical 'AND'. */
export type Organization_Networks_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Organization_Networks_Bool_Exp>>>;
  _not?: Maybe<Organization_Networks_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Organization_Networks_Bool_Exp>>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  is_archived?: Maybe<Boolean_Comparison_Exp>;
  network?: Maybe<Networks_Bool_Exp>;
  network_id?: Maybe<String_Comparison_Exp>;
  organization?: Maybe<Organizations_Bool_Exp>;
  organization_id?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "organization_networks" */
export enum Organization_Networks_Constraint {
  /** unique or primary key constraint */
  OrganizationNetworksPkey = 'organization_networks_pkey'
}

/** input type for inserting data into table "organization_networks" */
export type Organization_Networks_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  is_archived?: Maybe<Scalars['Boolean']>;
  network?: Maybe<Networks_Obj_Rel_Insert_Input>;
  network_id?: Maybe<Scalars['String']>;
  organization?: Maybe<Organizations_Obj_Rel_Insert_Input>;
  organization_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Organization_Networks_Max_Fields = {
  __typename?: 'organization_networks_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  network_id?: Maybe<Scalars['String']>;
  organization_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "organization_networks" */
export type Organization_Networks_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  network_id?: Maybe<Order_By>;
  organization_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Organization_Networks_Min_Fields = {
  __typename?: 'organization_networks_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  network_id?: Maybe<Scalars['String']>;
  organization_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "organization_networks" */
export type Organization_Networks_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  network_id?: Maybe<Order_By>;
  organization_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "organization_networks" */
export type Organization_Networks_Mutation_Response = {
  __typename?: 'organization_networks_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Organization_Networks>;
};

/** input type for inserting object relation for remote table "organization_networks" */
export type Organization_Networks_Obj_Rel_Insert_Input = {
  data: Organization_Networks_Insert_Input;
  on_conflict?: Maybe<Organization_Networks_On_Conflict>;
};

/** on conflict condition type for table "organization_networks" */
export type Organization_Networks_On_Conflict = {
  constraint: Organization_Networks_Constraint;
  update_columns: Array<Organization_Networks_Update_Column>;
  where?: Maybe<Organization_Networks_Bool_Exp>;
};

/** ordering options when selecting data from "organization_networks" */
export type Organization_Networks_Order_By = {
  created_at?: Maybe<Order_By>;
  is_archived?: Maybe<Order_By>;
  network?: Maybe<Networks_Order_By>;
  network_id?: Maybe<Order_By>;
  organization?: Maybe<Organizations_Order_By>;
  organization_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: "organization_networks" */
export type Organization_Networks_Pk_Columns_Input = {
  network_id: Scalars['String'];
  organization_id: Scalars['String'];
};

/** select columns of table "organization_networks" */
export enum Organization_Networks_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  IsArchived = 'is_archived',
  /** column name */
  NetworkId = 'network_id',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "organization_networks" */
export type Organization_Networks_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  is_archived?: Maybe<Scalars['Boolean']>;
  network_id?: Maybe<Scalars['String']>;
  organization_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "organization_networks" */
export enum Organization_Networks_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  IsArchived = 'is_archived',
  /** column name */
  NetworkId = 'network_id',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** columns and relationships of "organizations" */
export type Organizations = {
  __typename?: 'organizations';
  created_at: Scalars['timestamptz'];
  dashboards: Scalars['jsonb'];
  is_archived: Scalars['Boolean'];
  organization_description?: Maybe<Scalars['String']>;
  organization_id: Scalars['String'];
  organization_name: Scalars['String'];
  /** An array relationship */
  organization_networks: Array<Organization_Networks>;
  /** An aggregated array relationship */
  organization_networks_aggregate: Organization_Networks_Aggregate;
  updated_at: Scalars['timestamptz'];
  /** An array relationship */
  users: Array<Users>;
  /** An aggregated array relationship */
  users_aggregate: Users_Aggregate;
};


/** columns and relationships of "organizations" */
export type OrganizationsDashboardsArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "organizations" */
export type OrganizationsOrganization_NetworksArgs = {
  distinct_on?: Maybe<Array<Organization_Networks_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Organization_Networks_Order_By>>;
  where?: Maybe<Organization_Networks_Bool_Exp>;
};


/** columns and relationships of "organizations" */
export type OrganizationsOrganization_Networks_AggregateArgs = {
  distinct_on?: Maybe<Array<Organization_Networks_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Organization_Networks_Order_By>>;
  where?: Maybe<Organization_Networks_Bool_Exp>;
};


/** columns and relationships of "organizations" */
export type OrganizationsUsersArgs = {
  distinct_on?: Maybe<Array<Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Users_Order_By>>;
  where?: Maybe<Users_Bool_Exp>;
};


/** columns and relationships of "organizations" */
export type OrganizationsUsers_AggregateArgs = {
  distinct_on?: Maybe<Array<Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Users_Order_By>>;
  where?: Maybe<Users_Bool_Exp>;
};

/** aggregated selection of "organizations" */
export type Organizations_Aggregate = {
  __typename?: 'organizations_aggregate';
  aggregate?: Maybe<Organizations_Aggregate_Fields>;
  nodes: Array<Organizations>;
};

/** aggregate fields of "organizations" */
export type Organizations_Aggregate_Fields = {
  __typename?: 'organizations_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Organizations_Max_Fields>;
  min?: Maybe<Organizations_Min_Fields>;
};


/** aggregate fields of "organizations" */
export type Organizations_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Organizations_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "organizations" */
export type Organizations_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Organizations_Max_Order_By>;
  min?: Maybe<Organizations_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Organizations_Append_Input = {
  dashboards?: Maybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "organizations" */
export type Organizations_Arr_Rel_Insert_Input = {
  data: Array<Organizations_Insert_Input>;
  on_conflict?: Maybe<Organizations_On_Conflict>;
};

/** Boolean expression to filter rows from the table "organizations". All fields are combined with a logical 'AND'. */
export type Organizations_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Organizations_Bool_Exp>>>;
  _not?: Maybe<Organizations_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Organizations_Bool_Exp>>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  dashboards?: Maybe<Jsonb_Comparison_Exp>;
  is_archived?: Maybe<Boolean_Comparison_Exp>;
  organization_description?: Maybe<String_Comparison_Exp>;
  organization_id?: Maybe<String_Comparison_Exp>;
  organization_name?: Maybe<String_Comparison_Exp>;
  organization_networks?: Maybe<Organization_Networks_Bool_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  users?: Maybe<Users_Bool_Exp>;
};

/** unique or primary key constraints on table "organizations" */
export enum Organizations_Constraint {
  /** unique or primary key constraint */
  OrganizationsPkey = 'organizations_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Organizations_Delete_At_Path_Input = {
  dashboards?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Organizations_Delete_Elem_Input = {
  dashboards?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Organizations_Delete_Key_Input = {
  dashboards?: Maybe<Scalars['String']>;
};

/** input type for inserting data into table "organizations" */
export type Organizations_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  dashboards?: Maybe<Scalars['jsonb']>;
  is_archived?: Maybe<Scalars['Boolean']>;
  organization_description?: Maybe<Scalars['String']>;
  organization_id?: Maybe<Scalars['String']>;
  organization_name?: Maybe<Scalars['String']>;
  organization_networks?: Maybe<Organization_Networks_Arr_Rel_Insert_Input>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  users?: Maybe<Users_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Organizations_Max_Fields = {
  __typename?: 'organizations_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  organization_description?: Maybe<Scalars['String']>;
  organization_id?: Maybe<Scalars['String']>;
  organization_name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "organizations" */
export type Organizations_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  organization_description?: Maybe<Order_By>;
  organization_id?: Maybe<Order_By>;
  organization_name?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Organizations_Min_Fields = {
  __typename?: 'organizations_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  organization_description?: Maybe<Scalars['String']>;
  organization_id?: Maybe<Scalars['String']>;
  organization_name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "organizations" */
export type Organizations_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  organization_description?: Maybe<Order_By>;
  organization_id?: Maybe<Order_By>;
  organization_name?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "organizations" */
export type Organizations_Mutation_Response = {
  __typename?: 'organizations_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Organizations>;
};

/** input type for inserting object relation for remote table "organizations" */
export type Organizations_Obj_Rel_Insert_Input = {
  data: Organizations_Insert_Input;
  on_conflict?: Maybe<Organizations_On_Conflict>;
};

/** on conflict condition type for table "organizations" */
export type Organizations_On_Conflict = {
  constraint: Organizations_Constraint;
  update_columns: Array<Organizations_Update_Column>;
  where?: Maybe<Organizations_Bool_Exp>;
};

/** ordering options when selecting data from "organizations" */
export type Organizations_Order_By = {
  created_at?: Maybe<Order_By>;
  dashboards?: Maybe<Order_By>;
  is_archived?: Maybe<Order_By>;
  organization_description?: Maybe<Order_By>;
  organization_id?: Maybe<Order_By>;
  organization_name?: Maybe<Order_By>;
  organization_networks_aggregate?: Maybe<Organization_Networks_Aggregate_Order_By>;
  updated_at?: Maybe<Order_By>;
  users_aggregate?: Maybe<Users_Aggregate_Order_By>;
};

/** primary key columns input for table: "organizations" */
export type Organizations_Pk_Columns_Input = {
  organization_id: Scalars['String'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Organizations_Prepend_Input = {
  dashboards?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "organizations" */
export enum Organizations_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Dashboards = 'dashboards',
  /** column name */
  IsArchived = 'is_archived',
  /** column name */
  OrganizationDescription = 'organization_description',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  OrganizationName = 'organization_name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "organizations" */
export type Organizations_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  dashboards?: Maybe<Scalars['jsonb']>;
  is_archived?: Maybe<Scalars['Boolean']>;
  organization_description?: Maybe<Scalars['String']>;
  organization_id?: Maybe<Scalars['String']>;
  organization_name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "organizations" */
export enum Organizations_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Dashboards = 'dashboards',
  /** column name */
  IsArchived = 'is_archived',
  /** column name */
  OrganizationDescription = 'organization_description',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  OrganizationName = 'organization_name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** columns and relationships of "patient_alignment_labels" */
export type Patient_Alignment_Labels = {
  __typename?: 'patient_alignment_labels';
  alignment_status?: Maybe<Scalars['String']>;
  patient_id?: Maybe<Scalars['String']>;
};

/** aggregated selection of "patient_alignment_labels" */
export type Patient_Alignment_Labels_Aggregate = {
  __typename?: 'patient_alignment_labels_aggregate';
  aggregate?: Maybe<Patient_Alignment_Labels_Aggregate_Fields>;
  nodes: Array<Patient_Alignment_Labels>;
};

/** aggregate fields of "patient_alignment_labels" */
export type Patient_Alignment_Labels_Aggregate_Fields = {
  __typename?: 'patient_alignment_labels_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Patient_Alignment_Labels_Max_Fields>;
  min?: Maybe<Patient_Alignment_Labels_Min_Fields>;
};


/** aggregate fields of "patient_alignment_labels" */
export type Patient_Alignment_Labels_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Patient_Alignment_Labels_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "patient_alignment_labels" */
export type Patient_Alignment_Labels_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Patient_Alignment_Labels_Max_Order_By>;
  min?: Maybe<Patient_Alignment_Labels_Min_Order_By>;
};

/** Boolean expression to filter rows from the table "patient_alignment_labels". All fields are combined with a logical 'AND'. */
export type Patient_Alignment_Labels_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Patient_Alignment_Labels_Bool_Exp>>>;
  _not?: Maybe<Patient_Alignment_Labels_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Patient_Alignment_Labels_Bool_Exp>>>;
  alignment_status?: Maybe<String_Comparison_Exp>;
  patient_id?: Maybe<String_Comparison_Exp>;
};

/** aggregate max on columns */
export type Patient_Alignment_Labels_Max_Fields = {
  __typename?: 'patient_alignment_labels_max_fields';
  alignment_status?: Maybe<Scalars['String']>;
  patient_id?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "patient_alignment_labels" */
export type Patient_Alignment_Labels_Max_Order_By = {
  alignment_status?: Maybe<Order_By>;
  patient_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Patient_Alignment_Labels_Min_Fields = {
  __typename?: 'patient_alignment_labels_min_fields';
  alignment_status?: Maybe<Scalars['String']>;
  patient_id?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "patient_alignment_labels" */
export type Patient_Alignment_Labels_Min_Order_By = {
  alignment_status?: Maybe<Order_By>;
  patient_id?: Maybe<Order_By>;
};

/** ordering options when selecting data from "patient_alignment_labels" */
export type Patient_Alignment_Labels_Order_By = {
  alignment_status?: Maybe<Order_By>;
  patient_id?: Maybe<Order_By>;
};

/** select columns of table "patient_alignment_labels" */
export enum Patient_Alignment_Labels_Select_Column {
  /** column name */
  AlignmentStatus = 'alignment_status',
  /** column name */
  PatientId = 'patient_id'
}

/** columns and relationships of "patient_hedr_labels" */
export type Patient_Hedr_Labels = {
  __typename?: 'patient_hedr_labels';
  hedr_demographic_status?: Maybe<Scalars['String']>;
  hedr_sdoh_status_2024?: Maybe<Scalars['String']>;
  hedr_status?: Maybe<Scalars['String']>;
  patient_id?: Maybe<Scalars['String']>;
};

/** aggregated selection of "patient_hedr_labels" */
export type Patient_Hedr_Labels_Aggregate = {
  __typename?: 'patient_hedr_labels_aggregate';
  aggregate?: Maybe<Patient_Hedr_Labels_Aggregate_Fields>;
  nodes: Array<Patient_Hedr_Labels>;
};

/** aggregate fields of "patient_hedr_labels" */
export type Patient_Hedr_Labels_Aggregate_Fields = {
  __typename?: 'patient_hedr_labels_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Patient_Hedr_Labels_Max_Fields>;
  min?: Maybe<Patient_Hedr_Labels_Min_Fields>;
};


/** aggregate fields of "patient_hedr_labels" */
export type Patient_Hedr_Labels_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Patient_Hedr_Labels_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "patient_hedr_labels" */
export type Patient_Hedr_Labels_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Patient_Hedr_Labels_Max_Order_By>;
  min?: Maybe<Patient_Hedr_Labels_Min_Order_By>;
};

/** Boolean expression to filter rows from the table "patient_hedr_labels". All fields are combined with a logical 'AND'. */
export type Patient_Hedr_Labels_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Patient_Hedr_Labels_Bool_Exp>>>;
  _not?: Maybe<Patient_Hedr_Labels_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Patient_Hedr_Labels_Bool_Exp>>>;
  hedr_demographic_status?: Maybe<String_Comparison_Exp>;
  hedr_sdoh_status_2024?: Maybe<String_Comparison_Exp>;
  hedr_status?: Maybe<String_Comparison_Exp>;
  patient_id?: Maybe<String_Comparison_Exp>;
};

/** aggregate max on columns */
export type Patient_Hedr_Labels_Max_Fields = {
  __typename?: 'patient_hedr_labels_max_fields';
  hedr_demographic_status?: Maybe<Scalars['String']>;
  hedr_sdoh_status_2024?: Maybe<Scalars['String']>;
  hedr_status?: Maybe<Scalars['String']>;
  patient_id?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "patient_hedr_labels" */
export type Patient_Hedr_Labels_Max_Order_By = {
  hedr_demographic_status?: Maybe<Order_By>;
  hedr_sdoh_status_2024?: Maybe<Order_By>;
  hedr_status?: Maybe<Order_By>;
  patient_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Patient_Hedr_Labels_Min_Fields = {
  __typename?: 'patient_hedr_labels_min_fields';
  hedr_demographic_status?: Maybe<Scalars['String']>;
  hedr_sdoh_status_2024?: Maybe<Scalars['String']>;
  hedr_status?: Maybe<Scalars['String']>;
  patient_id?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "patient_hedr_labels" */
export type Patient_Hedr_Labels_Min_Order_By = {
  hedr_demographic_status?: Maybe<Order_By>;
  hedr_sdoh_status_2024?: Maybe<Order_By>;
  hedr_status?: Maybe<Order_By>;
  patient_id?: Maybe<Order_By>;
};

/** ordering options when selecting data from "patient_hedr_labels" */
export type Patient_Hedr_Labels_Order_By = {
  hedr_demographic_status?: Maybe<Order_By>;
  hedr_sdoh_status_2024?: Maybe<Order_By>;
  hedr_status?: Maybe<Order_By>;
  patient_id?: Maybe<Order_By>;
};

/** select columns of table "patient_hedr_labels" */
export enum Patient_Hedr_Labels_Select_Column {
  /** column name */
  HedrDemographicStatus = 'hedr_demographic_status',
  /** column name */
  HedrSdohStatus_2024 = 'hedr_sdoh_status_2024',
  /** column name */
  HedrStatus = 'hedr_status',
  /** column name */
  PatientId = 'patient_id'
}

/**
 * Patient roster uploads at the practice level. The tuple of (identity_id, level, key) is needed by Amplify to retrieve file in S3. 
 * 
 * 
 * columns and relationships of "patient_roster_files"
 */
export type Patient_Roster_Files = {
  __typename?: 'patient_roster_files';
  created_at: Scalars['timestamptz'];
  field_mapping?: Maybe<Scalars['jsonb']>;
  identity_id: Scalars['String'];
  is_archived: Scalars['Boolean'];
  key: Scalars['String'];
  level: Scalars['String'];
  /** An object relationship */
  practice: Practices;
  practice_id: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};


/**
 * Patient roster uploads at the practice level. The tuple of (identity_id, level, key) is needed by Amplify to retrieve file in S3. 
 * 
 * 
 * columns and relationships of "patient_roster_files"
 */
export type Patient_Roster_FilesField_MappingArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "patient_roster_files" */
export type Patient_Roster_Files_Aggregate = {
  __typename?: 'patient_roster_files_aggregate';
  aggregate?: Maybe<Patient_Roster_Files_Aggregate_Fields>;
  nodes: Array<Patient_Roster_Files>;
};

/** aggregate fields of "patient_roster_files" */
export type Patient_Roster_Files_Aggregate_Fields = {
  __typename?: 'patient_roster_files_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Patient_Roster_Files_Max_Fields>;
  min?: Maybe<Patient_Roster_Files_Min_Fields>;
};


/** aggregate fields of "patient_roster_files" */
export type Patient_Roster_Files_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Patient_Roster_Files_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "patient_roster_files" */
export type Patient_Roster_Files_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Patient_Roster_Files_Max_Order_By>;
  min?: Maybe<Patient_Roster_Files_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Patient_Roster_Files_Append_Input = {
  field_mapping?: Maybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "patient_roster_files" */
export type Patient_Roster_Files_Arr_Rel_Insert_Input = {
  data: Array<Patient_Roster_Files_Insert_Input>;
  on_conflict?: Maybe<Patient_Roster_Files_On_Conflict>;
};

/** Boolean expression to filter rows from the table "patient_roster_files". All fields are combined with a logical 'AND'. */
export type Patient_Roster_Files_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Patient_Roster_Files_Bool_Exp>>>;
  _not?: Maybe<Patient_Roster_Files_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Patient_Roster_Files_Bool_Exp>>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  field_mapping?: Maybe<Jsonb_Comparison_Exp>;
  identity_id?: Maybe<String_Comparison_Exp>;
  is_archived?: Maybe<Boolean_Comparison_Exp>;
  key?: Maybe<String_Comparison_Exp>;
  level?: Maybe<String_Comparison_Exp>;
  practice?: Maybe<Practices_Bool_Exp>;
  practice_id?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "patient_roster_files" */
export enum Patient_Roster_Files_Constraint {
  /** unique or primary key constraint */
  PatientRosterFilesPkey = 'patient_roster_files_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Patient_Roster_Files_Delete_At_Path_Input = {
  field_mapping?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Patient_Roster_Files_Delete_Elem_Input = {
  field_mapping?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Patient_Roster_Files_Delete_Key_Input = {
  field_mapping?: Maybe<Scalars['String']>;
};

/** input type for inserting data into table "patient_roster_files" */
export type Patient_Roster_Files_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  field_mapping?: Maybe<Scalars['jsonb']>;
  identity_id?: Maybe<Scalars['String']>;
  is_archived?: Maybe<Scalars['Boolean']>;
  key?: Maybe<Scalars['String']>;
  level?: Maybe<Scalars['String']>;
  practice?: Maybe<Practices_Obj_Rel_Insert_Input>;
  practice_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Patient_Roster_Files_Max_Fields = {
  __typename?: 'patient_roster_files_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  identity_id?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
  level?: Maybe<Scalars['String']>;
  practice_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "patient_roster_files" */
export type Patient_Roster_Files_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  identity_id?: Maybe<Order_By>;
  key?: Maybe<Order_By>;
  level?: Maybe<Order_By>;
  practice_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Patient_Roster_Files_Min_Fields = {
  __typename?: 'patient_roster_files_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  identity_id?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
  level?: Maybe<Scalars['String']>;
  practice_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "patient_roster_files" */
export type Patient_Roster_Files_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  identity_id?: Maybe<Order_By>;
  key?: Maybe<Order_By>;
  level?: Maybe<Order_By>;
  practice_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "patient_roster_files" */
export type Patient_Roster_Files_Mutation_Response = {
  __typename?: 'patient_roster_files_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Patient_Roster_Files>;
};

/** input type for inserting object relation for remote table "patient_roster_files" */
export type Patient_Roster_Files_Obj_Rel_Insert_Input = {
  data: Patient_Roster_Files_Insert_Input;
  on_conflict?: Maybe<Patient_Roster_Files_On_Conflict>;
};

/** on conflict condition type for table "patient_roster_files" */
export type Patient_Roster_Files_On_Conflict = {
  constraint: Patient_Roster_Files_Constraint;
  update_columns: Array<Patient_Roster_Files_Update_Column>;
  where?: Maybe<Patient_Roster_Files_Bool_Exp>;
};

/** ordering options when selecting data from "patient_roster_files" */
export type Patient_Roster_Files_Order_By = {
  created_at?: Maybe<Order_By>;
  field_mapping?: Maybe<Order_By>;
  identity_id?: Maybe<Order_By>;
  is_archived?: Maybe<Order_By>;
  key?: Maybe<Order_By>;
  level?: Maybe<Order_By>;
  practice?: Maybe<Practices_Order_By>;
  practice_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: "patient_roster_files" */
export type Patient_Roster_Files_Pk_Columns_Input = {
  identity_id: Scalars['String'];
  key: Scalars['String'];
  level: Scalars['String'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Patient_Roster_Files_Prepend_Input = {
  field_mapping?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "patient_roster_files" */
export enum Patient_Roster_Files_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FieldMapping = 'field_mapping',
  /** column name */
  IdentityId = 'identity_id',
  /** column name */
  IsArchived = 'is_archived',
  /** column name */
  Key = 'key',
  /** column name */
  Level = 'level',
  /** column name */
  PracticeId = 'practice_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "patient_roster_files" */
export type Patient_Roster_Files_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  field_mapping?: Maybe<Scalars['jsonb']>;
  identity_id?: Maybe<Scalars['String']>;
  is_archived?: Maybe<Scalars['Boolean']>;
  key?: Maybe<Scalars['String']>;
  level?: Maybe<Scalars['String']>;
  practice_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "patient_roster_files" */
export enum Patient_Roster_Files_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FieldMapping = 'field_mapping',
  /** column name */
  IdentityId = 'identity_id',
  /** column name */
  IsArchived = 'is_archived',
  /** column name */
  Key = 'key',
  /** column name */
  Level = 'level',
  /** column name */
  PracticeId = 'practice_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** columns and relationships of "patient_segment_labels" */
export type Patient_Segment_Labels = {
  __typename?: 'patient_segment_labels';
  /** An object relationship */
  patient?: Maybe<Patients>;
  patient_id?: Maybe<Scalars['String']>;
  patient_segment_id?: Maybe<Scalars['String']>;
};

/** aggregated selection of "patient_segment_labels" */
export type Patient_Segment_Labels_Aggregate = {
  __typename?: 'patient_segment_labels_aggregate';
  aggregate?: Maybe<Patient_Segment_Labels_Aggregate_Fields>;
  nodes: Array<Patient_Segment_Labels>;
};

/** aggregate fields of "patient_segment_labels" */
export type Patient_Segment_Labels_Aggregate_Fields = {
  __typename?: 'patient_segment_labels_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Patient_Segment_Labels_Max_Fields>;
  min?: Maybe<Patient_Segment_Labels_Min_Fields>;
};


/** aggregate fields of "patient_segment_labels" */
export type Patient_Segment_Labels_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Patient_Segment_Labels_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "patient_segment_labels" */
export type Patient_Segment_Labels_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Patient_Segment_Labels_Max_Order_By>;
  min?: Maybe<Patient_Segment_Labels_Min_Order_By>;
};

/** Boolean expression to filter rows from the table "patient_segment_labels". All fields are combined with a logical 'AND'. */
export type Patient_Segment_Labels_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Patient_Segment_Labels_Bool_Exp>>>;
  _not?: Maybe<Patient_Segment_Labels_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Patient_Segment_Labels_Bool_Exp>>>;
  patient?: Maybe<Patients_Bool_Exp>;
  patient_id?: Maybe<String_Comparison_Exp>;
  patient_segment_id?: Maybe<String_Comparison_Exp>;
};

/** aggregate max on columns */
export type Patient_Segment_Labels_Max_Fields = {
  __typename?: 'patient_segment_labels_max_fields';
  patient_id?: Maybe<Scalars['String']>;
  patient_segment_id?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "patient_segment_labels" */
export type Patient_Segment_Labels_Max_Order_By = {
  patient_id?: Maybe<Order_By>;
  patient_segment_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Patient_Segment_Labels_Min_Fields = {
  __typename?: 'patient_segment_labels_min_fields';
  patient_id?: Maybe<Scalars['String']>;
  patient_segment_id?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "patient_segment_labels" */
export type Patient_Segment_Labels_Min_Order_By = {
  patient_id?: Maybe<Order_By>;
  patient_segment_id?: Maybe<Order_By>;
};

/** ordering options when selecting data from "patient_segment_labels" */
export type Patient_Segment_Labels_Order_By = {
  patient?: Maybe<Patients_Order_By>;
  patient_id?: Maybe<Order_By>;
  patient_segment_id?: Maybe<Order_By>;
};

/** select columns of table "patient_segment_labels" */
export enum Patient_Segment_Labels_Select_Column {
  /** column name */
  PatientId = 'patient_id',
  /** column name */
  PatientSegmentId = 'patient_segment_id'
}

/** columns and relationships of "patient_segments" */
export type Patient_Segments = {
  __typename?: 'patient_segments';
  /** An array relationship */
  campaign_email_components: Array<Campaign_Email_Components>;
  /** An aggregated array relationship */
  campaign_email_components_aggregate: Campaign_Email_Components_Aggregate;
  /** An array relationship */
  campaign_letter_components: Array<Campaign_Letter_Components>;
  /** An aggregated array relationship */
  campaign_letter_components_aggregate: Campaign_Letter_Components_Aggregate;
  patient_segment_description: Scalars['String'];
  patient_segment_id: Scalars['String'];
};


/** columns and relationships of "patient_segments" */
export type Patient_SegmentsCampaign_Email_ComponentsArgs = {
  distinct_on?: Maybe<Array<Campaign_Email_Components_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Campaign_Email_Components_Order_By>>;
  where?: Maybe<Campaign_Email_Components_Bool_Exp>;
};


/** columns and relationships of "patient_segments" */
export type Patient_SegmentsCampaign_Email_Components_AggregateArgs = {
  distinct_on?: Maybe<Array<Campaign_Email_Components_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Campaign_Email_Components_Order_By>>;
  where?: Maybe<Campaign_Email_Components_Bool_Exp>;
};


/** columns and relationships of "patient_segments" */
export type Patient_SegmentsCampaign_Letter_ComponentsArgs = {
  distinct_on?: Maybe<Array<Campaign_Letter_Components_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Campaign_Letter_Components_Order_By>>;
  where?: Maybe<Campaign_Letter_Components_Bool_Exp>;
};


/** columns and relationships of "patient_segments" */
export type Patient_SegmentsCampaign_Letter_Components_AggregateArgs = {
  distinct_on?: Maybe<Array<Campaign_Letter_Components_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Campaign_Letter_Components_Order_By>>;
  where?: Maybe<Campaign_Letter_Components_Bool_Exp>;
};

/** aggregated selection of "patient_segments" */
export type Patient_Segments_Aggregate = {
  __typename?: 'patient_segments_aggregate';
  aggregate?: Maybe<Patient_Segments_Aggregate_Fields>;
  nodes: Array<Patient_Segments>;
};

/** aggregate fields of "patient_segments" */
export type Patient_Segments_Aggregate_Fields = {
  __typename?: 'patient_segments_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Patient_Segments_Max_Fields>;
  min?: Maybe<Patient_Segments_Min_Fields>;
};


/** aggregate fields of "patient_segments" */
export type Patient_Segments_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Patient_Segments_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "patient_segments" */
export type Patient_Segments_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Patient_Segments_Max_Order_By>;
  min?: Maybe<Patient_Segments_Min_Order_By>;
};

/** input type for inserting array relation for remote table "patient_segments" */
export type Patient_Segments_Arr_Rel_Insert_Input = {
  data: Array<Patient_Segments_Insert_Input>;
  on_conflict?: Maybe<Patient_Segments_On_Conflict>;
};

/** Boolean expression to filter rows from the table "patient_segments". All fields are combined with a logical 'AND'. */
export type Patient_Segments_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Patient_Segments_Bool_Exp>>>;
  _not?: Maybe<Patient_Segments_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Patient_Segments_Bool_Exp>>>;
  campaign_email_components?: Maybe<Campaign_Email_Components_Bool_Exp>;
  campaign_letter_components?: Maybe<Campaign_Letter_Components_Bool_Exp>;
  patient_segment_description?: Maybe<String_Comparison_Exp>;
  patient_segment_id?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "patient_segments" */
export enum Patient_Segments_Constraint {
  /** unique or primary key constraint */
  PatientSegmentPkey = 'patient_segment_pkey'
}

/** input type for inserting data into table "patient_segments" */
export type Patient_Segments_Insert_Input = {
  campaign_email_components?: Maybe<Campaign_Email_Components_Arr_Rel_Insert_Input>;
  campaign_letter_components?: Maybe<Campaign_Letter_Components_Arr_Rel_Insert_Input>;
  patient_segment_description?: Maybe<Scalars['String']>;
  patient_segment_id?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Patient_Segments_Max_Fields = {
  __typename?: 'patient_segments_max_fields';
  patient_segment_description?: Maybe<Scalars['String']>;
  patient_segment_id?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "patient_segments" */
export type Patient_Segments_Max_Order_By = {
  patient_segment_description?: Maybe<Order_By>;
  patient_segment_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Patient_Segments_Min_Fields = {
  __typename?: 'patient_segments_min_fields';
  patient_segment_description?: Maybe<Scalars['String']>;
  patient_segment_id?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "patient_segments" */
export type Patient_Segments_Min_Order_By = {
  patient_segment_description?: Maybe<Order_By>;
  patient_segment_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "patient_segments" */
export type Patient_Segments_Mutation_Response = {
  __typename?: 'patient_segments_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Patient_Segments>;
};

/** input type for inserting object relation for remote table "patient_segments" */
export type Patient_Segments_Obj_Rel_Insert_Input = {
  data: Patient_Segments_Insert_Input;
  on_conflict?: Maybe<Patient_Segments_On_Conflict>;
};

/** on conflict condition type for table "patient_segments" */
export type Patient_Segments_On_Conflict = {
  constraint: Patient_Segments_Constraint;
  update_columns: Array<Patient_Segments_Update_Column>;
  where?: Maybe<Patient_Segments_Bool_Exp>;
};

/** ordering options when selecting data from "patient_segments" */
export type Patient_Segments_Order_By = {
  campaign_email_components_aggregate?: Maybe<Campaign_Email_Components_Aggregate_Order_By>;
  campaign_letter_components_aggregate?: Maybe<Campaign_Letter_Components_Aggregate_Order_By>;
  patient_segment_description?: Maybe<Order_By>;
  patient_segment_id?: Maybe<Order_By>;
};

/** primary key columns input for table: "patient_segments" */
export type Patient_Segments_Pk_Columns_Input = {
  patient_segment_id: Scalars['String'];
};

/** select columns of table "patient_segments" */
export enum Patient_Segments_Select_Column {
  /** column name */
  PatientSegmentDescription = 'patient_segment_description',
  /** column name */
  PatientSegmentId = 'patient_segment_id'
}

/** input type for updating data in table "patient_segments" */
export type Patient_Segments_Set_Input = {
  patient_segment_description?: Maybe<Scalars['String']>;
  patient_segment_id?: Maybe<Scalars['String']>;
};

/** update columns of table "patient_segments" */
export enum Patient_Segments_Update_Column {
  /** column name */
  PatientSegmentDescription = 'patient_segment_description',
  /** column name */
  PatientSegmentId = 'patient_segment_id'
}

/** columns and relationships of "patients" */
export type Patients = {
  __typename?: 'patients';
  address_verification_result?: Maybe<Scalars['jsonb']>;
  alignment_tin?: Maybe<Scalars['String']>;
  /** An array relationship */
  alignments: Array<Alignments>;
  /** An aggregated array relationship */
  alignments_aggregate: Alignments_Aggregate;
  birth_date?: Maybe<Scalars['date']>;
  created_at: Scalars['timestamptz'];
  deceased_as_of_date?: Maybe<Scalars['date']>;
  do_not_direct_mail: Scalars['Boolean'];
  do_not_email: Scalars['Boolean'];
  do_not_sms: Scalars['Boolean'];
  email_validation_result?: Maybe<Scalars['jsonb']>;
  /** An array relationship */
  emails: Array<Emails>;
  /** An aggregated array relationship */
  emails_aggregate: Emails_Aggregate;
  external_id?: Maybe<Scalars['String']>;
  is_archived: Scalars['Boolean'];
  last_parsed_record?: Maybe<Scalars['jsonb']>;
  /** An array relationship */
  letters: Array<Letters>;
  /** An aggregated array relationship */
  letters_aggregate: Letters_Aggregate;
  /** Medicare Beneficiary Identifier */
  mbi?: Maybe<Scalars['String']>;
  patient_address?: Maybe<Scalars['String']>;
  /** An object relationship */
  patient_alignment_label?: Maybe<Patient_Alignment_Labels>;
  patient_email?: Maybe<Scalars['String']>;
  /** An object relationship */
  patient_hedr_label?: Maybe<Patient_Hedr_Labels>;
  patient_id: Scalars['String'];
  patient_logo?: Maybe<Scalars['String']>;
  patient_name?: Maybe<Scalars['String']>;
  patient_notes?: Maybe<Scalars['String']>;
  patient_pcp?: Maybe<Scalars['String']>;
  patient_phone?: Maybe<Scalars['String']>;
  /** An object relationship */
  patient_segment_label?: Maybe<Patient_Segment_Labels>;
  /** An object relationship */
  practice?: Maybe<Practices>;
  practice_id: Scalars['String'];
  primary_insurance_group_id?: Maybe<Scalars['String']>;
  primary_insurance_member_id?: Maybe<Scalars['String']>;
  primary_insurance_payer_id?: Maybe<Scalars['String']>;
  primary_insurance_payer_name?: Maybe<Scalars['String']>;
  primary_insurance_plan_name?: Maybe<Scalars['String']>;
  /** An array relationship */
  pvas: Array<Pvas>;
  /** An aggregated array relationship */
  pvas_aggregate: Pvas_Aggregate;
  secondary_insurance_group_id?: Maybe<Scalars['String']>;
  secondary_insurance_member_id?: Maybe<Scalars['String']>;
  secondary_insurance_payer_id?: Maybe<Scalars['String']>;
  secondary_insurance_payer_name?: Maybe<Scalars['String']>;
  secondary_insurance_plan_name?: Maybe<Scalars['String']>;
  sex_assigned_at_birth?: Maybe<Scalars['String']>;
  /** An array relationship */
  sms_messages: Array<Sms_Messages>;
  /** An aggregated array relationship */
  sms_messages_aggregate: Sms_Messages_Aggregate;
  /** An object relationship */
  suspected_provider?: Maybe<Providers>;
  suspected_provider_npi?: Maybe<Scalars['String']>;
  tertiary_insurance_group_id?: Maybe<Scalars['String']>;
  tertiary_insurance_member_id?: Maybe<Scalars['String']>;
  tertiary_insurance_payer_id?: Maybe<Scalars['String']>;
  tertiary_insurance_payer_name?: Maybe<Scalars['String']>;
  tertiary_insurance_plan_name?: Maybe<Scalars['String']>;
  /** DEPRECATED (spelled wrong) */
  tertiery_insurance_group_id?: Maybe<Scalars['String']>;
  /** DEPRECATED (spelled wrong) */
  tertiery_insurance_member_id?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "patients" */
export type PatientsAddress_Verification_ResultArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "patients" */
export type PatientsAlignmentsArgs = {
  distinct_on?: Maybe<Array<Alignments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Alignments_Order_By>>;
  where?: Maybe<Alignments_Bool_Exp>;
};


/** columns and relationships of "patients" */
export type PatientsAlignments_AggregateArgs = {
  distinct_on?: Maybe<Array<Alignments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Alignments_Order_By>>;
  where?: Maybe<Alignments_Bool_Exp>;
};


/** columns and relationships of "patients" */
export type PatientsEmail_Validation_ResultArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "patients" */
export type PatientsEmailsArgs = {
  distinct_on?: Maybe<Array<Emails_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Emails_Order_By>>;
  where?: Maybe<Emails_Bool_Exp>;
};


/** columns and relationships of "patients" */
export type PatientsEmails_AggregateArgs = {
  distinct_on?: Maybe<Array<Emails_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Emails_Order_By>>;
  where?: Maybe<Emails_Bool_Exp>;
};


/** columns and relationships of "patients" */
export type PatientsLast_Parsed_RecordArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "patients" */
export type PatientsLettersArgs = {
  distinct_on?: Maybe<Array<Letters_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Letters_Order_By>>;
  where?: Maybe<Letters_Bool_Exp>;
};


/** columns and relationships of "patients" */
export type PatientsLetters_AggregateArgs = {
  distinct_on?: Maybe<Array<Letters_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Letters_Order_By>>;
  where?: Maybe<Letters_Bool_Exp>;
};


/** columns and relationships of "patients" */
export type PatientsPvasArgs = {
  distinct_on?: Maybe<Array<Pvas_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Pvas_Order_By>>;
  where?: Maybe<Pvas_Bool_Exp>;
};


/** columns and relationships of "patients" */
export type PatientsPvas_AggregateArgs = {
  distinct_on?: Maybe<Array<Pvas_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Pvas_Order_By>>;
  where?: Maybe<Pvas_Bool_Exp>;
};


/** columns and relationships of "patients" */
export type PatientsSms_MessagesArgs = {
  distinct_on?: Maybe<Array<Sms_Messages_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Sms_Messages_Order_By>>;
  where?: Maybe<Sms_Messages_Bool_Exp>;
};


/** columns and relationships of "patients" */
export type PatientsSms_Messages_AggregateArgs = {
  distinct_on?: Maybe<Array<Sms_Messages_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Sms_Messages_Order_By>>;
  where?: Maybe<Sms_Messages_Bool_Exp>;
};

/** aggregated selection of "patients" */
export type Patients_Aggregate = {
  __typename?: 'patients_aggregate';
  aggregate?: Maybe<Patients_Aggregate_Fields>;
  nodes: Array<Patients>;
};

/** aggregate fields of "patients" */
export type Patients_Aggregate_Fields = {
  __typename?: 'patients_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Patients_Max_Fields>;
  min?: Maybe<Patients_Min_Fields>;
};


/** aggregate fields of "patients" */
export type Patients_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Patients_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "patients" */
export type Patients_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Patients_Max_Order_By>;
  min?: Maybe<Patients_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Patients_Append_Input = {
  address_verification_result?: Maybe<Scalars['jsonb']>;
  email_validation_result?: Maybe<Scalars['jsonb']>;
  last_parsed_record?: Maybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "patients" */
export type Patients_Arr_Rel_Insert_Input = {
  data: Array<Patients_Insert_Input>;
  on_conflict?: Maybe<Patients_On_Conflict>;
};

/** columns and relationships of "patients_audit" */
export type Patients_Audit = {
  __typename?: 'patients_audit';
  action?: Maybe<Scalars['String']>;
  action_tstamp_clk?: Maybe<Scalars['timestamptz']>;
  action_tstamp_stm?: Maybe<Scalars['timestamptz']>;
  action_tstamp_tx?: Maybe<Scalars['timestamptz']>;
  application_name?: Maybe<Scalars['String']>;
  changed_fields?: Maybe<Scalars['jsonb']>;
  client_addr?: Maybe<Scalars['inet']>;
  client_port?: Maybe<Scalars['Int']>;
  client_query?: Maybe<Scalars['String']>;
  event_id?: Maybe<Scalars['bigint']>;
  hasura_user?: Maybe<Scalars['jsonb']>;
  patient_id?: Maybe<Scalars['String']>;
  relid?: Maybe<Scalars['oid']>;
  row_data?: Maybe<Scalars['jsonb']>;
  schema_name?: Maybe<Scalars['String']>;
  /** An object relationship */
  session_user?: Maybe<Users>;
  session_user_name?: Maybe<Scalars['String']>;
  statement_only?: Maybe<Scalars['Boolean']>;
  table_name?: Maybe<Scalars['String']>;
  transaction_id?: Maybe<Scalars['bigint']>;
};


/** columns and relationships of "patients_audit" */
export type Patients_AuditChanged_FieldsArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "patients_audit" */
export type Patients_AuditHasura_UserArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "patients_audit" */
export type Patients_AuditRow_DataArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "patients_audit" */
export type Patients_Audit_Aggregate = {
  __typename?: 'patients_audit_aggregate';
  aggregate?: Maybe<Patients_Audit_Aggregate_Fields>;
  nodes: Array<Patients_Audit>;
};

/** aggregate fields of "patients_audit" */
export type Patients_Audit_Aggregate_Fields = {
  __typename?: 'patients_audit_aggregate_fields';
  avg?: Maybe<Patients_Audit_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Patients_Audit_Max_Fields>;
  min?: Maybe<Patients_Audit_Min_Fields>;
  stddev?: Maybe<Patients_Audit_Stddev_Fields>;
  stddev_pop?: Maybe<Patients_Audit_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Patients_Audit_Stddev_Samp_Fields>;
  sum?: Maybe<Patients_Audit_Sum_Fields>;
  var_pop?: Maybe<Patients_Audit_Var_Pop_Fields>;
  var_samp?: Maybe<Patients_Audit_Var_Samp_Fields>;
  variance?: Maybe<Patients_Audit_Variance_Fields>;
};


/** aggregate fields of "patients_audit" */
export type Patients_Audit_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Patients_Audit_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "patients_audit" */
export type Patients_Audit_Aggregate_Order_By = {
  avg?: Maybe<Patients_Audit_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Patients_Audit_Max_Order_By>;
  min?: Maybe<Patients_Audit_Min_Order_By>;
  stddev?: Maybe<Patients_Audit_Stddev_Order_By>;
  stddev_pop?: Maybe<Patients_Audit_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Patients_Audit_Stddev_Samp_Order_By>;
  sum?: Maybe<Patients_Audit_Sum_Order_By>;
  var_pop?: Maybe<Patients_Audit_Var_Pop_Order_By>;
  var_samp?: Maybe<Patients_Audit_Var_Samp_Order_By>;
  variance?: Maybe<Patients_Audit_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Patients_Audit_Append_Input = {
  changed_fields?: Maybe<Scalars['jsonb']>;
  hasura_user?: Maybe<Scalars['jsonb']>;
  row_data?: Maybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "patients_audit" */
export type Patients_Audit_Arr_Rel_Insert_Input = {
  data: Array<Patients_Audit_Insert_Input>;
};

/** aggregate avg on columns */
export type Patients_Audit_Avg_Fields = {
  __typename?: 'patients_audit_avg_fields';
  client_port?: Maybe<Scalars['Float']>;
  event_id?: Maybe<Scalars['Float']>;
  transaction_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "patients_audit" */
export type Patients_Audit_Avg_Order_By = {
  client_port?: Maybe<Order_By>;
  event_id?: Maybe<Order_By>;
  transaction_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "patients_audit". All fields are combined with a logical 'AND'. */
export type Patients_Audit_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Patients_Audit_Bool_Exp>>>;
  _not?: Maybe<Patients_Audit_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Patients_Audit_Bool_Exp>>>;
  action?: Maybe<String_Comparison_Exp>;
  action_tstamp_clk?: Maybe<Timestamptz_Comparison_Exp>;
  action_tstamp_stm?: Maybe<Timestamptz_Comparison_Exp>;
  action_tstamp_tx?: Maybe<Timestamptz_Comparison_Exp>;
  application_name?: Maybe<String_Comparison_Exp>;
  changed_fields?: Maybe<Jsonb_Comparison_Exp>;
  client_addr?: Maybe<Inet_Comparison_Exp>;
  client_port?: Maybe<Int_Comparison_Exp>;
  client_query?: Maybe<String_Comparison_Exp>;
  event_id?: Maybe<Bigint_Comparison_Exp>;
  hasura_user?: Maybe<Jsonb_Comparison_Exp>;
  patient_id?: Maybe<String_Comparison_Exp>;
  relid?: Maybe<Oid_Comparison_Exp>;
  row_data?: Maybe<Jsonb_Comparison_Exp>;
  schema_name?: Maybe<String_Comparison_Exp>;
  session_user?: Maybe<Users_Bool_Exp>;
  session_user_name?: Maybe<String_Comparison_Exp>;
  statement_only?: Maybe<Boolean_Comparison_Exp>;
  table_name?: Maybe<String_Comparison_Exp>;
  transaction_id?: Maybe<Bigint_Comparison_Exp>;
};

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Patients_Audit_Delete_At_Path_Input = {
  changed_fields?: Maybe<Array<Maybe<Scalars['String']>>>;
  hasura_user?: Maybe<Array<Maybe<Scalars['String']>>>;
  row_data?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Patients_Audit_Delete_Elem_Input = {
  changed_fields?: Maybe<Scalars['Int']>;
  hasura_user?: Maybe<Scalars['Int']>;
  row_data?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Patients_Audit_Delete_Key_Input = {
  changed_fields?: Maybe<Scalars['String']>;
  hasura_user?: Maybe<Scalars['String']>;
  row_data?: Maybe<Scalars['String']>;
};

/** input type for incrementing integer column in table "patients_audit" */
export type Patients_Audit_Inc_Input = {
  client_port?: Maybe<Scalars['Int']>;
  event_id?: Maybe<Scalars['bigint']>;
  transaction_id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "patients_audit" */
export type Patients_Audit_Insert_Input = {
  action?: Maybe<Scalars['String']>;
  action_tstamp_clk?: Maybe<Scalars['timestamptz']>;
  action_tstamp_stm?: Maybe<Scalars['timestamptz']>;
  action_tstamp_tx?: Maybe<Scalars['timestamptz']>;
  application_name?: Maybe<Scalars['String']>;
  changed_fields?: Maybe<Scalars['jsonb']>;
  client_addr?: Maybe<Scalars['inet']>;
  client_port?: Maybe<Scalars['Int']>;
  client_query?: Maybe<Scalars['String']>;
  event_id?: Maybe<Scalars['bigint']>;
  hasura_user?: Maybe<Scalars['jsonb']>;
  patient_id?: Maybe<Scalars['String']>;
  relid?: Maybe<Scalars['oid']>;
  row_data?: Maybe<Scalars['jsonb']>;
  schema_name?: Maybe<Scalars['String']>;
  session_user?: Maybe<Users_Obj_Rel_Insert_Input>;
  session_user_name?: Maybe<Scalars['String']>;
  statement_only?: Maybe<Scalars['Boolean']>;
  table_name?: Maybe<Scalars['String']>;
  transaction_id?: Maybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Patients_Audit_Max_Fields = {
  __typename?: 'patients_audit_max_fields';
  action?: Maybe<Scalars['String']>;
  action_tstamp_clk?: Maybe<Scalars['timestamptz']>;
  action_tstamp_stm?: Maybe<Scalars['timestamptz']>;
  action_tstamp_tx?: Maybe<Scalars['timestamptz']>;
  application_name?: Maybe<Scalars['String']>;
  client_port?: Maybe<Scalars['Int']>;
  client_query?: Maybe<Scalars['String']>;
  event_id?: Maybe<Scalars['bigint']>;
  patient_id?: Maybe<Scalars['String']>;
  schema_name?: Maybe<Scalars['String']>;
  session_user_name?: Maybe<Scalars['String']>;
  table_name?: Maybe<Scalars['String']>;
  transaction_id?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "patients_audit" */
export type Patients_Audit_Max_Order_By = {
  action?: Maybe<Order_By>;
  action_tstamp_clk?: Maybe<Order_By>;
  action_tstamp_stm?: Maybe<Order_By>;
  action_tstamp_tx?: Maybe<Order_By>;
  application_name?: Maybe<Order_By>;
  client_port?: Maybe<Order_By>;
  client_query?: Maybe<Order_By>;
  event_id?: Maybe<Order_By>;
  patient_id?: Maybe<Order_By>;
  schema_name?: Maybe<Order_By>;
  session_user_name?: Maybe<Order_By>;
  table_name?: Maybe<Order_By>;
  transaction_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Patients_Audit_Min_Fields = {
  __typename?: 'patients_audit_min_fields';
  action?: Maybe<Scalars['String']>;
  action_tstamp_clk?: Maybe<Scalars['timestamptz']>;
  action_tstamp_stm?: Maybe<Scalars['timestamptz']>;
  action_tstamp_tx?: Maybe<Scalars['timestamptz']>;
  application_name?: Maybe<Scalars['String']>;
  client_port?: Maybe<Scalars['Int']>;
  client_query?: Maybe<Scalars['String']>;
  event_id?: Maybe<Scalars['bigint']>;
  patient_id?: Maybe<Scalars['String']>;
  schema_name?: Maybe<Scalars['String']>;
  session_user_name?: Maybe<Scalars['String']>;
  table_name?: Maybe<Scalars['String']>;
  transaction_id?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "patients_audit" */
export type Patients_Audit_Min_Order_By = {
  action?: Maybe<Order_By>;
  action_tstamp_clk?: Maybe<Order_By>;
  action_tstamp_stm?: Maybe<Order_By>;
  action_tstamp_tx?: Maybe<Order_By>;
  application_name?: Maybe<Order_By>;
  client_port?: Maybe<Order_By>;
  client_query?: Maybe<Order_By>;
  event_id?: Maybe<Order_By>;
  patient_id?: Maybe<Order_By>;
  schema_name?: Maybe<Order_By>;
  session_user_name?: Maybe<Order_By>;
  table_name?: Maybe<Order_By>;
  transaction_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "patients_audit" */
export type Patients_Audit_Mutation_Response = {
  __typename?: 'patients_audit_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Patients_Audit>;
};

/** input type for inserting object relation for remote table "patients_audit" */
export type Patients_Audit_Obj_Rel_Insert_Input = {
  data: Patients_Audit_Insert_Input;
};

/** ordering options when selecting data from "patients_audit" */
export type Patients_Audit_Order_By = {
  action?: Maybe<Order_By>;
  action_tstamp_clk?: Maybe<Order_By>;
  action_tstamp_stm?: Maybe<Order_By>;
  action_tstamp_tx?: Maybe<Order_By>;
  application_name?: Maybe<Order_By>;
  changed_fields?: Maybe<Order_By>;
  client_addr?: Maybe<Order_By>;
  client_port?: Maybe<Order_By>;
  client_query?: Maybe<Order_By>;
  event_id?: Maybe<Order_By>;
  hasura_user?: Maybe<Order_By>;
  patient_id?: Maybe<Order_By>;
  relid?: Maybe<Order_By>;
  row_data?: Maybe<Order_By>;
  schema_name?: Maybe<Order_By>;
  session_user?: Maybe<Users_Order_By>;
  session_user_name?: Maybe<Order_By>;
  statement_only?: Maybe<Order_By>;
  table_name?: Maybe<Order_By>;
  transaction_id?: Maybe<Order_By>;
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Patients_Audit_Prepend_Input = {
  changed_fields?: Maybe<Scalars['jsonb']>;
  hasura_user?: Maybe<Scalars['jsonb']>;
  row_data?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "patients_audit" */
export enum Patients_Audit_Select_Column {
  /** column name */
  Action = 'action',
  /** column name */
  ActionTstampClk = 'action_tstamp_clk',
  /** column name */
  ActionTstampStm = 'action_tstamp_stm',
  /** column name */
  ActionTstampTx = 'action_tstamp_tx',
  /** column name */
  ApplicationName = 'application_name',
  /** column name */
  ChangedFields = 'changed_fields',
  /** column name */
  ClientAddr = 'client_addr',
  /** column name */
  ClientPort = 'client_port',
  /** column name */
  ClientQuery = 'client_query',
  /** column name */
  EventId = 'event_id',
  /** column name */
  HasuraUser = 'hasura_user',
  /** column name */
  PatientId = 'patient_id',
  /** column name */
  Relid = 'relid',
  /** column name */
  RowData = 'row_data',
  /** column name */
  SchemaName = 'schema_name',
  /** column name */
  SessionUserName = 'session_user_name',
  /** column name */
  StatementOnly = 'statement_only',
  /** column name */
  TableName = 'table_name',
  /** column name */
  TransactionId = 'transaction_id'
}

/** input type for updating data in table "patients_audit" */
export type Patients_Audit_Set_Input = {
  action?: Maybe<Scalars['String']>;
  action_tstamp_clk?: Maybe<Scalars['timestamptz']>;
  action_tstamp_stm?: Maybe<Scalars['timestamptz']>;
  action_tstamp_tx?: Maybe<Scalars['timestamptz']>;
  application_name?: Maybe<Scalars['String']>;
  changed_fields?: Maybe<Scalars['jsonb']>;
  client_addr?: Maybe<Scalars['inet']>;
  client_port?: Maybe<Scalars['Int']>;
  client_query?: Maybe<Scalars['String']>;
  event_id?: Maybe<Scalars['bigint']>;
  hasura_user?: Maybe<Scalars['jsonb']>;
  patient_id?: Maybe<Scalars['String']>;
  relid?: Maybe<Scalars['oid']>;
  row_data?: Maybe<Scalars['jsonb']>;
  schema_name?: Maybe<Scalars['String']>;
  session_user_name?: Maybe<Scalars['String']>;
  statement_only?: Maybe<Scalars['Boolean']>;
  table_name?: Maybe<Scalars['String']>;
  transaction_id?: Maybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Patients_Audit_Stddev_Fields = {
  __typename?: 'patients_audit_stddev_fields';
  client_port?: Maybe<Scalars['Float']>;
  event_id?: Maybe<Scalars['Float']>;
  transaction_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "patients_audit" */
export type Patients_Audit_Stddev_Order_By = {
  client_port?: Maybe<Order_By>;
  event_id?: Maybe<Order_By>;
  transaction_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Patients_Audit_Stddev_Pop_Fields = {
  __typename?: 'patients_audit_stddev_pop_fields';
  client_port?: Maybe<Scalars['Float']>;
  event_id?: Maybe<Scalars['Float']>;
  transaction_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "patients_audit" */
export type Patients_Audit_Stddev_Pop_Order_By = {
  client_port?: Maybe<Order_By>;
  event_id?: Maybe<Order_By>;
  transaction_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Patients_Audit_Stddev_Samp_Fields = {
  __typename?: 'patients_audit_stddev_samp_fields';
  client_port?: Maybe<Scalars['Float']>;
  event_id?: Maybe<Scalars['Float']>;
  transaction_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "patients_audit" */
export type Patients_Audit_Stddev_Samp_Order_By = {
  client_port?: Maybe<Order_By>;
  event_id?: Maybe<Order_By>;
  transaction_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Patients_Audit_Sum_Fields = {
  __typename?: 'patients_audit_sum_fields';
  client_port?: Maybe<Scalars['Int']>;
  event_id?: Maybe<Scalars['bigint']>;
  transaction_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "patients_audit" */
export type Patients_Audit_Sum_Order_By = {
  client_port?: Maybe<Order_By>;
  event_id?: Maybe<Order_By>;
  transaction_id?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Patients_Audit_Var_Pop_Fields = {
  __typename?: 'patients_audit_var_pop_fields';
  client_port?: Maybe<Scalars['Float']>;
  event_id?: Maybe<Scalars['Float']>;
  transaction_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "patients_audit" */
export type Patients_Audit_Var_Pop_Order_By = {
  client_port?: Maybe<Order_By>;
  event_id?: Maybe<Order_By>;
  transaction_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Patients_Audit_Var_Samp_Fields = {
  __typename?: 'patients_audit_var_samp_fields';
  client_port?: Maybe<Scalars['Float']>;
  event_id?: Maybe<Scalars['Float']>;
  transaction_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "patients_audit" */
export type Patients_Audit_Var_Samp_Order_By = {
  client_port?: Maybe<Order_By>;
  event_id?: Maybe<Order_By>;
  transaction_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Patients_Audit_Variance_Fields = {
  __typename?: 'patients_audit_variance_fields';
  client_port?: Maybe<Scalars['Float']>;
  event_id?: Maybe<Scalars['Float']>;
  transaction_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "patients_audit" */
export type Patients_Audit_Variance_Order_By = {
  client_port?: Maybe<Order_By>;
  event_id?: Maybe<Order_By>;
  transaction_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "patients". All fields are combined with a logical 'AND'. */
export type Patients_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Patients_Bool_Exp>>>;
  _not?: Maybe<Patients_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Patients_Bool_Exp>>>;
  address_verification_result?: Maybe<Jsonb_Comparison_Exp>;
  alignment_tin?: Maybe<String_Comparison_Exp>;
  alignments?: Maybe<Alignments_Bool_Exp>;
  birth_date?: Maybe<Date_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deceased_as_of_date?: Maybe<Date_Comparison_Exp>;
  do_not_direct_mail?: Maybe<Boolean_Comparison_Exp>;
  do_not_email?: Maybe<Boolean_Comparison_Exp>;
  do_not_sms?: Maybe<Boolean_Comparison_Exp>;
  email_validation_result?: Maybe<Jsonb_Comparison_Exp>;
  emails?: Maybe<Emails_Bool_Exp>;
  external_id?: Maybe<String_Comparison_Exp>;
  is_archived?: Maybe<Boolean_Comparison_Exp>;
  last_parsed_record?: Maybe<Jsonb_Comparison_Exp>;
  letters?: Maybe<Letters_Bool_Exp>;
  mbi?: Maybe<String_Comparison_Exp>;
  patient_address?: Maybe<String_Comparison_Exp>;
  patient_alignment_label?: Maybe<Patient_Alignment_Labels_Bool_Exp>;
  patient_email?: Maybe<String_Comparison_Exp>;
  patient_hedr_label?: Maybe<Patient_Hedr_Labels_Bool_Exp>;
  patient_id?: Maybe<String_Comparison_Exp>;
  patient_logo?: Maybe<String_Comparison_Exp>;
  patient_name?: Maybe<String_Comparison_Exp>;
  patient_notes?: Maybe<String_Comparison_Exp>;
  patient_pcp?: Maybe<String_Comparison_Exp>;
  patient_phone?: Maybe<String_Comparison_Exp>;
  patient_segment_label?: Maybe<Patient_Segment_Labels_Bool_Exp>;
  practice?: Maybe<Practices_Bool_Exp>;
  practice_id?: Maybe<String_Comparison_Exp>;
  primary_insurance_group_id?: Maybe<String_Comparison_Exp>;
  primary_insurance_member_id?: Maybe<String_Comparison_Exp>;
  primary_insurance_payer_id?: Maybe<String_Comparison_Exp>;
  primary_insurance_payer_name?: Maybe<String_Comparison_Exp>;
  primary_insurance_plan_name?: Maybe<String_Comparison_Exp>;
  pvas?: Maybe<Pvas_Bool_Exp>;
  secondary_insurance_group_id?: Maybe<String_Comparison_Exp>;
  secondary_insurance_member_id?: Maybe<String_Comparison_Exp>;
  secondary_insurance_payer_id?: Maybe<String_Comparison_Exp>;
  secondary_insurance_payer_name?: Maybe<String_Comparison_Exp>;
  secondary_insurance_plan_name?: Maybe<String_Comparison_Exp>;
  sex_assigned_at_birth?: Maybe<String_Comparison_Exp>;
  sms_messages?: Maybe<Sms_Messages_Bool_Exp>;
  suspected_provider?: Maybe<Providers_Bool_Exp>;
  suspected_provider_npi?: Maybe<String_Comparison_Exp>;
  tertiary_insurance_group_id?: Maybe<String_Comparison_Exp>;
  tertiary_insurance_member_id?: Maybe<String_Comparison_Exp>;
  tertiary_insurance_payer_id?: Maybe<String_Comparison_Exp>;
  tertiary_insurance_payer_name?: Maybe<String_Comparison_Exp>;
  tertiary_insurance_plan_name?: Maybe<String_Comparison_Exp>;
  tertiery_insurance_group_id?: Maybe<String_Comparison_Exp>;
  tertiery_insurance_member_id?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "patients" */
export enum Patients_Constraint {
  /** unique or primary key constraint */
  PatientPkey = 'patient_pkey',
  /** unique or primary key constraint */
  PatientsPracticeIdExternalIdKey = 'patients_practice_id_external_id_key'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Patients_Delete_At_Path_Input = {
  address_verification_result?: Maybe<Array<Maybe<Scalars['String']>>>;
  email_validation_result?: Maybe<Array<Maybe<Scalars['String']>>>;
  last_parsed_record?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Patients_Delete_Elem_Input = {
  address_verification_result?: Maybe<Scalars['Int']>;
  email_validation_result?: Maybe<Scalars['Int']>;
  last_parsed_record?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Patients_Delete_Key_Input = {
  address_verification_result?: Maybe<Scalars['String']>;
  email_validation_result?: Maybe<Scalars['String']>;
  last_parsed_record?: Maybe<Scalars['String']>;
};

/** input type for inserting data into table "patients" */
export type Patients_Insert_Input = {
  address_verification_result?: Maybe<Scalars['jsonb']>;
  alignment_tin?: Maybe<Scalars['String']>;
  alignments?: Maybe<Alignments_Arr_Rel_Insert_Input>;
  birth_date?: Maybe<Scalars['date']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deceased_as_of_date?: Maybe<Scalars['date']>;
  do_not_direct_mail?: Maybe<Scalars['Boolean']>;
  do_not_email?: Maybe<Scalars['Boolean']>;
  do_not_sms?: Maybe<Scalars['Boolean']>;
  email_validation_result?: Maybe<Scalars['jsonb']>;
  emails?: Maybe<Emails_Arr_Rel_Insert_Input>;
  external_id?: Maybe<Scalars['String']>;
  is_archived?: Maybe<Scalars['Boolean']>;
  last_parsed_record?: Maybe<Scalars['jsonb']>;
  letters?: Maybe<Letters_Arr_Rel_Insert_Input>;
  mbi?: Maybe<Scalars['String']>;
  patient_address?: Maybe<Scalars['String']>;
  patient_email?: Maybe<Scalars['String']>;
  patient_id?: Maybe<Scalars['String']>;
  patient_logo?: Maybe<Scalars['String']>;
  patient_name?: Maybe<Scalars['String']>;
  patient_notes?: Maybe<Scalars['String']>;
  patient_pcp?: Maybe<Scalars['String']>;
  patient_phone?: Maybe<Scalars['String']>;
  practice?: Maybe<Practices_Obj_Rel_Insert_Input>;
  practice_id?: Maybe<Scalars['String']>;
  primary_insurance_group_id?: Maybe<Scalars['String']>;
  primary_insurance_member_id?: Maybe<Scalars['String']>;
  primary_insurance_payer_id?: Maybe<Scalars['String']>;
  primary_insurance_payer_name?: Maybe<Scalars['String']>;
  primary_insurance_plan_name?: Maybe<Scalars['String']>;
  pvas?: Maybe<Pvas_Arr_Rel_Insert_Input>;
  secondary_insurance_group_id?: Maybe<Scalars['String']>;
  secondary_insurance_member_id?: Maybe<Scalars['String']>;
  secondary_insurance_payer_id?: Maybe<Scalars['String']>;
  secondary_insurance_payer_name?: Maybe<Scalars['String']>;
  secondary_insurance_plan_name?: Maybe<Scalars['String']>;
  sex_assigned_at_birth?: Maybe<Scalars['String']>;
  sms_messages?: Maybe<Sms_Messages_Arr_Rel_Insert_Input>;
  suspected_provider?: Maybe<Providers_Obj_Rel_Insert_Input>;
  suspected_provider_npi?: Maybe<Scalars['String']>;
  tertiary_insurance_group_id?: Maybe<Scalars['String']>;
  tertiary_insurance_member_id?: Maybe<Scalars['String']>;
  tertiary_insurance_payer_id?: Maybe<Scalars['String']>;
  tertiary_insurance_payer_name?: Maybe<Scalars['String']>;
  tertiary_insurance_plan_name?: Maybe<Scalars['String']>;
  tertiery_insurance_group_id?: Maybe<Scalars['String']>;
  tertiery_insurance_member_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Patients_Max_Fields = {
  __typename?: 'patients_max_fields';
  alignment_tin?: Maybe<Scalars['String']>;
  birth_date?: Maybe<Scalars['date']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deceased_as_of_date?: Maybe<Scalars['date']>;
  external_id?: Maybe<Scalars['String']>;
  mbi?: Maybe<Scalars['String']>;
  patient_address?: Maybe<Scalars['String']>;
  patient_email?: Maybe<Scalars['String']>;
  patient_id?: Maybe<Scalars['String']>;
  patient_logo?: Maybe<Scalars['String']>;
  patient_name?: Maybe<Scalars['String']>;
  patient_notes?: Maybe<Scalars['String']>;
  patient_pcp?: Maybe<Scalars['String']>;
  patient_phone?: Maybe<Scalars['String']>;
  practice_id?: Maybe<Scalars['String']>;
  primary_insurance_group_id?: Maybe<Scalars['String']>;
  primary_insurance_member_id?: Maybe<Scalars['String']>;
  primary_insurance_payer_id?: Maybe<Scalars['String']>;
  primary_insurance_payer_name?: Maybe<Scalars['String']>;
  primary_insurance_plan_name?: Maybe<Scalars['String']>;
  secondary_insurance_group_id?: Maybe<Scalars['String']>;
  secondary_insurance_member_id?: Maybe<Scalars['String']>;
  secondary_insurance_payer_id?: Maybe<Scalars['String']>;
  secondary_insurance_payer_name?: Maybe<Scalars['String']>;
  secondary_insurance_plan_name?: Maybe<Scalars['String']>;
  sex_assigned_at_birth?: Maybe<Scalars['String']>;
  suspected_provider_npi?: Maybe<Scalars['String']>;
  tertiary_insurance_group_id?: Maybe<Scalars['String']>;
  tertiary_insurance_member_id?: Maybe<Scalars['String']>;
  tertiary_insurance_payer_id?: Maybe<Scalars['String']>;
  tertiary_insurance_payer_name?: Maybe<Scalars['String']>;
  tertiary_insurance_plan_name?: Maybe<Scalars['String']>;
  tertiery_insurance_group_id?: Maybe<Scalars['String']>;
  tertiery_insurance_member_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "patients" */
export type Patients_Max_Order_By = {
  alignment_tin?: Maybe<Order_By>;
  birth_date?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deceased_as_of_date?: Maybe<Order_By>;
  external_id?: Maybe<Order_By>;
  mbi?: Maybe<Order_By>;
  patient_address?: Maybe<Order_By>;
  patient_email?: Maybe<Order_By>;
  patient_id?: Maybe<Order_By>;
  patient_logo?: Maybe<Order_By>;
  patient_name?: Maybe<Order_By>;
  patient_notes?: Maybe<Order_By>;
  patient_pcp?: Maybe<Order_By>;
  patient_phone?: Maybe<Order_By>;
  practice_id?: Maybe<Order_By>;
  primary_insurance_group_id?: Maybe<Order_By>;
  primary_insurance_member_id?: Maybe<Order_By>;
  primary_insurance_payer_id?: Maybe<Order_By>;
  primary_insurance_payer_name?: Maybe<Order_By>;
  primary_insurance_plan_name?: Maybe<Order_By>;
  secondary_insurance_group_id?: Maybe<Order_By>;
  secondary_insurance_member_id?: Maybe<Order_By>;
  secondary_insurance_payer_id?: Maybe<Order_By>;
  secondary_insurance_payer_name?: Maybe<Order_By>;
  secondary_insurance_plan_name?: Maybe<Order_By>;
  sex_assigned_at_birth?: Maybe<Order_By>;
  suspected_provider_npi?: Maybe<Order_By>;
  tertiary_insurance_group_id?: Maybe<Order_By>;
  tertiary_insurance_member_id?: Maybe<Order_By>;
  tertiary_insurance_payer_id?: Maybe<Order_By>;
  tertiary_insurance_payer_name?: Maybe<Order_By>;
  tertiary_insurance_plan_name?: Maybe<Order_By>;
  tertiery_insurance_group_id?: Maybe<Order_By>;
  tertiery_insurance_member_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Patients_Min_Fields = {
  __typename?: 'patients_min_fields';
  alignment_tin?: Maybe<Scalars['String']>;
  birth_date?: Maybe<Scalars['date']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deceased_as_of_date?: Maybe<Scalars['date']>;
  external_id?: Maybe<Scalars['String']>;
  mbi?: Maybe<Scalars['String']>;
  patient_address?: Maybe<Scalars['String']>;
  patient_email?: Maybe<Scalars['String']>;
  patient_id?: Maybe<Scalars['String']>;
  patient_logo?: Maybe<Scalars['String']>;
  patient_name?: Maybe<Scalars['String']>;
  patient_notes?: Maybe<Scalars['String']>;
  patient_pcp?: Maybe<Scalars['String']>;
  patient_phone?: Maybe<Scalars['String']>;
  practice_id?: Maybe<Scalars['String']>;
  primary_insurance_group_id?: Maybe<Scalars['String']>;
  primary_insurance_member_id?: Maybe<Scalars['String']>;
  primary_insurance_payer_id?: Maybe<Scalars['String']>;
  primary_insurance_payer_name?: Maybe<Scalars['String']>;
  primary_insurance_plan_name?: Maybe<Scalars['String']>;
  secondary_insurance_group_id?: Maybe<Scalars['String']>;
  secondary_insurance_member_id?: Maybe<Scalars['String']>;
  secondary_insurance_payer_id?: Maybe<Scalars['String']>;
  secondary_insurance_payer_name?: Maybe<Scalars['String']>;
  secondary_insurance_plan_name?: Maybe<Scalars['String']>;
  sex_assigned_at_birth?: Maybe<Scalars['String']>;
  suspected_provider_npi?: Maybe<Scalars['String']>;
  tertiary_insurance_group_id?: Maybe<Scalars['String']>;
  tertiary_insurance_member_id?: Maybe<Scalars['String']>;
  tertiary_insurance_payer_id?: Maybe<Scalars['String']>;
  tertiary_insurance_payer_name?: Maybe<Scalars['String']>;
  tertiary_insurance_plan_name?: Maybe<Scalars['String']>;
  tertiery_insurance_group_id?: Maybe<Scalars['String']>;
  tertiery_insurance_member_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "patients" */
export type Patients_Min_Order_By = {
  alignment_tin?: Maybe<Order_By>;
  birth_date?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deceased_as_of_date?: Maybe<Order_By>;
  external_id?: Maybe<Order_By>;
  mbi?: Maybe<Order_By>;
  patient_address?: Maybe<Order_By>;
  patient_email?: Maybe<Order_By>;
  patient_id?: Maybe<Order_By>;
  patient_logo?: Maybe<Order_By>;
  patient_name?: Maybe<Order_By>;
  patient_notes?: Maybe<Order_By>;
  patient_pcp?: Maybe<Order_By>;
  patient_phone?: Maybe<Order_By>;
  practice_id?: Maybe<Order_By>;
  primary_insurance_group_id?: Maybe<Order_By>;
  primary_insurance_member_id?: Maybe<Order_By>;
  primary_insurance_payer_id?: Maybe<Order_By>;
  primary_insurance_payer_name?: Maybe<Order_By>;
  primary_insurance_plan_name?: Maybe<Order_By>;
  secondary_insurance_group_id?: Maybe<Order_By>;
  secondary_insurance_member_id?: Maybe<Order_By>;
  secondary_insurance_payer_id?: Maybe<Order_By>;
  secondary_insurance_payer_name?: Maybe<Order_By>;
  secondary_insurance_plan_name?: Maybe<Order_By>;
  sex_assigned_at_birth?: Maybe<Order_By>;
  suspected_provider_npi?: Maybe<Order_By>;
  tertiary_insurance_group_id?: Maybe<Order_By>;
  tertiary_insurance_member_id?: Maybe<Order_By>;
  tertiary_insurance_payer_id?: Maybe<Order_By>;
  tertiary_insurance_payer_name?: Maybe<Order_By>;
  tertiary_insurance_plan_name?: Maybe<Order_By>;
  tertiery_insurance_group_id?: Maybe<Order_By>;
  tertiery_insurance_member_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "patients" */
export type Patients_Mutation_Response = {
  __typename?: 'patients_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Patients>;
};

/** input type for inserting object relation for remote table "patients" */
export type Patients_Obj_Rel_Insert_Input = {
  data: Patients_Insert_Input;
  on_conflict?: Maybe<Patients_On_Conflict>;
};

/** on conflict condition type for table "patients" */
export type Patients_On_Conflict = {
  constraint: Patients_Constraint;
  update_columns: Array<Patients_Update_Column>;
  where?: Maybe<Patients_Bool_Exp>;
};

/** ordering options when selecting data from "patients" */
export type Patients_Order_By = {
  address_verification_result?: Maybe<Order_By>;
  alignment_tin?: Maybe<Order_By>;
  alignments_aggregate?: Maybe<Alignments_Aggregate_Order_By>;
  birth_date?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deceased_as_of_date?: Maybe<Order_By>;
  do_not_direct_mail?: Maybe<Order_By>;
  do_not_email?: Maybe<Order_By>;
  do_not_sms?: Maybe<Order_By>;
  email_validation_result?: Maybe<Order_By>;
  emails_aggregate?: Maybe<Emails_Aggregate_Order_By>;
  external_id?: Maybe<Order_By>;
  is_archived?: Maybe<Order_By>;
  last_parsed_record?: Maybe<Order_By>;
  letters_aggregate?: Maybe<Letters_Aggregate_Order_By>;
  mbi?: Maybe<Order_By>;
  patient_address?: Maybe<Order_By>;
  patient_alignment_label?: Maybe<Patient_Alignment_Labels_Order_By>;
  patient_email?: Maybe<Order_By>;
  patient_hedr_label?: Maybe<Patient_Hedr_Labels_Order_By>;
  patient_id?: Maybe<Order_By>;
  patient_logo?: Maybe<Order_By>;
  patient_name?: Maybe<Order_By>;
  patient_notes?: Maybe<Order_By>;
  patient_pcp?: Maybe<Order_By>;
  patient_phone?: Maybe<Order_By>;
  patient_segment_label?: Maybe<Patient_Segment_Labels_Order_By>;
  practice?: Maybe<Practices_Order_By>;
  practice_id?: Maybe<Order_By>;
  primary_insurance_group_id?: Maybe<Order_By>;
  primary_insurance_member_id?: Maybe<Order_By>;
  primary_insurance_payer_id?: Maybe<Order_By>;
  primary_insurance_payer_name?: Maybe<Order_By>;
  primary_insurance_plan_name?: Maybe<Order_By>;
  pvas_aggregate?: Maybe<Pvas_Aggregate_Order_By>;
  secondary_insurance_group_id?: Maybe<Order_By>;
  secondary_insurance_member_id?: Maybe<Order_By>;
  secondary_insurance_payer_id?: Maybe<Order_By>;
  secondary_insurance_payer_name?: Maybe<Order_By>;
  secondary_insurance_plan_name?: Maybe<Order_By>;
  sex_assigned_at_birth?: Maybe<Order_By>;
  sms_messages_aggregate?: Maybe<Sms_Messages_Aggregate_Order_By>;
  suspected_provider?: Maybe<Providers_Order_By>;
  suspected_provider_npi?: Maybe<Order_By>;
  tertiary_insurance_group_id?: Maybe<Order_By>;
  tertiary_insurance_member_id?: Maybe<Order_By>;
  tertiary_insurance_payer_id?: Maybe<Order_By>;
  tertiary_insurance_payer_name?: Maybe<Order_By>;
  tertiary_insurance_plan_name?: Maybe<Order_By>;
  tertiery_insurance_group_id?: Maybe<Order_By>;
  tertiery_insurance_member_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: "patients" */
export type Patients_Pk_Columns_Input = {
  patient_id: Scalars['String'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Patients_Prepend_Input = {
  address_verification_result?: Maybe<Scalars['jsonb']>;
  email_validation_result?: Maybe<Scalars['jsonb']>;
  last_parsed_record?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "patients" */
export enum Patients_Select_Column {
  /** column name */
  AddressVerificationResult = 'address_verification_result',
  /** column name */
  AlignmentTin = 'alignment_tin',
  /** column name */
  BirthDate = 'birth_date',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeceasedAsOfDate = 'deceased_as_of_date',
  /** column name */
  DoNotDirectMail = 'do_not_direct_mail',
  /** column name */
  DoNotEmail = 'do_not_email',
  /** column name */
  DoNotSms = 'do_not_sms',
  /** column name */
  EmailValidationResult = 'email_validation_result',
  /** column name */
  ExternalId = 'external_id',
  /** column name */
  IsArchived = 'is_archived',
  /** column name */
  LastParsedRecord = 'last_parsed_record',
  /** column name */
  Mbi = 'mbi',
  /** column name */
  PatientAddress = 'patient_address',
  /** column name */
  PatientEmail = 'patient_email',
  /** column name */
  PatientId = 'patient_id',
  /** column name */
  PatientLogo = 'patient_logo',
  /** column name */
  PatientName = 'patient_name',
  /** column name */
  PatientNotes = 'patient_notes',
  /** column name */
  PatientPcp = 'patient_pcp',
  /** column name */
  PatientPhone = 'patient_phone',
  /** column name */
  PracticeId = 'practice_id',
  /** column name */
  PrimaryInsuranceGroupId = 'primary_insurance_group_id',
  /** column name */
  PrimaryInsuranceMemberId = 'primary_insurance_member_id',
  /** column name */
  PrimaryInsurancePayerId = 'primary_insurance_payer_id',
  /** column name */
  PrimaryInsurancePayerName = 'primary_insurance_payer_name',
  /** column name */
  PrimaryInsurancePlanName = 'primary_insurance_plan_name',
  /** column name */
  SecondaryInsuranceGroupId = 'secondary_insurance_group_id',
  /** column name */
  SecondaryInsuranceMemberId = 'secondary_insurance_member_id',
  /** column name */
  SecondaryInsurancePayerId = 'secondary_insurance_payer_id',
  /** column name */
  SecondaryInsurancePayerName = 'secondary_insurance_payer_name',
  /** column name */
  SecondaryInsurancePlanName = 'secondary_insurance_plan_name',
  /** column name */
  SexAssignedAtBirth = 'sex_assigned_at_birth',
  /** column name */
  SuspectedProviderNpi = 'suspected_provider_npi',
  /** column name */
  TertiaryInsuranceGroupId = 'tertiary_insurance_group_id',
  /** column name */
  TertiaryInsuranceMemberId = 'tertiary_insurance_member_id',
  /** column name */
  TertiaryInsurancePayerId = 'tertiary_insurance_payer_id',
  /** column name */
  TertiaryInsurancePayerName = 'tertiary_insurance_payer_name',
  /** column name */
  TertiaryInsurancePlanName = 'tertiary_insurance_plan_name',
  /** column name */
  TertieryInsuranceGroupId = 'tertiery_insurance_group_id',
  /** column name */
  TertieryInsuranceMemberId = 'tertiery_insurance_member_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "patients" */
export type Patients_Set_Input = {
  address_verification_result?: Maybe<Scalars['jsonb']>;
  alignment_tin?: Maybe<Scalars['String']>;
  birth_date?: Maybe<Scalars['date']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deceased_as_of_date?: Maybe<Scalars['date']>;
  do_not_direct_mail?: Maybe<Scalars['Boolean']>;
  do_not_email?: Maybe<Scalars['Boolean']>;
  do_not_sms?: Maybe<Scalars['Boolean']>;
  email_validation_result?: Maybe<Scalars['jsonb']>;
  external_id?: Maybe<Scalars['String']>;
  is_archived?: Maybe<Scalars['Boolean']>;
  last_parsed_record?: Maybe<Scalars['jsonb']>;
  mbi?: Maybe<Scalars['String']>;
  patient_address?: Maybe<Scalars['String']>;
  patient_email?: Maybe<Scalars['String']>;
  patient_id?: Maybe<Scalars['String']>;
  patient_logo?: Maybe<Scalars['String']>;
  patient_name?: Maybe<Scalars['String']>;
  patient_notes?: Maybe<Scalars['String']>;
  patient_pcp?: Maybe<Scalars['String']>;
  patient_phone?: Maybe<Scalars['String']>;
  practice_id?: Maybe<Scalars['String']>;
  primary_insurance_group_id?: Maybe<Scalars['String']>;
  primary_insurance_member_id?: Maybe<Scalars['String']>;
  primary_insurance_payer_id?: Maybe<Scalars['String']>;
  primary_insurance_payer_name?: Maybe<Scalars['String']>;
  primary_insurance_plan_name?: Maybe<Scalars['String']>;
  secondary_insurance_group_id?: Maybe<Scalars['String']>;
  secondary_insurance_member_id?: Maybe<Scalars['String']>;
  secondary_insurance_payer_id?: Maybe<Scalars['String']>;
  secondary_insurance_payer_name?: Maybe<Scalars['String']>;
  secondary_insurance_plan_name?: Maybe<Scalars['String']>;
  sex_assigned_at_birth?: Maybe<Scalars['String']>;
  suspected_provider_npi?: Maybe<Scalars['String']>;
  tertiary_insurance_group_id?: Maybe<Scalars['String']>;
  tertiary_insurance_member_id?: Maybe<Scalars['String']>;
  tertiary_insurance_payer_id?: Maybe<Scalars['String']>;
  tertiary_insurance_payer_name?: Maybe<Scalars['String']>;
  tertiary_insurance_plan_name?: Maybe<Scalars['String']>;
  tertiery_insurance_group_id?: Maybe<Scalars['String']>;
  tertiery_insurance_member_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "patients" */
export enum Patients_Update_Column {
  /** column name */
  AddressVerificationResult = 'address_verification_result',
  /** column name */
  AlignmentTin = 'alignment_tin',
  /** column name */
  BirthDate = 'birth_date',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeceasedAsOfDate = 'deceased_as_of_date',
  /** column name */
  DoNotDirectMail = 'do_not_direct_mail',
  /** column name */
  DoNotEmail = 'do_not_email',
  /** column name */
  DoNotSms = 'do_not_sms',
  /** column name */
  EmailValidationResult = 'email_validation_result',
  /** column name */
  ExternalId = 'external_id',
  /** column name */
  IsArchived = 'is_archived',
  /** column name */
  LastParsedRecord = 'last_parsed_record',
  /** column name */
  Mbi = 'mbi',
  /** column name */
  PatientAddress = 'patient_address',
  /** column name */
  PatientEmail = 'patient_email',
  /** column name */
  PatientId = 'patient_id',
  /** column name */
  PatientLogo = 'patient_logo',
  /** column name */
  PatientName = 'patient_name',
  /** column name */
  PatientNotes = 'patient_notes',
  /** column name */
  PatientPcp = 'patient_pcp',
  /** column name */
  PatientPhone = 'patient_phone',
  /** column name */
  PracticeId = 'practice_id',
  /** column name */
  PrimaryInsuranceGroupId = 'primary_insurance_group_id',
  /** column name */
  PrimaryInsuranceMemberId = 'primary_insurance_member_id',
  /** column name */
  PrimaryInsurancePayerId = 'primary_insurance_payer_id',
  /** column name */
  PrimaryInsurancePayerName = 'primary_insurance_payer_name',
  /** column name */
  PrimaryInsurancePlanName = 'primary_insurance_plan_name',
  /** column name */
  SecondaryInsuranceGroupId = 'secondary_insurance_group_id',
  /** column name */
  SecondaryInsuranceMemberId = 'secondary_insurance_member_id',
  /** column name */
  SecondaryInsurancePayerId = 'secondary_insurance_payer_id',
  /** column name */
  SecondaryInsurancePayerName = 'secondary_insurance_payer_name',
  /** column name */
  SecondaryInsurancePlanName = 'secondary_insurance_plan_name',
  /** column name */
  SexAssignedAtBirth = 'sex_assigned_at_birth',
  /** column name */
  SuspectedProviderNpi = 'suspected_provider_npi',
  /** column name */
  TertiaryInsuranceGroupId = 'tertiary_insurance_group_id',
  /** column name */
  TertiaryInsuranceMemberId = 'tertiary_insurance_member_id',
  /** column name */
  TertiaryInsurancePayerId = 'tertiary_insurance_payer_id',
  /** column name */
  TertiaryInsurancePayerName = 'tertiary_insurance_payer_name',
  /** column name */
  TertiaryInsurancePlanName = 'tertiary_insurance_plan_name',
  /** column name */
  TertieryInsuranceGroupId = 'tertiery_insurance_group_id',
  /** column name */
  TertieryInsuranceMemberId = 'tertiery_insurance_member_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** columns and relationships of "practices" */
export type Practices = {
  __typename?: 'practices';
  address_verification_result?: Maybe<Scalars['jsonb']>;
  /** An array relationship */
  baas: Array<Business_Associate_Agreements>;
  /** An aggregated array relationship */
  baas_aggregate: Business_Associate_Agreements_Aggregate;
  /** An array relationship */
  campaign_approvals: Array<Campaign_Approvals>;
  /** An aggregated array relationship */
  campaign_approvals_aggregate: Campaign_Approvals_Aggregate;
  /** An array relationship */
  campaign_templates: Array<Campaign_Templates>;
  /** An aggregated array relationship */
  campaign_templates_aggregate: Campaign_Templates_Aggregate;
  created_at: Scalars['timestamptz'];
  /** An object relationship */
  default_provider?: Maybe<Providers>;
  default_provider_id?: Maybe<Scalars['String']>;
  /** An array relationship */
  emails: Array<Emails>;
  /** An aggregated array relationship */
  emails_aggregate: Emails_Aggregate;
  group_npi?: Maybe<Scalars['String']>;
  is_archived: Scalars['Boolean'];
  legal_business_name?: Maybe<Scalars['String']>;
  /** An array relationship */
  letters: Array<Letters>;
  /** An aggregated array relationship */
  letters_aggregate: Letters_Aggregate;
  /** Used for additional text in the "main doctor" section of SVA form */
  main_doctor_name_extension?: Maybe<Scalars['String']>;
  /** An array relationship */
  patient_roster_files: Array<Patient_Roster_Files>;
  /** An aggregated array relationship */
  patient_roster_files_aggregate: Patient_Roster_Files_Aggregate;
  /** An array relationship */
  patients: Array<Patients>;
  /** An aggregated array relationship */
  patients_aggregate: Patients_Aggregate;
  practice_address?: Maybe<Scalars['String']>;
  practice_color?: Maybe<Scalars['String']>;
  /** Secondary address line displayed on mailers (used for Lob's company field) */
  practice_company?: Maybe<Scalars['String']>;
  practice_description?: Maybe<Scalars['String']>;
  practice_id: Scalars['String'];
  /** Preferred language for the practice, e.g. EN or EN/SP */
  practice_language?: Maybe<Scalars['String']>;
  /** URL of logo file */
  practice_logo?: Maybe<Scalars['String']>;
  practice_merge_vars?: Maybe<Scalars['jsonb']>;
  practice_name: Scalars['String'];
  /** Used for "affiliate providers" or other additional practice info on SVA forms */
  practice_name_extension?: Maybe<Scalars['String']>;
  /** An object relationship */
  primary_network?: Maybe<Networks>;
  primary_network_id?: Maybe<Scalars['String']>;
  /** An array relationship */
  provider_employments: Array<Provider_Employments>;
  /** An aggregated array relationship */
  provider_employments_aggregate: Provider_Employments_Aggregate;
  tin?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
  /** An array relationship */
  users: Array<Users>;
  /** An aggregated array relationship */
  users_aggregate: Users_Aggregate;
};


/** columns and relationships of "practices" */
export type PracticesAddress_Verification_ResultArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "practices" */
export type PracticesBaasArgs = {
  distinct_on?: Maybe<Array<Business_Associate_Agreements_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Business_Associate_Agreements_Order_By>>;
  where?: Maybe<Business_Associate_Agreements_Bool_Exp>;
};


/** columns and relationships of "practices" */
export type PracticesBaas_AggregateArgs = {
  distinct_on?: Maybe<Array<Business_Associate_Agreements_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Business_Associate_Agreements_Order_By>>;
  where?: Maybe<Business_Associate_Agreements_Bool_Exp>;
};


/** columns and relationships of "practices" */
export type PracticesCampaign_ApprovalsArgs = {
  distinct_on?: Maybe<Array<Campaign_Approvals_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Campaign_Approvals_Order_By>>;
  where?: Maybe<Campaign_Approvals_Bool_Exp>;
};


/** columns and relationships of "practices" */
export type PracticesCampaign_Approvals_AggregateArgs = {
  distinct_on?: Maybe<Array<Campaign_Approvals_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Campaign_Approvals_Order_By>>;
  where?: Maybe<Campaign_Approvals_Bool_Exp>;
};


/** columns and relationships of "practices" */
export type PracticesCampaign_TemplatesArgs = {
  distinct_on?: Maybe<Array<Campaign_Templates_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Campaign_Templates_Order_By>>;
  where?: Maybe<Campaign_Templates_Bool_Exp>;
};


/** columns and relationships of "practices" */
export type PracticesCampaign_Templates_AggregateArgs = {
  distinct_on?: Maybe<Array<Campaign_Templates_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Campaign_Templates_Order_By>>;
  where?: Maybe<Campaign_Templates_Bool_Exp>;
};


/** columns and relationships of "practices" */
export type PracticesEmailsArgs = {
  distinct_on?: Maybe<Array<Emails_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Emails_Order_By>>;
  where?: Maybe<Emails_Bool_Exp>;
};


/** columns and relationships of "practices" */
export type PracticesEmails_AggregateArgs = {
  distinct_on?: Maybe<Array<Emails_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Emails_Order_By>>;
  where?: Maybe<Emails_Bool_Exp>;
};


/** columns and relationships of "practices" */
export type PracticesLettersArgs = {
  distinct_on?: Maybe<Array<Letters_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Letters_Order_By>>;
  where?: Maybe<Letters_Bool_Exp>;
};


/** columns and relationships of "practices" */
export type PracticesLetters_AggregateArgs = {
  distinct_on?: Maybe<Array<Letters_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Letters_Order_By>>;
  where?: Maybe<Letters_Bool_Exp>;
};


/** columns and relationships of "practices" */
export type PracticesPatient_Roster_FilesArgs = {
  distinct_on?: Maybe<Array<Patient_Roster_Files_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Patient_Roster_Files_Order_By>>;
  where?: Maybe<Patient_Roster_Files_Bool_Exp>;
};


/** columns and relationships of "practices" */
export type PracticesPatient_Roster_Files_AggregateArgs = {
  distinct_on?: Maybe<Array<Patient_Roster_Files_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Patient_Roster_Files_Order_By>>;
  where?: Maybe<Patient_Roster_Files_Bool_Exp>;
};


/** columns and relationships of "practices" */
export type PracticesPatientsArgs = {
  distinct_on?: Maybe<Array<Patients_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Patients_Order_By>>;
  where?: Maybe<Patients_Bool_Exp>;
};


/** columns and relationships of "practices" */
export type PracticesPatients_AggregateArgs = {
  distinct_on?: Maybe<Array<Patients_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Patients_Order_By>>;
  where?: Maybe<Patients_Bool_Exp>;
};


/** columns and relationships of "practices" */
export type PracticesPractice_Merge_VarsArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "practices" */
export type PracticesProvider_EmploymentsArgs = {
  distinct_on?: Maybe<Array<Provider_Employments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Provider_Employments_Order_By>>;
  where?: Maybe<Provider_Employments_Bool_Exp>;
};


/** columns and relationships of "practices" */
export type PracticesProvider_Employments_AggregateArgs = {
  distinct_on?: Maybe<Array<Provider_Employments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Provider_Employments_Order_By>>;
  where?: Maybe<Provider_Employments_Bool_Exp>;
};


/** columns and relationships of "practices" */
export type PracticesUsersArgs = {
  distinct_on?: Maybe<Array<Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Users_Order_By>>;
  where?: Maybe<Users_Bool_Exp>;
};


/** columns and relationships of "practices" */
export type PracticesUsers_AggregateArgs = {
  distinct_on?: Maybe<Array<Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Users_Order_By>>;
  where?: Maybe<Users_Bool_Exp>;
};

/** aggregated selection of "practices" */
export type Practices_Aggregate = {
  __typename?: 'practices_aggregate';
  aggregate?: Maybe<Practices_Aggregate_Fields>;
  nodes: Array<Practices>;
};

/** aggregate fields of "practices" */
export type Practices_Aggregate_Fields = {
  __typename?: 'practices_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Practices_Max_Fields>;
  min?: Maybe<Practices_Min_Fields>;
};


/** aggregate fields of "practices" */
export type Practices_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Practices_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "practices" */
export type Practices_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Practices_Max_Order_By>;
  min?: Maybe<Practices_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Practices_Append_Input = {
  address_verification_result?: Maybe<Scalars['jsonb']>;
  practice_merge_vars?: Maybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "practices" */
export type Practices_Arr_Rel_Insert_Input = {
  data: Array<Practices_Insert_Input>;
  on_conflict?: Maybe<Practices_On_Conflict>;
};

/** Boolean expression to filter rows from the table "practices". All fields are combined with a logical 'AND'. */
export type Practices_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Practices_Bool_Exp>>>;
  _not?: Maybe<Practices_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Practices_Bool_Exp>>>;
  address_verification_result?: Maybe<Jsonb_Comparison_Exp>;
  baas?: Maybe<Business_Associate_Agreements_Bool_Exp>;
  campaign_approvals?: Maybe<Campaign_Approvals_Bool_Exp>;
  campaign_templates?: Maybe<Campaign_Templates_Bool_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  default_provider?: Maybe<Providers_Bool_Exp>;
  default_provider_id?: Maybe<String_Comparison_Exp>;
  emails?: Maybe<Emails_Bool_Exp>;
  group_npi?: Maybe<String_Comparison_Exp>;
  is_archived?: Maybe<Boolean_Comparison_Exp>;
  legal_business_name?: Maybe<String_Comparison_Exp>;
  letters?: Maybe<Letters_Bool_Exp>;
  main_doctor_name_extension?: Maybe<String_Comparison_Exp>;
  patient_roster_files?: Maybe<Patient_Roster_Files_Bool_Exp>;
  patients?: Maybe<Patients_Bool_Exp>;
  practice_address?: Maybe<String_Comparison_Exp>;
  practice_color?: Maybe<String_Comparison_Exp>;
  practice_company?: Maybe<String_Comparison_Exp>;
  practice_description?: Maybe<String_Comparison_Exp>;
  practice_id?: Maybe<String_Comparison_Exp>;
  practice_language?: Maybe<String_Comparison_Exp>;
  practice_logo?: Maybe<String_Comparison_Exp>;
  practice_merge_vars?: Maybe<Jsonb_Comparison_Exp>;
  practice_name?: Maybe<String_Comparison_Exp>;
  practice_name_extension?: Maybe<String_Comparison_Exp>;
  primary_network?: Maybe<Networks_Bool_Exp>;
  primary_network_id?: Maybe<String_Comparison_Exp>;
  provider_employments?: Maybe<Provider_Employments_Bool_Exp>;
  tin?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  users?: Maybe<Users_Bool_Exp>;
};

/** unique or primary key constraints on table "practices" */
export enum Practices_Constraint {
  /** unique or primary key constraint */
  PracticesPkey = 'practices_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Practices_Delete_At_Path_Input = {
  address_verification_result?: Maybe<Array<Maybe<Scalars['String']>>>;
  practice_merge_vars?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Practices_Delete_Elem_Input = {
  address_verification_result?: Maybe<Scalars['Int']>;
  practice_merge_vars?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Practices_Delete_Key_Input = {
  address_verification_result?: Maybe<Scalars['String']>;
  practice_merge_vars?: Maybe<Scalars['String']>;
};

/** input type for inserting data into table "practices" */
export type Practices_Insert_Input = {
  address_verification_result?: Maybe<Scalars['jsonb']>;
  baas?: Maybe<Business_Associate_Agreements_Arr_Rel_Insert_Input>;
  campaign_approvals?: Maybe<Campaign_Approvals_Arr_Rel_Insert_Input>;
  campaign_templates?: Maybe<Campaign_Templates_Arr_Rel_Insert_Input>;
  created_at?: Maybe<Scalars['timestamptz']>;
  default_provider?: Maybe<Providers_Obj_Rel_Insert_Input>;
  default_provider_id?: Maybe<Scalars['String']>;
  emails?: Maybe<Emails_Arr_Rel_Insert_Input>;
  group_npi?: Maybe<Scalars['String']>;
  is_archived?: Maybe<Scalars['Boolean']>;
  legal_business_name?: Maybe<Scalars['String']>;
  letters?: Maybe<Letters_Arr_Rel_Insert_Input>;
  main_doctor_name_extension?: Maybe<Scalars['String']>;
  patient_roster_files?: Maybe<Patient_Roster_Files_Arr_Rel_Insert_Input>;
  patients?: Maybe<Patients_Arr_Rel_Insert_Input>;
  practice_address?: Maybe<Scalars['String']>;
  practice_color?: Maybe<Scalars['String']>;
  practice_company?: Maybe<Scalars['String']>;
  practice_description?: Maybe<Scalars['String']>;
  practice_id?: Maybe<Scalars['String']>;
  practice_language?: Maybe<Scalars['String']>;
  practice_logo?: Maybe<Scalars['String']>;
  practice_merge_vars?: Maybe<Scalars['jsonb']>;
  practice_name?: Maybe<Scalars['String']>;
  practice_name_extension?: Maybe<Scalars['String']>;
  primary_network?: Maybe<Networks_Obj_Rel_Insert_Input>;
  primary_network_id?: Maybe<Scalars['String']>;
  provider_employments?: Maybe<Provider_Employments_Arr_Rel_Insert_Input>;
  tin?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  users?: Maybe<Users_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Practices_Max_Fields = {
  __typename?: 'practices_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  default_provider_id?: Maybe<Scalars['String']>;
  group_npi?: Maybe<Scalars['String']>;
  legal_business_name?: Maybe<Scalars['String']>;
  main_doctor_name_extension?: Maybe<Scalars['String']>;
  practice_address?: Maybe<Scalars['String']>;
  practice_color?: Maybe<Scalars['String']>;
  practice_company?: Maybe<Scalars['String']>;
  practice_description?: Maybe<Scalars['String']>;
  practice_id?: Maybe<Scalars['String']>;
  practice_language?: Maybe<Scalars['String']>;
  practice_logo?: Maybe<Scalars['String']>;
  practice_name?: Maybe<Scalars['String']>;
  practice_name_extension?: Maybe<Scalars['String']>;
  primary_network_id?: Maybe<Scalars['String']>;
  tin?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "practices" */
export type Practices_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  default_provider_id?: Maybe<Order_By>;
  group_npi?: Maybe<Order_By>;
  legal_business_name?: Maybe<Order_By>;
  main_doctor_name_extension?: Maybe<Order_By>;
  practice_address?: Maybe<Order_By>;
  practice_color?: Maybe<Order_By>;
  practice_company?: Maybe<Order_By>;
  practice_description?: Maybe<Order_By>;
  practice_id?: Maybe<Order_By>;
  practice_language?: Maybe<Order_By>;
  practice_logo?: Maybe<Order_By>;
  practice_name?: Maybe<Order_By>;
  practice_name_extension?: Maybe<Order_By>;
  primary_network_id?: Maybe<Order_By>;
  tin?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Practices_Min_Fields = {
  __typename?: 'practices_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  default_provider_id?: Maybe<Scalars['String']>;
  group_npi?: Maybe<Scalars['String']>;
  legal_business_name?: Maybe<Scalars['String']>;
  main_doctor_name_extension?: Maybe<Scalars['String']>;
  practice_address?: Maybe<Scalars['String']>;
  practice_color?: Maybe<Scalars['String']>;
  practice_company?: Maybe<Scalars['String']>;
  practice_description?: Maybe<Scalars['String']>;
  practice_id?: Maybe<Scalars['String']>;
  practice_language?: Maybe<Scalars['String']>;
  practice_logo?: Maybe<Scalars['String']>;
  practice_name?: Maybe<Scalars['String']>;
  practice_name_extension?: Maybe<Scalars['String']>;
  primary_network_id?: Maybe<Scalars['String']>;
  tin?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "practices" */
export type Practices_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  default_provider_id?: Maybe<Order_By>;
  group_npi?: Maybe<Order_By>;
  legal_business_name?: Maybe<Order_By>;
  main_doctor_name_extension?: Maybe<Order_By>;
  practice_address?: Maybe<Order_By>;
  practice_color?: Maybe<Order_By>;
  practice_company?: Maybe<Order_By>;
  practice_description?: Maybe<Order_By>;
  practice_id?: Maybe<Order_By>;
  practice_language?: Maybe<Order_By>;
  practice_logo?: Maybe<Order_By>;
  practice_name?: Maybe<Order_By>;
  practice_name_extension?: Maybe<Order_By>;
  primary_network_id?: Maybe<Order_By>;
  tin?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "practices" */
export type Practices_Mutation_Response = {
  __typename?: 'practices_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Practices>;
};

/** input type for inserting object relation for remote table "practices" */
export type Practices_Obj_Rel_Insert_Input = {
  data: Practices_Insert_Input;
  on_conflict?: Maybe<Practices_On_Conflict>;
};

/** on conflict condition type for table "practices" */
export type Practices_On_Conflict = {
  constraint: Practices_Constraint;
  update_columns: Array<Practices_Update_Column>;
  where?: Maybe<Practices_Bool_Exp>;
};

/** ordering options when selecting data from "practices" */
export type Practices_Order_By = {
  address_verification_result?: Maybe<Order_By>;
  baas_aggregate?: Maybe<Business_Associate_Agreements_Aggregate_Order_By>;
  campaign_approvals_aggregate?: Maybe<Campaign_Approvals_Aggregate_Order_By>;
  campaign_templates_aggregate?: Maybe<Campaign_Templates_Aggregate_Order_By>;
  created_at?: Maybe<Order_By>;
  default_provider?: Maybe<Providers_Order_By>;
  default_provider_id?: Maybe<Order_By>;
  emails_aggregate?: Maybe<Emails_Aggregate_Order_By>;
  group_npi?: Maybe<Order_By>;
  is_archived?: Maybe<Order_By>;
  legal_business_name?: Maybe<Order_By>;
  letters_aggregate?: Maybe<Letters_Aggregate_Order_By>;
  main_doctor_name_extension?: Maybe<Order_By>;
  patient_roster_files_aggregate?: Maybe<Patient_Roster_Files_Aggregate_Order_By>;
  patients_aggregate?: Maybe<Patients_Aggregate_Order_By>;
  practice_address?: Maybe<Order_By>;
  practice_color?: Maybe<Order_By>;
  practice_company?: Maybe<Order_By>;
  practice_description?: Maybe<Order_By>;
  practice_id?: Maybe<Order_By>;
  practice_language?: Maybe<Order_By>;
  practice_logo?: Maybe<Order_By>;
  practice_merge_vars?: Maybe<Order_By>;
  practice_name?: Maybe<Order_By>;
  practice_name_extension?: Maybe<Order_By>;
  primary_network?: Maybe<Networks_Order_By>;
  primary_network_id?: Maybe<Order_By>;
  provider_employments_aggregate?: Maybe<Provider_Employments_Aggregate_Order_By>;
  tin?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  users_aggregate?: Maybe<Users_Aggregate_Order_By>;
};

/** primary key columns input for table: "practices" */
export type Practices_Pk_Columns_Input = {
  practice_id: Scalars['String'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Practices_Prepend_Input = {
  address_verification_result?: Maybe<Scalars['jsonb']>;
  practice_merge_vars?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "practices" */
export enum Practices_Select_Column {
  /** column name */
  AddressVerificationResult = 'address_verification_result',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DefaultProviderId = 'default_provider_id',
  /** column name */
  GroupNpi = 'group_npi',
  /** column name */
  IsArchived = 'is_archived',
  /** column name */
  LegalBusinessName = 'legal_business_name',
  /** column name */
  MainDoctorNameExtension = 'main_doctor_name_extension',
  /** column name */
  PracticeAddress = 'practice_address',
  /** column name */
  PracticeColor = 'practice_color',
  /** column name */
  PracticeCompany = 'practice_company',
  /** column name */
  PracticeDescription = 'practice_description',
  /** column name */
  PracticeId = 'practice_id',
  /** column name */
  PracticeLanguage = 'practice_language',
  /** column name */
  PracticeLogo = 'practice_logo',
  /** column name */
  PracticeMergeVars = 'practice_merge_vars',
  /** column name */
  PracticeName = 'practice_name',
  /** column name */
  PracticeNameExtension = 'practice_name_extension',
  /** column name */
  PrimaryNetworkId = 'primary_network_id',
  /** column name */
  Tin = 'tin',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "practices" */
export type Practices_Set_Input = {
  address_verification_result?: Maybe<Scalars['jsonb']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  default_provider_id?: Maybe<Scalars['String']>;
  group_npi?: Maybe<Scalars['String']>;
  is_archived?: Maybe<Scalars['Boolean']>;
  legal_business_name?: Maybe<Scalars['String']>;
  main_doctor_name_extension?: Maybe<Scalars['String']>;
  practice_address?: Maybe<Scalars['String']>;
  practice_color?: Maybe<Scalars['String']>;
  practice_company?: Maybe<Scalars['String']>;
  practice_description?: Maybe<Scalars['String']>;
  practice_id?: Maybe<Scalars['String']>;
  practice_language?: Maybe<Scalars['String']>;
  practice_logo?: Maybe<Scalars['String']>;
  practice_merge_vars?: Maybe<Scalars['jsonb']>;
  practice_name?: Maybe<Scalars['String']>;
  practice_name_extension?: Maybe<Scalars['String']>;
  primary_network_id?: Maybe<Scalars['String']>;
  tin?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "practices" */
export enum Practices_Update_Column {
  /** column name */
  AddressVerificationResult = 'address_verification_result',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DefaultProviderId = 'default_provider_id',
  /** column name */
  GroupNpi = 'group_npi',
  /** column name */
  IsArchived = 'is_archived',
  /** column name */
  LegalBusinessName = 'legal_business_name',
  /** column name */
  MainDoctorNameExtension = 'main_doctor_name_extension',
  /** column name */
  PracticeAddress = 'practice_address',
  /** column name */
  PracticeColor = 'practice_color',
  /** column name */
  PracticeCompany = 'practice_company',
  /** column name */
  PracticeDescription = 'practice_description',
  /** column name */
  PracticeId = 'practice_id',
  /** column name */
  PracticeLanguage = 'practice_language',
  /** column name */
  PracticeLogo = 'practice_logo',
  /** column name */
  PracticeMergeVars = 'practice_merge_vars',
  /** column name */
  PracticeName = 'practice_name',
  /** column name */
  PracticeNameExtension = 'practice_name_extension',
  /** column name */
  PrimaryNetworkId = 'primary_network_id',
  /** column name */
  Tin = 'tin',
  /** column name */
  UpdatedAt = 'updated_at'
}

/**
 * Maps providers to practices that employ them
 * 
 * 
 * columns and relationships of "provider_employments"
 */
export type Provider_Employments = {
  __typename?: 'provider_employments';
  created_at: Scalars['timestamptz'];
  is_archived: Scalars['Boolean'];
  /** An object relationship */
  practice: Practices;
  practice_id: Scalars['String'];
  /** An object relationship */
  provider: Providers;
  provider_id: Scalars['String'];
  tin?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "provider_employments" */
export type Provider_Employments_Aggregate = {
  __typename?: 'provider_employments_aggregate';
  aggregate?: Maybe<Provider_Employments_Aggregate_Fields>;
  nodes: Array<Provider_Employments>;
};

/** aggregate fields of "provider_employments" */
export type Provider_Employments_Aggregate_Fields = {
  __typename?: 'provider_employments_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Provider_Employments_Max_Fields>;
  min?: Maybe<Provider_Employments_Min_Fields>;
};


/** aggregate fields of "provider_employments" */
export type Provider_Employments_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Provider_Employments_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "provider_employments" */
export type Provider_Employments_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Provider_Employments_Max_Order_By>;
  min?: Maybe<Provider_Employments_Min_Order_By>;
};

/** input type for inserting array relation for remote table "provider_employments" */
export type Provider_Employments_Arr_Rel_Insert_Input = {
  data: Array<Provider_Employments_Insert_Input>;
  on_conflict?: Maybe<Provider_Employments_On_Conflict>;
};

/** Boolean expression to filter rows from the table "provider_employments". All fields are combined with a logical 'AND'. */
export type Provider_Employments_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Provider_Employments_Bool_Exp>>>;
  _not?: Maybe<Provider_Employments_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Provider_Employments_Bool_Exp>>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  is_archived?: Maybe<Boolean_Comparison_Exp>;
  practice?: Maybe<Practices_Bool_Exp>;
  practice_id?: Maybe<String_Comparison_Exp>;
  provider?: Maybe<Providers_Bool_Exp>;
  provider_id?: Maybe<String_Comparison_Exp>;
  tin?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "provider_employments" */
export enum Provider_Employments_Constraint {
  /** unique or primary key constraint */
  ProviderEmploymentsPkey = 'provider_employments_pkey'
}

/** input type for inserting data into table "provider_employments" */
export type Provider_Employments_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  is_archived?: Maybe<Scalars['Boolean']>;
  practice?: Maybe<Practices_Obj_Rel_Insert_Input>;
  practice_id?: Maybe<Scalars['String']>;
  provider?: Maybe<Providers_Obj_Rel_Insert_Input>;
  provider_id?: Maybe<Scalars['String']>;
  tin?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Provider_Employments_Max_Fields = {
  __typename?: 'provider_employments_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  practice_id?: Maybe<Scalars['String']>;
  provider_id?: Maybe<Scalars['String']>;
  tin?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "provider_employments" */
export type Provider_Employments_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  practice_id?: Maybe<Order_By>;
  provider_id?: Maybe<Order_By>;
  tin?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Provider_Employments_Min_Fields = {
  __typename?: 'provider_employments_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  practice_id?: Maybe<Scalars['String']>;
  provider_id?: Maybe<Scalars['String']>;
  tin?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "provider_employments" */
export type Provider_Employments_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  practice_id?: Maybe<Order_By>;
  provider_id?: Maybe<Order_By>;
  tin?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "provider_employments" */
export type Provider_Employments_Mutation_Response = {
  __typename?: 'provider_employments_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Provider_Employments>;
};

/** input type for inserting object relation for remote table "provider_employments" */
export type Provider_Employments_Obj_Rel_Insert_Input = {
  data: Provider_Employments_Insert_Input;
  on_conflict?: Maybe<Provider_Employments_On_Conflict>;
};

/** on conflict condition type for table "provider_employments" */
export type Provider_Employments_On_Conflict = {
  constraint: Provider_Employments_Constraint;
  update_columns: Array<Provider_Employments_Update_Column>;
  where?: Maybe<Provider_Employments_Bool_Exp>;
};

/** ordering options when selecting data from "provider_employments" */
export type Provider_Employments_Order_By = {
  created_at?: Maybe<Order_By>;
  is_archived?: Maybe<Order_By>;
  practice?: Maybe<Practices_Order_By>;
  practice_id?: Maybe<Order_By>;
  provider?: Maybe<Providers_Order_By>;
  provider_id?: Maybe<Order_By>;
  tin?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: "provider_employments" */
export type Provider_Employments_Pk_Columns_Input = {
  practice_id: Scalars['String'];
  provider_id: Scalars['String'];
};

/** select columns of table "provider_employments" */
export enum Provider_Employments_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  IsArchived = 'is_archived',
  /** column name */
  PracticeId = 'practice_id',
  /** column name */
  ProviderId = 'provider_id',
  /** column name */
  Tin = 'tin',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "provider_employments" */
export type Provider_Employments_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  is_archived?: Maybe<Scalars['Boolean']>;
  practice_id?: Maybe<Scalars['String']>;
  provider_id?: Maybe<Scalars['String']>;
  tin?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "provider_employments" */
export enum Provider_Employments_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  IsArchived = 'is_archived',
  /** column name */
  PracticeId = 'practice_id',
  /** column name */
  ProviderId = 'provider_id',
  /** column name */
  Tin = 'tin',
  /** column name */
  UpdatedAt = 'updated_at'
}

/**
 * Maps providers to networks (IPAs/Medical Groups) they belong to
 * 
 * 
 * columns and relationships of "provider_memberships"
 */
export type Provider_Memberships = {
  __typename?: 'provider_memberships';
  created_at?: Maybe<Scalars['timestamptz']>;
  is_archived: Scalars['Boolean'];
  /** An object relationship */
  network: Networks;
  network_id: Scalars['String'];
  /** An object relationship */
  provider: Providers;
  provider_id: Scalars['String'];
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregated selection of "provider_memberships" */
export type Provider_Memberships_Aggregate = {
  __typename?: 'provider_memberships_aggregate';
  aggregate?: Maybe<Provider_Memberships_Aggregate_Fields>;
  nodes: Array<Provider_Memberships>;
};

/** aggregate fields of "provider_memberships" */
export type Provider_Memberships_Aggregate_Fields = {
  __typename?: 'provider_memberships_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Provider_Memberships_Max_Fields>;
  min?: Maybe<Provider_Memberships_Min_Fields>;
};


/** aggregate fields of "provider_memberships" */
export type Provider_Memberships_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Provider_Memberships_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "provider_memberships" */
export type Provider_Memberships_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Provider_Memberships_Max_Order_By>;
  min?: Maybe<Provider_Memberships_Min_Order_By>;
};

/** input type for inserting array relation for remote table "provider_memberships" */
export type Provider_Memberships_Arr_Rel_Insert_Input = {
  data: Array<Provider_Memberships_Insert_Input>;
  on_conflict?: Maybe<Provider_Memberships_On_Conflict>;
};

/** Boolean expression to filter rows from the table "provider_memberships". All fields are combined with a logical 'AND'. */
export type Provider_Memberships_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Provider_Memberships_Bool_Exp>>>;
  _not?: Maybe<Provider_Memberships_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Provider_Memberships_Bool_Exp>>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  is_archived?: Maybe<Boolean_Comparison_Exp>;
  network?: Maybe<Networks_Bool_Exp>;
  network_id?: Maybe<String_Comparison_Exp>;
  provider?: Maybe<Providers_Bool_Exp>;
  provider_id?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "provider_memberships" */
export enum Provider_Memberships_Constraint {
  /** unique or primary key constraint */
  ProviderMembershipsPkey = 'provider_memberships_pkey'
}

/** input type for inserting data into table "provider_memberships" */
export type Provider_Memberships_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  is_archived?: Maybe<Scalars['Boolean']>;
  network?: Maybe<Networks_Obj_Rel_Insert_Input>;
  network_id?: Maybe<Scalars['String']>;
  provider?: Maybe<Providers_Obj_Rel_Insert_Input>;
  provider_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Provider_Memberships_Max_Fields = {
  __typename?: 'provider_memberships_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  network_id?: Maybe<Scalars['String']>;
  provider_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "provider_memberships" */
export type Provider_Memberships_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  network_id?: Maybe<Order_By>;
  provider_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Provider_Memberships_Min_Fields = {
  __typename?: 'provider_memberships_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  network_id?: Maybe<Scalars['String']>;
  provider_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "provider_memberships" */
export type Provider_Memberships_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  network_id?: Maybe<Order_By>;
  provider_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "provider_memberships" */
export type Provider_Memberships_Mutation_Response = {
  __typename?: 'provider_memberships_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Provider_Memberships>;
};

/** input type for inserting object relation for remote table "provider_memberships" */
export type Provider_Memberships_Obj_Rel_Insert_Input = {
  data: Provider_Memberships_Insert_Input;
  on_conflict?: Maybe<Provider_Memberships_On_Conflict>;
};

/** on conflict condition type for table "provider_memberships" */
export type Provider_Memberships_On_Conflict = {
  constraint: Provider_Memberships_Constraint;
  update_columns: Array<Provider_Memberships_Update_Column>;
  where?: Maybe<Provider_Memberships_Bool_Exp>;
};

/** ordering options when selecting data from "provider_memberships" */
export type Provider_Memberships_Order_By = {
  created_at?: Maybe<Order_By>;
  is_archived?: Maybe<Order_By>;
  network?: Maybe<Networks_Order_By>;
  network_id?: Maybe<Order_By>;
  provider?: Maybe<Providers_Order_By>;
  provider_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: "provider_memberships" */
export type Provider_Memberships_Pk_Columns_Input = {
  network_id: Scalars['String'];
  provider_id: Scalars['String'];
};

/** select columns of table "provider_memberships" */
export enum Provider_Memberships_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  IsArchived = 'is_archived',
  /** column name */
  NetworkId = 'network_id',
  /** column name */
  ProviderId = 'provider_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "provider_memberships" */
export type Provider_Memberships_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  is_archived?: Maybe<Scalars['Boolean']>;
  network_id?: Maybe<Scalars['String']>;
  provider_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "provider_memberships" */
export enum Provider_Memberships_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  IsArchived = 'is_archived',
  /** column name */
  NetworkId = 'network_id',
  /** column name */
  ProviderId = 'provider_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** columns and relationships of "providers" */
export type Providers = {
  __typename?: 'providers';
  billing_tin?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamptz'];
  is_archived: Scalars['Boolean'];
  /** An array relationship */
  provider_employments: Array<Provider_Employments>;
  /** An aggregated array relationship */
  provider_employments_aggregate: Provider_Employments_Aggregate;
  provider_id: Scalars['String'];
  /** An array relationship */
  provider_memberships: Array<Provider_Memberships>;
  /** An aggregated array relationship */
  provider_memberships_aggregate: Provider_Memberships_Aggregate;
  provider_name: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "providers" */
export type ProvidersProvider_EmploymentsArgs = {
  distinct_on?: Maybe<Array<Provider_Employments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Provider_Employments_Order_By>>;
  where?: Maybe<Provider_Employments_Bool_Exp>;
};


/** columns and relationships of "providers" */
export type ProvidersProvider_Employments_AggregateArgs = {
  distinct_on?: Maybe<Array<Provider_Employments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Provider_Employments_Order_By>>;
  where?: Maybe<Provider_Employments_Bool_Exp>;
};


/** columns and relationships of "providers" */
export type ProvidersProvider_MembershipsArgs = {
  distinct_on?: Maybe<Array<Provider_Memberships_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Provider_Memberships_Order_By>>;
  where?: Maybe<Provider_Memberships_Bool_Exp>;
};


/** columns and relationships of "providers" */
export type ProvidersProvider_Memberships_AggregateArgs = {
  distinct_on?: Maybe<Array<Provider_Memberships_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Provider_Memberships_Order_By>>;
  where?: Maybe<Provider_Memberships_Bool_Exp>;
};

/** aggregated selection of "providers" */
export type Providers_Aggregate = {
  __typename?: 'providers_aggregate';
  aggregate?: Maybe<Providers_Aggregate_Fields>;
  nodes: Array<Providers>;
};

/** aggregate fields of "providers" */
export type Providers_Aggregate_Fields = {
  __typename?: 'providers_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Providers_Max_Fields>;
  min?: Maybe<Providers_Min_Fields>;
};


/** aggregate fields of "providers" */
export type Providers_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Providers_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "providers" */
export type Providers_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Providers_Max_Order_By>;
  min?: Maybe<Providers_Min_Order_By>;
};

/** input type for inserting array relation for remote table "providers" */
export type Providers_Arr_Rel_Insert_Input = {
  data: Array<Providers_Insert_Input>;
  on_conflict?: Maybe<Providers_On_Conflict>;
};

/** Boolean expression to filter rows from the table "providers". All fields are combined with a logical 'AND'. */
export type Providers_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Providers_Bool_Exp>>>;
  _not?: Maybe<Providers_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Providers_Bool_Exp>>>;
  billing_tin?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  is_archived?: Maybe<Boolean_Comparison_Exp>;
  provider_employments?: Maybe<Provider_Employments_Bool_Exp>;
  provider_id?: Maybe<String_Comparison_Exp>;
  provider_memberships?: Maybe<Provider_Memberships_Bool_Exp>;
  provider_name?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "providers" */
export enum Providers_Constraint {
  /** unique or primary key constraint */
  ProvidersPkey = 'providers_pkey'
}

/** input type for inserting data into table "providers" */
export type Providers_Insert_Input = {
  billing_tin?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  is_archived?: Maybe<Scalars['Boolean']>;
  provider_employments?: Maybe<Provider_Employments_Arr_Rel_Insert_Input>;
  provider_id?: Maybe<Scalars['String']>;
  provider_memberships?: Maybe<Provider_Memberships_Arr_Rel_Insert_Input>;
  provider_name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Providers_Max_Fields = {
  __typename?: 'providers_max_fields';
  billing_tin?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  provider_id?: Maybe<Scalars['String']>;
  provider_name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "providers" */
export type Providers_Max_Order_By = {
  billing_tin?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  provider_id?: Maybe<Order_By>;
  provider_name?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Providers_Min_Fields = {
  __typename?: 'providers_min_fields';
  billing_tin?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  provider_id?: Maybe<Scalars['String']>;
  provider_name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "providers" */
export type Providers_Min_Order_By = {
  billing_tin?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  provider_id?: Maybe<Order_By>;
  provider_name?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "providers" */
export type Providers_Mutation_Response = {
  __typename?: 'providers_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Providers>;
};

/** input type for inserting object relation for remote table "providers" */
export type Providers_Obj_Rel_Insert_Input = {
  data: Providers_Insert_Input;
  on_conflict?: Maybe<Providers_On_Conflict>;
};

/** on conflict condition type for table "providers" */
export type Providers_On_Conflict = {
  constraint: Providers_Constraint;
  update_columns: Array<Providers_Update_Column>;
  where?: Maybe<Providers_Bool_Exp>;
};

/** ordering options when selecting data from "providers" */
export type Providers_Order_By = {
  billing_tin?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  is_archived?: Maybe<Order_By>;
  provider_employments_aggregate?: Maybe<Provider_Employments_Aggregate_Order_By>;
  provider_id?: Maybe<Order_By>;
  provider_memberships_aggregate?: Maybe<Provider_Memberships_Aggregate_Order_By>;
  provider_name?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: "providers" */
export type Providers_Pk_Columns_Input = {
  provider_id: Scalars['String'];
};

/** select columns of table "providers" */
export enum Providers_Select_Column {
  /** column name */
  BillingTin = 'billing_tin',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  IsArchived = 'is_archived',
  /** column name */
  ProviderId = 'provider_id',
  /** column name */
  ProviderName = 'provider_name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "providers" */
export type Providers_Set_Input = {
  billing_tin?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  is_archived?: Maybe<Scalars['Boolean']>;
  provider_id?: Maybe<Scalars['String']>;
  provider_name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "providers" */
export enum Providers_Update_Column {
  /** column name */
  BillingTin = 'billing_tin',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  IsArchived = 'is_archived',
  /** column name */
  ProviderId = 'provider_id',
  /** column name */
  ProviderName = 'provider_name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/**
 * Paper-Based Voluntary Alignment
 * 
 * 
 * columns and relationships of "pvas"
 */
export type Pvas = {
  __typename?: 'pvas';
  /** An object relationship */
  alignment?: Maybe<Alignments>;
  alignment_id?: Maybe<Scalars['String']>;
  beneficiary_first_name?: Maybe<Scalars['String']>;
  beneficiary_last_name?: Maybe<Scalars['String']>;
  beneficiary_name?: Maybe<Scalars['String']>;
  birth_date?: Maybe<Scalars['date']>;
  birth_date_raw?: Maybe<Scalars['String']>;
  channel?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamptz'];
  is_archived: Scalars['Boolean'];
  is_signed: Scalars['Boolean'];
  language?: Maybe<Scalars['String']>;
  /** An object relationship */
  letter?: Maybe<Letters>;
  letter_id?: Maybe<Scalars['String']>;
  letter_id_prefix?: Maybe<Scalars['String']>;
  /** An object relationship */
  matched_patient?: Maybe<Patients>;
  matched_patient_id?: Maybe<Scalars['String']>;
  mbi?: Maybe<Scalars['String']>;
  /** An object relationship */
  network?: Maybe<Networks>;
  network_number?: Maybe<Scalars['Int']>;
  ocr_output?: Maybe<Scalars['jsonb']>;
  onedrive_file_id?: Maybe<Scalars['String']>;
  /** An array relationship */
  patients: Array<Patients>;
  /** An aggregated array relationship */
  patients_aggregate: Patients_Aggregate;
  processing_status?: Maybe<Scalars['String']>;
  provider_name_or_med_group?: Maybe<Scalars['String']>;
  pva_id: Scalars['String'];
  pva_npi?: Maybe<Scalars['String']>;
  pva_practice_id?: Maybe<Scalars['String']>;
  pva_practice_name?: Maybe<Scalars['String']>;
  pva_tin?: Maybe<Scalars['String']>;
  short_url?: Maybe<Scalars['String']>;
  signature_date?: Maybe<Scalars['date']>;
  signature_date_raw?: Maybe<Scalars['String']>;
  signature_raw?: Maybe<Scalars['String']>;
  signature_url?: Maybe<Scalars['String']>;
  signer_printed_name?: Maybe<Scalars['String']>;
  submission_id?: Maybe<Scalars['String']>;
  submission_source?: Maybe<Scalars['String']>;
  template_version?: Maybe<Scalars['String']>;
  transcriber_notes?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
  ycode?: Maybe<Scalars['String']>;
};


/**
 * Paper-Based Voluntary Alignment
 * 
 * 
 * columns and relationships of "pvas"
 */
export type PvasOcr_OutputArgs = {
  path?: Maybe<Scalars['String']>;
};


/**
 * Paper-Based Voluntary Alignment
 * 
 * 
 * columns and relationships of "pvas"
 */
export type PvasPatientsArgs = {
  distinct_on?: Maybe<Array<Patients_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Patients_Order_By>>;
  where?: Maybe<Patients_Bool_Exp>;
};


/**
 * Paper-Based Voluntary Alignment
 * 
 * 
 * columns and relationships of "pvas"
 */
export type PvasPatients_AggregateArgs = {
  distinct_on?: Maybe<Array<Patients_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Patients_Order_By>>;
  where?: Maybe<Patients_Bool_Exp>;
};

/** aggregated selection of "pvas" */
export type Pvas_Aggregate = {
  __typename?: 'pvas_aggregate';
  aggregate?: Maybe<Pvas_Aggregate_Fields>;
  nodes: Array<Pvas>;
};

/** aggregate fields of "pvas" */
export type Pvas_Aggregate_Fields = {
  __typename?: 'pvas_aggregate_fields';
  avg?: Maybe<Pvas_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Pvas_Max_Fields>;
  min?: Maybe<Pvas_Min_Fields>;
  stddev?: Maybe<Pvas_Stddev_Fields>;
  stddev_pop?: Maybe<Pvas_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Pvas_Stddev_Samp_Fields>;
  sum?: Maybe<Pvas_Sum_Fields>;
  var_pop?: Maybe<Pvas_Var_Pop_Fields>;
  var_samp?: Maybe<Pvas_Var_Samp_Fields>;
  variance?: Maybe<Pvas_Variance_Fields>;
};


/** aggregate fields of "pvas" */
export type Pvas_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Pvas_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "pvas" */
export type Pvas_Aggregate_Order_By = {
  avg?: Maybe<Pvas_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Pvas_Max_Order_By>;
  min?: Maybe<Pvas_Min_Order_By>;
  stddev?: Maybe<Pvas_Stddev_Order_By>;
  stddev_pop?: Maybe<Pvas_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Pvas_Stddev_Samp_Order_By>;
  sum?: Maybe<Pvas_Sum_Order_By>;
  var_pop?: Maybe<Pvas_Var_Pop_Order_By>;
  var_samp?: Maybe<Pvas_Var_Samp_Order_By>;
  variance?: Maybe<Pvas_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Pvas_Append_Input = {
  ocr_output?: Maybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "pvas" */
export type Pvas_Arr_Rel_Insert_Input = {
  data: Array<Pvas_Insert_Input>;
  on_conflict?: Maybe<Pvas_On_Conflict>;
};

/** columns and relationships of "pvas_audit" */
export type Pvas_Audit = {
  __typename?: 'pvas_audit';
  action?: Maybe<Scalars['String']>;
  action_tstamp_clk?: Maybe<Scalars['timestamptz']>;
  action_tstamp_stm?: Maybe<Scalars['timestamptz']>;
  action_tstamp_tx?: Maybe<Scalars['timestamptz']>;
  application_name?: Maybe<Scalars['String']>;
  changed_fields?: Maybe<Scalars['jsonb']>;
  client_addr?: Maybe<Scalars['inet']>;
  client_port?: Maybe<Scalars['Int']>;
  client_query?: Maybe<Scalars['String']>;
  event_id?: Maybe<Scalars['bigint']>;
  hasura_user?: Maybe<Scalars['jsonb']>;
  pva_id?: Maybe<Scalars['String']>;
  relid?: Maybe<Scalars['oid']>;
  row_data?: Maybe<Scalars['jsonb']>;
  schema_name?: Maybe<Scalars['String']>;
  /** An object relationship */
  session_user?: Maybe<Users>;
  session_user_name?: Maybe<Scalars['String']>;
  statement_only?: Maybe<Scalars['Boolean']>;
  table_name?: Maybe<Scalars['String']>;
  transaction_id?: Maybe<Scalars['bigint']>;
};


/** columns and relationships of "pvas_audit" */
export type Pvas_AuditChanged_FieldsArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "pvas_audit" */
export type Pvas_AuditHasura_UserArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "pvas_audit" */
export type Pvas_AuditRow_DataArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "pvas_audit" */
export type Pvas_Audit_Aggregate = {
  __typename?: 'pvas_audit_aggregate';
  aggregate?: Maybe<Pvas_Audit_Aggregate_Fields>;
  nodes: Array<Pvas_Audit>;
};

/** aggregate fields of "pvas_audit" */
export type Pvas_Audit_Aggregate_Fields = {
  __typename?: 'pvas_audit_aggregate_fields';
  avg?: Maybe<Pvas_Audit_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Pvas_Audit_Max_Fields>;
  min?: Maybe<Pvas_Audit_Min_Fields>;
  stddev?: Maybe<Pvas_Audit_Stddev_Fields>;
  stddev_pop?: Maybe<Pvas_Audit_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Pvas_Audit_Stddev_Samp_Fields>;
  sum?: Maybe<Pvas_Audit_Sum_Fields>;
  var_pop?: Maybe<Pvas_Audit_Var_Pop_Fields>;
  var_samp?: Maybe<Pvas_Audit_Var_Samp_Fields>;
  variance?: Maybe<Pvas_Audit_Variance_Fields>;
};


/** aggregate fields of "pvas_audit" */
export type Pvas_Audit_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Pvas_Audit_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "pvas_audit" */
export type Pvas_Audit_Aggregate_Order_By = {
  avg?: Maybe<Pvas_Audit_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Pvas_Audit_Max_Order_By>;
  min?: Maybe<Pvas_Audit_Min_Order_By>;
  stddev?: Maybe<Pvas_Audit_Stddev_Order_By>;
  stddev_pop?: Maybe<Pvas_Audit_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Pvas_Audit_Stddev_Samp_Order_By>;
  sum?: Maybe<Pvas_Audit_Sum_Order_By>;
  var_pop?: Maybe<Pvas_Audit_Var_Pop_Order_By>;
  var_samp?: Maybe<Pvas_Audit_Var_Samp_Order_By>;
  variance?: Maybe<Pvas_Audit_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Pvas_Audit_Append_Input = {
  changed_fields?: Maybe<Scalars['jsonb']>;
  hasura_user?: Maybe<Scalars['jsonb']>;
  row_data?: Maybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "pvas_audit" */
export type Pvas_Audit_Arr_Rel_Insert_Input = {
  data: Array<Pvas_Audit_Insert_Input>;
};

/** aggregate avg on columns */
export type Pvas_Audit_Avg_Fields = {
  __typename?: 'pvas_audit_avg_fields';
  client_port?: Maybe<Scalars['Float']>;
  event_id?: Maybe<Scalars['Float']>;
  transaction_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "pvas_audit" */
export type Pvas_Audit_Avg_Order_By = {
  client_port?: Maybe<Order_By>;
  event_id?: Maybe<Order_By>;
  transaction_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "pvas_audit". All fields are combined with a logical 'AND'. */
export type Pvas_Audit_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Pvas_Audit_Bool_Exp>>>;
  _not?: Maybe<Pvas_Audit_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Pvas_Audit_Bool_Exp>>>;
  action?: Maybe<String_Comparison_Exp>;
  action_tstamp_clk?: Maybe<Timestamptz_Comparison_Exp>;
  action_tstamp_stm?: Maybe<Timestamptz_Comparison_Exp>;
  action_tstamp_tx?: Maybe<Timestamptz_Comparison_Exp>;
  application_name?: Maybe<String_Comparison_Exp>;
  changed_fields?: Maybe<Jsonb_Comparison_Exp>;
  client_addr?: Maybe<Inet_Comparison_Exp>;
  client_port?: Maybe<Int_Comparison_Exp>;
  client_query?: Maybe<String_Comparison_Exp>;
  event_id?: Maybe<Bigint_Comparison_Exp>;
  hasura_user?: Maybe<Jsonb_Comparison_Exp>;
  pva_id?: Maybe<String_Comparison_Exp>;
  relid?: Maybe<Oid_Comparison_Exp>;
  row_data?: Maybe<Jsonb_Comparison_Exp>;
  schema_name?: Maybe<String_Comparison_Exp>;
  session_user?: Maybe<Users_Bool_Exp>;
  session_user_name?: Maybe<String_Comparison_Exp>;
  statement_only?: Maybe<Boolean_Comparison_Exp>;
  table_name?: Maybe<String_Comparison_Exp>;
  transaction_id?: Maybe<Bigint_Comparison_Exp>;
};

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Pvas_Audit_Delete_At_Path_Input = {
  changed_fields?: Maybe<Array<Maybe<Scalars['String']>>>;
  hasura_user?: Maybe<Array<Maybe<Scalars['String']>>>;
  row_data?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Pvas_Audit_Delete_Elem_Input = {
  changed_fields?: Maybe<Scalars['Int']>;
  hasura_user?: Maybe<Scalars['Int']>;
  row_data?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Pvas_Audit_Delete_Key_Input = {
  changed_fields?: Maybe<Scalars['String']>;
  hasura_user?: Maybe<Scalars['String']>;
  row_data?: Maybe<Scalars['String']>;
};

/** input type for incrementing integer column in table "pvas_audit" */
export type Pvas_Audit_Inc_Input = {
  client_port?: Maybe<Scalars['Int']>;
  event_id?: Maybe<Scalars['bigint']>;
  transaction_id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "pvas_audit" */
export type Pvas_Audit_Insert_Input = {
  action?: Maybe<Scalars['String']>;
  action_tstamp_clk?: Maybe<Scalars['timestamptz']>;
  action_tstamp_stm?: Maybe<Scalars['timestamptz']>;
  action_tstamp_tx?: Maybe<Scalars['timestamptz']>;
  application_name?: Maybe<Scalars['String']>;
  changed_fields?: Maybe<Scalars['jsonb']>;
  client_addr?: Maybe<Scalars['inet']>;
  client_port?: Maybe<Scalars['Int']>;
  client_query?: Maybe<Scalars['String']>;
  event_id?: Maybe<Scalars['bigint']>;
  hasura_user?: Maybe<Scalars['jsonb']>;
  pva_id?: Maybe<Scalars['String']>;
  relid?: Maybe<Scalars['oid']>;
  row_data?: Maybe<Scalars['jsonb']>;
  schema_name?: Maybe<Scalars['String']>;
  session_user?: Maybe<Users_Obj_Rel_Insert_Input>;
  session_user_name?: Maybe<Scalars['String']>;
  statement_only?: Maybe<Scalars['Boolean']>;
  table_name?: Maybe<Scalars['String']>;
  transaction_id?: Maybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Pvas_Audit_Max_Fields = {
  __typename?: 'pvas_audit_max_fields';
  action?: Maybe<Scalars['String']>;
  action_tstamp_clk?: Maybe<Scalars['timestamptz']>;
  action_tstamp_stm?: Maybe<Scalars['timestamptz']>;
  action_tstamp_tx?: Maybe<Scalars['timestamptz']>;
  application_name?: Maybe<Scalars['String']>;
  client_port?: Maybe<Scalars['Int']>;
  client_query?: Maybe<Scalars['String']>;
  event_id?: Maybe<Scalars['bigint']>;
  pva_id?: Maybe<Scalars['String']>;
  schema_name?: Maybe<Scalars['String']>;
  session_user_name?: Maybe<Scalars['String']>;
  table_name?: Maybe<Scalars['String']>;
  transaction_id?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "pvas_audit" */
export type Pvas_Audit_Max_Order_By = {
  action?: Maybe<Order_By>;
  action_tstamp_clk?: Maybe<Order_By>;
  action_tstamp_stm?: Maybe<Order_By>;
  action_tstamp_tx?: Maybe<Order_By>;
  application_name?: Maybe<Order_By>;
  client_port?: Maybe<Order_By>;
  client_query?: Maybe<Order_By>;
  event_id?: Maybe<Order_By>;
  pva_id?: Maybe<Order_By>;
  schema_name?: Maybe<Order_By>;
  session_user_name?: Maybe<Order_By>;
  table_name?: Maybe<Order_By>;
  transaction_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Pvas_Audit_Min_Fields = {
  __typename?: 'pvas_audit_min_fields';
  action?: Maybe<Scalars['String']>;
  action_tstamp_clk?: Maybe<Scalars['timestamptz']>;
  action_tstamp_stm?: Maybe<Scalars['timestamptz']>;
  action_tstamp_tx?: Maybe<Scalars['timestamptz']>;
  application_name?: Maybe<Scalars['String']>;
  client_port?: Maybe<Scalars['Int']>;
  client_query?: Maybe<Scalars['String']>;
  event_id?: Maybe<Scalars['bigint']>;
  pva_id?: Maybe<Scalars['String']>;
  schema_name?: Maybe<Scalars['String']>;
  session_user_name?: Maybe<Scalars['String']>;
  table_name?: Maybe<Scalars['String']>;
  transaction_id?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "pvas_audit" */
export type Pvas_Audit_Min_Order_By = {
  action?: Maybe<Order_By>;
  action_tstamp_clk?: Maybe<Order_By>;
  action_tstamp_stm?: Maybe<Order_By>;
  action_tstamp_tx?: Maybe<Order_By>;
  application_name?: Maybe<Order_By>;
  client_port?: Maybe<Order_By>;
  client_query?: Maybe<Order_By>;
  event_id?: Maybe<Order_By>;
  pva_id?: Maybe<Order_By>;
  schema_name?: Maybe<Order_By>;
  session_user_name?: Maybe<Order_By>;
  table_name?: Maybe<Order_By>;
  transaction_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "pvas_audit" */
export type Pvas_Audit_Mutation_Response = {
  __typename?: 'pvas_audit_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Pvas_Audit>;
};

/** input type for inserting object relation for remote table "pvas_audit" */
export type Pvas_Audit_Obj_Rel_Insert_Input = {
  data: Pvas_Audit_Insert_Input;
};

/** ordering options when selecting data from "pvas_audit" */
export type Pvas_Audit_Order_By = {
  action?: Maybe<Order_By>;
  action_tstamp_clk?: Maybe<Order_By>;
  action_tstamp_stm?: Maybe<Order_By>;
  action_tstamp_tx?: Maybe<Order_By>;
  application_name?: Maybe<Order_By>;
  changed_fields?: Maybe<Order_By>;
  client_addr?: Maybe<Order_By>;
  client_port?: Maybe<Order_By>;
  client_query?: Maybe<Order_By>;
  event_id?: Maybe<Order_By>;
  hasura_user?: Maybe<Order_By>;
  pva_id?: Maybe<Order_By>;
  relid?: Maybe<Order_By>;
  row_data?: Maybe<Order_By>;
  schema_name?: Maybe<Order_By>;
  session_user?: Maybe<Users_Order_By>;
  session_user_name?: Maybe<Order_By>;
  statement_only?: Maybe<Order_By>;
  table_name?: Maybe<Order_By>;
  transaction_id?: Maybe<Order_By>;
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Pvas_Audit_Prepend_Input = {
  changed_fields?: Maybe<Scalars['jsonb']>;
  hasura_user?: Maybe<Scalars['jsonb']>;
  row_data?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "pvas_audit" */
export enum Pvas_Audit_Select_Column {
  /** column name */
  Action = 'action',
  /** column name */
  ActionTstampClk = 'action_tstamp_clk',
  /** column name */
  ActionTstampStm = 'action_tstamp_stm',
  /** column name */
  ActionTstampTx = 'action_tstamp_tx',
  /** column name */
  ApplicationName = 'application_name',
  /** column name */
  ChangedFields = 'changed_fields',
  /** column name */
  ClientAddr = 'client_addr',
  /** column name */
  ClientPort = 'client_port',
  /** column name */
  ClientQuery = 'client_query',
  /** column name */
  EventId = 'event_id',
  /** column name */
  HasuraUser = 'hasura_user',
  /** column name */
  PvaId = 'pva_id',
  /** column name */
  Relid = 'relid',
  /** column name */
  RowData = 'row_data',
  /** column name */
  SchemaName = 'schema_name',
  /** column name */
  SessionUserName = 'session_user_name',
  /** column name */
  StatementOnly = 'statement_only',
  /** column name */
  TableName = 'table_name',
  /** column name */
  TransactionId = 'transaction_id'
}

/** input type for updating data in table "pvas_audit" */
export type Pvas_Audit_Set_Input = {
  action?: Maybe<Scalars['String']>;
  action_tstamp_clk?: Maybe<Scalars['timestamptz']>;
  action_tstamp_stm?: Maybe<Scalars['timestamptz']>;
  action_tstamp_tx?: Maybe<Scalars['timestamptz']>;
  application_name?: Maybe<Scalars['String']>;
  changed_fields?: Maybe<Scalars['jsonb']>;
  client_addr?: Maybe<Scalars['inet']>;
  client_port?: Maybe<Scalars['Int']>;
  client_query?: Maybe<Scalars['String']>;
  event_id?: Maybe<Scalars['bigint']>;
  hasura_user?: Maybe<Scalars['jsonb']>;
  pva_id?: Maybe<Scalars['String']>;
  relid?: Maybe<Scalars['oid']>;
  row_data?: Maybe<Scalars['jsonb']>;
  schema_name?: Maybe<Scalars['String']>;
  session_user_name?: Maybe<Scalars['String']>;
  statement_only?: Maybe<Scalars['Boolean']>;
  table_name?: Maybe<Scalars['String']>;
  transaction_id?: Maybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Pvas_Audit_Stddev_Fields = {
  __typename?: 'pvas_audit_stddev_fields';
  client_port?: Maybe<Scalars['Float']>;
  event_id?: Maybe<Scalars['Float']>;
  transaction_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "pvas_audit" */
export type Pvas_Audit_Stddev_Order_By = {
  client_port?: Maybe<Order_By>;
  event_id?: Maybe<Order_By>;
  transaction_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Pvas_Audit_Stddev_Pop_Fields = {
  __typename?: 'pvas_audit_stddev_pop_fields';
  client_port?: Maybe<Scalars['Float']>;
  event_id?: Maybe<Scalars['Float']>;
  transaction_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "pvas_audit" */
export type Pvas_Audit_Stddev_Pop_Order_By = {
  client_port?: Maybe<Order_By>;
  event_id?: Maybe<Order_By>;
  transaction_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Pvas_Audit_Stddev_Samp_Fields = {
  __typename?: 'pvas_audit_stddev_samp_fields';
  client_port?: Maybe<Scalars['Float']>;
  event_id?: Maybe<Scalars['Float']>;
  transaction_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "pvas_audit" */
export type Pvas_Audit_Stddev_Samp_Order_By = {
  client_port?: Maybe<Order_By>;
  event_id?: Maybe<Order_By>;
  transaction_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Pvas_Audit_Sum_Fields = {
  __typename?: 'pvas_audit_sum_fields';
  client_port?: Maybe<Scalars['Int']>;
  event_id?: Maybe<Scalars['bigint']>;
  transaction_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "pvas_audit" */
export type Pvas_Audit_Sum_Order_By = {
  client_port?: Maybe<Order_By>;
  event_id?: Maybe<Order_By>;
  transaction_id?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Pvas_Audit_Var_Pop_Fields = {
  __typename?: 'pvas_audit_var_pop_fields';
  client_port?: Maybe<Scalars['Float']>;
  event_id?: Maybe<Scalars['Float']>;
  transaction_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "pvas_audit" */
export type Pvas_Audit_Var_Pop_Order_By = {
  client_port?: Maybe<Order_By>;
  event_id?: Maybe<Order_By>;
  transaction_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Pvas_Audit_Var_Samp_Fields = {
  __typename?: 'pvas_audit_var_samp_fields';
  client_port?: Maybe<Scalars['Float']>;
  event_id?: Maybe<Scalars['Float']>;
  transaction_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "pvas_audit" */
export type Pvas_Audit_Var_Samp_Order_By = {
  client_port?: Maybe<Order_By>;
  event_id?: Maybe<Order_By>;
  transaction_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Pvas_Audit_Variance_Fields = {
  __typename?: 'pvas_audit_variance_fields';
  client_port?: Maybe<Scalars['Float']>;
  event_id?: Maybe<Scalars['Float']>;
  transaction_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "pvas_audit" */
export type Pvas_Audit_Variance_Order_By = {
  client_port?: Maybe<Order_By>;
  event_id?: Maybe<Order_By>;
  transaction_id?: Maybe<Order_By>;
};

/** aggregate avg on columns */
export type Pvas_Avg_Fields = {
  __typename?: 'pvas_avg_fields';
  network_number?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "pvas" */
export type Pvas_Avg_Order_By = {
  network_number?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "pvas". All fields are combined with a logical 'AND'. */
export type Pvas_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Pvas_Bool_Exp>>>;
  _not?: Maybe<Pvas_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Pvas_Bool_Exp>>>;
  alignment?: Maybe<Alignments_Bool_Exp>;
  alignment_id?: Maybe<String_Comparison_Exp>;
  beneficiary_first_name?: Maybe<String_Comparison_Exp>;
  beneficiary_last_name?: Maybe<String_Comparison_Exp>;
  beneficiary_name?: Maybe<String_Comparison_Exp>;
  birth_date?: Maybe<Date_Comparison_Exp>;
  birth_date_raw?: Maybe<String_Comparison_Exp>;
  channel?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  is_archived?: Maybe<Boolean_Comparison_Exp>;
  is_signed?: Maybe<Boolean_Comparison_Exp>;
  language?: Maybe<String_Comparison_Exp>;
  letter?: Maybe<Letters_Bool_Exp>;
  letter_id?: Maybe<String_Comparison_Exp>;
  letter_id_prefix?: Maybe<String_Comparison_Exp>;
  matched_patient?: Maybe<Patients_Bool_Exp>;
  matched_patient_id?: Maybe<String_Comparison_Exp>;
  mbi?: Maybe<String_Comparison_Exp>;
  network?: Maybe<Networks_Bool_Exp>;
  network_number?: Maybe<Int_Comparison_Exp>;
  ocr_output?: Maybe<Jsonb_Comparison_Exp>;
  onedrive_file_id?: Maybe<String_Comparison_Exp>;
  patients?: Maybe<Patients_Bool_Exp>;
  processing_status?: Maybe<String_Comparison_Exp>;
  provider_name_or_med_group?: Maybe<String_Comparison_Exp>;
  pva_id?: Maybe<String_Comparison_Exp>;
  pva_npi?: Maybe<String_Comparison_Exp>;
  pva_practice_id?: Maybe<String_Comparison_Exp>;
  pva_practice_name?: Maybe<String_Comparison_Exp>;
  pva_tin?: Maybe<String_Comparison_Exp>;
  short_url?: Maybe<String_Comparison_Exp>;
  signature_date?: Maybe<Date_Comparison_Exp>;
  signature_date_raw?: Maybe<String_Comparison_Exp>;
  signature_raw?: Maybe<String_Comparison_Exp>;
  signature_url?: Maybe<String_Comparison_Exp>;
  signer_printed_name?: Maybe<String_Comparison_Exp>;
  submission_id?: Maybe<String_Comparison_Exp>;
  submission_source?: Maybe<String_Comparison_Exp>;
  template_version?: Maybe<String_Comparison_Exp>;
  transcriber_notes?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  ycode?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "pvas" */
export enum Pvas_Constraint {
  /** unique or primary key constraint */
  PvasPkey = 'pvas_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Pvas_Delete_At_Path_Input = {
  ocr_output?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Pvas_Delete_Elem_Input = {
  ocr_output?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Pvas_Delete_Key_Input = {
  ocr_output?: Maybe<Scalars['String']>;
};

/** input type for incrementing integer column in table "pvas" */
export type Pvas_Inc_Input = {
  network_number?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "pvas" */
export type Pvas_Insert_Input = {
  alignment?: Maybe<Alignments_Obj_Rel_Insert_Input>;
  alignment_id?: Maybe<Scalars['String']>;
  beneficiary_first_name?: Maybe<Scalars['String']>;
  beneficiary_last_name?: Maybe<Scalars['String']>;
  beneficiary_name?: Maybe<Scalars['String']>;
  birth_date?: Maybe<Scalars['date']>;
  birth_date_raw?: Maybe<Scalars['String']>;
  channel?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  is_archived?: Maybe<Scalars['Boolean']>;
  is_signed?: Maybe<Scalars['Boolean']>;
  language?: Maybe<Scalars['String']>;
  letter?: Maybe<Letters_Obj_Rel_Insert_Input>;
  letter_id?: Maybe<Scalars['String']>;
  letter_id_prefix?: Maybe<Scalars['String']>;
  matched_patient?: Maybe<Patients_Obj_Rel_Insert_Input>;
  matched_patient_id?: Maybe<Scalars['String']>;
  mbi?: Maybe<Scalars['String']>;
  network?: Maybe<Networks_Obj_Rel_Insert_Input>;
  network_number?: Maybe<Scalars['Int']>;
  ocr_output?: Maybe<Scalars['jsonb']>;
  onedrive_file_id?: Maybe<Scalars['String']>;
  patients?: Maybe<Patients_Arr_Rel_Insert_Input>;
  processing_status?: Maybe<Scalars['String']>;
  provider_name_or_med_group?: Maybe<Scalars['String']>;
  pva_id?: Maybe<Scalars['String']>;
  pva_npi?: Maybe<Scalars['String']>;
  pva_practice_id?: Maybe<Scalars['String']>;
  pva_practice_name?: Maybe<Scalars['String']>;
  pva_tin?: Maybe<Scalars['String']>;
  short_url?: Maybe<Scalars['String']>;
  signature_date?: Maybe<Scalars['date']>;
  signature_date_raw?: Maybe<Scalars['String']>;
  signature_raw?: Maybe<Scalars['String']>;
  signature_url?: Maybe<Scalars['String']>;
  signer_printed_name?: Maybe<Scalars['String']>;
  submission_id?: Maybe<Scalars['String']>;
  submission_source?: Maybe<Scalars['String']>;
  template_version?: Maybe<Scalars['String']>;
  transcriber_notes?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  ycode?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Pvas_Max_Fields = {
  __typename?: 'pvas_max_fields';
  alignment_id?: Maybe<Scalars['String']>;
  beneficiary_first_name?: Maybe<Scalars['String']>;
  beneficiary_last_name?: Maybe<Scalars['String']>;
  beneficiary_name?: Maybe<Scalars['String']>;
  birth_date?: Maybe<Scalars['date']>;
  birth_date_raw?: Maybe<Scalars['String']>;
  channel?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  language?: Maybe<Scalars['String']>;
  letter_id?: Maybe<Scalars['String']>;
  letter_id_prefix?: Maybe<Scalars['String']>;
  matched_patient_id?: Maybe<Scalars['String']>;
  mbi?: Maybe<Scalars['String']>;
  network_number?: Maybe<Scalars['Int']>;
  onedrive_file_id?: Maybe<Scalars['String']>;
  processing_status?: Maybe<Scalars['String']>;
  provider_name_or_med_group?: Maybe<Scalars['String']>;
  pva_id?: Maybe<Scalars['String']>;
  pva_npi?: Maybe<Scalars['String']>;
  pva_practice_id?: Maybe<Scalars['String']>;
  pva_practice_name?: Maybe<Scalars['String']>;
  pva_tin?: Maybe<Scalars['String']>;
  short_url?: Maybe<Scalars['String']>;
  signature_date?: Maybe<Scalars['date']>;
  signature_date_raw?: Maybe<Scalars['String']>;
  signature_raw?: Maybe<Scalars['String']>;
  signature_url?: Maybe<Scalars['String']>;
  signer_printed_name?: Maybe<Scalars['String']>;
  submission_id?: Maybe<Scalars['String']>;
  submission_source?: Maybe<Scalars['String']>;
  template_version?: Maybe<Scalars['String']>;
  transcriber_notes?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  ycode?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "pvas" */
export type Pvas_Max_Order_By = {
  alignment_id?: Maybe<Order_By>;
  beneficiary_first_name?: Maybe<Order_By>;
  beneficiary_last_name?: Maybe<Order_By>;
  beneficiary_name?: Maybe<Order_By>;
  birth_date?: Maybe<Order_By>;
  birth_date_raw?: Maybe<Order_By>;
  channel?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  language?: Maybe<Order_By>;
  letter_id?: Maybe<Order_By>;
  letter_id_prefix?: Maybe<Order_By>;
  matched_patient_id?: Maybe<Order_By>;
  mbi?: Maybe<Order_By>;
  network_number?: Maybe<Order_By>;
  onedrive_file_id?: Maybe<Order_By>;
  processing_status?: Maybe<Order_By>;
  provider_name_or_med_group?: Maybe<Order_By>;
  pva_id?: Maybe<Order_By>;
  pva_npi?: Maybe<Order_By>;
  pva_practice_id?: Maybe<Order_By>;
  pva_practice_name?: Maybe<Order_By>;
  pva_tin?: Maybe<Order_By>;
  short_url?: Maybe<Order_By>;
  signature_date?: Maybe<Order_By>;
  signature_date_raw?: Maybe<Order_By>;
  signature_raw?: Maybe<Order_By>;
  signature_url?: Maybe<Order_By>;
  signer_printed_name?: Maybe<Order_By>;
  submission_id?: Maybe<Order_By>;
  submission_source?: Maybe<Order_By>;
  template_version?: Maybe<Order_By>;
  transcriber_notes?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  ycode?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Pvas_Min_Fields = {
  __typename?: 'pvas_min_fields';
  alignment_id?: Maybe<Scalars['String']>;
  beneficiary_first_name?: Maybe<Scalars['String']>;
  beneficiary_last_name?: Maybe<Scalars['String']>;
  beneficiary_name?: Maybe<Scalars['String']>;
  birth_date?: Maybe<Scalars['date']>;
  birth_date_raw?: Maybe<Scalars['String']>;
  channel?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  language?: Maybe<Scalars['String']>;
  letter_id?: Maybe<Scalars['String']>;
  letter_id_prefix?: Maybe<Scalars['String']>;
  matched_patient_id?: Maybe<Scalars['String']>;
  mbi?: Maybe<Scalars['String']>;
  network_number?: Maybe<Scalars['Int']>;
  onedrive_file_id?: Maybe<Scalars['String']>;
  processing_status?: Maybe<Scalars['String']>;
  provider_name_or_med_group?: Maybe<Scalars['String']>;
  pva_id?: Maybe<Scalars['String']>;
  pva_npi?: Maybe<Scalars['String']>;
  pva_practice_id?: Maybe<Scalars['String']>;
  pva_practice_name?: Maybe<Scalars['String']>;
  pva_tin?: Maybe<Scalars['String']>;
  short_url?: Maybe<Scalars['String']>;
  signature_date?: Maybe<Scalars['date']>;
  signature_date_raw?: Maybe<Scalars['String']>;
  signature_raw?: Maybe<Scalars['String']>;
  signature_url?: Maybe<Scalars['String']>;
  signer_printed_name?: Maybe<Scalars['String']>;
  submission_id?: Maybe<Scalars['String']>;
  submission_source?: Maybe<Scalars['String']>;
  template_version?: Maybe<Scalars['String']>;
  transcriber_notes?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  ycode?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "pvas" */
export type Pvas_Min_Order_By = {
  alignment_id?: Maybe<Order_By>;
  beneficiary_first_name?: Maybe<Order_By>;
  beneficiary_last_name?: Maybe<Order_By>;
  beneficiary_name?: Maybe<Order_By>;
  birth_date?: Maybe<Order_By>;
  birth_date_raw?: Maybe<Order_By>;
  channel?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  language?: Maybe<Order_By>;
  letter_id?: Maybe<Order_By>;
  letter_id_prefix?: Maybe<Order_By>;
  matched_patient_id?: Maybe<Order_By>;
  mbi?: Maybe<Order_By>;
  network_number?: Maybe<Order_By>;
  onedrive_file_id?: Maybe<Order_By>;
  processing_status?: Maybe<Order_By>;
  provider_name_or_med_group?: Maybe<Order_By>;
  pva_id?: Maybe<Order_By>;
  pva_npi?: Maybe<Order_By>;
  pva_practice_id?: Maybe<Order_By>;
  pva_practice_name?: Maybe<Order_By>;
  pva_tin?: Maybe<Order_By>;
  short_url?: Maybe<Order_By>;
  signature_date?: Maybe<Order_By>;
  signature_date_raw?: Maybe<Order_By>;
  signature_raw?: Maybe<Order_By>;
  signature_url?: Maybe<Order_By>;
  signer_printed_name?: Maybe<Order_By>;
  submission_id?: Maybe<Order_By>;
  submission_source?: Maybe<Order_By>;
  template_version?: Maybe<Order_By>;
  transcriber_notes?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  ycode?: Maybe<Order_By>;
};

/** response of any mutation on the table "pvas" */
export type Pvas_Mutation_Response = {
  __typename?: 'pvas_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Pvas>;
};

/** input type for inserting object relation for remote table "pvas" */
export type Pvas_Obj_Rel_Insert_Input = {
  data: Pvas_Insert_Input;
  on_conflict?: Maybe<Pvas_On_Conflict>;
};

/** on conflict condition type for table "pvas" */
export type Pvas_On_Conflict = {
  constraint: Pvas_Constraint;
  update_columns: Array<Pvas_Update_Column>;
  where?: Maybe<Pvas_Bool_Exp>;
};

/** ordering options when selecting data from "pvas" */
export type Pvas_Order_By = {
  alignment?: Maybe<Alignments_Order_By>;
  alignment_id?: Maybe<Order_By>;
  beneficiary_first_name?: Maybe<Order_By>;
  beneficiary_last_name?: Maybe<Order_By>;
  beneficiary_name?: Maybe<Order_By>;
  birth_date?: Maybe<Order_By>;
  birth_date_raw?: Maybe<Order_By>;
  channel?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  is_archived?: Maybe<Order_By>;
  is_signed?: Maybe<Order_By>;
  language?: Maybe<Order_By>;
  letter?: Maybe<Letters_Order_By>;
  letter_id?: Maybe<Order_By>;
  letter_id_prefix?: Maybe<Order_By>;
  matched_patient?: Maybe<Patients_Order_By>;
  matched_patient_id?: Maybe<Order_By>;
  mbi?: Maybe<Order_By>;
  network?: Maybe<Networks_Order_By>;
  network_number?: Maybe<Order_By>;
  ocr_output?: Maybe<Order_By>;
  onedrive_file_id?: Maybe<Order_By>;
  patients_aggregate?: Maybe<Patients_Aggregate_Order_By>;
  processing_status?: Maybe<Order_By>;
  provider_name_or_med_group?: Maybe<Order_By>;
  pva_id?: Maybe<Order_By>;
  pva_npi?: Maybe<Order_By>;
  pva_practice_id?: Maybe<Order_By>;
  pva_practice_name?: Maybe<Order_By>;
  pva_tin?: Maybe<Order_By>;
  short_url?: Maybe<Order_By>;
  signature_date?: Maybe<Order_By>;
  signature_date_raw?: Maybe<Order_By>;
  signature_raw?: Maybe<Order_By>;
  signature_url?: Maybe<Order_By>;
  signer_printed_name?: Maybe<Order_By>;
  submission_id?: Maybe<Order_By>;
  submission_source?: Maybe<Order_By>;
  template_version?: Maybe<Order_By>;
  transcriber_notes?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  ycode?: Maybe<Order_By>;
};

/** primary key columns input for table: "pvas" */
export type Pvas_Pk_Columns_Input = {
  pva_id: Scalars['String'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Pvas_Prepend_Input = {
  ocr_output?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "pvas" */
export enum Pvas_Select_Column {
  /** column name */
  AlignmentId = 'alignment_id',
  /** column name */
  BeneficiaryFirstName = 'beneficiary_first_name',
  /** column name */
  BeneficiaryLastName = 'beneficiary_last_name',
  /** column name */
  BeneficiaryName = 'beneficiary_name',
  /** column name */
  BirthDate = 'birth_date',
  /** column name */
  BirthDateRaw = 'birth_date_raw',
  /** column name */
  Channel = 'channel',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  IsArchived = 'is_archived',
  /** column name */
  IsSigned = 'is_signed',
  /** column name */
  Language = 'language',
  /** column name */
  LetterId = 'letter_id',
  /** column name */
  LetterIdPrefix = 'letter_id_prefix',
  /** column name */
  MatchedPatientId = 'matched_patient_id',
  /** column name */
  Mbi = 'mbi',
  /** column name */
  NetworkNumber = 'network_number',
  /** column name */
  OcrOutput = 'ocr_output',
  /** column name */
  OnedriveFileId = 'onedrive_file_id',
  /** column name */
  ProcessingStatus = 'processing_status',
  /** column name */
  ProviderNameOrMedGroup = 'provider_name_or_med_group',
  /** column name */
  PvaId = 'pva_id',
  /** column name */
  PvaNpi = 'pva_npi',
  /** column name */
  PvaPracticeId = 'pva_practice_id',
  /** column name */
  PvaPracticeName = 'pva_practice_name',
  /** column name */
  PvaTin = 'pva_tin',
  /** column name */
  ShortUrl = 'short_url',
  /** column name */
  SignatureDate = 'signature_date',
  /** column name */
  SignatureDateRaw = 'signature_date_raw',
  /** column name */
  SignatureRaw = 'signature_raw',
  /** column name */
  SignatureUrl = 'signature_url',
  /** column name */
  SignerPrintedName = 'signer_printed_name',
  /** column name */
  SubmissionId = 'submission_id',
  /** column name */
  SubmissionSource = 'submission_source',
  /** column name */
  TemplateVersion = 'template_version',
  /** column name */
  TranscriberNotes = 'transcriber_notes',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Ycode = 'ycode'
}

/** input type for updating data in table "pvas" */
export type Pvas_Set_Input = {
  alignment_id?: Maybe<Scalars['String']>;
  beneficiary_first_name?: Maybe<Scalars['String']>;
  beneficiary_last_name?: Maybe<Scalars['String']>;
  beneficiary_name?: Maybe<Scalars['String']>;
  birth_date?: Maybe<Scalars['date']>;
  birth_date_raw?: Maybe<Scalars['String']>;
  channel?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  is_archived?: Maybe<Scalars['Boolean']>;
  is_signed?: Maybe<Scalars['Boolean']>;
  language?: Maybe<Scalars['String']>;
  letter_id?: Maybe<Scalars['String']>;
  letter_id_prefix?: Maybe<Scalars['String']>;
  matched_patient_id?: Maybe<Scalars['String']>;
  mbi?: Maybe<Scalars['String']>;
  network_number?: Maybe<Scalars['Int']>;
  ocr_output?: Maybe<Scalars['jsonb']>;
  onedrive_file_id?: Maybe<Scalars['String']>;
  processing_status?: Maybe<Scalars['String']>;
  provider_name_or_med_group?: Maybe<Scalars['String']>;
  pva_id?: Maybe<Scalars['String']>;
  pva_npi?: Maybe<Scalars['String']>;
  pva_practice_id?: Maybe<Scalars['String']>;
  pva_practice_name?: Maybe<Scalars['String']>;
  pva_tin?: Maybe<Scalars['String']>;
  short_url?: Maybe<Scalars['String']>;
  signature_date?: Maybe<Scalars['date']>;
  signature_date_raw?: Maybe<Scalars['String']>;
  signature_raw?: Maybe<Scalars['String']>;
  signature_url?: Maybe<Scalars['String']>;
  signer_printed_name?: Maybe<Scalars['String']>;
  submission_id?: Maybe<Scalars['String']>;
  submission_source?: Maybe<Scalars['String']>;
  template_version?: Maybe<Scalars['String']>;
  transcriber_notes?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  ycode?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Pvas_Stddev_Fields = {
  __typename?: 'pvas_stddev_fields';
  network_number?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "pvas" */
export type Pvas_Stddev_Order_By = {
  network_number?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Pvas_Stddev_Pop_Fields = {
  __typename?: 'pvas_stddev_pop_fields';
  network_number?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "pvas" */
export type Pvas_Stddev_Pop_Order_By = {
  network_number?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Pvas_Stddev_Samp_Fields = {
  __typename?: 'pvas_stddev_samp_fields';
  network_number?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "pvas" */
export type Pvas_Stddev_Samp_Order_By = {
  network_number?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Pvas_Sum_Fields = {
  __typename?: 'pvas_sum_fields';
  network_number?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "pvas" */
export type Pvas_Sum_Order_By = {
  network_number?: Maybe<Order_By>;
};

/** update columns of table "pvas" */
export enum Pvas_Update_Column {
  /** column name */
  AlignmentId = 'alignment_id',
  /** column name */
  BeneficiaryFirstName = 'beneficiary_first_name',
  /** column name */
  BeneficiaryLastName = 'beneficiary_last_name',
  /** column name */
  BeneficiaryName = 'beneficiary_name',
  /** column name */
  BirthDate = 'birth_date',
  /** column name */
  BirthDateRaw = 'birth_date_raw',
  /** column name */
  Channel = 'channel',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  IsArchived = 'is_archived',
  /** column name */
  IsSigned = 'is_signed',
  /** column name */
  Language = 'language',
  /** column name */
  LetterId = 'letter_id',
  /** column name */
  LetterIdPrefix = 'letter_id_prefix',
  /** column name */
  MatchedPatientId = 'matched_patient_id',
  /** column name */
  Mbi = 'mbi',
  /** column name */
  NetworkNumber = 'network_number',
  /** column name */
  OcrOutput = 'ocr_output',
  /** column name */
  OnedriveFileId = 'onedrive_file_id',
  /** column name */
  ProcessingStatus = 'processing_status',
  /** column name */
  ProviderNameOrMedGroup = 'provider_name_or_med_group',
  /** column name */
  PvaId = 'pva_id',
  /** column name */
  PvaNpi = 'pva_npi',
  /** column name */
  PvaPracticeId = 'pva_practice_id',
  /** column name */
  PvaPracticeName = 'pva_practice_name',
  /** column name */
  PvaTin = 'pva_tin',
  /** column name */
  ShortUrl = 'short_url',
  /** column name */
  SignatureDate = 'signature_date',
  /** column name */
  SignatureDateRaw = 'signature_date_raw',
  /** column name */
  SignatureRaw = 'signature_raw',
  /** column name */
  SignatureUrl = 'signature_url',
  /** column name */
  SignerPrintedName = 'signer_printed_name',
  /** column name */
  SubmissionId = 'submission_id',
  /** column name */
  SubmissionSource = 'submission_source',
  /** column name */
  TemplateVersion = 'template_version',
  /** column name */
  TranscriberNotes = 'transcriber_notes',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Ycode = 'ycode'
}

/** aggregate var_pop on columns */
export type Pvas_Var_Pop_Fields = {
  __typename?: 'pvas_var_pop_fields';
  network_number?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "pvas" */
export type Pvas_Var_Pop_Order_By = {
  network_number?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Pvas_Var_Samp_Fields = {
  __typename?: 'pvas_var_samp_fields';
  network_number?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "pvas" */
export type Pvas_Var_Samp_Order_By = {
  network_number?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Pvas_Variance_Fields = {
  __typename?: 'pvas_variance_fields';
  network_number?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "pvas" */
export type Pvas_Variance_Order_By = {
  network_number?: Maybe<Order_By>;
};

/** query root */
export type Query_Root = {
  __typename?: 'query_root';
  /** fetch data from the table: "account_creation_requests" */
  account_creation_requests: Array<Account_Creation_Requests>;
  /** fetch aggregated fields from the table: "account_creation_requests" */
  account_creation_requests_aggregate: Account_Creation_Requests_Aggregate;
  /** fetch data from the table: "account_creation_requests" using primary key columns */
  account_creation_requests_by_pk?: Maybe<Account_Creation_Requests>;
  /** fetch data from the table: "alignments" */
  alignments: Array<Alignments>;
  /** fetch aggregated fields from the table: "alignments" */
  alignments_aggregate: Alignments_Aggregate;
  /** fetch data from the table: "alignments" using primary key columns */
  alignments_by_pk?: Maybe<Alignments>;
  /** fetch data from the table: "analytics.analytics_cms_active_dce_participants" */
  analytics_analytics_cms_active_dce_participants: Array<Analytics_Analytics_Cms_Active_Dce_Participants>;
  /** fetch aggregated fields from the table: "analytics.analytics_cms_active_dce_participants" */
  analytics_analytics_cms_active_dce_participants_aggregate: Analytics_Analytics_Cms_Active_Dce_Participants_Aggregate;
  /** fetch data from the table: "business_associate_agreements" */
  business_associate_agreements: Array<Business_Associate_Agreements>;
  /** fetch aggregated fields from the table: "business_associate_agreements" */
  business_associate_agreements_aggregate: Business_Associate_Agreements_Aggregate;
  /** fetch data from the table: "business_associate_agreements" using primary key columns */
  business_associate_agreements_by_pk?: Maybe<Business_Associate_Agreements>;
  /** fetch data from the table: "campaign_approvals" */
  campaign_approvals: Array<Campaign_Approvals>;
  /** fetch aggregated fields from the table: "campaign_approvals" */
  campaign_approvals_aggregate: Campaign_Approvals_Aggregate;
  /** fetch data from the table: "campaign_approvals" using primary key columns */
  campaign_approvals_by_pk?: Maybe<Campaign_Approvals>;
  /** fetch data from the table: "campaign_email_components" */
  campaign_email_components: Array<Campaign_Email_Components>;
  /** fetch aggregated fields from the table: "campaign_email_components" */
  campaign_email_components_aggregate: Campaign_Email_Components_Aggregate;
  /** fetch data from the table: "campaign_email_components" using primary key columns */
  campaign_email_components_by_pk?: Maybe<Campaign_Email_Components>;
  /** fetch data from the table: "campaign_letter_components" */
  campaign_letter_components: Array<Campaign_Letter_Components>;
  /** fetch aggregated fields from the table: "campaign_letter_components" */
  campaign_letter_components_aggregate: Campaign_Letter_Components_Aggregate;
  /** fetch data from the table: "campaign_letter_components" using primary key columns */
  campaign_letter_components_by_pk?: Maybe<Campaign_Letter_Components>;
  /** fetch data from the table: "campaign_sms_components" */
  campaign_sms_components: Array<Campaign_Sms_Components>;
  /** fetch aggregated fields from the table: "campaign_sms_components" */
  campaign_sms_components_aggregate: Campaign_Sms_Components_Aggregate;
  /** fetch data from the table: "campaign_sms_components" using primary key columns */
  campaign_sms_components_by_pk?: Maybe<Campaign_Sms_Components>;
  /** fetch data from the table: "campaign_templates" */
  campaign_templates: Array<Campaign_Templates>;
  /** fetch aggregated fields from the table: "campaign_templates" */
  campaign_templates_aggregate: Campaign_Templates_Aggregate;
  /** fetch data from the table: "campaign_templates" using primary key columns */
  campaign_templates_by_pk?: Maybe<Campaign_Templates>;
  /** fetch data from the table: "client_call_records" */
  client_call_records: Array<Client_Call_Records>;
  /** fetch aggregated fields from the table: "client_call_records" */
  client_call_records_aggregate: Client_Call_Records_Aggregate;
  /** fetch data from the table: "client_call_records" using primary key columns */
  client_call_records_by_pk?: Maybe<Client_Call_Records>;
  /** fetch data from the table: "cms_alignment_reports" */
  cms_alignment_reports: Array<Cms_Alignment_Reports>;
  /** fetch aggregated fields from the table: "cms_alignment_reports" */
  cms_alignment_reports_aggregate: Cms_Alignment_Reports_Aggregate;
  /** fetch data from the table: "cms_alignment_reports" using primary key columns */
  cms_alignment_reports_by_pk?: Maybe<Cms_Alignment_Reports>;
  /** fetch data from the table: "cms_dce_participants" */
  cms_dce_participants: Array<Cms_Dce_Participants>;
  /** fetch aggregated fields from the table: "cms_dce_participants" */
  cms_dce_participants_aggregate: Cms_Dce_Participants_Aggregate;
  /** fetch data from the table: "cms_provider_alignment_reports" */
  cms_provider_alignment_reports: Array<Cms_Provider_Alignment_Reports>;
  /** fetch aggregated fields from the table: "cms_provider_alignment_reports" */
  cms_provider_alignment_reports_aggregate: Cms_Provider_Alignment_Reports_Aggregate;
  /** fetch data from the table: "cms_provider_alignment_reports" using primary key columns */
  cms_provider_alignment_reports_by_pk?: Maybe<Cms_Provider_Alignment_Reports>;
  /** fetch data from the table: "cms_pva_responses" */
  cms_pva_responses: Array<Cms_Pva_Responses>;
  /** fetch aggregated fields from the table: "cms_pva_responses" */
  cms_pva_responses_aggregate: Cms_Pva_Responses_Aggregate;
  /** fetch data from the table: "cms_pva_responses" using primary key columns */
  cms_pva_responses_by_pk?: Maybe<Cms_Pva_Responses>;
  /** fetch data from the table: "component_tracking_ids" */
  component_tracking_ids: Array<Component_Tracking_Ids>;
  /** fetch aggregated fields from the table: "component_tracking_ids" */
  component_tracking_ids_aggregate: Component_Tracking_Ids_Aggregate;
  /** fetch data from the table: "component_tracking_ids" using primary key columns */
  component_tracking_ids_by_pk?: Maybe<Component_Tracking_Ids>;
  /** fetch data from the table: "daily_performance_stats" */
  daily_performance_stats: Array<Daily_Performance_Stats>;
  /** fetch aggregated fields from the table: "daily_performance_stats" */
  daily_performance_stats_aggregate: Daily_Performance_Stats_Aggregate;
  /** fetch data from the table: "daily_performance_stats" using primary key columns */
  daily_performance_stats_by_pk?: Maybe<Daily_Performance_Stats>;
  /** fetch data from the table: "email_component_approvals" */
  email_component_approvals: Array<Email_Component_Approvals>;
  /** fetch aggregated fields from the table: "email_component_approvals" */
  email_component_approvals_aggregate: Email_Component_Approvals_Aggregate;
  /** fetch data from the table: "email_component_approvals" using primary key columns */
  email_component_approvals_by_pk?: Maybe<Email_Component_Approvals>;
  /** fetch data from the table: "email_templates" */
  email_templates: Array<Email_Templates>;
  /** fetch aggregated fields from the table: "email_templates" */
  email_templates_aggregate: Email_Templates_Aggregate;
  /** fetch data from the table: "email_templates" using primary key columns */
  email_templates_by_pk?: Maybe<Email_Templates>;
  /** fetch data from the table: "emails" */
  emails: Array<Emails>;
  /** fetch aggregated fields from the table: "emails" */
  emails_aggregate: Emails_Aggregate;
  /** fetch data from the table: "emails" using primary key columns */
  emails_by_pk?: Maybe<Emails>;
  /** fetch data from the table: "file_uploads" */
  file_uploads: Array<File_Uploads>;
  /** fetch aggregated fields from the table: "file_uploads" */
  file_uploads_aggregate: File_Uploads_Aggregate;
  /** fetch data from the table: "file_uploads" using primary key columns */
  file_uploads_by_pk?: Maybe<File_Uploads>;
  /** execute function "filtered_insurance_counts" which returns "insurance_counts" */
  filtered_insurance_counts: Array<Insurance_Counts>;
  /** execute function "filtered_insurance_counts" and query aggregates on result of table type "insurance_counts" */
  filtered_insurance_counts_aggregate: Insurance_Counts_Aggregate;
  /** fetch data from the table: "health_equity_reports" */
  health_equity_reports: Array<Health_Equity_Reports>;
  /** fetch aggregated fields from the table: "health_equity_reports" */
  health_equity_reports_aggregate: Health_Equity_Reports_Aggregate;
  /** fetch data from the table: "health_equity_reports_audit" */
  health_equity_reports_audit: Array<Health_Equity_Reports_Audit>;
  /** fetch aggregated fields from the table: "health_equity_reports_audit" */
  health_equity_reports_audit_aggregate: Health_Equity_Reports_Audit_Aggregate;
  /** fetch data from the table: "health_equity_reports" using primary key columns */
  health_equity_reports_by_pk?: Maybe<Health_Equity_Reports>;
  /** fetch data from the table: "hedr_submission_templates" */
  hedr_submission_templates: Array<Hedr_Submission_Templates>;
  /** fetch aggregated fields from the table: "hedr_submission_templates" */
  hedr_submission_templates_aggregate: Hedr_Submission_Templates_Aggregate;
  /** fetch data from the table: "hedr_submission_templates" using primary key columns */
  hedr_submission_templates_by_pk?: Maybe<Hedr_Submission_Templates>;
  /** fetch data from the table: "insurance_counts" */
  insurance_counts: Array<Insurance_Counts>;
  /** fetch aggregated fields from the table: "insurance_counts" */
  insurance_counts_aggregate: Insurance_Counts_Aggregate;
  /** fetch data from the table: "insurance_segment_mappings" */
  insurance_segment_mappings: Array<Insurance_Segment_Mappings>;
  /** fetch aggregated fields from the table: "insurance_segment_mappings" */
  insurance_segment_mappings_aggregate: Insurance_Segment_Mappings_Aggregate;
  /** fetch data from the table: "insurance_segment_mappings" using primary key columns */
  insurance_segment_mappings_by_pk?: Maybe<Insurance_Segment_Mappings>;
  /** fetch data from the table: "letter_component_approvals" */
  letter_component_approvals: Array<Letter_Component_Approvals>;
  /** fetch aggregated fields from the table: "letter_component_approvals" */
  letter_component_approvals_aggregate: Letter_Component_Approvals_Aggregate;
  /** fetch data from the table: "letter_component_approvals" using primary key columns */
  letter_component_approvals_by_pk?: Maybe<Letter_Component_Approvals>;
  /** fetch data from the table: "letter_format_types" */
  letter_format_types: Array<Letter_Format_Types>;
  /** fetch aggregated fields from the table: "letter_format_types" */
  letter_format_types_aggregate: Letter_Format_Types_Aggregate;
  /** fetch data from the table: "letter_format_types" using primary key columns */
  letter_format_types_by_pk?: Maybe<Letter_Format_Types>;
  /** fetch data from the table: "letter_templates" */
  letter_templates: Array<Letter_Templates>;
  /** fetch aggregated fields from the table: "letter_templates" */
  letter_templates_aggregate: Letter_Templates_Aggregate;
  /** fetch data from the table: "letter_templates" using primary key columns */
  letter_templates_by_pk?: Maybe<Letter_Templates>;
  /** fetch data from the table: "letter_variable_values" */
  letter_variable_values: Array<Letter_Variable_Values>;
  /** fetch aggregated fields from the table: "letter_variable_values" */
  letter_variable_values_aggregate: Letter_Variable_Values_Aggregate;
  /** fetch data from the table: "letter_variable_values" using primary key columns */
  letter_variable_values_by_pk?: Maybe<Letter_Variable_Values>;
  /** fetch data from the table: "letters" */
  letters: Array<Letters>;
  /** fetch aggregated fields from the table: "letters" */
  letters_aggregate: Letters_Aggregate;
  /** fetch data from the table: "letters" using primary key columns */
  letters_by_pk?: Maybe<Letters>;
  /** fetch data from the table: "networks" */
  networks: Array<Networks>;
  /** fetch aggregated fields from the table: "networks" */
  networks_aggregate: Networks_Aggregate;
  /** fetch data from the table: "networks" using primary key columns */
  networks_by_pk?: Maybe<Networks>;
  /** fetch data from the table: "organization_networks" */
  organization_networks: Array<Organization_Networks>;
  /** fetch aggregated fields from the table: "organization_networks" */
  organization_networks_aggregate: Organization_Networks_Aggregate;
  /** fetch data from the table: "organization_networks" using primary key columns */
  organization_networks_by_pk?: Maybe<Organization_Networks>;
  /** fetch data from the table: "organizations" */
  organizations: Array<Organizations>;
  /** fetch aggregated fields from the table: "organizations" */
  organizations_aggregate: Organizations_Aggregate;
  /** fetch data from the table: "organizations" using primary key columns */
  organizations_by_pk?: Maybe<Organizations>;
  /** fetch data from the table: "patient_alignment_labels" */
  patient_alignment_labels: Array<Patient_Alignment_Labels>;
  /** fetch aggregated fields from the table: "patient_alignment_labels" */
  patient_alignment_labels_aggregate: Patient_Alignment_Labels_Aggregate;
  /** fetch data from the table: "patient_hedr_labels" */
  patient_hedr_labels: Array<Patient_Hedr_Labels>;
  /** fetch aggregated fields from the table: "patient_hedr_labels" */
  patient_hedr_labels_aggregate: Patient_Hedr_Labels_Aggregate;
  /** fetch data from the table: "patient_roster_files" */
  patient_roster_files: Array<Patient_Roster_Files>;
  /** fetch aggregated fields from the table: "patient_roster_files" */
  patient_roster_files_aggregate: Patient_Roster_Files_Aggregate;
  /** fetch data from the table: "patient_roster_files" using primary key columns */
  patient_roster_files_by_pk?: Maybe<Patient_Roster_Files>;
  /** fetch data from the table: "patient_segment_labels" */
  patient_segment_labels: Array<Patient_Segment_Labels>;
  /** fetch aggregated fields from the table: "patient_segment_labels" */
  patient_segment_labels_aggregate: Patient_Segment_Labels_Aggregate;
  /** fetch data from the table: "patient_segments" */
  patient_segments: Array<Patient_Segments>;
  /** fetch aggregated fields from the table: "patient_segments" */
  patient_segments_aggregate: Patient_Segments_Aggregate;
  /** fetch data from the table: "patient_segments" using primary key columns */
  patient_segments_by_pk?: Maybe<Patient_Segments>;
  /** fetch data from the table: "patients" */
  patients: Array<Patients>;
  /** fetch aggregated fields from the table: "patients" */
  patients_aggregate: Patients_Aggregate;
  /** fetch data from the table: "patients_audit" */
  patients_audit: Array<Patients_Audit>;
  /** fetch aggregated fields from the table: "patients_audit" */
  patients_audit_aggregate: Patients_Audit_Aggregate;
  /** fetch data from the table: "patients" using primary key columns */
  patients_by_pk?: Maybe<Patients>;
  /** fetch data from the table: "practices" */
  practices: Array<Practices>;
  /** fetch aggregated fields from the table: "practices" */
  practices_aggregate: Practices_Aggregate;
  /** fetch data from the table: "practices" using primary key columns */
  practices_by_pk?: Maybe<Practices>;
  /** fetch data from the table: "provider_employments" */
  provider_employments: Array<Provider_Employments>;
  /** fetch aggregated fields from the table: "provider_employments" */
  provider_employments_aggregate: Provider_Employments_Aggregate;
  /** fetch data from the table: "provider_employments" using primary key columns */
  provider_employments_by_pk?: Maybe<Provider_Employments>;
  /** fetch data from the table: "provider_memberships" */
  provider_memberships: Array<Provider_Memberships>;
  /** fetch aggregated fields from the table: "provider_memberships" */
  provider_memberships_aggregate: Provider_Memberships_Aggregate;
  /** fetch data from the table: "provider_memberships" using primary key columns */
  provider_memberships_by_pk?: Maybe<Provider_Memberships>;
  /** fetch data from the table: "providers" */
  providers: Array<Providers>;
  /** fetch aggregated fields from the table: "providers" */
  providers_aggregate: Providers_Aggregate;
  /** fetch data from the table: "providers" using primary key columns */
  providers_by_pk?: Maybe<Providers>;
  /** fetch data from the table: "pvas" */
  pvas: Array<Pvas>;
  /** fetch aggregated fields from the table: "pvas" */
  pvas_aggregate: Pvas_Aggregate;
  /** fetch data from the table: "pvas_audit" */
  pvas_audit: Array<Pvas_Audit>;
  /** fetch aggregated fields from the table: "pvas_audit" */
  pvas_audit_aggregate: Pvas_Audit_Aggregate;
  /** fetch data from the table: "pvas" using primary key columns */
  pvas_by_pk?: Maybe<Pvas>;
  /** fetch data from the table: "satisfaction_surveys" */
  satisfaction_surveys: Array<Satisfaction_Surveys>;
  /** fetch aggregated fields from the table: "satisfaction_surveys" */
  satisfaction_surveys_aggregate: Satisfaction_Surveys_Aggregate;
  /** fetch data from the table: "satisfaction_surveys" using primary key columns */
  satisfaction_surveys_by_pk?: Maybe<Satisfaction_Surveys>;
  /** fetch data from the table: "scheduled_events" */
  scheduled_events: Array<Scheduled_Events>;
  /** fetch aggregated fields from the table: "scheduled_events" */
  scheduled_events_aggregate: Scheduled_Events_Aggregate;
  /** execute function "search_patients_by_hedr_data" which returns "patients" */
  search_patients_by_hedr_data: Array<Patients>;
  /** execute function "search_patients_by_hedr_data" and query aggregates on result of table type "patients" */
  search_patients_by_hedr_data_aggregate: Patients_Aggregate;
  /** execute function "search_patients_by_pva_data" which returns "patients" */
  search_patients_by_pva_data: Array<Patients>;
  /** execute function "search_patients_by_pva_data" and query aggregates on result of table type "patients" */
  search_patients_by_pva_data_aggregate: Patients_Aggregate;
  /** fetch data from the table: "sms_component_approvals" */
  sms_component_approvals: Array<Sms_Component_Approvals>;
  /** fetch aggregated fields from the table: "sms_component_approvals" */
  sms_component_approvals_aggregate: Sms_Component_Approvals_Aggregate;
  /** fetch data from the table: "sms_component_approvals" using primary key columns */
  sms_component_approvals_by_pk?: Maybe<Sms_Component_Approvals>;
  /** fetch data from the table: "sms_messages" */
  sms_messages: Array<Sms_Messages>;
  /** fetch aggregated fields from the table: "sms_messages" */
  sms_messages_aggregate: Sms_Messages_Aggregate;
  /** fetch data from the table: "sms_messages" using primary key columns */
  sms_messages_by_pk?: Maybe<Sms_Messages>;
  /** fetch data from the table: "sms_templates" */
  sms_templates: Array<Sms_Templates>;
  /** fetch aggregated fields from the table: "sms_templates" */
  sms_templates_aggregate: Sms_Templates_Aggregate;
  /** fetch data from the table: "sms_templates" using primary key columns */
  sms_templates_by_pk?: Maybe<Sms_Templates>;
  /** fetch data from the table: "users" */
  users: Array<Users>;
  /** fetch aggregated fields from the table: "users" */
  users_aggregate: Users_Aggregate;
  /** fetch data from the table: "users_audit" */
  users_audit: Array<Users_Audit>;
  /** fetch aggregated fields from the table: "users_audit" */
  users_audit_aggregate: Users_Audit_Aggregate;
  /** fetch data from the table: "users" using primary key columns */
  users_by_pk?: Maybe<Users>;
};


/** query root */
export type Query_RootAccount_Creation_RequestsArgs = {
  distinct_on?: Maybe<Array<Account_Creation_Requests_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Account_Creation_Requests_Order_By>>;
  where?: Maybe<Account_Creation_Requests_Bool_Exp>;
};


/** query root */
export type Query_RootAccount_Creation_Requests_AggregateArgs = {
  distinct_on?: Maybe<Array<Account_Creation_Requests_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Account_Creation_Requests_Order_By>>;
  where?: Maybe<Account_Creation_Requests_Bool_Exp>;
};


/** query root */
export type Query_RootAccount_Creation_Requests_By_PkArgs = {
  account_creation_request_id: Scalars['String'];
};


/** query root */
export type Query_RootAlignmentsArgs = {
  distinct_on?: Maybe<Array<Alignments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Alignments_Order_By>>;
  where?: Maybe<Alignments_Bool_Exp>;
};


/** query root */
export type Query_RootAlignments_AggregateArgs = {
  distinct_on?: Maybe<Array<Alignments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Alignments_Order_By>>;
  where?: Maybe<Alignments_Bool_Exp>;
};


/** query root */
export type Query_RootAlignments_By_PkArgs = {
  alignment_id: Scalars['String'];
};


/** query root */
export type Query_RootAnalytics_Analytics_Cms_Active_Dce_ParticipantsArgs = {
  distinct_on?: Maybe<Array<Analytics_Analytics_Cms_Active_Dce_Participants_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Analytics_Analytics_Cms_Active_Dce_Participants_Order_By>>;
  where?: Maybe<Analytics_Analytics_Cms_Active_Dce_Participants_Bool_Exp>;
};


/** query root */
export type Query_RootAnalytics_Analytics_Cms_Active_Dce_Participants_AggregateArgs = {
  distinct_on?: Maybe<Array<Analytics_Analytics_Cms_Active_Dce_Participants_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Analytics_Analytics_Cms_Active_Dce_Participants_Order_By>>;
  where?: Maybe<Analytics_Analytics_Cms_Active_Dce_Participants_Bool_Exp>;
};


/** query root */
export type Query_RootBusiness_Associate_AgreementsArgs = {
  distinct_on?: Maybe<Array<Business_Associate_Agreements_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Business_Associate_Agreements_Order_By>>;
  where?: Maybe<Business_Associate_Agreements_Bool_Exp>;
};


/** query root */
export type Query_RootBusiness_Associate_Agreements_AggregateArgs = {
  distinct_on?: Maybe<Array<Business_Associate_Agreements_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Business_Associate_Agreements_Order_By>>;
  where?: Maybe<Business_Associate_Agreements_Bool_Exp>;
};


/** query root */
export type Query_RootBusiness_Associate_Agreements_By_PkArgs = {
  external_id: Scalars['String'];
  external_source: Scalars['String'];
};


/** query root */
export type Query_RootCampaign_ApprovalsArgs = {
  distinct_on?: Maybe<Array<Campaign_Approvals_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Campaign_Approvals_Order_By>>;
  where?: Maybe<Campaign_Approvals_Bool_Exp>;
};


/** query root */
export type Query_RootCampaign_Approvals_AggregateArgs = {
  distinct_on?: Maybe<Array<Campaign_Approvals_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Campaign_Approvals_Order_By>>;
  where?: Maybe<Campaign_Approvals_Bool_Exp>;
};


/** query root */
export type Query_RootCampaign_Approvals_By_PkArgs = {
  campaign_template_id: Scalars['String'];
  practice_id: Scalars['String'];
};


/** query root */
export type Query_RootCampaign_Email_ComponentsArgs = {
  distinct_on?: Maybe<Array<Campaign_Email_Components_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Campaign_Email_Components_Order_By>>;
  where?: Maybe<Campaign_Email_Components_Bool_Exp>;
};


/** query root */
export type Query_RootCampaign_Email_Components_AggregateArgs = {
  distinct_on?: Maybe<Array<Campaign_Email_Components_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Campaign_Email_Components_Order_By>>;
  where?: Maybe<Campaign_Email_Components_Bool_Exp>;
};


/** query root */
export type Query_RootCampaign_Email_Components_By_PkArgs = {
  campaign_email_component_id: Scalars['String'];
};


/** query root */
export type Query_RootCampaign_Letter_ComponentsArgs = {
  distinct_on?: Maybe<Array<Campaign_Letter_Components_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Campaign_Letter_Components_Order_By>>;
  where?: Maybe<Campaign_Letter_Components_Bool_Exp>;
};


/** query root */
export type Query_RootCampaign_Letter_Components_AggregateArgs = {
  distinct_on?: Maybe<Array<Campaign_Letter_Components_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Campaign_Letter_Components_Order_By>>;
  where?: Maybe<Campaign_Letter_Components_Bool_Exp>;
};


/** query root */
export type Query_RootCampaign_Letter_Components_By_PkArgs = {
  campaign_letter_component_id: Scalars['String'];
};


/** query root */
export type Query_RootCampaign_Sms_ComponentsArgs = {
  distinct_on?: Maybe<Array<Campaign_Sms_Components_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Campaign_Sms_Components_Order_By>>;
  where?: Maybe<Campaign_Sms_Components_Bool_Exp>;
};


/** query root */
export type Query_RootCampaign_Sms_Components_AggregateArgs = {
  distinct_on?: Maybe<Array<Campaign_Sms_Components_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Campaign_Sms_Components_Order_By>>;
  where?: Maybe<Campaign_Sms_Components_Bool_Exp>;
};


/** query root */
export type Query_RootCampaign_Sms_Components_By_PkArgs = {
  campaign_sms_component_id: Scalars['String'];
};


/** query root */
export type Query_RootCampaign_TemplatesArgs = {
  distinct_on?: Maybe<Array<Campaign_Templates_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Campaign_Templates_Order_By>>;
  where?: Maybe<Campaign_Templates_Bool_Exp>;
};


/** query root */
export type Query_RootCampaign_Templates_AggregateArgs = {
  distinct_on?: Maybe<Array<Campaign_Templates_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Campaign_Templates_Order_By>>;
  where?: Maybe<Campaign_Templates_Bool_Exp>;
};


/** query root */
export type Query_RootCampaign_Templates_By_PkArgs = {
  campaign_template_id: Scalars['String'];
};


/** query root */
export type Query_RootClient_Call_RecordsArgs = {
  distinct_on?: Maybe<Array<Client_Call_Records_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Client_Call_Records_Order_By>>;
  where?: Maybe<Client_Call_Records_Bool_Exp>;
};


/** query root */
export type Query_RootClient_Call_Records_AggregateArgs = {
  distinct_on?: Maybe<Array<Client_Call_Records_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Client_Call_Records_Order_By>>;
  where?: Maybe<Client_Call_Records_Bool_Exp>;
};


/** query root */
export type Query_RootClient_Call_Records_By_PkArgs = {
  call_start_time: Scalars['timestamp'];
  caller_number: Scalars['String'];
  dialed_number: Scalars['String'];
};


/** query root */
export type Query_RootCms_Alignment_ReportsArgs = {
  distinct_on?: Maybe<Array<Cms_Alignment_Reports_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Cms_Alignment_Reports_Order_By>>;
  where?: Maybe<Cms_Alignment_Reports_Bool_Exp>;
};


/** query root */
export type Query_RootCms_Alignment_Reports_AggregateArgs = {
  distinct_on?: Maybe<Array<Cms_Alignment_Reports_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Cms_Alignment_Reports_Order_By>>;
  where?: Maybe<Cms_Alignment_Reports_Bool_Exp>;
};


/** query root */
export type Query_RootCms_Alignment_Reports_By_PkArgs = {
  beneficiary_mbi_id: Scalars['String'];
  dc_id: Scalars['String'];
  performance_year: Scalars['Int'];
};


/** query root */
export type Query_RootCms_Dce_ParticipantsArgs = {
  distinct_on?: Maybe<Array<Cms_Dce_Participants_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Cms_Dce_Participants_Order_By>>;
  where?: Maybe<Cms_Dce_Participants_Bool_Exp>;
};


/** query root */
export type Query_RootCms_Dce_Participants_AggregateArgs = {
  distinct_on?: Maybe<Array<Cms_Dce_Participants_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Cms_Dce_Participants_Order_By>>;
  where?: Maybe<Cms_Dce_Participants_Bool_Exp>;
};


/** query root */
export type Query_RootCms_Provider_Alignment_ReportsArgs = {
  distinct_on?: Maybe<Array<Cms_Provider_Alignment_Reports_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Cms_Provider_Alignment_Reports_Order_By>>;
  where?: Maybe<Cms_Provider_Alignment_Reports_Bool_Exp>;
};


/** query root */
export type Query_RootCms_Provider_Alignment_Reports_AggregateArgs = {
  distinct_on?: Maybe<Array<Cms_Provider_Alignment_Reports_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Cms_Provider_Alignment_Reports_Order_By>>;
  where?: Maybe<Cms_Provider_Alignment_Reports_Bool_Exp>;
};


/** query root */
export type Query_RootCms_Provider_Alignment_Reports_By_PkArgs = {
  ACO_ID: Scalars['String'];
  ALGN_TYPE_CLM: Scalars['String'];
  FAC_PRVDR_OSCAR_NUM: Scalars['String'];
  MBI_ID: Scalars['String'];
  PERFORMANCE_YEAR: Scalars['Int'];
  PRVDR_NPI: Scalars['String'];
  PRVDR_TIN: Scalars['String'];
};


/** query root */
export type Query_RootCms_Pva_ResponsesArgs = {
  distinct_on?: Maybe<Array<Cms_Pva_Responses_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Cms_Pva_Responses_Order_By>>;
  where?: Maybe<Cms_Pva_Responses_Bool_Exp>;
};


/** query root */
export type Query_RootCms_Pva_Responses_AggregateArgs = {
  distinct_on?: Maybe<Array<Cms_Pva_Responses_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Cms_Pva_Responses_Order_By>>;
  where?: Maybe<Cms_Pva_Responses_Bool_Exp>;
};


/** query root */
export type Query_RootCms_Pva_Responses_By_PkArgs = {
  DCE_ID: Scalars['String'];
  ID_RECEIVED: Scalars['String'];
  IND_NPI: Scalars['String'];
  IND_TIN: Scalars['String'];
  SIGNATURE_DATE: Scalars['date'];
  SUBMISSION_DATE: Scalars['date'];
};


/** query root */
export type Query_RootComponent_Tracking_IdsArgs = {
  distinct_on?: Maybe<Array<Component_Tracking_Ids_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Component_Tracking_Ids_Order_By>>;
  where?: Maybe<Component_Tracking_Ids_Bool_Exp>;
};


/** query root */
export type Query_RootComponent_Tracking_Ids_AggregateArgs = {
  distinct_on?: Maybe<Array<Component_Tracking_Ids_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Component_Tracking_Ids_Order_By>>;
  where?: Maybe<Component_Tracking_Ids_Bool_Exp>;
};


/** query root */
export type Query_RootComponent_Tracking_Ids_By_PkArgs = {
  component_id: Scalars['String'];
  tracking_id: Scalars['String'];
};


/** query root */
export type Query_RootDaily_Performance_StatsArgs = {
  distinct_on?: Maybe<Array<Daily_Performance_Stats_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Daily_Performance_Stats_Order_By>>;
  where?: Maybe<Daily_Performance_Stats_Bool_Exp>;
};


/** query root */
export type Query_RootDaily_Performance_Stats_AggregateArgs = {
  distinct_on?: Maybe<Array<Daily_Performance_Stats_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Daily_Performance_Stats_Order_By>>;
  where?: Maybe<Daily_Performance_Stats_Bool_Exp>;
};


/** query root */
export type Query_RootDaily_Performance_Stats_By_PkArgs = {
  alliance_name: Scalars['String'];
  report_date: Scalars['date'];
};


/** query root */
export type Query_RootEmail_Component_ApprovalsArgs = {
  distinct_on?: Maybe<Array<Email_Component_Approvals_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Email_Component_Approvals_Order_By>>;
  where?: Maybe<Email_Component_Approvals_Bool_Exp>;
};


/** query root */
export type Query_RootEmail_Component_Approvals_AggregateArgs = {
  distinct_on?: Maybe<Array<Email_Component_Approvals_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Email_Component_Approvals_Order_By>>;
  where?: Maybe<Email_Component_Approvals_Bool_Exp>;
};


/** query root */
export type Query_RootEmail_Component_Approvals_By_PkArgs = {
  campaign_email_component_id: Scalars['String'];
  campaign_template_id: Scalars['String'];
  practice_id: Scalars['String'];
};


/** query root */
export type Query_RootEmail_TemplatesArgs = {
  distinct_on?: Maybe<Array<Email_Templates_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Email_Templates_Order_By>>;
  where?: Maybe<Email_Templates_Bool_Exp>;
};


/** query root */
export type Query_RootEmail_Templates_AggregateArgs = {
  distinct_on?: Maybe<Array<Email_Templates_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Email_Templates_Order_By>>;
  where?: Maybe<Email_Templates_Bool_Exp>;
};


/** query root */
export type Query_RootEmail_Templates_By_PkArgs = {
  email_template_id: Scalars['String'];
};


/** query root */
export type Query_RootEmailsArgs = {
  distinct_on?: Maybe<Array<Emails_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Emails_Order_By>>;
  where?: Maybe<Emails_Bool_Exp>;
};


/** query root */
export type Query_RootEmails_AggregateArgs = {
  distinct_on?: Maybe<Array<Emails_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Emails_Order_By>>;
  where?: Maybe<Emails_Bool_Exp>;
};


/** query root */
export type Query_RootEmails_By_PkArgs = {
  email_id: Scalars['String'];
};


/** query root */
export type Query_RootFile_UploadsArgs = {
  distinct_on?: Maybe<Array<File_Uploads_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<File_Uploads_Order_By>>;
  where?: Maybe<File_Uploads_Bool_Exp>;
};


/** query root */
export type Query_RootFile_Uploads_AggregateArgs = {
  distinct_on?: Maybe<Array<File_Uploads_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<File_Uploads_Order_By>>;
  where?: Maybe<File_Uploads_Bool_Exp>;
};


/** query root */
export type Query_RootFile_Uploads_By_PkArgs = {
  file_upload_id: Scalars['String'];
};


/** query root */
export type Query_RootFiltered_Insurance_CountsArgs = {
  args: Filtered_Insurance_Counts_Args;
  distinct_on?: Maybe<Array<Insurance_Counts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Insurance_Counts_Order_By>>;
  where?: Maybe<Insurance_Counts_Bool_Exp>;
};


/** query root */
export type Query_RootFiltered_Insurance_Counts_AggregateArgs = {
  args: Filtered_Insurance_Counts_Args;
  distinct_on?: Maybe<Array<Insurance_Counts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Insurance_Counts_Order_By>>;
  where?: Maybe<Insurance_Counts_Bool_Exp>;
};


/** query root */
export type Query_RootHealth_Equity_ReportsArgs = {
  distinct_on?: Maybe<Array<Health_Equity_Reports_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Health_Equity_Reports_Order_By>>;
  where?: Maybe<Health_Equity_Reports_Bool_Exp>;
};


/** query root */
export type Query_RootHealth_Equity_Reports_AggregateArgs = {
  distinct_on?: Maybe<Array<Health_Equity_Reports_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Health_Equity_Reports_Order_By>>;
  where?: Maybe<Health_Equity_Reports_Bool_Exp>;
};


/** query root */
export type Query_RootHealth_Equity_Reports_AuditArgs = {
  distinct_on?: Maybe<Array<Health_Equity_Reports_Audit_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Health_Equity_Reports_Audit_Order_By>>;
  where?: Maybe<Health_Equity_Reports_Audit_Bool_Exp>;
};


/** query root */
export type Query_RootHealth_Equity_Reports_Audit_AggregateArgs = {
  distinct_on?: Maybe<Array<Health_Equity_Reports_Audit_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Health_Equity_Reports_Audit_Order_By>>;
  where?: Maybe<Health_Equity_Reports_Audit_Bool_Exp>;
};


/** query root */
export type Query_RootHealth_Equity_Reports_By_PkArgs = {
  health_equity_report_id: Scalars['String'];
};


/** query root */
export type Query_RootHedr_Submission_TemplatesArgs = {
  distinct_on?: Maybe<Array<Hedr_Submission_Templates_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hedr_Submission_Templates_Order_By>>;
  where?: Maybe<Hedr_Submission_Templates_Bool_Exp>;
};


/** query root */
export type Query_RootHedr_Submission_Templates_AggregateArgs = {
  distinct_on?: Maybe<Array<Hedr_Submission_Templates_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hedr_Submission_Templates_Order_By>>;
  where?: Maybe<Hedr_Submission_Templates_Bool_Exp>;
};


/** query root */
export type Query_RootHedr_Submission_Templates_By_PkArgs = {
  entity_id: Scalars['String'];
  mbi: Scalars['String'];
  performance_year: Scalars['Int'];
};


/** query root */
export type Query_RootInsurance_CountsArgs = {
  distinct_on?: Maybe<Array<Insurance_Counts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Insurance_Counts_Order_By>>;
  where?: Maybe<Insurance_Counts_Bool_Exp>;
};


/** query root */
export type Query_RootInsurance_Counts_AggregateArgs = {
  distinct_on?: Maybe<Array<Insurance_Counts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Insurance_Counts_Order_By>>;
  where?: Maybe<Insurance_Counts_Bool_Exp>;
};


/** query root */
export type Query_RootInsurance_Segment_MappingsArgs = {
  distinct_on?: Maybe<Array<Insurance_Segment_Mappings_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Insurance_Segment_Mappings_Order_By>>;
  where?: Maybe<Insurance_Segment_Mappings_Bool_Exp>;
};


/** query root */
export type Query_RootInsurance_Segment_Mappings_AggregateArgs = {
  distinct_on?: Maybe<Array<Insurance_Segment_Mappings_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Insurance_Segment_Mappings_Order_By>>;
  where?: Maybe<Insurance_Segment_Mappings_Bool_Exp>;
};


/** query root */
export type Query_RootInsurance_Segment_Mappings_By_PkArgs = {
  primary_insurance_is_null: Scalars['Boolean'];
  primary_insurance_payer_name: Scalars['String'];
  secondary_insurance_is_null: Scalars['Boolean'];
  secondary_insurance_payer_name: Scalars['String'];
};


/** query root */
export type Query_RootLetter_Component_ApprovalsArgs = {
  distinct_on?: Maybe<Array<Letter_Component_Approvals_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Letter_Component_Approvals_Order_By>>;
  where?: Maybe<Letter_Component_Approvals_Bool_Exp>;
};


/** query root */
export type Query_RootLetter_Component_Approvals_AggregateArgs = {
  distinct_on?: Maybe<Array<Letter_Component_Approvals_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Letter_Component_Approvals_Order_By>>;
  where?: Maybe<Letter_Component_Approvals_Bool_Exp>;
};


/** query root */
export type Query_RootLetter_Component_Approvals_By_PkArgs = {
  campaign_letter_component_id: Scalars['String'];
  campaign_template_id: Scalars['String'];
  practice_id: Scalars['String'];
};


/** query root */
export type Query_RootLetter_Format_TypesArgs = {
  distinct_on?: Maybe<Array<Letter_Format_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Letter_Format_Types_Order_By>>;
  where?: Maybe<Letter_Format_Types_Bool_Exp>;
};


/** query root */
export type Query_RootLetter_Format_Types_AggregateArgs = {
  distinct_on?: Maybe<Array<Letter_Format_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Letter_Format_Types_Order_By>>;
  where?: Maybe<Letter_Format_Types_Bool_Exp>;
};


/** query root */
export type Query_RootLetter_Format_Types_By_PkArgs = {
  letter_format_type_id: Scalars['String'];
};


/** query root */
export type Query_RootLetter_TemplatesArgs = {
  distinct_on?: Maybe<Array<Letter_Templates_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Letter_Templates_Order_By>>;
  where?: Maybe<Letter_Templates_Bool_Exp>;
};


/** query root */
export type Query_RootLetter_Templates_AggregateArgs = {
  distinct_on?: Maybe<Array<Letter_Templates_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Letter_Templates_Order_By>>;
  where?: Maybe<Letter_Templates_Bool_Exp>;
};


/** query root */
export type Query_RootLetter_Templates_By_PkArgs = {
  letter_template_id: Scalars['String'];
};


/** query root */
export type Query_RootLetter_Variable_ValuesArgs = {
  distinct_on?: Maybe<Array<Letter_Variable_Values_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Letter_Variable_Values_Order_By>>;
  where?: Maybe<Letter_Variable_Values_Bool_Exp>;
};


/** query root */
export type Query_RootLetter_Variable_Values_AggregateArgs = {
  distinct_on?: Maybe<Array<Letter_Variable_Values_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Letter_Variable_Values_Order_By>>;
  where?: Maybe<Letter_Variable_Values_Bool_Exp>;
};


/** query root */
export type Query_RootLetter_Variable_Values_By_PkArgs = {
  key: Scalars['String'];
  letter_id: Scalars['String'];
};


/** query root */
export type Query_RootLettersArgs = {
  distinct_on?: Maybe<Array<Letters_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Letters_Order_By>>;
  where?: Maybe<Letters_Bool_Exp>;
};


/** query root */
export type Query_RootLetters_AggregateArgs = {
  distinct_on?: Maybe<Array<Letters_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Letters_Order_By>>;
  where?: Maybe<Letters_Bool_Exp>;
};


/** query root */
export type Query_RootLetters_By_PkArgs = {
  letter_id: Scalars['String'];
};


/** query root */
export type Query_RootNetworksArgs = {
  distinct_on?: Maybe<Array<Networks_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Networks_Order_By>>;
  where?: Maybe<Networks_Bool_Exp>;
};


/** query root */
export type Query_RootNetworks_AggregateArgs = {
  distinct_on?: Maybe<Array<Networks_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Networks_Order_By>>;
  where?: Maybe<Networks_Bool_Exp>;
};


/** query root */
export type Query_RootNetworks_By_PkArgs = {
  network_id: Scalars['String'];
};


/** query root */
export type Query_RootOrganization_NetworksArgs = {
  distinct_on?: Maybe<Array<Organization_Networks_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Organization_Networks_Order_By>>;
  where?: Maybe<Organization_Networks_Bool_Exp>;
};


/** query root */
export type Query_RootOrganization_Networks_AggregateArgs = {
  distinct_on?: Maybe<Array<Organization_Networks_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Organization_Networks_Order_By>>;
  where?: Maybe<Organization_Networks_Bool_Exp>;
};


/** query root */
export type Query_RootOrganization_Networks_By_PkArgs = {
  network_id: Scalars['String'];
  organization_id: Scalars['String'];
};


/** query root */
export type Query_RootOrganizationsArgs = {
  distinct_on?: Maybe<Array<Organizations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Organizations_Order_By>>;
  where?: Maybe<Organizations_Bool_Exp>;
};


/** query root */
export type Query_RootOrganizations_AggregateArgs = {
  distinct_on?: Maybe<Array<Organizations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Organizations_Order_By>>;
  where?: Maybe<Organizations_Bool_Exp>;
};


/** query root */
export type Query_RootOrganizations_By_PkArgs = {
  organization_id: Scalars['String'];
};


/** query root */
export type Query_RootPatient_Alignment_LabelsArgs = {
  distinct_on?: Maybe<Array<Patient_Alignment_Labels_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Patient_Alignment_Labels_Order_By>>;
  where?: Maybe<Patient_Alignment_Labels_Bool_Exp>;
};


/** query root */
export type Query_RootPatient_Alignment_Labels_AggregateArgs = {
  distinct_on?: Maybe<Array<Patient_Alignment_Labels_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Patient_Alignment_Labels_Order_By>>;
  where?: Maybe<Patient_Alignment_Labels_Bool_Exp>;
};


/** query root */
export type Query_RootPatient_Hedr_LabelsArgs = {
  distinct_on?: Maybe<Array<Patient_Hedr_Labels_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Patient_Hedr_Labels_Order_By>>;
  where?: Maybe<Patient_Hedr_Labels_Bool_Exp>;
};


/** query root */
export type Query_RootPatient_Hedr_Labels_AggregateArgs = {
  distinct_on?: Maybe<Array<Patient_Hedr_Labels_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Patient_Hedr_Labels_Order_By>>;
  where?: Maybe<Patient_Hedr_Labels_Bool_Exp>;
};


/** query root */
export type Query_RootPatient_Roster_FilesArgs = {
  distinct_on?: Maybe<Array<Patient_Roster_Files_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Patient_Roster_Files_Order_By>>;
  where?: Maybe<Patient_Roster_Files_Bool_Exp>;
};


/** query root */
export type Query_RootPatient_Roster_Files_AggregateArgs = {
  distinct_on?: Maybe<Array<Patient_Roster_Files_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Patient_Roster_Files_Order_By>>;
  where?: Maybe<Patient_Roster_Files_Bool_Exp>;
};


/** query root */
export type Query_RootPatient_Roster_Files_By_PkArgs = {
  identity_id: Scalars['String'];
  key: Scalars['String'];
  level: Scalars['String'];
};


/** query root */
export type Query_RootPatient_Segment_LabelsArgs = {
  distinct_on?: Maybe<Array<Patient_Segment_Labels_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Patient_Segment_Labels_Order_By>>;
  where?: Maybe<Patient_Segment_Labels_Bool_Exp>;
};


/** query root */
export type Query_RootPatient_Segment_Labels_AggregateArgs = {
  distinct_on?: Maybe<Array<Patient_Segment_Labels_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Patient_Segment_Labels_Order_By>>;
  where?: Maybe<Patient_Segment_Labels_Bool_Exp>;
};


/** query root */
export type Query_RootPatient_SegmentsArgs = {
  distinct_on?: Maybe<Array<Patient_Segments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Patient_Segments_Order_By>>;
  where?: Maybe<Patient_Segments_Bool_Exp>;
};


/** query root */
export type Query_RootPatient_Segments_AggregateArgs = {
  distinct_on?: Maybe<Array<Patient_Segments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Patient_Segments_Order_By>>;
  where?: Maybe<Patient_Segments_Bool_Exp>;
};


/** query root */
export type Query_RootPatient_Segments_By_PkArgs = {
  patient_segment_id: Scalars['String'];
};


/** query root */
export type Query_RootPatientsArgs = {
  distinct_on?: Maybe<Array<Patients_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Patients_Order_By>>;
  where?: Maybe<Patients_Bool_Exp>;
};


/** query root */
export type Query_RootPatients_AggregateArgs = {
  distinct_on?: Maybe<Array<Patients_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Patients_Order_By>>;
  where?: Maybe<Patients_Bool_Exp>;
};


/** query root */
export type Query_RootPatients_AuditArgs = {
  distinct_on?: Maybe<Array<Patients_Audit_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Patients_Audit_Order_By>>;
  where?: Maybe<Patients_Audit_Bool_Exp>;
};


/** query root */
export type Query_RootPatients_Audit_AggregateArgs = {
  distinct_on?: Maybe<Array<Patients_Audit_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Patients_Audit_Order_By>>;
  where?: Maybe<Patients_Audit_Bool_Exp>;
};


/** query root */
export type Query_RootPatients_By_PkArgs = {
  patient_id: Scalars['String'];
};


/** query root */
export type Query_RootPracticesArgs = {
  distinct_on?: Maybe<Array<Practices_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Practices_Order_By>>;
  where?: Maybe<Practices_Bool_Exp>;
};


/** query root */
export type Query_RootPractices_AggregateArgs = {
  distinct_on?: Maybe<Array<Practices_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Practices_Order_By>>;
  where?: Maybe<Practices_Bool_Exp>;
};


/** query root */
export type Query_RootPractices_By_PkArgs = {
  practice_id: Scalars['String'];
};


/** query root */
export type Query_RootProvider_EmploymentsArgs = {
  distinct_on?: Maybe<Array<Provider_Employments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Provider_Employments_Order_By>>;
  where?: Maybe<Provider_Employments_Bool_Exp>;
};


/** query root */
export type Query_RootProvider_Employments_AggregateArgs = {
  distinct_on?: Maybe<Array<Provider_Employments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Provider_Employments_Order_By>>;
  where?: Maybe<Provider_Employments_Bool_Exp>;
};


/** query root */
export type Query_RootProvider_Employments_By_PkArgs = {
  practice_id: Scalars['String'];
  provider_id: Scalars['String'];
};


/** query root */
export type Query_RootProvider_MembershipsArgs = {
  distinct_on?: Maybe<Array<Provider_Memberships_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Provider_Memberships_Order_By>>;
  where?: Maybe<Provider_Memberships_Bool_Exp>;
};


/** query root */
export type Query_RootProvider_Memberships_AggregateArgs = {
  distinct_on?: Maybe<Array<Provider_Memberships_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Provider_Memberships_Order_By>>;
  where?: Maybe<Provider_Memberships_Bool_Exp>;
};


/** query root */
export type Query_RootProvider_Memberships_By_PkArgs = {
  network_id: Scalars['String'];
  provider_id: Scalars['String'];
};


/** query root */
export type Query_RootProvidersArgs = {
  distinct_on?: Maybe<Array<Providers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Providers_Order_By>>;
  where?: Maybe<Providers_Bool_Exp>;
};


/** query root */
export type Query_RootProviders_AggregateArgs = {
  distinct_on?: Maybe<Array<Providers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Providers_Order_By>>;
  where?: Maybe<Providers_Bool_Exp>;
};


/** query root */
export type Query_RootProviders_By_PkArgs = {
  provider_id: Scalars['String'];
};


/** query root */
export type Query_RootPvasArgs = {
  distinct_on?: Maybe<Array<Pvas_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Pvas_Order_By>>;
  where?: Maybe<Pvas_Bool_Exp>;
};


/** query root */
export type Query_RootPvas_AggregateArgs = {
  distinct_on?: Maybe<Array<Pvas_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Pvas_Order_By>>;
  where?: Maybe<Pvas_Bool_Exp>;
};


/** query root */
export type Query_RootPvas_AuditArgs = {
  distinct_on?: Maybe<Array<Pvas_Audit_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Pvas_Audit_Order_By>>;
  where?: Maybe<Pvas_Audit_Bool_Exp>;
};


/** query root */
export type Query_RootPvas_Audit_AggregateArgs = {
  distinct_on?: Maybe<Array<Pvas_Audit_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Pvas_Audit_Order_By>>;
  where?: Maybe<Pvas_Audit_Bool_Exp>;
};


/** query root */
export type Query_RootPvas_By_PkArgs = {
  pva_id: Scalars['String'];
};


/** query root */
export type Query_RootSatisfaction_SurveysArgs = {
  distinct_on?: Maybe<Array<Satisfaction_Surveys_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Satisfaction_Surveys_Order_By>>;
  where?: Maybe<Satisfaction_Surveys_Bool_Exp>;
};


/** query root */
export type Query_RootSatisfaction_Surveys_AggregateArgs = {
  distinct_on?: Maybe<Array<Satisfaction_Surveys_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Satisfaction_Surveys_Order_By>>;
  where?: Maybe<Satisfaction_Surveys_Bool_Exp>;
};


/** query root */
export type Query_RootSatisfaction_Surveys_By_PkArgs = {
  satisfaction_survey_id: Scalars['String'];
};


/** query root */
export type Query_RootScheduled_EventsArgs = {
  distinct_on?: Maybe<Array<Scheduled_Events_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Scheduled_Events_Order_By>>;
  where?: Maybe<Scheduled_Events_Bool_Exp>;
};


/** query root */
export type Query_RootScheduled_Events_AggregateArgs = {
  distinct_on?: Maybe<Array<Scheduled_Events_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Scheduled_Events_Order_By>>;
  where?: Maybe<Scheduled_Events_Bool_Exp>;
};


/** query root */
export type Query_RootSearch_Patients_By_Hedr_DataArgs = {
  args: Search_Patients_By_Hedr_Data_Args;
  distinct_on?: Maybe<Array<Patients_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Patients_Order_By>>;
  where?: Maybe<Patients_Bool_Exp>;
};


/** query root */
export type Query_RootSearch_Patients_By_Hedr_Data_AggregateArgs = {
  args: Search_Patients_By_Hedr_Data_Args;
  distinct_on?: Maybe<Array<Patients_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Patients_Order_By>>;
  where?: Maybe<Patients_Bool_Exp>;
};


/** query root */
export type Query_RootSearch_Patients_By_Pva_DataArgs = {
  args: Search_Patients_By_Pva_Data_Args;
  distinct_on?: Maybe<Array<Patients_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Patients_Order_By>>;
  where?: Maybe<Patients_Bool_Exp>;
};


/** query root */
export type Query_RootSearch_Patients_By_Pva_Data_AggregateArgs = {
  args: Search_Patients_By_Pva_Data_Args;
  distinct_on?: Maybe<Array<Patients_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Patients_Order_By>>;
  where?: Maybe<Patients_Bool_Exp>;
};


/** query root */
export type Query_RootSms_Component_ApprovalsArgs = {
  distinct_on?: Maybe<Array<Sms_Component_Approvals_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Sms_Component_Approvals_Order_By>>;
  where?: Maybe<Sms_Component_Approvals_Bool_Exp>;
};


/** query root */
export type Query_RootSms_Component_Approvals_AggregateArgs = {
  distinct_on?: Maybe<Array<Sms_Component_Approvals_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Sms_Component_Approvals_Order_By>>;
  where?: Maybe<Sms_Component_Approvals_Bool_Exp>;
};


/** query root */
export type Query_RootSms_Component_Approvals_By_PkArgs = {
  campaign_sms_component_id: Scalars['String'];
  campaign_template_id: Scalars['String'];
  practice_id: Scalars['String'];
};


/** query root */
export type Query_RootSms_MessagesArgs = {
  distinct_on?: Maybe<Array<Sms_Messages_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Sms_Messages_Order_By>>;
  where?: Maybe<Sms_Messages_Bool_Exp>;
};


/** query root */
export type Query_RootSms_Messages_AggregateArgs = {
  distinct_on?: Maybe<Array<Sms_Messages_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Sms_Messages_Order_By>>;
  where?: Maybe<Sms_Messages_Bool_Exp>;
};


/** query root */
export type Query_RootSms_Messages_By_PkArgs = {
  sms_message_id: Scalars['String'];
};


/** query root */
export type Query_RootSms_TemplatesArgs = {
  distinct_on?: Maybe<Array<Sms_Templates_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Sms_Templates_Order_By>>;
  where?: Maybe<Sms_Templates_Bool_Exp>;
};


/** query root */
export type Query_RootSms_Templates_AggregateArgs = {
  distinct_on?: Maybe<Array<Sms_Templates_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Sms_Templates_Order_By>>;
  where?: Maybe<Sms_Templates_Bool_Exp>;
};


/** query root */
export type Query_RootSms_Templates_By_PkArgs = {
  sms_template_id: Scalars['String'];
};


/** query root */
export type Query_RootUsersArgs = {
  distinct_on?: Maybe<Array<Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Users_Order_By>>;
  where?: Maybe<Users_Bool_Exp>;
};


/** query root */
export type Query_RootUsers_AggregateArgs = {
  distinct_on?: Maybe<Array<Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Users_Order_By>>;
  where?: Maybe<Users_Bool_Exp>;
};


/** query root */
export type Query_RootUsers_AuditArgs = {
  distinct_on?: Maybe<Array<Users_Audit_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Users_Audit_Order_By>>;
  where?: Maybe<Users_Audit_Bool_Exp>;
};


/** query root */
export type Query_RootUsers_Audit_AggregateArgs = {
  distinct_on?: Maybe<Array<Users_Audit_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Users_Audit_Order_By>>;
  where?: Maybe<Users_Audit_Bool_Exp>;
};


/** query root */
export type Query_RootUsers_By_PkArgs = {
  user_id: Scalars['String'];
};

/** columns and relationships of "satisfaction_surveys" */
export type Satisfaction_Surveys = {
  __typename?: 'satisfaction_surveys';
  care_team?: Maybe<Scalars['String']>;
  channel?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamptz'];
  form_language?: Maybe<Scalars['String']>;
  form_practice_id?: Maybe<Scalars['String']>;
  letter_id?: Maybe<Scalars['String']>;
  letter_id_prefix?: Maybe<Scalars['String']>;
  matched_patient_id?: Maybe<Scalars['String']>;
  network_number?: Maybe<Scalars['Int']>;
  ocr_output?: Maybe<Scalars['jsonb']>;
  onedrive_file_id?: Maybe<Scalars['String']>;
  practice_id_prefix?: Maybe<Scalars['String']>;
  processing_status: Scalars['String'];
  responder_name?: Maybe<Scalars['String']>;
  satisfaction_reason?: Maybe<Scalars['String']>;
  satisfaction_score?: Maybe<Scalars['Int']>;
  satisfaction_survey_id: Scalars['String'];
  short_url?: Maybe<Scalars['String']>;
  submission_id?: Maybe<Scalars['String']>;
  submission_source?: Maybe<Scalars['String']>;
  template_id?: Maybe<Scalars['String']>;
  template_version?: Maybe<Scalars['String']>;
  transcriber_notes?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "satisfaction_surveys" */
export type Satisfaction_SurveysOcr_OutputArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "satisfaction_surveys" */
export type Satisfaction_Surveys_Aggregate = {
  __typename?: 'satisfaction_surveys_aggregate';
  aggregate?: Maybe<Satisfaction_Surveys_Aggregate_Fields>;
  nodes: Array<Satisfaction_Surveys>;
};

/** aggregate fields of "satisfaction_surveys" */
export type Satisfaction_Surveys_Aggregate_Fields = {
  __typename?: 'satisfaction_surveys_aggregate_fields';
  avg?: Maybe<Satisfaction_Surveys_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Satisfaction_Surveys_Max_Fields>;
  min?: Maybe<Satisfaction_Surveys_Min_Fields>;
  stddev?: Maybe<Satisfaction_Surveys_Stddev_Fields>;
  stddev_pop?: Maybe<Satisfaction_Surveys_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Satisfaction_Surveys_Stddev_Samp_Fields>;
  sum?: Maybe<Satisfaction_Surveys_Sum_Fields>;
  var_pop?: Maybe<Satisfaction_Surveys_Var_Pop_Fields>;
  var_samp?: Maybe<Satisfaction_Surveys_Var_Samp_Fields>;
  variance?: Maybe<Satisfaction_Surveys_Variance_Fields>;
};


/** aggregate fields of "satisfaction_surveys" */
export type Satisfaction_Surveys_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Satisfaction_Surveys_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "satisfaction_surveys" */
export type Satisfaction_Surveys_Aggregate_Order_By = {
  avg?: Maybe<Satisfaction_Surveys_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Satisfaction_Surveys_Max_Order_By>;
  min?: Maybe<Satisfaction_Surveys_Min_Order_By>;
  stddev?: Maybe<Satisfaction_Surveys_Stddev_Order_By>;
  stddev_pop?: Maybe<Satisfaction_Surveys_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Satisfaction_Surveys_Stddev_Samp_Order_By>;
  sum?: Maybe<Satisfaction_Surveys_Sum_Order_By>;
  var_pop?: Maybe<Satisfaction_Surveys_Var_Pop_Order_By>;
  var_samp?: Maybe<Satisfaction_Surveys_Var_Samp_Order_By>;
  variance?: Maybe<Satisfaction_Surveys_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Satisfaction_Surveys_Append_Input = {
  ocr_output?: Maybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "satisfaction_surveys" */
export type Satisfaction_Surveys_Arr_Rel_Insert_Input = {
  data: Array<Satisfaction_Surveys_Insert_Input>;
  on_conflict?: Maybe<Satisfaction_Surveys_On_Conflict>;
};

/** aggregate avg on columns */
export type Satisfaction_Surveys_Avg_Fields = {
  __typename?: 'satisfaction_surveys_avg_fields';
  network_number?: Maybe<Scalars['Float']>;
  satisfaction_score?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "satisfaction_surveys" */
export type Satisfaction_Surveys_Avg_Order_By = {
  network_number?: Maybe<Order_By>;
  satisfaction_score?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "satisfaction_surveys". All fields are combined with a logical 'AND'. */
export type Satisfaction_Surveys_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Satisfaction_Surveys_Bool_Exp>>>;
  _not?: Maybe<Satisfaction_Surveys_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Satisfaction_Surveys_Bool_Exp>>>;
  care_team?: Maybe<String_Comparison_Exp>;
  channel?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  form_language?: Maybe<String_Comparison_Exp>;
  form_practice_id?: Maybe<String_Comparison_Exp>;
  letter_id?: Maybe<String_Comparison_Exp>;
  letter_id_prefix?: Maybe<String_Comparison_Exp>;
  matched_patient_id?: Maybe<String_Comparison_Exp>;
  network_number?: Maybe<Int_Comparison_Exp>;
  ocr_output?: Maybe<Jsonb_Comparison_Exp>;
  onedrive_file_id?: Maybe<String_Comparison_Exp>;
  practice_id_prefix?: Maybe<String_Comparison_Exp>;
  processing_status?: Maybe<String_Comparison_Exp>;
  responder_name?: Maybe<String_Comparison_Exp>;
  satisfaction_reason?: Maybe<String_Comparison_Exp>;
  satisfaction_score?: Maybe<Int_Comparison_Exp>;
  satisfaction_survey_id?: Maybe<String_Comparison_Exp>;
  short_url?: Maybe<String_Comparison_Exp>;
  submission_id?: Maybe<String_Comparison_Exp>;
  submission_source?: Maybe<String_Comparison_Exp>;
  template_id?: Maybe<String_Comparison_Exp>;
  template_version?: Maybe<String_Comparison_Exp>;
  transcriber_notes?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "satisfaction_surveys" */
export enum Satisfaction_Surveys_Constraint {
  /** unique or primary key constraint */
  SatisfactionSurveysPkey = 'satisfaction_surveys_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Satisfaction_Surveys_Delete_At_Path_Input = {
  ocr_output?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Satisfaction_Surveys_Delete_Elem_Input = {
  ocr_output?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Satisfaction_Surveys_Delete_Key_Input = {
  ocr_output?: Maybe<Scalars['String']>;
};

/** input type for incrementing integer column in table "satisfaction_surveys" */
export type Satisfaction_Surveys_Inc_Input = {
  network_number?: Maybe<Scalars['Int']>;
  satisfaction_score?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "satisfaction_surveys" */
export type Satisfaction_Surveys_Insert_Input = {
  care_team?: Maybe<Scalars['String']>;
  channel?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  form_language?: Maybe<Scalars['String']>;
  form_practice_id?: Maybe<Scalars['String']>;
  letter_id?: Maybe<Scalars['String']>;
  letter_id_prefix?: Maybe<Scalars['String']>;
  matched_patient_id?: Maybe<Scalars['String']>;
  network_number?: Maybe<Scalars['Int']>;
  ocr_output?: Maybe<Scalars['jsonb']>;
  onedrive_file_id?: Maybe<Scalars['String']>;
  practice_id_prefix?: Maybe<Scalars['String']>;
  processing_status?: Maybe<Scalars['String']>;
  responder_name?: Maybe<Scalars['String']>;
  satisfaction_reason?: Maybe<Scalars['String']>;
  satisfaction_score?: Maybe<Scalars['Int']>;
  satisfaction_survey_id?: Maybe<Scalars['String']>;
  short_url?: Maybe<Scalars['String']>;
  submission_id?: Maybe<Scalars['String']>;
  submission_source?: Maybe<Scalars['String']>;
  template_id?: Maybe<Scalars['String']>;
  template_version?: Maybe<Scalars['String']>;
  transcriber_notes?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Satisfaction_Surveys_Max_Fields = {
  __typename?: 'satisfaction_surveys_max_fields';
  care_team?: Maybe<Scalars['String']>;
  channel?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  form_language?: Maybe<Scalars['String']>;
  form_practice_id?: Maybe<Scalars['String']>;
  letter_id?: Maybe<Scalars['String']>;
  letter_id_prefix?: Maybe<Scalars['String']>;
  matched_patient_id?: Maybe<Scalars['String']>;
  network_number?: Maybe<Scalars['Int']>;
  onedrive_file_id?: Maybe<Scalars['String']>;
  practice_id_prefix?: Maybe<Scalars['String']>;
  processing_status?: Maybe<Scalars['String']>;
  responder_name?: Maybe<Scalars['String']>;
  satisfaction_reason?: Maybe<Scalars['String']>;
  satisfaction_score?: Maybe<Scalars['Int']>;
  satisfaction_survey_id?: Maybe<Scalars['String']>;
  short_url?: Maybe<Scalars['String']>;
  submission_id?: Maybe<Scalars['String']>;
  submission_source?: Maybe<Scalars['String']>;
  template_id?: Maybe<Scalars['String']>;
  template_version?: Maybe<Scalars['String']>;
  transcriber_notes?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "satisfaction_surveys" */
export type Satisfaction_Surveys_Max_Order_By = {
  care_team?: Maybe<Order_By>;
  channel?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  form_language?: Maybe<Order_By>;
  form_practice_id?: Maybe<Order_By>;
  letter_id?: Maybe<Order_By>;
  letter_id_prefix?: Maybe<Order_By>;
  matched_patient_id?: Maybe<Order_By>;
  network_number?: Maybe<Order_By>;
  onedrive_file_id?: Maybe<Order_By>;
  practice_id_prefix?: Maybe<Order_By>;
  processing_status?: Maybe<Order_By>;
  responder_name?: Maybe<Order_By>;
  satisfaction_reason?: Maybe<Order_By>;
  satisfaction_score?: Maybe<Order_By>;
  satisfaction_survey_id?: Maybe<Order_By>;
  short_url?: Maybe<Order_By>;
  submission_id?: Maybe<Order_By>;
  submission_source?: Maybe<Order_By>;
  template_id?: Maybe<Order_By>;
  template_version?: Maybe<Order_By>;
  transcriber_notes?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Satisfaction_Surveys_Min_Fields = {
  __typename?: 'satisfaction_surveys_min_fields';
  care_team?: Maybe<Scalars['String']>;
  channel?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  form_language?: Maybe<Scalars['String']>;
  form_practice_id?: Maybe<Scalars['String']>;
  letter_id?: Maybe<Scalars['String']>;
  letter_id_prefix?: Maybe<Scalars['String']>;
  matched_patient_id?: Maybe<Scalars['String']>;
  network_number?: Maybe<Scalars['Int']>;
  onedrive_file_id?: Maybe<Scalars['String']>;
  practice_id_prefix?: Maybe<Scalars['String']>;
  processing_status?: Maybe<Scalars['String']>;
  responder_name?: Maybe<Scalars['String']>;
  satisfaction_reason?: Maybe<Scalars['String']>;
  satisfaction_score?: Maybe<Scalars['Int']>;
  satisfaction_survey_id?: Maybe<Scalars['String']>;
  short_url?: Maybe<Scalars['String']>;
  submission_id?: Maybe<Scalars['String']>;
  submission_source?: Maybe<Scalars['String']>;
  template_id?: Maybe<Scalars['String']>;
  template_version?: Maybe<Scalars['String']>;
  transcriber_notes?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "satisfaction_surveys" */
export type Satisfaction_Surveys_Min_Order_By = {
  care_team?: Maybe<Order_By>;
  channel?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  form_language?: Maybe<Order_By>;
  form_practice_id?: Maybe<Order_By>;
  letter_id?: Maybe<Order_By>;
  letter_id_prefix?: Maybe<Order_By>;
  matched_patient_id?: Maybe<Order_By>;
  network_number?: Maybe<Order_By>;
  onedrive_file_id?: Maybe<Order_By>;
  practice_id_prefix?: Maybe<Order_By>;
  processing_status?: Maybe<Order_By>;
  responder_name?: Maybe<Order_By>;
  satisfaction_reason?: Maybe<Order_By>;
  satisfaction_score?: Maybe<Order_By>;
  satisfaction_survey_id?: Maybe<Order_By>;
  short_url?: Maybe<Order_By>;
  submission_id?: Maybe<Order_By>;
  submission_source?: Maybe<Order_By>;
  template_id?: Maybe<Order_By>;
  template_version?: Maybe<Order_By>;
  transcriber_notes?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "satisfaction_surveys" */
export type Satisfaction_Surveys_Mutation_Response = {
  __typename?: 'satisfaction_surveys_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Satisfaction_Surveys>;
};

/** input type for inserting object relation for remote table "satisfaction_surveys" */
export type Satisfaction_Surveys_Obj_Rel_Insert_Input = {
  data: Satisfaction_Surveys_Insert_Input;
  on_conflict?: Maybe<Satisfaction_Surveys_On_Conflict>;
};

/** on conflict condition type for table "satisfaction_surveys" */
export type Satisfaction_Surveys_On_Conflict = {
  constraint: Satisfaction_Surveys_Constraint;
  update_columns: Array<Satisfaction_Surveys_Update_Column>;
  where?: Maybe<Satisfaction_Surveys_Bool_Exp>;
};

/** ordering options when selecting data from "satisfaction_surveys" */
export type Satisfaction_Surveys_Order_By = {
  care_team?: Maybe<Order_By>;
  channel?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  form_language?: Maybe<Order_By>;
  form_practice_id?: Maybe<Order_By>;
  letter_id?: Maybe<Order_By>;
  letter_id_prefix?: Maybe<Order_By>;
  matched_patient_id?: Maybe<Order_By>;
  network_number?: Maybe<Order_By>;
  ocr_output?: Maybe<Order_By>;
  onedrive_file_id?: Maybe<Order_By>;
  practice_id_prefix?: Maybe<Order_By>;
  processing_status?: Maybe<Order_By>;
  responder_name?: Maybe<Order_By>;
  satisfaction_reason?: Maybe<Order_By>;
  satisfaction_score?: Maybe<Order_By>;
  satisfaction_survey_id?: Maybe<Order_By>;
  short_url?: Maybe<Order_By>;
  submission_id?: Maybe<Order_By>;
  submission_source?: Maybe<Order_By>;
  template_id?: Maybe<Order_By>;
  template_version?: Maybe<Order_By>;
  transcriber_notes?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: "satisfaction_surveys" */
export type Satisfaction_Surveys_Pk_Columns_Input = {
  satisfaction_survey_id: Scalars['String'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Satisfaction_Surveys_Prepend_Input = {
  ocr_output?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "satisfaction_surveys" */
export enum Satisfaction_Surveys_Select_Column {
  /** column name */
  CareTeam = 'care_team',
  /** column name */
  Channel = 'channel',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FormLanguage = 'form_language',
  /** column name */
  FormPracticeId = 'form_practice_id',
  /** column name */
  LetterId = 'letter_id',
  /** column name */
  LetterIdPrefix = 'letter_id_prefix',
  /** column name */
  MatchedPatientId = 'matched_patient_id',
  /** column name */
  NetworkNumber = 'network_number',
  /** column name */
  OcrOutput = 'ocr_output',
  /** column name */
  OnedriveFileId = 'onedrive_file_id',
  /** column name */
  PracticeIdPrefix = 'practice_id_prefix',
  /** column name */
  ProcessingStatus = 'processing_status',
  /** column name */
  ResponderName = 'responder_name',
  /** column name */
  SatisfactionReason = 'satisfaction_reason',
  /** column name */
  SatisfactionScore = 'satisfaction_score',
  /** column name */
  SatisfactionSurveyId = 'satisfaction_survey_id',
  /** column name */
  ShortUrl = 'short_url',
  /** column name */
  SubmissionId = 'submission_id',
  /** column name */
  SubmissionSource = 'submission_source',
  /** column name */
  TemplateId = 'template_id',
  /** column name */
  TemplateVersion = 'template_version',
  /** column name */
  TranscriberNotes = 'transcriber_notes',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "satisfaction_surveys" */
export type Satisfaction_Surveys_Set_Input = {
  care_team?: Maybe<Scalars['String']>;
  channel?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  form_language?: Maybe<Scalars['String']>;
  form_practice_id?: Maybe<Scalars['String']>;
  letter_id?: Maybe<Scalars['String']>;
  letter_id_prefix?: Maybe<Scalars['String']>;
  matched_patient_id?: Maybe<Scalars['String']>;
  network_number?: Maybe<Scalars['Int']>;
  ocr_output?: Maybe<Scalars['jsonb']>;
  onedrive_file_id?: Maybe<Scalars['String']>;
  practice_id_prefix?: Maybe<Scalars['String']>;
  processing_status?: Maybe<Scalars['String']>;
  responder_name?: Maybe<Scalars['String']>;
  satisfaction_reason?: Maybe<Scalars['String']>;
  satisfaction_score?: Maybe<Scalars['Int']>;
  satisfaction_survey_id?: Maybe<Scalars['String']>;
  short_url?: Maybe<Scalars['String']>;
  submission_id?: Maybe<Scalars['String']>;
  submission_source?: Maybe<Scalars['String']>;
  template_id?: Maybe<Scalars['String']>;
  template_version?: Maybe<Scalars['String']>;
  transcriber_notes?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Satisfaction_Surveys_Stddev_Fields = {
  __typename?: 'satisfaction_surveys_stddev_fields';
  network_number?: Maybe<Scalars['Float']>;
  satisfaction_score?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "satisfaction_surveys" */
export type Satisfaction_Surveys_Stddev_Order_By = {
  network_number?: Maybe<Order_By>;
  satisfaction_score?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Satisfaction_Surveys_Stddev_Pop_Fields = {
  __typename?: 'satisfaction_surveys_stddev_pop_fields';
  network_number?: Maybe<Scalars['Float']>;
  satisfaction_score?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "satisfaction_surveys" */
export type Satisfaction_Surveys_Stddev_Pop_Order_By = {
  network_number?: Maybe<Order_By>;
  satisfaction_score?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Satisfaction_Surveys_Stddev_Samp_Fields = {
  __typename?: 'satisfaction_surveys_stddev_samp_fields';
  network_number?: Maybe<Scalars['Float']>;
  satisfaction_score?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "satisfaction_surveys" */
export type Satisfaction_Surveys_Stddev_Samp_Order_By = {
  network_number?: Maybe<Order_By>;
  satisfaction_score?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Satisfaction_Surveys_Sum_Fields = {
  __typename?: 'satisfaction_surveys_sum_fields';
  network_number?: Maybe<Scalars['Int']>;
  satisfaction_score?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "satisfaction_surveys" */
export type Satisfaction_Surveys_Sum_Order_By = {
  network_number?: Maybe<Order_By>;
  satisfaction_score?: Maybe<Order_By>;
};

/** update columns of table "satisfaction_surveys" */
export enum Satisfaction_Surveys_Update_Column {
  /** column name */
  CareTeam = 'care_team',
  /** column name */
  Channel = 'channel',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FormLanguage = 'form_language',
  /** column name */
  FormPracticeId = 'form_practice_id',
  /** column name */
  LetterId = 'letter_id',
  /** column name */
  LetterIdPrefix = 'letter_id_prefix',
  /** column name */
  MatchedPatientId = 'matched_patient_id',
  /** column name */
  NetworkNumber = 'network_number',
  /** column name */
  OcrOutput = 'ocr_output',
  /** column name */
  OnedriveFileId = 'onedrive_file_id',
  /** column name */
  PracticeIdPrefix = 'practice_id_prefix',
  /** column name */
  ProcessingStatus = 'processing_status',
  /** column name */
  ResponderName = 'responder_name',
  /** column name */
  SatisfactionReason = 'satisfaction_reason',
  /** column name */
  SatisfactionScore = 'satisfaction_score',
  /** column name */
  SatisfactionSurveyId = 'satisfaction_survey_id',
  /** column name */
  ShortUrl = 'short_url',
  /** column name */
  SubmissionId = 'submission_id',
  /** column name */
  SubmissionSource = 'submission_source',
  /** column name */
  TemplateId = 'template_id',
  /** column name */
  TemplateVersion = 'template_version',
  /** column name */
  TranscriberNotes = 'transcriber_notes',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Satisfaction_Surveys_Var_Pop_Fields = {
  __typename?: 'satisfaction_surveys_var_pop_fields';
  network_number?: Maybe<Scalars['Float']>;
  satisfaction_score?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "satisfaction_surveys" */
export type Satisfaction_Surveys_Var_Pop_Order_By = {
  network_number?: Maybe<Order_By>;
  satisfaction_score?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Satisfaction_Surveys_Var_Samp_Fields = {
  __typename?: 'satisfaction_surveys_var_samp_fields';
  network_number?: Maybe<Scalars['Float']>;
  satisfaction_score?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "satisfaction_surveys" */
export type Satisfaction_Surveys_Var_Samp_Order_By = {
  network_number?: Maybe<Order_By>;
  satisfaction_score?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Satisfaction_Surveys_Variance_Fields = {
  __typename?: 'satisfaction_surveys_variance_fields';
  network_number?: Maybe<Scalars['Float']>;
  satisfaction_score?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "satisfaction_surveys" */
export type Satisfaction_Surveys_Variance_Order_By = {
  network_number?: Maybe<Order_By>;
  satisfaction_score?: Maybe<Order_By>;
};

/** columns and relationships of "scheduled_events" */
export type Scheduled_Events = {
  __typename?: 'scheduled_events';
  comment?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  next_retry_at?: Maybe<Scalars['timestamptz']>;
  payload?: Maybe<Scalars['json']>;
  scheduled_event_id?: Maybe<Scalars['String']>;
  scheduled_time?: Maybe<Scalars['timestamptz']>;
  status?: Maybe<Scalars['String']>;
  tries?: Maybe<Scalars['Int']>;
  webhook_conf?: Maybe<Scalars['json']>;
};


/** columns and relationships of "scheduled_events" */
export type Scheduled_EventsPayloadArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "scheduled_events" */
export type Scheduled_EventsWebhook_ConfArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "scheduled_events" */
export type Scheduled_Events_Aggregate = {
  __typename?: 'scheduled_events_aggregate';
  aggregate?: Maybe<Scheduled_Events_Aggregate_Fields>;
  nodes: Array<Scheduled_Events>;
};

/** aggregate fields of "scheduled_events" */
export type Scheduled_Events_Aggregate_Fields = {
  __typename?: 'scheduled_events_aggregate_fields';
  avg?: Maybe<Scheduled_Events_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Scheduled_Events_Max_Fields>;
  min?: Maybe<Scheduled_Events_Min_Fields>;
  stddev?: Maybe<Scheduled_Events_Stddev_Fields>;
  stddev_pop?: Maybe<Scheduled_Events_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Scheduled_Events_Stddev_Samp_Fields>;
  sum?: Maybe<Scheduled_Events_Sum_Fields>;
  var_pop?: Maybe<Scheduled_Events_Var_Pop_Fields>;
  var_samp?: Maybe<Scheduled_Events_Var_Samp_Fields>;
  variance?: Maybe<Scheduled_Events_Variance_Fields>;
};


/** aggregate fields of "scheduled_events" */
export type Scheduled_Events_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Scheduled_Events_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "scheduled_events" */
export type Scheduled_Events_Aggregate_Order_By = {
  avg?: Maybe<Scheduled_Events_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Scheduled_Events_Max_Order_By>;
  min?: Maybe<Scheduled_Events_Min_Order_By>;
  stddev?: Maybe<Scheduled_Events_Stddev_Order_By>;
  stddev_pop?: Maybe<Scheduled_Events_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Scheduled_Events_Stddev_Samp_Order_By>;
  sum?: Maybe<Scheduled_Events_Sum_Order_By>;
  var_pop?: Maybe<Scheduled_Events_Var_Pop_Order_By>;
  var_samp?: Maybe<Scheduled_Events_Var_Samp_Order_By>;
  variance?: Maybe<Scheduled_Events_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "scheduled_events" */
export type Scheduled_Events_Arr_Rel_Insert_Input = {
  data: Array<Scheduled_Events_Insert_Input>;
};

/** aggregate avg on columns */
export type Scheduled_Events_Avg_Fields = {
  __typename?: 'scheduled_events_avg_fields';
  tries?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "scheduled_events" */
export type Scheduled_Events_Avg_Order_By = {
  tries?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "scheduled_events". All fields are combined with a logical 'AND'. */
export type Scheduled_Events_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Scheduled_Events_Bool_Exp>>>;
  _not?: Maybe<Scheduled_Events_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Scheduled_Events_Bool_Exp>>>;
  comment?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  next_retry_at?: Maybe<Timestamptz_Comparison_Exp>;
  payload?: Maybe<Json_Comparison_Exp>;
  scheduled_event_id?: Maybe<String_Comparison_Exp>;
  scheduled_time?: Maybe<Timestamptz_Comparison_Exp>;
  status?: Maybe<String_Comparison_Exp>;
  tries?: Maybe<Int_Comparison_Exp>;
  webhook_conf?: Maybe<Json_Comparison_Exp>;
};

/** input type for incrementing integer column in table "scheduled_events" */
export type Scheduled_Events_Inc_Input = {
  tries?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "scheduled_events" */
export type Scheduled_Events_Insert_Input = {
  comment?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  next_retry_at?: Maybe<Scalars['timestamptz']>;
  payload?: Maybe<Scalars['json']>;
  scheduled_event_id?: Maybe<Scalars['String']>;
  scheduled_time?: Maybe<Scalars['timestamptz']>;
  status?: Maybe<Scalars['String']>;
  tries?: Maybe<Scalars['Int']>;
  webhook_conf?: Maybe<Scalars['json']>;
};

/** aggregate max on columns */
export type Scheduled_Events_Max_Fields = {
  __typename?: 'scheduled_events_max_fields';
  comment?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  next_retry_at?: Maybe<Scalars['timestamptz']>;
  scheduled_event_id?: Maybe<Scalars['String']>;
  scheduled_time?: Maybe<Scalars['timestamptz']>;
  status?: Maybe<Scalars['String']>;
  tries?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "scheduled_events" */
export type Scheduled_Events_Max_Order_By = {
  comment?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  next_retry_at?: Maybe<Order_By>;
  scheduled_event_id?: Maybe<Order_By>;
  scheduled_time?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  tries?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Scheduled_Events_Min_Fields = {
  __typename?: 'scheduled_events_min_fields';
  comment?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  next_retry_at?: Maybe<Scalars['timestamptz']>;
  scheduled_event_id?: Maybe<Scalars['String']>;
  scheduled_time?: Maybe<Scalars['timestamptz']>;
  status?: Maybe<Scalars['String']>;
  tries?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "scheduled_events" */
export type Scheduled_Events_Min_Order_By = {
  comment?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  next_retry_at?: Maybe<Order_By>;
  scheduled_event_id?: Maybe<Order_By>;
  scheduled_time?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  tries?: Maybe<Order_By>;
};

/** response of any mutation on the table "scheduled_events" */
export type Scheduled_Events_Mutation_Response = {
  __typename?: 'scheduled_events_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Scheduled_Events>;
};

/** input type for inserting object relation for remote table "scheduled_events" */
export type Scheduled_Events_Obj_Rel_Insert_Input = {
  data: Scheduled_Events_Insert_Input;
};

/** ordering options when selecting data from "scheduled_events" */
export type Scheduled_Events_Order_By = {
  comment?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  next_retry_at?: Maybe<Order_By>;
  payload?: Maybe<Order_By>;
  scheduled_event_id?: Maybe<Order_By>;
  scheduled_time?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  tries?: Maybe<Order_By>;
  webhook_conf?: Maybe<Order_By>;
};

/** select columns of table "scheduled_events" */
export enum Scheduled_Events_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  NextRetryAt = 'next_retry_at',
  /** column name */
  Payload = 'payload',
  /** column name */
  ScheduledEventId = 'scheduled_event_id',
  /** column name */
  ScheduledTime = 'scheduled_time',
  /** column name */
  Status = 'status',
  /** column name */
  Tries = 'tries',
  /** column name */
  WebhookConf = 'webhook_conf'
}

/** input type for updating data in table "scheduled_events" */
export type Scheduled_Events_Set_Input = {
  comment?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  next_retry_at?: Maybe<Scalars['timestamptz']>;
  payload?: Maybe<Scalars['json']>;
  scheduled_event_id?: Maybe<Scalars['String']>;
  scheduled_time?: Maybe<Scalars['timestamptz']>;
  status?: Maybe<Scalars['String']>;
  tries?: Maybe<Scalars['Int']>;
  webhook_conf?: Maybe<Scalars['json']>;
};

/** aggregate stddev on columns */
export type Scheduled_Events_Stddev_Fields = {
  __typename?: 'scheduled_events_stddev_fields';
  tries?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "scheduled_events" */
export type Scheduled_Events_Stddev_Order_By = {
  tries?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Scheduled_Events_Stddev_Pop_Fields = {
  __typename?: 'scheduled_events_stddev_pop_fields';
  tries?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "scheduled_events" */
export type Scheduled_Events_Stddev_Pop_Order_By = {
  tries?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Scheduled_Events_Stddev_Samp_Fields = {
  __typename?: 'scheduled_events_stddev_samp_fields';
  tries?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "scheduled_events" */
export type Scheduled_Events_Stddev_Samp_Order_By = {
  tries?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Scheduled_Events_Sum_Fields = {
  __typename?: 'scheduled_events_sum_fields';
  tries?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "scheduled_events" */
export type Scheduled_Events_Sum_Order_By = {
  tries?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Scheduled_Events_Var_Pop_Fields = {
  __typename?: 'scheduled_events_var_pop_fields';
  tries?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "scheduled_events" */
export type Scheduled_Events_Var_Pop_Order_By = {
  tries?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Scheduled_Events_Var_Samp_Fields = {
  __typename?: 'scheduled_events_var_samp_fields';
  tries?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "scheduled_events" */
export type Scheduled_Events_Var_Samp_Order_By = {
  tries?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Scheduled_Events_Variance_Fields = {
  __typename?: 'scheduled_events_variance_fields';
  tries?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "scheduled_events" */
export type Scheduled_Events_Variance_Order_By = {
  tries?: Maybe<Order_By>;
};

export type Search_Patients_By_Hedr_Data_Args = {
  input_birth_date?: Maybe<Scalars['date']>;
  input_letter_id?: Maybe<Scalars['String']>;
  input_mbi?: Maybe<Scalars['String']>;
  input_network_number?: Maybe<Scalars['Int']>;
  input_patient_name?: Maybe<Scalars['String']>;
};

export type Search_Patients_By_Pva_Data_Args = {
  input_birth_date?: Maybe<Scalars['date']>;
  input_letter_id?: Maybe<Scalars['String']>;
  input_mbi?: Maybe<Scalars['String']>;
  input_network_number?: Maybe<Scalars['Int']>;
  input_patient_name?: Maybe<Scalars['String']>;
};


/** expression to compare columns of type smallint. All fields are combined with logical 'AND'. */
export type Smallint_Comparison_Exp = {
  _eq?: Maybe<Scalars['smallint']>;
  _gt?: Maybe<Scalars['smallint']>;
  _gte?: Maybe<Scalars['smallint']>;
  _in?: Maybe<Array<Scalars['smallint']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['smallint']>;
  _lte?: Maybe<Scalars['smallint']>;
  _neq?: Maybe<Scalars['smallint']>;
  _nin?: Maybe<Array<Scalars['smallint']>>;
};

/** columns and relationships of "sms_component_approvals" */
export type Sms_Component_Approvals = {
  __typename?: 'sms_component_approvals';
  /** An object relationship */
  campaign_approval?: Maybe<Campaign_Approvals>;
  /** An object relationship */
  campaign_sms_component: Campaign_Sms_Components;
  campaign_sms_component_id: Scalars['String'];
  campaign_template_id: Scalars['String'];
  created_at: Scalars['timestamptz'];
  is_cancelled: Scalars['Boolean'];
  practice_id: Scalars['String'];
  /** An object relationship */
  scheduled_event?: Maybe<Scheduled_Events>;
  scheduled_event_id?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "sms_component_approvals" */
export type Sms_Component_Approvals_Aggregate = {
  __typename?: 'sms_component_approvals_aggregate';
  aggregate?: Maybe<Sms_Component_Approvals_Aggregate_Fields>;
  nodes: Array<Sms_Component_Approvals>;
};

/** aggregate fields of "sms_component_approvals" */
export type Sms_Component_Approvals_Aggregate_Fields = {
  __typename?: 'sms_component_approvals_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Sms_Component_Approvals_Max_Fields>;
  min?: Maybe<Sms_Component_Approvals_Min_Fields>;
};


/** aggregate fields of "sms_component_approvals" */
export type Sms_Component_Approvals_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Sms_Component_Approvals_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "sms_component_approvals" */
export type Sms_Component_Approvals_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Sms_Component_Approvals_Max_Order_By>;
  min?: Maybe<Sms_Component_Approvals_Min_Order_By>;
};

/** input type for inserting array relation for remote table "sms_component_approvals" */
export type Sms_Component_Approvals_Arr_Rel_Insert_Input = {
  data: Array<Sms_Component_Approvals_Insert_Input>;
  on_conflict?: Maybe<Sms_Component_Approvals_On_Conflict>;
};

/** Boolean expression to filter rows from the table "sms_component_approvals". All fields are combined with a logical 'AND'. */
export type Sms_Component_Approvals_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Sms_Component_Approvals_Bool_Exp>>>;
  _not?: Maybe<Sms_Component_Approvals_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Sms_Component_Approvals_Bool_Exp>>>;
  campaign_approval?: Maybe<Campaign_Approvals_Bool_Exp>;
  campaign_sms_component?: Maybe<Campaign_Sms_Components_Bool_Exp>;
  campaign_sms_component_id?: Maybe<String_Comparison_Exp>;
  campaign_template_id?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  is_cancelled?: Maybe<Boolean_Comparison_Exp>;
  practice_id?: Maybe<String_Comparison_Exp>;
  scheduled_event?: Maybe<Scheduled_Events_Bool_Exp>;
  scheduled_event_id?: Maybe<String_Comparison_Exp>;
  status?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "sms_component_approvals" */
export enum Sms_Component_Approvals_Constraint {
  /** unique or primary key constraint */
  SmsComponentApprovalsPkey = 'sms_component_approvals_pkey'
}

/** input type for inserting data into table "sms_component_approvals" */
export type Sms_Component_Approvals_Insert_Input = {
  campaign_approval?: Maybe<Campaign_Approvals_Obj_Rel_Insert_Input>;
  campaign_sms_component?: Maybe<Campaign_Sms_Components_Obj_Rel_Insert_Input>;
  campaign_sms_component_id?: Maybe<Scalars['String']>;
  campaign_template_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  is_cancelled?: Maybe<Scalars['Boolean']>;
  practice_id?: Maybe<Scalars['String']>;
  scheduled_event?: Maybe<Scheduled_Events_Obj_Rel_Insert_Input>;
  scheduled_event_id?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Sms_Component_Approvals_Max_Fields = {
  __typename?: 'sms_component_approvals_max_fields';
  campaign_sms_component_id?: Maybe<Scalars['String']>;
  campaign_template_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  practice_id?: Maybe<Scalars['String']>;
  scheduled_event_id?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "sms_component_approvals" */
export type Sms_Component_Approvals_Max_Order_By = {
  campaign_sms_component_id?: Maybe<Order_By>;
  campaign_template_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  practice_id?: Maybe<Order_By>;
  scheduled_event_id?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Sms_Component_Approvals_Min_Fields = {
  __typename?: 'sms_component_approvals_min_fields';
  campaign_sms_component_id?: Maybe<Scalars['String']>;
  campaign_template_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  practice_id?: Maybe<Scalars['String']>;
  scheduled_event_id?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "sms_component_approvals" */
export type Sms_Component_Approvals_Min_Order_By = {
  campaign_sms_component_id?: Maybe<Order_By>;
  campaign_template_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  practice_id?: Maybe<Order_By>;
  scheduled_event_id?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "sms_component_approvals" */
export type Sms_Component_Approvals_Mutation_Response = {
  __typename?: 'sms_component_approvals_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Sms_Component_Approvals>;
};

/** input type for inserting object relation for remote table "sms_component_approvals" */
export type Sms_Component_Approvals_Obj_Rel_Insert_Input = {
  data: Sms_Component_Approvals_Insert_Input;
  on_conflict?: Maybe<Sms_Component_Approvals_On_Conflict>;
};

/** on conflict condition type for table "sms_component_approvals" */
export type Sms_Component_Approvals_On_Conflict = {
  constraint: Sms_Component_Approvals_Constraint;
  update_columns: Array<Sms_Component_Approvals_Update_Column>;
  where?: Maybe<Sms_Component_Approvals_Bool_Exp>;
};

/** ordering options when selecting data from "sms_component_approvals" */
export type Sms_Component_Approvals_Order_By = {
  campaign_approval?: Maybe<Campaign_Approvals_Order_By>;
  campaign_sms_component?: Maybe<Campaign_Sms_Components_Order_By>;
  campaign_sms_component_id?: Maybe<Order_By>;
  campaign_template_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  is_cancelled?: Maybe<Order_By>;
  practice_id?: Maybe<Order_By>;
  scheduled_event?: Maybe<Scheduled_Events_Order_By>;
  scheduled_event_id?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: "sms_component_approvals" */
export type Sms_Component_Approvals_Pk_Columns_Input = {
  campaign_sms_component_id: Scalars['String'];
  campaign_template_id: Scalars['String'];
  practice_id: Scalars['String'];
};

/** select columns of table "sms_component_approvals" */
export enum Sms_Component_Approvals_Select_Column {
  /** column name */
  CampaignSmsComponentId = 'campaign_sms_component_id',
  /** column name */
  CampaignTemplateId = 'campaign_template_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  IsCancelled = 'is_cancelled',
  /** column name */
  PracticeId = 'practice_id',
  /** column name */
  ScheduledEventId = 'scheduled_event_id',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "sms_component_approvals" */
export type Sms_Component_Approvals_Set_Input = {
  campaign_sms_component_id?: Maybe<Scalars['String']>;
  campaign_template_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  is_cancelled?: Maybe<Scalars['Boolean']>;
  practice_id?: Maybe<Scalars['String']>;
  scheduled_event_id?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "sms_component_approvals" */
export enum Sms_Component_Approvals_Update_Column {
  /** column name */
  CampaignSmsComponentId = 'campaign_sms_component_id',
  /** column name */
  CampaignTemplateId = 'campaign_template_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  IsCancelled = 'is_cancelled',
  /** column name */
  PracticeId = 'practice_id',
  /** column name */
  ScheduledEventId = 'scheduled_event_id',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** columns and relationships of "sms_messages" */
export type Sms_Messages = {
  __typename?: 'sms_messages';
  /** An object relationship */
  campaign_sms_component?: Maybe<Campaign_Sms_Components>;
  campaign_sms_component_id: Scalars['String'];
  created_at: Scalars['timestamptz'];
  external_id?: Maybe<Scalars['String']>;
  is_archived: Scalars['Boolean'];
  /** An object relationship */
  patient: Patients;
  patient_id: Scalars['String'];
  /** An object relationship */
  practice: Practices;
  practice_id: Scalars['String'];
  send_datetime: Scalars['timestamptz'];
  sms_message_id: Scalars['String'];
  status: Scalars['String'];
  tracking_events: Scalars['jsonb'];
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "sms_messages" */
export type Sms_MessagesTracking_EventsArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "sms_messages" */
export type Sms_Messages_Aggregate = {
  __typename?: 'sms_messages_aggregate';
  aggregate?: Maybe<Sms_Messages_Aggregate_Fields>;
  nodes: Array<Sms_Messages>;
};

/** aggregate fields of "sms_messages" */
export type Sms_Messages_Aggregate_Fields = {
  __typename?: 'sms_messages_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Sms_Messages_Max_Fields>;
  min?: Maybe<Sms_Messages_Min_Fields>;
};


/** aggregate fields of "sms_messages" */
export type Sms_Messages_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Sms_Messages_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "sms_messages" */
export type Sms_Messages_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Sms_Messages_Max_Order_By>;
  min?: Maybe<Sms_Messages_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Sms_Messages_Append_Input = {
  tracking_events?: Maybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "sms_messages" */
export type Sms_Messages_Arr_Rel_Insert_Input = {
  data: Array<Sms_Messages_Insert_Input>;
  on_conflict?: Maybe<Sms_Messages_On_Conflict>;
};

/** Boolean expression to filter rows from the table "sms_messages". All fields are combined with a logical 'AND'. */
export type Sms_Messages_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Sms_Messages_Bool_Exp>>>;
  _not?: Maybe<Sms_Messages_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Sms_Messages_Bool_Exp>>>;
  campaign_sms_component?: Maybe<Campaign_Sms_Components_Bool_Exp>;
  campaign_sms_component_id?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  external_id?: Maybe<String_Comparison_Exp>;
  is_archived?: Maybe<Boolean_Comparison_Exp>;
  patient?: Maybe<Patients_Bool_Exp>;
  patient_id?: Maybe<String_Comparison_Exp>;
  practice?: Maybe<Practices_Bool_Exp>;
  practice_id?: Maybe<String_Comparison_Exp>;
  send_datetime?: Maybe<Timestamptz_Comparison_Exp>;
  sms_message_id?: Maybe<String_Comparison_Exp>;
  status?: Maybe<String_Comparison_Exp>;
  tracking_events?: Maybe<Jsonb_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "sms_messages" */
export enum Sms_Messages_Constraint {
  /** unique or primary key constraint */
  SmsMessagesExternalIdKey = 'sms_messages_external_id_key',
  /** unique or primary key constraint */
  SmsMessagesPatientIdPracticeIdCampaignSmsComponentKey = 'sms_messages_patient_id_practice_id_campaign_sms_component__key',
  /** unique or primary key constraint */
  SmsMessagesPkey = 'sms_messages_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Sms_Messages_Delete_At_Path_Input = {
  tracking_events?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Sms_Messages_Delete_Elem_Input = {
  tracking_events?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Sms_Messages_Delete_Key_Input = {
  tracking_events?: Maybe<Scalars['String']>;
};

/** input type for inserting data into table "sms_messages" */
export type Sms_Messages_Insert_Input = {
  campaign_sms_component?: Maybe<Campaign_Sms_Components_Obj_Rel_Insert_Input>;
  campaign_sms_component_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  external_id?: Maybe<Scalars['String']>;
  is_archived?: Maybe<Scalars['Boolean']>;
  patient?: Maybe<Patients_Obj_Rel_Insert_Input>;
  patient_id?: Maybe<Scalars['String']>;
  practice?: Maybe<Practices_Obj_Rel_Insert_Input>;
  practice_id?: Maybe<Scalars['String']>;
  send_datetime?: Maybe<Scalars['timestamptz']>;
  sms_message_id?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  tracking_events?: Maybe<Scalars['jsonb']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Sms_Messages_Max_Fields = {
  __typename?: 'sms_messages_max_fields';
  campaign_sms_component_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  external_id?: Maybe<Scalars['String']>;
  patient_id?: Maybe<Scalars['String']>;
  practice_id?: Maybe<Scalars['String']>;
  send_datetime?: Maybe<Scalars['timestamptz']>;
  sms_message_id?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "sms_messages" */
export type Sms_Messages_Max_Order_By = {
  campaign_sms_component_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  external_id?: Maybe<Order_By>;
  patient_id?: Maybe<Order_By>;
  practice_id?: Maybe<Order_By>;
  send_datetime?: Maybe<Order_By>;
  sms_message_id?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Sms_Messages_Min_Fields = {
  __typename?: 'sms_messages_min_fields';
  campaign_sms_component_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  external_id?: Maybe<Scalars['String']>;
  patient_id?: Maybe<Scalars['String']>;
  practice_id?: Maybe<Scalars['String']>;
  send_datetime?: Maybe<Scalars['timestamptz']>;
  sms_message_id?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "sms_messages" */
export type Sms_Messages_Min_Order_By = {
  campaign_sms_component_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  external_id?: Maybe<Order_By>;
  patient_id?: Maybe<Order_By>;
  practice_id?: Maybe<Order_By>;
  send_datetime?: Maybe<Order_By>;
  sms_message_id?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "sms_messages" */
export type Sms_Messages_Mutation_Response = {
  __typename?: 'sms_messages_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Sms_Messages>;
};

/** input type for inserting object relation for remote table "sms_messages" */
export type Sms_Messages_Obj_Rel_Insert_Input = {
  data: Sms_Messages_Insert_Input;
  on_conflict?: Maybe<Sms_Messages_On_Conflict>;
};

/** on conflict condition type for table "sms_messages" */
export type Sms_Messages_On_Conflict = {
  constraint: Sms_Messages_Constraint;
  update_columns: Array<Sms_Messages_Update_Column>;
  where?: Maybe<Sms_Messages_Bool_Exp>;
};

/** ordering options when selecting data from "sms_messages" */
export type Sms_Messages_Order_By = {
  campaign_sms_component?: Maybe<Campaign_Sms_Components_Order_By>;
  campaign_sms_component_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  external_id?: Maybe<Order_By>;
  is_archived?: Maybe<Order_By>;
  patient?: Maybe<Patients_Order_By>;
  patient_id?: Maybe<Order_By>;
  practice?: Maybe<Practices_Order_By>;
  practice_id?: Maybe<Order_By>;
  send_datetime?: Maybe<Order_By>;
  sms_message_id?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  tracking_events?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: "sms_messages" */
export type Sms_Messages_Pk_Columns_Input = {
  sms_message_id: Scalars['String'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Sms_Messages_Prepend_Input = {
  tracking_events?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "sms_messages" */
export enum Sms_Messages_Select_Column {
  /** column name */
  CampaignSmsComponentId = 'campaign_sms_component_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ExternalId = 'external_id',
  /** column name */
  IsArchived = 'is_archived',
  /** column name */
  PatientId = 'patient_id',
  /** column name */
  PracticeId = 'practice_id',
  /** column name */
  SendDatetime = 'send_datetime',
  /** column name */
  SmsMessageId = 'sms_message_id',
  /** column name */
  Status = 'status',
  /** column name */
  TrackingEvents = 'tracking_events',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "sms_messages" */
export type Sms_Messages_Set_Input = {
  campaign_sms_component_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  external_id?: Maybe<Scalars['String']>;
  is_archived?: Maybe<Scalars['Boolean']>;
  patient_id?: Maybe<Scalars['String']>;
  practice_id?: Maybe<Scalars['String']>;
  send_datetime?: Maybe<Scalars['timestamptz']>;
  sms_message_id?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  tracking_events?: Maybe<Scalars['jsonb']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "sms_messages" */
export enum Sms_Messages_Update_Column {
  /** column name */
  CampaignSmsComponentId = 'campaign_sms_component_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ExternalId = 'external_id',
  /** column name */
  IsArchived = 'is_archived',
  /** column name */
  PatientId = 'patient_id',
  /** column name */
  PracticeId = 'practice_id',
  /** column name */
  SendDatetime = 'send_datetime',
  /** column name */
  SmsMessageId = 'sms_message_id',
  /** column name */
  Status = 'status',
  /** column name */
  TrackingEvents = 'tracking_events',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** columns and relationships of "sms_templates" */
export type Sms_Templates = {
  __typename?: 'sms_templates';
  body_text: Scalars['String'];
  created_at: Scalars['timestamp'];
  default_merge_vars: Scalars['jsonb'];
  is_archived: Scalars['Boolean'];
  sms_template_description?: Maybe<Scalars['String']>;
  sms_template_id: Scalars['String'];
  sms_template_name?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
  webform_url?: Maybe<Scalars['String']>;
};


/** columns and relationships of "sms_templates" */
export type Sms_TemplatesDefault_Merge_VarsArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "sms_templates" */
export type Sms_Templates_Aggregate = {
  __typename?: 'sms_templates_aggregate';
  aggregate?: Maybe<Sms_Templates_Aggregate_Fields>;
  nodes: Array<Sms_Templates>;
};

/** aggregate fields of "sms_templates" */
export type Sms_Templates_Aggregate_Fields = {
  __typename?: 'sms_templates_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Sms_Templates_Max_Fields>;
  min?: Maybe<Sms_Templates_Min_Fields>;
};


/** aggregate fields of "sms_templates" */
export type Sms_Templates_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Sms_Templates_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "sms_templates" */
export type Sms_Templates_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Sms_Templates_Max_Order_By>;
  min?: Maybe<Sms_Templates_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Sms_Templates_Append_Input = {
  default_merge_vars?: Maybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "sms_templates" */
export type Sms_Templates_Arr_Rel_Insert_Input = {
  data: Array<Sms_Templates_Insert_Input>;
  on_conflict?: Maybe<Sms_Templates_On_Conflict>;
};

/** Boolean expression to filter rows from the table "sms_templates". All fields are combined with a logical 'AND'. */
export type Sms_Templates_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Sms_Templates_Bool_Exp>>>;
  _not?: Maybe<Sms_Templates_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Sms_Templates_Bool_Exp>>>;
  body_text?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamp_Comparison_Exp>;
  default_merge_vars?: Maybe<Jsonb_Comparison_Exp>;
  is_archived?: Maybe<Boolean_Comparison_Exp>;
  sms_template_description?: Maybe<String_Comparison_Exp>;
  sms_template_id?: Maybe<String_Comparison_Exp>;
  sms_template_name?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  webform_url?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "sms_templates" */
export enum Sms_Templates_Constraint {
  /** unique or primary key constraint */
  SmsTemplatesPkey = 'sms_templates_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Sms_Templates_Delete_At_Path_Input = {
  default_merge_vars?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Sms_Templates_Delete_Elem_Input = {
  default_merge_vars?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Sms_Templates_Delete_Key_Input = {
  default_merge_vars?: Maybe<Scalars['String']>;
};

/** input type for inserting data into table "sms_templates" */
export type Sms_Templates_Insert_Input = {
  body_text?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamp']>;
  default_merge_vars?: Maybe<Scalars['jsonb']>;
  is_archived?: Maybe<Scalars['Boolean']>;
  sms_template_description?: Maybe<Scalars['String']>;
  sms_template_id?: Maybe<Scalars['String']>;
  sms_template_name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  webform_url?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Sms_Templates_Max_Fields = {
  __typename?: 'sms_templates_max_fields';
  body_text?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamp']>;
  sms_template_description?: Maybe<Scalars['String']>;
  sms_template_id?: Maybe<Scalars['String']>;
  sms_template_name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  webform_url?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "sms_templates" */
export type Sms_Templates_Max_Order_By = {
  body_text?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  sms_template_description?: Maybe<Order_By>;
  sms_template_id?: Maybe<Order_By>;
  sms_template_name?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  webform_url?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Sms_Templates_Min_Fields = {
  __typename?: 'sms_templates_min_fields';
  body_text?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamp']>;
  sms_template_description?: Maybe<Scalars['String']>;
  sms_template_id?: Maybe<Scalars['String']>;
  sms_template_name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  webform_url?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "sms_templates" */
export type Sms_Templates_Min_Order_By = {
  body_text?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  sms_template_description?: Maybe<Order_By>;
  sms_template_id?: Maybe<Order_By>;
  sms_template_name?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  webform_url?: Maybe<Order_By>;
};

/** response of any mutation on the table "sms_templates" */
export type Sms_Templates_Mutation_Response = {
  __typename?: 'sms_templates_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Sms_Templates>;
};

/** input type for inserting object relation for remote table "sms_templates" */
export type Sms_Templates_Obj_Rel_Insert_Input = {
  data: Sms_Templates_Insert_Input;
  on_conflict?: Maybe<Sms_Templates_On_Conflict>;
};

/** on conflict condition type for table "sms_templates" */
export type Sms_Templates_On_Conflict = {
  constraint: Sms_Templates_Constraint;
  update_columns: Array<Sms_Templates_Update_Column>;
  where?: Maybe<Sms_Templates_Bool_Exp>;
};

/** ordering options when selecting data from "sms_templates" */
export type Sms_Templates_Order_By = {
  body_text?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  default_merge_vars?: Maybe<Order_By>;
  is_archived?: Maybe<Order_By>;
  sms_template_description?: Maybe<Order_By>;
  sms_template_id?: Maybe<Order_By>;
  sms_template_name?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  webform_url?: Maybe<Order_By>;
};

/** primary key columns input for table: "sms_templates" */
export type Sms_Templates_Pk_Columns_Input = {
  sms_template_id: Scalars['String'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Sms_Templates_Prepend_Input = {
  default_merge_vars?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "sms_templates" */
export enum Sms_Templates_Select_Column {
  /** column name */
  BodyText = 'body_text',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DefaultMergeVars = 'default_merge_vars',
  /** column name */
  IsArchived = 'is_archived',
  /** column name */
  SmsTemplateDescription = 'sms_template_description',
  /** column name */
  SmsTemplateId = 'sms_template_id',
  /** column name */
  SmsTemplateName = 'sms_template_name',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  WebformUrl = 'webform_url'
}

/** input type for updating data in table "sms_templates" */
export type Sms_Templates_Set_Input = {
  body_text?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamp']>;
  default_merge_vars?: Maybe<Scalars['jsonb']>;
  is_archived?: Maybe<Scalars['Boolean']>;
  sms_template_description?: Maybe<Scalars['String']>;
  sms_template_id?: Maybe<Scalars['String']>;
  sms_template_name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  webform_url?: Maybe<Scalars['String']>;
};

/** update columns of table "sms_templates" */
export enum Sms_Templates_Update_Column {
  /** column name */
  BodyText = 'body_text',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DefaultMergeVars = 'default_merge_vars',
  /** column name */
  IsArchived = 'is_archived',
  /** column name */
  SmsTemplateDescription = 'sms_template_description',
  /** column name */
  SmsTemplateId = 'sms_template_id',
  /** column name */
  SmsTemplateName = 'sms_template_name',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  WebformUrl = 'webform_url'
}

/** subscription root */
export type Subscription_Root = {
  __typename?: 'subscription_root';
  /** fetch data from the table: "account_creation_requests" */
  account_creation_requests: Array<Account_Creation_Requests>;
  /** fetch aggregated fields from the table: "account_creation_requests" */
  account_creation_requests_aggregate: Account_Creation_Requests_Aggregate;
  /** fetch data from the table: "account_creation_requests" using primary key columns */
  account_creation_requests_by_pk?: Maybe<Account_Creation_Requests>;
  /** fetch data from the table: "alignments" */
  alignments: Array<Alignments>;
  /** fetch aggregated fields from the table: "alignments" */
  alignments_aggregate: Alignments_Aggregate;
  /** fetch data from the table: "alignments" using primary key columns */
  alignments_by_pk?: Maybe<Alignments>;
  /** fetch data from the table: "analytics.analytics_cms_active_dce_participants" */
  analytics_analytics_cms_active_dce_participants: Array<Analytics_Analytics_Cms_Active_Dce_Participants>;
  /** fetch aggregated fields from the table: "analytics.analytics_cms_active_dce_participants" */
  analytics_analytics_cms_active_dce_participants_aggregate: Analytics_Analytics_Cms_Active_Dce_Participants_Aggregate;
  /** fetch data from the table: "business_associate_agreements" */
  business_associate_agreements: Array<Business_Associate_Agreements>;
  /** fetch aggregated fields from the table: "business_associate_agreements" */
  business_associate_agreements_aggregate: Business_Associate_Agreements_Aggregate;
  /** fetch data from the table: "business_associate_agreements" using primary key columns */
  business_associate_agreements_by_pk?: Maybe<Business_Associate_Agreements>;
  /** fetch data from the table: "campaign_approvals" */
  campaign_approvals: Array<Campaign_Approvals>;
  /** fetch aggregated fields from the table: "campaign_approvals" */
  campaign_approvals_aggregate: Campaign_Approvals_Aggregate;
  /** fetch data from the table: "campaign_approvals" using primary key columns */
  campaign_approvals_by_pk?: Maybe<Campaign_Approvals>;
  /** fetch data from the table: "campaign_email_components" */
  campaign_email_components: Array<Campaign_Email_Components>;
  /** fetch aggregated fields from the table: "campaign_email_components" */
  campaign_email_components_aggregate: Campaign_Email_Components_Aggregate;
  /** fetch data from the table: "campaign_email_components" using primary key columns */
  campaign_email_components_by_pk?: Maybe<Campaign_Email_Components>;
  /** fetch data from the table: "campaign_letter_components" */
  campaign_letter_components: Array<Campaign_Letter_Components>;
  /** fetch aggregated fields from the table: "campaign_letter_components" */
  campaign_letter_components_aggregate: Campaign_Letter_Components_Aggregate;
  /** fetch data from the table: "campaign_letter_components" using primary key columns */
  campaign_letter_components_by_pk?: Maybe<Campaign_Letter_Components>;
  /** fetch data from the table: "campaign_sms_components" */
  campaign_sms_components: Array<Campaign_Sms_Components>;
  /** fetch aggregated fields from the table: "campaign_sms_components" */
  campaign_sms_components_aggregate: Campaign_Sms_Components_Aggregate;
  /** fetch data from the table: "campaign_sms_components" using primary key columns */
  campaign_sms_components_by_pk?: Maybe<Campaign_Sms_Components>;
  /** fetch data from the table: "campaign_templates" */
  campaign_templates: Array<Campaign_Templates>;
  /** fetch aggregated fields from the table: "campaign_templates" */
  campaign_templates_aggregate: Campaign_Templates_Aggregate;
  /** fetch data from the table: "campaign_templates" using primary key columns */
  campaign_templates_by_pk?: Maybe<Campaign_Templates>;
  /** fetch data from the table: "client_call_records" */
  client_call_records: Array<Client_Call_Records>;
  /** fetch aggregated fields from the table: "client_call_records" */
  client_call_records_aggregate: Client_Call_Records_Aggregate;
  /** fetch data from the table: "client_call_records" using primary key columns */
  client_call_records_by_pk?: Maybe<Client_Call_Records>;
  /** fetch data from the table: "cms_alignment_reports" */
  cms_alignment_reports: Array<Cms_Alignment_Reports>;
  /** fetch aggregated fields from the table: "cms_alignment_reports" */
  cms_alignment_reports_aggregate: Cms_Alignment_Reports_Aggregate;
  /** fetch data from the table: "cms_alignment_reports" using primary key columns */
  cms_alignment_reports_by_pk?: Maybe<Cms_Alignment_Reports>;
  /** fetch data from the table: "cms_dce_participants" */
  cms_dce_participants: Array<Cms_Dce_Participants>;
  /** fetch aggregated fields from the table: "cms_dce_participants" */
  cms_dce_participants_aggregate: Cms_Dce_Participants_Aggregate;
  /** fetch data from the table: "cms_provider_alignment_reports" */
  cms_provider_alignment_reports: Array<Cms_Provider_Alignment_Reports>;
  /** fetch aggregated fields from the table: "cms_provider_alignment_reports" */
  cms_provider_alignment_reports_aggregate: Cms_Provider_Alignment_Reports_Aggregate;
  /** fetch data from the table: "cms_provider_alignment_reports" using primary key columns */
  cms_provider_alignment_reports_by_pk?: Maybe<Cms_Provider_Alignment_Reports>;
  /** fetch data from the table: "cms_pva_responses" */
  cms_pva_responses: Array<Cms_Pva_Responses>;
  /** fetch aggregated fields from the table: "cms_pva_responses" */
  cms_pva_responses_aggregate: Cms_Pva_Responses_Aggregate;
  /** fetch data from the table: "cms_pva_responses" using primary key columns */
  cms_pva_responses_by_pk?: Maybe<Cms_Pva_Responses>;
  /** fetch data from the table: "component_tracking_ids" */
  component_tracking_ids: Array<Component_Tracking_Ids>;
  /** fetch aggregated fields from the table: "component_tracking_ids" */
  component_tracking_ids_aggregate: Component_Tracking_Ids_Aggregate;
  /** fetch data from the table: "component_tracking_ids" using primary key columns */
  component_tracking_ids_by_pk?: Maybe<Component_Tracking_Ids>;
  /** fetch data from the table: "daily_performance_stats" */
  daily_performance_stats: Array<Daily_Performance_Stats>;
  /** fetch aggregated fields from the table: "daily_performance_stats" */
  daily_performance_stats_aggregate: Daily_Performance_Stats_Aggregate;
  /** fetch data from the table: "daily_performance_stats" using primary key columns */
  daily_performance_stats_by_pk?: Maybe<Daily_Performance_Stats>;
  /** fetch data from the table: "email_component_approvals" */
  email_component_approvals: Array<Email_Component_Approvals>;
  /** fetch aggregated fields from the table: "email_component_approvals" */
  email_component_approvals_aggregate: Email_Component_Approvals_Aggregate;
  /** fetch data from the table: "email_component_approvals" using primary key columns */
  email_component_approvals_by_pk?: Maybe<Email_Component_Approvals>;
  /** fetch data from the table: "email_templates" */
  email_templates: Array<Email_Templates>;
  /** fetch aggregated fields from the table: "email_templates" */
  email_templates_aggregate: Email_Templates_Aggregate;
  /** fetch data from the table: "email_templates" using primary key columns */
  email_templates_by_pk?: Maybe<Email_Templates>;
  /** fetch data from the table: "emails" */
  emails: Array<Emails>;
  /** fetch aggregated fields from the table: "emails" */
  emails_aggregate: Emails_Aggregate;
  /** fetch data from the table: "emails" using primary key columns */
  emails_by_pk?: Maybe<Emails>;
  /** fetch data from the table: "file_uploads" */
  file_uploads: Array<File_Uploads>;
  /** fetch aggregated fields from the table: "file_uploads" */
  file_uploads_aggregate: File_Uploads_Aggregate;
  /** fetch data from the table: "file_uploads" using primary key columns */
  file_uploads_by_pk?: Maybe<File_Uploads>;
  /** execute function "filtered_insurance_counts" which returns "insurance_counts" */
  filtered_insurance_counts: Array<Insurance_Counts>;
  /** execute function "filtered_insurance_counts" and query aggregates on result of table type "insurance_counts" */
  filtered_insurance_counts_aggregate: Insurance_Counts_Aggregate;
  /** fetch data from the table: "health_equity_reports" */
  health_equity_reports: Array<Health_Equity_Reports>;
  /** fetch aggregated fields from the table: "health_equity_reports" */
  health_equity_reports_aggregate: Health_Equity_Reports_Aggregate;
  /** fetch data from the table: "health_equity_reports_audit" */
  health_equity_reports_audit: Array<Health_Equity_Reports_Audit>;
  /** fetch aggregated fields from the table: "health_equity_reports_audit" */
  health_equity_reports_audit_aggregate: Health_Equity_Reports_Audit_Aggregate;
  /** fetch data from the table: "health_equity_reports" using primary key columns */
  health_equity_reports_by_pk?: Maybe<Health_Equity_Reports>;
  /** fetch data from the table: "hedr_submission_templates" */
  hedr_submission_templates: Array<Hedr_Submission_Templates>;
  /** fetch aggregated fields from the table: "hedr_submission_templates" */
  hedr_submission_templates_aggregate: Hedr_Submission_Templates_Aggregate;
  /** fetch data from the table: "hedr_submission_templates" using primary key columns */
  hedr_submission_templates_by_pk?: Maybe<Hedr_Submission_Templates>;
  /** fetch data from the table: "insurance_counts" */
  insurance_counts: Array<Insurance_Counts>;
  /** fetch aggregated fields from the table: "insurance_counts" */
  insurance_counts_aggregate: Insurance_Counts_Aggregate;
  /** fetch data from the table: "insurance_segment_mappings" */
  insurance_segment_mappings: Array<Insurance_Segment_Mappings>;
  /** fetch aggregated fields from the table: "insurance_segment_mappings" */
  insurance_segment_mappings_aggregate: Insurance_Segment_Mappings_Aggregate;
  /** fetch data from the table: "insurance_segment_mappings" using primary key columns */
  insurance_segment_mappings_by_pk?: Maybe<Insurance_Segment_Mappings>;
  /** fetch data from the table: "letter_component_approvals" */
  letter_component_approvals: Array<Letter_Component_Approvals>;
  /** fetch aggregated fields from the table: "letter_component_approvals" */
  letter_component_approvals_aggregate: Letter_Component_Approvals_Aggregate;
  /** fetch data from the table: "letter_component_approvals" using primary key columns */
  letter_component_approvals_by_pk?: Maybe<Letter_Component_Approvals>;
  /** fetch data from the table: "letter_format_types" */
  letter_format_types: Array<Letter_Format_Types>;
  /** fetch aggregated fields from the table: "letter_format_types" */
  letter_format_types_aggregate: Letter_Format_Types_Aggregate;
  /** fetch data from the table: "letter_format_types" using primary key columns */
  letter_format_types_by_pk?: Maybe<Letter_Format_Types>;
  /** fetch data from the table: "letter_templates" */
  letter_templates: Array<Letter_Templates>;
  /** fetch aggregated fields from the table: "letter_templates" */
  letter_templates_aggregate: Letter_Templates_Aggregate;
  /** fetch data from the table: "letter_templates" using primary key columns */
  letter_templates_by_pk?: Maybe<Letter_Templates>;
  /** fetch data from the table: "letter_variable_values" */
  letter_variable_values: Array<Letter_Variable_Values>;
  /** fetch aggregated fields from the table: "letter_variable_values" */
  letter_variable_values_aggregate: Letter_Variable_Values_Aggregate;
  /** fetch data from the table: "letter_variable_values" using primary key columns */
  letter_variable_values_by_pk?: Maybe<Letter_Variable_Values>;
  /** fetch data from the table: "letters" */
  letters: Array<Letters>;
  /** fetch aggregated fields from the table: "letters" */
  letters_aggregate: Letters_Aggregate;
  /** fetch data from the table: "letters" using primary key columns */
  letters_by_pk?: Maybe<Letters>;
  /** fetch data from the table: "networks" */
  networks: Array<Networks>;
  /** fetch aggregated fields from the table: "networks" */
  networks_aggregate: Networks_Aggregate;
  /** fetch data from the table: "networks" using primary key columns */
  networks_by_pk?: Maybe<Networks>;
  /** fetch data from the table: "organization_networks" */
  organization_networks: Array<Organization_Networks>;
  /** fetch aggregated fields from the table: "organization_networks" */
  organization_networks_aggregate: Organization_Networks_Aggregate;
  /** fetch data from the table: "organization_networks" using primary key columns */
  organization_networks_by_pk?: Maybe<Organization_Networks>;
  /** fetch data from the table: "organizations" */
  organizations: Array<Organizations>;
  /** fetch aggregated fields from the table: "organizations" */
  organizations_aggregate: Organizations_Aggregate;
  /** fetch data from the table: "organizations" using primary key columns */
  organizations_by_pk?: Maybe<Organizations>;
  /** fetch data from the table: "patient_alignment_labels" */
  patient_alignment_labels: Array<Patient_Alignment_Labels>;
  /** fetch aggregated fields from the table: "patient_alignment_labels" */
  patient_alignment_labels_aggregate: Patient_Alignment_Labels_Aggregate;
  /** fetch data from the table: "patient_hedr_labels" */
  patient_hedr_labels: Array<Patient_Hedr_Labels>;
  /** fetch aggregated fields from the table: "patient_hedr_labels" */
  patient_hedr_labels_aggregate: Patient_Hedr_Labels_Aggregate;
  /** fetch data from the table: "patient_roster_files" */
  patient_roster_files: Array<Patient_Roster_Files>;
  /** fetch aggregated fields from the table: "patient_roster_files" */
  patient_roster_files_aggregate: Patient_Roster_Files_Aggregate;
  /** fetch data from the table: "patient_roster_files" using primary key columns */
  patient_roster_files_by_pk?: Maybe<Patient_Roster_Files>;
  /** fetch data from the table: "patient_segment_labels" */
  patient_segment_labels: Array<Patient_Segment_Labels>;
  /** fetch aggregated fields from the table: "patient_segment_labels" */
  patient_segment_labels_aggregate: Patient_Segment_Labels_Aggregate;
  /** fetch data from the table: "patient_segments" */
  patient_segments: Array<Patient_Segments>;
  /** fetch aggregated fields from the table: "patient_segments" */
  patient_segments_aggregate: Patient_Segments_Aggregate;
  /** fetch data from the table: "patient_segments" using primary key columns */
  patient_segments_by_pk?: Maybe<Patient_Segments>;
  /** fetch data from the table: "patients" */
  patients: Array<Patients>;
  /** fetch aggregated fields from the table: "patients" */
  patients_aggregate: Patients_Aggregate;
  /** fetch data from the table: "patients_audit" */
  patients_audit: Array<Patients_Audit>;
  /** fetch aggregated fields from the table: "patients_audit" */
  patients_audit_aggregate: Patients_Audit_Aggregate;
  /** fetch data from the table: "patients" using primary key columns */
  patients_by_pk?: Maybe<Patients>;
  /** fetch data from the table: "practices" */
  practices: Array<Practices>;
  /** fetch aggregated fields from the table: "practices" */
  practices_aggregate: Practices_Aggregate;
  /** fetch data from the table: "practices" using primary key columns */
  practices_by_pk?: Maybe<Practices>;
  /** fetch data from the table: "provider_employments" */
  provider_employments: Array<Provider_Employments>;
  /** fetch aggregated fields from the table: "provider_employments" */
  provider_employments_aggregate: Provider_Employments_Aggregate;
  /** fetch data from the table: "provider_employments" using primary key columns */
  provider_employments_by_pk?: Maybe<Provider_Employments>;
  /** fetch data from the table: "provider_memberships" */
  provider_memberships: Array<Provider_Memberships>;
  /** fetch aggregated fields from the table: "provider_memberships" */
  provider_memberships_aggregate: Provider_Memberships_Aggregate;
  /** fetch data from the table: "provider_memberships" using primary key columns */
  provider_memberships_by_pk?: Maybe<Provider_Memberships>;
  /** fetch data from the table: "providers" */
  providers: Array<Providers>;
  /** fetch aggregated fields from the table: "providers" */
  providers_aggregate: Providers_Aggregate;
  /** fetch data from the table: "providers" using primary key columns */
  providers_by_pk?: Maybe<Providers>;
  /** fetch data from the table: "pvas" */
  pvas: Array<Pvas>;
  /** fetch aggregated fields from the table: "pvas" */
  pvas_aggregate: Pvas_Aggregate;
  /** fetch data from the table: "pvas_audit" */
  pvas_audit: Array<Pvas_Audit>;
  /** fetch aggregated fields from the table: "pvas_audit" */
  pvas_audit_aggregate: Pvas_Audit_Aggregate;
  /** fetch data from the table: "pvas" using primary key columns */
  pvas_by_pk?: Maybe<Pvas>;
  /** fetch data from the table: "satisfaction_surveys" */
  satisfaction_surveys: Array<Satisfaction_Surveys>;
  /** fetch aggregated fields from the table: "satisfaction_surveys" */
  satisfaction_surveys_aggregate: Satisfaction_Surveys_Aggregate;
  /** fetch data from the table: "satisfaction_surveys" using primary key columns */
  satisfaction_surveys_by_pk?: Maybe<Satisfaction_Surveys>;
  /** fetch data from the table: "scheduled_events" */
  scheduled_events: Array<Scheduled_Events>;
  /** fetch aggregated fields from the table: "scheduled_events" */
  scheduled_events_aggregate: Scheduled_Events_Aggregate;
  /** execute function "search_patients_by_hedr_data" which returns "patients" */
  search_patients_by_hedr_data: Array<Patients>;
  /** execute function "search_patients_by_hedr_data" and query aggregates on result of table type "patients" */
  search_patients_by_hedr_data_aggregate: Patients_Aggregate;
  /** execute function "search_patients_by_pva_data" which returns "patients" */
  search_patients_by_pva_data: Array<Patients>;
  /** execute function "search_patients_by_pva_data" and query aggregates on result of table type "patients" */
  search_patients_by_pva_data_aggregate: Patients_Aggregate;
  /** fetch data from the table: "sms_component_approvals" */
  sms_component_approvals: Array<Sms_Component_Approvals>;
  /** fetch aggregated fields from the table: "sms_component_approvals" */
  sms_component_approvals_aggregate: Sms_Component_Approvals_Aggregate;
  /** fetch data from the table: "sms_component_approvals" using primary key columns */
  sms_component_approvals_by_pk?: Maybe<Sms_Component_Approvals>;
  /** fetch data from the table: "sms_messages" */
  sms_messages: Array<Sms_Messages>;
  /** fetch aggregated fields from the table: "sms_messages" */
  sms_messages_aggregate: Sms_Messages_Aggregate;
  /** fetch data from the table: "sms_messages" using primary key columns */
  sms_messages_by_pk?: Maybe<Sms_Messages>;
  /** fetch data from the table: "sms_templates" */
  sms_templates: Array<Sms_Templates>;
  /** fetch aggregated fields from the table: "sms_templates" */
  sms_templates_aggregate: Sms_Templates_Aggregate;
  /** fetch data from the table: "sms_templates" using primary key columns */
  sms_templates_by_pk?: Maybe<Sms_Templates>;
  /** fetch data from the table: "users" */
  users: Array<Users>;
  /** fetch aggregated fields from the table: "users" */
  users_aggregate: Users_Aggregate;
  /** fetch data from the table: "users_audit" */
  users_audit: Array<Users_Audit>;
  /** fetch aggregated fields from the table: "users_audit" */
  users_audit_aggregate: Users_Audit_Aggregate;
  /** fetch data from the table: "users" using primary key columns */
  users_by_pk?: Maybe<Users>;
};


/** subscription root */
export type Subscription_RootAccount_Creation_RequestsArgs = {
  distinct_on?: Maybe<Array<Account_Creation_Requests_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Account_Creation_Requests_Order_By>>;
  where?: Maybe<Account_Creation_Requests_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootAccount_Creation_Requests_AggregateArgs = {
  distinct_on?: Maybe<Array<Account_Creation_Requests_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Account_Creation_Requests_Order_By>>;
  where?: Maybe<Account_Creation_Requests_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootAccount_Creation_Requests_By_PkArgs = {
  account_creation_request_id: Scalars['String'];
};


/** subscription root */
export type Subscription_RootAlignmentsArgs = {
  distinct_on?: Maybe<Array<Alignments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Alignments_Order_By>>;
  where?: Maybe<Alignments_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootAlignments_AggregateArgs = {
  distinct_on?: Maybe<Array<Alignments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Alignments_Order_By>>;
  where?: Maybe<Alignments_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootAlignments_By_PkArgs = {
  alignment_id: Scalars['String'];
};


/** subscription root */
export type Subscription_RootAnalytics_Analytics_Cms_Active_Dce_ParticipantsArgs = {
  distinct_on?: Maybe<Array<Analytics_Analytics_Cms_Active_Dce_Participants_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Analytics_Analytics_Cms_Active_Dce_Participants_Order_By>>;
  where?: Maybe<Analytics_Analytics_Cms_Active_Dce_Participants_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootAnalytics_Analytics_Cms_Active_Dce_Participants_AggregateArgs = {
  distinct_on?: Maybe<Array<Analytics_Analytics_Cms_Active_Dce_Participants_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Analytics_Analytics_Cms_Active_Dce_Participants_Order_By>>;
  where?: Maybe<Analytics_Analytics_Cms_Active_Dce_Participants_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootBusiness_Associate_AgreementsArgs = {
  distinct_on?: Maybe<Array<Business_Associate_Agreements_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Business_Associate_Agreements_Order_By>>;
  where?: Maybe<Business_Associate_Agreements_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootBusiness_Associate_Agreements_AggregateArgs = {
  distinct_on?: Maybe<Array<Business_Associate_Agreements_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Business_Associate_Agreements_Order_By>>;
  where?: Maybe<Business_Associate_Agreements_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootBusiness_Associate_Agreements_By_PkArgs = {
  external_id: Scalars['String'];
  external_source: Scalars['String'];
};


/** subscription root */
export type Subscription_RootCampaign_ApprovalsArgs = {
  distinct_on?: Maybe<Array<Campaign_Approvals_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Campaign_Approvals_Order_By>>;
  where?: Maybe<Campaign_Approvals_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootCampaign_Approvals_AggregateArgs = {
  distinct_on?: Maybe<Array<Campaign_Approvals_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Campaign_Approvals_Order_By>>;
  where?: Maybe<Campaign_Approvals_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootCampaign_Approvals_By_PkArgs = {
  campaign_template_id: Scalars['String'];
  practice_id: Scalars['String'];
};


/** subscription root */
export type Subscription_RootCampaign_Email_ComponentsArgs = {
  distinct_on?: Maybe<Array<Campaign_Email_Components_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Campaign_Email_Components_Order_By>>;
  where?: Maybe<Campaign_Email_Components_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootCampaign_Email_Components_AggregateArgs = {
  distinct_on?: Maybe<Array<Campaign_Email_Components_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Campaign_Email_Components_Order_By>>;
  where?: Maybe<Campaign_Email_Components_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootCampaign_Email_Components_By_PkArgs = {
  campaign_email_component_id: Scalars['String'];
};


/** subscription root */
export type Subscription_RootCampaign_Letter_ComponentsArgs = {
  distinct_on?: Maybe<Array<Campaign_Letter_Components_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Campaign_Letter_Components_Order_By>>;
  where?: Maybe<Campaign_Letter_Components_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootCampaign_Letter_Components_AggregateArgs = {
  distinct_on?: Maybe<Array<Campaign_Letter_Components_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Campaign_Letter_Components_Order_By>>;
  where?: Maybe<Campaign_Letter_Components_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootCampaign_Letter_Components_By_PkArgs = {
  campaign_letter_component_id: Scalars['String'];
};


/** subscription root */
export type Subscription_RootCampaign_Sms_ComponentsArgs = {
  distinct_on?: Maybe<Array<Campaign_Sms_Components_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Campaign_Sms_Components_Order_By>>;
  where?: Maybe<Campaign_Sms_Components_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootCampaign_Sms_Components_AggregateArgs = {
  distinct_on?: Maybe<Array<Campaign_Sms_Components_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Campaign_Sms_Components_Order_By>>;
  where?: Maybe<Campaign_Sms_Components_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootCampaign_Sms_Components_By_PkArgs = {
  campaign_sms_component_id: Scalars['String'];
};


/** subscription root */
export type Subscription_RootCampaign_TemplatesArgs = {
  distinct_on?: Maybe<Array<Campaign_Templates_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Campaign_Templates_Order_By>>;
  where?: Maybe<Campaign_Templates_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootCampaign_Templates_AggregateArgs = {
  distinct_on?: Maybe<Array<Campaign_Templates_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Campaign_Templates_Order_By>>;
  where?: Maybe<Campaign_Templates_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootCampaign_Templates_By_PkArgs = {
  campaign_template_id: Scalars['String'];
};


/** subscription root */
export type Subscription_RootClient_Call_RecordsArgs = {
  distinct_on?: Maybe<Array<Client_Call_Records_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Client_Call_Records_Order_By>>;
  where?: Maybe<Client_Call_Records_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootClient_Call_Records_AggregateArgs = {
  distinct_on?: Maybe<Array<Client_Call_Records_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Client_Call_Records_Order_By>>;
  where?: Maybe<Client_Call_Records_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootClient_Call_Records_By_PkArgs = {
  call_start_time: Scalars['timestamp'];
  caller_number: Scalars['String'];
  dialed_number: Scalars['String'];
};


/** subscription root */
export type Subscription_RootCms_Alignment_ReportsArgs = {
  distinct_on?: Maybe<Array<Cms_Alignment_Reports_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Cms_Alignment_Reports_Order_By>>;
  where?: Maybe<Cms_Alignment_Reports_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootCms_Alignment_Reports_AggregateArgs = {
  distinct_on?: Maybe<Array<Cms_Alignment_Reports_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Cms_Alignment_Reports_Order_By>>;
  where?: Maybe<Cms_Alignment_Reports_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootCms_Alignment_Reports_By_PkArgs = {
  beneficiary_mbi_id: Scalars['String'];
  dc_id: Scalars['String'];
  performance_year: Scalars['Int'];
};


/** subscription root */
export type Subscription_RootCms_Dce_ParticipantsArgs = {
  distinct_on?: Maybe<Array<Cms_Dce_Participants_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Cms_Dce_Participants_Order_By>>;
  where?: Maybe<Cms_Dce_Participants_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootCms_Dce_Participants_AggregateArgs = {
  distinct_on?: Maybe<Array<Cms_Dce_Participants_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Cms_Dce_Participants_Order_By>>;
  where?: Maybe<Cms_Dce_Participants_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootCms_Provider_Alignment_ReportsArgs = {
  distinct_on?: Maybe<Array<Cms_Provider_Alignment_Reports_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Cms_Provider_Alignment_Reports_Order_By>>;
  where?: Maybe<Cms_Provider_Alignment_Reports_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootCms_Provider_Alignment_Reports_AggregateArgs = {
  distinct_on?: Maybe<Array<Cms_Provider_Alignment_Reports_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Cms_Provider_Alignment_Reports_Order_By>>;
  where?: Maybe<Cms_Provider_Alignment_Reports_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootCms_Provider_Alignment_Reports_By_PkArgs = {
  ACO_ID: Scalars['String'];
  ALGN_TYPE_CLM: Scalars['String'];
  FAC_PRVDR_OSCAR_NUM: Scalars['String'];
  MBI_ID: Scalars['String'];
  PERFORMANCE_YEAR: Scalars['Int'];
  PRVDR_NPI: Scalars['String'];
  PRVDR_TIN: Scalars['String'];
};


/** subscription root */
export type Subscription_RootCms_Pva_ResponsesArgs = {
  distinct_on?: Maybe<Array<Cms_Pva_Responses_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Cms_Pva_Responses_Order_By>>;
  where?: Maybe<Cms_Pva_Responses_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootCms_Pva_Responses_AggregateArgs = {
  distinct_on?: Maybe<Array<Cms_Pva_Responses_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Cms_Pva_Responses_Order_By>>;
  where?: Maybe<Cms_Pva_Responses_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootCms_Pva_Responses_By_PkArgs = {
  DCE_ID: Scalars['String'];
  ID_RECEIVED: Scalars['String'];
  IND_NPI: Scalars['String'];
  IND_TIN: Scalars['String'];
  SIGNATURE_DATE: Scalars['date'];
  SUBMISSION_DATE: Scalars['date'];
};


/** subscription root */
export type Subscription_RootComponent_Tracking_IdsArgs = {
  distinct_on?: Maybe<Array<Component_Tracking_Ids_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Component_Tracking_Ids_Order_By>>;
  where?: Maybe<Component_Tracking_Ids_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootComponent_Tracking_Ids_AggregateArgs = {
  distinct_on?: Maybe<Array<Component_Tracking_Ids_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Component_Tracking_Ids_Order_By>>;
  where?: Maybe<Component_Tracking_Ids_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootComponent_Tracking_Ids_By_PkArgs = {
  component_id: Scalars['String'];
  tracking_id: Scalars['String'];
};


/** subscription root */
export type Subscription_RootDaily_Performance_StatsArgs = {
  distinct_on?: Maybe<Array<Daily_Performance_Stats_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Daily_Performance_Stats_Order_By>>;
  where?: Maybe<Daily_Performance_Stats_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootDaily_Performance_Stats_AggregateArgs = {
  distinct_on?: Maybe<Array<Daily_Performance_Stats_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Daily_Performance_Stats_Order_By>>;
  where?: Maybe<Daily_Performance_Stats_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootDaily_Performance_Stats_By_PkArgs = {
  alliance_name: Scalars['String'];
  report_date: Scalars['date'];
};


/** subscription root */
export type Subscription_RootEmail_Component_ApprovalsArgs = {
  distinct_on?: Maybe<Array<Email_Component_Approvals_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Email_Component_Approvals_Order_By>>;
  where?: Maybe<Email_Component_Approvals_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootEmail_Component_Approvals_AggregateArgs = {
  distinct_on?: Maybe<Array<Email_Component_Approvals_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Email_Component_Approvals_Order_By>>;
  where?: Maybe<Email_Component_Approvals_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootEmail_Component_Approvals_By_PkArgs = {
  campaign_email_component_id: Scalars['String'];
  campaign_template_id: Scalars['String'];
  practice_id: Scalars['String'];
};


/** subscription root */
export type Subscription_RootEmail_TemplatesArgs = {
  distinct_on?: Maybe<Array<Email_Templates_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Email_Templates_Order_By>>;
  where?: Maybe<Email_Templates_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootEmail_Templates_AggregateArgs = {
  distinct_on?: Maybe<Array<Email_Templates_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Email_Templates_Order_By>>;
  where?: Maybe<Email_Templates_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootEmail_Templates_By_PkArgs = {
  email_template_id: Scalars['String'];
};


/** subscription root */
export type Subscription_RootEmailsArgs = {
  distinct_on?: Maybe<Array<Emails_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Emails_Order_By>>;
  where?: Maybe<Emails_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootEmails_AggregateArgs = {
  distinct_on?: Maybe<Array<Emails_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Emails_Order_By>>;
  where?: Maybe<Emails_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootEmails_By_PkArgs = {
  email_id: Scalars['String'];
};


/** subscription root */
export type Subscription_RootFile_UploadsArgs = {
  distinct_on?: Maybe<Array<File_Uploads_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<File_Uploads_Order_By>>;
  where?: Maybe<File_Uploads_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootFile_Uploads_AggregateArgs = {
  distinct_on?: Maybe<Array<File_Uploads_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<File_Uploads_Order_By>>;
  where?: Maybe<File_Uploads_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootFile_Uploads_By_PkArgs = {
  file_upload_id: Scalars['String'];
};


/** subscription root */
export type Subscription_RootFiltered_Insurance_CountsArgs = {
  args: Filtered_Insurance_Counts_Args;
  distinct_on?: Maybe<Array<Insurance_Counts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Insurance_Counts_Order_By>>;
  where?: Maybe<Insurance_Counts_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootFiltered_Insurance_Counts_AggregateArgs = {
  args: Filtered_Insurance_Counts_Args;
  distinct_on?: Maybe<Array<Insurance_Counts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Insurance_Counts_Order_By>>;
  where?: Maybe<Insurance_Counts_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootHealth_Equity_ReportsArgs = {
  distinct_on?: Maybe<Array<Health_Equity_Reports_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Health_Equity_Reports_Order_By>>;
  where?: Maybe<Health_Equity_Reports_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootHealth_Equity_Reports_AggregateArgs = {
  distinct_on?: Maybe<Array<Health_Equity_Reports_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Health_Equity_Reports_Order_By>>;
  where?: Maybe<Health_Equity_Reports_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootHealth_Equity_Reports_AuditArgs = {
  distinct_on?: Maybe<Array<Health_Equity_Reports_Audit_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Health_Equity_Reports_Audit_Order_By>>;
  where?: Maybe<Health_Equity_Reports_Audit_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootHealth_Equity_Reports_Audit_AggregateArgs = {
  distinct_on?: Maybe<Array<Health_Equity_Reports_Audit_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Health_Equity_Reports_Audit_Order_By>>;
  where?: Maybe<Health_Equity_Reports_Audit_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootHealth_Equity_Reports_By_PkArgs = {
  health_equity_report_id: Scalars['String'];
};


/** subscription root */
export type Subscription_RootHedr_Submission_TemplatesArgs = {
  distinct_on?: Maybe<Array<Hedr_Submission_Templates_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hedr_Submission_Templates_Order_By>>;
  where?: Maybe<Hedr_Submission_Templates_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootHedr_Submission_Templates_AggregateArgs = {
  distinct_on?: Maybe<Array<Hedr_Submission_Templates_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hedr_Submission_Templates_Order_By>>;
  where?: Maybe<Hedr_Submission_Templates_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootHedr_Submission_Templates_By_PkArgs = {
  entity_id: Scalars['String'];
  mbi: Scalars['String'];
  performance_year: Scalars['Int'];
};


/** subscription root */
export type Subscription_RootInsurance_CountsArgs = {
  distinct_on?: Maybe<Array<Insurance_Counts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Insurance_Counts_Order_By>>;
  where?: Maybe<Insurance_Counts_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootInsurance_Counts_AggregateArgs = {
  distinct_on?: Maybe<Array<Insurance_Counts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Insurance_Counts_Order_By>>;
  where?: Maybe<Insurance_Counts_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootInsurance_Segment_MappingsArgs = {
  distinct_on?: Maybe<Array<Insurance_Segment_Mappings_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Insurance_Segment_Mappings_Order_By>>;
  where?: Maybe<Insurance_Segment_Mappings_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootInsurance_Segment_Mappings_AggregateArgs = {
  distinct_on?: Maybe<Array<Insurance_Segment_Mappings_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Insurance_Segment_Mappings_Order_By>>;
  where?: Maybe<Insurance_Segment_Mappings_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootInsurance_Segment_Mappings_By_PkArgs = {
  primary_insurance_is_null: Scalars['Boolean'];
  primary_insurance_payer_name: Scalars['String'];
  secondary_insurance_is_null: Scalars['Boolean'];
  secondary_insurance_payer_name: Scalars['String'];
};


/** subscription root */
export type Subscription_RootLetter_Component_ApprovalsArgs = {
  distinct_on?: Maybe<Array<Letter_Component_Approvals_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Letter_Component_Approvals_Order_By>>;
  where?: Maybe<Letter_Component_Approvals_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootLetter_Component_Approvals_AggregateArgs = {
  distinct_on?: Maybe<Array<Letter_Component_Approvals_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Letter_Component_Approvals_Order_By>>;
  where?: Maybe<Letter_Component_Approvals_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootLetter_Component_Approvals_By_PkArgs = {
  campaign_letter_component_id: Scalars['String'];
  campaign_template_id: Scalars['String'];
  practice_id: Scalars['String'];
};


/** subscription root */
export type Subscription_RootLetter_Format_TypesArgs = {
  distinct_on?: Maybe<Array<Letter_Format_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Letter_Format_Types_Order_By>>;
  where?: Maybe<Letter_Format_Types_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootLetter_Format_Types_AggregateArgs = {
  distinct_on?: Maybe<Array<Letter_Format_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Letter_Format_Types_Order_By>>;
  where?: Maybe<Letter_Format_Types_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootLetter_Format_Types_By_PkArgs = {
  letter_format_type_id: Scalars['String'];
};


/** subscription root */
export type Subscription_RootLetter_TemplatesArgs = {
  distinct_on?: Maybe<Array<Letter_Templates_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Letter_Templates_Order_By>>;
  where?: Maybe<Letter_Templates_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootLetter_Templates_AggregateArgs = {
  distinct_on?: Maybe<Array<Letter_Templates_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Letter_Templates_Order_By>>;
  where?: Maybe<Letter_Templates_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootLetter_Templates_By_PkArgs = {
  letter_template_id: Scalars['String'];
};


/** subscription root */
export type Subscription_RootLetter_Variable_ValuesArgs = {
  distinct_on?: Maybe<Array<Letter_Variable_Values_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Letter_Variable_Values_Order_By>>;
  where?: Maybe<Letter_Variable_Values_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootLetter_Variable_Values_AggregateArgs = {
  distinct_on?: Maybe<Array<Letter_Variable_Values_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Letter_Variable_Values_Order_By>>;
  where?: Maybe<Letter_Variable_Values_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootLetter_Variable_Values_By_PkArgs = {
  key: Scalars['String'];
  letter_id: Scalars['String'];
};


/** subscription root */
export type Subscription_RootLettersArgs = {
  distinct_on?: Maybe<Array<Letters_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Letters_Order_By>>;
  where?: Maybe<Letters_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootLetters_AggregateArgs = {
  distinct_on?: Maybe<Array<Letters_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Letters_Order_By>>;
  where?: Maybe<Letters_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootLetters_By_PkArgs = {
  letter_id: Scalars['String'];
};


/** subscription root */
export type Subscription_RootNetworksArgs = {
  distinct_on?: Maybe<Array<Networks_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Networks_Order_By>>;
  where?: Maybe<Networks_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootNetworks_AggregateArgs = {
  distinct_on?: Maybe<Array<Networks_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Networks_Order_By>>;
  where?: Maybe<Networks_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootNetworks_By_PkArgs = {
  network_id: Scalars['String'];
};


/** subscription root */
export type Subscription_RootOrganization_NetworksArgs = {
  distinct_on?: Maybe<Array<Organization_Networks_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Organization_Networks_Order_By>>;
  where?: Maybe<Organization_Networks_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootOrganization_Networks_AggregateArgs = {
  distinct_on?: Maybe<Array<Organization_Networks_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Organization_Networks_Order_By>>;
  where?: Maybe<Organization_Networks_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootOrganization_Networks_By_PkArgs = {
  network_id: Scalars['String'];
  organization_id: Scalars['String'];
};


/** subscription root */
export type Subscription_RootOrganizationsArgs = {
  distinct_on?: Maybe<Array<Organizations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Organizations_Order_By>>;
  where?: Maybe<Organizations_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootOrganizations_AggregateArgs = {
  distinct_on?: Maybe<Array<Organizations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Organizations_Order_By>>;
  where?: Maybe<Organizations_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootOrganizations_By_PkArgs = {
  organization_id: Scalars['String'];
};


/** subscription root */
export type Subscription_RootPatient_Alignment_LabelsArgs = {
  distinct_on?: Maybe<Array<Patient_Alignment_Labels_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Patient_Alignment_Labels_Order_By>>;
  where?: Maybe<Patient_Alignment_Labels_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootPatient_Alignment_Labels_AggregateArgs = {
  distinct_on?: Maybe<Array<Patient_Alignment_Labels_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Patient_Alignment_Labels_Order_By>>;
  where?: Maybe<Patient_Alignment_Labels_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootPatient_Hedr_LabelsArgs = {
  distinct_on?: Maybe<Array<Patient_Hedr_Labels_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Patient_Hedr_Labels_Order_By>>;
  where?: Maybe<Patient_Hedr_Labels_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootPatient_Hedr_Labels_AggregateArgs = {
  distinct_on?: Maybe<Array<Patient_Hedr_Labels_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Patient_Hedr_Labels_Order_By>>;
  where?: Maybe<Patient_Hedr_Labels_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootPatient_Roster_FilesArgs = {
  distinct_on?: Maybe<Array<Patient_Roster_Files_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Patient_Roster_Files_Order_By>>;
  where?: Maybe<Patient_Roster_Files_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootPatient_Roster_Files_AggregateArgs = {
  distinct_on?: Maybe<Array<Patient_Roster_Files_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Patient_Roster_Files_Order_By>>;
  where?: Maybe<Patient_Roster_Files_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootPatient_Roster_Files_By_PkArgs = {
  identity_id: Scalars['String'];
  key: Scalars['String'];
  level: Scalars['String'];
};


/** subscription root */
export type Subscription_RootPatient_Segment_LabelsArgs = {
  distinct_on?: Maybe<Array<Patient_Segment_Labels_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Patient_Segment_Labels_Order_By>>;
  where?: Maybe<Patient_Segment_Labels_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootPatient_Segment_Labels_AggregateArgs = {
  distinct_on?: Maybe<Array<Patient_Segment_Labels_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Patient_Segment_Labels_Order_By>>;
  where?: Maybe<Patient_Segment_Labels_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootPatient_SegmentsArgs = {
  distinct_on?: Maybe<Array<Patient_Segments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Patient_Segments_Order_By>>;
  where?: Maybe<Patient_Segments_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootPatient_Segments_AggregateArgs = {
  distinct_on?: Maybe<Array<Patient_Segments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Patient_Segments_Order_By>>;
  where?: Maybe<Patient_Segments_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootPatient_Segments_By_PkArgs = {
  patient_segment_id: Scalars['String'];
};


/** subscription root */
export type Subscription_RootPatientsArgs = {
  distinct_on?: Maybe<Array<Patients_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Patients_Order_By>>;
  where?: Maybe<Patients_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootPatients_AggregateArgs = {
  distinct_on?: Maybe<Array<Patients_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Patients_Order_By>>;
  where?: Maybe<Patients_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootPatients_AuditArgs = {
  distinct_on?: Maybe<Array<Patients_Audit_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Patients_Audit_Order_By>>;
  where?: Maybe<Patients_Audit_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootPatients_Audit_AggregateArgs = {
  distinct_on?: Maybe<Array<Patients_Audit_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Patients_Audit_Order_By>>;
  where?: Maybe<Patients_Audit_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootPatients_By_PkArgs = {
  patient_id: Scalars['String'];
};


/** subscription root */
export type Subscription_RootPracticesArgs = {
  distinct_on?: Maybe<Array<Practices_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Practices_Order_By>>;
  where?: Maybe<Practices_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootPractices_AggregateArgs = {
  distinct_on?: Maybe<Array<Practices_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Practices_Order_By>>;
  where?: Maybe<Practices_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootPractices_By_PkArgs = {
  practice_id: Scalars['String'];
};


/** subscription root */
export type Subscription_RootProvider_EmploymentsArgs = {
  distinct_on?: Maybe<Array<Provider_Employments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Provider_Employments_Order_By>>;
  where?: Maybe<Provider_Employments_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootProvider_Employments_AggregateArgs = {
  distinct_on?: Maybe<Array<Provider_Employments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Provider_Employments_Order_By>>;
  where?: Maybe<Provider_Employments_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootProvider_Employments_By_PkArgs = {
  practice_id: Scalars['String'];
  provider_id: Scalars['String'];
};


/** subscription root */
export type Subscription_RootProvider_MembershipsArgs = {
  distinct_on?: Maybe<Array<Provider_Memberships_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Provider_Memberships_Order_By>>;
  where?: Maybe<Provider_Memberships_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootProvider_Memberships_AggregateArgs = {
  distinct_on?: Maybe<Array<Provider_Memberships_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Provider_Memberships_Order_By>>;
  where?: Maybe<Provider_Memberships_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootProvider_Memberships_By_PkArgs = {
  network_id: Scalars['String'];
  provider_id: Scalars['String'];
};


/** subscription root */
export type Subscription_RootProvidersArgs = {
  distinct_on?: Maybe<Array<Providers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Providers_Order_By>>;
  where?: Maybe<Providers_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootProviders_AggregateArgs = {
  distinct_on?: Maybe<Array<Providers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Providers_Order_By>>;
  where?: Maybe<Providers_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootProviders_By_PkArgs = {
  provider_id: Scalars['String'];
};


/** subscription root */
export type Subscription_RootPvasArgs = {
  distinct_on?: Maybe<Array<Pvas_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Pvas_Order_By>>;
  where?: Maybe<Pvas_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootPvas_AggregateArgs = {
  distinct_on?: Maybe<Array<Pvas_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Pvas_Order_By>>;
  where?: Maybe<Pvas_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootPvas_AuditArgs = {
  distinct_on?: Maybe<Array<Pvas_Audit_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Pvas_Audit_Order_By>>;
  where?: Maybe<Pvas_Audit_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootPvas_Audit_AggregateArgs = {
  distinct_on?: Maybe<Array<Pvas_Audit_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Pvas_Audit_Order_By>>;
  where?: Maybe<Pvas_Audit_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootPvas_By_PkArgs = {
  pva_id: Scalars['String'];
};


/** subscription root */
export type Subscription_RootSatisfaction_SurveysArgs = {
  distinct_on?: Maybe<Array<Satisfaction_Surveys_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Satisfaction_Surveys_Order_By>>;
  where?: Maybe<Satisfaction_Surveys_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootSatisfaction_Surveys_AggregateArgs = {
  distinct_on?: Maybe<Array<Satisfaction_Surveys_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Satisfaction_Surveys_Order_By>>;
  where?: Maybe<Satisfaction_Surveys_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootSatisfaction_Surveys_By_PkArgs = {
  satisfaction_survey_id: Scalars['String'];
};


/** subscription root */
export type Subscription_RootScheduled_EventsArgs = {
  distinct_on?: Maybe<Array<Scheduled_Events_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Scheduled_Events_Order_By>>;
  where?: Maybe<Scheduled_Events_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootScheduled_Events_AggregateArgs = {
  distinct_on?: Maybe<Array<Scheduled_Events_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Scheduled_Events_Order_By>>;
  where?: Maybe<Scheduled_Events_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootSearch_Patients_By_Hedr_DataArgs = {
  args: Search_Patients_By_Hedr_Data_Args;
  distinct_on?: Maybe<Array<Patients_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Patients_Order_By>>;
  where?: Maybe<Patients_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootSearch_Patients_By_Hedr_Data_AggregateArgs = {
  args: Search_Patients_By_Hedr_Data_Args;
  distinct_on?: Maybe<Array<Patients_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Patients_Order_By>>;
  where?: Maybe<Patients_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootSearch_Patients_By_Pva_DataArgs = {
  args: Search_Patients_By_Pva_Data_Args;
  distinct_on?: Maybe<Array<Patients_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Patients_Order_By>>;
  where?: Maybe<Patients_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootSearch_Patients_By_Pva_Data_AggregateArgs = {
  args: Search_Patients_By_Pva_Data_Args;
  distinct_on?: Maybe<Array<Patients_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Patients_Order_By>>;
  where?: Maybe<Patients_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootSms_Component_ApprovalsArgs = {
  distinct_on?: Maybe<Array<Sms_Component_Approvals_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Sms_Component_Approvals_Order_By>>;
  where?: Maybe<Sms_Component_Approvals_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootSms_Component_Approvals_AggregateArgs = {
  distinct_on?: Maybe<Array<Sms_Component_Approvals_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Sms_Component_Approvals_Order_By>>;
  where?: Maybe<Sms_Component_Approvals_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootSms_Component_Approvals_By_PkArgs = {
  campaign_sms_component_id: Scalars['String'];
  campaign_template_id: Scalars['String'];
  practice_id: Scalars['String'];
};


/** subscription root */
export type Subscription_RootSms_MessagesArgs = {
  distinct_on?: Maybe<Array<Sms_Messages_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Sms_Messages_Order_By>>;
  where?: Maybe<Sms_Messages_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootSms_Messages_AggregateArgs = {
  distinct_on?: Maybe<Array<Sms_Messages_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Sms_Messages_Order_By>>;
  where?: Maybe<Sms_Messages_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootSms_Messages_By_PkArgs = {
  sms_message_id: Scalars['String'];
};


/** subscription root */
export type Subscription_RootSms_TemplatesArgs = {
  distinct_on?: Maybe<Array<Sms_Templates_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Sms_Templates_Order_By>>;
  where?: Maybe<Sms_Templates_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootSms_Templates_AggregateArgs = {
  distinct_on?: Maybe<Array<Sms_Templates_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Sms_Templates_Order_By>>;
  where?: Maybe<Sms_Templates_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootSms_Templates_By_PkArgs = {
  sms_template_id: Scalars['String'];
};


/** subscription root */
export type Subscription_RootUsersArgs = {
  distinct_on?: Maybe<Array<Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Users_Order_By>>;
  where?: Maybe<Users_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootUsers_AggregateArgs = {
  distinct_on?: Maybe<Array<Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Users_Order_By>>;
  where?: Maybe<Users_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootUsers_AuditArgs = {
  distinct_on?: Maybe<Array<Users_Audit_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Users_Audit_Order_By>>;
  where?: Maybe<Users_Audit_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootUsers_Audit_AggregateArgs = {
  distinct_on?: Maybe<Array<Users_Audit_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Users_Audit_Order_By>>;
  where?: Maybe<Users_Audit_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootUsers_By_PkArgs = {
  user_id: Scalars['String'];
};


/** expression to compare columns of type timestamp. All fields are combined with logical 'AND'. */
export type Timestamp_Comparison_Exp = {
  _eq?: Maybe<Scalars['timestamp']>;
  _gt?: Maybe<Scalars['timestamp']>;
  _gte?: Maybe<Scalars['timestamp']>;
  _in?: Maybe<Array<Scalars['timestamp']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['timestamp']>;
  _lte?: Maybe<Scalars['timestamp']>;
  _neq?: Maybe<Scalars['timestamp']>;
  _nin?: Maybe<Array<Scalars['timestamp']>>;
};


/** expression to compare columns of type timestamptz. All fields are combined with logical 'AND'. */
export type Timestamptz_Comparison_Exp = {
  _eq?: Maybe<Scalars['timestamptz']>;
  _gt?: Maybe<Scalars['timestamptz']>;
  _gte?: Maybe<Scalars['timestamptz']>;
  _in?: Maybe<Array<Scalars['timestamptz']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['timestamptz']>;
  _lte?: Maybe<Scalars['timestamptz']>;
  _neq?: Maybe<Scalars['timestamptz']>;
  _nin?: Maybe<Array<Scalars['timestamptz']>>;
};

/**
 * App user info. The user_id and groups come from cognito.
 * 
 * 
 * columns and relationships of "users"
 */
export type Users = {
  __typename?: 'users';
  /** An array relationship */
  campaign_approvals: Array<Campaign_Approvals>;
  /** An aggregated array relationship */
  campaign_approvals_aggregate: Campaign_Approvals_Aggregate;
  created_at: Scalars['timestamptz'];
  email_verified: Scalars['Boolean'];
  /** Same as cognito groups and x-hasura-allowed-roles. Array of values from: "admin", "network_user",  and "practice_user" */
  groups: Scalars['jsonb'];
  /** This is the ID assigned by the AWS identity pool (different from the cognito user pool ID). Amplify stores objects in S3 with the identity ID, so we keep the value here merely for convenience. It should not be used as a user's primary ID. */
  identity_id?: Maybe<Scalars['String']>;
  is_disabled: Scalars['Boolean'];
  last_login_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  network?: Maybe<Networks>;
  /** users in the "network_user" group should have a non-null value here */
  network_id?: Maybe<Scalars['String']>;
  /** An object relationship */
  organization?: Maybe<Organizations>;
  organization_id?: Maybe<Scalars['String']>;
  /** URL of avatar photo */
  photo?: Maybe<Scalars['String']>;
  /** An object relationship */
  practice?: Maybe<Practices>;
  /** users in the "practice_user" group should have a non-null value here */
  practice_id?: Maybe<Scalars['String']>;
  preferred_mfa: Scalars['String'];
  updated_at: Scalars['timestamptz'];
  user_email: Scalars['String'];
  /** Same as cognito user id and x-hasura-user-id. */
  user_id: Scalars['String'];
  user_name: Scalars['String'];
};


/**
 * App user info. The user_id and groups come from cognito.
 * 
 * 
 * columns and relationships of "users"
 */
export type UsersCampaign_ApprovalsArgs = {
  distinct_on?: Maybe<Array<Campaign_Approvals_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Campaign_Approvals_Order_By>>;
  where?: Maybe<Campaign_Approvals_Bool_Exp>;
};


/**
 * App user info. The user_id and groups come from cognito.
 * 
 * 
 * columns and relationships of "users"
 */
export type UsersCampaign_Approvals_AggregateArgs = {
  distinct_on?: Maybe<Array<Campaign_Approvals_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Campaign_Approvals_Order_By>>;
  where?: Maybe<Campaign_Approvals_Bool_Exp>;
};


/**
 * App user info. The user_id and groups come from cognito.
 * 
 * 
 * columns and relationships of "users"
 */
export type UsersGroupsArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "users" */
export type Users_Aggregate = {
  __typename?: 'users_aggregate';
  aggregate?: Maybe<Users_Aggregate_Fields>;
  nodes: Array<Users>;
};

/** aggregate fields of "users" */
export type Users_Aggregate_Fields = {
  __typename?: 'users_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Users_Max_Fields>;
  min?: Maybe<Users_Min_Fields>;
};


/** aggregate fields of "users" */
export type Users_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Users_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "users" */
export type Users_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Users_Max_Order_By>;
  min?: Maybe<Users_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Users_Append_Input = {
  groups?: Maybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "users" */
export type Users_Arr_Rel_Insert_Input = {
  data: Array<Users_Insert_Input>;
  on_conflict?: Maybe<Users_On_Conflict>;
};

/** columns and relationships of "users_audit" */
export type Users_Audit = {
  __typename?: 'users_audit';
  action?: Maybe<Scalars['String']>;
  action_tstamp_clk?: Maybe<Scalars['timestamptz']>;
  action_tstamp_stm?: Maybe<Scalars['timestamptz']>;
  action_tstamp_tx?: Maybe<Scalars['timestamptz']>;
  application_name?: Maybe<Scalars['String']>;
  changed_fields?: Maybe<Scalars['jsonb']>;
  client_addr?: Maybe<Scalars['inet']>;
  client_port?: Maybe<Scalars['Int']>;
  client_query?: Maybe<Scalars['String']>;
  event_id?: Maybe<Scalars['bigint']>;
  hasura_user?: Maybe<Scalars['jsonb']>;
  relid?: Maybe<Scalars['oid']>;
  row_data?: Maybe<Scalars['jsonb']>;
  schema_name?: Maybe<Scalars['String']>;
  /** An object relationship */
  session_user?: Maybe<Users>;
  session_user_name?: Maybe<Scalars['String']>;
  statement_only?: Maybe<Scalars['Boolean']>;
  table_name?: Maybe<Scalars['String']>;
  transaction_id?: Maybe<Scalars['bigint']>;
  user_id?: Maybe<Scalars['String']>;
};


/** columns and relationships of "users_audit" */
export type Users_AuditChanged_FieldsArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "users_audit" */
export type Users_AuditHasura_UserArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "users_audit" */
export type Users_AuditRow_DataArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "users_audit" */
export type Users_Audit_Aggregate = {
  __typename?: 'users_audit_aggregate';
  aggregate?: Maybe<Users_Audit_Aggregate_Fields>;
  nodes: Array<Users_Audit>;
};

/** aggregate fields of "users_audit" */
export type Users_Audit_Aggregate_Fields = {
  __typename?: 'users_audit_aggregate_fields';
  avg?: Maybe<Users_Audit_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Users_Audit_Max_Fields>;
  min?: Maybe<Users_Audit_Min_Fields>;
  stddev?: Maybe<Users_Audit_Stddev_Fields>;
  stddev_pop?: Maybe<Users_Audit_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Users_Audit_Stddev_Samp_Fields>;
  sum?: Maybe<Users_Audit_Sum_Fields>;
  var_pop?: Maybe<Users_Audit_Var_Pop_Fields>;
  var_samp?: Maybe<Users_Audit_Var_Samp_Fields>;
  variance?: Maybe<Users_Audit_Variance_Fields>;
};


/** aggregate fields of "users_audit" */
export type Users_Audit_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Users_Audit_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "users_audit" */
export type Users_Audit_Aggregate_Order_By = {
  avg?: Maybe<Users_Audit_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Users_Audit_Max_Order_By>;
  min?: Maybe<Users_Audit_Min_Order_By>;
  stddev?: Maybe<Users_Audit_Stddev_Order_By>;
  stddev_pop?: Maybe<Users_Audit_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Users_Audit_Stddev_Samp_Order_By>;
  sum?: Maybe<Users_Audit_Sum_Order_By>;
  var_pop?: Maybe<Users_Audit_Var_Pop_Order_By>;
  var_samp?: Maybe<Users_Audit_Var_Samp_Order_By>;
  variance?: Maybe<Users_Audit_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Users_Audit_Append_Input = {
  changed_fields?: Maybe<Scalars['jsonb']>;
  hasura_user?: Maybe<Scalars['jsonb']>;
  row_data?: Maybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "users_audit" */
export type Users_Audit_Arr_Rel_Insert_Input = {
  data: Array<Users_Audit_Insert_Input>;
};

/** aggregate avg on columns */
export type Users_Audit_Avg_Fields = {
  __typename?: 'users_audit_avg_fields';
  client_port?: Maybe<Scalars['Float']>;
  event_id?: Maybe<Scalars['Float']>;
  transaction_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "users_audit" */
export type Users_Audit_Avg_Order_By = {
  client_port?: Maybe<Order_By>;
  event_id?: Maybe<Order_By>;
  transaction_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "users_audit". All fields are combined with a logical 'AND'. */
export type Users_Audit_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Users_Audit_Bool_Exp>>>;
  _not?: Maybe<Users_Audit_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Users_Audit_Bool_Exp>>>;
  action?: Maybe<String_Comparison_Exp>;
  action_tstamp_clk?: Maybe<Timestamptz_Comparison_Exp>;
  action_tstamp_stm?: Maybe<Timestamptz_Comparison_Exp>;
  action_tstamp_tx?: Maybe<Timestamptz_Comparison_Exp>;
  application_name?: Maybe<String_Comparison_Exp>;
  changed_fields?: Maybe<Jsonb_Comparison_Exp>;
  client_addr?: Maybe<Inet_Comparison_Exp>;
  client_port?: Maybe<Int_Comparison_Exp>;
  client_query?: Maybe<String_Comparison_Exp>;
  event_id?: Maybe<Bigint_Comparison_Exp>;
  hasura_user?: Maybe<Jsonb_Comparison_Exp>;
  relid?: Maybe<Oid_Comparison_Exp>;
  row_data?: Maybe<Jsonb_Comparison_Exp>;
  schema_name?: Maybe<String_Comparison_Exp>;
  session_user?: Maybe<Users_Bool_Exp>;
  session_user_name?: Maybe<String_Comparison_Exp>;
  statement_only?: Maybe<Boolean_Comparison_Exp>;
  table_name?: Maybe<String_Comparison_Exp>;
  transaction_id?: Maybe<Bigint_Comparison_Exp>;
  user_id?: Maybe<String_Comparison_Exp>;
};

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Users_Audit_Delete_At_Path_Input = {
  changed_fields?: Maybe<Array<Maybe<Scalars['String']>>>;
  hasura_user?: Maybe<Array<Maybe<Scalars['String']>>>;
  row_data?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Users_Audit_Delete_Elem_Input = {
  changed_fields?: Maybe<Scalars['Int']>;
  hasura_user?: Maybe<Scalars['Int']>;
  row_data?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Users_Audit_Delete_Key_Input = {
  changed_fields?: Maybe<Scalars['String']>;
  hasura_user?: Maybe<Scalars['String']>;
  row_data?: Maybe<Scalars['String']>;
};

/** input type for incrementing integer column in table "users_audit" */
export type Users_Audit_Inc_Input = {
  client_port?: Maybe<Scalars['Int']>;
  event_id?: Maybe<Scalars['bigint']>;
  transaction_id?: Maybe<Scalars['bigint']>;
};

/** input type for inserting data into table "users_audit" */
export type Users_Audit_Insert_Input = {
  action?: Maybe<Scalars['String']>;
  action_tstamp_clk?: Maybe<Scalars['timestamptz']>;
  action_tstamp_stm?: Maybe<Scalars['timestamptz']>;
  action_tstamp_tx?: Maybe<Scalars['timestamptz']>;
  application_name?: Maybe<Scalars['String']>;
  changed_fields?: Maybe<Scalars['jsonb']>;
  client_addr?: Maybe<Scalars['inet']>;
  client_port?: Maybe<Scalars['Int']>;
  client_query?: Maybe<Scalars['String']>;
  event_id?: Maybe<Scalars['bigint']>;
  hasura_user?: Maybe<Scalars['jsonb']>;
  relid?: Maybe<Scalars['oid']>;
  row_data?: Maybe<Scalars['jsonb']>;
  schema_name?: Maybe<Scalars['String']>;
  session_user?: Maybe<Users_Obj_Rel_Insert_Input>;
  session_user_name?: Maybe<Scalars['String']>;
  statement_only?: Maybe<Scalars['Boolean']>;
  table_name?: Maybe<Scalars['String']>;
  transaction_id?: Maybe<Scalars['bigint']>;
  user_id?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Users_Audit_Max_Fields = {
  __typename?: 'users_audit_max_fields';
  action?: Maybe<Scalars['String']>;
  action_tstamp_clk?: Maybe<Scalars['timestamptz']>;
  action_tstamp_stm?: Maybe<Scalars['timestamptz']>;
  action_tstamp_tx?: Maybe<Scalars['timestamptz']>;
  application_name?: Maybe<Scalars['String']>;
  client_port?: Maybe<Scalars['Int']>;
  client_query?: Maybe<Scalars['String']>;
  event_id?: Maybe<Scalars['bigint']>;
  schema_name?: Maybe<Scalars['String']>;
  session_user_name?: Maybe<Scalars['String']>;
  table_name?: Maybe<Scalars['String']>;
  transaction_id?: Maybe<Scalars['bigint']>;
  user_id?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "users_audit" */
export type Users_Audit_Max_Order_By = {
  action?: Maybe<Order_By>;
  action_tstamp_clk?: Maybe<Order_By>;
  action_tstamp_stm?: Maybe<Order_By>;
  action_tstamp_tx?: Maybe<Order_By>;
  application_name?: Maybe<Order_By>;
  client_port?: Maybe<Order_By>;
  client_query?: Maybe<Order_By>;
  event_id?: Maybe<Order_By>;
  schema_name?: Maybe<Order_By>;
  session_user_name?: Maybe<Order_By>;
  table_name?: Maybe<Order_By>;
  transaction_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Users_Audit_Min_Fields = {
  __typename?: 'users_audit_min_fields';
  action?: Maybe<Scalars['String']>;
  action_tstamp_clk?: Maybe<Scalars['timestamptz']>;
  action_tstamp_stm?: Maybe<Scalars['timestamptz']>;
  action_tstamp_tx?: Maybe<Scalars['timestamptz']>;
  application_name?: Maybe<Scalars['String']>;
  client_port?: Maybe<Scalars['Int']>;
  client_query?: Maybe<Scalars['String']>;
  event_id?: Maybe<Scalars['bigint']>;
  schema_name?: Maybe<Scalars['String']>;
  session_user_name?: Maybe<Scalars['String']>;
  table_name?: Maybe<Scalars['String']>;
  transaction_id?: Maybe<Scalars['bigint']>;
  user_id?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "users_audit" */
export type Users_Audit_Min_Order_By = {
  action?: Maybe<Order_By>;
  action_tstamp_clk?: Maybe<Order_By>;
  action_tstamp_stm?: Maybe<Order_By>;
  action_tstamp_tx?: Maybe<Order_By>;
  application_name?: Maybe<Order_By>;
  client_port?: Maybe<Order_By>;
  client_query?: Maybe<Order_By>;
  event_id?: Maybe<Order_By>;
  schema_name?: Maybe<Order_By>;
  session_user_name?: Maybe<Order_By>;
  table_name?: Maybe<Order_By>;
  transaction_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "users_audit" */
export type Users_Audit_Mutation_Response = {
  __typename?: 'users_audit_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Users_Audit>;
};

/** input type for inserting object relation for remote table "users_audit" */
export type Users_Audit_Obj_Rel_Insert_Input = {
  data: Users_Audit_Insert_Input;
};

/** ordering options when selecting data from "users_audit" */
export type Users_Audit_Order_By = {
  action?: Maybe<Order_By>;
  action_tstamp_clk?: Maybe<Order_By>;
  action_tstamp_stm?: Maybe<Order_By>;
  action_tstamp_tx?: Maybe<Order_By>;
  application_name?: Maybe<Order_By>;
  changed_fields?: Maybe<Order_By>;
  client_addr?: Maybe<Order_By>;
  client_port?: Maybe<Order_By>;
  client_query?: Maybe<Order_By>;
  event_id?: Maybe<Order_By>;
  hasura_user?: Maybe<Order_By>;
  relid?: Maybe<Order_By>;
  row_data?: Maybe<Order_By>;
  schema_name?: Maybe<Order_By>;
  session_user?: Maybe<Users_Order_By>;
  session_user_name?: Maybe<Order_By>;
  statement_only?: Maybe<Order_By>;
  table_name?: Maybe<Order_By>;
  transaction_id?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Users_Audit_Prepend_Input = {
  changed_fields?: Maybe<Scalars['jsonb']>;
  hasura_user?: Maybe<Scalars['jsonb']>;
  row_data?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "users_audit" */
export enum Users_Audit_Select_Column {
  /** column name */
  Action = 'action',
  /** column name */
  ActionTstampClk = 'action_tstamp_clk',
  /** column name */
  ActionTstampStm = 'action_tstamp_stm',
  /** column name */
  ActionTstampTx = 'action_tstamp_tx',
  /** column name */
  ApplicationName = 'application_name',
  /** column name */
  ChangedFields = 'changed_fields',
  /** column name */
  ClientAddr = 'client_addr',
  /** column name */
  ClientPort = 'client_port',
  /** column name */
  ClientQuery = 'client_query',
  /** column name */
  EventId = 'event_id',
  /** column name */
  HasuraUser = 'hasura_user',
  /** column name */
  Relid = 'relid',
  /** column name */
  RowData = 'row_data',
  /** column name */
  SchemaName = 'schema_name',
  /** column name */
  SessionUserName = 'session_user_name',
  /** column name */
  StatementOnly = 'statement_only',
  /** column name */
  TableName = 'table_name',
  /** column name */
  TransactionId = 'transaction_id',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "users_audit" */
export type Users_Audit_Set_Input = {
  action?: Maybe<Scalars['String']>;
  action_tstamp_clk?: Maybe<Scalars['timestamptz']>;
  action_tstamp_stm?: Maybe<Scalars['timestamptz']>;
  action_tstamp_tx?: Maybe<Scalars['timestamptz']>;
  application_name?: Maybe<Scalars['String']>;
  changed_fields?: Maybe<Scalars['jsonb']>;
  client_addr?: Maybe<Scalars['inet']>;
  client_port?: Maybe<Scalars['Int']>;
  client_query?: Maybe<Scalars['String']>;
  event_id?: Maybe<Scalars['bigint']>;
  hasura_user?: Maybe<Scalars['jsonb']>;
  relid?: Maybe<Scalars['oid']>;
  row_data?: Maybe<Scalars['jsonb']>;
  schema_name?: Maybe<Scalars['String']>;
  session_user_name?: Maybe<Scalars['String']>;
  statement_only?: Maybe<Scalars['Boolean']>;
  table_name?: Maybe<Scalars['String']>;
  transaction_id?: Maybe<Scalars['bigint']>;
  user_id?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Users_Audit_Stddev_Fields = {
  __typename?: 'users_audit_stddev_fields';
  client_port?: Maybe<Scalars['Float']>;
  event_id?: Maybe<Scalars['Float']>;
  transaction_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "users_audit" */
export type Users_Audit_Stddev_Order_By = {
  client_port?: Maybe<Order_By>;
  event_id?: Maybe<Order_By>;
  transaction_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Users_Audit_Stddev_Pop_Fields = {
  __typename?: 'users_audit_stddev_pop_fields';
  client_port?: Maybe<Scalars['Float']>;
  event_id?: Maybe<Scalars['Float']>;
  transaction_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "users_audit" */
export type Users_Audit_Stddev_Pop_Order_By = {
  client_port?: Maybe<Order_By>;
  event_id?: Maybe<Order_By>;
  transaction_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Users_Audit_Stddev_Samp_Fields = {
  __typename?: 'users_audit_stddev_samp_fields';
  client_port?: Maybe<Scalars['Float']>;
  event_id?: Maybe<Scalars['Float']>;
  transaction_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "users_audit" */
export type Users_Audit_Stddev_Samp_Order_By = {
  client_port?: Maybe<Order_By>;
  event_id?: Maybe<Order_By>;
  transaction_id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Users_Audit_Sum_Fields = {
  __typename?: 'users_audit_sum_fields';
  client_port?: Maybe<Scalars['Int']>;
  event_id?: Maybe<Scalars['bigint']>;
  transaction_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "users_audit" */
export type Users_Audit_Sum_Order_By = {
  client_port?: Maybe<Order_By>;
  event_id?: Maybe<Order_By>;
  transaction_id?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Users_Audit_Var_Pop_Fields = {
  __typename?: 'users_audit_var_pop_fields';
  client_port?: Maybe<Scalars['Float']>;
  event_id?: Maybe<Scalars['Float']>;
  transaction_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "users_audit" */
export type Users_Audit_Var_Pop_Order_By = {
  client_port?: Maybe<Order_By>;
  event_id?: Maybe<Order_By>;
  transaction_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Users_Audit_Var_Samp_Fields = {
  __typename?: 'users_audit_var_samp_fields';
  client_port?: Maybe<Scalars['Float']>;
  event_id?: Maybe<Scalars['Float']>;
  transaction_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "users_audit" */
export type Users_Audit_Var_Samp_Order_By = {
  client_port?: Maybe<Order_By>;
  event_id?: Maybe<Order_By>;
  transaction_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Users_Audit_Variance_Fields = {
  __typename?: 'users_audit_variance_fields';
  client_port?: Maybe<Scalars['Float']>;
  event_id?: Maybe<Scalars['Float']>;
  transaction_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "users_audit" */
export type Users_Audit_Variance_Order_By = {
  client_port?: Maybe<Order_By>;
  event_id?: Maybe<Order_By>;
  transaction_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "users". All fields are combined with a logical 'AND'. */
export type Users_Bool_Exp = {
  _and?: Maybe<Array<Maybe<Users_Bool_Exp>>>;
  _not?: Maybe<Users_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Users_Bool_Exp>>>;
  campaign_approvals?: Maybe<Campaign_Approvals_Bool_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  email_verified?: Maybe<Boolean_Comparison_Exp>;
  groups?: Maybe<Jsonb_Comparison_Exp>;
  identity_id?: Maybe<String_Comparison_Exp>;
  is_disabled?: Maybe<Boolean_Comparison_Exp>;
  last_login_at?: Maybe<Timestamptz_Comparison_Exp>;
  network?: Maybe<Networks_Bool_Exp>;
  network_id?: Maybe<String_Comparison_Exp>;
  organization?: Maybe<Organizations_Bool_Exp>;
  organization_id?: Maybe<String_Comparison_Exp>;
  photo?: Maybe<String_Comparison_Exp>;
  practice?: Maybe<Practices_Bool_Exp>;
  practice_id?: Maybe<String_Comparison_Exp>;
  preferred_mfa?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  user_email?: Maybe<String_Comparison_Exp>;
  user_id?: Maybe<String_Comparison_Exp>;
  user_name?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "users" */
export enum Users_Constraint {
  /** unique or primary key constraint */
  UsersPkey = 'users_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Users_Delete_At_Path_Input = {
  groups?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Users_Delete_Elem_Input = {
  groups?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Users_Delete_Key_Input = {
  groups?: Maybe<Scalars['String']>;
};

/** input type for inserting data into table "users" */
export type Users_Insert_Input = {
  campaign_approvals?: Maybe<Campaign_Approvals_Arr_Rel_Insert_Input>;
  created_at?: Maybe<Scalars['timestamptz']>;
  email_verified?: Maybe<Scalars['Boolean']>;
  groups?: Maybe<Scalars['jsonb']>;
  identity_id?: Maybe<Scalars['String']>;
  is_disabled?: Maybe<Scalars['Boolean']>;
  last_login_at?: Maybe<Scalars['timestamptz']>;
  network?: Maybe<Networks_Obj_Rel_Insert_Input>;
  network_id?: Maybe<Scalars['String']>;
  organization?: Maybe<Organizations_Obj_Rel_Insert_Input>;
  organization_id?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  practice?: Maybe<Practices_Obj_Rel_Insert_Input>;
  practice_id?: Maybe<Scalars['String']>;
  preferred_mfa?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_email?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['String']>;
  user_name?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Users_Max_Fields = {
  __typename?: 'users_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  identity_id?: Maybe<Scalars['String']>;
  last_login_at?: Maybe<Scalars['timestamptz']>;
  network_id?: Maybe<Scalars['String']>;
  organization_id?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  practice_id?: Maybe<Scalars['String']>;
  preferred_mfa?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_email?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['String']>;
  user_name?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "users" */
export type Users_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  identity_id?: Maybe<Order_By>;
  last_login_at?: Maybe<Order_By>;
  network_id?: Maybe<Order_By>;
  organization_id?: Maybe<Order_By>;
  photo?: Maybe<Order_By>;
  practice_id?: Maybe<Order_By>;
  preferred_mfa?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user_email?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
  user_name?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Users_Min_Fields = {
  __typename?: 'users_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  identity_id?: Maybe<Scalars['String']>;
  last_login_at?: Maybe<Scalars['timestamptz']>;
  network_id?: Maybe<Scalars['String']>;
  organization_id?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  practice_id?: Maybe<Scalars['String']>;
  preferred_mfa?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_email?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['String']>;
  user_name?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "users" */
export type Users_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  identity_id?: Maybe<Order_By>;
  last_login_at?: Maybe<Order_By>;
  network_id?: Maybe<Order_By>;
  organization_id?: Maybe<Order_By>;
  photo?: Maybe<Order_By>;
  practice_id?: Maybe<Order_By>;
  preferred_mfa?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user_email?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
  user_name?: Maybe<Order_By>;
};

/** response of any mutation on the table "users" */
export type Users_Mutation_Response = {
  __typename?: 'users_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Users>;
};

/** input type for inserting object relation for remote table "users" */
export type Users_Obj_Rel_Insert_Input = {
  data: Users_Insert_Input;
  on_conflict?: Maybe<Users_On_Conflict>;
};

/** on conflict condition type for table "users" */
export type Users_On_Conflict = {
  constraint: Users_Constraint;
  update_columns: Array<Users_Update_Column>;
  where?: Maybe<Users_Bool_Exp>;
};

/** ordering options when selecting data from "users" */
export type Users_Order_By = {
  campaign_approvals_aggregate?: Maybe<Campaign_Approvals_Aggregate_Order_By>;
  created_at?: Maybe<Order_By>;
  email_verified?: Maybe<Order_By>;
  groups?: Maybe<Order_By>;
  identity_id?: Maybe<Order_By>;
  is_disabled?: Maybe<Order_By>;
  last_login_at?: Maybe<Order_By>;
  network?: Maybe<Networks_Order_By>;
  network_id?: Maybe<Order_By>;
  organization?: Maybe<Organizations_Order_By>;
  organization_id?: Maybe<Order_By>;
  photo?: Maybe<Order_By>;
  practice?: Maybe<Practices_Order_By>;
  practice_id?: Maybe<Order_By>;
  preferred_mfa?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user_email?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
  user_name?: Maybe<Order_By>;
};

/** primary key columns input for table: "users" */
export type Users_Pk_Columns_Input = {
  /** Same as cognito user id and x-hasura-user-id. */
  user_id: Scalars['String'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Users_Prepend_Input = {
  groups?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "users" */
export enum Users_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EmailVerified = 'email_verified',
  /** column name */
  Groups = 'groups',
  /** column name */
  IdentityId = 'identity_id',
  /** column name */
  IsDisabled = 'is_disabled',
  /** column name */
  LastLoginAt = 'last_login_at',
  /** column name */
  NetworkId = 'network_id',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  Photo = 'photo',
  /** column name */
  PracticeId = 'practice_id',
  /** column name */
  PreferredMfa = 'preferred_mfa',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserEmail = 'user_email',
  /** column name */
  UserId = 'user_id',
  /** column name */
  UserName = 'user_name'
}

/** input type for updating data in table "users" */
export type Users_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  email_verified?: Maybe<Scalars['Boolean']>;
  groups?: Maybe<Scalars['jsonb']>;
  identity_id?: Maybe<Scalars['String']>;
  is_disabled?: Maybe<Scalars['Boolean']>;
  last_login_at?: Maybe<Scalars['timestamptz']>;
  network_id?: Maybe<Scalars['String']>;
  organization_id?: Maybe<Scalars['String']>;
  photo?: Maybe<Scalars['String']>;
  practice_id?: Maybe<Scalars['String']>;
  preferred_mfa?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_email?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['String']>;
  user_name?: Maybe<Scalars['String']>;
};

/** update columns of table "users" */
export enum Users_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EmailVerified = 'email_verified',
  /** column name */
  Groups = 'groups',
  /** column name */
  IdentityId = 'identity_id',
  /** column name */
  IsDisabled = 'is_disabled',
  /** column name */
  LastLoginAt = 'last_login_at',
  /** column name */
  NetworkId = 'network_id',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  Photo = 'photo',
  /** column name */
  PracticeId = 'practice_id',
  /** column name */
  PreferredMfa = 'preferred_mfa',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserEmail = 'user_email',
  /** column name */
  UserId = 'user_id',
  /** column name */
  UserName = 'user_name'
}


export type UserBasicFragment = (
  { __typename?: 'users' }
  & Pick<Users, 'user_id' | 'user_email' | 'user_name' | 'photo' | 'groups' | 'practice_id' | 'network_id' | 'organization_id' | 'created_at' | 'updated_at' | 'is_disabled' | 'identity_id' | 'preferred_mfa' | 'last_login_at'>
);

export type PatientBasicFragment = (
  { __typename?: 'patients' }
  & Pick<Patients, 'patient_id' | 'practice_id' | 'external_id' | 'patient_name' | 'birth_date' | 'patient_address' | 'patient_phone' | 'patient_email' | 'address_verification_result' | 'email_validation_result' | 'mbi' | 'primary_insurance_payer_id' | 'primary_insurance_payer_name' | 'primary_insurance_plan_name' | 'primary_insurance_member_id' | 'primary_insurance_group_id' | 'secondary_insurance_payer_id' | 'secondary_insurance_payer_name' | 'secondary_insurance_plan_name' | 'secondary_insurance_member_id' | 'secondary_insurance_group_id' | 'patient_pcp' | 'suspected_provider_npi' | 'alignment_tin' | 'sex_assigned_at_birth' | 'do_not_email' | 'do_not_direct_mail' | 'is_archived' | 'deceased_as_of_date' | 'patient_notes' | 'patient_logo' | 'created_at' | 'updated_at'>
);

export type ProviderBasicFragment = (
  { __typename?: 'providers' }
  & Pick<Providers, 'provider_id' | 'provider_name' | 'is_archived'>
);

export type ProviderMembershipBasicFragment = (
  { __typename?: 'provider_memberships' }
  & Pick<Provider_Memberships, 'provider_id' | 'network_id' | 'is_archived'>
);

export type ProviderEmploymentBasicFragment = (
  { __typename?: 'provider_employments' }
  & Pick<Provider_Employments, 'provider_id' | 'practice_id' | 'is_archived' | 'tin'>
);

export type PracticeBasicFragment = (
  { __typename?: 'practices' }
  & Pick<Practices, 'practice_id' | 'practice_name' | 'practice_address' | 'practice_description' | 'practice_language' | 'legal_business_name' | 'tin' | 'default_provider_id' | 'is_archived'>
);

export type NetworkTagFragment = (
  { __typename?: 'networks' }
  & Pick<Networks, 'network_id' | 'network_name' | 'network_number' | 'network_description' | 'dc_id' | 'is_archived'>
);

export type NetworkBasicFragment = (
  { __typename?: 'networks' }
  & Pick<Networks, 'network_id' | 'network_number' | 'network_slug' | 'network_name' | 'network_description' | 'network_logo' | 'network_color' | 'network_merge_vars' | 'broker_phone_for_emails' | 'broker_phone_for_letters' | 'broker_url_for_emails' | 'broker_url_for_letters' | 'broker_name' | 'broker_disclaimer' | 'is_archived' | 'dc_id' | 'default_practice_id'>
  & NetworkTagFragment
);

export type OrganizationBasicFragment = (
  { __typename?: 'organizations' }
  & Pick<Organizations, 'organization_id' | 'organization_name' | 'organization_description' | 'dashboards' | 'is_archived'>
);

export type AccountCreationRequestBasicFragment = (
  { __typename?: 'account_creation_requests' }
  & Pick<Account_Creation_Requests, 'account_creation_request_id' | 'network_id' | 'network_user_email' | 'practice_name' | 'practice_id' | 'practice_user_name' | 'practice_user_email' | 'practice_user_phone' | 'providers_text' | 'signatory_name' | 'signatory_email' | 'comments' | 'status' | 'created_at'>
);

export type BusinessAssociateAgreementBasicFragment = (
  { __typename?: 'business_associate_agreements' }
  & Pick<Business_Associate_Agreements, 'external_id' | 'external_source' | 'status' | 'recipient_email' | 'recipient_name' | 'updated_at'>
);

export type PatientSegmentBasicFragment = (
  { __typename?: 'patient_segments' }
  & Pick<Patient_Segments, 'patient_segment_id' | 'patient_segment_description'>
);

export type LetterBasicFragment = (
  { __typename?: 'letters' }
  & Pick<Letters, 'practice_id' | 'external_id' | 'updated_at' | 'send_datetime' | 'letter_id' | 'status'>
);

export type EmailBasicFragment = (
  { __typename?: 'emails' }
  & Pick<Emails, 'practice_id' | 'updated_at' | 'send_datetime' | 'email_id' | 'status'>
);

export type SmsBasicFragment = (
  { __typename?: 'sms_messages' }
  & Pick<Sms_Messages, 'practice_id' | 'updated_at' | 'send_datetime' | 'sms_message_id' | 'status'>
);

export type LetterTemplateBasicFragment = (
  { __typename?: 'letter_templates' }
  & Pick<Letter_Templates, 'letter_template_id' | 'default_merge_vars' | 'external_id' | 'external_secondary_id' | 'external_source' | 'external_version' | 'letter_template_name' | 'format' | 'size' | 'num_pages' | 'is_color' | 'perforated_page' | 'webform_url' | 'letter_from' | 'letter_company' | 'is_public'>
);

export type EmailTemplateBasicFragment = (
  { __typename?: 'email_templates' }
  & Pick<Email_Templates, 'email_template_id' | 'default_merge_vars' | 'external_source' | 'external_version' | 'email_template_name' | 'email_subject' | 'body_html' | 'email_from' | 'webform_url'>
);

export type SmsTemplateBasicFragment = (
  { __typename?: 'sms_templates' }
  & Pick<Sms_Templates, 'sms_template_id' | 'sms_template_name' | 'body_text' | 'webform_url' | 'default_merge_vars'>
);

export type CampaignTemplateBasicFragment = (
  { __typename?: 'campaign_templates' }
  & Pick<Campaign_Templates, 'campaign_template_id' | 'campaign_name' | 'campaign_description' | 'campaign_merge_vars' | 'is_archived'>
  & { network?: Maybe<(
    { __typename?: 'networks' }
    & NetworkTagFragment
  )>, practice?: Maybe<(
    { __typename?: 'practices' }
    & PracticeBasicFragment
  )> }
);

export type CampaignLetterComponentBasicFragment = (
  { __typename?: 'campaign_letter_components' }
  & Pick<Campaign_Letter_Components, 'campaign_letter_component_id' | 'patient_segment_id' | 'letter_template_id' | 'return_envelope_id' | 'custom_envelope_id' | 'is_first_class' | 'skip_undeliverable_addresses' | 'component_merge_vars' | 'send_datetime' | 'is_archived'>
);

export type CampaignEmailComponentBasicFragment = (
  { __typename?: 'campaign_email_components' }
  & Pick<Campaign_Email_Components, 'campaign_email_component_id' | 'patient_segment_id' | 'email_template_id' | 'component_merge_vars' | 'send_datetime' | 'is_archived'>
);

export type CampaignSmsComponentBasicFragment = (
  { __typename?: 'campaign_sms_components' }
  & Pick<Campaign_Sms_Components, 'campaign_sms_component_id' | 'patient_segment_id' | 'sms_template_id' | 'component_merge_vars' | 'send_datetime' | 'is_archived'>
);

export type CampaignApprovalBasicFragment = (
  { __typename?: 'campaign_approvals' }
  & Pick<Campaign_Approvals, 'practice_id' | 'campaign_template_id' | 'approved_by' | 'created_at'>
);

export type PatientRosterFileBasicFragment = (
  { __typename?: 'patient_roster_files' }
  & Pick<Patient_Roster_Files, 'identity_id' | 'level' | 'key' | 'practice_id' | 'field_mapping' | 'is_archived' | 'created_at' | 'updated_at'>
);

export type FileUploadBasicFragment = (
  { __typename?: 'file_uploads' }
  & Pick<File_Uploads, 'file_upload_id' | 'file_name' | 'identity_id' | 'level' | 'key' | 'file_category' | 'user_id' | 'practice_id' | 'network_id' | 'organization_id' | 'is_archived' | 'created_at' | 'updated_at' | 'upload_status'>
);

export type PvaBasicFragment = (
  { __typename?: 'pvas' }
  & Pick<Pvas, 'pva_id' | 'submission_id' | 'submission_source' | 'pva_practice_name' | 'provider_name_or_med_group' | 'beneficiary_name' | 'beneficiary_first_name' | 'beneficiary_last_name' | 'signature_raw' | 'is_signed' | 'signature_url' | 'signature_date_raw' | 'signature_date' | 'signer_printed_name' | 'mbi' | 'birth_date' | 'birth_date_raw' | 'ycode' | 'letter_id' | 'letter_id_prefix' | 'network_number' | 'language' | 'matched_patient_id' | 'transcriber_notes' | 'processing_status' | 'pva_practice_id' | 'pva_npi' | 'pva_tin' | 'onedrive_file_id' | 'created_at' | 'updated_at'>
);

export type HedrBasicFragment = (
  { __typename?: 'health_equity_reports' }
  & Pick<Health_Equity_Reports, 'health_equity_report_id' | 'submission_id' | 'submission_source' | 'mbi' | 'birth_date' | 'beneficiary_first_name' | 'beneficiary_last_name' | 'beneficiary_name' | 'letter_id' | 'letter_id_prefix' | 'network_number' | 'transcriber_notes' | 'processing_status' | 'matched_patient_id' | 'sex_assigned_at_birth' | 'channel' | 'onedrive_file_id' | 'sex_orientation' | 'sex_orientation_os' | 'gender_identity' | 'gender_identity_os' | 'preferred_language' | 'race' | 'ethnicity_base' | 'form_language' | 'form_practice_id' | 'created_at' | 'updated_at' | 'ocr_output' | 'practice_id_prefix' | 'short_url' | 'template_version' | 'ethnicity_expanded_1' | 'ethnicity_expanded_2' | 'ethnicity_expanded_3' | 'ethnicity_expanded_4' | 'date_assessment_complete' | 'assessment_declined' | 'NC_Q1' | 'NC_Q2' | 'NC_Q4' | 'NC_Q5' | 'NC_Q6' | 'NC_Q7' | 'NC_Q8' | 'NC_Q9' | 'NC_Q10' | 'AHC_71802_3' | 'AHC_LA31996_4' | 'AHC_LA28580_1' | 'AHC_LA31997_2' | 'AHC_LA31998_0' | 'AHC_LA31999_8' | 'AHC_LA32000_4' | 'AHC_LA32001_2' | 'AHC_LA9_3' | 'AHC_88122_7' | 'AHC_88123_5' | 'AHC_93030_5' | 'AHC_96779_4' | 'AHC_95618_5' | 'AHC_95617_7' | 'AHC_95616_9' | 'AHC_95615_1' | 'P_93035_4' | 'P_93034_7' | 'P_63512_8' | 'P_LA30122_8_FAM' | 'P_71802_3' | 'P_93033_9' | 'P_82589_3' | 'P_67875_5' | 'P_67875_5_OS' | 'P_63586_2' | 'P_LA30122_8_INC' | 'P_LA30125_1' | 'P_LA30126_9' | 'P_LA30124_4' | 'P_LA30127_7' | 'P_LA30128_5' | 'P_LA30129_3' | 'P_93031_3_O' | 'P_93031_3_OS' | 'P_LA30122_8_NEEDS' | 'P_93030_5' | 'P_93029_7' | 'P_93038_8' | 'P_93026_3' | 'P_76501_6'>
);

export type HedrDetailsFragment = (
  { __typename?: 'health_equity_reports' }
  & { pages: Health_Equity_Reports['ocr_output'] }
  & { network?: Maybe<(
    { __typename?: 'networks' }
    & NetworkBasicFragment
  )> }
  & HedrBasicFragment
);

export type UserDetailsFragment = (
  { __typename?: 'users' }
  & { practice?: Maybe<(
    { __typename?: 'practices' }
    & PracticeBasicFragment
  )>, network?: Maybe<(
    { __typename?: 'networks' }
    & NetworkBasicFragment
  )>, organization?: Maybe<(
    { __typename?: 'organizations' }
    & OrganizationBasicFragment
  )> }
  & UserBasicFragment
);

export type PatientDetailsFragment = (
  { __typename?: 'patients' }
  & { deliverability: Patients['address_verification_result'] }
  & { patient_segment_label?: Maybe<(
    { __typename?: 'patient_segment_labels' }
    & Pick<Patient_Segment_Labels, 'patient_id' | 'patient_segment_id'>
  )>, suspected_provider?: Maybe<(
    { __typename?: 'providers' }
    & Pick<Providers, 'provider_id' | 'provider_name'>
  )> }
  & PatientBasicFragment
);

export type OrganizationDetailsFragment = (
  { __typename?: 'organizations' }
  & { organization_networks: Array<(
    { __typename?: 'organization_networks' }
    & Pick<Organization_Networks, 'is_archived' | 'organization_id' | 'network_id'>
    & { network: (
      { __typename?: 'networks' }
      & NetworkBasicFragment
    ) }
  )> }
  & OrganizationBasicFragment
);

export type NetworkDetailsFragment = (
  { __typename?: 'networks' }
  & { default_practice?: Maybe<(
    { __typename?: 'practices' }
    & PracticeBasicFragment
  )>, provider_memberships: Array<(
    { __typename?: 'provider_memberships' }
    & { provider: (
      { __typename?: 'providers' }
      & ProviderDetailsFragment
    ) }
    & ProviderMembershipBasicFragment
  )> }
  & NetworkBasicFragment
);

export type ProviderDetailsFragment = (
  { __typename?: 'providers' }
  & { provider_employments: Array<(
    { __typename?: 'provider_employments' }
    & { practice: (
      { __typename?: 'practices' }
      & PracticeBasicFragment
    ) }
    & ProviderEmploymentBasicFragment
  )>, provider_memberships: Array<(
    { __typename?: 'provider_memberships' }
    & { network: (
      { __typename?: 'networks' }
      & NetworkBasicFragment
    ) }
    & ProviderMembershipBasicFragment
  )> }
  & ProviderBasicFragment
);

export type PracticeDetailsFragment = (
  { __typename?: 'practices' }
  & Pick<Practices, 'address_verification_result' | 'practice_company' | 'main_doctor_name_extension' | 'tin' | 'primary_network_id' | 'practice_logo' | 'practice_color' | 'practice_merge_vars' | 'default_provider_id'>
  & { deliverability: Practices['address_verification_result'] }
  & { default_provider?: Maybe<(
    { __typename?: 'providers' }
    & ProviderBasicFragment
  )>, primary_network?: Maybe<(
    { __typename?: 'networks' }
    & NetworkBasicFragment
  )>, provider_employments: Array<(
    { __typename?: 'provider_employments' }
    & { provider: (
      { __typename?: 'providers' }
      & ProviderDetailsFragment
    ) }
    & ProviderEmploymentBasicFragment
  )>, users: Array<(
    { __typename?: 'users' }
    & UserBasicFragment
  )>, baas: Array<(
    { __typename?: 'business_associate_agreements' }
    & BusinessAssociateAgreementBasicFragment
  )>, patients_aggregate: (
    { __typename?: 'patients_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'patients_aggregate_fields' }
      & Pick<Patients_Aggregate_Fields, 'count'>
    )> }
  ) }
  & PracticeBasicFragment
);

export type AccountCreationRequestDetailsFragment = (
  { __typename?: 'account_creation_requests' }
  & { practice?: Maybe<(
    { __typename?: 'practices' }
    & PracticeBasicFragment
  )>, network?: Maybe<(
    { __typename?: 'networks' }
    & NetworkBasicFragment
  )>, submitter?: Maybe<(
    { __typename?: 'users' }
    & UserBasicFragment
  )> }
  & AccountCreationRequestBasicFragment
);

export type CampaignLetterComponentDetailsFragment = (
  { __typename?: 'campaign_letter_components' }
  & { patient_segment: (
    { __typename?: 'patient_segments' }
    & PatientSegmentBasicFragment
  ), letter_template: (
    { __typename?: 'letter_templates' }
    & LetterTemplateBasicFragment
  ), tracking_ids: Array<(
    { __typename?: 'component_tracking_ids' }
    & Pick<Component_Tracking_Ids, 'component_id' | 'tracking_id'>
  )> }
  & CampaignLetterComponentBasicFragment
);

export type CampaignEmailComponentDetailsFragment = (
  { __typename?: 'campaign_email_components' }
  & { patient_segment: (
    { __typename?: 'patient_segments' }
    & PatientSegmentBasicFragment
  ), email_template: (
    { __typename?: 'email_templates' }
    & EmailTemplateBasicFragment
  ), tracking_ids: Array<(
    { __typename?: 'component_tracking_ids' }
    & Pick<Component_Tracking_Ids, 'component_id' | 'tracking_id'>
  )> }
  & CampaignEmailComponentBasicFragment
);

export type CampaignSmsComponentDetailsFragment = (
  { __typename?: 'campaign_sms_components' }
  & { patient_segment: (
    { __typename?: 'patient_segments' }
    & PatientSegmentBasicFragment
  ), sms_template: (
    { __typename?: 'sms_templates' }
    & SmsTemplateBasicFragment
  ), tracking_ids: Array<(
    { __typename?: 'component_tracking_ids' }
    & Pick<Component_Tracking_Ids, 'component_id' | 'tracking_id'>
  )> }
  & CampaignSmsComponentBasicFragment
);

export type CampaignTemplateDetailsFragment = (
  { __typename?: 'campaign_templates' }
  & { campaign_letter_components: Array<(
    { __typename?: 'campaign_letter_components' }
    & CampaignLetterComponentDetailsFragment
  )>, campaign_email_components: Array<(
    { __typename?: 'campaign_email_components' }
    & CampaignEmailComponentDetailsFragment
  )>, campaign_sms_components: Array<(
    { __typename?: 'campaign_sms_components' }
    & CampaignSmsComponentDetailsFragment
  )> }
  & CampaignTemplateBasicFragment
);

export type CampaignApprovalDetailsFragment = (
  { __typename?: 'campaign_approvals' }
  & { approver: (
    { __typename?: 'users' }
    & UserBasicFragment
  ), email_component_approvals: Array<(
    { __typename?: 'email_component_approvals' }
    & { campaign_email_component: (
      { __typename?: 'campaign_email_components' }
      & CampaignEmailComponentBasicFragment
    ) }
  )>, letter_component_approvals: Array<(
    { __typename?: 'letter_component_approvals' }
    & { campaign_letter_component: (
      { __typename?: 'campaign_letter_components' }
      & CampaignLetterComponentBasicFragment
    ) }
  )>, sms_component_approvals: Array<(
    { __typename?: 'sms_component_approvals' }
    & { campaign_sms_component: (
      { __typename?: 'campaign_sms_components' }
      & CampaignSmsComponentBasicFragment
    ) }
  )> }
  & CampaignApprovalBasicFragment
);

export type PvaDetailsFragment = (
  { __typename?: 'pvas' }
  & { pages: Pvas['ocr_output'], num_pages: Pvas['ocr_output'] }
  & { network?: Maybe<(
    { __typename?: 'networks' }
    & NetworkBasicFragment
  )>, matched_patient?: Maybe<(
    { __typename?: 'patients' }
    & { suspected_provider?: Maybe<(
      { __typename?: 'providers' }
      & Pick<Providers, 'provider_id' | 'provider_name'>
    )>, practice?: Maybe<(
      { __typename?: 'practices' }
      & PracticeBasicFragment
    )> }
    & PatientBasicFragment
  )> }
  & PvaBasicFragment
);

export type ExistsUserQueryVariables = Exact<{
  user_email: Scalars['String'];
}>;


export type ExistsUserQuery = (
  { __typename?: 'query_root' }
  & { users: Array<(
    { __typename?: 'users' }
    & Pick<Users, 'user_email'>
  )> }
);

export type SearchUsersAdvancedQueryVariables = Exact<{
  exact_query?: Maybe<Scalars['String']>;
  substring_query?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Users_Order_By>>;
  additional_filters?: Maybe<Array<Maybe<Users_Bool_Exp>>>;
  count_aggregate_limit?: Maybe<Scalars['Int']>;
}>;


export type SearchUsersAdvancedQuery = (
  { __typename?: 'query_root' }
  & { users: Array<(
    { __typename?: 'users' }
    & UserDetailsFragment
  )>, users_aggregate: (
    { __typename?: 'users_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'users_aggregate_fields' }
      & Pick<Users_Aggregate_Fields, 'count'>
    )> }
  ), networks: Array<(
    { __typename?: 'networks' }
    & NetworkBasicFragment
  )> }
);

export type GetUserDetailsQueryVariables = Exact<{
  user_id: Scalars['String'];
}>;


export type GetUserDetailsQuery = (
  { __typename?: 'query_root' }
  & { users_by_pk?: Maybe<(
    { __typename?: 'users' }
    & UserDetailsFragment
  )> }
);

export type CreateUserMutationVariables = Exact<{
  user_email: Scalars['String'];
  user_name: Scalars['String'];
  photo?: Maybe<Scalars['String']>;
  organization_id?: Maybe<Scalars['String']>;
  network_id?: Maybe<Scalars['String']>;
  practice_id?: Maybe<Scalars['String']>;
  is_admin?: Maybe<Scalars['Boolean']>;
}>;


export type CreateUserMutation = (
  { __typename?: 'mutation_root' }
  & { create_user?: Maybe<(
    { __typename?: 'CreateUserOutput' }
    & Pick<CreateUserOutput, 'user_id'>
    & { user?: Maybe<(
      { __typename?: 'users' }
      & { practice?: Maybe<(
        { __typename?: 'practices' }
        & { completed_baas: Array<(
          { __typename?: 'business_associate_agreements' }
          & Pick<Business_Associate_Agreements, 'external_id' | 'external_source'>
        )> }
      )> }
      & UserDetailsFragment
    )> }
  )> }
);

export type ResendUserInviteMutationVariables = Exact<{
  user_email: Scalars['String'];
}>;


export type ResendUserInviteMutation = (
  { __typename?: 'mutation_root' }
  & { resend_user_invite?: Maybe<(
    { __typename?: 'CreateUserOutput' }
    & Pick<CreateUserOutput, 'user_id'>
    & { user?: Maybe<(
      { __typename?: 'users' }
      & UserDetailsFragment
    )> }
  )> }
);

export type EnableUserMutationVariables = Exact<{
  user_id: Scalars['String'];
}>;


export type EnableUserMutation = (
  { __typename?: 'mutation_root' }
  & { enable_user?: Maybe<(
    { __typename?: 'EnableUserOutput' }
    & Pick<EnableUserOutput, 'user_id'>
    & { user?: Maybe<(
      { __typename?: 'users' }
      & UserDetailsFragment
    )> }
  )> }
);

export type DisableUserMutationVariables = Exact<{
  user_id: Scalars['String'];
}>;


export type DisableUserMutation = (
  { __typename?: 'mutation_root' }
  & { disable_user?: Maybe<(
    { __typename?: 'DisableUserOutput' }
    & Pick<DisableUserOutput, 'user_id'>
    & { user?: Maybe<(
      { __typename?: 'users' }
      & UserDetailsFragment
    )> }
  )> }
);

export type UpdateUserMutationVariables = Exact<{
  user_id: Scalars['String'];
  changes?: Maybe<UpdateUserAttributes>;
}>;


export type UpdateUserMutation = (
  { __typename?: 'mutation_root' }
  & { update_user?: Maybe<(
    { __typename?: 'UpdateUserOutput' }
    & Pick<UpdateUserOutput, 'user_id'>
    & { user?: Maybe<(
      { __typename?: 'users' }
      & UserDetailsFragment
    )> }
  )> }
);

export type AdminInsertUserMutationVariables = Exact<{
  user: Users_Insert_Input;
}>;


export type AdminInsertUserMutation = (
  { __typename?: 'mutation_root' }
  & { insert_users_one?: Maybe<(
    { __typename?: 'users' }
    & Pick<Users, 'user_id'>
  )> }
);

export type UpdateUserByPkMutationVariables = Exact<{
  user_id: Scalars['String'];
  changes?: Maybe<Users_Set_Input>;
}>;


export type UpdateUserByPkMutation = (
  { __typename?: 'mutation_root' }
  & { update_users_by_pk?: Maybe<(
    { __typename?: 'users' }
    & UserDetailsFragment
  )> }
);

export type UpsertUserMutationVariables = Exact<{
  user: Users_Insert_Input;
  cols_to_update: Array<Users_Update_Column>;
}>;


export type UpsertUserMutation = (
  { __typename?: 'mutation_root' }
  & { insert_users_one?: Maybe<(
    { __typename?: 'users' }
    & UserDetailsFragment
  )> }
);

export type ListOrganizationsQueryVariables = Exact<{ [key: string]: never; }>;


export type ListOrganizationsQuery = (
  { __typename?: 'query_root' }
  & { organizations: Array<(
    { __typename?: 'organizations' }
    & OrganizationDetailsFragment
  )> }
);

export type SearchOrganizationsQueryVariables = Exact<{
  query: Scalars['String'];
}>;


export type SearchOrganizationsQuery = (
  { __typename?: 'query_root' }
  & { organizations: Array<(
    { __typename?: 'organizations' }
    & OrganizationBasicFragment
  )> }
);

export type CreateOrganizationMutationVariables = Exact<{
  organization: Organizations_Insert_Input;
}>;


export type CreateOrganizationMutation = (
  { __typename?: 'mutation_root' }
  & { insert_organizations_one?: Maybe<(
    { __typename?: 'organizations' }
    & OrganizationDetailsFragment
  )> }
);

export type UpdateOrganizationMutationVariables = Exact<{
  organization_id: Scalars['String'];
  changes?: Maybe<Organizations_Set_Input>;
  organization_networks?: Array<Organization_Networks_Insert_Input>;
}>;


export type UpdateOrganizationMutation = (
  { __typename?: 'mutation_root' }
  & { update_organizations_by_pk?: Maybe<(
    { __typename?: 'organizations' }
    & OrganizationDetailsFragment
  )>, insert_organization_networks?: Maybe<(
    { __typename?: 'organization_networks_mutation_response' }
    & { returning: Array<(
      { __typename?: 'organization_networks' }
      & Pick<Organization_Networks, 'organization_id' | 'network_id' | 'is_archived'>
    )> }
  )> }
);

export type GetPracticesForOrganizationQueryVariables = Exact<{
  organization_id: Scalars['String'];
}>;


export type GetPracticesForOrganizationQuery = (
  { __typename?: 'query_root' }
  & { networks: Array<(
    { __typename?: 'networks' }
    & Pick<Networks, 'network_id' | 'network_name' | 'network_description'>
    & { practices: Array<(
      { __typename?: 'practices' }
      & Pick<Practices, 'practice_id' | 'practice_name' | 'practice_description'>
    )> }
  )> }
);

export type ListNetworksQueryVariables = Exact<{ [key: string]: never; }>;


export type ListNetworksQuery = (
  { __typename?: 'query_root' }
  & { networks: Array<(
    { __typename?: 'networks' }
    & NetworkDetailsFragment
  )> }
);

export type GetNetworkBasicBySlugQueryVariables = Exact<{
  network_slug: Scalars['String'];
}>;


export type GetNetworkBasicBySlugQuery = (
  { __typename?: 'query_root' }
  & { networks: Array<(
    { __typename?: 'networks' }
    & Pick<Networks, 'network_id' | 'network_name' | 'network_slug' | 'network_logo'>
  )> }
);

export type GetNetworkByNumberQueryVariables = Exact<{
  network_number: Scalars['Int'];
}>;


export type GetNetworkByNumberQuery = (
  { __typename?: 'query_root' }
  & { networks: Array<(
    { __typename?: 'networks' }
    & { default_practice?: Maybe<(
      { __typename?: 'practices' }
      & PracticeBasicFragment
    )> }
    & NetworkBasicFragment
  )> }
);

export type GetNetworkDetailsQueryVariables = Exact<{
  network_id: Scalars['String'];
}>;


export type GetNetworkDetailsQuery = (
  { __typename?: 'query_root' }
  & { networks_by_pk?: Maybe<(
    { __typename?: 'networks' }
    & NetworkDetailsFragment
  )> }
);

export type CreateNetworkMutationVariables = Exact<{
  network: Networks_Insert_Input;
}>;


export type CreateNetworkMutation = (
  { __typename?: 'mutation_root' }
  & { insert_networks_one?: Maybe<(
    { __typename?: 'networks' }
    & NetworkDetailsFragment
  )> }
);

export type UpdateNetworkMutationVariables = Exact<{
  network_id: Scalars['String'];
  changes?: Maybe<Networks_Set_Input>;
}>;


export type UpdateNetworkMutation = (
  { __typename?: 'mutation_root' }
  & { update_networks_by_pk?: Maybe<(
    { __typename?: 'networks' }
    & NetworkDetailsFragment
  )> }
);

export type SearchNetworksQueryVariables = Exact<{
  query: Scalars['String'];
}>;


export type SearchNetworksQuery = (
  { __typename?: 'query_root' }
  & { networks: Array<(
    { __typename?: 'networks' }
    & NetworkBasicFragment
  )> }
);

export type SearchNetworksByProvidersQueryVariables = Exact<{
  provider_ids?: Maybe<Array<Scalars['String']>>;
}>;


export type SearchNetworksByProvidersQuery = (
  { __typename?: 'query_root' }
  & { networks_aggregate: (
    { __typename?: 'networks_aggregate' }
    & { nodes: Array<(
      { __typename?: 'networks' }
      & { provider_memberships_aggregate: (
        { __typename?: 'provider_memberships_aggregate' }
        & { aggregate?: Maybe<(
          { __typename?: 'provider_memberships_aggregate_fields' }
          & Pick<Provider_Memberships_Aggregate_Fields, 'count'>
        )> }
      ) }
      & NetworkBasicFragment
    )> }
  ) }
);

export type ListPracticesQueryVariables = Exact<{ [key: string]: never; }>;


export type ListPracticesQuery = (
  { __typename?: 'query_root' }
  & { practices: Array<(
    { __typename?: 'practices' }
    & PracticeBasicFragment
  )> }
);

export type SearchPracticesQueryVariables = Exact<{
  query: Scalars['String'];
  include_archived?: Maybe<Scalars['Boolean']>;
}>;


export type SearchPracticesQuery = (
  { __typename?: 'query_root' }
  & { practices: Array<(
    { __typename?: 'practices' }
    & PracticeBasicFragment
  )> }
);

export type GetPracticeDetailsQueryVariables = Exact<{
  practice_id: Scalars['String'];
}>;


export type GetPracticeDetailsQuery = (
  { __typename?: 'query_root' }
  & { practices_by_pk?: Maybe<(
    { __typename?: 'practices' }
    & PracticeDetailsFragment
  )> }
);

export type SearchPracticesAdvancedQueryVariables = Exact<{
  exact_query?: Maybe<Scalars['String']>;
  substring_query?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Practices_Order_By>>;
  additional_filters?: Maybe<Array<Maybe<Practices_Bool_Exp>>>;
  count_aggregate_limit?: Maybe<Scalars['Int']>;
}>;


export type SearchPracticesAdvancedQuery = (
  { __typename?: 'query_root' }
  & { practices: Array<(
    { __typename?: 'practices' }
    & PracticeDetailsFragment
  )>, practices_aggregate: (
    { __typename?: 'practices_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'practices_aggregate_fields' }
      & Pick<Practices_Aggregate_Fields, 'count'>
    )> }
  ), networks: Array<(
    { __typename?: 'networks' }
    & NetworkBasicFragment
  )> }
);

export type CreatePracticeMutationVariables = Exact<{
  practice: Practices_Insert_Input;
}>;


export type CreatePracticeMutation = (
  { __typename?: 'mutation_root' }
  & { insert_practices_one?: Maybe<(
    { __typename?: 'practices' }
    & PracticeDetailsFragment
  )> }
);

export type UpdatePracticeMutationVariables = Exact<{
  practice_id: Scalars['String'];
  changes?: Maybe<Practices_Set_Input>;
  provider_employments?: Array<Provider_Employments_Insert_Input>;
}>;


export type UpdatePracticeMutation = (
  { __typename?: 'mutation_root' }
  & { insert_provider_employments?: Maybe<(
    { __typename?: 'provider_employments_mutation_response' }
    & { returning: Array<(
      { __typename?: 'provider_employments' }
      & ProviderEmploymentBasicFragment
    )> }
  )>, update_practices_by_pk?: Maybe<(
    { __typename?: 'practices' }
    & PracticeDetailsFragment
  )> }
);

export type UpdateProviderEmploymentMutationVariables = Exact<{
  practice_id: Scalars['String'];
  provider_id: Scalars['String'];
  changes: Provider_Employments_Set_Input;
}>;


export type UpdateProviderEmploymentMutation = (
  { __typename?: 'mutation_root' }
  & { update_provider_employments_by_pk?: Maybe<(
    { __typename?: 'provider_employments' }
    & ProviderEmploymentBasicFragment
  )> }
);

export type SearchProvidersQueryVariables = Exact<{
  query: Scalars['String'];
}>;


export type SearchProvidersQuery = (
  { __typename?: 'query_root' }
  & { providers: Array<(
    { __typename?: 'providers' }
    & ProviderBasicFragment
  )> }
);

export type SearchProvidersAdvancedQueryVariables = Exact<{
  exact_query?: Maybe<Scalars['String']>;
  substring_query?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Providers_Order_By>>;
  additional_filters?: Maybe<Array<Maybe<Providers_Bool_Exp>>>;
  count_aggregate_limit?: Maybe<Scalars['Int']>;
}>;


export type SearchProvidersAdvancedQuery = (
  { __typename?: 'query_root' }
  & { providers: Array<(
    { __typename?: 'providers' }
    & ProviderDetailsFragment
  )>, providers_aggregate: (
    { __typename?: 'providers_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'providers_aggregate_fields' }
      & Pick<Providers_Aggregate_Fields, 'count'>
    )> }
  ), networks: Array<(
    { __typename?: 'networks' }
    & NetworkBasicFragment
  )> }
);

export type CreateProviderMutationVariables = Exact<{
  provider: Providers_Insert_Input;
}>;


export type CreateProviderMutation = (
  { __typename?: 'mutation_root' }
  & { insert_providers_one?: Maybe<(
    { __typename?: 'providers' }
    & ProviderDetailsFragment
  )> }
);

export type UpdateProviderMutationVariables = Exact<{
  provider_id: Scalars['String'];
  changes?: Maybe<Providers_Set_Input>;
  provider_memberships?: Array<Provider_Memberships_Insert_Input>;
  provider_employments?: Array<Provider_Employments_Insert_Input>;
}>;


export type UpdateProviderMutation = (
  { __typename?: 'mutation_root' }
  & { insert_provider_memberships?: Maybe<(
    { __typename?: 'provider_memberships_mutation_response' }
    & { returning: Array<(
      { __typename?: 'provider_memberships' }
      & ProviderMembershipBasicFragment
    )> }
  )>, insert_provider_employments?: Maybe<(
    { __typename?: 'provider_employments_mutation_response' }
    & { returning: Array<(
      { __typename?: 'provider_employments' }
      & ProviderEmploymentBasicFragment
    )> }
  )>, update_providers_by_pk?: Maybe<(
    { __typename?: 'providers' }
    & ProviderDetailsFragment
  )> }
);

export type GetPatientDetailsQueryVariables = Exact<{
  patient_id: Scalars['String'];
  expanded?: Maybe<Scalars['Boolean']>;
}>;


export type GetPatientDetailsQuery = (
  { __typename?: 'query_root' }
  & { patients_by_pk?: Maybe<(
    { __typename?: 'patients' }
    & PatientDetailsFragment
  )> }
);

export type GetPatientCountQueryVariables = Exact<{ [key: string]: never; }>;


export type GetPatientCountQuery = (
  { __typename?: 'query_root' }
  & { patients_aggregate: (
    { __typename?: 'patients_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'patients_aggregate_fields' }
      & Pick<Patients_Aggregate_Fields, 'count'>
    )> }
  ) }
);

export type PatientDetailsPvaFragment = (
  { __typename?: 'patients' }
  & { suspected_provider?: Maybe<(
    { __typename?: 'providers' }
    & { provider_employments: Array<(
      { __typename?: 'provider_employments' }
      & ProviderEmploymentBasicFragment
    )> }
    & ProviderBasicFragment
  )>, practice?: Maybe<(
    { __typename?: 'practices' }
    & PracticeBasicFragment
  )>, letters: Array<(
    { __typename?: 'letters' }
    & Pick<Letters, 'letter_id' | 'send_datetime'>
  )>, emails: Array<(
    { __typename?: 'emails' }
    & Pick<Emails, 'email_id' | 'send_datetime'>
  )> }
  & PatientDetailsFragment
);

export type SearchPatientsByPvaDataQueryVariables = Exact<{
  network_number: Scalars['Int'];
  mbi: Scalars['String'];
  patient_name: Scalars['String'];
  letter_id_prefix?: Maybe<Scalars['String']>;
  birth_date?: Maybe<Scalars['date']>;
}>;


export type SearchPatientsByPvaDataQuery = (
  { __typename?: 'query_root' }
  & { search_patients_by_pva_data: Array<(
    { __typename?: 'patients' }
    & PatientDetailsPvaFragment
  )> }
);

export type SearchPatientsByHedrDataQueryVariables = Exact<{
  network_number: Scalars['Int'];
  mbi: Scalars['String'];
  patient_name: Scalars['String'];
  letter_id_prefix?: Maybe<Scalars['String']>;
  birth_date?: Maybe<Scalars['date']>;
}>;


export type SearchPatientsByHedrDataQuery = (
  { __typename?: 'query_root' }
  & { search_patients_by_hedr_data: Array<(
    { __typename?: 'patients' }
    & PatientDetailsPvaFragment
  )> }
);

export type SearchPatientsAdvancedQueryVariables = Exact<{
  exact_query?: Maybe<Scalars['String']>;
  substring_query?: Maybe<Scalars['String']>;
  prefix_query?: Maybe<Scalars['String']>;
  practices?: Maybe<Array<Scalars['String']>>;
  networks?: Maybe<Array<Scalars['String']>>;
  suspected_providers?: Maybe<Array<Scalars['String']>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Patients_Order_By>>;
  additional_filters?: Maybe<Array<Maybe<Patients_Bool_Exp>>>;
  include_admin_details?: Scalars['Boolean'];
  count_aggregate_limit?: Maybe<Scalars['Int']>;
}>;


export type SearchPatientsAdvancedQuery = (
  { __typename?: 'query_root' }
  & { patients: Array<(
    { __typename?: 'patients' }
    & { practice?: Maybe<(
      { __typename?: 'practices' }
      & { primary_network?: Maybe<(
        { __typename?: 'networks' }
        & NetworkBasicFragment
      )> }
      & PracticeBasicFragment
    )> }
    & PatientDetailsFragment
  )>, patients_aggregate: (
    { __typename?: 'patients_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'patients_aggregate_fields' }
      & Pick<Patients_Aggregate_Fields, 'count'>
    )> }
  ) }
);

export type CreatePatientMutationVariables = Exact<{
  patient: Patients_Insert_Input;
}>;


export type CreatePatientMutation = (
  { __typename?: 'mutation_root' }
  & { insert_patients_one?: Maybe<(
    { __typename?: 'patients' }
    & PatientDetailsFragment
  )> }
);

export type UpdatePatientMutationVariables = Exact<{
  patient_id: Scalars['String'];
  changes?: Maybe<Patients_Set_Input>;
}>;


export type UpdatePatientMutation = (
  { __typename?: 'mutation_root' }
  & { update_patients_by_pk?: Maybe<(
    { __typename?: 'patients' }
    & PatientDetailsFragment
  )> }
);

export type UpsertPatientsMutationVariables = Exact<{
  objects: Array<Patients_Insert_Input>;
  cols_to_update: Array<Patients_Update_Column>;
}>;


export type UpsertPatientsMutation = (
  { __typename?: 'mutation_root' }
  & { insert_patients?: Maybe<(
    { __typename?: 'patients_mutation_response' }
    & Pick<Patients_Mutation_Response, 'affected_rows'>
  )> }
);

export type ArchiveAllPatientsMutationVariables = Exact<{
  practice_id: Scalars['String'];
}>;


export type ArchiveAllPatientsMutation = (
  { __typename?: 'mutation_root' }
  & { update_patients?: Maybe<(
    { __typename?: 'patients_mutation_response' }
    & Pick<Patients_Mutation_Response, 'affected_rows'>
  )> }
);

export type InsertAccountCreateRequestMutationVariables = Exact<{
  account_creation_request_obj: Account_Creation_Requests_Insert_Input;
}>;


export type InsertAccountCreateRequestMutation = (
  { __typename?: 'mutation_root' }
  & { insert_account_creation_requests_one?: Maybe<(
    { __typename?: 'account_creation_requests' }
    & Pick<Account_Creation_Requests, 'account_creation_request_id'>
  )> }
);

export type ListAccountCreatedRequestsQueryVariables = Exact<{ [key: string]: never; }>;


export type ListAccountCreatedRequestsQuery = (
  { __typename?: 'query_root' }
  & { account_creation_requests: Array<(
    { __typename?: 'account_creation_requests' }
    & AccountCreationRequestDetailsFragment
  )> }
);

export type UpdateAccountCreationRequestMutationVariables = Exact<{
  account_creation_request_id: Scalars['String'];
  changes?: Maybe<Account_Creation_Requests_Set_Input>;
}>;


export type UpdateAccountCreationRequestMutation = (
  { __typename?: 'mutation_root' }
  & { update_account_creation_requests_by_pk?: Maybe<(
    { __typename?: 'account_creation_requests' }
    & AccountCreationRequestBasicFragment
  )> }
);

export type SendBaaMutationVariables = Exact<{
  recipient_email: Scalars['String'];
  recipient_name?: Maybe<Scalars['String']>;
  practice_id: Scalars['String'];
  template_vars?: Maybe<Scalars['jsonb']>;
}>;


export type SendBaaMutation = (
  { __typename?: 'mutation_root' }
  & { send_baa?: Maybe<(
    { __typename?: 'SendBAAOutput' }
    & { baa?: Maybe<(
      { __typename?: 'business_associate_agreements' }
      & BusinessAssociateAgreementBasicFragment
    )> }
  )> }
);

export type AdminMarkBaaCompletedMutationVariables = Exact<{
  practice_id: Scalars['String'];
  external_id: Scalars['String'];
  signatory_email?: Maybe<Scalars['String']>;
  signatory_name?: Maybe<Scalars['String']>;
}>;


export type AdminMarkBaaCompletedMutation = (
  { __typename?: 'mutation_root' }
  & { insert_business_associate_agreements_one?: Maybe<(
    { __typename?: 'business_associate_agreements' }
    & BusinessAssociateAgreementBasicFragment
  )> }
);

export type GetCampaignTemplateQueryVariables = Exact<{
  campaign_template_id: Scalars['String'];
}>;


export type GetCampaignTemplateQuery = (
  { __typename?: 'query_root' }
  & { campaign_templates_by_pk?: Maybe<(
    { __typename?: 'campaign_templates' }
    & CampaignTemplateDetailsFragment
  )> }
);

export type ListCampaignTemplatesQueryVariables = Exact<{
  include_approvals: Scalars['Boolean'];
}>;


export type ListCampaignTemplatesQuery = (
  { __typename?: 'query_root' }
  & { campaign_templates: Array<(
    { __typename?: 'campaign_templates' }
    & { campaign_approvals: Array<(
      { __typename?: 'campaign_approvals' }
      & CampaignApprovalDetailsFragment
    )> }
    & CampaignTemplateDetailsFragment
  )>, networks: Array<(
    { __typename?: 'networks' }
    & Pick<Networks, 'network_id' | 'network_name'>
  )>, practices: Array<(
    { __typename?: 'practices' }
    & Pick<Practices, 'practice_id' | 'practice_name'>
  )> }
);

export type SearchCampaignsAdvancedQueryVariables = Exact<{
  exact_query?: Maybe<Scalars['String']>;
  substring_query?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Campaign_Templates_Order_By>>;
  additional_filters?: Maybe<Array<Maybe<Campaign_Templates_Bool_Exp>>>;
  count_aggregate_limit?: Maybe<Scalars['Int']>;
}>;


export type SearchCampaignsAdvancedQuery = (
  { __typename?: 'query_root' }
  & { campaign_templates: Array<(
    { __typename?: 'campaign_templates' }
    & { campaign_letter_components: Array<(
      { __typename?: 'campaign_letter_components' }
      & { patient_segment: (
        { __typename?: 'patient_segments' }
        & PatientSegmentBasicFragment
      ), letter_template: (
        { __typename?: 'letter_templates' }
        & Pick<Letter_Templates, 'letter_template_id' | 'letter_template_name'>
      ), tracking_ids: Array<(
        { __typename?: 'component_tracking_ids' }
        & Pick<Component_Tracking_Ids, 'component_id' | 'tracking_id'>
      )> }
      & CampaignLetterComponentBasicFragment
    )>, campaign_email_components: Array<(
      { __typename?: 'campaign_email_components' }
      & { patient_segment: (
        { __typename?: 'patient_segments' }
        & PatientSegmentBasicFragment
      ), email_template: (
        { __typename?: 'email_templates' }
        & Pick<Email_Templates, 'email_template_id' | 'email_template_name'>
      ), tracking_ids: Array<(
        { __typename?: 'component_tracking_ids' }
        & Pick<Component_Tracking_Ids, 'component_id' | 'tracking_id'>
      )> }
      & CampaignEmailComponentBasicFragment
    )>, campaign_sms_components: Array<(
      { __typename?: 'campaign_sms_components' }
      & { patient_segment: (
        { __typename?: 'patient_segments' }
        & PatientSegmentBasicFragment
      ), sms_template: (
        { __typename?: 'sms_templates' }
        & Pick<Sms_Templates, 'sms_template_id' | 'sms_template_name'>
      ), tracking_ids: Array<(
        { __typename?: 'component_tracking_ids' }
        & Pick<Component_Tracking_Ids, 'component_id' | 'tracking_id'>
      )> }
      & CampaignSmsComponentBasicFragment
    )>, campaign_approvals: Array<(
      { __typename?: 'campaign_approvals' }
      & CampaignApprovalBasicFragment
    )> }
    & CampaignTemplateBasicFragment
  )>, campaign_templates_aggregate: (
    { __typename?: 'campaign_templates_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'campaign_templates_aggregate_fields' }
      & Pick<Campaign_Templates_Aggregate_Fields, 'count'>
    )> }
  ), networks: Array<(
    { __typename?: 'networks' }
    & Pick<Networks, 'network_id' | 'network_name'>
  )>, practices: Array<(
    { __typename?: 'practices' }
    & Pick<Practices, 'practice_id' | 'practice_name'>
  )> }
);

export type GetAllCampaignTemplatePreviewsQueryVariables = Exact<{
  campaign_template_id: Scalars['String'];
}>;


export type GetAllCampaignTemplatePreviewsQuery = (
  { __typename?: 'query_root' }
  & { campaign_templates_by_pk?: Maybe<(
    { __typename?: 'campaign_templates' }
    & { network?: Maybe<(
      { __typename?: 'networks' }
      & { practices: Array<(
        { __typename?: 'practices' }
        & PracticeBasicFragment
      )> }
    )>, practice?: Maybe<(
      { __typename?: 'practices' }
      & PracticeBasicFragment
    )> }
    & CampaignTemplateDetailsFragment
  )> }
);

export type ListCampaignTemplatesForPracticeQueryVariables = Exact<{
  practice_id: Scalars['String'];
}>;


export type ListCampaignTemplatesForPracticeQuery = (
  { __typename?: 'query_root' }
  & { campaign_templates: Array<(
    { __typename?: 'campaign_templates' }
    & { campaign_approvals: Array<(
      { __typename?: 'campaign_approvals' }
      & CampaignApprovalDetailsFragment
    )> }
    & CampaignTemplateDetailsFragment
  )> }
);

export type CreateCampaignTemplateMutationVariables = Exact<{
  campaign_template: Campaign_Templates_Insert_Input;
}>;


export type CreateCampaignTemplateMutation = (
  { __typename?: 'mutation_root' }
  & { insert_campaign_templates_one?: Maybe<(
    { __typename?: 'campaign_templates' }
    & CampaignTemplateDetailsFragment
  )> }
);

export type UpdateCampaignTemplateMutationVariables = Exact<{
  campaign_template_id: Scalars['String'];
  changes?: Maybe<Campaign_Templates_Set_Input>;
}>;


export type UpdateCampaignTemplateMutation = (
  { __typename?: 'mutation_root' }
  & { update_campaign_templates_by_pk?: Maybe<(
    { __typename?: 'campaign_templates' }
    & CampaignTemplateDetailsFragment
  )> }
);

export type DeleteCampaignTemplateMutationVariables = Exact<{
  campaign_template_id: Scalars['String'];
}>;


export type DeleteCampaignTemplateMutation = (
  { __typename?: 'mutation_root' }
  & { delete_campaign_email_components?: Maybe<(
    { __typename?: 'campaign_email_components_mutation_response' }
    & { returning: Array<(
      { __typename?: 'campaign_email_components' }
      & CampaignEmailComponentDetailsFragment
    )> }
  )>, delete_campaign_letter_components?: Maybe<(
    { __typename?: 'campaign_letter_components_mutation_response' }
    & { returning: Array<(
      { __typename?: 'campaign_letter_components' }
      & CampaignLetterComponentDetailsFragment
    )> }
  )>, delete_campaign_templates_by_pk?: Maybe<(
    { __typename?: 'campaign_templates' }
    & CampaignTemplateDetailsFragment
  )> }
);

export type CreateCampaignLetterComponentMutationVariables = Exact<{
  campaign_letter_component: Campaign_Letter_Components_Insert_Input;
}>;


export type CreateCampaignLetterComponentMutation = (
  { __typename?: 'mutation_root' }
  & { insert_campaign_letter_components_one?: Maybe<(
    { __typename?: 'campaign_letter_components' }
    & CampaignLetterComponentDetailsFragment
  )> }
);

export type UpdateCampaignLetterComponentMutationVariables = Exact<{
  campaign_letter_component_id: Scalars['String'];
  changes?: Maybe<Campaign_Letter_Components_Set_Input>;
}>;


export type UpdateCampaignLetterComponentMutation = (
  { __typename?: 'mutation_root' }
  & { update_campaign_letter_components_by_pk?: Maybe<(
    { __typename?: 'campaign_letter_components' }
    & CampaignLetterComponentDetailsFragment
  )> }
);

export type CreateCampaignEmailComponentMutationVariables = Exact<{
  campaign_email_component: Campaign_Email_Components_Insert_Input;
}>;


export type CreateCampaignEmailComponentMutation = (
  { __typename?: 'mutation_root' }
  & { insert_campaign_email_components_one?: Maybe<(
    { __typename?: 'campaign_email_components' }
    & CampaignEmailComponentDetailsFragment
  )> }
);

export type UpdateCampaignEmailComponentMutationVariables = Exact<{
  campaign_email_component_id: Scalars['String'];
  changes?: Maybe<Campaign_Email_Components_Set_Input>;
}>;


export type UpdateCampaignEmailComponentMutation = (
  { __typename?: 'mutation_root' }
  & { update_campaign_email_components_by_pk?: Maybe<(
    { __typename?: 'campaign_email_components' }
    & CampaignEmailComponentDetailsFragment
  )> }
);

export type CreateCampaignSmsComponentMutationVariables = Exact<{
  campaign_sms_component: Campaign_Sms_Components_Insert_Input;
}>;


export type CreateCampaignSmsComponentMutation = (
  { __typename?: 'mutation_root' }
  & { insert_campaign_sms_components_one?: Maybe<(
    { __typename?: 'campaign_sms_components' }
    & CampaignSmsComponentDetailsFragment
  )> }
);

export type UpdateCampaignSmsComponentMutationVariables = Exact<{
  campaign_sms_component_id: Scalars['String'];
  changes?: Maybe<Campaign_Sms_Components_Set_Input>;
}>;


export type UpdateCampaignSmsComponentMutation = (
  { __typename?: 'mutation_root' }
  & { update_campaign_sms_components_by_pk?: Maybe<(
    { __typename?: 'campaign_sms_components' }
    & CampaignSmsComponentDetailsFragment
  )> }
);

export type CreateComponentTrackingIdMutationVariables = Exact<{
  component_id: Scalars['String'];
  tracking_id: Scalars['String'];
}>;


export type CreateComponentTrackingIdMutation = (
  { __typename?: 'mutation_root' }
  & { insert_component_tracking_ids_one?: Maybe<(
    { __typename?: 'component_tracking_ids' }
    & { campaign_email_component?: Maybe<(
      { __typename?: 'campaign_email_components' }
      & CampaignEmailComponentDetailsFragment
    )>, campaign_letter_component?: Maybe<(
      { __typename?: 'campaign_letter_components' }
      & CampaignLetterComponentDetailsFragment
    )>, campaign_sms_component?: Maybe<(
      { __typename?: 'campaign_sms_components' }
      & CampaignSmsComponentDetailsFragment
    )> }
  )> }
);

export type DeleteComponentTrackingIdMutationVariables = Exact<{
  component_id: Scalars['String'];
  tracking_id: Scalars['String'];
}>;


export type DeleteComponentTrackingIdMutation = (
  { __typename?: 'mutation_root' }
  & { delete_component_tracking_ids_by_pk?: Maybe<(
    { __typename?: 'component_tracking_ids' }
    & { campaign_email_component?: Maybe<(
      { __typename?: 'campaign_email_components' }
      & CampaignEmailComponentDetailsFragment
    )>, campaign_letter_component?: Maybe<(
      { __typename?: 'campaign_letter_components' }
      & CampaignLetterComponentDetailsFragment
    )>, campaign_sms_component?: Maybe<(
      { __typename?: 'campaign_sms_components' }
      & CampaignSmsComponentDetailsFragment
    )> }
  )> }
);

export type ApproveCampaignMutationVariables = Exact<{
  practice_id: Scalars['String'];
  campaign_template_id: Scalars['String'];
  letter_component_approvals: Array<Letter_Component_Approvals_Insert_Input>;
  email_component_approvals: Array<Email_Component_Approvals_Insert_Input>;
  sms_component_approvals: Array<Sms_Component_Approvals_Insert_Input>;
}>;


export type ApproveCampaignMutation = (
  { __typename?: 'mutation_root' }
  & { insert_campaign_approvals_one?: Maybe<(
    { __typename?: 'campaign_approvals' }
    & { campaign_template: (
      { __typename?: 'campaign_templates' }
      & { campaign_approvals: Array<(
        { __typename?: 'campaign_approvals' }
        & CampaignApprovalDetailsFragment
      )> }
      & CampaignTemplateDetailsFragment
    ) }
  )> }
);

export type ListSmsTemplatesQueryVariables = Exact<{ [key: string]: never; }>;


export type ListSmsTemplatesQuery = (
  { __typename?: 'query_root' }
  & { sms_templates: Array<(
    { __typename?: 'sms_templates' }
    & SmsTemplateBasicFragment
  )> }
);

export type SearchSmsTemplatesQueryVariables = Exact<{
  exact_query?: Maybe<Scalars['String']>;
  substring_query?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Sms_Templates_Order_By>>;
  additional_filters?: Maybe<Array<Maybe<Sms_Templates_Bool_Exp>>>;
  count_aggregate_limit?: Maybe<Scalars['Int']>;
}>;


export type SearchSmsTemplatesQuery = (
  { __typename?: 'query_root' }
  & { sms_templates: Array<(
    { __typename?: 'sms_templates' }
    & SmsTemplateBasicFragment
  )>, sms_templates_aggregate: (
    { __typename?: 'sms_templates_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'sms_templates_aggregate_fields' }
      & Pick<Sms_Templates_Aggregate_Fields, 'count'>
    )> }
  ) }
);

export type ListEmailTemplatesQueryVariables = Exact<{ [key: string]: never; }>;


export type ListEmailTemplatesQuery = (
  { __typename?: 'query_root' }
  & { email_templates: Array<(
    { __typename?: 'email_templates' }
    & EmailTemplateBasicFragment
  )> }
);

export type SearchEmailTemplatesQueryVariables = Exact<{
  exact_query?: Maybe<Scalars['String']>;
  substring_query?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Email_Templates_Order_By>>;
  additional_filters?: Maybe<Array<Maybe<Email_Templates_Bool_Exp>>>;
  count_aggregate_limit?: Maybe<Scalars['Int']>;
}>;


export type SearchEmailTemplatesQuery = (
  { __typename?: 'query_root' }
  & { email_templates: Array<(
    { __typename?: 'email_templates' }
    & EmailTemplateBasicFragment
  )>, email_templates_aggregate: (
    { __typename?: 'email_templates_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'email_templates_aggregate_fields' }
      & Pick<Email_Templates_Aggregate_Fields, 'count'>
    )> }
  ) }
);

export type ListLetterTemplatesQueryVariables = Exact<{ [key: string]: never; }>;


export type ListLetterTemplatesQuery = (
  { __typename?: 'query_root' }
  & { letter_templates: Array<(
    { __typename?: 'letter_templates' }
    & LetterTemplateBasicFragment
  )> }
);

export type ListPublicLetterTemplatesQueryVariables = Exact<{ [key: string]: never; }>;


export type ListPublicLetterTemplatesQuery = (
  { __typename?: 'query_root' }
  & { letter_templates: Array<(
    { __typename?: 'letter_templates' }
    & LetterTemplateBasicFragment
  )> }
);

export type GetLetterTemplateQueryVariables = Exact<{
  letter_template_id: Scalars['String'];
}>;


export type GetLetterTemplateQuery = (
  { __typename?: 'query_root' }
  & { letter_templates_by_pk?: Maybe<(
    { __typename?: 'letter_templates' }
    & LetterTemplateBasicFragment
  )> }
);

export type SearchLetterTemplatesQueryVariables = Exact<{
  exact_query?: Maybe<Scalars['String']>;
  substring_query?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Letter_Templates_Order_By>>;
  additional_filters?: Maybe<Array<Maybe<Letter_Templates_Bool_Exp>>>;
  count_aggregate_limit?: Maybe<Scalars['Int']>;
}>;


export type SearchLetterTemplatesQuery = (
  { __typename?: 'query_root' }
  & { letter_templates: Array<(
    { __typename?: 'letter_templates' }
    & LetterTemplateBasicFragment
  )>, letter_templates_aggregate: (
    { __typename?: 'letter_templates_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'letter_templates_aggregate_fields' }
      & Pick<Letter_Templates_Aggregate_Fields, 'count'>
    )> }
  ) }
);

export type CreateLetterTemplateMutationVariables = Exact<{
  letter_template: Letter_Templates_Insert_Input;
}>;


export type CreateLetterTemplateMutation = (
  { __typename?: 'mutation_root' }
  & { insert_letter_templates_one?: Maybe<(
    { __typename?: 'letter_templates' }
    & LetterTemplateBasicFragment
  )> }
);

export type UpdateLetterTemplateMutationVariables = Exact<{
  letter_template_id: Scalars['String'];
  changes?: Maybe<Letter_Templates_Set_Input>;
}>;


export type UpdateLetterTemplateMutation = (
  { __typename?: 'mutation_root' }
  & { update_letter_templates_by_pk?: Maybe<(
    { __typename?: 'letter_templates' }
    & LetterTemplateBasicFragment
  )> }
);

export type CreateEmailTemplateMutationVariables = Exact<{
  email_template: Email_Templates_Insert_Input;
}>;


export type CreateEmailTemplateMutation = (
  { __typename?: 'mutation_root' }
  & { insert_email_templates_one?: Maybe<(
    { __typename?: 'email_templates' }
    & EmailTemplateBasicFragment
  )> }
);

export type UpdateEmailTemplateMutationVariables = Exact<{
  email_template_id: Scalars['String'];
  changes?: Maybe<Email_Templates_Set_Input>;
}>;


export type UpdateEmailTemplateMutation = (
  { __typename?: 'mutation_root' }
  & { update_email_templates_by_pk?: Maybe<(
    { __typename?: 'email_templates' }
    & EmailTemplateBasicFragment
  )> }
);

export type CreateSmsTemplateMutationVariables = Exact<{
  sms_template: Sms_Templates_Insert_Input;
}>;


export type CreateSmsTemplateMutation = (
  { __typename?: 'mutation_root' }
  & { insert_sms_templates_one?: Maybe<(
    { __typename?: 'sms_templates' }
    & SmsTemplateBasicFragment
  )> }
);

export type UpdateSmsTemplateMutationVariables = Exact<{
  sms_template_id: Scalars['String'];
  changes?: Maybe<Sms_Templates_Set_Input>;
}>;


export type UpdateSmsTemplateMutation = (
  { __typename?: 'mutation_root' }
  & { update_sms_templates_by_pk?: Maybe<(
    { __typename?: 'sms_templates' }
    & SmsTemplateBasicFragment
  )> }
);

export type ListPatientSegmentsQueryVariables = Exact<{ [key: string]: never; }>;


export type ListPatientSegmentsQuery = (
  { __typename?: 'query_root' }
  & { patient_segments: Array<(
    { __typename?: 'patient_segments' }
    & PatientSegmentBasicFragment
  )> }
);

export type ListPatientRosterFilesQueryVariables = Exact<{
  practice_id?: Maybe<Scalars['String']>;
}>;


export type ListPatientRosterFilesQuery = (
  { __typename?: 'query_root' }
  & { patient_roster_files: Array<(
    { __typename?: 'patient_roster_files' }
    & PatientRosterFileBasicFragment
  )> }
);

export type CreatePatientRosterFileMutationVariables = Exact<{
  practice_id: Scalars['String'];
  identity_id: Scalars['String'];
  key: Scalars['String'];
  level?: Maybe<Scalars['String']>;
}>;


export type CreatePatientRosterFileMutation = (
  { __typename?: 'mutation_root' }
  & { insert_patient_roster_files_one?: Maybe<(
    { __typename?: 'patient_roster_files' }
    & PatientRosterFileBasicFragment
  )> }
);

export type UpdatePatientRosterFileMutationVariables = Exact<{
  identity_id: Scalars['String'];
  key: Scalars['String'];
  level: Scalars['String'];
  changes?: Maybe<Patient_Roster_Files_Set_Input>;
}>;


export type UpdatePatientRosterFileMutation = (
  { __typename?: 'mutation_root' }
  & { update_patient_roster_files_by_pk?: Maybe<(
    { __typename?: 'patient_roster_files' }
    & PatientRosterFileBasicFragment
  )> }
);

export type CreateFileUploadMutationVariables = Exact<{
  file_upload: File_Uploads_Insert_Input;
}>;


export type CreateFileUploadMutation = (
  { __typename?: 'mutation_root' }
  & { insert_file_uploads_one?: Maybe<(
    { __typename?: 'file_uploads' }
    & FileUploadBasicFragment
  )> }
);

export type UpdateFileUploadMutationVariables = Exact<{
  file_upload_id: Scalars['String'];
  changes?: Maybe<File_Uploads_Set_Input>;
}>;


export type UpdateFileUploadMutation = (
  { __typename?: 'mutation_root' }
  & { update_file_uploads_by_pk?: Maybe<(
    { __typename?: 'file_uploads' }
    & FileUploadBasicFragment
  )> }
);

export type GetInsuranceCountsQueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  birth_date_before?: Maybe<Scalars['date']>;
  birth_date_after?: Maybe<Scalars['date']>;
  network_id?: Maybe<Scalars['String']>;
  practice_id?: Maybe<Scalars['String']>;
}>;


export type GetInsuranceCountsQuery = (
  { __typename?: 'query_root' }
  & { filtered_insurance_counts: Array<(
    { __typename?: 'insurance_counts' }
    & Pick<Insurance_Counts, 'count' | 'primary_insurance_payer_name' | 'primary_insurance_is_null' | 'secondary_insurance_payer_name' | 'secondary_insurance_is_null'>
    & { insurance_segment_mapping?: Maybe<(
      { __typename?: 'insurance_segment_mappings' }
      & Pick<Insurance_Segment_Mappings, 'primary_insurance_payer_name' | 'primary_insurance_is_null' | 'secondary_insurance_payer_name' | 'secondary_insurance_is_null' | 'patient_segment_id'>
    )> }
  )>, filtered_insurance_counts_aggregate: (
    { __typename?: 'insurance_counts_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'insurance_counts_aggregate_fields' }
      & { sum?: Maybe<(
        { __typename?: 'insurance_counts_sum_fields' }
        & Pick<Insurance_Counts_Sum_Fields, 'count'>
      )> }
    )> }
  ) }
);

export type UpsertInsuranceSegmentMappingMutationVariables = Exact<{
  primary_insurance_payer_name: Scalars['String'];
  primary_insurance_is_null: Scalars['Boolean'];
  secondary_insurance_payer_name: Scalars['String'];
  secondary_insurance_is_null: Scalars['Boolean'];
  patient_segment_id?: Maybe<Scalars['String']>;
}>;


export type UpsertInsuranceSegmentMappingMutation = (
  { __typename?: 'mutation_root' }
  & { insert_insurance_segment_mappings_one?: Maybe<(
    { __typename?: 'insurance_segment_mappings' }
    & Pick<Insurance_Segment_Mappings, 'primary_insurance_payer_name' | 'primary_insurance_is_null' | 'secondary_insurance_payer_name' | 'secondary_insurance_is_null' | 'patient_segment_id'>
  )> }
);

export type LetterDetailsFragment = (
  { __typename?: 'letters' }
  & { campaign_letter_component?: Maybe<(
    { __typename?: 'campaign_letter_components' }
    & { campaign_template: (
      { __typename?: 'campaign_templates' }
      & CampaignTemplateBasicFragment
    ) }
    & CampaignLetterComponentDetailsFragment
  )> }
  & LetterBasicFragment
);

export type EmailDetailsFragment = (
  { __typename?: 'emails' }
  & { campaign_email_component?: Maybe<(
    { __typename?: 'campaign_email_components' }
    & { campaign_template: (
      { __typename?: 'campaign_templates' }
      & CampaignTemplateBasicFragment
    ) }
    & CampaignEmailComponentDetailsFragment
  )> }
  & EmailBasicFragment
);

export type SmsDetailsFragment = (
  { __typename?: 'sms_messages' }
  & { campaign_sms_component?: Maybe<(
    { __typename?: 'campaign_sms_components' }
    & { campaign_template: (
      { __typename?: 'campaign_templates' }
      & CampaignTemplateBasicFragment
    ) }
    & CampaignSmsComponentDetailsFragment
  )> }
  & SmsBasicFragment
);

export type ListPatientCommunicationsQueryVariables = Exact<{
  patient_id: Scalars['String'];
}>;


export type ListPatientCommunicationsQuery = (
  { __typename?: 'query_root' }
  & { letters: Array<(
    { __typename?: 'letters' }
    & LetterDetailsFragment
  )>, emails: Array<(
    { __typename?: 'emails' }
    & EmailDetailsFragment
  )>, sms_messages: Array<(
    { __typename?: 'sms_messages' }
    & SmsDetailsFragment
  )> }
);

export type SearchPvAsQueryVariables = Exact<{
  exact_query?: Maybe<Scalars['String']>;
  substring_query?: Maybe<Scalars['String']>;
  prefix_query?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Pvas_Order_By>>;
  additional_filters?: Maybe<Array<Pvas_Bool_Exp>>;
  count_aggregate_limit?: Maybe<Scalars['Int']>;
}>;


export type SearchPvAsQuery = (
  { __typename?: 'query_root' }
  & { pvas: Array<(
    { __typename?: 'pvas' }
    & PvaDetailsFragment
  )>, pvas_aggregate: (
    { __typename?: 'pvas_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'pvas_aggregate_fields' }
      & Pick<Pvas_Aggregate_Fields, 'count'>
    )> }
  ), networks: Array<(
    { __typename?: 'networks' }
    & NetworkBasicFragment
  )> }
);

export type UpdatePvaMutationVariables = Exact<{
  pva_id: Scalars['String'];
  changes?: Maybe<Pvas_Set_Input>;
}>;


export type UpdatePvaMutation = (
  { __typename?: 'mutation_root' }
  & { update_pvas_by_pk?: Maybe<(
    { __typename?: 'pvas' }
    & PvaDetailsFragment
  )> }
);

export type ParticipantBasicFragment = (
  { __typename?: 'analytics_analytics_cms_active_dce_participants' }
  & Pick<Analytics_Analytics_Cms_Active_Dce_Participants, 'cms_dce_participant_id' | 'entity_id' | 'performance_year' | 'legal_business_name' | 'individual_npi' | 'tin' | 'participant_type' | 'effective_start_date' | 'effective_end_date' | 'last_updated'>
);

export type SearchActiveParticipantsQueryVariables = Exact<{
  dc_id: Scalars['String'];
  signature_date: Scalars['date'];
  prefix_query?: Maybe<Scalars['String']>;
  substring_query?: Maybe<Scalars['String']>;
}>;


export type SearchActiveParticipantsQuery = (
  { __typename?: 'query_root' }
  & { analytics_analytics_cms_active_dce_participants: Array<(
    { __typename?: 'analytics_analytics_cms_active_dce_participants' }
    & ParticipantBasicFragment
  )> }
);

export type ValidateActiveParticipantsQueryVariables = Exact<{
  dc_id: Scalars['String'];
  npi: Scalars['String'];
  tin: Scalars['String'];
}>;


export type ValidateActiveParticipantsQuery = (
  { __typename?: 'query_root' }
  & { analytics_analytics_cms_active_dce_participants: Array<(
    { __typename?: 'analytics_analytics_cms_active_dce_participants' }
    & ParticipantBasicFragment
  )> }
);

export type SearchHedRsQueryVariables = Exact<{
  exact_query?: Maybe<Scalars['String']>;
  substring_query?: Maybe<Scalars['String']>;
  prefix_query?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Health_Equity_Reports_Order_By>>;
  additional_filters?: Maybe<Array<Health_Equity_Reports_Bool_Exp>>;
  count_aggregate_limit?: Maybe<Scalars['Int']>;
}>;


export type SearchHedRsQuery = (
  { __typename?: 'query_root' }
  & { health_equity_reports: Array<(
    { __typename?: 'health_equity_reports' }
    & HedrDetailsFragment
  )>, health_equity_reports_aggregate: (
    { __typename?: 'health_equity_reports_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'health_equity_reports_aggregate_fields' }
      & Pick<Health_Equity_Reports_Aggregate_Fields, 'count'>
    )> }
  ), networks: Array<(
    { __typename?: 'networks' }
    & NetworkBasicFragment
  )> }
);

export type UpdateHedrMutationVariables = Exact<{
  health_equity_report_id: Scalars['String'];
  changes?: Maybe<Health_Equity_Reports_Set_Input>;
}>;


export type UpdateHedrMutation = (
  { __typename?: 'mutation_root' }
  & { update_health_equity_reports_by_pk?: Maybe<(
    { __typename?: 'health_equity_reports' }
    & HedrDetailsFragment
  )> }
);

export type ListPvaResponseUploadQueryVariables = Exact<{
  DC_ID: Scalars['String'];
}>;


export type ListPvaResponseUploadQuery = (
  { __typename?: 'query_root' }
  & { cms_pva_responses: Array<(
    { __typename?: 'cms_pva_responses' }
    & Pick<Cms_Pva_Responses, 'DCE_ID' | 'SUBMISSION_DATE' | 'ID_RECEIVED' | 'IND_NPI' | 'IND_TIN' | 'SIGNATURE_DATE' | 'created_at' | 'file_upload_id'>
    & { file_upload?: Maybe<(
      { __typename?: 'file_uploads' }
      & FileUploadBasicFragment
    )> }
  )> }
);

export type InsertPvaResponseMutationVariables = Exact<{
  objects: Array<Cms_Pva_Responses_Insert_Input>;
}>;


export type InsertPvaResponseMutation = (
  { __typename?: 'mutation_root' }
  & { insert_cms_pva_responses?: Maybe<(
    { __typename?: 'cms_pva_responses_mutation_response' }
    & Pick<Cms_Pva_Responses_Mutation_Response, 'affected_rows'>
  )> }
);

export type ListParticipantUploadQueryVariables = Exact<{
  DC_ID: Scalars['String'];
}>;


export type ListParticipantUploadQuery = (
  { __typename?: 'query_root' }
  & { cms_dce_participants: Array<(
    { __typename?: 'cms_dce_participants' }
    & Pick<Cms_Dce_Participants, 'entity_id' | 'performance_year' | 'created_at' | 'file_upload_id'>
    & { file_upload?: Maybe<(
      { __typename?: 'file_uploads' }
      & FileUploadBasicFragment
    )> }
  )> }
);

export type InsertParticipantListsMutationVariables = Exact<{
  objects: Array<Cms_Dce_Participants_Insert_Input>;
}>;


export type InsertParticipantListsMutation = (
  { __typename?: 'mutation_root' }
  & { insert_cms_dce_participants?: Maybe<(
    { __typename?: 'cms_dce_participants_mutation_response' }
    & Pick<Cms_Dce_Participants_Mutation_Response, 'affected_rows'>
  )> }
);

export type ListAlignmentUploadsQueryVariables = Exact<{
  DC_ID: Scalars['String'];
}>;


export type ListAlignmentUploadsQuery = (
  { __typename?: 'query_root' }
  & { cms_alignment_reports: Array<(
    { __typename?: 'cms_alignment_reports' }
    & Pick<Cms_Alignment_Reports, 'dc_id' | 'performance_year' | 'beneficiary_mbi_id' | 'updated_at' | 'file_upload_id'>
    & { file_upload?: Maybe<(
      { __typename?: 'file_uploads' }
      & FileUploadBasicFragment
    )> }
  )> }
);

export type UpsertAlignmentReportsMutationVariables = Exact<{
  objects: Array<Cms_Alignment_Reports_Insert_Input>;
  cols_to_update: Array<Cms_Alignment_Reports_Update_Column>;
}>;


export type UpsertAlignmentReportsMutation = (
  { __typename?: 'mutation_root' }
  & { insert_cms_alignment_reports?: Maybe<(
    { __typename?: 'cms_alignment_reports_mutation_response' }
    & Pick<Cms_Alignment_Reports_Mutation_Response, 'affected_rows'>
  )> }
);

export type ListProviderAlignmentUploadsQueryVariables = Exact<{
  DC_ID: Scalars['String'];
}>;


export type ListProviderAlignmentUploadsQuery = (
  { __typename?: 'query_root' }
  & { cms_provider_alignment_reports: Array<(
    { __typename?: 'cms_provider_alignment_reports' }
    & Pick<Cms_Provider_Alignment_Reports, 'ACO_ID' | 'PERFORMANCE_YEAR' | 'MBI_ID' | 'ALGN_TYPE_CLM' | 'PRVDR_NPI' | 'PRVDR_TIN' | 'updated_at' | 'file_upload_id'>
    & { file_upload?: Maybe<(
      { __typename?: 'file_uploads' }
      & FileUploadBasicFragment
    )> }
  )> }
);

export type UpsertProviderAlignmentReportsMutationVariables = Exact<{
  objects: Array<Cms_Provider_Alignment_Reports_Insert_Input>;
  cols_to_update: Array<Cms_Provider_Alignment_Reports_Update_Column>;
}>;


export type UpsertProviderAlignmentReportsMutation = (
  { __typename?: 'mutation_root' }
  & { insert_cms_provider_alignment_reports?: Maybe<(
    { __typename?: 'cms_provider_alignment_reports_mutation_response' }
    & Pick<Cms_Provider_Alignment_Reports_Mutation_Response, 'affected_rows'>
  )> }
);

export type ListHedrTemplateUploadsQueryVariables = Exact<{
  DC_ID: Scalars['String'];
}>;


export type ListHedrTemplateUploadsQuery = (
  { __typename?: 'query_root' }
  & { hedr_submission_templates: Array<(
    { __typename?: 'hedr_submission_templates' }
    & Pick<Hedr_Submission_Templates, 'entity_id' | 'performance_year' | 'mbi' | 'created_at' | 'file_upload_id'>
    & { file_upload?: Maybe<(
      { __typename?: 'file_uploads' }
      & FileUploadBasicFragment
    )> }
  )> }
);

export type InsertHedrTemplateMutationVariables = Exact<{
  objects: Array<Hedr_Submission_Templates_Insert_Input>;
}>;


export type InsertHedrTemplateMutation = (
  { __typename?: 'mutation_root' }
  & { insert_hedr_submission_templates?: Maybe<(
    { __typename?: 'hedr_submission_templates_mutation_response' }
    & Pick<Hedr_Submission_Templates_Mutation_Response, 'affected_rows'>
  )> }
);

export type PvaAuditEntryFragment = (
  { __typename?: 'pvas_audit' }
  & Pick<Pvas_Audit, 'event_id' | 'action_tstamp_stm' | 'action' | 'application_name' | 'hasura_user' | 'row_data' | 'changed_fields'>
  & { session_user?: Maybe<(
    { __typename?: 'users' }
    & Pick<Users, 'user_name' | 'user_email' | 'user_id'>
  )> }
);

export type HedrAuditEntryFragment = (
  { __typename?: 'health_equity_reports_audit' }
  & Pick<Health_Equity_Reports_Audit, 'event_id' | 'action_tstamp_stm' | 'action' | 'application_name' | 'hasura_user' | 'row_data' | 'changed_fields'>
  & { session_user?: Maybe<(
    { __typename?: 'users' }
    & Pick<Users, 'user_name' | 'user_email' | 'user_id'>
  )> }
);

export type PatientAuditEntryFragment = (
  { __typename?: 'patients_audit' }
  & Pick<Patients_Audit, 'event_id' | 'action_tstamp_stm' | 'action' | 'application_name' | 'hasura_user' | 'row_data' | 'changed_fields'>
  & { session_user?: Maybe<(
    { __typename?: 'users' }
    & Pick<Users, 'user_name' | 'user_email' | 'user_id'>
  )> }
);

export type GetPvaAuditLogQueryVariables = Exact<{
  pva_id: Scalars['String'];
}>;


export type GetPvaAuditLogQuery = (
  { __typename?: 'query_root' }
  & { pvas_audit: Array<(
    { __typename?: 'pvas_audit' }
    & PvaAuditEntryFragment
  )> }
);

export type GetHedrAuditLogQueryVariables = Exact<{
  health_equity_report_id: Scalars['String'];
}>;


export type GetHedrAuditLogQuery = (
  { __typename?: 'query_root' }
  & { health_equity_reports_audit: Array<(
    { __typename?: 'health_equity_reports_audit' }
    & HedrAuditEntryFragment
  )> }
);

export type GetPatientAuditLogQueryVariables = Exact<{
  patient_id: Scalars['String'];
}>;


export type GetPatientAuditLogQuery = (
  { __typename?: 'query_root' }
  & { patients_audit: Array<(
    { __typename?: 'patients_audit' }
    & PatientAuditEntryFragment
  )> }
);

export const PatientRosterFileBasicFragmentDoc = gql`
    fragment PatientRosterFileBasic on patient_roster_files {
  identity_id
  level
  key
  practice_id
  field_mapping
  is_archived
  created_at
  updated_at
}
    `;
export const FileUploadBasicFragmentDoc = gql`
    fragment FileUploadBasic on file_uploads {
  file_upload_id
  file_name
  identity_id
  level
  key
  file_category
  user_id
  practice_id
  network_id
  organization_id
  is_archived
  created_at
  updated_at
  upload_status
}
    `;
export const HedrBasicFragmentDoc = gql`
    fragment HEDRBasic on health_equity_reports {
  health_equity_report_id
  submission_id
  submission_source
  mbi
  birth_date
  beneficiary_first_name
  beneficiary_last_name
  beneficiary_name
  letter_id
  letter_id_prefix
  network_number
  transcriber_notes
  processing_status
  matched_patient_id
  sex_assigned_at_birth
  channel
  onedrive_file_id
  sex_orientation
  sex_orientation_os
  gender_identity
  gender_identity_os
  preferred_language
  race
  ethnicity_base
  form_language
  form_practice_id
  created_at
  updated_at
  ocr_output
  practice_id_prefix
  short_url
  template_version
  ethnicity_expanded_1
  ethnicity_expanded_2
  ethnicity_expanded_3
  ethnicity_expanded_4
  date_assessment_complete
  assessment_declined
  NC_Q1
  NC_Q2
  NC_Q4
  NC_Q5
  NC_Q6
  NC_Q7
  NC_Q8
  NC_Q9
  NC_Q10
  AHC_71802_3
  AHC_LA31996_4
  AHC_LA28580_1
  AHC_LA31997_2
  AHC_LA31998_0
  AHC_LA31999_8
  AHC_LA32000_4
  AHC_LA32001_2
  AHC_LA9_3
  AHC_88122_7
  AHC_88123_5
  AHC_93030_5
  AHC_96779_4
  AHC_95618_5
  AHC_95617_7
  AHC_95616_9
  AHC_95615_1
  P_93035_4
  P_93034_7
  P_63512_8
  P_LA30122_8_FAM
  P_71802_3
  P_93033_9
  P_82589_3
  P_67875_5
  P_67875_5_OS
  P_63586_2
  P_LA30122_8_INC
  P_LA30125_1
  P_LA30126_9
  P_LA30124_4
  P_LA30127_7
  P_LA30128_5
  P_LA30129_3
  P_93031_3_O
  P_93031_3_OS
  P_LA30122_8_NEEDS
  P_93030_5
  P_93029_7
  P_93038_8
  P_93026_3
  P_76501_6
}
    `;
export const NetworkTagFragmentDoc = gql`
    fragment NetworkTag on networks {
  network_id
  network_name
  network_number
  network_description
  dc_id
  is_archived
}
    `;
export const NetworkBasicFragmentDoc = gql`
    fragment NetworkBasic on networks {
  ...NetworkTag
  network_id
  network_number
  network_slug
  network_name
  network_description
  network_logo
  network_color
  network_merge_vars
  broker_phone_for_emails
  broker_phone_for_letters
  broker_url_for_emails
  broker_url_for_letters
  broker_name
  broker_disclaimer
  is_archived
  dc_id
  default_practice_id
}
    ${NetworkTagFragmentDoc}`;
export const HedrDetailsFragmentDoc = gql`
    fragment HEDRDetails on health_equity_reports {
  ...HEDRBasic
  pages: ocr_output(path: "$.pages")
  network {
    ...NetworkBasic
  }
}
    ${HedrBasicFragmentDoc}
${NetworkBasicFragmentDoc}`;
export const UserBasicFragmentDoc = gql`
    fragment UserBasic on users {
  user_id
  user_email
  user_name
  photo
  groups
  practice_id
  network_id
  organization_id
  created_at
  updated_at
  is_disabled
  identity_id
  preferred_mfa
  last_login_at
}
    `;
export const PracticeBasicFragmentDoc = gql`
    fragment PracticeBasic on practices {
  practice_id
  practice_name
  practice_address
  practice_description
  practice_language
  legal_business_name
  tin
  default_provider_id
  is_archived
}
    `;
export const OrganizationBasicFragmentDoc = gql`
    fragment OrganizationBasic on organizations {
  organization_id
  organization_name
  organization_description
  dashboards
  is_archived
}
    `;
export const UserDetailsFragmentDoc = gql`
    fragment UserDetails on users {
  ...UserBasic
  practice {
    ...PracticeBasic
  }
  network {
    ...NetworkBasic
  }
  organization {
    ...OrganizationBasic
  }
}
    ${UserBasicFragmentDoc}
${PracticeBasicFragmentDoc}
${NetworkBasicFragmentDoc}
${OrganizationBasicFragmentDoc}`;
export const OrganizationDetailsFragmentDoc = gql`
    fragment OrganizationDetails on organizations {
  ...OrganizationBasic
  organization_networks {
    is_archived
    organization_id
    network_id
    network {
      ...NetworkBasic
    }
  }
}
    ${OrganizationBasicFragmentDoc}
${NetworkBasicFragmentDoc}`;
export const ProviderMembershipBasicFragmentDoc = gql`
    fragment ProviderMembershipBasic on provider_memberships {
  provider_id
  network_id
  is_archived
}
    `;
export const ProviderBasicFragmentDoc = gql`
    fragment ProviderBasic on providers {
  provider_id
  provider_name
  is_archived
}
    `;
export const ProviderEmploymentBasicFragmentDoc = gql`
    fragment ProviderEmploymentBasic on provider_employments {
  provider_id
  practice_id
  is_archived
  tin
}
    `;
export const ProviderDetailsFragmentDoc = gql`
    fragment ProviderDetails on providers {
  ...ProviderBasic
  provider_employments {
    ...ProviderEmploymentBasic
    practice {
      ...PracticeBasic
    }
  }
  provider_memberships {
    ...ProviderMembershipBasic
    network {
      ...NetworkBasic
    }
  }
}
    ${ProviderBasicFragmentDoc}
${ProviderEmploymentBasicFragmentDoc}
${PracticeBasicFragmentDoc}
${ProviderMembershipBasicFragmentDoc}
${NetworkBasicFragmentDoc}`;
export const NetworkDetailsFragmentDoc = gql`
    fragment NetworkDetails on networks {
  ...NetworkBasic
  default_practice {
    ...PracticeBasic
  }
  provider_memberships {
    ...ProviderMembershipBasic
    provider {
      ...ProviderDetails
    }
  }
}
    ${NetworkBasicFragmentDoc}
${PracticeBasicFragmentDoc}
${ProviderMembershipBasicFragmentDoc}
${ProviderDetailsFragmentDoc}`;
export const BusinessAssociateAgreementBasicFragmentDoc = gql`
    fragment BusinessAssociateAgreementBasic on business_associate_agreements {
  external_id
  external_source
  status
  recipient_email
  recipient_name
  updated_at
}
    `;
export const PracticeDetailsFragmentDoc = gql`
    fragment PracticeDetails on practices {
  ...PracticeBasic
  deliverability: address_verification_result(path: "$.deliverability")
  address_verification_result
  practice_company
  main_doctor_name_extension
  tin
  primary_network_id
  practice_logo
  practice_color
  practice_merge_vars
  default_provider_id
  default_provider {
    ...ProviderBasic
  }
  primary_network {
    ...NetworkBasic
  }
  provider_employments {
    ...ProviderEmploymentBasic
    provider {
      ...ProviderDetails
    }
  }
  users {
    ...UserBasic
  }
  baas(order_by: {updated_at: asc}) {
    ...BusinessAssociateAgreementBasic
  }
  patients_aggregate {
    aggregate {
      count
    }
  }
}
    ${PracticeBasicFragmentDoc}
${ProviderBasicFragmentDoc}
${NetworkBasicFragmentDoc}
${ProviderEmploymentBasicFragmentDoc}
${ProviderDetailsFragmentDoc}
${UserBasicFragmentDoc}
${BusinessAssociateAgreementBasicFragmentDoc}`;
export const AccountCreationRequestBasicFragmentDoc = gql`
    fragment AccountCreationRequestBasic on account_creation_requests {
  account_creation_request_id
  network_id
  network_user_email
  practice_name
  practice_id
  practice_user_name
  practice_user_email
  practice_user_phone
  providers_text
  signatory_name
  signatory_email
  comments
  status
  created_at
}
    `;
export const AccountCreationRequestDetailsFragmentDoc = gql`
    fragment AccountCreationRequestDetails on account_creation_requests {
  ...AccountCreationRequestBasic
  practice {
    ...PracticeBasic
  }
  network {
    ...NetworkBasic
  }
  submitter {
    ...UserBasic
  }
}
    ${AccountCreationRequestBasicFragmentDoc}
${PracticeBasicFragmentDoc}
${NetworkBasicFragmentDoc}
${UserBasicFragmentDoc}`;
export const CampaignTemplateBasicFragmentDoc = gql`
    fragment CampaignTemplateBasic on campaign_templates {
  campaign_template_id
  campaign_name
  campaign_description
  campaign_merge_vars
  is_archived
  network {
    ...NetworkTag
  }
  practice {
    ...PracticeBasic
  }
}
    ${NetworkTagFragmentDoc}
${PracticeBasicFragmentDoc}`;
export const CampaignLetterComponentBasicFragmentDoc = gql`
    fragment CampaignLetterComponentBasic on campaign_letter_components {
  campaign_letter_component_id
  patient_segment_id
  letter_template_id
  return_envelope_id
  custom_envelope_id
  is_first_class
  skip_undeliverable_addresses
  component_merge_vars
  send_datetime
  is_archived
}
    `;
export const PatientSegmentBasicFragmentDoc = gql`
    fragment PatientSegmentBasic on patient_segments {
  patient_segment_id
  patient_segment_description
}
    `;
export const LetterTemplateBasicFragmentDoc = gql`
    fragment LetterTemplateBasic on letter_templates {
  letter_template_id
  default_merge_vars
  external_id
  external_secondary_id
  external_source
  external_version
  letter_template_name
  format
  size
  num_pages
  is_color
  perforated_page
  webform_url
  letter_from
  letter_company
  is_public
}
    `;
export const CampaignLetterComponentDetailsFragmentDoc = gql`
    fragment CampaignLetterComponentDetails on campaign_letter_components {
  ...CampaignLetterComponentBasic
  patient_segment {
    ...PatientSegmentBasic
  }
  letter_template {
    ...LetterTemplateBasic
  }
  tracking_ids {
    component_id
    tracking_id
  }
}
    ${CampaignLetterComponentBasicFragmentDoc}
${PatientSegmentBasicFragmentDoc}
${LetterTemplateBasicFragmentDoc}`;
export const CampaignEmailComponentBasicFragmentDoc = gql`
    fragment CampaignEmailComponentBasic on campaign_email_components {
  campaign_email_component_id
  patient_segment_id
  email_template_id
  component_merge_vars
  send_datetime
  is_archived
}
    `;
export const EmailTemplateBasicFragmentDoc = gql`
    fragment EmailTemplateBasic on email_templates {
  email_template_id
  default_merge_vars
  external_source
  external_version
  email_template_name
  email_subject
  body_html
  email_from
  webform_url
}
    `;
export const CampaignEmailComponentDetailsFragmentDoc = gql`
    fragment CampaignEmailComponentDetails on campaign_email_components {
  ...CampaignEmailComponentBasic
  patient_segment {
    ...PatientSegmentBasic
  }
  email_template {
    ...EmailTemplateBasic
  }
  tracking_ids {
    component_id
    tracking_id
  }
}
    ${CampaignEmailComponentBasicFragmentDoc}
${PatientSegmentBasicFragmentDoc}
${EmailTemplateBasicFragmentDoc}`;
export const CampaignSmsComponentBasicFragmentDoc = gql`
    fragment CampaignSMSComponentBasic on campaign_sms_components {
  campaign_sms_component_id
  patient_segment_id
  sms_template_id
  component_merge_vars
  send_datetime
  is_archived
}
    `;
export const SmsTemplateBasicFragmentDoc = gql`
    fragment SMSTemplateBasic on sms_templates {
  sms_template_id
  sms_template_name
  body_text
  webform_url
  default_merge_vars
}
    `;
export const CampaignSmsComponentDetailsFragmentDoc = gql`
    fragment CampaignSMSComponentDetails on campaign_sms_components {
  ...CampaignSMSComponentBasic
  patient_segment {
    ...PatientSegmentBasic
  }
  sms_template {
    ...SMSTemplateBasic
  }
  tracking_ids {
    component_id
    tracking_id
  }
}
    ${CampaignSmsComponentBasicFragmentDoc}
${PatientSegmentBasicFragmentDoc}
${SmsTemplateBasicFragmentDoc}`;
export const CampaignTemplateDetailsFragmentDoc = gql`
    fragment CampaignTemplateDetails on campaign_templates {
  ...CampaignTemplateBasic
  campaign_letter_components(order_by: {send_datetime: asc}) {
    ...CampaignLetterComponentDetails
  }
  campaign_email_components(order_by: {send_datetime: asc}) {
    ...CampaignEmailComponentDetails
  }
  campaign_sms_components(order_by: {send_datetime: asc}) {
    ...CampaignSMSComponentDetails
  }
}
    ${CampaignTemplateBasicFragmentDoc}
${CampaignLetterComponentDetailsFragmentDoc}
${CampaignEmailComponentDetailsFragmentDoc}
${CampaignSmsComponentDetailsFragmentDoc}`;
export const CampaignApprovalBasicFragmentDoc = gql`
    fragment CampaignApprovalBasic on campaign_approvals {
  practice_id
  campaign_template_id
  approved_by
  created_at
}
    `;
export const CampaignApprovalDetailsFragmentDoc = gql`
    fragment CampaignApprovalDetails on campaign_approvals {
  ...CampaignApprovalBasic
  approver {
    ...UserBasic
  }
  email_component_approvals {
    campaign_email_component {
      ...CampaignEmailComponentBasic
    }
  }
  letter_component_approvals {
    campaign_letter_component {
      ...CampaignLetterComponentBasic
    }
  }
  sms_component_approvals {
    campaign_sms_component {
      ...CampaignSMSComponentBasic
    }
  }
}
    ${CampaignApprovalBasicFragmentDoc}
${UserBasicFragmentDoc}
${CampaignEmailComponentBasicFragmentDoc}
${CampaignLetterComponentBasicFragmentDoc}
${CampaignSmsComponentBasicFragmentDoc}`;
export const PvaBasicFragmentDoc = gql`
    fragment PVABasic on pvas {
  pva_id
  submission_id
  submission_source
  pva_practice_name
  provider_name_or_med_group
  beneficiary_name
  beneficiary_first_name
  beneficiary_last_name
  signature_raw
  is_signed
  signature_url
  signature_date_raw
  signature_date
  signer_printed_name
  mbi
  birth_date
  birth_date_raw
  ycode
  letter_id
  letter_id_prefix
  network_number
  language
  matched_patient_id
  transcriber_notes
  processing_status
  pva_practice_id
  pva_npi
  pva_tin
  onedrive_file_id
  created_at
  updated_at
}
    `;
export const PatientBasicFragmentDoc = gql`
    fragment PatientBasic on patients {
  patient_id
  practice_id
  external_id
  patient_name
  birth_date
  patient_address
  patient_phone
  patient_email
  address_verification_result
  email_validation_result
  mbi
  primary_insurance_payer_id
  primary_insurance_payer_name
  primary_insurance_plan_name
  primary_insurance_member_id
  primary_insurance_group_id
  secondary_insurance_payer_id
  secondary_insurance_payer_name
  secondary_insurance_plan_name
  secondary_insurance_member_id
  secondary_insurance_group_id
  patient_pcp
  suspected_provider_npi
  alignment_tin
  sex_assigned_at_birth
  do_not_email
  do_not_direct_mail
  is_archived
  deceased_as_of_date
  patient_notes
  patient_logo
  created_at
  updated_at
}
    `;
export const PvaDetailsFragmentDoc = gql`
    fragment PVADetails on pvas {
  ...PVABasic
  pages: ocr_output(path: "$.pages")
  num_pages: ocr_output(path: "$.responsev2.predictionOutput.pageCount")
  network {
    ...NetworkBasic
  }
  matched_patient {
    ...PatientBasic
    suspected_provider {
      provider_id
      provider_name
    }
    practice {
      ...PracticeBasic
    }
  }
}
    ${PvaBasicFragmentDoc}
${NetworkBasicFragmentDoc}
${PatientBasicFragmentDoc}
${PracticeBasicFragmentDoc}`;
export const PatientDetailsFragmentDoc = gql`
    fragment PatientDetails on patients {
  ...PatientBasic
  deliverability: address_verification_result(path: "$.deliverability")
  patient_segment_label {
    patient_id
    patient_segment_id
  }
  suspected_provider {
    provider_id
    provider_name
  }
}
    ${PatientBasicFragmentDoc}`;
export const PatientDetailsPvaFragmentDoc = gql`
    fragment PatientDetailsPVA on patients {
  ...PatientDetails
  suspected_provider {
    ...ProviderBasic
    provider_employments(where: {is_archived: {_eq: false}}) {
      ...ProviderEmploymentBasic
    }
  }
  practice {
    ...PracticeBasic
  }
  letters(where: {letter_id: {_ilike: $letter_id_prefix}}, limit: 10) {
    letter_id
    send_datetime
  }
  emails(where: {email_id: {_ilike: $letter_id_prefix}}, limit: 10) {
    email_id
    send_datetime
  }
}
    ${PatientDetailsFragmentDoc}
${ProviderBasicFragmentDoc}
${ProviderEmploymentBasicFragmentDoc}
${PracticeBasicFragmentDoc}`;
export const LetterBasicFragmentDoc = gql`
    fragment LetterBasic on letters {
  practice_id
  external_id
  updated_at
  send_datetime
  letter_id
  status
}
    `;
export const LetterDetailsFragmentDoc = gql`
    fragment LetterDetails on letters {
  campaign_letter_component {
    ...CampaignLetterComponentDetails
    campaign_template {
      ...CampaignTemplateBasic
    }
  }
  ...LetterBasic
}
    ${CampaignLetterComponentDetailsFragmentDoc}
${CampaignTemplateBasicFragmentDoc}
${LetterBasicFragmentDoc}`;
export const EmailBasicFragmentDoc = gql`
    fragment EmailBasic on emails {
  practice_id
  updated_at
  send_datetime
  email_id
  status
}
    `;
export const EmailDetailsFragmentDoc = gql`
    fragment EmailDetails on emails {
  campaign_email_component {
    ...CampaignEmailComponentDetails
    campaign_template {
      ...CampaignTemplateBasic
    }
  }
  ...EmailBasic
}
    ${CampaignEmailComponentDetailsFragmentDoc}
${CampaignTemplateBasicFragmentDoc}
${EmailBasicFragmentDoc}`;
export const SmsBasicFragmentDoc = gql`
    fragment SMSBasic on sms_messages {
  practice_id
  updated_at
  send_datetime
  sms_message_id
  status
}
    `;
export const SmsDetailsFragmentDoc = gql`
    fragment SMSDetails on sms_messages {
  campaign_sms_component {
    ...CampaignSMSComponentDetails
    campaign_template {
      ...CampaignTemplateBasic
    }
  }
  ...SMSBasic
}
    ${CampaignSmsComponentDetailsFragmentDoc}
${CampaignTemplateBasicFragmentDoc}
${SmsBasicFragmentDoc}`;
export const ParticipantBasicFragmentDoc = gql`
    fragment ParticipantBasic on analytics_analytics_cms_active_dce_participants {
  cms_dce_participant_id
  entity_id
  performance_year
  legal_business_name
  individual_npi
  tin
  participant_type
  effective_start_date
  effective_end_date
  last_updated
}
    `;
export const PvaAuditEntryFragmentDoc = gql`
    fragment PVAAuditEntry on pvas_audit {
  event_id
  action_tstamp_stm
  action
  application_name
  session_user {
    user_name
    user_email
    user_id
  }
  hasura_user
  row_data
  changed_fields
}
    `;
export const HedrAuditEntryFragmentDoc = gql`
    fragment HEDRAuditEntry on health_equity_reports_audit {
  event_id
  action_tstamp_stm
  action
  application_name
  session_user {
    user_name
    user_email
    user_id
  }
  hasura_user
  row_data
  changed_fields
}
    `;
export const PatientAuditEntryFragmentDoc = gql`
    fragment PatientAuditEntry on patients_audit {
  event_id
  action_tstamp_stm
  action
  application_name
  session_user {
    user_name
    user_email
    user_id
  }
  hasura_user
  row_data
  changed_fields
}
    `;
export const ExistsUserDocument = gql`
    query existsUser($user_email: String!) {
  users(where: {user_email: {_eq: $user_email}, is_disabled: {_eq: false}}) {
    user_email
  }
}
    `;

/**
 * __useExistsUserQuery__
 *
 * To run a query within a React component, call `useExistsUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useExistsUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExistsUserQuery({
 *   variables: {
 *      user_email: // value for 'user_email'
 *   },
 * });
 */
export function useExistsUserQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ExistsUserQuery, ExistsUserQueryVariables>) {
        return ApolloReactHooks.useQuery<ExistsUserQuery, ExistsUserQueryVariables>(ExistsUserDocument, baseOptions);
      }
export function useExistsUserLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ExistsUserQuery, ExistsUserQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ExistsUserQuery, ExistsUserQueryVariables>(ExistsUserDocument, baseOptions);
        }
export type ExistsUserQueryHookResult = ReturnType<typeof useExistsUserQuery>;
export type ExistsUserLazyQueryHookResult = ReturnType<typeof useExistsUserLazyQuery>;
export type ExistsUserQueryResult = ApolloReactCommon.QueryResult<ExistsUserQuery, ExistsUserQueryVariables>;
export function refetchExistsUserQuery(variables?: ExistsUserQueryVariables) {
      return { query: ExistsUserDocument, variables: variables }
    }
export const SearchUsersAdvancedDocument = gql`
    query searchUsersAdvanced($exact_query: String, $substring_query: String, $limit: Int, $offset: Int, $order_by: [users_order_by!], $additional_filters: [users_bool_exp], $count_aggregate_limit: Int) {
  users(
    where: {_or: [{user_id: {_eq: $exact_query}}, {user_name: {_ilike: $substring_query}}, {user_email: {_ilike: $substring_query}}], _and: $additional_filters}
    limit: $limit
    offset: $offset
    order_by: $order_by
  ) {
    ...UserDetails
  }
  users_aggregate(
    where: {_or: [{user_id: {_eq: $exact_query}}, {user_name: {_ilike: $substring_query}}, {user_email: {_ilike: $substring_query}}], _and: $additional_filters}
    limit: $count_aggregate_limit
  ) {
    aggregate {
      count
    }
  }
  networks(where: {is_archived: {_eq: false}}, order_by: [{network_name: asc}]) {
    ...NetworkBasic
  }
}
    ${UserDetailsFragmentDoc}
${NetworkBasicFragmentDoc}`;

/**
 * __useSearchUsersAdvancedQuery__
 *
 * To run a query within a React component, call `useSearchUsersAdvancedQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchUsersAdvancedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchUsersAdvancedQuery({
 *   variables: {
 *      exact_query: // value for 'exact_query'
 *      substring_query: // value for 'substring_query'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *      additional_filters: // value for 'additional_filters'
 *      count_aggregate_limit: // value for 'count_aggregate_limit'
 *   },
 * });
 */
export function useSearchUsersAdvancedQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SearchUsersAdvancedQuery, SearchUsersAdvancedQueryVariables>) {
        return ApolloReactHooks.useQuery<SearchUsersAdvancedQuery, SearchUsersAdvancedQueryVariables>(SearchUsersAdvancedDocument, baseOptions);
      }
export function useSearchUsersAdvancedLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SearchUsersAdvancedQuery, SearchUsersAdvancedQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SearchUsersAdvancedQuery, SearchUsersAdvancedQueryVariables>(SearchUsersAdvancedDocument, baseOptions);
        }
export type SearchUsersAdvancedQueryHookResult = ReturnType<typeof useSearchUsersAdvancedQuery>;
export type SearchUsersAdvancedLazyQueryHookResult = ReturnType<typeof useSearchUsersAdvancedLazyQuery>;
export type SearchUsersAdvancedQueryResult = ApolloReactCommon.QueryResult<SearchUsersAdvancedQuery, SearchUsersAdvancedQueryVariables>;
export function refetchSearchUsersAdvancedQuery(variables?: SearchUsersAdvancedQueryVariables) {
      return { query: SearchUsersAdvancedDocument, variables: variables }
    }
export const GetUserDetailsDocument = gql`
    query getUserDetails($user_id: String!) {
  users_by_pk(user_id: $user_id) {
    ...UserDetails
  }
}
    ${UserDetailsFragmentDoc}`;

/**
 * __useGetUserDetailsQuery__
 *
 * To run a query within a React component, call `useGetUserDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserDetailsQuery({
 *   variables: {
 *      user_id: // value for 'user_id'
 *   },
 * });
 */
export function useGetUserDetailsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetUserDetailsQuery, GetUserDetailsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetUserDetailsQuery, GetUserDetailsQueryVariables>(GetUserDetailsDocument, baseOptions);
      }
export function useGetUserDetailsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetUserDetailsQuery, GetUserDetailsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetUserDetailsQuery, GetUserDetailsQueryVariables>(GetUserDetailsDocument, baseOptions);
        }
export type GetUserDetailsQueryHookResult = ReturnType<typeof useGetUserDetailsQuery>;
export type GetUserDetailsLazyQueryHookResult = ReturnType<typeof useGetUserDetailsLazyQuery>;
export type GetUserDetailsQueryResult = ApolloReactCommon.QueryResult<GetUserDetailsQuery, GetUserDetailsQueryVariables>;
export function refetchGetUserDetailsQuery(variables?: GetUserDetailsQueryVariables) {
      return { query: GetUserDetailsDocument, variables: variables }
    }
export const CreateUserDocument = gql`
    mutation createUser($user_email: String!, $user_name: String!, $photo: String, $organization_id: String, $network_id: String, $practice_id: String, $is_admin: Boolean = false) {
  create_user(
    user_email: $user_email
    user_name: $user_name
    photo: $photo
    organization_id: $organization_id
    network_id: $network_id
    practice_id: $practice_id
    is_admin: $is_admin
  ) {
    user_id
    user {
      ...UserDetails
      practice {
        completed_baas: baas(where: {status: {_eq: "Completed"}}) {
          external_id
          external_source
        }
      }
    }
  }
}
    ${UserDetailsFragmentDoc}`;
export type CreateUserMutationFn = ApolloReactCommon.MutationFunction<CreateUserMutation, CreateUserMutationVariables>;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      user_email: // value for 'user_email'
 *      user_name: // value for 'user_name'
 *      photo: // value for 'photo'
 *      organization_id: // value for 'organization_id'
 *      network_id: // value for 'network_id'
 *      practice_id: // value for 'practice_id'
 *      is_admin: // value for 'is_admin'
 *   },
 * });
 */
export function useCreateUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateUserMutation, CreateUserMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateUserMutation, CreateUserMutationVariables>(CreateUserDocument, baseOptions);
      }
export type CreateUserMutationHookResult = ReturnType<typeof useCreateUserMutation>;
export type CreateUserMutationResult = ApolloReactCommon.MutationResult<CreateUserMutation>;
export type CreateUserMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateUserMutation, CreateUserMutationVariables>;
export const ResendUserInviteDocument = gql`
    mutation resendUserInvite($user_email: String!) {
  resend_user_invite(user_email: $user_email) {
    user_id
    user {
      ...UserDetails
    }
  }
}
    ${UserDetailsFragmentDoc}`;
export type ResendUserInviteMutationFn = ApolloReactCommon.MutationFunction<ResendUserInviteMutation, ResendUserInviteMutationVariables>;

/**
 * __useResendUserInviteMutation__
 *
 * To run a mutation, you first call `useResendUserInviteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendUserInviteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendUserInviteMutation, { data, loading, error }] = useResendUserInviteMutation({
 *   variables: {
 *      user_email: // value for 'user_email'
 *   },
 * });
 */
export function useResendUserInviteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ResendUserInviteMutation, ResendUserInviteMutationVariables>) {
        return ApolloReactHooks.useMutation<ResendUserInviteMutation, ResendUserInviteMutationVariables>(ResendUserInviteDocument, baseOptions);
      }
export type ResendUserInviteMutationHookResult = ReturnType<typeof useResendUserInviteMutation>;
export type ResendUserInviteMutationResult = ApolloReactCommon.MutationResult<ResendUserInviteMutation>;
export type ResendUserInviteMutationOptions = ApolloReactCommon.BaseMutationOptions<ResendUserInviteMutation, ResendUserInviteMutationVariables>;
export const EnableUserDocument = gql`
    mutation enableUser($user_id: String!) {
  enable_user(user_id: $user_id) {
    user_id
    user {
      ...UserDetails
    }
  }
}
    ${UserDetailsFragmentDoc}`;
export type EnableUserMutationFn = ApolloReactCommon.MutationFunction<EnableUserMutation, EnableUserMutationVariables>;

/**
 * __useEnableUserMutation__
 *
 * To run a mutation, you first call `useEnableUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEnableUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [enableUserMutation, { data, loading, error }] = useEnableUserMutation({
 *   variables: {
 *      user_id: // value for 'user_id'
 *   },
 * });
 */
export function useEnableUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EnableUserMutation, EnableUserMutationVariables>) {
        return ApolloReactHooks.useMutation<EnableUserMutation, EnableUserMutationVariables>(EnableUserDocument, baseOptions);
      }
export type EnableUserMutationHookResult = ReturnType<typeof useEnableUserMutation>;
export type EnableUserMutationResult = ApolloReactCommon.MutationResult<EnableUserMutation>;
export type EnableUserMutationOptions = ApolloReactCommon.BaseMutationOptions<EnableUserMutation, EnableUserMutationVariables>;
export const DisableUserDocument = gql`
    mutation disableUser($user_id: String!) {
  disable_user(user_id: $user_id) {
    user_id
    user {
      ...UserDetails
    }
  }
}
    ${UserDetailsFragmentDoc}`;
export type DisableUserMutationFn = ApolloReactCommon.MutationFunction<DisableUserMutation, DisableUserMutationVariables>;

/**
 * __useDisableUserMutation__
 *
 * To run a mutation, you first call `useDisableUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDisableUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [disableUserMutation, { data, loading, error }] = useDisableUserMutation({
 *   variables: {
 *      user_id: // value for 'user_id'
 *   },
 * });
 */
export function useDisableUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DisableUserMutation, DisableUserMutationVariables>) {
        return ApolloReactHooks.useMutation<DisableUserMutation, DisableUserMutationVariables>(DisableUserDocument, baseOptions);
      }
export type DisableUserMutationHookResult = ReturnType<typeof useDisableUserMutation>;
export type DisableUserMutationResult = ApolloReactCommon.MutationResult<DisableUserMutation>;
export type DisableUserMutationOptions = ApolloReactCommon.BaseMutationOptions<DisableUserMutation, DisableUserMutationVariables>;
export const UpdateUserDocument = gql`
    mutation updateUser($user_id: String!, $changes: UpdateUserAttributes) {
  update_user(user_id: $user_id, changes: $changes) {
    user_id
    user {
      ...UserDetails
    }
  }
}
    ${UserDetailsFragmentDoc}`;
export type UpdateUserMutationFn = ApolloReactCommon.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      user_id: // value for 'user_id'
 *      changes: // value for 'changes'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, baseOptions);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = ApolloReactCommon.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;
export const AdminInsertUserDocument = gql`
    mutation adminInsertUser($user: users_insert_input!) {
  insert_users_one(object: $user) {
    user_id
  }
}
    `;
export type AdminInsertUserMutationFn = ApolloReactCommon.MutationFunction<AdminInsertUserMutation, AdminInsertUserMutationVariables>;

/**
 * __useAdminInsertUserMutation__
 *
 * To run a mutation, you first call `useAdminInsertUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminInsertUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminInsertUserMutation, { data, loading, error }] = useAdminInsertUserMutation({
 *   variables: {
 *      user: // value for 'user'
 *   },
 * });
 */
export function useAdminInsertUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AdminInsertUserMutation, AdminInsertUserMutationVariables>) {
        return ApolloReactHooks.useMutation<AdminInsertUserMutation, AdminInsertUserMutationVariables>(AdminInsertUserDocument, baseOptions);
      }
export type AdminInsertUserMutationHookResult = ReturnType<typeof useAdminInsertUserMutation>;
export type AdminInsertUserMutationResult = ApolloReactCommon.MutationResult<AdminInsertUserMutation>;
export type AdminInsertUserMutationOptions = ApolloReactCommon.BaseMutationOptions<AdminInsertUserMutation, AdminInsertUserMutationVariables>;
export const UpdateUserByPkDocument = gql`
    mutation updateUserByPK($user_id: String!, $changes: users_set_input) {
  update_users_by_pk(pk_columns: {user_id: $user_id}, _set: $changes) {
    ...UserDetails
  }
}
    ${UserDetailsFragmentDoc}`;
export type UpdateUserByPkMutationFn = ApolloReactCommon.MutationFunction<UpdateUserByPkMutation, UpdateUserByPkMutationVariables>;

/**
 * __useUpdateUserByPkMutation__
 *
 * To run a mutation, you first call `useUpdateUserByPkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserByPkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserByPkMutation, { data, loading, error }] = useUpdateUserByPkMutation({
 *   variables: {
 *      user_id: // value for 'user_id'
 *      changes: // value for 'changes'
 *   },
 * });
 */
export function useUpdateUserByPkMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateUserByPkMutation, UpdateUserByPkMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateUserByPkMutation, UpdateUserByPkMutationVariables>(UpdateUserByPkDocument, baseOptions);
      }
export type UpdateUserByPkMutationHookResult = ReturnType<typeof useUpdateUserByPkMutation>;
export type UpdateUserByPkMutationResult = ApolloReactCommon.MutationResult<UpdateUserByPkMutation>;
export type UpdateUserByPkMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateUserByPkMutation, UpdateUserByPkMutationVariables>;
export const UpsertUserDocument = gql`
    mutation upsertUser($user: users_insert_input!, $cols_to_update: [users_update_column!]!) {
  insert_users_one(
    object: $user
    on_conflict: {constraint: users_pkey, update_columns: $cols_to_update}
  ) {
    ...UserDetails
  }
}
    ${UserDetailsFragmentDoc}`;
export type UpsertUserMutationFn = ApolloReactCommon.MutationFunction<UpsertUserMutation, UpsertUserMutationVariables>;

/**
 * __useUpsertUserMutation__
 *
 * To run a mutation, you first call `useUpsertUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertUserMutation, { data, loading, error }] = useUpsertUserMutation({
 *   variables: {
 *      user: // value for 'user'
 *      cols_to_update: // value for 'cols_to_update'
 *   },
 * });
 */
export function useUpsertUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpsertUserMutation, UpsertUserMutationVariables>) {
        return ApolloReactHooks.useMutation<UpsertUserMutation, UpsertUserMutationVariables>(UpsertUserDocument, baseOptions);
      }
export type UpsertUserMutationHookResult = ReturnType<typeof useUpsertUserMutation>;
export type UpsertUserMutationResult = ApolloReactCommon.MutationResult<UpsertUserMutation>;
export type UpsertUserMutationOptions = ApolloReactCommon.BaseMutationOptions<UpsertUserMutation, UpsertUserMutationVariables>;
export const ListOrganizationsDocument = gql`
    query listOrganizations {
  organizations {
    ...OrganizationDetails
  }
}
    ${OrganizationDetailsFragmentDoc}`;

/**
 * __useListOrganizationsQuery__
 *
 * To run a query within a React component, call `useListOrganizationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListOrganizationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListOrganizationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useListOrganizationsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ListOrganizationsQuery, ListOrganizationsQueryVariables>) {
        return ApolloReactHooks.useQuery<ListOrganizationsQuery, ListOrganizationsQueryVariables>(ListOrganizationsDocument, baseOptions);
      }
export function useListOrganizationsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ListOrganizationsQuery, ListOrganizationsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ListOrganizationsQuery, ListOrganizationsQueryVariables>(ListOrganizationsDocument, baseOptions);
        }
export type ListOrganizationsQueryHookResult = ReturnType<typeof useListOrganizationsQuery>;
export type ListOrganizationsLazyQueryHookResult = ReturnType<typeof useListOrganizationsLazyQuery>;
export type ListOrganizationsQueryResult = ApolloReactCommon.QueryResult<ListOrganizationsQuery, ListOrganizationsQueryVariables>;
export function refetchListOrganizationsQuery(variables?: ListOrganizationsQueryVariables) {
      return { query: ListOrganizationsDocument, variables: variables }
    }
export const SearchOrganizationsDocument = gql`
    query searchOrganizations($query: String!) {
  organizations(
    where: {organization_name: {_ilike: $query}, is_archived: {_eq: false}}
    order_by: {organization_name: asc}
    limit: 10
  ) {
    ...OrganizationBasic
  }
}
    ${OrganizationBasicFragmentDoc}`;

/**
 * __useSearchOrganizationsQuery__
 *
 * To run a query within a React component, call `useSearchOrganizationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchOrganizationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchOrganizationsQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useSearchOrganizationsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SearchOrganizationsQuery, SearchOrganizationsQueryVariables>) {
        return ApolloReactHooks.useQuery<SearchOrganizationsQuery, SearchOrganizationsQueryVariables>(SearchOrganizationsDocument, baseOptions);
      }
export function useSearchOrganizationsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SearchOrganizationsQuery, SearchOrganizationsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SearchOrganizationsQuery, SearchOrganizationsQueryVariables>(SearchOrganizationsDocument, baseOptions);
        }
export type SearchOrganizationsQueryHookResult = ReturnType<typeof useSearchOrganizationsQuery>;
export type SearchOrganizationsLazyQueryHookResult = ReturnType<typeof useSearchOrganizationsLazyQuery>;
export type SearchOrganizationsQueryResult = ApolloReactCommon.QueryResult<SearchOrganizationsQuery, SearchOrganizationsQueryVariables>;
export function refetchSearchOrganizationsQuery(variables?: SearchOrganizationsQueryVariables) {
      return { query: SearchOrganizationsDocument, variables: variables }
    }
export const CreateOrganizationDocument = gql`
    mutation createOrganization($organization: organizations_insert_input!) {
  insert_organizations_one(object: $organization) {
    ...OrganizationDetails
  }
}
    ${OrganizationDetailsFragmentDoc}`;
export type CreateOrganizationMutationFn = ApolloReactCommon.MutationFunction<CreateOrganizationMutation, CreateOrganizationMutationVariables>;

/**
 * __useCreateOrganizationMutation__
 *
 * To run a mutation, you first call `useCreateOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrganizationMutation, { data, loading, error }] = useCreateOrganizationMutation({
 *   variables: {
 *      organization: // value for 'organization'
 *   },
 * });
 */
export function useCreateOrganizationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateOrganizationMutation, CreateOrganizationMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateOrganizationMutation, CreateOrganizationMutationVariables>(CreateOrganizationDocument, baseOptions);
      }
export type CreateOrganizationMutationHookResult = ReturnType<typeof useCreateOrganizationMutation>;
export type CreateOrganizationMutationResult = ApolloReactCommon.MutationResult<CreateOrganizationMutation>;
export type CreateOrganizationMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateOrganizationMutation, CreateOrganizationMutationVariables>;
export const UpdateOrganizationDocument = gql`
    mutation updateOrganization($organization_id: String!, $changes: organizations_set_input = {updated_at: null}, $organization_networks: [organization_networks_insert_input!]! = []) {
  update_organizations_by_pk(
    pk_columns: {organization_id: $organization_id}
    _set: $changes
  ) {
    ...OrganizationDetails
  }
  insert_organization_networks(
    objects: $organization_networks
    on_conflict: {constraint: organization_networks_pkey, update_columns: [is_archived]}
  ) {
    returning {
      organization_id
      network_id
      is_archived
    }
  }
}
    ${OrganizationDetailsFragmentDoc}`;
export type UpdateOrganizationMutationFn = ApolloReactCommon.MutationFunction<UpdateOrganizationMutation, UpdateOrganizationMutationVariables>;

/**
 * __useUpdateOrganizationMutation__
 *
 * To run a mutation, you first call `useUpdateOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrganizationMutation, { data, loading, error }] = useUpdateOrganizationMutation({
 *   variables: {
 *      organization_id: // value for 'organization_id'
 *      changes: // value for 'changes'
 *      organization_networks: // value for 'organization_networks'
 *   },
 * });
 */
export function useUpdateOrganizationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateOrganizationMutation, UpdateOrganizationMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateOrganizationMutation, UpdateOrganizationMutationVariables>(UpdateOrganizationDocument, baseOptions);
      }
export type UpdateOrganizationMutationHookResult = ReturnType<typeof useUpdateOrganizationMutation>;
export type UpdateOrganizationMutationResult = ApolloReactCommon.MutationResult<UpdateOrganizationMutation>;
export type UpdateOrganizationMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateOrganizationMutation, UpdateOrganizationMutationVariables>;
export const GetPracticesForOrganizationDocument = gql`
    query getPracticesForOrganization($organization_id: String!) {
  networks(
    where: {organization_networks: {organization_id: {_eq: $organization_id}, is_archived: {_eq: false}}, is_archived: {_eq: false}}
  ) {
    network_id
    network_name
    network_description
    practices(where: {is_archived: {_eq: false}}) {
      practice_id
      practice_name
      practice_description
    }
  }
}
    `;

/**
 * __useGetPracticesForOrganizationQuery__
 *
 * To run a query within a React component, call `useGetPracticesForOrganizationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPracticesForOrganizationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPracticesForOrganizationQuery({
 *   variables: {
 *      organization_id: // value for 'organization_id'
 *   },
 * });
 */
export function useGetPracticesForOrganizationQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetPracticesForOrganizationQuery, GetPracticesForOrganizationQueryVariables>) {
        return ApolloReactHooks.useQuery<GetPracticesForOrganizationQuery, GetPracticesForOrganizationQueryVariables>(GetPracticesForOrganizationDocument, baseOptions);
      }
export function useGetPracticesForOrganizationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPracticesForOrganizationQuery, GetPracticesForOrganizationQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetPracticesForOrganizationQuery, GetPracticesForOrganizationQueryVariables>(GetPracticesForOrganizationDocument, baseOptions);
        }
export type GetPracticesForOrganizationQueryHookResult = ReturnType<typeof useGetPracticesForOrganizationQuery>;
export type GetPracticesForOrganizationLazyQueryHookResult = ReturnType<typeof useGetPracticesForOrganizationLazyQuery>;
export type GetPracticesForOrganizationQueryResult = ApolloReactCommon.QueryResult<GetPracticesForOrganizationQuery, GetPracticesForOrganizationQueryVariables>;
export function refetchGetPracticesForOrganizationQuery(variables?: GetPracticesForOrganizationQueryVariables) {
      return { query: GetPracticesForOrganizationDocument, variables: variables }
    }
export const ListNetworksDocument = gql`
    query listNetworks {
  networks(order_by: {created_at: desc}) {
    ...NetworkDetails
  }
}
    ${NetworkDetailsFragmentDoc}`;

/**
 * __useListNetworksQuery__
 *
 * To run a query within a React component, call `useListNetworksQuery` and pass it any options that fit your needs.
 * When your component renders, `useListNetworksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListNetworksQuery({
 *   variables: {
 *   },
 * });
 */
export function useListNetworksQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ListNetworksQuery, ListNetworksQueryVariables>) {
        return ApolloReactHooks.useQuery<ListNetworksQuery, ListNetworksQueryVariables>(ListNetworksDocument, baseOptions);
      }
export function useListNetworksLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ListNetworksQuery, ListNetworksQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ListNetworksQuery, ListNetworksQueryVariables>(ListNetworksDocument, baseOptions);
        }
export type ListNetworksQueryHookResult = ReturnType<typeof useListNetworksQuery>;
export type ListNetworksLazyQueryHookResult = ReturnType<typeof useListNetworksLazyQuery>;
export type ListNetworksQueryResult = ApolloReactCommon.QueryResult<ListNetworksQuery, ListNetworksQueryVariables>;
export function refetchListNetworksQuery(variables?: ListNetworksQueryVariables) {
      return { query: ListNetworksDocument, variables: variables }
    }
export const GetNetworkBasicBySlugDocument = gql`
    query getNetworkBasicBySlug($network_slug: String!) {
  networks(where: {network_slug: {_eq: $network_slug}}) {
    network_id
    network_name
    network_slug
    network_logo
  }
}
    `;

/**
 * __useGetNetworkBasicBySlugQuery__
 *
 * To run a query within a React component, call `useGetNetworkBasicBySlugQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNetworkBasicBySlugQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNetworkBasicBySlugQuery({
 *   variables: {
 *      network_slug: // value for 'network_slug'
 *   },
 * });
 */
export function useGetNetworkBasicBySlugQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetNetworkBasicBySlugQuery, GetNetworkBasicBySlugQueryVariables>) {
        return ApolloReactHooks.useQuery<GetNetworkBasicBySlugQuery, GetNetworkBasicBySlugQueryVariables>(GetNetworkBasicBySlugDocument, baseOptions);
      }
export function useGetNetworkBasicBySlugLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetNetworkBasicBySlugQuery, GetNetworkBasicBySlugQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetNetworkBasicBySlugQuery, GetNetworkBasicBySlugQueryVariables>(GetNetworkBasicBySlugDocument, baseOptions);
        }
export type GetNetworkBasicBySlugQueryHookResult = ReturnType<typeof useGetNetworkBasicBySlugQuery>;
export type GetNetworkBasicBySlugLazyQueryHookResult = ReturnType<typeof useGetNetworkBasicBySlugLazyQuery>;
export type GetNetworkBasicBySlugQueryResult = ApolloReactCommon.QueryResult<GetNetworkBasicBySlugQuery, GetNetworkBasicBySlugQueryVariables>;
export function refetchGetNetworkBasicBySlugQuery(variables?: GetNetworkBasicBySlugQueryVariables) {
      return { query: GetNetworkBasicBySlugDocument, variables: variables }
    }
export const GetNetworkByNumberDocument = gql`
    query getNetworkByNumber($network_number: Int!) {
  networks(where: {network_number: {_eq: $network_number}}) {
    ...NetworkBasic
    default_practice {
      ...PracticeBasic
    }
  }
}
    ${NetworkBasicFragmentDoc}
${PracticeBasicFragmentDoc}`;

/**
 * __useGetNetworkByNumberQuery__
 *
 * To run a query within a React component, call `useGetNetworkByNumberQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNetworkByNumberQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNetworkByNumberQuery({
 *   variables: {
 *      network_number: // value for 'network_number'
 *   },
 * });
 */
export function useGetNetworkByNumberQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetNetworkByNumberQuery, GetNetworkByNumberQueryVariables>) {
        return ApolloReactHooks.useQuery<GetNetworkByNumberQuery, GetNetworkByNumberQueryVariables>(GetNetworkByNumberDocument, baseOptions);
      }
export function useGetNetworkByNumberLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetNetworkByNumberQuery, GetNetworkByNumberQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetNetworkByNumberQuery, GetNetworkByNumberQueryVariables>(GetNetworkByNumberDocument, baseOptions);
        }
export type GetNetworkByNumberQueryHookResult = ReturnType<typeof useGetNetworkByNumberQuery>;
export type GetNetworkByNumberLazyQueryHookResult = ReturnType<typeof useGetNetworkByNumberLazyQuery>;
export type GetNetworkByNumberQueryResult = ApolloReactCommon.QueryResult<GetNetworkByNumberQuery, GetNetworkByNumberQueryVariables>;
export function refetchGetNetworkByNumberQuery(variables?: GetNetworkByNumberQueryVariables) {
      return { query: GetNetworkByNumberDocument, variables: variables }
    }
export const GetNetworkDetailsDocument = gql`
    query getNetworkDetails($network_id: String!) {
  networks_by_pk(network_id: $network_id) {
    ...NetworkDetails
  }
}
    ${NetworkDetailsFragmentDoc}`;

/**
 * __useGetNetworkDetailsQuery__
 *
 * To run a query within a React component, call `useGetNetworkDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNetworkDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNetworkDetailsQuery({
 *   variables: {
 *      network_id: // value for 'network_id'
 *   },
 * });
 */
export function useGetNetworkDetailsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetNetworkDetailsQuery, GetNetworkDetailsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetNetworkDetailsQuery, GetNetworkDetailsQueryVariables>(GetNetworkDetailsDocument, baseOptions);
      }
export function useGetNetworkDetailsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetNetworkDetailsQuery, GetNetworkDetailsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetNetworkDetailsQuery, GetNetworkDetailsQueryVariables>(GetNetworkDetailsDocument, baseOptions);
        }
export type GetNetworkDetailsQueryHookResult = ReturnType<typeof useGetNetworkDetailsQuery>;
export type GetNetworkDetailsLazyQueryHookResult = ReturnType<typeof useGetNetworkDetailsLazyQuery>;
export type GetNetworkDetailsQueryResult = ApolloReactCommon.QueryResult<GetNetworkDetailsQuery, GetNetworkDetailsQueryVariables>;
export function refetchGetNetworkDetailsQuery(variables?: GetNetworkDetailsQueryVariables) {
      return { query: GetNetworkDetailsDocument, variables: variables }
    }
export const CreateNetworkDocument = gql`
    mutation createNetwork($network: networks_insert_input!) {
  insert_networks_one(object: $network) {
    ...NetworkDetails
  }
}
    ${NetworkDetailsFragmentDoc}`;
export type CreateNetworkMutationFn = ApolloReactCommon.MutationFunction<CreateNetworkMutation, CreateNetworkMutationVariables>;

/**
 * __useCreateNetworkMutation__
 *
 * To run a mutation, you first call `useCreateNetworkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNetworkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNetworkMutation, { data, loading, error }] = useCreateNetworkMutation({
 *   variables: {
 *      network: // value for 'network'
 *   },
 * });
 */
export function useCreateNetworkMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateNetworkMutation, CreateNetworkMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateNetworkMutation, CreateNetworkMutationVariables>(CreateNetworkDocument, baseOptions);
      }
export type CreateNetworkMutationHookResult = ReturnType<typeof useCreateNetworkMutation>;
export type CreateNetworkMutationResult = ApolloReactCommon.MutationResult<CreateNetworkMutation>;
export type CreateNetworkMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateNetworkMutation, CreateNetworkMutationVariables>;
export const UpdateNetworkDocument = gql`
    mutation updateNetwork($network_id: String!, $changes: networks_set_input = {updated_at: null}) {
  update_networks_by_pk(pk_columns: {network_id: $network_id}, _set: $changes) {
    ...NetworkDetails
  }
}
    ${NetworkDetailsFragmentDoc}`;
export type UpdateNetworkMutationFn = ApolloReactCommon.MutationFunction<UpdateNetworkMutation, UpdateNetworkMutationVariables>;

/**
 * __useUpdateNetworkMutation__
 *
 * To run a mutation, you first call `useUpdateNetworkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateNetworkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateNetworkMutation, { data, loading, error }] = useUpdateNetworkMutation({
 *   variables: {
 *      network_id: // value for 'network_id'
 *      changes: // value for 'changes'
 *   },
 * });
 */
export function useUpdateNetworkMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateNetworkMutation, UpdateNetworkMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateNetworkMutation, UpdateNetworkMutationVariables>(UpdateNetworkDocument, baseOptions);
      }
export type UpdateNetworkMutationHookResult = ReturnType<typeof useUpdateNetworkMutation>;
export type UpdateNetworkMutationResult = ApolloReactCommon.MutationResult<UpdateNetworkMutation>;
export type UpdateNetworkMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateNetworkMutation, UpdateNetworkMutationVariables>;
export const SearchNetworksDocument = gql`
    query searchNetworks($query: String!) {
  networks(
    where: {network_name: {_ilike: $query}, is_archived: {_eq: false}}
    order_by: {network_name: asc}
    limit: 10
  ) {
    ...NetworkBasic
  }
}
    ${NetworkBasicFragmentDoc}`;

/**
 * __useSearchNetworksQuery__
 *
 * To run a query within a React component, call `useSearchNetworksQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchNetworksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchNetworksQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useSearchNetworksQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SearchNetworksQuery, SearchNetworksQueryVariables>) {
        return ApolloReactHooks.useQuery<SearchNetworksQuery, SearchNetworksQueryVariables>(SearchNetworksDocument, baseOptions);
      }
export function useSearchNetworksLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SearchNetworksQuery, SearchNetworksQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SearchNetworksQuery, SearchNetworksQueryVariables>(SearchNetworksDocument, baseOptions);
        }
export type SearchNetworksQueryHookResult = ReturnType<typeof useSearchNetworksQuery>;
export type SearchNetworksLazyQueryHookResult = ReturnType<typeof useSearchNetworksLazyQuery>;
export type SearchNetworksQueryResult = ApolloReactCommon.QueryResult<SearchNetworksQuery, SearchNetworksQueryVariables>;
export function refetchSearchNetworksQuery(variables?: SearchNetworksQueryVariables) {
      return { query: SearchNetworksDocument, variables: variables }
    }
export const SearchNetworksByProvidersDocument = gql`
    query searchNetworksByProviders($provider_ids: [String!]) {
  networks_aggregate(
    where: {provider_memberships: {provider_id: {_in: $provider_ids}}, is_archived: {_eq: false}}
  ) {
    nodes {
      ...NetworkBasic
      provider_memberships_aggregate(
        where: {provider_id: {_in: $provider_ids}, is_archived: {_eq: false}}
      ) {
        aggregate {
          count(columns: network_id)
        }
      }
    }
  }
}
    ${NetworkBasicFragmentDoc}`;

/**
 * __useSearchNetworksByProvidersQuery__
 *
 * To run a query within a React component, call `useSearchNetworksByProvidersQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchNetworksByProvidersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchNetworksByProvidersQuery({
 *   variables: {
 *      provider_ids: // value for 'provider_ids'
 *   },
 * });
 */
export function useSearchNetworksByProvidersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SearchNetworksByProvidersQuery, SearchNetworksByProvidersQueryVariables>) {
        return ApolloReactHooks.useQuery<SearchNetworksByProvidersQuery, SearchNetworksByProvidersQueryVariables>(SearchNetworksByProvidersDocument, baseOptions);
      }
export function useSearchNetworksByProvidersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SearchNetworksByProvidersQuery, SearchNetworksByProvidersQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SearchNetworksByProvidersQuery, SearchNetworksByProvidersQueryVariables>(SearchNetworksByProvidersDocument, baseOptions);
        }
export type SearchNetworksByProvidersQueryHookResult = ReturnType<typeof useSearchNetworksByProvidersQuery>;
export type SearchNetworksByProvidersLazyQueryHookResult = ReturnType<typeof useSearchNetworksByProvidersLazyQuery>;
export type SearchNetworksByProvidersQueryResult = ApolloReactCommon.QueryResult<SearchNetworksByProvidersQuery, SearchNetworksByProvidersQueryVariables>;
export function refetchSearchNetworksByProvidersQuery(variables?: SearchNetworksByProvidersQueryVariables) {
      return { query: SearchNetworksByProvidersDocument, variables: variables }
    }
export const ListPracticesDocument = gql`
    query listPractices {
  practices {
    ...PracticeBasic
  }
}
    ${PracticeBasicFragmentDoc}`;

/**
 * __useListPracticesQuery__
 *
 * To run a query within a React component, call `useListPracticesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListPracticesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListPracticesQuery({
 *   variables: {
 *   },
 * });
 */
export function useListPracticesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ListPracticesQuery, ListPracticesQueryVariables>) {
        return ApolloReactHooks.useQuery<ListPracticesQuery, ListPracticesQueryVariables>(ListPracticesDocument, baseOptions);
      }
export function useListPracticesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ListPracticesQuery, ListPracticesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ListPracticesQuery, ListPracticesQueryVariables>(ListPracticesDocument, baseOptions);
        }
export type ListPracticesQueryHookResult = ReturnType<typeof useListPracticesQuery>;
export type ListPracticesLazyQueryHookResult = ReturnType<typeof useListPracticesLazyQuery>;
export type ListPracticesQueryResult = ApolloReactCommon.QueryResult<ListPracticesQuery, ListPracticesQueryVariables>;
export function refetchListPracticesQuery(variables?: ListPracticesQueryVariables) {
      return { query: ListPracticesDocument, variables: variables }
    }
export const SearchPracticesDocument = gql`
    query searchPractices($query: String!, $include_archived: Boolean = true) {
  practices(
    where: {practice_name: {_ilike: $query}, _or: [{is_archived: {_eq: false}}, {is_archived: {_eq: $include_archived}}]}
    limit: 10
  ) {
    ...PracticeBasic
  }
}
    ${PracticeBasicFragmentDoc}`;

/**
 * __useSearchPracticesQuery__
 *
 * To run a query within a React component, call `useSearchPracticesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchPracticesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchPracticesQuery({
 *   variables: {
 *      query: // value for 'query'
 *      include_archived: // value for 'include_archived'
 *   },
 * });
 */
export function useSearchPracticesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SearchPracticesQuery, SearchPracticesQueryVariables>) {
        return ApolloReactHooks.useQuery<SearchPracticesQuery, SearchPracticesQueryVariables>(SearchPracticesDocument, baseOptions);
      }
export function useSearchPracticesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SearchPracticesQuery, SearchPracticesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SearchPracticesQuery, SearchPracticesQueryVariables>(SearchPracticesDocument, baseOptions);
        }
export type SearchPracticesQueryHookResult = ReturnType<typeof useSearchPracticesQuery>;
export type SearchPracticesLazyQueryHookResult = ReturnType<typeof useSearchPracticesLazyQuery>;
export type SearchPracticesQueryResult = ApolloReactCommon.QueryResult<SearchPracticesQuery, SearchPracticesQueryVariables>;
export function refetchSearchPracticesQuery(variables?: SearchPracticesQueryVariables) {
      return { query: SearchPracticesDocument, variables: variables }
    }
export const GetPracticeDetailsDocument = gql`
    query getPracticeDetails($practice_id: String!) {
  practices_by_pk(practice_id: $practice_id) {
    ...PracticeDetails
  }
}
    ${PracticeDetailsFragmentDoc}`;

/**
 * __useGetPracticeDetailsQuery__
 *
 * To run a query within a React component, call `useGetPracticeDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPracticeDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPracticeDetailsQuery({
 *   variables: {
 *      practice_id: // value for 'practice_id'
 *   },
 * });
 */
export function useGetPracticeDetailsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetPracticeDetailsQuery, GetPracticeDetailsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetPracticeDetailsQuery, GetPracticeDetailsQueryVariables>(GetPracticeDetailsDocument, baseOptions);
      }
export function useGetPracticeDetailsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPracticeDetailsQuery, GetPracticeDetailsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetPracticeDetailsQuery, GetPracticeDetailsQueryVariables>(GetPracticeDetailsDocument, baseOptions);
        }
export type GetPracticeDetailsQueryHookResult = ReturnType<typeof useGetPracticeDetailsQuery>;
export type GetPracticeDetailsLazyQueryHookResult = ReturnType<typeof useGetPracticeDetailsLazyQuery>;
export type GetPracticeDetailsQueryResult = ApolloReactCommon.QueryResult<GetPracticeDetailsQuery, GetPracticeDetailsQueryVariables>;
export function refetchGetPracticeDetailsQuery(variables?: GetPracticeDetailsQueryVariables) {
      return { query: GetPracticeDetailsDocument, variables: variables }
    }
export const SearchPracticesAdvancedDocument = gql`
    query searchPracticesAdvanced($exact_query: String, $substring_query: String, $limit: Int, $offset: Int, $order_by: [practices_order_by!], $additional_filters: [practices_bool_exp], $count_aggregate_limit: Int) {
  practices(
    where: {_or: [{practice_id: {_eq: $exact_query}}, {practice_name: {_ilike: $substring_query}}, {practice_address: {_ilike: $substring_query}}], _and: $additional_filters}
    limit: $limit
    offset: $offset
    order_by: $order_by
  ) {
    ...PracticeDetails
  }
  practices_aggregate(
    where: {_or: [{practice_id: {_eq: $exact_query}}, {practice_name: {_ilike: $substring_query}}, {practice_address: {_ilike: $substring_query}}], _and: $additional_filters}
    limit: $count_aggregate_limit
  ) {
    aggregate {
      count
    }
  }
  networks(where: {is_archived: {_eq: false}}, order_by: [{network_name: asc}]) {
    ...NetworkBasic
  }
}
    ${PracticeDetailsFragmentDoc}
${NetworkBasicFragmentDoc}`;

/**
 * __useSearchPracticesAdvancedQuery__
 *
 * To run a query within a React component, call `useSearchPracticesAdvancedQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchPracticesAdvancedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchPracticesAdvancedQuery({
 *   variables: {
 *      exact_query: // value for 'exact_query'
 *      substring_query: // value for 'substring_query'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *      additional_filters: // value for 'additional_filters'
 *      count_aggregate_limit: // value for 'count_aggregate_limit'
 *   },
 * });
 */
export function useSearchPracticesAdvancedQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SearchPracticesAdvancedQuery, SearchPracticesAdvancedQueryVariables>) {
        return ApolloReactHooks.useQuery<SearchPracticesAdvancedQuery, SearchPracticesAdvancedQueryVariables>(SearchPracticesAdvancedDocument, baseOptions);
      }
export function useSearchPracticesAdvancedLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SearchPracticesAdvancedQuery, SearchPracticesAdvancedQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SearchPracticesAdvancedQuery, SearchPracticesAdvancedQueryVariables>(SearchPracticesAdvancedDocument, baseOptions);
        }
export type SearchPracticesAdvancedQueryHookResult = ReturnType<typeof useSearchPracticesAdvancedQuery>;
export type SearchPracticesAdvancedLazyQueryHookResult = ReturnType<typeof useSearchPracticesAdvancedLazyQuery>;
export type SearchPracticesAdvancedQueryResult = ApolloReactCommon.QueryResult<SearchPracticesAdvancedQuery, SearchPracticesAdvancedQueryVariables>;
export function refetchSearchPracticesAdvancedQuery(variables?: SearchPracticesAdvancedQueryVariables) {
      return { query: SearchPracticesAdvancedDocument, variables: variables }
    }
export const CreatePracticeDocument = gql`
    mutation createPractice($practice: practices_insert_input!) {
  insert_practices_one(object: $practice) {
    ...PracticeDetails
  }
}
    ${PracticeDetailsFragmentDoc}`;
export type CreatePracticeMutationFn = ApolloReactCommon.MutationFunction<CreatePracticeMutation, CreatePracticeMutationVariables>;

/**
 * __useCreatePracticeMutation__
 *
 * To run a mutation, you first call `useCreatePracticeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePracticeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPracticeMutation, { data, loading, error }] = useCreatePracticeMutation({
 *   variables: {
 *      practice: // value for 'practice'
 *   },
 * });
 */
export function useCreatePracticeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreatePracticeMutation, CreatePracticeMutationVariables>) {
        return ApolloReactHooks.useMutation<CreatePracticeMutation, CreatePracticeMutationVariables>(CreatePracticeDocument, baseOptions);
      }
export type CreatePracticeMutationHookResult = ReturnType<typeof useCreatePracticeMutation>;
export type CreatePracticeMutationResult = ApolloReactCommon.MutationResult<CreatePracticeMutation>;
export type CreatePracticeMutationOptions = ApolloReactCommon.BaseMutationOptions<CreatePracticeMutation, CreatePracticeMutationVariables>;
export const UpdatePracticeDocument = gql`
    mutation updatePractice($practice_id: String!, $changes: practices_set_input = {updated_at: null}, $provider_employments: [provider_employments_insert_input!]! = []) {
  insert_provider_employments(
    objects: $provider_employments
    on_conflict: {constraint: provider_employments_pkey, update_columns: [is_archived]}
  ) {
    returning {
      ...ProviderEmploymentBasic
    }
  }
  update_practices_by_pk(pk_columns: {practice_id: $practice_id}, _set: $changes) {
    ...PracticeDetails
  }
}
    ${ProviderEmploymentBasicFragmentDoc}
${PracticeDetailsFragmentDoc}`;
export type UpdatePracticeMutationFn = ApolloReactCommon.MutationFunction<UpdatePracticeMutation, UpdatePracticeMutationVariables>;

/**
 * __useUpdatePracticeMutation__
 *
 * To run a mutation, you first call `useUpdatePracticeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePracticeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePracticeMutation, { data, loading, error }] = useUpdatePracticeMutation({
 *   variables: {
 *      practice_id: // value for 'practice_id'
 *      changes: // value for 'changes'
 *      provider_employments: // value for 'provider_employments'
 *   },
 * });
 */
export function useUpdatePracticeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdatePracticeMutation, UpdatePracticeMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdatePracticeMutation, UpdatePracticeMutationVariables>(UpdatePracticeDocument, baseOptions);
      }
export type UpdatePracticeMutationHookResult = ReturnType<typeof useUpdatePracticeMutation>;
export type UpdatePracticeMutationResult = ApolloReactCommon.MutationResult<UpdatePracticeMutation>;
export type UpdatePracticeMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdatePracticeMutation, UpdatePracticeMutationVariables>;
export const UpdateProviderEmploymentDocument = gql`
    mutation updateProviderEmployment($practice_id: String!, $provider_id: String!, $changes: provider_employments_set_input!) {
  update_provider_employments_by_pk(
    pk_columns: {practice_id: $practice_id, provider_id: $provider_id}
    _set: $changes
  ) {
    ...ProviderEmploymentBasic
  }
}
    ${ProviderEmploymentBasicFragmentDoc}`;
export type UpdateProviderEmploymentMutationFn = ApolloReactCommon.MutationFunction<UpdateProviderEmploymentMutation, UpdateProviderEmploymentMutationVariables>;

/**
 * __useUpdateProviderEmploymentMutation__
 *
 * To run a mutation, you first call `useUpdateProviderEmploymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProviderEmploymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProviderEmploymentMutation, { data, loading, error }] = useUpdateProviderEmploymentMutation({
 *   variables: {
 *      practice_id: // value for 'practice_id'
 *      provider_id: // value for 'provider_id'
 *      changes: // value for 'changes'
 *   },
 * });
 */
export function useUpdateProviderEmploymentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateProviderEmploymentMutation, UpdateProviderEmploymentMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateProviderEmploymentMutation, UpdateProviderEmploymentMutationVariables>(UpdateProviderEmploymentDocument, baseOptions);
      }
export type UpdateProviderEmploymentMutationHookResult = ReturnType<typeof useUpdateProviderEmploymentMutation>;
export type UpdateProviderEmploymentMutationResult = ApolloReactCommon.MutationResult<UpdateProviderEmploymentMutation>;
export type UpdateProviderEmploymentMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateProviderEmploymentMutation, UpdateProviderEmploymentMutationVariables>;
export const SearchProvidersDocument = gql`
    query searchProviders($query: String!) {
  providers(
    where: {_or: [{provider_id: {_ilike: $query}}, {provider_name: {_ilike: $query}}], is_archived: {_eq: false}}
    limit: 10
  ) {
    ...ProviderBasic
  }
}
    ${ProviderBasicFragmentDoc}`;

/**
 * __useSearchProvidersQuery__
 *
 * To run a query within a React component, call `useSearchProvidersQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchProvidersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchProvidersQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useSearchProvidersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SearchProvidersQuery, SearchProvidersQueryVariables>) {
        return ApolloReactHooks.useQuery<SearchProvidersQuery, SearchProvidersQueryVariables>(SearchProvidersDocument, baseOptions);
      }
export function useSearchProvidersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SearchProvidersQuery, SearchProvidersQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SearchProvidersQuery, SearchProvidersQueryVariables>(SearchProvidersDocument, baseOptions);
        }
export type SearchProvidersQueryHookResult = ReturnType<typeof useSearchProvidersQuery>;
export type SearchProvidersLazyQueryHookResult = ReturnType<typeof useSearchProvidersLazyQuery>;
export type SearchProvidersQueryResult = ApolloReactCommon.QueryResult<SearchProvidersQuery, SearchProvidersQueryVariables>;
export function refetchSearchProvidersQuery(variables?: SearchProvidersQueryVariables) {
      return { query: SearchProvidersDocument, variables: variables }
    }
export const SearchProvidersAdvancedDocument = gql`
    query searchProvidersAdvanced($exact_query: String, $substring_query: String, $limit: Int, $offset: Int, $order_by: [providers_order_by!], $additional_filters: [providers_bool_exp], $count_aggregate_limit: Int) {
  providers(
    where: {_or: [{provider_id: {_eq: $exact_query}}, {provider_name: {_ilike: $substring_query}}], _and: $additional_filters}
    limit: $limit
    offset: $offset
    order_by: $order_by
  ) {
    ...ProviderDetails
  }
  providers_aggregate(
    where: {_or: [{provider_id: {_eq: $exact_query}}, {provider_name: {_ilike: $substring_query}}], _and: $additional_filters}
    limit: $count_aggregate_limit
  ) {
    aggregate {
      count
    }
  }
  networks(where: {is_archived: {_eq: false}}, order_by: [{network_name: asc}]) {
    ...NetworkBasic
  }
}
    ${ProviderDetailsFragmentDoc}
${NetworkBasicFragmentDoc}`;

/**
 * __useSearchProvidersAdvancedQuery__
 *
 * To run a query within a React component, call `useSearchProvidersAdvancedQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchProvidersAdvancedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchProvidersAdvancedQuery({
 *   variables: {
 *      exact_query: // value for 'exact_query'
 *      substring_query: // value for 'substring_query'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *      additional_filters: // value for 'additional_filters'
 *      count_aggregate_limit: // value for 'count_aggregate_limit'
 *   },
 * });
 */
export function useSearchProvidersAdvancedQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SearchProvidersAdvancedQuery, SearchProvidersAdvancedQueryVariables>) {
        return ApolloReactHooks.useQuery<SearchProvidersAdvancedQuery, SearchProvidersAdvancedQueryVariables>(SearchProvidersAdvancedDocument, baseOptions);
      }
export function useSearchProvidersAdvancedLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SearchProvidersAdvancedQuery, SearchProvidersAdvancedQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SearchProvidersAdvancedQuery, SearchProvidersAdvancedQueryVariables>(SearchProvidersAdvancedDocument, baseOptions);
        }
export type SearchProvidersAdvancedQueryHookResult = ReturnType<typeof useSearchProvidersAdvancedQuery>;
export type SearchProvidersAdvancedLazyQueryHookResult = ReturnType<typeof useSearchProvidersAdvancedLazyQuery>;
export type SearchProvidersAdvancedQueryResult = ApolloReactCommon.QueryResult<SearchProvidersAdvancedQuery, SearchProvidersAdvancedQueryVariables>;
export function refetchSearchProvidersAdvancedQuery(variables?: SearchProvidersAdvancedQueryVariables) {
      return { query: SearchProvidersAdvancedDocument, variables: variables }
    }
export const CreateProviderDocument = gql`
    mutation createProvider($provider: providers_insert_input!) {
  insert_providers_one(object: $provider) {
    ...ProviderDetails
  }
}
    ${ProviderDetailsFragmentDoc}`;
export type CreateProviderMutationFn = ApolloReactCommon.MutationFunction<CreateProviderMutation, CreateProviderMutationVariables>;

/**
 * __useCreateProviderMutation__
 *
 * To run a mutation, you first call `useCreateProviderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProviderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProviderMutation, { data, loading, error }] = useCreateProviderMutation({
 *   variables: {
 *      provider: // value for 'provider'
 *   },
 * });
 */
export function useCreateProviderMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateProviderMutation, CreateProviderMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateProviderMutation, CreateProviderMutationVariables>(CreateProviderDocument, baseOptions);
      }
export type CreateProviderMutationHookResult = ReturnType<typeof useCreateProviderMutation>;
export type CreateProviderMutationResult = ApolloReactCommon.MutationResult<CreateProviderMutation>;
export type CreateProviderMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateProviderMutation, CreateProviderMutationVariables>;
export const UpdateProviderDocument = gql`
    mutation updateProvider($provider_id: String!, $changes: providers_set_input = {updated_at: null}, $provider_memberships: [provider_memberships_insert_input!]! = [], $provider_employments: [provider_employments_insert_input!]! = []) {
  insert_provider_memberships(
    objects: $provider_memberships
    on_conflict: {constraint: provider_memberships_pkey, update_columns: [is_archived]}
  ) {
    returning {
      ...ProviderMembershipBasic
    }
  }
  insert_provider_employments(
    objects: $provider_employments
    on_conflict: {constraint: provider_employments_pkey, update_columns: [is_archived]}
  ) {
    returning {
      ...ProviderEmploymentBasic
    }
  }
  update_providers_by_pk(pk_columns: {provider_id: $provider_id}, _set: $changes) {
    ...ProviderDetails
  }
}
    ${ProviderMembershipBasicFragmentDoc}
${ProviderEmploymentBasicFragmentDoc}
${ProviderDetailsFragmentDoc}`;
export type UpdateProviderMutationFn = ApolloReactCommon.MutationFunction<UpdateProviderMutation, UpdateProviderMutationVariables>;

/**
 * __useUpdateProviderMutation__
 *
 * To run a mutation, you first call `useUpdateProviderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProviderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProviderMutation, { data, loading, error }] = useUpdateProviderMutation({
 *   variables: {
 *      provider_id: // value for 'provider_id'
 *      changes: // value for 'changes'
 *      provider_memberships: // value for 'provider_memberships'
 *      provider_employments: // value for 'provider_employments'
 *   },
 * });
 */
export function useUpdateProviderMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateProviderMutation, UpdateProviderMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateProviderMutation, UpdateProviderMutationVariables>(UpdateProviderDocument, baseOptions);
      }
export type UpdateProviderMutationHookResult = ReturnType<typeof useUpdateProviderMutation>;
export type UpdateProviderMutationResult = ApolloReactCommon.MutationResult<UpdateProviderMutation>;
export type UpdateProviderMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateProviderMutation, UpdateProviderMutationVariables>;
export const GetPatientDetailsDocument = gql`
    query getPatientDetails($patient_id: String!, $expanded: Boolean = false) {
  patients_by_pk(patient_id: $patient_id) {
    ...PatientDetails
  }
}
    ${PatientDetailsFragmentDoc}`;

/**
 * __useGetPatientDetailsQuery__
 *
 * To run a query within a React component, call `useGetPatientDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPatientDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPatientDetailsQuery({
 *   variables: {
 *      patient_id: // value for 'patient_id'
 *      expanded: // value for 'expanded'
 *   },
 * });
 */
export function useGetPatientDetailsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetPatientDetailsQuery, GetPatientDetailsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetPatientDetailsQuery, GetPatientDetailsQueryVariables>(GetPatientDetailsDocument, baseOptions);
      }
export function useGetPatientDetailsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPatientDetailsQuery, GetPatientDetailsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetPatientDetailsQuery, GetPatientDetailsQueryVariables>(GetPatientDetailsDocument, baseOptions);
        }
export type GetPatientDetailsQueryHookResult = ReturnType<typeof useGetPatientDetailsQuery>;
export type GetPatientDetailsLazyQueryHookResult = ReturnType<typeof useGetPatientDetailsLazyQuery>;
export type GetPatientDetailsQueryResult = ApolloReactCommon.QueryResult<GetPatientDetailsQuery, GetPatientDetailsQueryVariables>;
export function refetchGetPatientDetailsQuery(variables?: GetPatientDetailsQueryVariables) {
      return { query: GetPatientDetailsDocument, variables: variables }
    }
export const GetPatientCountDocument = gql`
    query getPatientCount {
  patients_aggregate(where: {is_archived: {_eq: false}}) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useGetPatientCountQuery__
 *
 * To run a query within a React component, call `useGetPatientCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPatientCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPatientCountQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPatientCountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetPatientCountQuery, GetPatientCountQueryVariables>) {
        return ApolloReactHooks.useQuery<GetPatientCountQuery, GetPatientCountQueryVariables>(GetPatientCountDocument, baseOptions);
      }
export function useGetPatientCountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPatientCountQuery, GetPatientCountQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetPatientCountQuery, GetPatientCountQueryVariables>(GetPatientCountDocument, baseOptions);
        }
export type GetPatientCountQueryHookResult = ReturnType<typeof useGetPatientCountQuery>;
export type GetPatientCountLazyQueryHookResult = ReturnType<typeof useGetPatientCountLazyQuery>;
export type GetPatientCountQueryResult = ApolloReactCommon.QueryResult<GetPatientCountQuery, GetPatientCountQueryVariables>;
export function refetchGetPatientCountQuery(variables?: GetPatientCountQueryVariables) {
      return { query: GetPatientCountDocument, variables: variables }
    }
export const SearchPatientsByPvaDataDocument = gql`
    query searchPatientsByPVAData($network_number: Int!, $mbi: String!, $patient_name: String!, $letter_id_prefix: String, $birth_date: date) {
  search_patients_by_pva_data(
    args: {input_network_number: $network_number, input_mbi: $mbi, input_patient_name: $patient_name, input_letter_id: $letter_id_prefix, input_birth_date: $birth_date}
  ) {
    ...PatientDetailsPVA
  }
}
    ${PatientDetailsPvaFragmentDoc}`;

/**
 * __useSearchPatientsByPvaDataQuery__
 *
 * To run a query within a React component, call `useSearchPatientsByPvaDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchPatientsByPvaDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchPatientsByPvaDataQuery({
 *   variables: {
 *      network_number: // value for 'network_number'
 *      mbi: // value for 'mbi'
 *      patient_name: // value for 'patient_name'
 *      letter_id_prefix: // value for 'letter_id_prefix'
 *      birth_date: // value for 'birth_date'
 *   },
 * });
 */
export function useSearchPatientsByPvaDataQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SearchPatientsByPvaDataQuery, SearchPatientsByPvaDataQueryVariables>) {
        return ApolloReactHooks.useQuery<SearchPatientsByPvaDataQuery, SearchPatientsByPvaDataQueryVariables>(SearchPatientsByPvaDataDocument, baseOptions);
      }
export function useSearchPatientsByPvaDataLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SearchPatientsByPvaDataQuery, SearchPatientsByPvaDataQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SearchPatientsByPvaDataQuery, SearchPatientsByPvaDataQueryVariables>(SearchPatientsByPvaDataDocument, baseOptions);
        }
export type SearchPatientsByPvaDataQueryHookResult = ReturnType<typeof useSearchPatientsByPvaDataQuery>;
export type SearchPatientsByPvaDataLazyQueryHookResult = ReturnType<typeof useSearchPatientsByPvaDataLazyQuery>;
export type SearchPatientsByPvaDataQueryResult = ApolloReactCommon.QueryResult<SearchPatientsByPvaDataQuery, SearchPatientsByPvaDataQueryVariables>;
export function refetchSearchPatientsByPvaDataQuery(variables?: SearchPatientsByPvaDataQueryVariables) {
      return { query: SearchPatientsByPvaDataDocument, variables: variables }
    }
export const SearchPatientsByHedrDataDocument = gql`
    query searchPatientsByHEDRData($network_number: Int!, $mbi: String!, $patient_name: String!, $letter_id_prefix: String, $birth_date: date) {
  search_patients_by_hedr_data(
    args: {input_network_number: $network_number, input_mbi: $mbi, input_patient_name: $patient_name, input_letter_id: $letter_id_prefix, input_birth_date: $birth_date}
  ) {
    ...PatientDetailsPVA
  }
}
    ${PatientDetailsPvaFragmentDoc}`;

/**
 * __useSearchPatientsByHedrDataQuery__
 *
 * To run a query within a React component, call `useSearchPatientsByHedrDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchPatientsByHedrDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchPatientsByHedrDataQuery({
 *   variables: {
 *      network_number: // value for 'network_number'
 *      mbi: // value for 'mbi'
 *      patient_name: // value for 'patient_name'
 *      letter_id_prefix: // value for 'letter_id_prefix'
 *      birth_date: // value for 'birth_date'
 *   },
 * });
 */
export function useSearchPatientsByHedrDataQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SearchPatientsByHedrDataQuery, SearchPatientsByHedrDataQueryVariables>) {
        return ApolloReactHooks.useQuery<SearchPatientsByHedrDataQuery, SearchPatientsByHedrDataQueryVariables>(SearchPatientsByHedrDataDocument, baseOptions);
      }
export function useSearchPatientsByHedrDataLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SearchPatientsByHedrDataQuery, SearchPatientsByHedrDataQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SearchPatientsByHedrDataQuery, SearchPatientsByHedrDataQueryVariables>(SearchPatientsByHedrDataDocument, baseOptions);
        }
export type SearchPatientsByHedrDataQueryHookResult = ReturnType<typeof useSearchPatientsByHedrDataQuery>;
export type SearchPatientsByHedrDataLazyQueryHookResult = ReturnType<typeof useSearchPatientsByHedrDataLazyQuery>;
export type SearchPatientsByHedrDataQueryResult = ApolloReactCommon.QueryResult<SearchPatientsByHedrDataQuery, SearchPatientsByHedrDataQueryVariables>;
export function refetchSearchPatientsByHedrDataQuery(variables?: SearchPatientsByHedrDataQueryVariables) {
      return { query: SearchPatientsByHedrDataDocument, variables: variables }
    }
export const SearchPatientsAdvancedDocument = gql`
    query searchPatientsAdvanced($exact_query: String, $substring_query: String, $prefix_query: String, $practices: [String!], $networks: [String!], $suspected_providers: [String!], $limit: Int, $offset: Int, $order_by: [patients_order_by!], $additional_filters: [patients_bool_exp], $include_admin_details: Boolean! = false, $count_aggregate_limit: Int) {
  patients(
    where: {_or: [{patient_id: {_eq: $exact_query}}, {external_id: {_eq: $exact_query}}, {patient_name: {_ilike: $substring_query}}, {patient_email: {_ilike: $substring_query}}, {patient_address: {_ilike: $substring_query}}, {mbi: {_ilike: $prefix_query}}, {letters: {letter_id: {_ilike: $prefix_query}}}, {emails: {email_id: {_ilike: $prefix_query}}}], practice_id: {_in: $practices}, practice: {primary_network_id: {_in: $networks}}, suspected_provider_npi: {_in: $suspected_providers}, _and: $additional_filters}
    limit: $limit
    offset: $offset
    order_by: $order_by
  ) {
    ...PatientDetails
    practice @include(if: $include_admin_details) {
      ...PracticeBasic
      primary_network {
        ...NetworkBasic
      }
    }
  }
  patients_aggregate(
    where: {_or: [{patient_id: {_eq: $exact_query}}, {external_id: {_eq: $exact_query}}, {patient_name: {_ilike: $substring_query}}, {patient_email: {_ilike: $substring_query}}, {patient_address: {_ilike: $substring_query}}, {mbi: {_ilike: $prefix_query}}, {letters: {letter_id: {_ilike: $prefix_query}}}, {emails: {email_id: {_ilike: $prefix_query}}}], practice_id: {_in: $practices}, practice: {primary_network_id: {_in: $networks}}, suspected_provider_npi: {_in: $suspected_providers}, _and: $additional_filters}
    limit: $count_aggregate_limit
  ) {
    aggregate {
      count
    }
  }
}
    ${PatientDetailsFragmentDoc}
${PracticeBasicFragmentDoc}
${NetworkBasicFragmentDoc}`;

/**
 * __useSearchPatientsAdvancedQuery__
 *
 * To run a query within a React component, call `useSearchPatientsAdvancedQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchPatientsAdvancedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchPatientsAdvancedQuery({
 *   variables: {
 *      exact_query: // value for 'exact_query'
 *      substring_query: // value for 'substring_query'
 *      prefix_query: // value for 'prefix_query'
 *      practices: // value for 'practices'
 *      networks: // value for 'networks'
 *      suspected_providers: // value for 'suspected_providers'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *      additional_filters: // value for 'additional_filters'
 *      include_admin_details: // value for 'include_admin_details'
 *      count_aggregate_limit: // value for 'count_aggregate_limit'
 *   },
 * });
 */
export function useSearchPatientsAdvancedQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SearchPatientsAdvancedQuery, SearchPatientsAdvancedQueryVariables>) {
        return ApolloReactHooks.useQuery<SearchPatientsAdvancedQuery, SearchPatientsAdvancedQueryVariables>(SearchPatientsAdvancedDocument, baseOptions);
      }
export function useSearchPatientsAdvancedLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SearchPatientsAdvancedQuery, SearchPatientsAdvancedQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SearchPatientsAdvancedQuery, SearchPatientsAdvancedQueryVariables>(SearchPatientsAdvancedDocument, baseOptions);
        }
export type SearchPatientsAdvancedQueryHookResult = ReturnType<typeof useSearchPatientsAdvancedQuery>;
export type SearchPatientsAdvancedLazyQueryHookResult = ReturnType<typeof useSearchPatientsAdvancedLazyQuery>;
export type SearchPatientsAdvancedQueryResult = ApolloReactCommon.QueryResult<SearchPatientsAdvancedQuery, SearchPatientsAdvancedQueryVariables>;
export function refetchSearchPatientsAdvancedQuery(variables?: SearchPatientsAdvancedQueryVariables) {
      return { query: SearchPatientsAdvancedDocument, variables: variables }
    }
export const CreatePatientDocument = gql`
    mutation createPatient($patient: patients_insert_input!) {
  insert_patients_one(object: $patient) {
    ...PatientDetails
  }
}
    ${PatientDetailsFragmentDoc}`;
export type CreatePatientMutationFn = ApolloReactCommon.MutationFunction<CreatePatientMutation, CreatePatientMutationVariables>;

/**
 * __useCreatePatientMutation__
 *
 * To run a mutation, you first call `useCreatePatientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePatientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPatientMutation, { data, loading, error }] = useCreatePatientMutation({
 *   variables: {
 *      patient: // value for 'patient'
 *   },
 * });
 */
export function useCreatePatientMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreatePatientMutation, CreatePatientMutationVariables>) {
        return ApolloReactHooks.useMutation<CreatePatientMutation, CreatePatientMutationVariables>(CreatePatientDocument, baseOptions);
      }
export type CreatePatientMutationHookResult = ReturnType<typeof useCreatePatientMutation>;
export type CreatePatientMutationResult = ApolloReactCommon.MutationResult<CreatePatientMutation>;
export type CreatePatientMutationOptions = ApolloReactCommon.BaseMutationOptions<CreatePatientMutation, CreatePatientMutationVariables>;
export const UpdatePatientDocument = gql`
    mutation updatePatient($patient_id: String!, $changes: patients_set_input = {updated_at: null}) {
  update_patients_by_pk(pk_columns: {patient_id: $patient_id}, _set: $changes) {
    ...PatientDetails
  }
}
    ${PatientDetailsFragmentDoc}`;
export type UpdatePatientMutationFn = ApolloReactCommon.MutationFunction<UpdatePatientMutation, UpdatePatientMutationVariables>;

/**
 * __useUpdatePatientMutation__
 *
 * To run a mutation, you first call `useUpdatePatientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePatientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePatientMutation, { data, loading, error }] = useUpdatePatientMutation({
 *   variables: {
 *      patient_id: // value for 'patient_id'
 *      changes: // value for 'changes'
 *   },
 * });
 */
export function useUpdatePatientMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdatePatientMutation, UpdatePatientMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdatePatientMutation, UpdatePatientMutationVariables>(UpdatePatientDocument, baseOptions);
      }
export type UpdatePatientMutationHookResult = ReturnType<typeof useUpdatePatientMutation>;
export type UpdatePatientMutationResult = ApolloReactCommon.MutationResult<UpdatePatientMutation>;
export type UpdatePatientMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdatePatientMutation, UpdatePatientMutationVariables>;
export const UpsertPatientsDocument = gql`
    mutation upsertPatients($objects: [patients_insert_input!]!, $cols_to_update: [patients_update_column!]!) {
  insert_patients(
    objects: $objects
    on_conflict: {constraint: patients_practice_id_external_id_key, update_columns: $cols_to_update}
  ) {
    affected_rows
  }
}
    `;
export type UpsertPatientsMutationFn = ApolloReactCommon.MutationFunction<UpsertPatientsMutation, UpsertPatientsMutationVariables>;

/**
 * __useUpsertPatientsMutation__
 *
 * To run a mutation, you first call `useUpsertPatientsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertPatientsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertPatientsMutation, { data, loading, error }] = useUpsertPatientsMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *      cols_to_update: // value for 'cols_to_update'
 *   },
 * });
 */
export function useUpsertPatientsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpsertPatientsMutation, UpsertPatientsMutationVariables>) {
        return ApolloReactHooks.useMutation<UpsertPatientsMutation, UpsertPatientsMutationVariables>(UpsertPatientsDocument, baseOptions);
      }
export type UpsertPatientsMutationHookResult = ReturnType<typeof useUpsertPatientsMutation>;
export type UpsertPatientsMutationResult = ApolloReactCommon.MutationResult<UpsertPatientsMutation>;
export type UpsertPatientsMutationOptions = ApolloReactCommon.BaseMutationOptions<UpsertPatientsMutation, UpsertPatientsMutationVariables>;
export const ArchiveAllPatientsDocument = gql`
    mutation archiveAllPatients($practice_id: String!) {
  update_patients(
    where: {practice_id: {_eq: $practice_id}, is_archived: {_eq: false}}
    _set: {is_archived: true}
  ) {
    affected_rows
  }
}
    `;
export type ArchiveAllPatientsMutationFn = ApolloReactCommon.MutationFunction<ArchiveAllPatientsMutation, ArchiveAllPatientsMutationVariables>;

/**
 * __useArchiveAllPatientsMutation__
 *
 * To run a mutation, you first call `useArchiveAllPatientsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveAllPatientsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveAllPatientsMutation, { data, loading, error }] = useArchiveAllPatientsMutation({
 *   variables: {
 *      practice_id: // value for 'practice_id'
 *   },
 * });
 */
export function useArchiveAllPatientsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ArchiveAllPatientsMutation, ArchiveAllPatientsMutationVariables>) {
        return ApolloReactHooks.useMutation<ArchiveAllPatientsMutation, ArchiveAllPatientsMutationVariables>(ArchiveAllPatientsDocument, baseOptions);
      }
export type ArchiveAllPatientsMutationHookResult = ReturnType<typeof useArchiveAllPatientsMutation>;
export type ArchiveAllPatientsMutationResult = ApolloReactCommon.MutationResult<ArchiveAllPatientsMutation>;
export type ArchiveAllPatientsMutationOptions = ApolloReactCommon.BaseMutationOptions<ArchiveAllPatientsMutation, ArchiveAllPatientsMutationVariables>;
export const InsertAccountCreateRequestDocument = gql`
    mutation insertAccountCreateRequest($account_creation_request_obj: account_creation_requests_insert_input!) {
  insert_account_creation_requests_one(object: $account_creation_request_obj) {
    account_creation_request_id
  }
}
    `;
export type InsertAccountCreateRequestMutationFn = ApolloReactCommon.MutationFunction<InsertAccountCreateRequestMutation, InsertAccountCreateRequestMutationVariables>;

/**
 * __useInsertAccountCreateRequestMutation__
 *
 * To run a mutation, you first call `useInsertAccountCreateRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertAccountCreateRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertAccountCreateRequestMutation, { data, loading, error }] = useInsertAccountCreateRequestMutation({
 *   variables: {
 *      account_creation_request_obj: // value for 'account_creation_request_obj'
 *   },
 * });
 */
export function useInsertAccountCreateRequestMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<InsertAccountCreateRequestMutation, InsertAccountCreateRequestMutationVariables>) {
        return ApolloReactHooks.useMutation<InsertAccountCreateRequestMutation, InsertAccountCreateRequestMutationVariables>(InsertAccountCreateRequestDocument, baseOptions);
      }
export type InsertAccountCreateRequestMutationHookResult = ReturnType<typeof useInsertAccountCreateRequestMutation>;
export type InsertAccountCreateRequestMutationResult = ApolloReactCommon.MutationResult<InsertAccountCreateRequestMutation>;
export type InsertAccountCreateRequestMutationOptions = ApolloReactCommon.BaseMutationOptions<InsertAccountCreateRequestMutation, InsertAccountCreateRequestMutationVariables>;
export const ListAccountCreatedRequestsDocument = gql`
    query listAccountCreatedRequests {
  account_creation_requests(order_by: {updated_at: desc}) {
    ...AccountCreationRequestDetails
  }
}
    ${AccountCreationRequestDetailsFragmentDoc}`;

/**
 * __useListAccountCreatedRequestsQuery__
 *
 * To run a query within a React component, call `useListAccountCreatedRequestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListAccountCreatedRequestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListAccountCreatedRequestsQuery({
 *   variables: {
 *   },
 * });
 */
export function useListAccountCreatedRequestsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ListAccountCreatedRequestsQuery, ListAccountCreatedRequestsQueryVariables>) {
        return ApolloReactHooks.useQuery<ListAccountCreatedRequestsQuery, ListAccountCreatedRequestsQueryVariables>(ListAccountCreatedRequestsDocument, baseOptions);
      }
export function useListAccountCreatedRequestsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ListAccountCreatedRequestsQuery, ListAccountCreatedRequestsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ListAccountCreatedRequestsQuery, ListAccountCreatedRequestsQueryVariables>(ListAccountCreatedRequestsDocument, baseOptions);
        }
export type ListAccountCreatedRequestsQueryHookResult = ReturnType<typeof useListAccountCreatedRequestsQuery>;
export type ListAccountCreatedRequestsLazyQueryHookResult = ReturnType<typeof useListAccountCreatedRequestsLazyQuery>;
export type ListAccountCreatedRequestsQueryResult = ApolloReactCommon.QueryResult<ListAccountCreatedRequestsQuery, ListAccountCreatedRequestsQueryVariables>;
export function refetchListAccountCreatedRequestsQuery(variables?: ListAccountCreatedRequestsQueryVariables) {
      return { query: ListAccountCreatedRequestsDocument, variables: variables }
    }
export const UpdateAccountCreationRequestDocument = gql`
    mutation updateAccountCreationRequest($account_creation_request_id: String!, $changes: account_creation_requests_set_input = {updated_at: null}) {
  update_account_creation_requests_by_pk(
    pk_columns: {account_creation_request_id: $account_creation_request_id}
    _set: $changes
  ) {
    ...AccountCreationRequestBasic
  }
}
    ${AccountCreationRequestBasicFragmentDoc}`;
export type UpdateAccountCreationRequestMutationFn = ApolloReactCommon.MutationFunction<UpdateAccountCreationRequestMutation, UpdateAccountCreationRequestMutationVariables>;

/**
 * __useUpdateAccountCreationRequestMutation__
 *
 * To run a mutation, you first call `useUpdateAccountCreationRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAccountCreationRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAccountCreationRequestMutation, { data, loading, error }] = useUpdateAccountCreationRequestMutation({
 *   variables: {
 *      account_creation_request_id: // value for 'account_creation_request_id'
 *      changes: // value for 'changes'
 *   },
 * });
 */
export function useUpdateAccountCreationRequestMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateAccountCreationRequestMutation, UpdateAccountCreationRequestMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateAccountCreationRequestMutation, UpdateAccountCreationRequestMutationVariables>(UpdateAccountCreationRequestDocument, baseOptions);
      }
export type UpdateAccountCreationRequestMutationHookResult = ReturnType<typeof useUpdateAccountCreationRequestMutation>;
export type UpdateAccountCreationRequestMutationResult = ApolloReactCommon.MutationResult<UpdateAccountCreationRequestMutation>;
export type UpdateAccountCreationRequestMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateAccountCreationRequestMutation, UpdateAccountCreationRequestMutationVariables>;
export const SendBaaDocument = gql`
    mutation sendBAA($recipient_email: String!, $recipient_name: String, $practice_id: String!, $template_vars: jsonb) {
  send_baa(
    recipient_name: $recipient_name
    recipient_email: $recipient_email
    practice_id: $practice_id
    template_vars: $template_vars
  ) {
    baa {
      ...BusinessAssociateAgreementBasic
    }
  }
}
    ${BusinessAssociateAgreementBasicFragmentDoc}`;
export type SendBaaMutationFn = ApolloReactCommon.MutationFunction<SendBaaMutation, SendBaaMutationVariables>;

/**
 * __useSendBaaMutation__
 *
 * To run a mutation, you first call `useSendBaaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendBaaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendBaaMutation, { data, loading, error }] = useSendBaaMutation({
 *   variables: {
 *      recipient_email: // value for 'recipient_email'
 *      recipient_name: // value for 'recipient_name'
 *      practice_id: // value for 'practice_id'
 *      template_vars: // value for 'template_vars'
 *   },
 * });
 */
export function useSendBaaMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SendBaaMutation, SendBaaMutationVariables>) {
        return ApolloReactHooks.useMutation<SendBaaMutation, SendBaaMutationVariables>(SendBaaDocument, baseOptions);
      }
export type SendBaaMutationHookResult = ReturnType<typeof useSendBaaMutation>;
export type SendBaaMutationResult = ApolloReactCommon.MutationResult<SendBaaMutation>;
export type SendBaaMutationOptions = ApolloReactCommon.BaseMutationOptions<SendBaaMutation, SendBaaMutationVariables>;
export const AdminMarkBaaCompletedDocument = gql`
    mutation adminMarkBAACompleted($practice_id: String!, $external_id: String!, $signatory_email: String, $signatory_name: String) {
  insert_business_associate_agreements_one(
    object: {external_id: $external_id, external_source: "MANUAL", status: "Completed", practice_id: $practice_id, recipient_name: $signatory_name, recipient_email: $signatory_email}
    on_conflict: {constraint: business_associate_agreements_pkey, update_columns: [recipient_email, recipient_name]}
  ) {
    ...BusinessAssociateAgreementBasic
  }
}
    ${BusinessAssociateAgreementBasicFragmentDoc}`;
export type AdminMarkBaaCompletedMutationFn = ApolloReactCommon.MutationFunction<AdminMarkBaaCompletedMutation, AdminMarkBaaCompletedMutationVariables>;

/**
 * __useAdminMarkBaaCompletedMutation__
 *
 * To run a mutation, you first call `useAdminMarkBaaCompletedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminMarkBaaCompletedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminMarkBaaCompletedMutation, { data, loading, error }] = useAdminMarkBaaCompletedMutation({
 *   variables: {
 *      practice_id: // value for 'practice_id'
 *      external_id: // value for 'external_id'
 *      signatory_email: // value for 'signatory_email'
 *      signatory_name: // value for 'signatory_name'
 *   },
 * });
 */
export function useAdminMarkBaaCompletedMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AdminMarkBaaCompletedMutation, AdminMarkBaaCompletedMutationVariables>) {
        return ApolloReactHooks.useMutation<AdminMarkBaaCompletedMutation, AdminMarkBaaCompletedMutationVariables>(AdminMarkBaaCompletedDocument, baseOptions);
      }
export type AdminMarkBaaCompletedMutationHookResult = ReturnType<typeof useAdminMarkBaaCompletedMutation>;
export type AdminMarkBaaCompletedMutationResult = ApolloReactCommon.MutationResult<AdminMarkBaaCompletedMutation>;
export type AdminMarkBaaCompletedMutationOptions = ApolloReactCommon.BaseMutationOptions<AdminMarkBaaCompletedMutation, AdminMarkBaaCompletedMutationVariables>;
export const GetCampaignTemplateDocument = gql`
    query getCampaignTemplate($campaign_template_id: String!) {
  campaign_templates_by_pk(campaign_template_id: $campaign_template_id) {
    ...CampaignTemplateDetails
  }
}
    ${CampaignTemplateDetailsFragmentDoc}`;

/**
 * __useGetCampaignTemplateQuery__
 *
 * To run a query within a React component, call `useGetCampaignTemplateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCampaignTemplateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCampaignTemplateQuery({
 *   variables: {
 *      campaign_template_id: // value for 'campaign_template_id'
 *   },
 * });
 */
export function useGetCampaignTemplateQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCampaignTemplateQuery, GetCampaignTemplateQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCampaignTemplateQuery, GetCampaignTemplateQueryVariables>(GetCampaignTemplateDocument, baseOptions);
      }
export function useGetCampaignTemplateLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCampaignTemplateQuery, GetCampaignTemplateQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCampaignTemplateQuery, GetCampaignTemplateQueryVariables>(GetCampaignTemplateDocument, baseOptions);
        }
export type GetCampaignTemplateQueryHookResult = ReturnType<typeof useGetCampaignTemplateQuery>;
export type GetCampaignTemplateLazyQueryHookResult = ReturnType<typeof useGetCampaignTemplateLazyQuery>;
export type GetCampaignTemplateQueryResult = ApolloReactCommon.QueryResult<GetCampaignTemplateQuery, GetCampaignTemplateQueryVariables>;
export function refetchGetCampaignTemplateQuery(variables?: GetCampaignTemplateQueryVariables) {
      return { query: GetCampaignTemplateDocument, variables: variables }
    }
export const ListCampaignTemplatesDocument = gql`
    query listCampaignTemplates($include_approvals: Boolean!) {
  campaign_templates(order_by: {campaign_name: asc}) {
    ...CampaignTemplateDetails
    campaign_approvals @include(if: $include_approvals) {
      ...CampaignApprovalDetails
    }
  }
  networks(where: {campaign_templates: {}}) {
    network_id
    network_name
  }
  practices(where: {campaign_templates: {}}) {
    practice_id
    practice_name
  }
}
    ${CampaignTemplateDetailsFragmentDoc}
${CampaignApprovalDetailsFragmentDoc}`;

/**
 * __useListCampaignTemplatesQuery__
 *
 * To run a query within a React component, call `useListCampaignTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListCampaignTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListCampaignTemplatesQuery({
 *   variables: {
 *      include_approvals: // value for 'include_approvals'
 *   },
 * });
 */
export function useListCampaignTemplatesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ListCampaignTemplatesQuery, ListCampaignTemplatesQueryVariables>) {
        return ApolloReactHooks.useQuery<ListCampaignTemplatesQuery, ListCampaignTemplatesQueryVariables>(ListCampaignTemplatesDocument, baseOptions);
      }
export function useListCampaignTemplatesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ListCampaignTemplatesQuery, ListCampaignTemplatesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ListCampaignTemplatesQuery, ListCampaignTemplatesQueryVariables>(ListCampaignTemplatesDocument, baseOptions);
        }
export type ListCampaignTemplatesQueryHookResult = ReturnType<typeof useListCampaignTemplatesQuery>;
export type ListCampaignTemplatesLazyQueryHookResult = ReturnType<typeof useListCampaignTemplatesLazyQuery>;
export type ListCampaignTemplatesQueryResult = ApolloReactCommon.QueryResult<ListCampaignTemplatesQuery, ListCampaignTemplatesQueryVariables>;
export function refetchListCampaignTemplatesQuery(variables?: ListCampaignTemplatesQueryVariables) {
      return { query: ListCampaignTemplatesDocument, variables: variables }
    }
export const SearchCampaignsAdvancedDocument = gql`
    query searchCampaignsAdvanced($exact_query: String, $substring_query: String, $limit: Int, $offset: Int, $order_by: [campaign_templates_order_by!] = [{updated_at: desc}], $additional_filters: [campaign_templates_bool_exp], $count_aggregate_limit: Int) {
  campaign_templates(
    where: {_or: [{campaign_template_id: {_eq: $exact_query}}, {campaign_name: {_ilike: $substring_query}}, {campaign_description: {_ilike: $substring_query}}, {network: {network_name: {_ilike: $substring_query}}}, {practice: {practice_name: {_ilike: $substring_query}}}], _and: $additional_filters}
    limit: $limit
    offset: $offset
    order_by: $order_by
  ) {
    ...CampaignTemplateBasic
    campaign_letter_components(order_by: {send_datetime: asc}) {
      ...CampaignLetterComponentBasic
      patient_segment {
        ...PatientSegmentBasic
      }
      letter_template {
        letter_template_id
        letter_template_name
      }
      tracking_ids {
        component_id
        tracking_id
      }
    }
    campaign_email_components(order_by: {send_datetime: asc}) {
      ...CampaignEmailComponentBasic
      patient_segment {
        ...PatientSegmentBasic
      }
      email_template {
        email_template_id
        email_template_name
      }
      tracking_ids {
        component_id
        tracking_id
      }
    }
    campaign_sms_components(order_by: {send_datetime: asc}) {
      ...CampaignSMSComponentBasic
      patient_segment {
        ...PatientSegmentBasic
      }
      sms_template {
        sms_template_id
        sms_template_name
      }
      tracking_ids {
        component_id
        tracking_id
      }
    }
    campaign_approvals {
      ...CampaignApprovalBasic
    }
  }
  campaign_templates_aggregate(
    where: {_or: [{campaign_template_id: {_eq: $exact_query}}, {campaign_name: {_ilike: $substring_query}}, {campaign_description: {_ilike: $substring_query}}, {network: {network_name: {_ilike: $substring_query}}}, {practice: {practice_name: {_ilike: $substring_query}}}], _and: $additional_filters}
    limit: $count_aggregate_limit
  ) {
    aggregate {
      count
    }
  }
  networks(where: {campaign_templates: {}}, order_by: [{network_name: asc}]) {
    network_id
    network_name
  }
  practices(where: {campaign_templates: {}}, order_by: [{practice_name: asc}]) {
    practice_id
    practice_name
  }
}
    ${CampaignTemplateBasicFragmentDoc}
${CampaignLetterComponentBasicFragmentDoc}
${PatientSegmentBasicFragmentDoc}
${CampaignEmailComponentBasicFragmentDoc}
${CampaignSmsComponentBasicFragmentDoc}
${CampaignApprovalBasicFragmentDoc}`;

/**
 * __useSearchCampaignsAdvancedQuery__
 *
 * To run a query within a React component, call `useSearchCampaignsAdvancedQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchCampaignsAdvancedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchCampaignsAdvancedQuery({
 *   variables: {
 *      exact_query: // value for 'exact_query'
 *      substring_query: // value for 'substring_query'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *      additional_filters: // value for 'additional_filters'
 *      count_aggregate_limit: // value for 'count_aggregate_limit'
 *   },
 * });
 */
export function useSearchCampaignsAdvancedQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SearchCampaignsAdvancedQuery, SearchCampaignsAdvancedQueryVariables>) {
        return ApolloReactHooks.useQuery<SearchCampaignsAdvancedQuery, SearchCampaignsAdvancedQueryVariables>(SearchCampaignsAdvancedDocument, baseOptions);
      }
export function useSearchCampaignsAdvancedLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SearchCampaignsAdvancedQuery, SearchCampaignsAdvancedQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SearchCampaignsAdvancedQuery, SearchCampaignsAdvancedQueryVariables>(SearchCampaignsAdvancedDocument, baseOptions);
        }
export type SearchCampaignsAdvancedQueryHookResult = ReturnType<typeof useSearchCampaignsAdvancedQuery>;
export type SearchCampaignsAdvancedLazyQueryHookResult = ReturnType<typeof useSearchCampaignsAdvancedLazyQuery>;
export type SearchCampaignsAdvancedQueryResult = ApolloReactCommon.QueryResult<SearchCampaignsAdvancedQuery, SearchCampaignsAdvancedQueryVariables>;
export function refetchSearchCampaignsAdvancedQuery(variables?: SearchCampaignsAdvancedQueryVariables) {
      return { query: SearchCampaignsAdvancedDocument, variables: variables }
    }
export const GetAllCampaignTemplatePreviewsDocument = gql`
    query getAllCampaignTemplatePreviews($campaign_template_id: String!) {
  campaign_templates_by_pk(campaign_template_id: $campaign_template_id) {
    ...CampaignTemplateDetails
    network {
      practices(where: {is_archived: {_eq: false}}) {
        ...PracticeBasic
      }
    }
    practice {
      ...PracticeBasic
    }
  }
}
    ${CampaignTemplateDetailsFragmentDoc}
${PracticeBasicFragmentDoc}`;

/**
 * __useGetAllCampaignTemplatePreviewsQuery__
 *
 * To run a query within a React component, call `useGetAllCampaignTemplatePreviewsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllCampaignTemplatePreviewsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllCampaignTemplatePreviewsQuery({
 *   variables: {
 *      campaign_template_id: // value for 'campaign_template_id'
 *   },
 * });
 */
export function useGetAllCampaignTemplatePreviewsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAllCampaignTemplatePreviewsQuery, GetAllCampaignTemplatePreviewsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAllCampaignTemplatePreviewsQuery, GetAllCampaignTemplatePreviewsQueryVariables>(GetAllCampaignTemplatePreviewsDocument, baseOptions);
      }
export function useGetAllCampaignTemplatePreviewsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAllCampaignTemplatePreviewsQuery, GetAllCampaignTemplatePreviewsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAllCampaignTemplatePreviewsQuery, GetAllCampaignTemplatePreviewsQueryVariables>(GetAllCampaignTemplatePreviewsDocument, baseOptions);
        }
export type GetAllCampaignTemplatePreviewsQueryHookResult = ReturnType<typeof useGetAllCampaignTemplatePreviewsQuery>;
export type GetAllCampaignTemplatePreviewsLazyQueryHookResult = ReturnType<typeof useGetAllCampaignTemplatePreviewsLazyQuery>;
export type GetAllCampaignTemplatePreviewsQueryResult = ApolloReactCommon.QueryResult<GetAllCampaignTemplatePreviewsQuery, GetAllCampaignTemplatePreviewsQueryVariables>;
export function refetchGetAllCampaignTemplatePreviewsQuery(variables?: GetAllCampaignTemplatePreviewsQueryVariables) {
      return { query: GetAllCampaignTemplatePreviewsDocument, variables: variables }
    }
export const ListCampaignTemplatesForPracticeDocument = gql`
    query listCampaignTemplatesForPractice($practice_id: String!) {
  campaign_templates(
    where: {_or: [{practice_id: {_eq: $practice_id}}, {network: {practices: {practice_id: {_eq: $practice_id}}}}]}
    order_by: {created_at: asc}
  ) {
    ...CampaignTemplateDetails
    campaign_approvals(where: {practice_id: {_eq: $practice_id}}) {
      ...CampaignApprovalDetails
    }
  }
}
    ${CampaignTemplateDetailsFragmentDoc}
${CampaignApprovalDetailsFragmentDoc}`;

/**
 * __useListCampaignTemplatesForPracticeQuery__
 *
 * To run a query within a React component, call `useListCampaignTemplatesForPracticeQuery` and pass it any options that fit your needs.
 * When your component renders, `useListCampaignTemplatesForPracticeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListCampaignTemplatesForPracticeQuery({
 *   variables: {
 *      practice_id: // value for 'practice_id'
 *   },
 * });
 */
export function useListCampaignTemplatesForPracticeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ListCampaignTemplatesForPracticeQuery, ListCampaignTemplatesForPracticeQueryVariables>) {
        return ApolloReactHooks.useQuery<ListCampaignTemplatesForPracticeQuery, ListCampaignTemplatesForPracticeQueryVariables>(ListCampaignTemplatesForPracticeDocument, baseOptions);
      }
export function useListCampaignTemplatesForPracticeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ListCampaignTemplatesForPracticeQuery, ListCampaignTemplatesForPracticeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ListCampaignTemplatesForPracticeQuery, ListCampaignTemplatesForPracticeQueryVariables>(ListCampaignTemplatesForPracticeDocument, baseOptions);
        }
export type ListCampaignTemplatesForPracticeQueryHookResult = ReturnType<typeof useListCampaignTemplatesForPracticeQuery>;
export type ListCampaignTemplatesForPracticeLazyQueryHookResult = ReturnType<typeof useListCampaignTemplatesForPracticeLazyQuery>;
export type ListCampaignTemplatesForPracticeQueryResult = ApolloReactCommon.QueryResult<ListCampaignTemplatesForPracticeQuery, ListCampaignTemplatesForPracticeQueryVariables>;
export function refetchListCampaignTemplatesForPracticeQuery(variables?: ListCampaignTemplatesForPracticeQueryVariables) {
      return { query: ListCampaignTemplatesForPracticeDocument, variables: variables }
    }
export const CreateCampaignTemplateDocument = gql`
    mutation createCampaignTemplate($campaign_template: campaign_templates_insert_input!) {
  insert_campaign_templates_one(object: $campaign_template) {
    ...CampaignTemplateDetails
  }
}
    ${CampaignTemplateDetailsFragmentDoc}`;
export type CreateCampaignTemplateMutationFn = ApolloReactCommon.MutationFunction<CreateCampaignTemplateMutation, CreateCampaignTemplateMutationVariables>;

/**
 * __useCreateCampaignTemplateMutation__
 *
 * To run a mutation, you first call `useCreateCampaignTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCampaignTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCampaignTemplateMutation, { data, loading, error }] = useCreateCampaignTemplateMutation({
 *   variables: {
 *      campaign_template: // value for 'campaign_template'
 *   },
 * });
 */
export function useCreateCampaignTemplateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateCampaignTemplateMutation, CreateCampaignTemplateMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateCampaignTemplateMutation, CreateCampaignTemplateMutationVariables>(CreateCampaignTemplateDocument, baseOptions);
      }
export type CreateCampaignTemplateMutationHookResult = ReturnType<typeof useCreateCampaignTemplateMutation>;
export type CreateCampaignTemplateMutationResult = ApolloReactCommon.MutationResult<CreateCampaignTemplateMutation>;
export type CreateCampaignTemplateMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateCampaignTemplateMutation, CreateCampaignTemplateMutationVariables>;
export const UpdateCampaignTemplateDocument = gql`
    mutation updateCampaignTemplate($campaign_template_id: String!, $changes: campaign_templates_set_input = {updated_at: null}) {
  update_campaign_templates_by_pk(
    pk_columns: {campaign_template_id: $campaign_template_id}
    _set: $changes
  ) {
    ...CampaignTemplateDetails
  }
}
    ${CampaignTemplateDetailsFragmentDoc}`;
export type UpdateCampaignTemplateMutationFn = ApolloReactCommon.MutationFunction<UpdateCampaignTemplateMutation, UpdateCampaignTemplateMutationVariables>;

/**
 * __useUpdateCampaignTemplateMutation__
 *
 * To run a mutation, you first call `useUpdateCampaignTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCampaignTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCampaignTemplateMutation, { data, loading, error }] = useUpdateCampaignTemplateMutation({
 *   variables: {
 *      campaign_template_id: // value for 'campaign_template_id'
 *      changes: // value for 'changes'
 *   },
 * });
 */
export function useUpdateCampaignTemplateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateCampaignTemplateMutation, UpdateCampaignTemplateMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateCampaignTemplateMutation, UpdateCampaignTemplateMutationVariables>(UpdateCampaignTemplateDocument, baseOptions);
      }
export type UpdateCampaignTemplateMutationHookResult = ReturnType<typeof useUpdateCampaignTemplateMutation>;
export type UpdateCampaignTemplateMutationResult = ApolloReactCommon.MutationResult<UpdateCampaignTemplateMutation>;
export type UpdateCampaignTemplateMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateCampaignTemplateMutation, UpdateCampaignTemplateMutationVariables>;
export const DeleteCampaignTemplateDocument = gql`
    mutation deleteCampaignTemplate($campaign_template_id: String!) {
  delete_campaign_email_components(
    where: {campaign_template_id: {_eq: $campaign_template_id}}
  ) {
    returning {
      ...CampaignEmailComponentDetails
    }
  }
  delete_campaign_letter_components(
    where: {campaign_template_id: {_eq: $campaign_template_id}}
  ) {
    returning {
      ...CampaignLetterComponentDetails
    }
  }
  delete_campaign_templates_by_pk(campaign_template_id: $campaign_template_id) {
    ...CampaignTemplateDetails
  }
}
    ${CampaignEmailComponentDetailsFragmentDoc}
${CampaignLetterComponentDetailsFragmentDoc}
${CampaignTemplateDetailsFragmentDoc}`;
export type DeleteCampaignTemplateMutationFn = ApolloReactCommon.MutationFunction<DeleteCampaignTemplateMutation, DeleteCampaignTemplateMutationVariables>;

/**
 * __useDeleteCampaignTemplateMutation__
 *
 * To run a mutation, you first call `useDeleteCampaignTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCampaignTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCampaignTemplateMutation, { data, loading, error }] = useDeleteCampaignTemplateMutation({
 *   variables: {
 *      campaign_template_id: // value for 'campaign_template_id'
 *   },
 * });
 */
export function useDeleteCampaignTemplateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteCampaignTemplateMutation, DeleteCampaignTemplateMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteCampaignTemplateMutation, DeleteCampaignTemplateMutationVariables>(DeleteCampaignTemplateDocument, baseOptions);
      }
export type DeleteCampaignTemplateMutationHookResult = ReturnType<typeof useDeleteCampaignTemplateMutation>;
export type DeleteCampaignTemplateMutationResult = ApolloReactCommon.MutationResult<DeleteCampaignTemplateMutation>;
export type DeleteCampaignTemplateMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteCampaignTemplateMutation, DeleteCampaignTemplateMutationVariables>;
export const CreateCampaignLetterComponentDocument = gql`
    mutation createCampaignLetterComponent($campaign_letter_component: campaign_letter_components_insert_input!) {
  insert_campaign_letter_components_one(object: $campaign_letter_component) {
    ...CampaignLetterComponentDetails
  }
}
    ${CampaignLetterComponentDetailsFragmentDoc}`;
export type CreateCampaignLetterComponentMutationFn = ApolloReactCommon.MutationFunction<CreateCampaignLetterComponentMutation, CreateCampaignLetterComponentMutationVariables>;

/**
 * __useCreateCampaignLetterComponentMutation__
 *
 * To run a mutation, you first call `useCreateCampaignLetterComponentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCampaignLetterComponentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCampaignLetterComponentMutation, { data, loading, error }] = useCreateCampaignLetterComponentMutation({
 *   variables: {
 *      campaign_letter_component: // value for 'campaign_letter_component'
 *   },
 * });
 */
export function useCreateCampaignLetterComponentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateCampaignLetterComponentMutation, CreateCampaignLetterComponentMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateCampaignLetterComponentMutation, CreateCampaignLetterComponentMutationVariables>(CreateCampaignLetterComponentDocument, baseOptions);
      }
export type CreateCampaignLetterComponentMutationHookResult = ReturnType<typeof useCreateCampaignLetterComponentMutation>;
export type CreateCampaignLetterComponentMutationResult = ApolloReactCommon.MutationResult<CreateCampaignLetterComponentMutation>;
export type CreateCampaignLetterComponentMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateCampaignLetterComponentMutation, CreateCampaignLetterComponentMutationVariables>;
export const UpdateCampaignLetterComponentDocument = gql`
    mutation updateCampaignLetterComponent($campaign_letter_component_id: String!, $changes: campaign_letter_components_set_input = {updated_at: null}) {
  update_campaign_letter_components_by_pk(
    pk_columns: {campaign_letter_component_id: $campaign_letter_component_id}
    _set: $changes
  ) {
    ...CampaignLetterComponentDetails
  }
}
    ${CampaignLetterComponentDetailsFragmentDoc}`;
export type UpdateCampaignLetterComponentMutationFn = ApolloReactCommon.MutationFunction<UpdateCampaignLetterComponentMutation, UpdateCampaignLetterComponentMutationVariables>;

/**
 * __useUpdateCampaignLetterComponentMutation__
 *
 * To run a mutation, you first call `useUpdateCampaignLetterComponentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCampaignLetterComponentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCampaignLetterComponentMutation, { data, loading, error }] = useUpdateCampaignLetterComponentMutation({
 *   variables: {
 *      campaign_letter_component_id: // value for 'campaign_letter_component_id'
 *      changes: // value for 'changes'
 *   },
 * });
 */
export function useUpdateCampaignLetterComponentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateCampaignLetterComponentMutation, UpdateCampaignLetterComponentMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateCampaignLetterComponentMutation, UpdateCampaignLetterComponentMutationVariables>(UpdateCampaignLetterComponentDocument, baseOptions);
      }
export type UpdateCampaignLetterComponentMutationHookResult = ReturnType<typeof useUpdateCampaignLetterComponentMutation>;
export type UpdateCampaignLetterComponentMutationResult = ApolloReactCommon.MutationResult<UpdateCampaignLetterComponentMutation>;
export type UpdateCampaignLetterComponentMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateCampaignLetterComponentMutation, UpdateCampaignLetterComponentMutationVariables>;
export const CreateCampaignEmailComponentDocument = gql`
    mutation createCampaignEmailComponent($campaign_email_component: campaign_email_components_insert_input!) {
  insert_campaign_email_components_one(object: $campaign_email_component) {
    ...CampaignEmailComponentDetails
  }
}
    ${CampaignEmailComponentDetailsFragmentDoc}`;
export type CreateCampaignEmailComponentMutationFn = ApolloReactCommon.MutationFunction<CreateCampaignEmailComponentMutation, CreateCampaignEmailComponentMutationVariables>;

/**
 * __useCreateCampaignEmailComponentMutation__
 *
 * To run a mutation, you first call `useCreateCampaignEmailComponentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCampaignEmailComponentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCampaignEmailComponentMutation, { data, loading, error }] = useCreateCampaignEmailComponentMutation({
 *   variables: {
 *      campaign_email_component: // value for 'campaign_email_component'
 *   },
 * });
 */
export function useCreateCampaignEmailComponentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateCampaignEmailComponentMutation, CreateCampaignEmailComponentMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateCampaignEmailComponentMutation, CreateCampaignEmailComponentMutationVariables>(CreateCampaignEmailComponentDocument, baseOptions);
      }
export type CreateCampaignEmailComponentMutationHookResult = ReturnType<typeof useCreateCampaignEmailComponentMutation>;
export type CreateCampaignEmailComponentMutationResult = ApolloReactCommon.MutationResult<CreateCampaignEmailComponentMutation>;
export type CreateCampaignEmailComponentMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateCampaignEmailComponentMutation, CreateCampaignEmailComponentMutationVariables>;
export const UpdateCampaignEmailComponentDocument = gql`
    mutation updateCampaignEmailComponent($campaign_email_component_id: String!, $changes: campaign_email_components_set_input = {updated_at: null}) {
  update_campaign_email_components_by_pk(
    pk_columns: {campaign_email_component_id: $campaign_email_component_id}
    _set: $changes
  ) {
    ...CampaignEmailComponentDetails
  }
}
    ${CampaignEmailComponentDetailsFragmentDoc}`;
export type UpdateCampaignEmailComponentMutationFn = ApolloReactCommon.MutationFunction<UpdateCampaignEmailComponentMutation, UpdateCampaignEmailComponentMutationVariables>;

/**
 * __useUpdateCampaignEmailComponentMutation__
 *
 * To run a mutation, you first call `useUpdateCampaignEmailComponentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCampaignEmailComponentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCampaignEmailComponentMutation, { data, loading, error }] = useUpdateCampaignEmailComponentMutation({
 *   variables: {
 *      campaign_email_component_id: // value for 'campaign_email_component_id'
 *      changes: // value for 'changes'
 *   },
 * });
 */
export function useUpdateCampaignEmailComponentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateCampaignEmailComponentMutation, UpdateCampaignEmailComponentMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateCampaignEmailComponentMutation, UpdateCampaignEmailComponentMutationVariables>(UpdateCampaignEmailComponentDocument, baseOptions);
      }
export type UpdateCampaignEmailComponentMutationHookResult = ReturnType<typeof useUpdateCampaignEmailComponentMutation>;
export type UpdateCampaignEmailComponentMutationResult = ApolloReactCommon.MutationResult<UpdateCampaignEmailComponentMutation>;
export type UpdateCampaignEmailComponentMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateCampaignEmailComponentMutation, UpdateCampaignEmailComponentMutationVariables>;
export const CreateCampaignSmsComponentDocument = gql`
    mutation createCampaignSMSComponent($campaign_sms_component: campaign_sms_components_insert_input!) {
  insert_campaign_sms_components_one(object: $campaign_sms_component) {
    ...CampaignSMSComponentDetails
  }
}
    ${CampaignSmsComponentDetailsFragmentDoc}`;
export type CreateCampaignSmsComponentMutationFn = ApolloReactCommon.MutationFunction<CreateCampaignSmsComponentMutation, CreateCampaignSmsComponentMutationVariables>;

/**
 * __useCreateCampaignSmsComponentMutation__
 *
 * To run a mutation, you first call `useCreateCampaignSmsComponentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCampaignSmsComponentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCampaignSmsComponentMutation, { data, loading, error }] = useCreateCampaignSmsComponentMutation({
 *   variables: {
 *      campaign_sms_component: // value for 'campaign_sms_component'
 *   },
 * });
 */
export function useCreateCampaignSmsComponentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateCampaignSmsComponentMutation, CreateCampaignSmsComponentMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateCampaignSmsComponentMutation, CreateCampaignSmsComponentMutationVariables>(CreateCampaignSmsComponentDocument, baseOptions);
      }
export type CreateCampaignSmsComponentMutationHookResult = ReturnType<typeof useCreateCampaignSmsComponentMutation>;
export type CreateCampaignSmsComponentMutationResult = ApolloReactCommon.MutationResult<CreateCampaignSmsComponentMutation>;
export type CreateCampaignSmsComponentMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateCampaignSmsComponentMutation, CreateCampaignSmsComponentMutationVariables>;
export const UpdateCampaignSmsComponentDocument = gql`
    mutation updateCampaignSMSComponent($campaign_sms_component_id: String!, $changes: campaign_sms_components_set_input = {updated_at: null}) {
  update_campaign_sms_components_by_pk(
    pk_columns: {campaign_sms_component_id: $campaign_sms_component_id}
    _set: $changes
  ) {
    ...CampaignSMSComponentDetails
  }
}
    ${CampaignSmsComponentDetailsFragmentDoc}`;
export type UpdateCampaignSmsComponentMutationFn = ApolloReactCommon.MutationFunction<UpdateCampaignSmsComponentMutation, UpdateCampaignSmsComponentMutationVariables>;

/**
 * __useUpdateCampaignSmsComponentMutation__
 *
 * To run a mutation, you first call `useUpdateCampaignSmsComponentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCampaignSmsComponentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCampaignSmsComponentMutation, { data, loading, error }] = useUpdateCampaignSmsComponentMutation({
 *   variables: {
 *      campaign_sms_component_id: // value for 'campaign_sms_component_id'
 *      changes: // value for 'changes'
 *   },
 * });
 */
export function useUpdateCampaignSmsComponentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateCampaignSmsComponentMutation, UpdateCampaignSmsComponentMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateCampaignSmsComponentMutation, UpdateCampaignSmsComponentMutationVariables>(UpdateCampaignSmsComponentDocument, baseOptions);
      }
export type UpdateCampaignSmsComponentMutationHookResult = ReturnType<typeof useUpdateCampaignSmsComponentMutation>;
export type UpdateCampaignSmsComponentMutationResult = ApolloReactCommon.MutationResult<UpdateCampaignSmsComponentMutation>;
export type UpdateCampaignSmsComponentMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateCampaignSmsComponentMutation, UpdateCampaignSmsComponentMutationVariables>;
export const CreateComponentTrackingIdDocument = gql`
    mutation createComponentTrackingID($component_id: String!, $tracking_id: String!) {
  insert_component_tracking_ids_one(
    object: {component_id: $component_id, tracking_id: $tracking_id}
  ) {
    campaign_email_component {
      ...CampaignEmailComponentDetails
    }
    campaign_letter_component {
      ...CampaignLetterComponentDetails
    }
    campaign_sms_component {
      ...CampaignSMSComponentDetails
    }
  }
}
    ${CampaignEmailComponentDetailsFragmentDoc}
${CampaignLetterComponentDetailsFragmentDoc}
${CampaignSmsComponentDetailsFragmentDoc}`;
export type CreateComponentTrackingIdMutationFn = ApolloReactCommon.MutationFunction<CreateComponentTrackingIdMutation, CreateComponentTrackingIdMutationVariables>;

/**
 * __useCreateComponentTrackingIdMutation__
 *
 * To run a mutation, you first call `useCreateComponentTrackingIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateComponentTrackingIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createComponentTrackingIdMutation, { data, loading, error }] = useCreateComponentTrackingIdMutation({
 *   variables: {
 *      component_id: // value for 'component_id'
 *      tracking_id: // value for 'tracking_id'
 *   },
 * });
 */
export function useCreateComponentTrackingIdMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateComponentTrackingIdMutation, CreateComponentTrackingIdMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateComponentTrackingIdMutation, CreateComponentTrackingIdMutationVariables>(CreateComponentTrackingIdDocument, baseOptions);
      }
export type CreateComponentTrackingIdMutationHookResult = ReturnType<typeof useCreateComponentTrackingIdMutation>;
export type CreateComponentTrackingIdMutationResult = ApolloReactCommon.MutationResult<CreateComponentTrackingIdMutation>;
export type CreateComponentTrackingIdMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateComponentTrackingIdMutation, CreateComponentTrackingIdMutationVariables>;
export const DeleteComponentTrackingIdDocument = gql`
    mutation deleteComponentTrackingID($component_id: String!, $tracking_id: String!) {
  delete_component_tracking_ids_by_pk(
    component_id: $component_id
    tracking_id: $tracking_id
  ) {
    campaign_email_component {
      ...CampaignEmailComponentDetails
    }
    campaign_letter_component {
      ...CampaignLetterComponentDetails
    }
    campaign_sms_component {
      ...CampaignSMSComponentDetails
    }
  }
}
    ${CampaignEmailComponentDetailsFragmentDoc}
${CampaignLetterComponentDetailsFragmentDoc}
${CampaignSmsComponentDetailsFragmentDoc}`;
export type DeleteComponentTrackingIdMutationFn = ApolloReactCommon.MutationFunction<DeleteComponentTrackingIdMutation, DeleteComponentTrackingIdMutationVariables>;

/**
 * __useDeleteComponentTrackingIdMutation__
 *
 * To run a mutation, you first call `useDeleteComponentTrackingIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteComponentTrackingIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteComponentTrackingIdMutation, { data, loading, error }] = useDeleteComponentTrackingIdMutation({
 *   variables: {
 *      component_id: // value for 'component_id'
 *      tracking_id: // value for 'tracking_id'
 *   },
 * });
 */
export function useDeleteComponentTrackingIdMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteComponentTrackingIdMutation, DeleteComponentTrackingIdMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteComponentTrackingIdMutation, DeleteComponentTrackingIdMutationVariables>(DeleteComponentTrackingIdDocument, baseOptions);
      }
export type DeleteComponentTrackingIdMutationHookResult = ReturnType<typeof useDeleteComponentTrackingIdMutation>;
export type DeleteComponentTrackingIdMutationResult = ApolloReactCommon.MutationResult<DeleteComponentTrackingIdMutation>;
export type DeleteComponentTrackingIdMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteComponentTrackingIdMutation, DeleteComponentTrackingIdMutationVariables>;
export const ApproveCampaignDocument = gql`
    mutation approveCampaign($practice_id: String!, $campaign_template_id: String!, $letter_component_approvals: [letter_component_approvals_insert_input!]!, $email_component_approvals: [email_component_approvals_insert_input!]!, $sms_component_approvals: [sms_component_approvals_insert_input!]!) {
  insert_campaign_approvals_one(
    object: {practice_id: $practice_id, campaign_template_id: $campaign_template_id, letter_component_approvals: {data: $letter_component_approvals}, email_component_approvals: {data: $email_component_approvals}, sms_component_approvals: {data: $sms_component_approvals}}
  ) {
    campaign_template {
      ...CampaignTemplateDetails
      campaign_approvals {
        ...CampaignApprovalDetails
      }
    }
  }
}
    ${CampaignTemplateDetailsFragmentDoc}
${CampaignApprovalDetailsFragmentDoc}`;
export type ApproveCampaignMutationFn = ApolloReactCommon.MutationFunction<ApproveCampaignMutation, ApproveCampaignMutationVariables>;

/**
 * __useApproveCampaignMutation__
 *
 * To run a mutation, you first call `useApproveCampaignMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApproveCampaignMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approveCampaignMutation, { data, loading, error }] = useApproveCampaignMutation({
 *   variables: {
 *      practice_id: // value for 'practice_id'
 *      campaign_template_id: // value for 'campaign_template_id'
 *      letter_component_approvals: // value for 'letter_component_approvals'
 *      email_component_approvals: // value for 'email_component_approvals'
 *      sms_component_approvals: // value for 'sms_component_approvals'
 *   },
 * });
 */
export function useApproveCampaignMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ApproveCampaignMutation, ApproveCampaignMutationVariables>) {
        return ApolloReactHooks.useMutation<ApproveCampaignMutation, ApproveCampaignMutationVariables>(ApproveCampaignDocument, baseOptions);
      }
export type ApproveCampaignMutationHookResult = ReturnType<typeof useApproveCampaignMutation>;
export type ApproveCampaignMutationResult = ApolloReactCommon.MutationResult<ApproveCampaignMutation>;
export type ApproveCampaignMutationOptions = ApolloReactCommon.BaseMutationOptions<ApproveCampaignMutation, ApproveCampaignMutationVariables>;
export const ListSmsTemplatesDocument = gql`
    query listSMSTemplates {
  sms_templates(
    where: {is_archived: {_eq: false}}
    order_by: [{sms_template_name: asc}]
  ) {
    ...SMSTemplateBasic
  }
}
    ${SmsTemplateBasicFragmentDoc}`;

/**
 * __useListSmsTemplatesQuery__
 *
 * To run a query within a React component, call `useListSmsTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListSmsTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListSmsTemplatesQuery({
 *   variables: {
 *   },
 * });
 */
export function useListSmsTemplatesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ListSmsTemplatesQuery, ListSmsTemplatesQueryVariables>) {
        return ApolloReactHooks.useQuery<ListSmsTemplatesQuery, ListSmsTemplatesQueryVariables>(ListSmsTemplatesDocument, baseOptions);
      }
export function useListSmsTemplatesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ListSmsTemplatesQuery, ListSmsTemplatesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ListSmsTemplatesQuery, ListSmsTemplatesQueryVariables>(ListSmsTemplatesDocument, baseOptions);
        }
export type ListSmsTemplatesQueryHookResult = ReturnType<typeof useListSmsTemplatesQuery>;
export type ListSmsTemplatesLazyQueryHookResult = ReturnType<typeof useListSmsTemplatesLazyQuery>;
export type ListSmsTemplatesQueryResult = ApolloReactCommon.QueryResult<ListSmsTemplatesQuery, ListSmsTemplatesQueryVariables>;
export function refetchListSmsTemplatesQuery(variables?: ListSmsTemplatesQueryVariables) {
      return { query: ListSmsTemplatesDocument, variables: variables }
    }
export const SearchSmsTemplatesDocument = gql`
    query searchSMSTemplates($exact_query: String, $substring_query: String, $limit: Int, $offset: Int, $order_by: [sms_templates_order_by!] = [{updated_at: desc}], $additional_filters: [sms_templates_bool_exp], $count_aggregate_limit: Int) {
  sms_templates(
    where: {_or: [{sms_template_id: {_eq: $exact_query}}, {sms_template_name: {_ilike: $substring_query}}], _and: $additional_filters}
    limit: $limit
    offset: $offset
    order_by: $order_by
  ) {
    ...SMSTemplateBasic
  }
  sms_templates_aggregate(
    where: {_or: [{sms_template_id: {_eq: $exact_query}}, {sms_template_name: {_ilike: $substring_query}}], _and: $additional_filters}
    limit: $count_aggregate_limit
  ) {
    aggregate {
      count
    }
  }
}
    ${SmsTemplateBasicFragmentDoc}`;

/**
 * __useSearchSmsTemplatesQuery__
 *
 * To run a query within a React component, call `useSearchSmsTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchSmsTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchSmsTemplatesQuery({
 *   variables: {
 *      exact_query: // value for 'exact_query'
 *      substring_query: // value for 'substring_query'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *      additional_filters: // value for 'additional_filters'
 *      count_aggregate_limit: // value for 'count_aggregate_limit'
 *   },
 * });
 */
export function useSearchSmsTemplatesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SearchSmsTemplatesQuery, SearchSmsTemplatesQueryVariables>) {
        return ApolloReactHooks.useQuery<SearchSmsTemplatesQuery, SearchSmsTemplatesQueryVariables>(SearchSmsTemplatesDocument, baseOptions);
      }
export function useSearchSmsTemplatesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SearchSmsTemplatesQuery, SearchSmsTemplatesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SearchSmsTemplatesQuery, SearchSmsTemplatesQueryVariables>(SearchSmsTemplatesDocument, baseOptions);
        }
export type SearchSmsTemplatesQueryHookResult = ReturnType<typeof useSearchSmsTemplatesQuery>;
export type SearchSmsTemplatesLazyQueryHookResult = ReturnType<typeof useSearchSmsTemplatesLazyQuery>;
export type SearchSmsTemplatesQueryResult = ApolloReactCommon.QueryResult<SearchSmsTemplatesQuery, SearchSmsTemplatesQueryVariables>;
export function refetchSearchSmsTemplatesQuery(variables?: SearchSmsTemplatesQueryVariables) {
      return { query: SearchSmsTemplatesDocument, variables: variables }
    }
export const ListEmailTemplatesDocument = gql`
    query listEmailTemplates {
  email_templates(
    where: {is_archived: {_eq: false}}
    order_by: [{email_template_name: asc}]
  ) {
    ...EmailTemplateBasic
  }
}
    ${EmailTemplateBasicFragmentDoc}`;

/**
 * __useListEmailTemplatesQuery__
 *
 * To run a query within a React component, call `useListEmailTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListEmailTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListEmailTemplatesQuery({
 *   variables: {
 *   },
 * });
 */
export function useListEmailTemplatesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ListEmailTemplatesQuery, ListEmailTemplatesQueryVariables>) {
        return ApolloReactHooks.useQuery<ListEmailTemplatesQuery, ListEmailTemplatesQueryVariables>(ListEmailTemplatesDocument, baseOptions);
      }
export function useListEmailTemplatesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ListEmailTemplatesQuery, ListEmailTemplatesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ListEmailTemplatesQuery, ListEmailTemplatesQueryVariables>(ListEmailTemplatesDocument, baseOptions);
        }
export type ListEmailTemplatesQueryHookResult = ReturnType<typeof useListEmailTemplatesQuery>;
export type ListEmailTemplatesLazyQueryHookResult = ReturnType<typeof useListEmailTemplatesLazyQuery>;
export type ListEmailTemplatesQueryResult = ApolloReactCommon.QueryResult<ListEmailTemplatesQuery, ListEmailTemplatesQueryVariables>;
export function refetchListEmailTemplatesQuery(variables?: ListEmailTemplatesQueryVariables) {
      return { query: ListEmailTemplatesDocument, variables: variables }
    }
export const SearchEmailTemplatesDocument = gql`
    query searchEmailTemplates($exact_query: String, $substring_query: String, $limit: Int, $offset: Int, $order_by: [email_templates_order_by!] = [{updated_at: desc}], $additional_filters: [email_templates_bool_exp], $count_aggregate_limit: Int) {
  email_templates(
    where: {_or: [{email_template_id: {_eq: $exact_query}}, {email_template_name: {_ilike: $substring_query}}], _and: $additional_filters}
    limit: $limit
    offset: $offset
    order_by: $order_by
  ) {
    ...EmailTemplateBasic
  }
  email_templates_aggregate(
    where: {_or: [{email_template_id: {_eq: $exact_query}}, {email_template_name: {_ilike: $substring_query}}], _and: $additional_filters}
    limit: $count_aggregate_limit
  ) {
    aggregate {
      count
    }
  }
}
    ${EmailTemplateBasicFragmentDoc}`;

/**
 * __useSearchEmailTemplatesQuery__
 *
 * To run a query within a React component, call `useSearchEmailTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchEmailTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchEmailTemplatesQuery({
 *   variables: {
 *      exact_query: // value for 'exact_query'
 *      substring_query: // value for 'substring_query'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *      additional_filters: // value for 'additional_filters'
 *      count_aggregate_limit: // value for 'count_aggregate_limit'
 *   },
 * });
 */
export function useSearchEmailTemplatesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SearchEmailTemplatesQuery, SearchEmailTemplatesQueryVariables>) {
        return ApolloReactHooks.useQuery<SearchEmailTemplatesQuery, SearchEmailTemplatesQueryVariables>(SearchEmailTemplatesDocument, baseOptions);
      }
export function useSearchEmailTemplatesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SearchEmailTemplatesQuery, SearchEmailTemplatesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SearchEmailTemplatesQuery, SearchEmailTemplatesQueryVariables>(SearchEmailTemplatesDocument, baseOptions);
        }
export type SearchEmailTemplatesQueryHookResult = ReturnType<typeof useSearchEmailTemplatesQuery>;
export type SearchEmailTemplatesLazyQueryHookResult = ReturnType<typeof useSearchEmailTemplatesLazyQuery>;
export type SearchEmailTemplatesQueryResult = ApolloReactCommon.QueryResult<SearchEmailTemplatesQuery, SearchEmailTemplatesQueryVariables>;
export function refetchSearchEmailTemplatesQuery(variables?: SearchEmailTemplatesQueryVariables) {
      return { query: SearchEmailTemplatesDocument, variables: variables }
    }
export const ListLetterTemplatesDocument = gql`
    query listLetterTemplates {
  letter_templates(
    where: {is_archived: {_eq: false}}
    order_by: [{letter_template_name: asc}]
  ) {
    ...LetterTemplateBasic
  }
}
    ${LetterTemplateBasicFragmentDoc}`;

/**
 * __useListLetterTemplatesQuery__
 *
 * To run a query within a React component, call `useListLetterTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListLetterTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListLetterTemplatesQuery({
 *   variables: {
 *   },
 * });
 */
export function useListLetterTemplatesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ListLetterTemplatesQuery, ListLetterTemplatesQueryVariables>) {
        return ApolloReactHooks.useQuery<ListLetterTemplatesQuery, ListLetterTemplatesQueryVariables>(ListLetterTemplatesDocument, baseOptions);
      }
export function useListLetterTemplatesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ListLetterTemplatesQuery, ListLetterTemplatesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ListLetterTemplatesQuery, ListLetterTemplatesQueryVariables>(ListLetterTemplatesDocument, baseOptions);
        }
export type ListLetterTemplatesQueryHookResult = ReturnType<typeof useListLetterTemplatesQuery>;
export type ListLetterTemplatesLazyQueryHookResult = ReturnType<typeof useListLetterTemplatesLazyQuery>;
export type ListLetterTemplatesQueryResult = ApolloReactCommon.QueryResult<ListLetterTemplatesQuery, ListLetterTemplatesQueryVariables>;
export function refetchListLetterTemplatesQuery(variables?: ListLetterTemplatesQueryVariables) {
      return { query: ListLetterTemplatesDocument, variables: variables }
    }
export const ListPublicLetterTemplatesDocument = gql`
    query listPublicLetterTemplates {
  letter_templates(
    where: {is_archived: {_eq: false}, is_public: {_eq: true}}
    order_by: [{letter_template_name: asc}]
  ) {
    ...LetterTemplateBasic
  }
}
    ${LetterTemplateBasicFragmentDoc}`;

/**
 * __useListPublicLetterTemplatesQuery__
 *
 * To run a query within a React component, call `useListPublicLetterTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListPublicLetterTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListPublicLetterTemplatesQuery({
 *   variables: {
 *   },
 * });
 */
export function useListPublicLetterTemplatesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ListPublicLetterTemplatesQuery, ListPublicLetterTemplatesQueryVariables>) {
        return ApolloReactHooks.useQuery<ListPublicLetterTemplatesQuery, ListPublicLetterTemplatesQueryVariables>(ListPublicLetterTemplatesDocument, baseOptions);
      }
export function useListPublicLetterTemplatesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ListPublicLetterTemplatesQuery, ListPublicLetterTemplatesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ListPublicLetterTemplatesQuery, ListPublicLetterTemplatesQueryVariables>(ListPublicLetterTemplatesDocument, baseOptions);
        }
export type ListPublicLetterTemplatesQueryHookResult = ReturnType<typeof useListPublicLetterTemplatesQuery>;
export type ListPublicLetterTemplatesLazyQueryHookResult = ReturnType<typeof useListPublicLetterTemplatesLazyQuery>;
export type ListPublicLetterTemplatesQueryResult = ApolloReactCommon.QueryResult<ListPublicLetterTemplatesQuery, ListPublicLetterTemplatesQueryVariables>;
export function refetchListPublicLetterTemplatesQuery(variables?: ListPublicLetterTemplatesQueryVariables) {
      return { query: ListPublicLetterTemplatesDocument, variables: variables }
    }
export const GetLetterTemplateDocument = gql`
    query getLetterTemplate($letter_template_id: String!) {
  letter_templates_by_pk(letter_template_id: $letter_template_id) {
    ...LetterTemplateBasic
  }
}
    ${LetterTemplateBasicFragmentDoc}`;

/**
 * __useGetLetterTemplateQuery__
 *
 * To run a query within a React component, call `useGetLetterTemplateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLetterTemplateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLetterTemplateQuery({
 *   variables: {
 *      letter_template_id: // value for 'letter_template_id'
 *   },
 * });
 */
export function useGetLetterTemplateQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetLetterTemplateQuery, GetLetterTemplateQueryVariables>) {
        return ApolloReactHooks.useQuery<GetLetterTemplateQuery, GetLetterTemplateQueryVariables>(GetLetterTemplateDocument, baseOptions);
      }
export function useGetLetterTemplateLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetLetterTemplateQuery, GetLetterTemplateQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetLetterTemplateQuery, GetLetterTemplateQueryVariables>(GetLetterTemplateDocument, baseOptions);
        }
export type GetLetterTemplateQueryHookResult = ReturnType<typeof useGetLetterTemplateQuery>;
export type GetLetterTemplateLazyQueryHookResult = ReturnType<typeof useGetLetterTemplateLazyQuery>;
export type GetLetterTemplateQueryResult = ApolloReactCommon.QueryResult<GetLetterTemplateQuery, GetLetterTemplateQueryVariables>;
export function refetchGetLetterTemplateQuery(variables?: GetLetterTemplateQueryVariables) {
      return { query: GetLetterTemplateDocument, variables: variables }
    }
export const SearchLetterTemplatesDocument = gql`
    query searchLetterTemplates($exact_query: String, $substring_query: String, $limit: Int, $offset: Int, $order_by: [letter_templates_order_by!] = [{updated_at: desc}], $additional_filters: [letter_templates_bool_exp], $count_aggregate_limit: Int) {
  letter_templates(
    where: {_or: [{letter_template_id: {_eq: $exact_query}}, {letter_template_name: {_ilike: $substring_query}}], _and: $additional_filters}
    limit: $limit
    offset: $offset
    order_by: $order_by
  ) {
    ...LetterTemplateBasic
  }
  letter_templates_aggregate(
    where: {_or: [{letter_template_id: {_eq: $exact_query}}, {letter_template_name: {_ilike: $substring_query}}], _and: $additional_filters}
    limit: $count_aggregate_limit
  ) {
    aggregate {
      count
    }
  }
}
    ${LetterTemplateBasicFragmentDoc}`;

/**
 * __useSearchLetterTemplatesQuery__
 *
 * To run a query within a React component, call `useSearchLetterTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchLetterTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchLetterTemplatesQuery({
 *   variables: {
 *      exact_query: // value for 'exact_query'
 *      substring_query: // value for 'substring_query'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *      additional_filters: // value for 'additional_filters'
 *      count_aggregate_limit: // value for 'count_aggregate_limit'
 *   },
 * });
 */
export function useSearchLetterTemplatesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SearchLetterTemplatesQuery, SearchLetterTemplatesQueryVariables>) {
        return ApolloReactHooks.useQuery<SearchLetterTemplatesQuery, SearchLetterTemplatesQueryVariables>(SearchLetterTemplatesDocument, baseOptions);
      }
export function useSearchLetterTemplatesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SearchLetterTemplatesQuery, SearchLetterTemplatesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SearchLetterTemplatesQuery, SearchLetterTemplatesQueryVariables>(SearchLetterTemplatesDocument, baseOptions);
        }
export type SearchLetterTemplatesQueryHookResult = ReturnType<typeof useSearchLetterTemplatesQuery>;
export type SearchLetterTemplatesLazyQueryHookResult = ReturnType<typeof useSearchLetterTemplatesLazyQuery>;
export type SearchLetterTemplatesQueryResult = ApolloReactCommon.QueryResult<SearchLetterTemplatesQuery, SearchLetterTemplatesQueryVariables>;
export function refetchSearchLetterTemplatesQuery(variables?: SearchLetterTemplatesQueryVariables) {
      return { query: SearchLetterTemplatesDocument, variables: variables }
    }
export const CreateLetterTemplateDocument = gql`
    mutation createLetterTemplate($letter_template: letter_templates_insert_input!) {
  insert_letter_templates_one(object: $letter_template) {
    ...LetterTemplateBasic
  }
}
    ${LetterTemplateBasicFragmentDoc}`;
export type CreateLetterTemplateMutationFn = ApolloReactCommon.MutationFunction<CreateLetterTemplateMutation, CreateLetterTemplateMutationVariables>;

/**
 * __useCreateLetterTemplateMutation__
 *
 * To run a mutation, you first call `useCreateLetterTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLetterTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLetterTemplateMutation, { data, loading, error }] = useCreateLetterTemplateMutation({
 *   variables: {
 *      letter_template: // value for 'letter_template'
 *   },
 * });
 */
export function useCreateLetterTemplateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateLetterTemplateMutation, CreateLetterTemplateMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateLetterTemplateMutation, CreateLetterTemplateMutationVariables>(CreateLetterTemplateDocument, baseOptions);
      }
export type CreateLetterTemplateMutationHookResult = ReturnType<typeof useCreateLetterTemplateMutation>;
export type CreateLetterTemplateMutationResult = ApolloReactCommon.MutationResult<CreateLetterTemplateMutation>;
export type CreateLetterTemplateMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateLetterTemplateMutation, CreateLetterTemplateMutationVariables>;
export const UpdateLetterTemplateDocument = gql`
    mutation updateLetterTemplate($letter_template_id: String!, $changes: letter_templates_set_input = {updated_at: null}) {
  update_letter_templates_by_pk(
    pk_columns: {letter_template_id: $letter_template_id}
    _set: $changes
  ) {
    ...LetterTemplateBasic
  }
}
    ${LetterTemplateBasicFragmentDoc}`;
export type UpdateLetterTemplateMutationFn = ApolloReactCommon.MutationFunction<UpdateLetterTemplateMutation, UpdateLetterTemplateMutationVariables>;

/**
 * __useUpdateLetterTemplateMutation__
 *
 * To run a mutation, you first call `useUpdateLetterTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLetterTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLetterTemplateMutation, { data, loading, error }] = useUpdateLetterTemplateMutation({
 *   variables: {
 *      letter_template_id: // value for 'letter_template_id'
 *      changes: // value for 'changes'
 *   },
 * });
 */
export function useUpdateLetterTemplateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateLetterTemplateMutation, UpdateLetterTemplateMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateLetterTemplateMutation, UpdateLetterTemplateMutationVariables>(UpdateLetterTemplateDocument, baseOptions);
      }
export type UpdateLetterTemplateMutationHookResult = ReturnType<typeof useUpdateLetterTemplateMutation>;
export type UpdateLetterTemplateMutationResult = ApolloReactCommon.MutationResult<UpdateLetterTemplateMutation>;
export type UpdateLetterTemplateMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateLetterTemplateMutation, UpdateLetterTemplateMutationVariables>;
export const CreateEmailTemplateDocument = gql`
    mutation createEmailTemplate($email_template: email_templates_insert_input!) {
  insert_email_templates_one(object: $email_template) {
    ...EmailTemplateBasic
  }
}
    ${EmailTemplateBasicFragmentDoc}`;
export type CreateEmailTemplateMutationFn = ApolloReactCommon.MutationFunction<CreateEmailTemplateMutation, CreateEmailTemplateMutationVariables>;

/**
 * __useCreateEmailTemplateMutation__
 *
 * To run a mutation, you first call `useCreateEmailTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEmailTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEmailTemplateMutation, { data, loading, error }] = useCreateEmailTemplateMutation({
 *   variables: {
 *      email_template: // value for 'email_template'
 *   },
 * });
 */
export function useCreateEmailTemplateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateEmailTemplateMutation, CreateEmailTemplateMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateEmailTemplateMutation, CreateEmailTemplateMutationVariables>(CreateEmailTemplateDocument, baseOptions);
      }
export type CreateEmailTemplateMutationHookResult = ReturnType<typeof useCreateEmailTemplateMutation>;
export type CreateEmailTemplateMutationResult = ApolloReactCommon.MutationResult<CreateEmailTemplateMutation>;
export type CreateEmailTemplateMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateEmailTemplateMutation, CreateEmailTemplateMutationVariables>;
export const UpdateEmailTemplateDocument = gql`
    mutation updateEmailTemplate($email_template_id: String!, $changes: email_templates_set_input = {updated_at: null}) {
  update_email_templates_by_pk(
    pk_columns: {email_template_id: $email_template_id}
    _set: $changes
  ) {
    ...EmailTemplateBasic
  }
}
    ${EmailTemplateBasicFragmentDoc}`;
export type UpdateEmailTemplateMutationFn = ApolloReactCommon.MutationFunction<UpdateEmailTemplateMutation, UpdateEmailTemplateMutationVariables>;

/**
 * __useUpdateEmailTemplateMutation__
 *
 * To run a mutation, you first call `useUpdateEmailTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEmailTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEmailTemplateMutation, { data, loading, error }] = useUpdateEmailTemplateMutation({
 *   variables: {
 *      email_template_id: // value for 'email_template_id'
 *      changes: // value for 'changes'
 *   },
 * });
 */
export function useUpdateEmailTemplateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateEmailTemplateMutation, UpdateEmailTemplateMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateEmailTemplateMutation, UpdateEmailTemplateMutationVariables>(UpdateEmailTemplateDocument, baseOptions);
      }
export type UpdateEmailTemplateMutationHookResult = ReturnType<typeof useUpdateEmailTemplateMutation>;
export type UpdateEmailTemplateMutationResult = ApolloReactCommon.MutationResult<UpdateEmailTemplateMutation>;
export type UpdateEmailTemplateMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateEmailTemplateMutation, UpdateEmailTemplateMutationVariables>;
export const CreateSmsTemplateDocument = gql`
    mutation createSMSTemplate($sms_template: sms_templates_insert_input!) {
  insert_sms_templates_one(object: $sms_template) {
    ...SMSTemplateBasic
  }
}
    ${SmsTemplateBasicFragmentDoc}`;
export type CreateSmsTemplateMutationFn = ApolloReactCommon.MutationFunction<CreateSmsTemplateMutation, CreateSmsTemplateMutationVariables>;

/**
 * __useCreateSmsTemplateMutation__
 *
 * To run a mutation, you first call `useCreateSmsTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSmsTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSmsTemplateMutation, { data, loading, error }] = useCreateSmsTemplateMutation({
 *   variables: {
 *      sms_template: // value for 'sms_template'
 *   },
 * });
 */
export function useCreateSmsTemplateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateSmsTemplateMutation, CreateSmsTemplateMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateSmsTemplateMutation, CreateSmsTemplateMutationVariables>(CreateSmsTemplateDocument, baseOptions);
      }
export type CreateSmsTemplateMutationHookResult = ReturnType<typeof useCreateSmsTemplateMutation>;
export type CreateSmsTemplateMutationResult = ApolloReactCommon.MutationResult<CreateSmsTemplateMutation>;
export type CreateSmsTemplateMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateSmsTemplateMutation, CreateSmsTemplateMutationVariables>;
export const UpdateSmsTemplateDocument = gql`
    mutation updateSMSTemplate($sms_template_id: String!, $changes: sms_templates_set_input = {updated_at: null}) {
  update_sms_templates_by_pk(
    pk_columns: {sms_template_id: $sms_template_id}
    _set: $changes
  ) {
    ...SMSTemplateBasic
  }
}
    ${SmsTemplateBasicFragmentDoc}`;
export type UpdateSmsTemplateMutationFn = ApolloReactCommon.MutationFunction<UpdateSmsTemplateMutation, UpdateSmsTemplateMutationVariables>;

/**
 * __useUpdateSmsTemplateMutation__
 *
 * To run a mutation, you first call `useUpdateSmsTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSmsTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSmsTemplateMutation, { data, loading, error }] = useUpdateSmsTemplateMutation({
 *   variables: {
 *      sms_template_id: // value for 'sms_template_id'
 *      changes: // value for 'changes'
 *   },
 * });
 */
export function useUpdateSmsTemplateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateSmsTemplateMutation, UpdateSmsTemplateMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateSmsTemplateMutation, UpdateSmsTemplateMutationVariables>(UpdateSmsTemplateDocument, baseOptions);
      }
export type UpdateSmsTemplateMutationHookResult = ReturnType<typeof useUpdateSmsTemplateMutation>;
export type UpdateSmsTemplateMutationResult = ApolloReactCommon.MutationResult<UpdateSmsTemplateMutation>;
export type UpdateSmsTemplateMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateSmsTemplateMutation, UpdateSmsTemplateMutationVariables>;
export const ListPatientSegmentsDocument = gql`
    query listPatientSegments {
  patient_segments {
    ...PatientSegmentBasic
  }
}
    ${PatientSegmentBasicFragmentDoc}`;

/**
 * __useListPatientSegmentsQuery__
 *
 * To run a query within a React component, call `useListPatientSegmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListPatientSegmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListPatientSegmentsQuery({
 *   variables: {
 *   },
 * });
 */
export function useListPatientSegmentsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ListPatientSegmentsQuery, ListPatientSegmentsQueryVariables>) {
        return ApolloReactHooks.useQuery<ListPatientSegmentsQuery, ListPatientSegmentsQueryVariables>(ListPatientSegmentsDocument, baseOptions);
      }
export function useListPatientSegmentsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ListPatientSegmentsQuery, ListPatientSegmentsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ListPatientSegmentsQuery, ListPatientSegmentsQueryVariables>(ListPatientSegmentsDocument, baseOptions);
        }
export type ListPatientSegmentsQueryHookResult = ReturnType<typeof useListPatientSegmentsQuery>;
export type ListPatientSegmentsLazyQueryHookResult = ReturnType<typeof useListPatientSegmentsLazyQuery>;
export type ListPatientSegmentsQueryResult = ApolloReactCommon.QueryResult<ListPatientSegmentsQuery, ListPatientSegmentsQueryVariables>;
export function refetchListPatientSegmentsQuery(variables?: ListPatientSegmentsQueryVariables) {
      return { query: ListPatientSegmentsDocument, variables: variables }
    }
export const ListPatientRosterFilesDocument = gql`
    query listPatientRosterFiles($practice_id: String) {
  patient_roster_files(where: {practice_id: {_eq: $practice_id}}) {
    ...PatientRosterFileBasic
  }
}
    ${PatientRosterFileBasicFragmentDoc}`;

/**
 * __useListPatientRosterFilesQuery__
 *
 * To run a query within a React component, call `useListPatientRosterFilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListPatientRosterFilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListPatientRosterFilesQuery({
 *   variables: {
 *      practice_id: // value for 'practice_id'
 *   },
 * });
 */
export function useListPatientRosterFilesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ListPatientRosterFilesQuery, ListPatientRosterFilesQueryVariables>) {
        return ApolloReactHooks.useQuery<ListPatientRosterFilesQuery, ListPatientRosterFilesQueryVariables>(ListPatientRosterFilesDocument, baseOptions);
      }
export function useListPatientRosterFilesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ListPatientRosterFilesQuery, ListPatientRosterFilesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ListPatientRosterFilesQuery, ListPatientRosterFilesQueryVariables>(ListPatientRosterFilesDocument, baseOptions);
        }
export type ListPatientRosterFilesQueryHookResult = ReturnType<typeof useListPatientRosterFilesQuery>;
export type ListPatientRosterFilesLazyQueryHookResult = ReturnType<typeof useListPatientRosterFilesLazyQuery>;
export type ListPatientRosterFilesQueryResult = ApolloReactCommon.QueryResult<ListPatientRosterFilesQuery, ListPatientRosterFilesQueryVariables>;
export function refetchListPatientRosterFilesQuery(variables?: ListPatientRosterFilesQueryVariables) {
      return { query: ListPatientRosterFilesDocument, variables: variables }
    }
export const CreatePatientRosterFileDocument = gql`
    mutation createPatientRosterFile($practice_id: String!, $identity_id: String!, $key: String!, $level: String = "private") {
  insert_patient_roster_files_one(
    object: {practice_id: $practice_id, identity_id: $identity_id, key: $key, level: $level}
    on_conflict: {constraint: patient_roster_files_pkey, update_columns: []}
  ) {
    ...PatientRosterFileBasic
  }
}
    ${PatientRosterFileBasicFragmentDoc}`;
export type CreatePatientRosterFileMutationFn = ApolloReactCommon.MutationFunction<CreatePatientRosterFileMutation, CreatePatientRosterFileMutationVariables>;

/**
 * __useCreatePatientRosterFileMutation__
 *
 * To run a mutation, you first call `useCreatePatientRosterFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePatientRosterFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPatientRosterFileMutation, { data, loading, error }] = useCreatePatientRosterFileMutation({
 *   variables: {
 *      practice_id: // value for 'practice_id'
 *      identity_id: // value for 'identity_id'
 *      key: // value for 'key'
 *      level: // value for 'level'
 *   },
 * });
 */
export function useCreatePatientRosterFileMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreatePatientRosterFileMutation, CreatePatientRosterFileMutationVariables>) {
        return ApolloReactHooks.useMutation<CreatePatientRosterFileMutation, CreatePatientRosterFileMutationVariables>(CreatePatientRosterFileDocument, baseOptions);
      }
export type CreatePatientRosterFileMutationHookResult = ReturnType<typeof useCreatePatientRosterFileMutation>;
export type CreatePatientRosterFileMutationResult = ApolloReactCommon.MutationResult<CreatePatientRosterFileMutation>;
export type CreatePatientRosterFileMutationOptions = ApolloReactCommon.BaseMutationOptions<CreatePatientRosterFileMutation, CreatePatientRosterFileMutationVariables>;
export const UpdatePatientRosterFileDocument = gql`
    mutation updatePatientRosterFile($identity_id: String!, $key: String!, $level: String!, $changes: patient_roster_files_set_input) {
  update_patient_roster_files_by_pk(
    pk_columns: {identity_id: $identity_id, key: $key, level: $level}
    _set: $changes
  ) {
    ...PatientRosterFileBasic
  }
}
    ${PatientRosterFileBasicFragmentDoc}`;
export type UpdatePatientRosterFileMutationFn = ApolloReactCommon.MutationFunction<UpdatePatientRosterFileMutation, UpdatePatientRosterFileMutationVariables>;

/**
 * __useUpdatePatientRosterFileMutation__
 *
 * To run a mutation, you first call `useUpdatePatientRosterFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePatientRosterFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePatientRosterFileMutation, { data, loading, error }] = useUpdatePatientRosterFileMutation({
 *   variables: {
 *      identity_id: // value for 'identity_id'
 *      key: // value for 'key'
 *      level: // value for 'level'
 *      changes: // value for 'changes'
 *   },
 * });
 */
export function useUpdatePatientRosterFileMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdatePatientRosterFileMutation, UpdatePatientRosterFileMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdatePatientRosterFileMutation, UpdatePatientRosterFileMutationVariables>(UpdatePatientRosterFileDocument, baseOptions);
      }
export type UpdatePatientRosterFileMutationHookResult = ReturnType<typeof useUpdatePatientRosterFileMutation>;
export type UpdatePatientRosterFileMutationResult = ApolloReactCommon.MutationResult<UpdatePatientRosterFileMutation>;
export type UpdatePatientRosterFileMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdatePatientRosterFileMutation, UpdatePatientRosterFileMutationVariables>;
export const CreateFileUploadDocument = gql`
    mutation createFileUpload($file_upload: file_uploads_insert_input!) {
  insert_file_uploads_one(object: $file_upload) {
    ...FileUploadBasic
  }
}
    ${FileUploadBasicFragmentDoc}`;
export type CreateFileUploadMutationFn = ApolloReactCommon.MutationFunction<CreateFileUploadMutation, CreateFileUploadMutationVariables>;

/**
 * __useCreateFileUploadMutation__
 *
 * To run a mutation, you first call `useCreateFileUploadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFileUploadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFileUploadMutation, { data, loading, error }] = useCreateFileUploadMutation({
 *   variables: {
 *      file_upload: // value for 'file_upload'
 *   },
 * });
 */
export function useCreateFileUploadMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateFileUploadMutation, CreateFileUploadMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateFileUploadMutation, CreateFileUploadMutationVariables>(CreateFileUploadDocument, baseOptions);
      }
export type CreateFileUploadMutationHookResult = ReturnType<typeof useCreateFileUploadMutation>;
export type CreateFileUploadMutationResult = ApolloReactCommon.MutationResult<CreateFileUploadMutation>;
export type CreateFileUploadMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateFileUploadMutation, CreateFileUploadMutationVariables>;
export const UpdateFileUploadDocument = gql`
    mutation updateFileUpload($file_upload_id: String!, $changes: file_uploads_set_input) {
  update_file_uploads_by_pk(
    pk_columns: {file_upload_id: $file_upload_id}
    _set: $changes
  ) {
    ...FileUploadBasic
  }
}
    ${FileUploadBasicFragmentDoc}`;
export type UpdateFileUploadMutationFn = ApolloReactCommon.MutationFunction<UpdateFileUploadMutation, UpdateFileUploadMutationVariables>;

/**
 * __useUpdateFileUploadMutation__
 *
 * To run a mutation, you first call `useUpdateFileUploadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFileUploadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFileUploadMutation, { data, loading, error }] = useUpdateFileUploadMutation({
 *   variables: {
 *      file_upload_id: // value for 'file_upload_id'
 *      changes: // value for 'changes'
 *   },
 * });
 */
export function useUpdateFileUploadMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateFileUploadMutation, UpdateFileUploadMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateFileUploadMutation, UpdateFileUploadMutationVariables>(UpdateFileUploadDocument, baseOptions);
      }
export type UpdateFileUploadMutationHookResult = ReturnType<typeof useUpdateFileUploadMutation>;
export type UpdateFileUploadMutationResult = ApolloReactCommon.MutationResult<UpdateFileUploadMutation>;
export type UpdateFileUploadMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateFileUploadMutation, UpdateFileUploadMutationVariables>;
export const GetInsuranceCountsDocument = gql`
    query getInsuranceCounts($limit: Int = 200, $offset: Int = 0, $birth_date_before: date, $birth_date_after: date, $network_id: String, $practice_id: String) {
  filtered_insurance_counts(
    args: {_limit: $limit, _offset: $offset, birth_date_before: $birth_date_before, birth_date_after: $birth_date_after, network_id: $network_id, practice_id: $practice_id}
    order_by: {count: desc}
  ) {
    count
    primary_insurance_payer_name
    primary_insurance_is_null
    secondary_insurance_payer_name
    secondary_insurance_is_null
    insurance_segment_mapping {
      primary_insurance_payer_name
      primary_insurance_is_null
      secondary_insurance_payer_name
      secondary_insurance_is_null
      patient_segment_id
    }
  }
  filtered_insurance_counts_aggregate(
    args: {birth_date_before: $birth_date_before, birth_date_after: $birth_date_after, network_id: $network_id, practice_id: $practice_id}
  ) {
    aggregate {
      sum {
        count
      }
    }
  }
}
    `;

/**
 * __useGetInsuranceCountsQuery__
 *
 * To run a query within a React component, call `useGetInsuranceCountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInsuranceCountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInsuranceCountsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      birth_date_before: // value for 'birth_date_before'
 *      birth_date_after: // value for 'birth_date_after'
 *      network_id: // value for 'network_id'
 *      practice_id: // value for 'practice_id'
 *   },
 * });
 */
export function useGetInsuranceCountsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetInsuranceCountsQuery, GetInsuranceCountsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetInsuranceCountsQuery, GetInsuranceCountsQueryVariables>(GetInsuranceCountsDocument, baseOptions);
      }
export function useGetInsuranceCountsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetInsuranceCountsQuery, GetInsuranceCountsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetInsuranceCountsQuery, GetInsuranceCountsQueryVariables>(GetInsuranceCountsDocument, baseOptions);
        }
export type GetInsuranceCountsQueryHookResult = ReturnType<typeof useGetInsuranceCountsQuery>;
export type GetInsuranceCountsLazyQueryHookResult = ReturnType<typeof useGetInsuranceCountsLazyQuery>;
export type GetInsuranceCountsQueryResult = ApolloReactCommon.QueryResult<GetInsuranceCountsQuery, GetInsuranceCountsQueryVariables>;
export function refetchGetInsuranceCountsQuery(variables?: GetInsuranceCountsQueryVariables) {
      return { query: GetInsuranceCountsDocument, variables: variables }
    }
export const UpsertInsuranceSegmentMappingDocument = gql`
    mutation upsertInsuranceSegmentMapping($primary_insurance_payer_name: String!, $primary_insurance_is_null: Boolean!, $secondary_insurance_payer_name: String!, $secondary_insurance_is_null: Boolean!, $patient_segment_id: String) {
  insert_insurance_segment_mappings_one(
    object: {primary_insurance_payer_name: $primary_insurance_payer_name, primary_insurance_is_null: $primary_insurance_is_null, secondary_insurance_payer_name: $secondary_insurance_payer_name, secondary_insurance_is_null: $secondary_insurance_is_null, patient_segment_id: $patient_segment_id}
    on_conflict: {constraint: insurance_segment_mappings_pkey, update_columns: patient_segment_id}
  ) {
    primary_insurance_payer_name
    primary_insurance_is_null
    secondary_insurance_payer_name
    secondary_insurance_is_null
    patient_segment_id
  }
}
    `;
export type UpsertInsuranceSegmentMappingMutationFn = ApolloReactCommon.MutationFunction<UpsertInsuranceSegmentMappingMutation, UpsertInsuranceSegmentMappingMutationVariables>;

/**
 * __useUpsertInsuranceSegmentMappingMutation__
 *
 * To run a mutation, you first call `useUpsertInsuranceSegmentMappingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertInsuranceSegmentMappingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertInsuranceSegmentMappingMutation, { data, loading, error }] = useUpsertInsuranceSegmentMappingMutation({
 *   variables: {
 *      primary_insurance_payer_name: // value for 'primary_insurance_payer_name'
 *      primary_insurance_is_null: // value for 'primary_insurance_is_null'
 *      secondary_insurance_payer_name: // value for 'secondary_insurance_payer_name'
 *      secondary_insurance_is_null: // value for 'secondary_insurance_is_null'
 *      patient_segment_id: // value for 'patient_segment_id'
 *   },
 * });
 */
export function useUpsertInsuranceSegmentMappingMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpsertInsuranceSegmentMappingMutation, UpsertInsuranceSegmentMappingMutationVariables>) {
        return ApolloReactHooks.useMutation<UpsertInsuranceSegmentMappingMutation, UpsertInsuranceSegmentMappingMutationVariables>(UpsertInsuranceSegmentMappingDocument, baseOptions);
      }
export type UpsertInsuranceSegmentMappingMutationHookResult = ReturnType<typeof useUpsertInsuranceSegmentMappingMutation>;
export type UpsertInsuranceSegmentMappingMutationResult = ApolloReactCommon.MutationResult<UpsertInsuranceSegmentMappingMutation>;
export type UpsertInsuranceSegmentMappingMutationOptions = ApolloReactCommon.BaseMutationOptions<UpsertInsuranceSegmentMappingMutation, UpsertInsuranceSegmentMappingMutationVariables>;
export const ListPatientCommunicationsDocument = gql`
    query listPatientCommunications($patient_id: String!) {
  letters(where: {patient_id: {_eq: $patient_id}}) {
    ...LetterDetails
  }
  emails(where: {patient_id: {_eq: $patient_id}}) {
    ...EmailDetails
  }
  sms_messages(where: {patient_id: {_eq: $patient_id}}) {
    ...SMSDetails
  }
}
    ${LetterDetailsFragmentDoc}
${EmailDetailsFragmentDoc}
${SmsDetailsFragmentDoc}`;

/**
 * __useListPatientCommunicationsQuery__
 *
 * To run a query within a React component, call `useListPatientCommunicationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListPatientCommunicationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListPatientCommunicationsQuery({
 *   variables: {
 *      patient_id: // value for 'patient_id'
 *   },
 * });
 */
export function useListPatientCommunicationsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ListPatientCommunicationsQuery, ListPatientCommunicationsQueryVariables>) {
        return ApolloReactHooks.useQuery<ListPatientCommunicationsQuery, ListPatientCommunicationsQueryVariables>(ListPatientCommunicationsDocument, baseOptions);
      }
export function useListPatientCommunicationsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ListPatientCommunicationsQuery, ListPatientCommunicationsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ListPatientCommunicationsQuery, ListPatientCommunicationsQueryVariables>(ListPatientCommunicationsDocument, baseOptions);
        }
export type ListPatientCommunicationsQueryHookResult = ReturnType<typeof useListPatientCommunicationsQuery>;
export type ListPatientCommunicationsLazyQueryHookResult = ReturnType<typeof useListPatientCommunicationsLazyQuery>;
export type ListPatientCommunicationsQueryResult = ApolloReactCommon.QueryResult<ListPatientCommunicationsQuery, ListPatientCommunicationsQueryVariables>;
export function refetchListPatientCommunicationsQuery(variables?: ListPatientCommunicationsQueryVariables) {
      return { query: ListPatientCommunicationsDocument, variables: variables }
    }
export const SearchPvAsDocument = gql`
    query searchPVAs($exact_query: String, $substring_query: String, $prefix_query: String, $limit: Int, $offset: Int, $order_by: [pvas_order_by!], $additional_filters: [pvas_bool_exp!], $count_aggregate_limit: Int) {
  pvas(
    where: {_or: [{pva_id: {_eq: $exact_query}}, {submission_id: {_ilike: $prefix_query}}, {mbi: {_ilike: $prefix_query}}, {beneficiary_name: {_ilike: $substring_query}}], _and: $additional_filters}
    limit: $limit
    offset: $offset
    order_by: $order_by
  ) {
    ...PVADetails
  }
  pvas_aggregate(
    where: {_or: [{submission_id: {_ilike: $prefix_query}}, {mbi: {_ilike: $prefix_query}}, {beneficiary_name: {_ilike: $substring_query}}], _and: $additional_filters}
    limit: $count_aggregate_limit
  ) {
    aggregate {
      count
    }
  }
  networks(where: {pvas: {}}, order_by: [{network_name: asc}]) {
    ...NetworkBasic
  }
}
    ${PvaDetailsFragmentDoc}
${NetworkBasicFragmentDoc}`;

/**
 * __useSearchPvAsQuery__
 *
 * To run a query within a React component, call `useSearchPvAsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchPvAsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchPvAsQuery({
 *   variables: {
 *      exact_query: // value for 'exact_query'
 *      substring_query: // value for 'substring_query'
 *      prefix_query: // value for 'prefix_query'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *      additional_filters: // value for 'additional_filters'
 *      count_aggregate_limit: // value for 'count_aggregate_limit'
 *   },
 * });
 */
export function useSearchPvAsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SearchPvAsQuery, SearchPvAsQueryVariables>) {
        return ApolloReactHooks.useQuery<SearchPvAsQuery, SearchPvAsQueryVariables>(SearchPvAsDocument, baseOptions);
      }
export function useSearchPvAsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SearchPvAsQuery, SearchPvAsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SearchPvAsQuery, SearchPvAsQueryVariables>(SearchPvAsDocument, baseOptions);
        }
export type SearchPvAsQueryHookResult = ReturnType<typeof useSearchPvAsQuery>;
export type SearchPvAsLazyQueryHookResult = ReturnType<typeof useSearchPvAsLazyQuery>;
export type SearchPvAsQueryResult = ApolloReactCommon.QueryResult<SearchPvAsQuery, SearchPvAsQueryVariables>;
export function refetchSearchPvAsQuery(variables?: SearchPvAsQueryVariables) {
      return { query: SearchPvAsDocument, variables: variables }
    }
export const UpdatePvaDocument = gql`
    mutation updatePVA($pva_id: String!, $changes: pvas_set_input) {
  update_pvas_by_pk(pk_columns: {pva_id: $pva_id}, _set: $changes) {
    ...PVADetails
  }
}
    ${PvaDetailsFragmentDoc}`;
export type UpdatePvaMutationFn = ApolloReactCommon.MutationFunction<UpdatePvaMutation, UpdatePvaMutationVariables>;

/**
 * __useUpdatePvaMutation__
 *
 * To run a mutation, you first call `useUpdatePvaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePvaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePvaMutation, { data, loading, error }] = useUpdatePvaMutation({
 *   variables: {
 *      pva_id: // value for 'pva_id'
 *      changes: // value for 'changes'
 *   },
 * });
 */
export function useUpdatePvaMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdatePvaMutation, UpdatePvaMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdatePvaMutation, UpdatePvaMutationVariables>(UpdatePvaDocument, baseOptions);
      }
export type UpdatePvaMutationHookResult = ReturnType<typeof useUpdatePvaMutation>;
export type UpdatePvaMutationResult = ApolloReactCommon.MutationResult<UpdatePvaMutation>;
export type UpdatePvaMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdatePvaMutation, UpdatePvaMutationVariables>;
export const SearchActiveParticipantsDocument = gql`
    query searchActiveParticipants($dc_id: String!, $signature_date: date!, $prefix_query: String, $substring_query: String) {
  analytics_analytics_cms_active_dce_participants(
    where: {entity_id: {_eq: $dc_id}, effective_start_date: {_lte: $signature_date, _is_null: false}, effective_end_date: {_gte: $signature_date, _is_null: false}, participant_type: {_in: ["Individual Participant", "FQHC Individual"]}, individual_npi: {_is_null: false, _neq: ""}, _or: [{legal_business_name: {_ilike: $substring_query}}, {individual_npi: {_ilike: $prefix_query}}]}
    limit: 50
  ) {
    ...ParticipantBasic
  }
}
    ${ParticipantBasicFragmentDoc}`;

/**
 * __useSearchActiveParticipantsQuery__
 *
 * To run a query within a React component, call `useSearchActiveParticipantsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchActiveParticipantsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchActiveParticipantsQuery({
 *   variables: {
 *      dc_id: // value for 'dc_id'
 *      signature_date: // value for 'signature_date'
 *      prefix_query: // value for 'prefix_query'
 *      substring_query: // value for 'substring_query'
 *   },
 * });
 */
export function useSearchActiveParticipantsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SearchActiveParticipantsQuery, SearchActiveParticipantsQueryVariables>) {
        return ApolloReactHooks.useQuery<SearchActiveParticipantsQuery, SearchActiveParticipantsQueryVariables>(SearchActiveParticipantsDocument, baseOptions);
      }
export function useSearchActiveParticipantsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SearchActiveParticipantsQuery, SearchActiveParticipantsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SearchActiveParticipantsQuery, SearchActiveParticipantsQueryVariables>(SearchActiveParticipantsDocument, baseOptions);
        }
export type SearchActiveParticipantsQueryHookResult = ReturnType<typeof useSearchActiveParticipantsQuery>;
export type SearchActiveParticipantsLazyQueryHookResult = ReturnType<typeof useSearchActiveParticipantsLazyQuery>;
export type SearchActiveParticipantsQueryResult = ApolloReactCommon.QueryResult<SearchActiveParticipantsQuery, SearchActiveParticipantsQueryVariables>;
export function refetchSearchActiveParticipantsQuery(variables?: SearchActiveParticipantsQueryVariables) {
      return { query: SearchActiveParticipantsDocument, variables: variables }
    }
export const ValidateActiveParticipantsDocument = gql`
    query validateActiveParticipants($dc_id: String!, $npi: String!, $tin: String!) {
  analytics_analytics_cms_active_dce_participants(
    where: {entity_id: {_eq: $dc_id}, individual_npi: {_eq: $npi}, tin: {_eq: $tin}, participant_type: {_in: ["Individual Participant", "FQHC Individual"]}}
  ) {
    ...ParticipantBasic
  }
}
    ${ParticipantBasicFragmentDoc}`;

/**
 * __useValidateActiveParticipantsQuery__
 *
 * To run a query within a React component, call `useValidateActiveParticipantsQuery` and pass it any options that fit your needs.
 * When your component renders, `useValidateActiveParticipantsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useValidateActiveParticipantsQuery({
 *   variables: {
 *      dc_id: // value for 'dc_id'
 *      npi: // value for 'npi'
 *      tin: // value for 'tin'
 *   },
 * });
 */
export function useValidateActiveParticipantsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ValidateActiveParticipantsQuery, ValidateActiveParticipantsQueryVariables>) {
        return ApolloReactHooks.useQuery<ValidateActiveParticipantsQuery, ValidateActiveParticipantsQueryVariables>(ValidateActiveParticipantsDocument, baseOptions);
      }
export function useValidateActiveParticipantsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ValidateActiveParticipantsQuery, ValidateActiveParticipantsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ValidateActiveParticipantsQuery, ValidateActiveParticipantsQueryVariables>(ValidateActiveParticipantsDocument, baseOptions);
        }
export type ValidateActiveParticipantsQueryHookResult = ReturnType<typeof useValidateActiveParticipantsQuery>;
export type ValidateActiveParticipantsLazyQueryHookResult = ReturnType<typeof useValidateActiveParticipantsLazyQuery>;
export type ValidateActiveParticipantsQueryResult = ApolloReactCommon.QueryResult<ValidateActiveParticipantsQuery, ValidateActiveParticipantsQueryVariables>;
export function refetchValidateActiveParticipantsQuery(variables?: ValidateActiveParticipantsQueryVariables) {
      return { query: ValidateActiveParticipantsDocument, variables: variables }
    }
export const SearchHedRsDocument = gql`
    query searchHEDRs($exact_query: String, $substring_query: String, $prefix_query: String, $limit: Int, $offset: Int, $order_by: [health_equity_reports_order_by!], $additional_filters: [health_equity_reports_bool_exp!], $count_aggregate_limit: Int) {
  health_equity_reports(
    where: {_or: [{health_equity_report_id: {_eq: $exact_query}}, {submission_id: {_ilike: $prefix_query}}, {mbi: {_ilike: $prefix_query}}, {beneficiary_name: {_ilike: $substring_query}}], _and: $additional_filters}
    limit: $limit
    offset: $offset
    order_by: $order_by
  ) {
    ...HEDRDetails
  }
  health_equity_reports_aggregate(
    where: {_or: [{submission_id: {_ilike: $prefix_query}}, {mbi: {_ilike: $prefix_query}}, {beneficiary_name: {_ilike: $substring_query}}], _and: $additional_filters}
    limit: $count_aggregate_limit
  ) {
    aggregate {
      count
    }
  }
  networks(where: {health_equity_reports: {}}, order_by: [{network_name: asc}]) {
    ...NetworkBasic
  }
}
    ${HedrDetailsFragmentDoc}
${NetworkBasicFragmentDoc}`;

/**
 * __useSearchHedRsQuery__
 *
 * To run a query within a React component, call `useSearchHedRsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchHedRsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchHedRsQuery({
 *   variables: {
 *      exact_query: // value for 'exact_query'
 *      substring_query: // value for 'substring_query'
 *      prefix_query: // value for 'prefix_query'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      order_by: // value for 'order_by'
 *      additional_filters: // value for 'additional_filters'
 *      count_aggregate_limit: // value for 'count_aggregate_limit'
 *   },
 * });
 */
export function useSearchHedRsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SearchHedRsQuery, SearchHedRsQueryVariables>) {
        return ApolloReactHooks.useQuery<SearchHedRsQuery, SearchHedRsQueryVariables>(SearchHedRsDocument, baseOptions);
      }
export function useSearchHedRsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SearchHedRsQuery, SearchHedRsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SearchHedRsQuery, SearchHedRsQueryVariables>(SearchHedRsDocument, baseOptions);
        }
export type SearchHedRsQueryHookResult = ReturnType<typeof useSearchHedRsQuery>;
export type SearchHedRsLazyQueryHookResult = ReturnType<typeof useSearchHedRsLazyQuery>;
export type SearchHedRsQueryResult = ApolloReactCommon.QueryResult<SearchHedRsQuery, SearchHedRsQueryVariables>;
export function refetchSearchHedRsQuery(variables?: SearchHedRsQueryVariables) {
      return { query: SearchHedRsDocument, variables: variables }
    }
export const UpdateHedrDocument = gql`
    mutation updateHEDR($health_equity_report_id: String!, $changes: health_equity_reports_set_input) {
  update_health_equity_reports_by_pk(
    pk_columns: {health_equity_report_id: $health_equity_report_id}
    _set: $changes
  ) {
    ...HEDRDetails
  }
}
    ${HedrDetailsFragmentDoc}`;
export type UpdateHedrMutationFn = ApolloReactCommon.MutationFunction<UpdateHedrMutation, UpdateHedrMutationVariables>;

/**
 * __useUpdateHedrMutation__
 *
 * To run a mutation, you first call `useUpdateHedrMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateHedrMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateHedrMutation, { data, loading, error }] = useUpdateHedrMutation({
 *   variables: {
 *      health_equity_report_id: // value for 'health_equity_report_id'
 *      changes: // value for 'changes'
 *   },
 * });
 */
export function useUpdateHedrMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateHedrMutation, UpdateHedrMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateHedrMutation, UpdateHedrMutationVariables>(UpdateHedrDocument, baseOptions);
      }
export type UpdateHedrMutationHookResult = ReturnType<typeof useUpdateHedrMutation>;
export type UpdateHedrMutationResult = ApolloReactCommon.MutationResult<UpdateHedrMutation>;
export type UpdateHedrMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateHedrMutation, UpdateHedrMutationVariables>;
export const ListPvaResponseUploadDocument = gql`
    query listPVAResponseUpload($DC_ID: String!) {
  cms_pva_responses(
    distinct_on: [DCE_ID, SUBMISSION_DATE, file_upload_id]
    where: {DCE_ID: {_eq: $DC_ID}}
    order_by: {DCE_ID: asc, SUBMISSION_DATE: desc, file_upload_id: desc}
  ) {
    DCE_ID
    SUBMISSION_DATE
    ID_RECEIVED
    IND_NPI
    IND_TIN
    SIGNATURE_DATE
    created_at
    file_upload_id
    file_upload {
      ...FileUploadBasic
    }
  }
}
    ${FileUploadBasicFragmentDoc}`;

/**
 * __useListPvaResponseUploadQuery__
 *
 * To run a query within a React component, call `useListPvaResponseUploadQuery` and pass it any options that fit your needs.
 * When your component renders, `useListPvaResponseUploadQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListPvaResponseUploadQuery({
 *   variables: {
 *      DC_ID: // value for 'DC_ID'
 *   },
 * });
 */
export function useListPvaResponseUploadQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ListPvaResponseUploadQuery, ListPvaResponseUploadQueryVariables>) {
        return ApolloReactHooks.useQuery<ListPvaResponseUploadQuery, ListPvaResponseUploadQueryVariables>(ListPvaResponseUploadDocument, baseOptions);
      }
export function useListPvaResponseUploadLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ListPvaResponseUploadQuery, ListPvaResponseUploadQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ListPvaResponseUploadQuery, ListPvaResponseUploadQueryVariables>(ListPvaResponseUploadDocument, baseOptions);
        }
export type ListPvaResponseUploadQueryHookResult = ReturnType<typeof useListPvaResponseUploadQuery>;
export type ListPvaResponseUploadLazyQueryHookResult = ReturnType<typeof useListPvaResponseUploadLazyQuery>;
export type ListPvaResponseUploadQueryResult = ApolloReactCommon.QueryResult<ListPvaResponseUploadQuery, ListPvaResponseUploadQueryVariables>;
export function refetchListPvaResponseUploadQuery(variables?: ListPvaResponseUploadQueryVariables) {
      return { query: ListPvaResponseUploadDocument, variables: variables }
    }
export const InsertPvaResponseDocument = gql`
    mutation insertPVAResponse($objects: [cms_pva_responses_insert_input!]!) {
  insert_cms_pva_responses(objects: $objects) {
    affected_rows
  }
}
    `;
export type InsertPvaResponseMutationFn = ApolloReactCommon.MutationFunction<InsertPvaResponseMutation, InsertPvaResponseMutationVariables>;

/**
 * __useInsertPvaResponseMutation__
 *
 * To run a mutation, you first call `useInsertPvaResponseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertPvaResponseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertPvaResponseMutation, { data, loading, error }] = useInsertPvaResponseMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useInsertPvaResponseMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<InsertPvaResponseMutation, InsertPvaResponseMutationVariables>) {
        return ApolloReactHooks.useMutation<InsertPvaResponseMutation, InsertPvaResponseMutationVariables>(InsertPvaResponseDocument, baseOptions);
      }
export type InsertPvaResponseMutationHookResult = ReturnType<typeof useInsertPvaResponseMutation>;
export type InsertPvaResponseMutationResult = ApolloReactCommon.MutationResult<InsertPvaResponseMutation>;
export type InsertPvaResponseMutationOptions = ApolloReactCommon.BaseMutationOptions<InsertPvaResponseMutation, InsertPvaResponseMutationVariables>;
export const ListParticipantUploadDocument = gql`
    query listParticipantUpload($DC_ID: String!) {
  cms_dce_participants(
    distinct_on: [entity_id, performance_year, file_upload_id]
    where: {entity_id: {_eq: $DC_ID}}
    order_by: {entity_id: asc, performance_year: desc, file_upload_id: desc}
  ) {
    entity_id
    performance_year
    created_at
    file_upload_id
    file_upload {
      ...FileUploadBasic
    }
  }
}
    ${FileUploadBasicFragmentDoc}`;

/**
 * __useListParticipantUploadQuery__
 *
 * To run a query within a React component, call `useListParticipantUploadQuery` and pass it any options that fit your needs.
 * When your component renders, `useListParticipantUploadQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListParticipantUploadQuery({
 *   variables: {
 *      DC_ID: // value for 'DC_ID'
 *   },
 * });
 */
export function useListParticipantUploadQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ListParticipantUploadQuery, ListParticipantUploadQueryVariables>) {
        return ApolloReactHooks.useQuery<ListParticipantUploadQuery, ListParticipantUploadQueryVariables>(ListParticipantUploadDocument, baseOptions);
      }
export function useListParticipantUploadLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ListParticipantUploadQuery, ListParticipantUploadQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ListParticipantUploadQuery, ListParticipantUploadQueryVariables>(ListParticipantUploadDocument, baseOptions);
        }
export type ListParticipantUploadQueryHookResult = ReturnType<typeof useListParticipantUploadQuery>;
export type ListParticipantUploadLazyQueryHookResult = ReturnType<typeof useListParticipantUploadLazyQuery>;
export type ListParticipantUploadQueryResult = ApolloReactCommon.QueryResult<ListParticipantUploadQuery, ListParticipantUploadQueryVariables>;
export function refetchListParticipantUploadQuery(variables?: ListParticipantUploadQueryVariables) {
      return { query: ListParticipantUploadDocument, variables: variables }
    }
export const InsertParticipantListsDocument = gql`
    mutation insertParticipantLists($objects: [cms_dce_participants_insert_input!]!) {
  insert_cms_dce_participants(objects: $objects) {
    affected_rows
  }
}
    `;
export type InsertParticipantListsMutationFn = ApolloReactCommon.MutationFunction<InsertParticipantListsMutation, InsertParticipantListsMutationVariables>;

/**
 * __useInsertParticipantListsMutation__
 *
 * To run a mutation, you first call `useInsertParticipantListsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertParticipantListsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertParticipantListsMutation, { data, loading, error }] = useInsertParticipantListsMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useInsertParticipantListsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<InsertParticipantListsMutation, InsertParticipantListsMutationVariables>) {
        return ApolloReactHooks.useMutation<InsertParticipantListsMutation, InsertParticipantListsMutationVariables>(InsertParticipantListsDocument, baseOptions);
      }
export type InsertParticipantListsMutationHookResult = ReturnType<typeof useInsertParticipantListsMutation>;
export type InsertParticipantListsMutationResult = ApolloReactCommon.MutationResult<InsertParticipantListsMutation>;
export type InsertParticipantListsMutationOptions = ApolloReactCommon.BaseMutationOptions<InsertParticipantListsMutation, InsertParticipantListsMutationVariables>;
export const ListAlignmentUploadsDocument = gql`
    query listAlignmentUploads($DC_ID: String!) {
  cms_alignment_reports(
    distinct_on: [dc_id, performance_year, file_upload_id]
    where: {dc_id: {_eq: $DC_ID}}
    order_by: {dc_id: asc, performance_year: desc, file_upload_id: desc}
  ) {
    dc_id
    performance_year
    beneficiary_mbi_id
    updated_at
    file_upload_id
    file_upload {
      ...FileUploadBasic
    }
  }
}
    ${FileUploadBasicFragmentDoc}`;

/**
 * __useListAlignmentUploadsQuery__
 *
 * To run a query within a React component, call `useListAlignmentUploadsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListAlignmentUploadsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListAlignmentUploadsQuery({
 *   variables: {
 *      DC_ID: // value for 'DC_ID'
 *   },
 * });
 */
export function useListAlignmentUploadsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ListAlignmentUploadsQuery, ListAlignmentUploadsQueryVariables>) {
        return ApolloReactHooks.useQuery<ListAlignmentUploadsQuery, ListAlignmentUploadsQueryVariables>(ListAlignmentUploadsDocument, baseOptions);
      }
export function useListAlignmentUploadsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ListAlignmentUploadsQuery, ListAlignmentUploadsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ListAlignmentUploadsQuery, ListAlignmentUploadsQueryVariables>(ListAlignmentUploadsDocument, baseOptions);
        }
export type ListAlignmentUploadsQueryHookResult = ReturnType<typeof useListAlignmentUploadsQuery>;
export type ListAlignmentUploadsLazyQueryHookResult = ReturnType<typeof useListAlignmentUploadsLazyQuery>;
export type ListAlignmentUploadsQueryResult = ApolloReactCommon.QueryResult<ListAlignmentUploadsQuery, ListAlignmentUploadsQueryVariables>;
export function refetchListAlignmentUploadsQuery(variables?: ListAlignmentUploadsQueryVariables) {
      return { query: ListAlignmentUploadsDocument, variables: variables }
    }
export const UpsertAlignmentReportsDocument = gql`
    mutation upsertAlignmentReports($objects: [cms_alignment_reports_insert_input!]!, $cols_to_update: [cms_alignment_reports_update_column!]!) {
  insert_cms_alignment_reports(
    objects: $objects
    on_conflict: {constraint: cms_alignment_reports_pkey, update_columns: $cols_to_update}
  ) {
    affected_rows
  }
}
    `;
export type UpsertAlignmentReportsMutationFn = ApolloReactCommon.MutationFunction<UpsertAlignmentReportsMutation, UpsertAlignmentReportsMutationVariables>;

/**
 * __useUpsertAlignmentReportsMutation__
 *
 * To run a mutation, you first call `useUpsertAlignmentReportsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertAlignmentReportsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertAlignmentReportsMutation, { data, loading, error }] = useUpsertAlignmentReportsMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *      cols_to_update: // value for 'cols_to_update'
 *   },
 * });
 */
export function useUpsertAlignmentReportsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpsertAlignmentReportsMutation, UpsertAlignmentReportsMutationVariables>) {
        return ApolloReactHooks.useMutation<UpsertAlignmentReportsMutation, UpsertAlignmentReportsMutationVariables>(UpsertAlignmentReportsDocument, baseOptions);
      }
export type UpsertAlignmentReportsMutationHookResult = ReturnType<typeof useUpsertAlignmentReportsMutation>;
export type UpsertAlignmentReportsMutationResult = ApolloReactCommon.MutationResult<UpsertAlignmentReportsMutation>;
export type UpsertAlignmentReportsMutationOptions = ApolloReactCommon.BaseMutationOptions<UpsertAlignmentReportsMutation, UpsertAlignmentReportsMutationVariables>;
export const ListProviderAlignmentUploadsDocument = gql`
    query listProviderAlignmentUploads($DC_ID: String!) {
  cms_provider_alignment_reports(
    distinct_on: [PERFORMANCE_YEAR, ACO_ID, file_upload_id]
    where: {ACO_ID: {_eq: $DC_ID}}
    order_by: {ACO_ID: asc, PERFORMANCE_YEAR: desc, file_upload_id: desc}
  ) {
    ACO_ID
    PERFORMANCE_YEAR
    MBI_ID
    ALGN_TYPE_CLM
    PRVDR_NPI
    PRVDR_TIN
    updated_at
    file_upload_id
    file_upload {
      ...FileUploadBasic
    }
  }
}
    ${FileUploadBasicFragmentDoc}`;

/**
 * __useListProviderAlignmentUploadsQuery__
 *
 * To run a query within a React component, call `useListProviderAlignmentUploadsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListProviderAlignmentUploadsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListProviderAlignmentUploadsQuery({
 *   variables: {
 *      DC_ID: // value for 'DC_ID'
 *   },
 * });
 */
export function useListProviderAlignmentUploadsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ListProviderAlignmentUploadsQuery, ListProviderAlignmentUploadsQueryVariables>) {
        return ApolloReactHooks.useQuery<ListProviderAlignmentUploadsQuery, ListProviderAlignmentUploadsQueryVariables>(ListProviderAlignmentUploadsDocument, baseOptions);
      }
export function useListProviderAlignmentUploadsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ListProviderAlignmentUploadsQuery, ListProviderAlignmentUploadsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ListProviderAlignmentUploadsQuery, ListProviderAlignmentUploadsQueryVariables>(ListProviderAlignmentUploadsDocument, baseOptions);
        }
export type ListProviderAlignmentUploadsQueryHookResult = ReturnType<typeof useListProviderAlignmentUploadsQuery>;
export type ListProviderAlignmentUploadsLazyQueryHookResult = ReturnType<typeof useListProviderAlignmentUploadsLazyQuery>;
export type ListProviderAlignmentUploadsQueryResult = ApolloReactCommon.QueryResult<ListProviderAlignmentUploadsQuery, ListProviderAlignmentUploadsQueryVariables>;
export function refetchListProviderAlignmentUploadsQuery(variables?: ListProviderAlignmentUploadsQueryVariables) {
      return { query: ListProviderAlignmentUploadsDocument, variables: variables }
    }
export const UpsertProviderAlignmentReportsDocument = gql`
    mutation upsertProviderAlignmentReports($objects: [cms_provider_alignment_reports_insert_input!]!, $cols_to_update: [cms_provider_alignment_reports_update_column!]!) {
  insert_cms_provider_alignment_reports(
    objects: $objects
    on_conflict: {constraint: cms_provider_alignment_reports_pkey, update_columns: $cols_to_update}
  ) {
    affected_rows
  }
}
    `;
export type UpsertProviderAlignmentReportsMutationFn = ApolloReactCommon.MutationFunction<UpsertProviderAlignmentReportsMutation, UpsertProviderAlignmentReportsMutationVariables>;

/**
 * __useUpsertProviderAlignmentReportsMutation__
 *
 * To run a mutation, you first call `useUpsertProviderAlignmentReportsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertProviderAlignmentReportsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertProviderAlignmentReportsMutation, { data, loading, error }] = useUpsertProviderAlignmentReportsMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *      cols_to_update: // value for 'cols_to_update'
 *   },
 * });
 */
export function useUpsertProviderAlignmentReportsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpsertProviderAlignmentReportsMutation, UpsertProviderAlignmentReportsMutationVariables>) {
        return ApolloReactHooks.useMutation<UpsertProviderAlignmentReportsMutation, UpsertProviderAlignmentReportsMutationVariables>(UpsertProviderAlignmentReportsDocument, baseOptions);
      }
export type UpsertProviderAlignmentReportsMutationHookResult = ReturnType<typeof useUpsertProviderAlignmentReportsMutation>;
export type UpsertProviderAlignmentReportsMutationResult = ApolloReactCommon.MutationResult<UpsertProviderAlignmentReportsMutation>;
export type UpsertProviderAlignmentReportsMutationOptions = ApolloReactCommon.BaseMutationOptions<UpsertProviderAlignmentReportsMutation, UpsertProviderAlignmentReportsMutationVariables>;
export const ListHedrTemplateUploadsDocument = gql`
    query listHEDRTemplateUploads($DC_ID: String!) {
  hedr_submission_templates(
    distinct_on: [entity_id, performance_year, file_upload_id]
    where: {entity_id: {_eq: $DC_ID}}
    order_by: {entity_id: asc, performance_year: desc, file_upload_id: desc}
  ) {
    entity_id
    performance_year
    mbi
    created_at
    file_upload_id
    file_upload {
      ...FileUploadBasic
    }
  }
}
    ${FileUploadBasicFragmentDoc}`;

/**
 * __useListHedrTemplateUploadsQuery__
 *
 * To run a query within a React component, call `useListHedrTemplateUploadsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListHedrTemplateUploadsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListHedrTemplateUploadsQuery({
 *   variables: {
 *      DC_ID: // value for 'DC_ID'
 *   },
 * });
 */
export function useListHedrTemplateUploadsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ListHedrTemplateUploadsQuery, ListHedrTemplateUploadsQueryVariables>) {
        return ApolloReactHooks.useQuery<ListHedrTemplateUploadsQuery, ListHedrTemplateUploadsQueryVariables>(ListHedrTemplateUploadsDocument, baseOptions);
      }
export function useListHedrTemplateUploadsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ListHedrTemplateUploadsQuery, ListHedrTemplateUploadsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ListHedrTemplateUploadsQuery, ListHedrTemplateUploadsQueryVariables>(ListHedrTemplateUploadsDocument, baseOptions);
        }
export type ListHedrTemplateUploadsQueryHookResult = ReturnType<typeof useListHedrTemplateUploadsQuery>;
export type ListHedrTemplateUploadsLazyQueryHookResult = ReturnType<typeof useListHedrTemplateUploadsLazyQuery>;
export type ListHedrTemplateUploadsQueryResult = ApolloReactCommon.QueryResult<ListHedrTemplateUploadsQuery, ListHedrTemplateUploadsQueryVariables>;
export function refetchListHedrTemplateUploadsQuery(variables?: ListHedrTemplateUploadsQueryVariables) {
      return { query: ListHedrTemplateUploadsDocument, variables: variables }
    }
export const InsertHedrTemplateDocument = gql`
    mutation insertHEDRTemplate($objects: [hedr_submission_templates_insert_input!]!) {
  insert_hedr_submission_templates(objects: $objects) {
    affected_rows
  }
}
    `;
export type InsertHedrTemplateMutationFn = ApolloReactCommon.MutationFunction<InsertHedrTemplateMutation, InsertHedrTemplateMutationVariables>;

/**
 * __useInsertHedrTemplateMutation__
 *
 * To run a mutation, you first call `useInsertHedrTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertHedrTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertHedrTemplateMutation, { data, loading, error }] = useInsertHedrTemplateMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useInsertHedrTemplateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<InsertHedrTemplateMutation, InsertHedrTemplateMutationVariables>) {
        return ApolloReactHooks.useMutation<InsertHedrTemplateMutation, InsertHedrTemplateMutationVariables>(InsertHedrTemplateDocument, baseOptions);
      }
export type InsertHedrTemplateMutationHookResult = ReturnType<typeof useInsertHedrTemplateMutation>;
export type InsertHedrTemplateMutationResult = ApolloReactCommon.MutationResult<InsertHedrTemplateMutation>;
export type InsertHedrTemplateMutationOptions = ApolloReactCommon.BaseMutationOptions<InsertHedrTemplateMutation, InsertHedrTemplateMutationVariables>;
export const GetPvaAuditLogDocument = gql`
    query getPVAAuditLog($pva_id: String!) {
  pvas_audit(where: {pva_id: {_eq: $pva_id}}, order_by: {action_tstamp_stm: asc}) {
    ...PVAAuditEntry
  }
}
    ${PvaAuditEntryFragmentDoc}`;

/**
 * __useGetPvaAuditLogQuery__
 *
 * To run a query within a React component, call `useGetPvaAuditLogQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPvaAuditLogQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPvaAuditLogQuery({
 *   variables: {
 *      pva_id: // value for 'pva_id'
 *   },
 * });
 */
export function useGetPvaAuditLogQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetPvaAuditLogQuery, GetPvaAuditLogQueryVariables>) {
        return ApolloReactHooks.useQuery<GetPvaAuditLogQuery, GetPvaAuditLogQueryVariables>(GetPvaAuditLogDocument, baseOptions);
      }
export function useGetPvaAuditLogLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPvaAuditLogQuery, GetPvaAuditLogQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetPvaAuditLogQuery, GetPvaAuditLogQueryVariables>(GetPvaAuditLogDocument, baseOptions);
        }
export type GetPvaAuditLogQueryHookResult = ReturnType<typeof useGetPvaAuditLogQuery>;
export type GetPvaAuditLogLazyQueryHookResult = ReturnType<typeof useGetPvaAuditLogLazyQuery>;
export type GetPvaAuditLogQueryResult = ApolloReactCommon.QueryResult<GetPvaAuditLogQuery, GetPvaAuditLogQueryVariables>;
export function refetchGetPvaAuditLogQuery(variables?: GetPvaAuditLogQueryVariables) {
      return { query: GetPvaAuditLogDocument, variables: variables }
    }
export const GetHedrAuditLogDocument = gql`
    query getHEDRAuditLog($health_equity_report_id: String!) {
  health_equity_reports_audit(
    where: {health_equity_report_id: {_eq: $health_equity_report_id}}
    order_by: {action_tstamp_stm: asc}
  ) {
    ...HEDRAuditEntry
  }
}
    ${HedrAuditEntryFragmentDoc}`;

/**
 * __useGetHedrAuditLogQuery__
 *
 * To run a query within a React component, call `useGetHedrAuditLogQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHedrAuditLogQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHedrAuditLogQuery({
 *   variables: {
 *      health_equity_report_id: // value for 'health_equity_report_id'
 *   },
 * });
 */
export function useGetHedrAuditLogQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetHedrAuditLogQuery, GetHedrAuditLogQueryVariables>) {
        return ApolloReactHooks.useQuery<GetHedrAuditLogQuery, GetHedrAuditLogQueryVariables>(GetHedrAuditLogDocument, baseOptions);
      }
export function useGetHedrAuditLogLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetHedrAuditLogQuery, GetHedrAuditLogQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetHedrAuditLogQuery, GetHedrAuditLogQueryVariables>(GetHedrAuditLogDocument, baseOptions);
        }
export type GetHedrAuditLogQueryHookResult = ReturnType<typeof useGetHedrAuditLogQuery>;
export type GetHedrAuditLogLazyQueryHookResult = ReturnType<typeof useGetHedrAuditLogLazyQuery>;
export type GetHedrAuditLogQueryResult = ApolloReactCommon.QueryResult<GetHedrAuditLogQuery, GetHedrAuditLogQueryVariables>;
export function refetchGetHedrAuditLogQuery(variables?: GetHedrAuditLogQueryVariables) {
      return { query: GetHedrAuditLogDocument, variables: variables }
    }
export const GetPatientAuditLogDocument = gql`
    query getPatientAuditLog($patient_id: String!) {
  patients_audit(
    where: {patient_id: {_eq: $patient_id}}
    order_by: {action_tstamp_stm: asc}
  ) {
    ...PatientAuditEntry
  }
}
    ${PatientAuditEntryFragmentDoc}`;

/**
 * __useGetPatientAuditLogQuery__
 *
 * To run a query within a React component, call `useGetPatientAuditLogQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPatientAuditLogQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPatientAuditLogQuery({
 *   variables: {
 *      patient_id: // value for 'patient_id'
 *   },
 * });
 */
export function useGetPatientAuditLogQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetPatientAuditLogQuery, GetPatientAuditLogQueryVariables>) {
        return ApolloReactHooks.useQuery<GetPatientAuditLogQuery, GetPatientAuditLogQueryVariables>(GetPatientAuditLogDocument, baseOptions);
      }
export function useGetPatientAuditLogLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPatientAuditLogQuery, GetPatientAuditLogQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetPatientAuditLogQuery, GetPatientAuditLogQueryVariables>(GetPatientAuditLogDocument, baseOptions);
        }
export type GetPatientAuditLogQueryHookResult = ReturnType<typeof useGetPatientAuditLogQuery>;
export type GetPatientAuditLogLazyQueryHookResult = ReturnType<typeof useGetPatientAuditLogLazyQuery>;
export type GetPatientAuditLogQueryResult = ApolloReactCommon.QueryResult<GetPatientAuditLogQuery, GetPatientAuditLogQueryVariables>;
export function refetchGetPatientAuditLogQuery(variables?: GetPatientAuditLogQueryVariables) {
      return { query: GetPatientAuditLogDocument, variables: variables }
    }