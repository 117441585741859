import React, { ReactNode, useContext, useEffect, useState } from 'react';

import { Alert, Form, Modal, Select, Upload, Progress, message } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { InboxOutlined } from '@ant-design/icons';
import * as XLSX from 'xlsx';
import { UserContext } from '../../contexts/UserContext';
import { Storage } from 'aws-amplify';
import Papa from 'papaparse';

import {
  useCreateFileUploadMutation,
  useUpdateFileUploadMutation,
  useGetUserDetailsQuery,
  useInsertParticipantListsMutation,
  useInsertPvaResponseMutation,
  useListPvaResponseUploadLazyQuery,
  useUpsertAlignmentReportsMutation,
  useUpsertProviderAlignmentReportsMutation,
  useListHedrTemplateUploadsLazyQuery,
  useInsertHedrTemplateMutation,
} from '../../graphql/generated';
import { useRequest } from 'ahooks';
import dayjs from 'dayjs';

const CMSUploadModal: React.FC<{
  network_id: string;
  dc_id: string;
  fileCategory?:
    | 'hedr_submission_templates'
    | 'cms_alignment_reports'
    | 'cms_provider_alignment_reports'
    | 'cms_dce_participants'
    | 'cms_pva_responses';
  visible: boolean;
  onCancel: () => void;
  onUpload: (file: File) => void;
}> = ({ network_id, dc_id, fileCategory, visible, onCancel, onUpload }) => {
  const { user } = useContext(UserContext);
  const userDetailsQuery = useGetUserDetailsQuery({
    variables: { user_id: user?.user_id },
  });
  const [form] = useForm();
  const [batchProgress, setBatchProgress] = useState<number | null>(null);
  const [fileValidationMessage, setFileValidationMessage] = useState<{
    error?: string | ReactNode;
    warning?: string;
    numRows?: number;
  } | null>(null);

  const [
    createFileUploadMutation,
    createFileUploadMutationResult,
  ] = useCreateFileUploadMutation();
  const [updateFileUploadMutation] = useUpdateFileUploadMutation();

  const [upsertAlignmentReportsMutation] = useUpsertAlignmentReportsMutation();
  const [
    upsertProviderAlignmentReportsMutation,
  ] = useUpsertProviderAlignmentReportsMutation();
  const [insertPVAResponsesMutation] = useInsertPvaResponseMutation();
  const [insertParticipantListMutation] = useInsertParticipantListsMutation();
  const [insertHedrTemplateMutation] = useInsertHedrTemplateMutation();

  const [
    listPvaResponseUploadQuery,
    listPvaResponseUploadQueryResult,
  ] = useListPvaResponseUploadLazyQuery();

  const [
    listHedrTemplateUploadsQuery,
    listHedrTemplateUploadsQueryResult,
  ] = useListHedrTemplateUploadsLazyQuery();

  // If fileCategory is cms_pva_responses, then we need to check for any existing upload SUBMISSION_DATES
  useEffect(() => {
    if (fileCategory === 'cms_pva_responses') {
      listPvaResponseUploadQuery({
        variables: { DC_ID: dc_id },
      });
    }
    if (fileCategory === 'hedr_submission_templates') {
      listHedrTemplateUploadsQuery({
        variables: { DC_ID: dc_id },
      });
    }
  }, [
    fileCategory,
    dc_id,
    listPvaResponseUploadQuery,
    listHedrTemplateUploadsQuery,
  ]);

  const normalizeString = (str: string | null | undefined) => {
    if (!str) {
      return str;
    }
    return str
      .replace(/\u00A0/g, ' ') // Replace NBSP with space
      .replace(/[\u200B-\u200D\uFEFF]/g, '') // Remove zero-width spaces
      .replace(/[\u2013\u2014]/g, '-') // Normalize dashes
      .replace(/[""]/g, '"') // Normalize double quotes
      .replace(/['']/g, "'") // Normalize single quotes
      .replace(/…/g, '...') // Normalize ellipsis
      .trim(); // Remove leading and trailing spaces
  };

  const handleSubmit = async () => {
    const result = await form.validateFields();
    if (result) {
      if (fileCategory === 'cms_alignment_reports') {
        await loadAlignmentReport(
          result.file_details.file.originFileObj,
          result.performance_year
        );
      } else if (fileCategory === 'cms_provider_alignment_reports') {
        await loadProviderAlignmentReport(
          result.file_details.file.originFileObj,
          result.performance_year
        );
      } else if (fileCategory === 'cms_pva_responses') {
        await loadPVAResponses(
          result.file_details.file.originFileObj,
          result.SUBMISSION_DATE
        );
      } else if (fileCategory === 'cms_dce_participants') {
        await loadDCEParticipants(result.file_details.file.originFileObj);
      } else if (fileCategory === 'hedr_submission_templates') {
        await loadHEDRTemplate(
          result.file_details.file.originFileObj,
          result.performance_year
        );
      }
      onUpload(result.file_details.file.originFileObj);
      form.resetFields();
      setBatchProgress(null);
      setFileValidationMessage(null);
    }
  };

  const {
    run: handleSubmitWithRequest,
    loading: handleSubmitLoading,
  } = useRequest(handleSubmit, {
    manual: true,
    onError: (error) => {
      console.error(error);
      message.error('An error occurred while processing the file');
    },
  });

  const allowed_csv_types = [
    'text/plain',
    'text/x-csv',
    'application/vnd.ms-excel',
    'application/csv',
    'application/x-csv',
    'text/csv',
    'text/comma-separated-values',
    'text/x-comma-separated-values',
    'text/tab-separated-values',
  ];
  const allowed_xlsx_types = [
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.ms-excel',
  ];
  const cms_alignment_reports_cols = [
    'Beneficiary MBI ID',
    'Beneficiary Alignment Effective Start Date',
    'Beneficiary Alignment Effective Termination Date',
    'Beneficiary First Name',
    'Beneficiary Last Name',
    'Beneficiary Line 1 Address',
    'Beneficiary Line 2 Address',
    'Beneficiary Line 3 Address',
    'Beneficiary Line 4 Address',
    'Beneficiary Line 5 Address',
    'Beneficiary Line 6 Address',
    'Beneficiary City',
    'Beneficiary USPS State Code',
    'Beneficiary Zip 5',
    'Beneficiary Zip 4',
    'Beneficiary State-County of Residence SSA',
    'Beneficiary State-County of Residence FIPS',
    'Beneficiary Gender',
    'Race-Ethnicity',
    'Beneficiary Date of Birth',
    'Beneficiary Age',
    'Beneficiary Date of Death',
    'Beneficiary Eligibility Alignment Year 1',
    'Beneficiary Eligibility Alignment Year 2',
    'Beneficiary Part D Coverage Alignment Year 1',
    'Beneficiary Part D Coverage Alignment Year 2',
    'Newly Aligned Beneficiary Flag',
    'Prospective Plus Alignment',
    'Claim Based Alignment Indicator',
    'Voluntary Alignment Type',
    'Mobility Impairment Indicator',
    'Frailty Indicator',
    'Medium Risk with Unplanned Admissions Indicator',
    'High Risk Score Indicator',
  ];
  const cms_alignment_reports_db_cols = [
    'beneficiary_mbi_id',
    'beneficiary_alignment_effective_start_date',
    'beneficiary_alignment_effective_termination_date',
    'beneficiary_first_name',
    'beneficiary_last_name',
    'beneficiary_line_1_address',
    'beneficiary_line_2_address',
    'beneficiary_line_3_address',
    'beneficiary_line_4_address',
    'beneficiary_line_5_address',
    'beneficiary_line_6_address',
    'beneficiary_city',
    'beneficiary_usps_state_code',
    'beneficiary_zip_5',
    'beneficiary_zip_4',
    'beneficiary_state_county_of_residence_ssa',
    'beneficiary_state_county_of_residence_fips',
    'beneficiary_gender',
    'race_ethnicity',
    'beneficiary_date_of_birth',
    'beneficiary_age',
    'beneficiary_date_of_death',
    'beneficiary_eligibility_alignment_year_1',
    'beneficiary_eligibility_alignment_year_2',
    'beneficiary_part_d_coverage_alignment_year_1',
    'beneficiary_part_d_coverage_alignment_year_2',
    'newly_aligned_beneficiary_flag',
    'prospective_plus_alignment',
    'claim_based_alignment_indicator',
    'voluntary_alignment_type',
    'mobility_impairment_indicator',
    'frailty_indicator',
    'medium_risk_with_unplanned_admissions_indicator',
    'high_risk_score_indicator',
  ];
  const cms_provider_alignment_report_cols = [
    'ACO_ID',
    'MBI_ID',
    'ALGN_TYPE_CLM',
    'ALGN_TYPE_VA',
    'PRVDR_TIN',
    'PRVDR_NPI',
    'FAC_PRVDR_OSCAR_NUM',
    'QEM_ALLOWED_PRIMARY_AY1',
    'QEM_ALLOWED_NONPRIMARY_AY1',
    'QEM_ALLOWED_OTHER_AY1',
    'QEM_ALLOWED_PRIMARY_AY2',
    'QEM_ALLOWED_NONPRIMARY_AY2',
    'QEM_ALLOWED_OTHER_AY2',
  ];
  const cms_pva_responses_cols = [
    'ACO_ID',
    'RESPONSE_CODE_LIST',
    'ID_RECEIVED',
    'BENE_MBI',
    'BENE_FIRST_NAME',
    'BENE_LAST_NAME',
    'BENE_LINE_1_ADDRESS',
    'BENE_LINE_2_ADDRESS',
    'BENE_CITY',
    'BENE_STATE',
    'BENE_ZIPCODE',
    'PROVIDER_NAME',
    'PRACTITIONER_NAME',
    'IND_NPI',
    'IND_TIN',
    'SIGNATURE_DATE',
  ];
  const cms_dce_participants_cols = [
    'Entity ID',
    'Entity TIN',
    'Entity Legal Business Name',
    'Performance Year',
    'Provider Type',
    'Provider Class',
    'Provider Legal Business Name',
    'Individual NPI',
    'Base Provider TIN',
    'Organization NPI',
    'CCN',
    'Sole Proprietor',
    'Sole Proprietor TIN',
    'Primary Care Services',
    'Specialty',
    'Base Provider TIN Status',
    'Base Provider TIN Dropped/Terminated Reason',
    'Effective Start Date',
    'Effective End Date',
    'Last Updated Date',
    'Overlaps/Deficiencies',
    'Attestation (Y/N)',
    'Total Care Capitation % Reduction',
    'Primary Care Capitation % Reduction',
    'Advanced Payment % Reduction',
    'Cardiac and Pulmonary Rehabilitation',
    'Care Management Home Visit',
    'Concurrent Care for Hospice Beneficiaries',
    'Chronic Disease Management Reward (BEI)',
    'Cost Sharing for Part B Services (BEI)',
    'Diabetic Shoes',
    'Home Health Homebound Waiver',
    'Home Infusion Therapy',
    'Hospice Care Certification',
    'Medical Nutrition Therapy',
    'Nurse Practitioner Services',
    'Post Discharge Home Visit',
    'Skilled Nursing Facility (SNF) 3-Day Stay Waiver',
    'Telehealth',
    'Email',
  ];
  const cms_dce_participants_db_cols = [
    'entity_id',
    'entity_tin',
    'entity_legal_business_name',
    'performance_year',
    'participant_type',
    'provider_class',
    'legal_business_name',
    'individual_npi',
    'billing_tin',
    'organizational_npi',
    'ccn',
    'sole_proprietor',
    'sole_proprietor_tin',
    'primary_care_services',
    'specialty',
    'billing_tin_current_status',
    'billing_tin_deficiency',
    'effective_start_date',
    'effective_end_date',
    'last_updated',
    'overlaps_deficiencies',
    'attestation_y_n',
    'total_care_capitation_percent_reduction',
    'primary_care_capitation_percent_reduction',
    'advanced_payment_percent_reduction',
    'cardiac_and_pulmonary_rehabilitation',
    'care_management_home_visit',
    'concurrent_care_for_hospice_beneficiaries',
    'chronic_disease_management_reward_bei',
    'cost_sharing_for_part_b_services_bei',
    'diabetic_shoes',
    'home_health_homebound_waiver',
    'home_infusion_therapy',
    'hospice_care_certification',
    'medical_nutrition_therapy',
    'nurse_practitioner_services',
    'post_discharge_home_visit',
    'skilled_nursing_facility_snf_3day_stay_waiver',
    'telehealth',
    'email',
  ];
  const hedr_template_cols = [
    'model_id',
    'entity_id',
    'mbi',
    'first_name',
    'last_name',
    'sex_assigned_at_birth',
    'date_of_birth',
  ];

  const validateAlignmentReport = async (
    file: File,
    performance_year: number | undefined
  ) => {
    // Check that performance year is selected
    if (!performance_year) {
      setFileValidationMessage({ error: 'Please select a performance year' });
      return Upload.LIST_IGNORE;
    }

    // Check that file is the right type
    if (!allowed_xlsx_types.includes(file.type)) {
      setFileValidationMessage({
        error: `${fileCategory} expects an Excel file`,
      });
      return Upload.LIST_IGNORE;
    }

    // Read file and read all cell values as strings
    const data = await file.arrayBuffer();
    const workbook = XLSX.read(data, {
      type: 'binary',
      cellText: false,
      cellDates: true,
    });

    // check that the first worksheet has the name "Overview ({dc_id})"
    const firstSheetName = normalizeString(workbook.SheetNames[0]);
    if (firstSheetName !== `Overview (${dc_id})`) {
      setFileValidationMessage({
        error: `The first sheet should be named "Overview (${dc_id})"`,
      });
      return Upload.LIST_IGNORE;
    }

    // check that the first row contains the right columns
    const worksheet = workbook.Sheets[firstSheetName];
    // turn the worksheet into a 2D array of strings
    const rows = XLSX.utils.sheet_to_json<string[]>(worksheet, {
      header: 1,
      defval: null,
      blankrows: false,
      raw: false,
      dateNF: 'yyyy-mm-dd',
    });
    // make sure to normalize all strings
    const headerRow = rows[0].map(normalizeString);
    const dataRows = rows
      .slice(1)
      .map((row) => row.map((value) => normalizeString(value) || null));
    setFileValidationMessage({
      numRows: dataRows.length,
    });

    // Check if the header row starts with the expected headers
    for (let i = 0; i < cms_alignment_reports_cols.length; i++) {
      if (headerRow[i] !== cms_alignment_reports_cols[i]) {
        setFileValidationMessage({
          error: `The header row expected field: ${cms_alignment_reports_cols[i]}, but encountered: ${headerRow[i]}.`,
        });

        return Upload.LIST_IGNORE;
      }
    }

    // Check that all values of "Beneficiary Alignment Effective Start Date"
    // are in the performance_year
    for (let i = 0; i < dataRows.length; i++) {
      const date = dataRows[i][1];
      if (date && parseInt(date.substring(0, 4)) !== performance_year) {
        setFileValidationMessage({
          error: `The "Beneficiary Alignment Effective Start Date" column contains a value ${date} not in Performance Year ${performance_year}`,
        });
        return Upload.LIST_IGNORE;
      }
    }

    return true;
  };

  const validateProviderAlignmentReport = async (
    file: File,
    performance_year: number | undefined
  ) => {
    // Check that the performance year is selected
    if (!performance_year) {
      setFileValidationMessage({ error: 'Please select a performance year' });
      return Upload.LIST_IGNORE;
    }

    // Check that the file is the right type (csv)
    if (!allowed_csv_types.includes(file.type)) {
      setFileValidationMessage({
        error: `${fileCategory} expects a CSV file`,
      });
      return Upload.LIST_IGNORE;
    }

    // Read file as CSV using papa parse
    const textData = await file.text();
    const result = Papa.parse(textData, {
      header: true,
      skipEmptyLines: true,
      transformHeader: (header) => {
        if (header === 'PRVDR_TIN (CLM OR VA)') {
          return 'PRVDR_TIN';
        }
        if (header === 'PRVDR_NPI (CLM OR VA)') {
          return 'PRVDR_NPI';
        }
        return header;
      },
      transform: (value, header) => {
        if (value === '') {
          return null; // convert empty strings to null
        }
        if (header.toString().startsWith('QEM')) {
          return parseFloat(value); // convert QEM columns to numbers
        }
        return value;
      },
    });
    if (result.errors.length > 0) {
      setFileValidationMessage({
        error: `Error parsing CSV file: ${result.errors
          .map((e) => e.message)
          .join(', ')}`,
      });
      return Upload.LIST_IGNORE;
    }
    setFileValidationMessage({
      numRows: result.data.length,
    });

    // Check that there is a header row with the expected columns, in order
    const headerRow = result.meta.fields;
    if (!headerRow) {
      setFileValidationMessage({
        error: 'The file does not have a header row',
      });
      return Upload.LIST_IGNORE;
    } else {
      for (let i = 0; i < cms_provider_alignment_report_cols.length; i++) {
        if (headerRow[i] !== cms_provider_alignment_report_cols[i]) {
          setFileValidationMessage({
            error: `The header row is missing the column ${cms_provider_alignment_report_cols[i]}`,
          });
          return Upload.LIST_IGNORE;
        }
      }
    }

    // Check that all values of the "ACO_ID" column are equal to dc_id
    for (let i = 0; i < result.data.length; i++) {
      const aco_id = (result.data[i] as any)['ACO_ID'];
      if (aco_id !== dc_id) {
        setFileValidationMessage({
          error: `The "ACO_ID" column contains a value ${aco_id} that is not equal to ${dc_id}`,
        });
        return Upload.LIST_IGNORE;
      }
    }

    // Check that all non-empty values of PRVDR_TIN are 9 chars long
    for (let i = 0; i < result.data.length; i++) {
      const tin = (result.data[i] as any)['PRVDR_TIN'];
      if (tin && tin.length !== 9) {
        setFileValidationMessage({
          error: `The PRVDR_TIN column contains a value ${tin} that is not 9 characters long. Did you accidentally strip leading zeros?`,
        });
        return Upload.LIST_IGNORE;
      }
    }

    return true;
  };

  const validatePVAResponses = async (
    file: File,
    SUBMISSION_DATE: string | undefined
  ) => {
    // Check that the submission date is selected
    if (!SUBMISSION_DATE) {
      setFileValidationMessage({ error: 'Please select a submission date' });
      return Upload.LIST_IGNORE;
    }

    // Check that the file is the right type (Excel)
    if (!allowed_xlsx_types.includes(file.type)) {
      setFileValidationMessage({
        error: `${fileCategory} expects an Excel file`,
      });
      return Upload.LIST_IGNORE;
    }

    // Read file and read all cell values as strings
    const data = await file.arrayBuffer();
    const workbook = XLSX.read(data, {
      type: 'binary',
      cellText: false,
      cellDates: true,
    });

    const firstSheetName = workbook.SheetNames[0];
    const worksheet = workbook.Sheets[firstSheetName];
    const rows = XLSX.utils.sheet_to_json<string[]>(worksheet, {
      header: 1,
      defval: null,
      blankrows: false,
      raw: false,
      dateNF: 'yyyy-mm-dd',
    });
    // make sure to normalize all strings
    const headerRow = rows[0].map(normalizeString);
    const dataRows = rows
      .slice(1)
      .map((row) => row.map((value) => normalizeString(value) || null));
    setFileValidationMessage({
      numRows: dataRows.length,
    });

    // Check if the header row starts with the expected headers
    for (let i = 0; i < cms_pva_responses_cols.length; i++) {
      if (headerRow[i] !== cms_pva_responses_cols[i]) {
        setFileValidationMessage({
          error: `The header row expected field: ${cms_pva_responses_cols[i]}, but encountered: ${headerRow[i]}.`,
        });

        return Upload.LIST_IGNORE;
      }
    }

    // Check that all values of the "ACO_ID" column are equal to dc_id
    for (let i = 0; i < dataRows.length; i++) {
      const aco_id = dataRows[i][0];
      if (aco_id !== dc_id) {
        setFileValidationMessage({
          error: `The "ACO_ID" column contains a value ${aco_id} that is not equal to ${dc_id}`,
        });
        return Upload.LIST_IGNORE;
      }
    }

    // Raise error if the signature SIGNATURE_DATE column contains a non-date
    const signature_dates = dataRows.map((row) => row[15]);
    for (let i = 0; i < signature_dates.length; i++) {
      if (
        signature_dates[i] &&
        isNaN(Date.parse(signature_dates[i] as string))
      ) {
        setFileValidationMessage({
          error: `The SIGNATURE_DATE column contains an invalid date: ${signature_dates[i]}`,
        });
        return Upload.LIST_IGNORE;
      }
    }

    // Raise an error if any of the SIGNATURE_DATEs are later than the submission date
    for (let i = 0; i < signature_dates.length; i++) {
      // signature_date[i] is in m/d/yyyy format so convert to YYYY-MM-DD format
      const signatureDate = dayjs(signature_dates[i]!, 'M/D/YYYY').format(
        'YYYY-MM-DD'
      );
      if (signatureDate > SUBMISSION_DATE) {
        setFileValidationMessage({
          error: `The SIGNATURE_DATE column contains a value ${signature_dates[i]} that is later than the Submission Date of ${SUBMISSION_DATE}`,
        });
        return Upload.LIST_IGNORE;
      }
    }

    // Warn if the most recent date in the SIGNATURE_DATE column is more than 3 months before the submission date
    const mostRecentSignatureDate = new Date(
      Math.max(
        ...signature_dates.map((date) => new Date(date as string).getTime())
      )
    );
    const submissionDate = new Date(SUBMISSION_DATE);
    const threeMonthsBeforeSubmission = new Date(submissionDate);
    threeMonthsBeforeSubmission.setMonth(submissionDate.getMonth() - 3);
    if (mostRecentSignatureDate < threeMonthsBeforeSubmission) {
      setFileValidationMessage({
        numRows: dataRows.length,
        warning: `Warning: the most recent signature date in this file is ${mostRecentSignatureDate.toDateString()}, which is more than 3 months before the chosen Submission Date of ${SUBMISSION_DATE}`,
      });
    }

    // Raise an error if duplicate primary keys exist based on
    // 'ID_RECEIVED', 'IND_NPI', 'IND_TIN', 'SIGNATURE_DATE'
    const primaryKeys = dataRows.map((row) => [
      row[2], // ID_RECEIVED
      row[13], // IND_NPI
      row[14], // IND_TIN
      row[15], // SIGNATURE_DATE
    ]);
    // Check for duplicates in O(n) time
    const seenKeys = new Set();
    const duplicateRows = [];
    for (const key of primaryKeys) {
      const keyString = key.join('|');
      if (seenKeys.has(keyString)) {
        duplicateRows.push(
          `ID_RECEIVED: ${key[0]}, IND_NPI: ${key[1]}, IND_TIN: ${key[2]}, SIGNATURE_DATE: ${key[3]}`
        );
      } else {
        seenKeys.add(keyString);
      }
    }
    if (duplicateRows.length > 0) {
      setFileValidationMessage({
        error: (
          <>
            Duplicate rows found:
            <ul>
              {duplicateRows.map((row, index) => (
                <li key={index}>{row}</li>
              ))}
            </ul>
          </>
        ),
      });
      return Upload.LIST_IGNORE;
    }

    return true;
  };

  const validateDCEParticipants = async (file: File) => {
    // Check that file is the right type
    if (!allowed_xlsx_types.includes(file.type)) {
      setFileValidationMessage({
        error: `${fileCategory} expects an Excel file`,
      });
      return Upload.LIST_IGNORE;
    }

    // Read file and read all cell values as strings
    const data = await file.arrayBuffer();
    const workbook = XLSX.read(data, {
      type: 'binary',
      cellText: false,
      cellDates: true,
    });

    // check that the first row contains the right columns
    const firstSheetName = workbook.SheetNames[0];
    const worksheet = workbook.Sheets[firstSheetName];
    const rows = XLSX.utils.sheet_to_json<string[]>(worksheet, {
      header: 1,
      defval: null,
      blankrows: false,
      raw: false,
      dateNF: 'yyyy-mm-dd',
    });
    // make sure to normalize all strings
    const headerRow = rows[0].map(normalizeString);
    const dataRows = rows
      .slice(1)
      .map((row) => row.map((value) => normalizeString(value) || null));
    setFileValidationMessage({
      numRows: dataRows.length,
    });

    // Check that the header row contains each of the expected columns (not necessarily in order)
    const headerSet = new Set(headerRow);
    for (let i = 0; i < cms_dce_participants_cols.length; i++) {
      if (!headerSet.has(cms_dce_participants_cols[i])) {
        setFileValidationMessage({
          error: `The header row is missing the column ${cms_dce_participants_cols[i]}`,
        });
        return Upload.LIST_IGNORE;
      }
    }

    // Check that all values of the "Entity ID" column are equal to dc_id
    for (let i = 0; i < dataRows.length; i++) {
      const entity_id = dataRows[i][0];
      if (entity_id !== dc_id) {
        setFileValidationMessage({
          error: `The "Entity ID" column contains a value ${entity_id} that is not equal to ${dc_id}`,
        });
        return Upload.LIST_IGNORE;
      }
    }

    return true;
  };

  const validateHEDRTemplate = async (file: File, performance_year: number) => {
    // Check that the performance year is selected
    if (!performance_year) {
      setFileValidationMessage({ error: 'Please select a performance year' });
      return Upload.LIST_IGNORE;
    }

    // Check that the file is the right type (Excel)
    if (!allowed_xlsx_types.includes(file.type)) {
      setFileValidationMessage({
        error: `${fileCategory} expects an Excel file`,
      });
      return Upload.LIST_IGNORE;
    }

    // Read file and read all cell values as strings
    const data = await file.arrayBuffer();
    const workbook = XLSX.read(data, {
      type: 'binary',
      cellText: false,
      cellDates: true,
    });

    // Check that one of the sheets is named Demographic, and get that sheet
    const demographicSheetIndex = workbook.SheetNames.findIndex(
      (sheetName) => sheetName === 'Demographic'
    );
    if (demographicSheetIndex === -1) {
      setFileValidationMessage({
        error: 'The file does not contain a sheet named "Demographic"',
      });
      return Upload.LIST_IGNORE;
    }

    // Check that the first row of the Demographic sheet contains the right columns
    const demographicSheet = workbook.Sheets['Demographic'];
    const demographicRows = XLSX.utils.sheet_to_json<string[]>(
      demographicSheet,
      {
        header: 1,
        defval: null,
        blankrows: false,
        raw: false,
        dateNF: 'yyyy-mm-dd',
      }
    );
    // make sure to normalize all strings
    const demographicHeaderRow = demographicRows[0].map(normalizeString);
    const demographicDataRows = demographicRows
      .slice(1)
      .map((row) => row.map((value) => normalizeString(value) || null));
    setFileValidationMessage({
      numRows: demographicDataRows.length,
    });

    // Check if the header row starts with the expected headers
    for (let i = 0; i < hedr_template_cols.length; i++) {
      if (demographicHeaderRow[i] !== hedr_template_cols[i]) {
        setFileValidationMessage({
          error: `The header row expected field: ${hedr_template_cols[i]}, but encountered: ${demographicHeaderRow[i]}.`,
        });

        return Upload.LIST_IGNORE;
      }
    }

    // Check that all values of the "entity_id" column are equal to dc_id
    for (let i = 0; i < demographicDataRows.length; i++) {
      const entity_id = demographicDataRows[i][1];
      if (entity_id !== dc_id) {
        setFileValidationMessage({
          error: `The "entity_id" column contains a value ${entity_id} that is not equal to ${dc_id}`,
        });
        return Upload.LIST_IGNORE;
      }
    }

    return true;
  };

  const processInBatches = async (
    batchSize: number,
    asyncFunction: (args: any) => Promise<any>,
    data: any[],
    cols_to_update?: string[],
    num_parallel: number = 0
  ) => {
    const fileUploadId =
      createFileUploadMutationResult.data?.insert_file_uploads_one
        ?.file_upload_id;
    if (fileUploadId) {
      await updateFileUploadMutation({
        variables: {
          file_upload_id: fileUploadId,
          changes: {
            upload_status: 'Uploading',
          },
        },
      });
    }

    const totalBatches = Math.ceil(data.length / batchSize);

    const runBatch = async (batchIndex: number) => {
      const batchStart = batchIndex * batchSize;
      const batchEnd = batchStart + batchSize;
      const batchData = data.slice(batchStart, batchEnd);

      const variables: any = { objects: batchData };
      if (cols_to_update) {
        variables.cols_to_update = cols_to_update;
      }
      await asyncFunction({ variables });
    };

    setBatchProgress(0);
    if (num_parallel > 0) {
      const parallelBatches = Array.from({ length: num_parallel }, (_, i) => i);
      for (
        let batchIndex = 0;
        batchIndex < totalBatches;
        batchIndex += num_parallel
      ) {
        await Promise.all(
          parallelBatches.map((i) => {
            const currentBatchIndex = batchIndex + i;
            if (currentBatchIndex < totalBatches) {
              return runBatch(currentBatchIndex);
            }
            return Promise.resolve();
          })
        );
        setBatchProgress(
          Math.round(((batchIndex + num_parallel) / totalBatches) * 100)
        );
      }
    } else {
      for (let batchIndex = 0; batchIndex < totalBatches; batchIndex++) {
        await runBatch(batchIndex);
        setBatchProgress(Math.round(((batchIndex + 1) / totalBatches) * 100));
      }
    }

    if (fileUploadId) {
      await updateFileUploadMutation({
        variables: {
          file_upload_id: fileUploadId,
          changes: {
            upload_status: 'Completed',
          },
        },
      });
    }
  };

  const loadAlignmentReport = async (file: File, performance_year: number) => {
    const isValid = await validateAlignmentReport(file, performance_year);
    if (isValid !== true) {
      return Promise.reject(new Error('File validation failed'));
    }

    // Upsert all file values to the database
    const data = await file.arrayBuffer();
    const workbook = XLSX.read(data, {
      type: 'binary',
      cellText: false,
      cellDates: true,
    });
    const firstSheetName = workbook.SheetNames[0];
    const worksheet = workbook.Sheets[firstSheetName];
    const rows = XLSX.utils.sheet_to_json<string[]>(worksheet, {
      header: 1,
      defval: null,
      blankrows: false,
      raw: false,
      dateNF: 'yyyy-mm-dd',
    });
    const dataRows = rows
      .slice(1)
      .map((row) => row.map((value) => normalizeString(value) || null));
    const dataToUpload: {
      [k: string]: string | null | number;
    }[] = dataRows.map((row) =>
      Object.fromEntries(
        cms_alignment_reports_db_cols.map((header, index) => [
          header,
          row[index],
        ])
      )
    );
    // for each object add performance_year and dc_id
    dataToUpload.forEach((obj) => {
      obj.performance_year = performance_year;
      obj.dc_id = dc_id;
      obj.file_upload_id =
        createFileUploadMutationResult.data?.insert_file_uploads_one
          ?.file_upload_id || null;
    });

    await processInBatches(
      500,
      upsertAlignmentReportsMutation,
      dataToUpload,
      cms_alignment_reports_db_cols.concat('file_upload_id')
    );
  };

  const loadProviderAlignmentReport = async (
    file: File,
    performance_year: number
  ) => {
    const isValid = await validateProviderAlignmentReport(
      file,
      performance_year
    );
    if (isValid !== true) {
      return Promise.reject(new Error('File validation failed'));
    }

    // Upsert all file values to the database
    const data = await file.text();
    const result = Papa.parse(data, {
      header: true,
      skipEmptyLines: true,
      transformHeader: (header) => {
        if (header === 'PRVDR_TIN (CLM OR VA)') {
          return 'PRVDR_TIN';
        }
        if (header === 'PRVDR_NPI (CLM OR VA)') {
          return 'PRVDR_NPI';
        }
        return header;
      },
      transform: (value, header) => {
        // convert empty strings to null, except for PRVDR_TIN and FAC_PRVDR_OSCAR_NUM
        // (since they are part of the primary key)
        if (
          value === '' &&
          header.toString() !== 'PRVDR_TIN' &&
          header.toString() !== 'FAC_PRVDR_OSCAR_NUM'
        ) {
          return null;
        }
        if (header.toString().startsWith('QEM')) {
          return parseFloat(value); // convert QEM columns to numbers
        }
        return value;
      },
    });

    if (result.errors.length > 0) {
      return Promise.reject(new Error('Error parsing CSV file'));
    }

    // construct dataToUpload by adding the PERFORMANCE_YEAR value to each row
    // along with the file_upload_id
    const dataToUpload = result.data.map((row) => {
      return {
        ...(row as any),
        PERFORMANCE_YEAR: performance_year,
        file_upload_id:
          createFileUploadMutationResult.data?.insert_file_uploads_one
            ?.file_upload_id || null,
      };
    });

    await processInBatches(
      500,
      upsertProviderAlignmentReportsMutation,
      dataToUpload,
      cms_provider_alignment_report_cols.concat(
        'PERFORMANCE_YEAR',
        'file_upload_id'
      )
    );
  };

  const loadPVAResponses = async (file: File, SUBMISSION_DATE: string) => {
    const isValid = await validatePVAResponses(file, SUBMISSION_DATE);
    if (isValid !== true) {
      return Promise.reject(new Error('File validation failed'));
    }

    // Insert all file values to the database
    const data = await file.arrayBuffer();
    const workbook = XLSX.read(data, {
      type: 'binary',
      cellText: false,
      cellDates: true,
    });
    const firstSheetName = workbook.SheetNames[0];
    const worksheet = workbook.Sheets[firstSheetName];
    const rows = XLSX.utils.sheet_to_json<string[]>(worksheet, {
      header: 1,
      defval: null,
      blankrows: false,
      raw: false,
      dateNF: 'yyyy-mm-dd',
    });
    const headerrow = rows[0].map(normalizeString);
    const dataRows = rows
      .slice(1)
      .map((row) => row.map((value) => normalizeString(value) || null));

    // construct the data to upload by mapping the rows to objects
    // but change the "ACO_ID" column to "DCE_ID"
    // and add the columns "SUBMISSION_DATE" and file_upload_id
    const dataToUpload: {
      [k: string]: string | null;
    }[] = dataRows.map((row) =>
      Object.fromEntries(
        headerrow
          .map((header, i) => [header === 'ACO_ID' ? 'DCE_ID' : header, row[i]])
          .concat([
            ['SUBMISSION_DATE', SUBMISSION_DATE],
            [
              'file_upload_id',
              createFileUploadMutationResult.data?.insert_file_uploads_one
                ?.file_upload_id || null,
            ],
          ])
      )
    );

    await processInBatches(500, insertPVAResponsesMutation, dataToUpload);
  };

  const loadDCEParticipants = async (file: File) => {
    const isValid = await validateDCEParticipants(file);
    if (isValid !== true) {
      return Promise.reject(new Error('File validation failed'));
    }
    // Insert all file values to the database
    const data = await file.arrayBuffer();
    const workbook = XLSX.read(data, {
      type: 'binary',
      cellText: false,
      cellDates: true,
    });
    const firstSheetName = workbook.SheetNames[0];
    const worksheet = workbook.Sheets[firstSheetName];
    const rows = XLSX.utils.sheet_to_json<string[]>(worksheet, {
      header: 1,
      defval: null,
      blankrows: false,
      raw: false,
      dateNF: 'yyyy-mm-dd',
    });
    const headerrow = rows[0].map(normalizeString);
    const dataRows = rows
      .slice(1)
      .map((row) => row.map((value) => normalizeString(value) || null));

    // construct the data to upload by mapping the rows to objects
    // but use the cms_dce_participants_db_cols as the keys instead of cms_dce_participants_cols
    // In other words, if the column "Individual NPI" is the 5th column, use that but rename
    // it to "individual_npi" as the key. Note that the order of the columns in the file
    // may not match the order of the columns in the db
    const matchingColumnIndexes = cms_dce_participants_cols.map((col) =>
      headerrow.findIndex((header) => header === col)
    );
    const dataToUpload: {
      [k: string]: string | null | number;
    }[] = dataRows.map((row) =>
      Object.fromEntries(
        matchingColumnIndexes.map((index, i) => [
          cms_dce_participants_db_cols[i],
          row[index],
        ])
      )
    );

    // add the file upload id
    dataToUpload.forEach((obj) => {
      obj.file_upload_id =
        createFileUploadMutationResult.data?.insert_file_uploads_one
          ?.file_upload_id || null;
    });

    // Because of this issue: https://github.com/hasura/graphql-engine/issues/4633
    // We can't insert more than one item at a time because of the generated TIN column
    await processInBatches(
      1,
      insertParticipantListMutation,
      dataToUpload,
      undefined,
      50 //run 50 inserts in parallel, since can only do 1 at a time.
    );
  };

  const loadHEDRTemplate = async (file: File, performance_year: number) => {
    const isValid = await validateHEDRTemplate(file, performance_year);
    if (isValid !== true) {
      return Promise.reject(new Error('File validation failed'));
    }

    // Insert all file values to the database
    const data = await file.arrayBuffer();
    const workbook = XLSX.read(data, {
      type: 'binary',
      cellText: false,
      cellDates: true,
    });
    const demographicSheet = workbook.Sheets['Demographic'];
    const demographicRows = XLSX.utils.sheet_to_json<string[]>(
      demographicSheet,
      {
        header: 1,
        defval: null,
        blankrows: false,
        raw: false,
        dateNF: 'yyyy-mm-dd',
      }
    );
    const dataRows = demographicRows
      .slice(1)
      .map((row) => row.map((value) => normalizeString(value) || null));
    const dataToUpload: {
      [k: string]: string | null | number;
    }[] = dataRows.map((row) =>
      Object.fromEntries(
        hedr_template_cols.map((header, i) => [header, row[i]])
      )
    );
    // for each object add performance_year and row_number
    dataToUpload.forEach((obj, i) => {
      obj.performance_year = performance_year;
      obj.row_number = i + 1;
      obj.file_upload_id =
        createFileUploadMutationResult.data?.insert_file_uploads_one
          ?.file_upload_id || null;
    });

    await processInBatches(500, insertHedrTemplateMutation, dataToUpload);
  };

  const beforeUpload = async (file: File) => {
    setFileValidationMessage(null);
    if (fileCategory === 'cms_alignment_reports') {
      return await validateAlignmentReport(
        file,
        form.getFieldValue('performance_year')
      );
    } else if (fileCategory === 'cms_provider_alignment_reports') {
      return await validateProviderAlignmentReport(
        file,
        form.getFieldValue('performance_year')
      );
    } else if (fileCategory === 'cms_pva_responses') {
      return await validatePVAResponses(
        file,
        form.getFieldValue('SUBMISSION_DATE')
      );
    } else if (fileCategory === 'cms_dce_participants') {
      return await validateDCEParticipants(file);
    } else if (fileCategory === 'hedr_submission_templates') {
      return await validateHEDRTemplate(
        file,
        form.getFieldValue('performance_year')
      );
    }
    return true;
  };

  const customRequest = ({ onProgress, onError, onSuccess, file }: any) => {
    const key = `network/${network_id}/${fileCategory}/${new Date().getTime()}/${
      file.name
    }`;
    Storage.put(key, file, {
      level: 'private',
      contentType: file.type,
      bucket: process.env.REACT_APP_PRIVATE_STORAGE_BUCKET,
      progressCallback(progress: any) {
        onProgress({ percent: (100 * progress.loaded) / progress.total }, file);
      },
    })
      .then((_) => {
        Storage.get(key, {
          level: 'private',
          bucket: process.env.REACT_APP_PRIVATE_STORAGE_BUCKET,
        }).then((result) => {
          createFileUploadMutation({
            variables: {
              file_upload: {
                file_name: file.name,
                file_category: fileCategory,
                identity_id: userDetailsQuery.data?.users_by_pk?.identity_id!,
                level: 'private',
                key: key,
                user_id: userDetailsQuery.data?.users_by_pk?.user_id!,
                network_id: network_id,
                upload_status: 'Initiated',
              },
            },
          }).then((_) => {
            const newVal = { url: result, file: file };
            onSuccess(newVal, file);
          });
        });
      })
      .catch((err) => onError(err));
  };

  return (
    <Modal
      visible={visible}
      confirmLoading={handleSubmitLoading}
      title={`Import to ${fileCategory} for ACO ${dc_id}`}
      okText="Import Data"
      okButtonProps={{
        disabled: form.getFieldValue('file_details') === undefined,
      }}
      cancelText="Cancel"
      cancelButtonProps={{
        disabled: handleSubmitLoading,
      }}
      onCancel={() => {
        form.resetFields();
        setBatchProgress(null);
        setFileValidationMessage(null);
        onCancel();
      }}
      onOk={handleSubmitWithRequest}
    >
      {fileCategory === 'hedr_submission_templates' && (
        <p>
          Note: this is for blank templates. If demographic or SDOH values are
          already filled in, they must be uploaded separately to the health
          equity reports table.
        </p>
      )}
      <Form form={form}>
        {(fileCategory === 'cms_alignment_reports' ||
          fileCategory === 'cms_provider_alignment_reports' ||
          fileCategory === 'hedr_submission_templates') && (
          <Form.Item
            label="Performance Year"
            name="performance_year"
            rules={[
              {
                required: true,
                message: 'Please select a performance year',
              },
            ]}
          >
            <Select
              allowClear
              options={Array.from(
                {
                  length:
                    new Date(
                      Date.now() + 7 * 24 * 60 * 60 * 1000 // one week from now
                    ).getFullYear() - 2020,
                },
                (_, i) => {
                  const year =
                    new Date(
                      Date.now() + 7 * 24 * 60 * 60 * 1000 // one week from now
                    ).getFullYear() - i;
                  //make disabled for hedr_submission_templates if already uploaded for that year
                  const disabled =
                    fileCategory === 'hedr_submission_templates' &&
                    listHedrTemplateUploadsQueryResult.data?.hedr_submission_templates.some(
                      (template) => template.performance_year === year
                    );
                  return {
                    value: year,
                    label: year.toString(),
                    disabled: disabled,
                  };
                }
              )}
            />
          </Form.Item>
        )}
        {fileCategory === 'cms_pva_responses' && (
          <Form.Item
            label="Submission Date"
            name="SUBMISSION_DATE"
            rules={[
              {
                required: true,
                message: 'Please select a submission date',
              },
            ]}
          >
            <Select
              allowClear
              options={(() => {
                const currentDate = new Date();
                const months = [10, 7, 4, 1]; // November, August, May, February
                let currentYear = currentDate.getFullYear();
                let monthIndex = months.findIndex(
                  (month) => month <= currentDate.getMonth()
                );

                if (monthIndex === -1) {
                  monthIndex = 0;
                  currentYear -= 1;
                }
                let startDate = new Date(currentYear, months[monthIndex], 15);
                const dates = [];
                // Add to dates by decreasing from startDate in 3 month intervals
                // until the year is < 2022
                while (startDate.getFullYear() >= 2022) {
                  dates.push(new Date(startDate));
                  startDate = new Date(
                    startDate.setMonth(startDate.getMonth() - 3)
                  );
                }

                return dates;
              })().map((date) => ({
                value: date.toISOString().split('T')[0],
                label: date.toISOString().split('T')[0],
                // make disabled if already uploaded for that submission date
                disabled: listPvaResponseUploadQueryResult.data?.cms_pva_responses.some(
                  (response) =>
                    response.SUBMISSION_DATE ===
                    date.toISOString().split('T')[0]
                ),
              }))}
            />
          </Form.Item>
        )}
        <Form.Item
          label=""
          name="file_details"
          rules={[
            {
              required: true,
              message: 'Please select a file to upload',
            },
          ]}
        >
          <Upload.Dragger
            maxCount={1}
            beforeUpload={beforeUpload}
            accept={
              fileCategory === 'cms_provider_alignment_reports'
                ? '.csv,.tsv'
                : '.xlsx'
            }
            customRequest={customRequest}
            // onRemove={handleRemove}
          >
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">
              Click here or drag files to this area to upload
            </p>
          </Upload.Dragger>
        </Form.Item>
      </Form>
      {fileValidationMessage?.numRows && (
        <b>Number of rows detected: {fileValidationMessage.numRows}</b>
      )}
      {fileValidationMessage?.warning && (
        <Alert
          message={fileValidationMessage?.warning}
          type="warning"
          showIcon
        />
      )}
      {fileValidationMessage?.error && (
        <Alert message={fileValidationMessage?.error} type="error" showIcon />
      )}
      {batchProgress !== null && <Progress percent={batchProgress} />}
    </Modal>
  );
};

export default CMSUploadModal;
