import React from 'react';
import { Badge, Button, Timeline, Tooltip, Typography } from 'antd';
import { PresetStatusColorType } from 'antd/es/_util/colors';

import { MailOutlined, MessageOutlined } from '@ant-design/icons';
import { PaperPlaneOutlined } from '../MabelIcons';

import dayjs, { Dayjs } from 'dayjs';
import {
  useListPatientCommunicationsQuery,
  CampaignTemplateBasicFragment,
  EmailDetailsFragment,
  SmsDetailsFragment,
} from '../../graphql/generated';

import { Link } from 'react-router-dom';
import { useRequest } from 'ahooks';
import { getMergeVars } from '../templates/TemplateUtils';
import * as Handlebars from 'handlebars';

type LetterStatus =
  | 'Deleted'
  | 'In Local Area'
  | 'In Transit'
  | 'Mailed'
  | 'Pending'
  | 'Processed For Delivery'
  | 'Re-Routed'
  | 'Returned To Sender'
  | 'Skipped - undeliverable address';

type EmailStatus =
  | 'Accepted'
  | 'Clicked'
  | 'Complained'
  | 'Delivered'
  | 'Failed'
  | 'Opened'
  | 'Pending'
  | 'Skipped - email suppressed'
  | 'Skipped - no email'
  | 'Skipped - send date has passed'
  | 'Skipped - undeliverable email'
  | 'Unsubscribed';

type SMSStatus =
  | 'Accepted'
  | 'Delivered'
  | 'Skipped - sms suppressed'
  | 'Skipped - no phone number'
  | 'Skipped - send date has passed'
  | 'Skipped - undeliverable number'
  | 'Unsubscribed';

const STATUS_COLOR: {
  [key in LetterStatus | EmailStatus | SMSStatus]: PresetStatusColorType;
} = {
  //Mail Events:
  Deleted: 'error',
  'In Local Area': 'success',
  'In Transit': 'processing',
  Mailed: 'processing',
  Pending: 'processing',
  'Processed For Delivery': 'success',
  'Re-Routed': 'warning',
  'Returned To Sender': 'error',
  'Skipped - undeliverable address': 'default',

  //Email Events
  Accepted: 'processing',
  Clicked: 'success',
  Complained: 'error',
  Delivered: 'success',
  Failed: 'error',
  Opened: 'success',
  'Skipped - email suppressed': 'default',
  'Skipped - no email': 'default',
  'Skipped - send date has passed': 'default',
  'Skipped - undeliverable email': 'default',
  Unsubscribed: 'error',

  //SMS Events
  'Skipped - sms suppressed': 'default',
  'Skipped - no phone number': 'default',
  'Skipped - undeliverable number': 'default',
};

interface CommunicationTimelineEntryProps {
  campaign: CampaignTemplateBasicFragment;
  patient_segment?: string;
  modifiedDate: Dayjs;
  status: LetterStatus | EmailStatus | SMSStatus;
}

const CommunicationTimelineEntry: React.FC<CommunicationTimelineEntryProps> = ({
  campaign,
  patient_segment,
  modifiedDate,
  status,
}) => {
  return (
    <>
      <div>
        <Link to={`campaigns/${campaign.campaign_template_id}`}>
          {campaign.campaign_name}
        </Link>
      </div>
      <div>
        <small> {patient_segment}</small>
      </div>
      <div>
        <Badge status={STATUS_COLOR[status] || 'default'} />
        {status} <small>({modifiedDate.calendar()})</small>
      </div>
    </>
  );
};

const EmailDownloadButton: React.FC<{
  patient_id: string;
  email: EmailDetailsFragment;
}> = ({ patient_id, email }) => {
  const { data: mergeVars, loading: mergeVarsLoading } = useRequest(
    async () =>
      getMergeVars(
        'email',
        email.campaign_email_component?.campaign_email_component_id,
        email.practice_id,
        patient_id,
        email.campaign_email_component?.email_template?.email_template_id
      ),
    {
      refreshDeps: [email, patient_id],
    }
  );
  return (
    <Tooltip title={`Email ID: ${email.email_id} (click to download)`}>
      <a
        href={`data:application/octet-stream,${encodeURIComponent(
          email.campaign_email_component?.email_template.body_html && mergeVars
            ? Handlebars.compile(
                email.campaign_email_component?.email_template.body_html
              )(mergeVars)
            : ''
        )}`}
        download={`${email.email_id}.html`}
      >
        <Button
          shape="circle"
          icon={<MailOutlined />}
          loading={mergeVarsLoading}
        />
      </a>
    </Tooltip>
  );
};

const SMSDownloadButton: React.FC<{
  patient_id: string;
  sms: SmsDetailsFragment;
}> = ({ patient_id, sms }) => {
  const { data: mergeVars, loading: mergeVarsLoading } = useRequest(
    async () =>
      getMergeVars(
        'sms',
        sms.campaign_sms_component?.campaign_sms_component_id,
        sms.practice_id,
        patient_id,
        sms.campaign_sms_component?.sms_template?.sms_template_id
      ),
    {
      refreshDeps: [sms, patient_id],
    }
  );
  return (
    <Tooltip title={`SMS ID: ${sms.sms_message_id} (click to download)`}>
      <a
        href={`data:application/octet-stream,${encodeURIComponent(
          sms.campaign_sms_component?.sms_template.body_text && mergeVars
            ? Handlebars.compile(
                sms.campaign_sms_component?.sms_template.body_text
              )(mergeVars)
            : ''
        )}`}
        download={`${sms.sms_message_id}.txt`}
      >
        <Button
          shape="circle"
          icon={<MessageOutlined />}
          loading={mergeVarsLoading}
        />
      </a>
    </Tooltip>
  );
};

interface CommunicationTimelineProps {
  patientId: string;
}
const CommunicationTimeline: React.FC<CommunicationTimelineProps> = ({
  patientId,
}) => {
  const { data } = useListPatientCommunicationsQuery({
    variables: { patient_id: patientId },
  });

  return data ? (
    <Timeline mode="left" reverse={true}>
      {[...data.emails, ...data.letters, ...data.sms_messages]
        .sort((a, b) => a.send_datetime.localeCompare(b.send_datetime))
        .map((s) => {
          const sendDate = dayjs(s.send_datetime);
          const updateDate = dayjs(s.updated_at);

          return s.__typename === 'emails' ? (
            <Timeline.Item
              label={
                <div style={{ margin: '0px 8px' }}>
                  <Tooltip
                    title={`Scheduled Send Date: ${sendDate.format('llll')}`}
                  >
                    {sendDate.format('L')}
                  </Tooltip>
                  <div>
                    <Typography.Text
                      type="secondary"
                      ellipsis={{ tooltip: s.email_id }}
                      style={{ width: 80 }}
                    >
                      {s.email_id}
                    </Typography.Text>
                  </div>
                </div>
              }
              dot={<EmailDownloadButton patient_id={patientId} email={s} />}
              key={s.email_id}
            >
              <CommunicationTimelineEntry
                patient_segment={
                  s.campaign_email_component?.patient_segment
                    .patient_segment_description ?? ''
                }
                campaign={s.campaign_email_component?.campaign_template!}
                modifiedDate={updateDate}
                status={s.status as EmailStatus}
              />
            </Timeline.Item>
          ) : s.__typename === 'letters' ? (
            <Timeline.Item
              label={
                <div style={{ margin: '0px 8px' }}>
                  <Tooltip
                    title={`Scheduled Send Date: ${sendDate.format('llll')}`}
                  >
                    {sendDate.format('L')}
                  </Tooltip>
                  <div>
                    <Typography.Text
                      type="secondary"
                      ellipsis={{ tooltip: s.letter_id }}
                      style={{ width: 80 }}
                    >
                      {s.letter_id}
                    </Typography.Text>
                  </div>
                </div>
              }
              dot={
                <Tooltip title={`Letter ID: ${s.letter_id}`}>
                  <Button shape="circle" icon={<PaperPlaneOutlined />} />
                </Tooltip>
              }
              key={s.letter_id}
            >
              <CommunicationTimelineEntry
                patient_segment={
                  s.campaign_letter_component?.patient_segment
                    .patient_segment_description ?? ''
                }
                campaign={s.campaign_letter_component?.campaign_template!}
                modifiedDate={updateDate}
                status={s.status as LetterStatus}
              />
            </Timeline.Item>
          ) : s.__typename === 'sms_messages' ? (
            <Timeline.Item
              label={
                <div style={{ margin: '0px 8px' }}>
                  <Tooltip
                    title={`Scheduled Send Date: ${sendDate.format('llll')}`}
                  >
                    {sendDate.format('L')}
                  </Tooltip>
                  <div>
                    <Typography.Text
                      type="secondary"
                      ellipsis={{ tooltip: s.sms_message_id }}
                      style={{ width: 80 }}
                    >
                      {s.sms_message_id}
                    </Typography.Text>
                  </div>
                </div>
              }
              dot={<SMSDownloadButton patient_id={patientId} sms={s} />}
              key={s.sms_message_id}
            >
              <CommunicationTimelineEntry
                patient_segment={
                  s.campaign_sms_component?.patient_segment
                    .patient_segment_description ?? ''
                }
                campaign={s.campaign_sms_component?.campaign_template!}
                modifiedDate={updateDate}
                status={s.status as SMSStatus}
              />
            </Timeline.Item>
          ) : null;
        })}
    </Timeline>
  ) : null;
};

export default CommunicationTimeline;
